import React, { useState } from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { Loading } from 'app/atoms/Loading/Loading';
import { cn } from 'app/lib/cn';
import { FileUploader, FileUploaderProps } from '../FileUploader/FileUploader';

type LogoUploaderProps = {
  id: string;
  imageUrl?: string;
  onRemove: React.MouseEventHandler<HTMLElement>;
} & FileUploaderProps;

export const LogoUploader = ({ imageUrl, id, onAttach, onRemove, ...rest }: LogoUploaderProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            {imageUrl ? (
              <div className="h-20 flex items-center">
                {onRemove && imageLoaded && (
                  <Button
                    onClick={onRemove}
                    minimal
                    icon="cross"
                    small
                    className="border-1 absolute top-0 right-0 z-5"
                  />
                )}
                <img
                  className={cn('m-0 max-h-16 w-auto my-auto object-contain justify-center', {
                    hidden: !imageLoaded
                  })}
                  src={imageUrl}
                  alt="logo"
                  onLoad={() => setImageLoaded(true)}
                />
                <div className={cn({ hidden: imageLoaded })}>
                  <Loading type="logo" />
                </div>
              </div>
            ) : (
              <div className="relative block h-20 w-64 rounded-md border-2 border-dashed border-gray-300 pt-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <Icon icon="media" size={32} className="m-auto text-gray-300" />
                <span className="mt-2 block text-sm font-medium text-gray-300">Logo</span>
              </div>
            )}
            <label
              htmlFor={`${id}-input`}
              className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center rounded-md bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
            >
              <span>{imageUrl ? 'Change' : 'Add Logo'}</span>
              <span className="sr-only">Add logo</span>
              <FileUploader
                id={id}
                wrapperElement="div"
                className="relative"
                onAttach={onAttach}
                useUploadArgs={{ maxFiles: 1 }}
                inputProps={{
                  className: 'absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md',
                  id: `${id}-input`
                }}
                {...rest}
              />
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
