import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';

import { pluralize } from 'app/lib/strings';
import { useOppSearchResults } from 'api/oppsApi';

export const SavedSearchVolumeCallout = () => {
  const { data: { meta } = {}, isLoading: isSearching } = useOppSearchResults();

  const { total, dateRangeInWords } = meta ?? {};

  if (isSearching || !total || total < 500) {
    return null;
  }

  let intent: Intent = 'warning';

  if (total > 2000) {
    intent = 'danger';
  }

  return (
    <Callout intent={intent} title="Are you sure you want to create this saved search?">
      <p>
        {`There have been ${total.toLocaleString()} ${pluralize(
          'hit',
          'hits',
          total
        )} in a period of ${dateRangeInWords}`}
        .
      </p>
    </Callout>
  );
};
