import React from 'react';

import { GovernmentEntitiesForm } from 'app/organisms/GovernmentEntitiesForm/GovernmentEntitiesForm';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const GovernmentEntitiesPage = () => {
  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']}>
      <Main>
        <MainColumn>
          <GovernmentEntitiesForm />
        </MainColumn>
      </Main>
    </SubscriptionRequiredPageWrapper>
  );
};
