import React, { useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { MapEventOf } from 'mapbox-gl';
import { CompoundTag } from '@blueprintjs/core';
import { TILE_LAYERS, getFillsKey } from 'app/lib/mapbox';
import { useOppSearchAnalyticsMapStore as useStore } from 'app/organisms/OppSearchUnitedStatesMap/useOppSearchAnalyticsMapStore';

export const useOppSearchAnalyticsMapEvents = () => {
  const { mapRef, isMapReady } = useStore(({ mapRef, isMapReady }) => ({
    mapRef,
    isMapReady
  }));

  useEffect(() => {
    if (!isMapReady || !mapRef.current) return;

    const map = mapRef.current;

    const { source, sourceLayer } = TILE_LAYERS.state;

    const onMouseMove = (e: MapEventOf<'mousemove'>) => {
      const [feature] = e.features ?? [];
      if (feature?.id) {
        map.getCanvas().style.cursor = 'pointer';
        const { hoveredFeatureRef } = useStore.getState();
        if (hoveredFeatureRef.current?.id) {
          map.setFeatureState({ id: hoveredFeatureRef.current.id, source, sourceLayer }, { hover: false });
        }
        map.setFeatureState({ id: feature.id, source, sourceLayer }, { hover: true });

        useStore.setState({
          popup: (
            <Popup
              longitude={e.lngLat.lng}
              latitude={e.lngLat.lat}
              closeButton={false}
              className="[&_.mapboxgl-popup-content]:p-0 [&_.mapboxgl-popup-tip]:!border-transparent"
            >
              <CompoundTag leftContent={TILE_LAYERS.state.getFeatureLabel(feature)} large>
                {map.getFeatureState(feature)?.docCount as string} opportunities
              </CompoundTag>
            </Popup>
          )
        });

        hoveredFeatureRef.current = feature;
      }
    };

    const onMouseLeave = (_e: MapEventOf<'mouseleave'>) => {
      const { hoveredFeatureRef } = useStore.getState();
      if (hoveredFeatureRef.current?.id) {
        map.setFeatureState({ id: hoveredFeatureRef.current.id, source, sourceLayer }, { hover: false });
      }
      useStore.setState({ popup: undefined });
      hoveredFeatureRef.current = null;
    };

    const onClick = (e: MapEventOf<'click'>) => {
      const [feature] = e.features ?? [];
      if (feature) {
        useStore.getState().clickedFeatureRef.current = feature;
        useStore.setState({ drawerIsOpen: true });
      }
    };

    const eventListeners = (
      [
        ['mousemove', onMouseMove],
        ['mouseleave', onMouseLeave],
        ['click', onClick]
      ] as const
    ).map(([event, listener]) => [event, getFillsKey(source), listener] as const);

    eventListeners.forEach(([event, layer, listener]) => map.on(event, layer, listener));
    return () => {
      eventListeners.forEach(([event, layer, listener]) => map.off(event, layer, listener));
    };
  }, [isMapReady, mapRef]);
};
