import React, { useCallback } from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useSearchQueryInputContext } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { useAssistantContext } from '../Assistant/Assistant';
import { AssistantHeader } from '../Assistant/AssistantHeader';
import { getWelcomeMessages } from './KeywordAssistantWelcomeMessages';

const KeywordAssistantHeader = () => {
  const { getNewThread, resetMessages, setWelcomeMessage } = useAssistantContext();
  const { assistantIsOpen, setAssistantIsOpen } = useSearchQueryInputContext();
  const { data: user } = useGetCurrentUserQuery();
  const { refreshWelcomeMessage } = getWelcomeMessages(user);
  const handleNewThreadRequest = () => {
    resetMessages();
    getNewThread();
    handleNewWelcomeMessage();
  };

  const handleNewWelcomeMessage = useCallback(() => {
    setWelcomeMessage?.(refreshWelcomeMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssistantHeader>
      <Button icon="annotation" onClick={handleNewThreadRequest} minimal>
        New Search Assistant
      </Button>
      <Tooltip content="Close" placement="right">
        <Button icon="cross" minimal onClick={() => setAssistantIsOpen(!assistantIsOpen)} />
      </Tooltip>
    </AssistantHeader>
  );
};

export { KeywordAssistantHeader };
