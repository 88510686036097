import React from 'react';
import { Button } from '@blueprintjs/core';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';

export const BackToTopButton = () => {
  const scrollAppBody = useScrollAppBody();
  const toTop = () => {
    scrollAppBody({ top: 0, behavior: 'smooth' });
  };
  return <Button onClick={() => toTop()} large text="Back to Top" />;
};
