import React from 'react';
import { Outlet } from 'react-router-dom';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { useMonitorCurrentUser } from 'app/hooks/useMonitorCurrentUser';
import { ApplicationErrorBoundary } from 'app/atoms/ErrorBoundary/ApplicationErrorBoundary';

export const AuthenticatedRouteLayout = () => {
  const { currentUser, isLoading } = useMonitorCurrentUser();

  if (isLoading) {
    return <AppLoading />;
  }

  if (!currentUser) {
    window.location.href = '/sign_in?error=401';
    return <AppLoading />;
  }

  return (
    <ApplicationErrorBoundary action="AuthenticatedRouteLayout">
      <Outlet />
    </ApplicationErrorBoundary>
  );
};
