import { SetAside } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetSetAsides = {
  params: void;
  result: SetAside[];
};

const unknownSetAside: SetAside = {
  __typename: 'SetAside',
  id: 'unknown',
  name: 'Unknown',
  code: 'UNKNOWN'
};

export const setAsidesApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getSetAsides: build.query<GetSetAsides['result'], GetSetAsides['params']>({
      query: () => ({
        url: '/v2/set_asides'
      }),
      transformResponse: (response: SetAside[]) => [unknownSetAside, ...response]
    })
  })
});

export const { useGetSetAsidesQuery } = setAsidesApi;
