import React from 'react';
import { MapRef, MapboxGeoJSONFeature, ViewState } from 'react-map-gl';
import { create } from 'zustand';

export type MapAgg = 'usPopRegionAgg' | 'usPopCdAgg';

export type State = {
  aggregation: MapAgg;
  isMapReady: boolean;
  popup: React.ReactNode;
  drawerIsOpen: boolean;
  mapRef: React.MutableRefObject<MapRef | null>;
  clickedFeatureRef: React.MutableRefObject<MapboxGeoJSONFeature | null>;
  hoveredFeatureRef: React.MutableRefObject<MapboxGeoJSONFeature | null>;
  viewState: Partial<ViewState>;
};

type Action = {
  setAggregation: (aggregation: MapAgg) => void;
  setIsMapReady: (isMapReady: boolean) => void;
  setDrawerIsOpen: (drawerIsOpen: boolean) => void;
  setViewState: (viewState: Partial<ViewState>) => void;
};

export type Store = State & Action;

export const useAwardSearchAnalyticsMapStore = create<Store>(set => ({
  aggregation: 'usPopRegionAgg',
  isMapReady: false,
  popup: undefined,
  drawerIsOpen: false,
  clickedFeatureRef: { current: null },
  hoveredFeatureRef: { current: null },
  mapRef: { current: null },
  viewState: { longitude: -98.5795, latitude: 39.8283, zoom: 3 },

  setAggregation: (aggregation: MapAgg) => set({ aggregation }),
  setIsMapReady: (isMapReady: boolean) => set({ isMapReady }),
  setDrawerIsOpen: (drawerIsOpen: boolean) => set({ drawerIsOpen }),
  setViewState: (viewState: Partial<ViewState>) => set({ viewState })
}));
