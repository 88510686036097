import { useAppLayoutContext } from 'app/organisms/AppLayout/AppLayoutContext';
import { useCallback } from 'react';

type ScrollToParams = Parameters<Element['scrollTo']>;

export const useScrollAppBody = () => {
  const { appBodyRef } = useAppLayoutContext();
  return useCallback<Element['scrollTo']>(
    <Params extends ScrollToParams>(...args: Params) => {
      appBodyRef.current?.scrollTo(...(args as ScrollToParams));
    },
    [appBodyRef]
  );
};
