import React from 'react';
import { Tag } from '@blueprintjs/core';

interface SetAsideTagProps {
  code?: string | Array<string> | null;
}

export const SetAsideTag = ({ code }: SetAsideTagProps) => {
  if (!code) {
    return null;
  }

  if (Array.isArray(code)) {
    return (
      <>
        {code.map((c, i) => (
          <Tag key={i} minimal intent="warning" icon="pie-chart">
            {c}
          </Tag>
        ))}
      </>
    );
  }

  return (
    <Tag minimal intent="warning" icon="pie-chart">
      {code}
    </Tag>
  );
};
