import React from 'react';

import { MultiSelectInput, MultiSelectInputProps } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';
import { SetAside } from 'types/__generated__/GovlyApi';

export const SetAsideMultiSelectInput = (props: Omit<MultiSelectInputProps<SetAside>, 'items' | 'searchKeys'>) => {
  const { data: setAsides = [], isLoading } = useGetSetAsidesQuery();

  return (
    <MultiSelectInput
      items={setAsides}
      searchKeys={['name', 'code']}
      disabled={isLoading}
      labelAttr="name"
      valueAttr="id"
      {...props}
    />
  );
};
