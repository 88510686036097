import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnchorButton, Intent, Tag } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';

import { OrganizationUserCurrentOrg, OrganizationUserWithReports } from 'types/__generated__/GovlyApi';
import { possessive } from 'app/lib/strings';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useGetOrganizationUserQuery } from 'api/organizationUsersApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { WorklistCalendarSubscription } from 'app/organisms/WorklistCalendarSubscription/WorklistCalendarSubscription';
import { UserProfileSubscriptionTag } from 'app/organisms/UserProfileSubscriptionTag/UserProfileSubscriptionTag';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { AttachmentList } from 'app/organisms/AttachmentList/AttachmentList';
import { TeamTagList } from 'app/atoms/TeamTagList/TeamTagList';

export const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const adminViewer = useAuthorized({ role: 'admin' });
  const viewerOrgId = useCurrentUserAttribute('organizationId') as string | undefined;
  const currentUserId = useCurrentUserAttribute('id') as string | undefined;
  const viewingSelf = currentUserId === id;
  const { data, isLoading } = useGetOrganizationUserQuery(
    {
      id: id ?? '',
      view: viewingSelf ? 'with_reports' : 'current_org'
    },
    { skip: !id, pollingInterval: viewingSelf ? 5000 : 0 }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <NotFound />;
  }

  const organizationUser = data as OrganizationUserCurrentOrg | OrganizationUserWithReports;

  const {
    calendarUrl,
    email,
    name,
    avatarColor,
    avatar,
    initials,
    subscriptionType,
    subscriptionSeatPaid,
    organizationId,
    organizationName,
    organizationTeams,
    role,
    phoneNumber,
    sysAdmin
  } = organizationUser;

  const recentReports = 'recentReports' in organizationUser ? (organizationUser.recentReports ?? []) : [];

  const showInternalOrgFields = organizationId === viewerOrgId;
  const viewingAsAdmin = (adminViewer || viewingSelf) && showInternalOrgFields;

  let roleIntent: Intent = 'primary';
  if (sysAdmin) {
    roleIntent = 'danger';
  } else if (role === 'admin') {
    roleIntent = 'warning';
  }

  return (
    <div className="grid grid-cols-12 gap-y-8">
      <div className="col-span-12 mx-4 flex items-center justify-between sm:mx-0">
        <Breadcrumbs />
      </div>
      <div className="col-span-12 mx-4 flex items-center justify-between sm:mx-0">
        <AvatarWithName
          initials={initials}
          imgSrc={avatar?.thumbUrl}
          avatarColor={avatarColor}
          id={id}
          email={email}
          name={name}
          size="2xl"
        />
        {viewingAsAdmin && (
          <AnchorButton
            onClick={() =>
              navigate(`/users/${id}/edit`, {
                state: {
                  breadcrumbs: [
                    { to: '/settings/users', text: 'Users' },
                    { to: `/users/${id}`, text: email },
                    { text: 'Edit' }
                  ]
                }
              })
            }
            large
            text="Edit"
          />
        )}
      </div>
      <Card className="col-span-12">
        <CardBody>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <DescriptionListField label="Name" value={name} cols={1} />
            <DescriptionListField label="Email" value={email} cols={1} />
            <DescriptionListField label="Phone" value={phoneNumber} cols={1} />
            <DescriptionListField label="Organization" value={organizationName} cols={1} />

            {showInternalOrgFields && (
              <>
                <DescriptionListField
                  label="Billing Status"
                  value={
                    <UserProfileSubscriptionTag
                      subscriptionType={subscriptionType}
                      subscriptionSeatPaid={subscriptionSeatPaid}
                    />
                  }
                  cols={1}
                />
                <DescriptionListField
                  label="Role"
                  value={<Tag intent={roleIntent}>{sysAdmin ? 'SYS ADMIN' : startCase(role)}</Tag>}
                  cols={1}
                />
              </>
            )}

            {organizationTeams && organizationTeams.length > 0 && (
              <DescriptionListField
                label="Team Memberships"
                value={<TeamTagList teams={organizationTeams} />}
                cols={2}
              />
            )}

            {calendarUrl && (
              <DescriptionListField
                label={`Subscribe to ${possessive(name)} Govly Calendar`}
                value={<WorklistCalendarSubscription calendarUrl={calendarUrl} className="mt-2" />}
                cols={2}
              />
            )}

            {viewingSelf && recentReports.length > 0 && (
              <div className="col-span-2">
                <LabelSpan label="Recent Reports" className="mb-1.5" />
                <AttachmentList attachments={recentReports} />
              </div>
            )}
          </dl>
        </CardBody>
      </Card>
    </div>
  );
};
