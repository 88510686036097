import React from 'react';
import { Tag } from '@blueprintjs/core';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { Activity } from 'types/__generated__/GovlyApi';

type OppUpdateTagsProps = {
  updates: Activity[];
  expiringSoon?: boolean;
};

export const OppUpdateTags = ({ updates, expiringSoon }: OppUpdateTagsProps) => {
  const mods = updates.filter(u => u.actionTargetType === 'Opp');
  const updatedQouteSought = mods.some(m => m.message?.includes('Updated Quote sought'));
  const updateTagContent = updatedQouteSought ? 'Updated Quote sought' : 'Updated';
  const updateTag = <Tag intent="warning">{updateTagContent}</Tag>;

  return (
    <div className="space-x-2">
      {mods.length > 0 && (
        <InfoTooltip target={updateTag} shouldTrack trackedEventName={updateTagContent}>
          {updateTag}
          {mods.map((m, i) => (
            <blockquote key={i}>{m.message}</blockquote>
          ))}
        </InfoTooltip>
      )}

      {expiringSoon && <Tag intent="danger">Expires Soon</Tag>}
    </div>
  );
};
