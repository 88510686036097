import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useContactSearchMutation } from 'api/contactsApi';
import { SearchQueryInput } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { SavedSearchSelectInput } from 'app/organisms/SavedSearchSelectInput/SavedSearchSelectInput';
import { SavedSearchControls } from 'app/organisms/SavedSearchControls/SavedSearchControls';

export const ContactSearchQuery = () => {
  const [_search, { isLoading }] = useContactSearchMutation({
    fixedCacheKey: 'contact-search'
  });

  return (
    <div className="grid grid-cols-1 divide-y dark:divide-gray-600">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <SavedSearchSelectInput searchableType="Contact" />
        <FormGroup className="m-0 pb-4 pt-0">
          <div className="flex min-w-max justify-end space-x-2">
            <SavedSearchControls searchableType="Contact" />
          </div>
        </FormGroup>
      </div>
      <SearchQueryInput
        name="query"
        className="m-0 pt-0 border-none"
        loading={isLoading}
        placeholder={`“end user” AND Nutanix`}
      />
    </div>
  );
};
