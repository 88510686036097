import React, { Suspense } from 'react';
import { useParams, Outlet } from 'react-router-dom';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { Tabs } from 'app/molecules/Tabs/Tabs';
import { useGetVendorQuery } from 'api/vendorsApi';
import { VendorProfileClaimButtons } from 'app/organisms/VendorProfileClaimButtons/VendorProfileClaimButtons';
import { LogoOrName } from 'app/molecules/LogoOrName/LogoOrName';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { VendorShow } from 'types/__generated__/GovlyApi';

export const VendorProfilesShowPage = () => {
  const { slug } = useParams();

  const { data, isLoading, isError } = useGetVendorQuery({ id: slug ?? '' }, { skip: !slug });
  const vendor = data as VendorShow | undefined;

  if (isLoading) return <AppLoading />;
  if (isError || !slug || !vendor) return <NotFound />;

  const tabs = [
    {
      text: 'Summary',
      icon: 'info-sign',
      to: `/vendors/${slug}`
    },
    {
      text: 'Partners',
      icon: 'third-party',
      to: `/vendors/${slug}/partners`
    },
    {
      text: 'Spending',
      icon: 'dollar',
      to: `/vendors/${slug}/spending`
    },
    {
      text: 'Contracts',
      icon: 'label',
      to: `/vendors/${slug}/contracts`
    }
  ];

  return (
    <Main>
      <MainColumn>
        <Breadcrumbs items={[{ to: '/vendors', text: 'Vendor Search' }, { text: vendor.name }]} />
      </MainColumn>
      <MainColumn>
        <div className="mt-4 mb-8 flex flex-col items-center justify-between lg:mx-0 lg:flex-row">
          <LogoOrName name={vendor.name || slug} logo={vendor.logo} />
          <VendorProfileClaimButtons slug={slug} />
        </div>
      </MainColumn>
      <MainColumn columnSpan={12}>
        <Tabs tabs={tabs} />
      </MainColumn>
      <MainColumn>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </MainColumn>
    </Main>
  );
};
