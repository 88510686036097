import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tag } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';

import { SouptoolsQuote } from 'types/__generated__/GovlyApi';
import { formatTime } from 'app/lib/dates';

type SouptoolsQuoteProps = {
  quote: SouptoolsQuote;
};

export const SouptoolsQuoteTableRow = ({
  quote: { id, createdAt, status, name, clinsCount, user }
}: SouptoolsQuoteProps) => {
  const { email: createdBy } = user ?? {};

  return (
    <div className="flex justify-between">
      <div className="flex flex-wrap justify-between">
        <div>
          <Link to={`/souptools/quotes/${id}`}>
            <strong>{name || `Quote ${id}`}</strong>
          </Link>
          {createdBy && (
            <small className="ml-2">
              <strong>{`Owned by ${createdBy}`}</strong>
            </small>
          )}
          <span className="ml-2">
            <Tag minimal>{startCase(status)}</Tag>
          </span>
          <div className="flex flex-wrap items-center py-1">
            <>
              <Icon icon="time" size={12} />
              <small className="ml-1">{` Created ${formatTime(createdAt)}`}</small>
            </>
            <>
              <Icon icon="dot" size={12} />

              <small>
                <strong>{`${clinsCount} CLINs`}</strong>
              </small>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
