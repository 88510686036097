import { JSONObject, SavedSearchDisplayable } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['SavedSearch'] });

export type SearchableType = 'Contact' | 'USASpendingAward';

export type SearchableTypeProps = {
  searchableType?: SearchableType;
};

type GetSavedSearches = {
  params: {
    active?: boolean;
    searchableType?: SearchableType;
  } | void;
  result: SavedSearchDisplayable[];
};

type GetSavedSearch = {
  params: {
    id: string;
  };
  result: SavedSearchDisplayable;
};

type CreateSavedSearch = {
  params: {
    name: string;
    searchableType: SearchableType;
  } & JSONObject;
  result: SavedSearchDisplayable;
};

type UpdateSavedSearch = {
  params: {
    id: string;
    searchableType?: SearchableType;
    active?: boolean;
  } & JSONObject;
  result: SavedSearchDisplayable;
};

export const savedSearchesApi = api.injectEndpoints({
  endpoints: build => ({
    getSavedSearches: build.query<GetSavedSearches['result'], GetSavedSearches['params']>({
      query: params => {
        return { url: '/v2/saved_searches', params: params || {} };
      },
      providesTags: result =>
        Array.isArray(result)
          ? [...result.map(({ id }) => ({ type: 'SavedSearch' as const, id })), 'SavedSearch']
          : ['SavedSearch']
    }),

    getSavedSearch: build.query<GetSavedSearch['result'], GetSavedSearch['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/saved_searches/${id}`, params }),
      providesTags: result => [{ type: 'SavedSearch', id: result?.id }],
      keepUnusedDataFor: 180
    }),

    createSavedSearch: build.mutation<CreateSavedSearch['result'], CreateSavedSearch['params']>({
      query: body => ({
        url: `/v2/saved_searches`,
        method: 'POST',
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: createdSavedSearch } = await queryFulfilled;
          dispatch(
            savedSearchesApi.util.updateQueryData(
              'getSavedSearches',
              { active: true, searchableType: body.searchableType },
              draftSavedSearches => {
                draftSavedSearches.push(createdSavedSearch);
              }
            )
          );
        } catch {}
      }
    }),

    updateSavedSearch: build.mutation<UpdateSavedSearch['result'], UpdateSavedSearch['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/saved_searches/${id}`,
        method: 'PATCH',
        body
      }),
      async onQueryStarted({ id, searchableType }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSavedSearch } = await queryFulfilled;

          dispatch(
            savedSearchesApi.util.updateQueryData(
              'getSavedSearches',
              { active: true, searchableType },
              draftSavedSearches =>
                draftSavedSearches.map(oppSearch => (oppSearch.id === id ? updatedSavedSearch : oppSearch))
            )
          );
          dispatch(
            savedSearchesApi.util.updateQueryData('getSavedSearches', { searchableType }, draftSavedSearches =>
              draftSavedSearches.map(oppSearch => (oppSearch.id === id ? updatedSavedSearch : oppSearch))
            )
          );
        } catch {}
      }
    })
  })
});

export const {
  useGetSavedSearchesQuery,
  useCreateSavedSearchMutation,
  useUpdateSavedSearchMutation,
  useGetSavedSearchQuery
} = savedSearchesApi;
