import { CheckboxProps, InputGroupProps, RadioGroupProps, SegmentedControlProps, SwitchProps } from '@blueprintjs/core';
import { DateRange, DateRangeInput3Props } from '@blueprintjs/datetime2';
import { InputMultiSelectProps } from 'app/molecules/InputMultiSelect/InputMultiSelect';
import { RangeInputProps, Range } from 'app/atoms/inputs/RangeInput/RangeInput';
import { FieldHelperProps, FieldInputProps } from 'formik';
import debounce from 'just-debounce-it';
import { DeepKeys } from '@tanstack/react-table';
import { notNullish } from 'app/lib/notNullish';

type Common<Value> = {
  field: FieldInputProps<Value>;
  helpers: FieldHelperProps<Value>;
  submitForm?: () => void;
  debounceTime?: number;
};

export type FormKeys<Values> = Extract<DeepKeys<Values>, string>;

function toRadioGroup<Value extends string | number>({
  field: { value, onChange, ...props },
  submitForm
}: Common<Value>): RadioGroupProps {
  return {
    ...props,
    selectedValue: String(value),
    onChange: e => {
      onChange(e);
      submitForm?.();
    }
  };
}

function toDateRange<Value extends DateRange>({
  field: { value, onChange: _, ...props },
  helpers,
  submitForm
}: Common<Value>): DateRangeInput3Props {
  return {
    ...props,
    value,
    formatDate: date => (date == null ? '' : date.toLocaleDateString()),
    parseDate: str => new Date(Date.parse(str)),
    onChange: newRange => {
      helpers.setValue([...newRange] as Value);
      submitForm?.();
    }
  };
}

function toRange({
  field: { value, onChange: _, ...props },
  helpers,
  debounceTime = 200
}: Common<Range>): RangeInputProps {
  return {
    ...props,
    value,
    onMinChange: debounce((newMin: number, minStr: string) => {
      if (minStr === '') {
        helpers.setValue([undefined, value[1]]);
        return;
      }
      if (typeof newMin === 'number' && !isNaN(newMin)) {
        helpers.setValue([newMin, value[1]]);
      }
    }, debounceTime),
    onMaxChange: debounce((newMax: number, maxStr: string) => {
      if (maxStr === '') {
        helpers.setValue([value[0], undefined]);
        return;
      }

      if (typeof newMax === 'number' && !isNaN(newMax)) {
        helpers.setValue([value[0], newMax]);
      }
    }, debounceTime)
  };
}

function toCheckbox<Value extends string | string[]>({
  field: { value: fieldValue, onChange, ...props },
  helpers,
  submitForm,
  value
}: Common<Value> & { value: string }): CheckboxProps {
  return {
    ...props,
    checked: Array.isArray(fieldValue) ? fieldValue.includes(value) : fieldValue === value,
    onChange: e => {
      if (Array.isArray(fieldValue)) {
        const selected = new Set<string>(fieldValue);
        if (e.target.checked) {
          selected.add(value);
        } else {
          selected.delete(value);
        }
        helpers.setValue(Array.from(selected) as Value);
      } else {
        onChange(e);
      }
      submitForm?.();
    }
  };
}

const valueMapCache = new Map<string, Record<string, unknown>>();

const getMultiSelectValueMapCache = () => valueMapCache;

function toMultiSelect<Item, Value extends string[] = string[]>({
  field: { value: fieldValue = [] as unknown as Value, ...props },
  helpers,
  submitForm,
  items,
  getItemValue,
  getItemFallback,
  shouldCacheValueMap = false
}: {
  field: FieldInputProps<Value>;
  helpers: FieldHelperProps<Value>;
  submitForm?: () => void;
  items: Item[];
  getItemValue: (item: Item) => Value[number];
  getItemFallback?: (value: string) => Item;
  shouldCacheValueMap?: boolean;
}): Pick<InputMultiSelectProps<Item>, 'onChange' | 'selectedItems' | 'name'> {
  const valuesMap = {
    ...((valueMapCache.get(props.name) ?? {}) as Record<string, Item>),
    ...Object.fromEntries(items.map(item => [getItemValue(item), item]))
  };

  const selectedItems = fieldValue.map(value => valuesMap[value] ?? getItemFallback?.(value)).filter(notNullish);

  if (shouldCacheValueMap) {
    valueMapCache.set(props.name, Object.fromEntries(selectedItems.map(item => [getItemValue(item), item])));
  }

  const onChange = (items: Item[]) => {
    helpers.setValue(items.map(getItemValue) as Value);
    submitForm?.();
  };

  return {
    ...props,
    selectedItems,
    onChange
  };
}

function toSwitch<Value extends boolean>({
  field: { value: fieldValue, onChange, ...props },
  submitForm,
  onChange: onChangeProp
}: Common<Value> & { onChange?: React.ChangeEventHandler<HTMLInputElement> }): SwitchProps {
  return {
    ...props,
    checked: fieldValue,
    onChange: onChangeProp
      ? onChangeProp
      : e => {
          onChange(e);
          submitForm?.();
        }
  };
}

function toSegmentedControl<Value extends string>({
  field: { value, onChange: _, ...props },
  helpers,
  submitForm,
  onValueChange
}: Common<Value> & Partial<Pick<SegmentedControlProps, 'onValueChange'>>): Omit<SegmentedControlProps, 'options'> {
  return {
    ...props,
    value,
    onValueChange: (val, ...rest) => {
      onValueChange?.(val, ...rest);
      helpers.setValue(val as Value);
      submitForm?.();
    }
  };
}

function toInputGroup<Value extends string>({
  field: { value, onChange: _, ...props },
  helpers,
  submitForm
}: Common<Value>): Omit<InputGroupProps, 'options'> {
  return {
    ...props,
    value,
    onValueChange: val => {
      helpers.setValue(val as Value);
      submitForm?.();
    }
  };
}

export const FormikToBp = {
  toRadioGroup,
  toDateRange,
  toRange,
  toCheckbox,
  toMultiSelect,
  toSwitch,
  toSegmentedControl,
  toInputGroup,
  getMultiSelectValueMapCache
};
