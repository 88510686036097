import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Button, Callout, Tag } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';
import { useNavigate } from 'react-router-dom';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { useCreateOrganizationMutation, useUpdateOrganizationMutation } from 'api/organizationsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TagInput } from 'app/atoms/inputs/TagInput/TagInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { NumericInput } from 'app/atoms/inputs/NumericInput/NumericInput';
import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { OrganizationCurrentOrganization } from 'types/__generated__/GovlyApi';
import { hasId } from 'api/utils';

type FormValues = {
  id?: string;
  name?: string;
  internal: boolean;
  approvedDomains: string[];
  primaryDomain?: string;
  settings: {
    preventOppAttachmentsInEmails?: boolean;
    compliancePreventUploads?: boolean;
    canViewFilesInBrowser?: boolean;
    hasConflictNotifications?: boolean;
  };
  workosDomains: string[];
  workosOrganizationId?: string;
  subscriptionType: string;
  ssoEnabled: boolean;
  subscriptionTrialLengthInDays?: number;
  subscriptionMaxPaidSeatCount: number;
  subscriptionHasNetwork: boolean;
  subscriptionHasSLED: boolean;
  subscriptionHasPrimeIngestion: boolean;
  subscriptionHasPrimeAutomations: boolean;
  subscriptionHasSouptools: boolean;
  subscriptionHasApiIntegrations: boolean;
};

const subscriptionTypesEnum = ['free', 'trial', 'basic', 'plus', 'enterprise'];

const onSubscriptionTypeChange = (type: string, setFieldValue: (field: string, value: boolean | number) => void) => {
  setFieldValue('subscriptionHasNetwork', false);
  setFieldValue('subscriptionTrialLengthInDays', 0);
  setFieldValue('subscriptionMaxPaidSeatCount', 0);
  setFieldValue('subscriptionHasSLED', false);
  setFieldValue('subscriptionHasSouptools', false);
  setFieldValue('subscriptionHasApiIntegrations', false);
  setFieldValue('subscriptionHasPrimeAutomations', false);
  setFieldValue('subscriptionHasPrimeIngestion', false);

  if (type === 'basic') {
    setFieldValue('subscriptionHasSLED', true);
    setFieldValue('subscriptionMaxPaidSeatCount', 5);
  }

  if (type === 'trial') {
    setFieldValue('subscriptionTrialLengthInDays', 30);
  }

  if (['trial', 'plus'].includes(type)) {
    setFieldValue('subscriptionHasNetwork', true);
    setFieldValue('subscriptionHasPrimeIngestion', true);
    setFieldValue('subscriptionHasSLED', true);
    setFieldValue('subscriptionMaxPaidSeatCount', 10);
  }

  if (type === 'enterprise') {
    setFieldValue('subscriptionHasApiIntegrations', true);
    setFieldValue('subscriptionHasNetwork', true);
    setFieldValue('subscriptionHasPrimeAutomations', true);
    setFieldValue('subscriptionHasPrimeIngestion', true);
    setFieldValue('subscriptionHasSLED', true);
    setFieldValue('subscriptionHasSouptools', true);
    setFieldValue('subscriptionMaxPaidSeatCount', 100);
  }
};

export const OrganizationSysAdminForm = ({ organization }: { organization?: OrganizationCurrentOrganization }) => {
  const navigate = useNavigate();
  const sysAdminViewer = useAuthorized({ role: 'sys_admin' });
  const [createMutation, { isLoading: isCreating }] = useCreateOrganizationMutation();
  const [updateMutation, { isLoading: isUpdating }] = useUpdateOrganizationMutation();

  if (!sysAdminViewer) {
    return null;
  }

  const {
    id,
    name,
    approvedDomains,
    primaryDomain,
    settings,
    internal,
    workosDomains,
    workosOrganizationId,
    ssoEnabled,
    subscriptionType,
    subscriptionTrialLengthInDays,
    subscriptionMaxPaidSeatCount,
    subscriptionHasNetwork,
    subscriptionHasSLED,
    subscriptionHasPrimeIngestion,
    subscriptionHasPrimeAutomations,
    subscriptionHasSouptools,
    subscriptionHasApiIntegrations,
    subscriptionTrialEndsAt
  } = organization || {};

  const form: FormikConfig<FormValues> = {
    initialValues: {
      id,
      name: name,
      internal: internal || false,
      approvedDomains: approvedDomains || [],
      primaryDomain: primaryDomain,
      settings: settings || {},
      workosDomains: workosDomains || [],
      workosOrganizationId: workosOrganizationId,
      subscriptionType: subscriptionType || 'free',
      ssoEnabled: !!ssoEnabled,
      subscriptionTrialLengthInDays,
      subscriptionMaxPaidSeatCount: subscriptionMaxPaidSeatCount || 0,
      subscriptionHasNetwork: subscriptionHasNetwork || false,
      subscriptionHasSLED: subscriptionHasSLED || false,
      subscriptionHasPrimeIngestion: subscriptionHasPrimeIngestion || false,
      subscriptionHasPrimeAutomations: subscriptionHasPrimeAutomations || false,
      subscriptionHasSouptools: subscriptionHasSouptools || false,
      subscriptionHasApiIntegrations: subscriptionHasApiIntegrations || false
    },
    validationSchema: yup.object().shape({
      primaryDomain: yup
        .string()
        .required('Primary domain is required.')
        .matches(/\w+\.\w+/, { message: 'Primary domain is invalid.', excludeEmptyString: false }),
      approvedDomains: yup.array().of(
        yup
          .string()
          .required('At lease one approved domain is required.')
          .matches(/\w+\.\w+/, { message: 'One or more domains are invalid.', excludeEmptyString: false })
      )
    }),
    onSubmit: async values => {
      try {
        const { id, ...body } = values;
        if (id && hasId(values)) {
          await updateMutation({ id, organization: body }).unwrap();
          successToast();
        } else {
          const json = await createMutation({ organization: body }).unwrap();
          navigate(`/settings/organization_settings?current_organization_id=${json.id}`);
        }
      } catch (resp) {
        errorToast(resp);
      }
    }
  };

  return (
    <Formik {...form}>
      {({ values, setFieldValue }) => (
        <Form>
          <Card title={id ? name : 'Create Organization'}>
            <CardBody>
              {id && <Tag large>{`ID: ${id}`}</Tag>}
              <TextInput name="name" label="Name" />
            </CardBody>

            <CardBody>
              <Callout intent="danger">Subscription settings</Callout>
              {subscriptionTrialEndsAt && (
                <Tag large intent="danger">{`Current trial ends on ${new Date(
                  subscriptionTrialEndsAt
                ).toLocaleDateString()}`}</Tag>
              )}
              <RadioGroupInput
                label="Subscription Type"
                name="subscriptionType"
                inline
                options={subscriptionTypesEnum.map(type => ({ label: startCase(type), value: type }))}
                beforeChange={type => onSubscriptionTypeChange(type, setFieldValue)}
              />
              <div className="flex space-x-4">
                <NumericInput
                  name="subscriptionTrialLengthInDays"
                  label="Trial Length (Days)"
                  disabled={values.subscriptionType !== 'trial'}
                />
                <NumericInput name="subscriptionMaxPaidSeatCount" label="Max Seat Count" />
              </div>
              <div className="space-y-4">
                <SwitchInput name="subscriptionHasNetwork" label="Subscription has network access?" />
                <SwitchInput name="subscriptionHasSLED" label="Subscription has SLED?" />
                <SwitchInput name="subscriptionHasPrimeIngestion" label="Subscription has Prime ingestion?" />
                <SwitchInput name="subscriptionHasPrimeAutomations" label="Subscription has Prime automations?" />
                <SwitchInput name="subscriptionHasSouptools" label="Subscription has Souptools?" />
                <SwitchInput name="subscriptionHasApiIntegrations" label="Subscription has API Integration?" />
              </div>
            </CardBody>

            <CardBody>
              <Callout intent="warning">System Admin settings</Callout>
              <SwitchInput name="internal" label="Is an internal testing organization?" />
              <SwitchInput
                name="settings.preventOppAttachmentsInEmails"
                label="Prevent opportunity attachments from being attached to emails sent by Govly."
              />
              <SwitchInput
                name="settings.compliancePreventUploads"
                label="Prevent CUI uploads. If enabled, users will not be able to upload files other than avatars and logos."
              />
              <SwitchInput
                name="settings.canViewFilesInBrowser"
                label="Default to view opportunity attachments in browser instead of downloading."
              />
              <TextInput
                name="primaryDomain"
                label="Primary Domain"
                helperText="Domain used for identifying same domain signup."
              />
              <TagInput
                name="approvedDomains"
                label="Approved Domains"
                helperText="Domains used for authorizing the addition of new users via the UI."
              />
            </CardBody>
            <CardBody>
              <Callout intent="primary">SSO Settings</Callout>

              <SwitchInput
                name="ssoEnabled"
                label="Enables SSO for the organization. If enabled, users with a workos configured domain will be required to login via SSO."
              />
              <TagInput
                name="workosDomains"
                label="WorkOS Domains (SSO)"
                helperText="Domain used for matching an SSO config in WorkOS. This domain will likely be the same as 'Primary Domain'. Leave blank unless an enterprise customer using SSO."
              />
              <TextInput
                name="workosOrganizationId"
                label="WorkOS Organization ID (SSO)"
                helperText="WorkOS ID used for matching an SSO config. LEAVE BLANK UNLESS an enterprise customer using SSO. If either WorkOS inputs are filled out and the SSO config is not found, the user will not be able to login."
              />
            </CardBody>
            <CardBody>
              <Callout>Deprecated Features</Callout>
              <SwitchInput name="settings.hasConflictNotifications" label="Has conflict notifications?" />
              {values.settings.hasConflictNotifications && (
                <TextInput
                  name="settings.conflictNotificationEmail"
                  label="Conflict Notification Team Email"
                  type="email"
                  className="sm:w-2/3"
                />
              )}
            </CardBody>
            <CardFooter>
              <Button intent="primary" type="submit" large loading={isCreating || isUpdating}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
