import React from 'react';
import { useGetGovernmentEntityQuery } from 'api/governmentEntitiesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { H2 } from 'app/atoms/Typography/Typography';
import { GovernmentEntityLogo } from '../utils';

export const Title = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="flex-row" />;
  if (!governmentEntity) return null;

  return (
    <div className="flex items-center">
      <GovernmentEntityLogo entity={governmentEntity} className="h-12 w-12" />
      <H2>{governmentEntity.name}</H2>
    </div>
  );
};
