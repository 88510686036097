import { Feed, FeedShow } from 'types/__generated__/GovlyApi';

export const fedFeedComparator =
  (sort: 'none' | 'asc' | 'desc') =>
  <T extends (Feed | FeedShow) & { activated: boolean; owned: boolean }>(a: T, b: T): number => {
    if (sort === 'none') {
      const fields: { field: keyof T; ifA: number; ifB: number }[] = [
        { field: 'owned', ifA: -1, ifB: 1 },
        { field: 'activated', ifA: -1, ifB: 1 },
        { field: 'featuredInFeedSearch', ifA: -1, ifB: 1 },
        { field: 'public', ifA: 1, ifB: -1 }
      ];

      for (const { field, ifA, ifB } of fields) {
        if (a[field] && !b[field]) return ifA;
        if (b[field] && !a[field]) return ifB;
      }

      return a.name.localeCompare(b.name);
    }

    return sort === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
  };
