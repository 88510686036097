import React from 'react';
import { FormGroup, FormGroupProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

export type InputFormGroupProps = {
  name?: string;
  label?: string | React.ReactNode;
} & FormGroupProps;

export const InputFormGroup = ({ name, label, helperText, className, children, ...rest }: InputFormGroupProps) => {
  if (!label && !helperText) {
    return React.isValidElement(children) ? children : <>{children}</>;
  }

  return (
    <FormGroup className={cn('m-0', className)} label={label} labelFor={name} helperText={helperText} {...rest}>
      {children}
    </FormGroup>
  );
};
