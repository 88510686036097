import React from 'react';

import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';

export interface FilterFormProps {
  filter: string;
  setFilter: (filter: string) => void;
}

export const GovlyTableFilterForm: React.FC<FilterFormProps> = ({ filter, setFilter }) => {
  const { table } = useGovlyTableContext();
  return (
    <Formik
      initialValues={{ filter }}
      onSubmit={({ filter }) => {
        table.setPageIndex(0);
        setFilter(filter);
      }}
    >
      {({ setFieldValue }) => (
        <Form className="flex items-stretch">
          <TextInput
            inline
            name="filter"
            inputProps={{
              small: true,
              placeholder: 'Filter',
              leftElement: filter ? (
                <Button
                  small
                  minimal
                  intent="warning"
                  icon="small-cross"
                  onClick={() => {
                    table.setPageIndex(0);
                    setFieldValue('filter', '');
                    setFilter('');
                  }}
                />
              ) : undefined,
              rightElement: <Button small minimal type="submit" icon="search" intent="primary" />
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
