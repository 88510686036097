import React from 'react';
import { ButtonGroup, Button, MenuItem } from '@blueprintjs/core';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';
import { Select } from '@blueprintjs/select';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { TextLoading } from 'app/atoms/Loading/Loading';
import { useGovlyTablePagination } from './useGovlyTablePagination';

export const GovlyTablePagination = () => {
  const { table, hasConfigurablePageSize, paginationSteps, id, isLoading } = useGovlyTableContext();
  const { trackEvent } = useEventTracking();

  const { total, currentRangeStart, currentRangeEnd, pageSize } = useGovlyTablePagination();

  if (total <= pageSize) return null;

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-2 items-center">
        {isLoading ? (
          <TextLoading>1-30 of 10000</TextLoading>
        ) : (
          <div className="text-muted">{`${currentRangeStart}-${currentRangeEnd} of ${total}`}</div>
        )}
        {hasConfigurablePageSize && (
          <Select
            itemRenderer={(item, { handleClick, modifiers }) => (
              <MenuItem key={item} text={item} active={modifiers.active} onClick={handleClick} />
            )}
            disabled={isLoading}
            activeItem={pageSize}
            filterable={false}
            items={paginationSteps}
            onItemSelect={pageSize => {
              trackEvent({
                object: id,
                action: 'change_page_size',
                properties: { pageSize }
              });
              table.setPageSize(pageSize);
            }}
          >
            <Button text={`${pageSize} per page`} rightIcon="double-caret-vertical" small />
          </Select>
        )}
      </div>

      <ButtonGroup alignText="center" large minimal>
        <Button
          icon="chevron-backward"
          aria-label="first page"
          disabled={!table.getCanPreviousPage() || isLoading}
          onClick={() => {
            trackEvent({ object: id, action: 'first_page' });
            table.firstPage();
          }}
        />
        <Button
          icon="chevron-left"
          aria-label="previous page"
          disabled={!table.getCanPreviousPage() || isLoading}
          onClick={() => {
            trackEvent({ object: id, action: 'previous_page' });
            table.previousPage();
          }}
        />
        <Button
          icon="chevron-right"
          aria-label="next page"
          disabled={!table.getCanNextPage() || isLoading}
          onClick={() => {
            trackEvent({ object: id, action: 'next_page' });
            table.nextPage();
          }}
        />
        <Button
          icon="chevron-forward"
          aria-label="last page"
          disabled={!table.getCanNextPage() || isLoading}
          onClick={() => {
            trackEvent({ object: id, action: 'last_page' });
            table.lastPage();
          }}
        />
      </ButtonGroup>
    </div>
  );
};
