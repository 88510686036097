import { ContractVehicle, ContractVehicleShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['ContractVehicle'] });

function isContractVehicleShow(
  contractVehicles?: ContractVehicle[] | ContractVehicleShow[]
): contractVehicles is ContractVehicleShow[] {
  return contractVehicles?.[0]?.__typename === 'ContractVehicleShow';
}

type GetContractVehicles = {
  params: { filter?: 'all' | 'searchable'; view?: 'solicitor' | 'show' } | void;
  result: ContractVehicle[] | ContractVehicleShow[];
};

type GetContractVehicle = {
  params: { id: string };
  result: ContractVehicleShow;
};

export const contractVehiclesApi = api.injectEndpoints({
  endpoints: build => ({
    getContractVehicles: build.query<GetContractVehicles['result'], GetContractVehicles['params']>({
      query: params => ({ url: '/v2/contract_vehicles', params: params ? params : undefined }),
      providesTags: result => [
        'ContractVehicle',
        ...(isContractVehicleShow(result) ? result.map(cv => ({ type: 'ContractVehicle' as const, id: cv.id })) : [])
      ]
    }),

    getContractVehicle: build.query<GetContractVehicle['result'], GetContractVehicle['params']>({
      query: ({ id }) => ({ url: `/v2/contract_vehicles/${id}` }),
      providesTags: ['ContractVehicle']
    })
  })
});

export const { useGetContractVehiclesQuery, useGetContractVehicleQuery } = contractVehiclesApi;
