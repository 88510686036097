import React, { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ButtonGroup, Drawer, Tag } from '@blueprintjs/core';
import { DeepKeys, PaginationState, SortingState, VisibilityState, createColumnHelper } from '@tanstack/react-table';
import { GovlyTableProps, GovlyTableRoot } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableCard } from 'app/molecules/GovlyTable/GovlyTableCard';
import { getFiltersCount, getNonDefaultFilters } from 'app/hooks/useGetFormikFilterCountByField';
import { pluralize } from 'app/lib/strings';
import { OpenInNewTabButton } from 'app/molecules/OpenInNewTabButton/OpenInNewTabButton';
import { SearchResultsExportButton } from 'app/organisms/SearchResults/SearchResultsExportButton';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { OppSearchResult } from 'types/__generated__/GovlyApi';
import { useOppSearchQuery } from 'api/oppsApi';
import { SetAsideTag } from 'app/organisms/SetAsideTag/SetAsideTag';
import { ScheduledDateTag } from 'app/molecules/ScheduledDateTag/ScheduledDateTag';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { GovlyTableOverflowCell } from 'app/molecules/GovlyTable/GovlyTableOverflowCell';

const columnHelper = createColumnHelper<OppSearchResult>();

type Props = {
  onClose?: () => void;
  filters?: Partial<OppSearchState['filters']>;
  drawerTitle?: string | React.ReactNode;
  isOpen?: boolean;
  columnVisibility?: { [k in DeepKeys<OppSearchResult>]?: boolean };
} & Pick<GovlyTableProps<OppSearchResult>, 'id'>;

export const OppSearchAnalyticsTableDrawer = ({
  onClose,
  filters: filtersProp,
  drawerTitle,
  isOpen,
  id,
  columnVisibility
}: Props) => {
  const { values } = useFormikContext<OppSearchState>();
  const filtersCount = getFiltersCount(values, v => v.filters);
  const { query, filters } = values;

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 30,
    pageIndex: 0
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'periodOfPerformanceStartDate',
      desc: true
    }
  ]);

  const [sort] = sorting.map(
    ({ id, desc }): Pick<OppSearchState['filters'], 'sort' | 'sortDirection'> => ({
      sort: id as OppSearchState['filters']['sort'],
      sortDirection: desc ? 'desc' : 'asc'
    })
  );

  const tableResult = useOppSearchQuery(
    {
      query,
      ...filters,
      ...sort,
      ...filtersProp,
      page: pagination.pageIndex + 1,
      per: pagination.pageSize
    },
    { skip: !isOpen }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('displayName', {
        header: 'ID',
        cell: e => (
          <LinkTag
            tag="a"
            href={`/opportunities/${e.row.original.id}`}
            target="_blank"
            className="max-w-32 inline-block truncate text-left"
          >
            {e.getValue()}
          </LinkTag>
        )
      }),
      columnHelper.accessor('title', {
        header: 'Title',
        cell: e => <GovlyTableOverflowCell context={e} />
      }),
      columnHelper.display({
        id: 'status',
        header: 'Status',
        size: 240,
        cell: e => (
          <ScheduledDateTag
            respondBy={e.row.original.respondBy}
            awardedAt={e.row.original.awardedAt}
            cancelledAt={e.row.original.cancelledAt}
          />
        )
      }),
      columnHelper.accessor('feedName', {
        header: 'Feed'
      }),
      columnHelper.accessor('setAside', {
        header: 'Set Asides',
        cell: e => <SetAsideTag code={e.getValue()} />
      }),
      columnHelper.accessor('region', {
        header: 'Location'
      }),
      columnHelper.accessor('buyerName', {
        header: 'Buyer',
        cell: e => <GovlyTableOverflowCell context={e} />
      })
    ],
    []
  );

  const viewInSearchPath = useMemo(() => {
    const searchParams = new URLSearchParams();
    const nonDefaultFilters = getNonDefaultFilters(values, v => v.filters);
    const combinedFilters = [...nonDefaultFilters, ...Object.entries(filtersProp ?? {})];
    searchParams.append(`query`, encodeURIComponent(query));
    combinedFilters.forEach(([filter, value]) => {
      searchParams.append(`filters.${filter}`, encodeURIComponent(JSON.stringify(value)));
    });
    return `/opportunities?${searchParams.toString()}`;
  }, [filtersProp, query, values]);

  return (
    <GovlyTableRoot
      id={id}
      data={tableResult.data?.results ?? []}
      isLoading={tableResult.isLoading}
      hasConfigurablePageSize
      columns={columns}
      isFixedLayout
      paginationSteps={[20, 50, 100]}
      initialState={{ pagination: { pageSize: 30 } }}
      enableColumnFilters={false}
      manualSorting
      manualPagination
      manualFiltering
      state={{ sorting, pagination, columnVisibility: columnVisibility as VisibilityState }}
      onSortingChange={setSorting}
      onPaginationChange={setPagination}
      rowCount={tableResult.data?.meta.total ?? 0}
      enableHiding={false}
    >
      <Drawer
        enforceFocus={false}
        isOpen={isOpen}
        onClose={() => {
          onClose?.();
        }}
        position="bottom"
        title={
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              {typeof drawerTitle === 'string' ? <span>{drawerTitle}</span> : drawerTitle}
              {filtersCount > 0 && (
                <Tag minimal>
                  +{filtersCount} custom {pluralize('filter', 'filters', filtersCount)}
                </Tag>
              )}
            </span>

            <ButtonGroup>
              <OpenInNewTabButton path={viewInSearchPath} text="Full Results View" />

              <SearchResultsExportButton
                query={query}
                filters={{
                  ...filters,
                  ...sort,
                  ...filtersProp
                }}
                resultType="opps"
              />
            </ButtonGroup>
          </div>
        }
      >
        <div className="h-full overflow-y-scroll">
          <GovlyTableCard />
        </div>
      </Drawer>
    </GovlyTableRoot>
  );
};
