import { useRef, useLayoutEffect, useMemo } from 'react';
import { MenuItemProps } from '@blueprintjs/core';

import { useFormikInput } from 'app/hooks/useFormikInput';
import { InputMultiSelectProps } from 'app/molecules/InputMultiSelect/InputMultiSelect';

type Args<Item> = Partial<InputMultiSelectProps<Item>> & {
  name: string;
  items: Item[];
  getItemValue: (item: Item) => string | number;
  submitOnChange?: boolean;
  menuItemProps?: Partial<MenuItemProps>;
  modifyItems?: (items: Item[]) => Item[];
};

export type { Args as UseFormikMultiSelectArgs };

export function useFormikMultiSelect<Item>({
  name,
  submitOnChange,
  getItemValue,
  items: allItems,
  modifyItems
}: Args<Item>) {
  const { onChange, field, ...rest } = useFormikInput<(string | number)[]>({ name, submitOnChange });

  const modifyItemsRef = useRef(modifyItems);
  useLayoutEffect(() => {
    modifyItemsRef.current = modifyItems;
  });

  const items = useMemo(() => (modifyItemsRef.current ? modifyItemsRef.current(allItems) : allItems), [allItems]);

  const getItemValueRef = useRef(getItemValue);
  useLayoutEffect(() => {
    getItemValueRef.current = getItemValue;
  });

  const valuesCache = useMemo(() => {
    const newValuesCache: Record<string | number, Item> = {};
    return items.reduce((acc, item) => {
      const value = getItemValueRef.current(item);
      acc[value] = item;
      return acc;
    }, newValuesCache);
  }, [items]);

  const discreteOnChange = (items: Item[]) => {
    const values = items.map(getItemValueRef.current);
    onChange(values);
  };

  const selectedItems = field.value.map(value => valuesCache[value]).filter((item): item is Item => Boolean(item));

  return {
    ...rest,
    name,
    onChange: discreteOnChange,
    selectedItems,
    items,
    field
  };
}
