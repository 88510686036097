import React from 'react';

import { cn } from 'app/lib/cn';

type DatalistLeftAlignedProps = {
  items: {
    label: string;
    key?: string;
    value?: string | React.JSX.Element | React.JSX.Element[];
    className?: string;
  }[];
  dlClassName?: string;
  excludeNullRows?: boolean;
};

const valueIsNully = (value?: string | React.JSX.Element | React.JSX.Element[]) => {
  if (!value) {
    return true;
  }

  if (typeof value === 'string' && value === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
};

export const DatalistLeftAligned = ({ items, dlClassName, excludeNullRows }: DatalistLeftAlignedProps) => {
  return (
    <dl className={cn('px-4 sm:divide-y sm:divide-gray-200 sm:px-0', dlClassName)}>
      {items
        .filter(item => item.value)
        .map(item => {
          if (excludeNullRows && valueIsNully(item.value)) {
            return null;
          }

          return (
            <div key={item.key || item.label} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
              <dd className={cn('mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0', item.className)}>{item.value}</dd>
            </div>
          );
        })}
    </dl>
  );
};
