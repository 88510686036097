import { useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { NavItem } from './AuthenticatedNavItemsUtils';

export function useSelectNavItem() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  return useCallback(
    (node: NavItem) => {
      const resolvedNode: typeof node = { ...node, to: replaceDynamicVariables(node.to, params) };

      // Preserve savedSearchId when navigating
      const currentSearchParams = new URLSearchParams(location.search);
      const savedSearchId = currentSearchParams.get('savedSearchId');
      const newTo = savedSearchId ? `${resolvedNode.to}?savedSearchId=${savedSearchId}` : resolvedNode.to;

      if (resolvedNode?.absolute && resolvedNode?.external) {
        window.open(`${window.location.origin}/${newTo}`);
      } else if (resolvedNode?.absolute) {
        window.location.pathname = `/${newTo}`;
      } else {
        navigate(String(newTo));
      }
    },
    [navigate, params, location.search]
  );
}

function replaceDynamicVariables(str: string, params: Record<string, string | undefined>): string {
  return str.replace(/:([a-zA-Z]+)/g, (_, key) => {
    return params[key] !== undefined ? params[key] : `:${key}`;
  });
}
