import { OrganizationUserCurrentUser } from 'types/__generated__/GovlyApi';

type WelcomeMessageArgs = {
  user?: OrganizationUserCurrentUser;
};

const standardWelcomeMessage = (user: WelcomeMessageArgs['user']) => () => {
  return `Hi ${user?.firstName || 'there'}! I can assist you in transforming your search criteria for government procurement opportunities into a boolean logic query. By doing this, I help streamline your search process, ensuring you find the most relevant contracting opportunities efficiently. Whether you're looking for specific products, services, or categories within the government procurement space, I can help craft a query that reflects your requirements and avoids unnecessary results. If you have a specific search in mind, let me know, and I'll create a tailored query for you.${'\n\n```userQuery\n\n```'}`;
};

const refreshWelcomeMessage = (user: WelcomeMessageArgs['user']) => {
  let callNum = 0;

  return () => {
    const evenCall = `Hi ${user?.firstName || 'there'}! I'm here to help you find relevant opportunities. Let me know how I can help with building the query.${'\n\n```userQuery\n\n```'}`;
    const oddCall = `Hi ${user?.firstName || 'there'}! Lets find you some opportunities. Let me know how I can assist you with your next query.${'\n\n```userQuery\n\n```'}`;
    const message = callNum % 2 === 0 ? evenCall : oddCall;
    callNum++;
    return message;
  };
};

export const getWelcomeMessages = (user: WelcomeMessageArgs['user']) => ({
  standardWelcomeMessage: standardWelcomeMessage(user),
  refreshWelcomeMessage: refreshWelcomeMessage(user)
});
