import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button, RadioGroup, Radio, Alert } from '@blueprintjs/core';
import { Formik, Form, useField, useFormikContext } from 'formik';
import { Editor } from 'app/molecules/Editor/Editor';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { CommentShow, FollowDisplayable, OrganizationUserFollow } from 'types/__generated__/GovlyApi';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { cn } from 'app/lib/cn';
import { useGetWorkspaceAttachmentsQuery } from 'api/workspacesAttachmentsApi';
import { OnAttachArgs } from 'app/hooks/useOnBulkAttach';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { useActiveStorageOnDrop } from 'app/hooks/useActiveStorageOnDrop';

type FormValues = {
  id: string;
  content: string;
  organizationUserId: string;
  commentableType: string;
  oppId: string;
  commentableId: string;
  follows: Pick<
    FollowDisplayable,
    'organizationUserId' | 'notifications' | 'state' | 'createdById' | 'organizationUser'
  >[];
  notifyIds: string[];
  attachments: { name: string; signedId: string }[];
};

const NEW_COMMENT_ID = 'new-comment';

type OppWorkspaceCommentFormProps = {
  onSubmit: (
    values: Omit<FormValues, 'follows' | 'attachments'> & { attachmentSignedIds: string[]; notifyIds: string[] }
  ) => { unwrap: () => Promise<unknown> };
  oppId: string;
  workspaceId: string;
  comment?: CommentShow;
  afterSubmit?: () => void;
  isLoading: boolean;
  autoFocus?: boolean;
};

export const OppWorkspaceCommentForm = ({
  comment,
  workspaceId,
  oppId,
  onSubmit,
  afterSubmit = () => {},
  isLoading,
  autoFocus = false
}: OppWorkspaceCommentFormProps) => {
  const [formState, setFormState] = useState<'idle' | 'uploading' | 'confirming'>('idle');
  const [mentions, setMentions] = useState<string[]>([]);
  const [notifyGroup, setNotifyGroup] = useState('followers');

  const editorRef = useRef<HTMLTextAreaElement>(null);

  const currentUserId = useCurrentUserAttribute('id');
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: { follows = [] } = {}, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({
    id: workspaceId
  });
  const { commentAttachments } = useGetWorkspaceAttachmentsQuery(
    { id: comment?.commentableId ?? '' },
    {
      skip: !comment,
      selectFromResult: ({ data }) => ({
        commentAttachments:
          data
            ?.filter(a => a.commentId === comment?.id)
            .map(({ attachment }) => attachment)
            .map(attachment => ({ signedId: attachment.signedId ?? '', name: attachment.name ?? '' })) ?? []
      })
    }
  );

  const toFollow = useCallback(
    (organizationUser: OrganizationUserFollow) => ({
      organizationUserId: organizationUser.id,
      notifications: 'user_setting' as const,
      state: 'following' as const,
      createdById: currentUserId,
      organizationUser
    }),
    [currentUserId]
  );

  const notifiableUsers = useMemo(
    () =>
      follows
        .filter(f => f.organizationUserId !== currentUserId)
        .map(({ organizationUser }) => organizationUser)
        .filter(user => user !== undefined),
    [currentUserId, follows]
  );

  if (workspaceLoading) {
    return null;
  }

  return (
    <Formik<FormValues>
      onSubmit={async (values, { resetForm }) => {
        try {
          const { follows = [], id = '', attachments, ...rest } = values;
          const signedIds = attachments.map(({ signedId }) => signedId);

          let notifyIds: string[] = [];

          if (notifyGroup === 'followers') {
            notifyIds = notifiableUsers.map(({ id }) => id);
          }

          if (notifyGroup === 'select') {
            notifyIds = follows.map(({ organizationUserId }) => organizationUserId);
          }

          await onSubmit({ ...rest, id, notifyIds, attachmentSignedIds: signedIds }).unwrap();
          setNotifyGroup('followers');

          window.sessionStorage.removeItem(`commentDraft-${workspaceId}`);
          if (values.id !== NEW_COMMENT_ID) {
            successToast('Message updated.');
          } else {
            resetForm();
            if (editorRef.current) {
              editorRef.current.value = '';
            }
            successToast('Message posted.');
          }

          setMentions([]);
          afterSubmit();
        } catch (error) {
          formErrorToast(error as number);
        }
      }}
      initialValues={{
        id: comment?.id ?? NEW_COMMENT_ID,
        content:
          comment?.contentRaw || JSON.parse(window.sessionStorage.getItem(`commentDraft-${workspaceId}`) || '""') || '',
        organizationUserId: currentUserId,
        commentableType: 'Workspace',
        oppId,
        commentableId: workspaceId,
        follows: notifiableUsers.filter(({ email }) => mentions.includes(email)).map(toFollow),
        notifyIds: [],
        attachments: commentAttachments
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitForm }) => (
        <Form>
          <Dropzone
            onAttach={() => setFormState('idle')}
            workspaceId={workspaceId}
            canDrop={!currentUser?.compliancePreventUploads}
            onInitialize={() => setFormState('uploading')}
          >
            <Editor
              id={comment?.id ?? NEW_COMMENT_ID}
              onChange={event => {
                const newValue = event.target?.value;
                setFieldValue('content', newValue);
                if (!comment?.id) {
                  window.sessionStorage.setItem(`commentDraft-${workspaceId}`, JSON.stringify(newValue));
                }
              }}
              inputProps={{ value: values.content }}
              ref={editorRef}
              notifiableUsers={notifiableUsers}
              setMentions={setMentions}
              trixEditorProps={{ placeholder: 'Write a new message...', autoFocus }}
              hideUploads
              formGroupProps={{
                className: 'mb-0',
                contentClassName: 'mt-0',
                labelFor: 'comment'
              }}
            />

            <div className="space-y-2">
              <div className="flex justify-between flex-row-reverse">
                <Button
                  intent="primary"
                  loading={isSubmitting || isLoading}
                  disabled={formState === 'uploading' || !(values.attachments.length > 0 || values.content.length > 0)}
                  onClick={() => {
                    if (notifiableUsers.length > 0) {
                      setFormState('confirming');
                    } else {
                      submitForm();
                    }
                  }}
                >
                  Submit
                </Button>

                {currentUser?.compliancePreventUploads ? null : (
                  <FileUploader
                    id={comment?.id || NEW_COMMENT_ID}
                    multiple
                    onInitialize={() => setFormState('uploading')}
                    onBulkAttach={async attachments => {
                      setFormState('idle');
                      setFieldValue('attachments', [...values.attachments, ...attachments]);
                    }}
                  >
                    {({ isDragActive }) => (
                      <Button
                        outlined
                        icon="paperclip"
                        className={cn(isDragActive ? 'outline-dashed outline-blue-500' : '')}
                      >
                        Add Attachments
                      </Button>
                    )}
                  </FileUploader>
                )}
              </div>

              {values.attachments.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {values.attachments.map((attachment, index) => (
                    <div key={index} className="flex items-center gap-x-2">
                      <AttachmentEntityTitle
                        {...{
                          extension: attachment.name.split('.').pop() ?? '',
                          url: '',
                          filename: attachment.name
                        }}
                        actionsButtonGroup={
                          <Button
                            aria-label="Remove attachment"
                            icon="small-cross"
                            intent="danger"
                            small
                            onClick={() => {
                              setFieldValue(
                                'attachments',
                                values.attachments.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </Dropzone>
          <Alert
            isOpen={formState === 'confirming'}
            confirmButtonText="Submit"
            cancelButtonText="Cancel"
            intent="primary"
            onClose={() => setFormState('idle')}
            onConfirm={() => {
              submitForm();
            }}
            loading={isSubmitting}
          >
            {notifiableUsers.length > 0 && (
              <div className="flex flex-col">
                <p className="mb-2 text-base font-medium">When I post this, notify...</p>
                <RadioGroup selectedValue={notifyGroup} onChange={e => setNotifyGroup(e.currentTarget.value)}>
                  <Radio label="Everyone in the workspace" value="followers" />
                  <Radio label="No one" value="nobody" />
                  <Radio label="Only the people I select..." value="select" />
                </RadioGroup>
                <FollowerSelect
                  mentions={mentions}
                  notifiableUsers={notifiableUsers}
                  setNotifyGroup={setNotifyGroup}
                  toFollow={toFollow}
                  notifyGroup={notifyGroup}
                />
              </div>
            )}
          </Alert>
        </Form>
      )}
    </Formik>
  );
};

const FollowerSelect = ({
  notifiableUsers,
  mentions,
  setNotifyGroup,
  toFollow,
  notifyGroup
}: {
  notifiableUsers: OrganizationUserFollow[];
  mentions: string[];
  notifyGroup: string;
  setNotifyGroup: (value: string) => void;
  toFollow: (organizationUser: OrganizationUserFollow) => FormValues['follows'][number];
}) => {
  const [_field, _meta, helpers] = useField('follows');

  useEffect(() => {
    if (mentions.length > 0) {
      setNotifyGroup('select');
      helpers.setValue(notifiableUsers.filter(({ email }) => mentions.includes(email)).map(toFollow));
    }
  }, [mentions, notifiableUsers, helpers, setNotifyGroup, toFollow]);

  return notifyGroup === 'select' ? (
    <FollowerSelectInput
      hideFollowSettings
      name="follows"
      defaultButtonText="Select recipients"
      organizationUsers={notifiableUsers}
    />
  ) : null;
};

type DropItem = {
  files: File[];
};

const Dropzone = ({
  children,
  onAttach,
  workspaceId,
  canDrop: canDropProp,
  onInitialize
}: React.PropsWithChildren<{
  onAttach: () => void;
  workspaceId: string;
  canDrop: boolean;
  onInitialize: (file?: File) => void;
}>) => {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const handleBulkAttach = async (attachments: OnAttachArgs[]) => {
    onAttach();
    setFieldValue('attachments', [...values.attachments, ...attachments]);
  };

  const { onDrop } = useActiveStorageOnDrop({ uploaderId: workspaceId, onBulkAttach: handleBulkAttach, onInitialize });

  const [{ canDrop, isOver }, ref] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: DropItem) {
        onDrop(item.files);
      },
      canDrop(_item: DropItem) {
        return canDropProp;
      },
      collect: (monitor: DropTargetMonitor<DropItem>) => {
        return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
      }
    }),
    [canDropProp]
  );

  const isActive = canDrop && isOver;
  return (
    <div ref={ref} className="flex flex-col gap-y-4">
      {children}

      {isActive ? (
        <span className="block absolute top-0 left-0 w-full h-full border-2 border-dashed border-blue-500" />
      ) : null}
    </div>
  );
};
