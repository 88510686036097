import { colors } from 'app/lib/colors';
import { roundToSignificant } from 'app/lib/numbers';
import { FIPS_TO_STATE_ABBR } from 'app/lib/usStates';
import { ExpressionSpecification, GeoJSONFeature } from 'mapbox-gl';

export const MAPBOX_STYLE_URL = 'mapbox://styles/govly/clzughq5c00sr01pdaxtf78zq';

export type FeatureWithProperties<Properties> = Omit<GeoJSONFeature, 'properties'> & {
  properties: Properties;
};

export type CongressionalDistrictFeature = FeatureWithProperties<{
  ALAND: number;
  AWATER: number;
  CD118FP: string;
  CDSESSN: string;
  GEOID: string;
  GEOIDFQ: string;
  LSAD: string;
  NAMELSAD: string;
  STATEFP: string;
}>;

export type StateFeature = FeatureWithProperties<{
  ALAND: number;
  AWATER: number;
  GEOID: string;
  GEOIDFQ: string;
  LSAD: string;
  NAME: string;
  STATEFP: string;
  STATENS: string;
  STUSPS: string;
}>;

const getCDKey = (feature: GeoJSONFeature) => {
  const { CD118FP: cd, STATEFP: fips } = (feature as CongressionalDistrictFeature).properties;
  return `${FIPS_TO_STATE_ABBR[fips as keyof typeof FIPS_TO_STATE_ABBR]}-${cd}`;
};

export type TileLayer = {
  url: string;
  sourceLayer: string;
  source: string;
  colorScheme: keyof typeof colors;
  getFeatureKey: (feature: GeoJSONFeature) => string;
  getFeatureLabel: (feature: GeoJSONFeature) => string;
  filter: ExpressionSpecification;
};

export const TILE_LAYERS = {
  state: {
    url: 'mapbox://govly.ce75b4o9',
    sourceLayer: 'cb_2023_us_state_20m-9a4c6z',
    source: 'states',
    colorScheme: 'blue',
    getFeatureKey: (feature: GeoJSONFeature) => (feature as StateFeature).properties.NAME.toUpperCase(),
    getFeatureLabel: (feature: GeoJSONFeature) => (feature as StateFeature).properties.NAME,
    filter: ['has', 'NAME' satisfies keyof StateFeature['properties']]
  },
  congressionalDistrict: {
    url: 'mapbox://govly.3s1n03sr',
    sourceLayer: 'cb_2023_us_cd118_20m-15umum',
    source: 'congressional-districts',
    colorScheme: 'green',
    getFeatureKey: getCDKey,
    getFeatureLabel: getCDKey,
    filter: ['has', 'CD118FP' satisfies keyof CongressionalDistrictFeature['properties']]
  }
} satisfies Record<'state' | 'congressionalDistrict', TileLayer>;

export const getColorRange = ({
  data: dataProp,
  color,
  numSteps = 6
}: {
  data: number[];
  color: keyof typeof colors;
  numSteps?: number;
}) => {
  if (dataProp.length === 0) {
    return [colors[color]['50'], 1, colors[color]['900']];
  }

  const data = [...dataProp].sort((a, b) => b - a);
  const quantiles: number[] = [];
  for (let i = 1; i < numSteps; i++) {
    const qIndex = Math.ceil((data.length - 1) * (i / numSteps));
    quantiles.push(data[qIndex]);
  }

  const colorScale = Object.values(colors[color]);

  return [...new Set(quantiles)].reverse().reduce(
    (acc, step, index) => {
      const rounded = roundToSignificantDeduped(step, acc);
      return [...acc, rounded, colorScale[index + 1]];
    },
    [colorScale[0]] as (string | number)[]
  );
};

export const groupColorRange = (colorRange: (string | number)[]): [number, string, number?][] => {
  const [start, ...rest] = colorRange;
  return [0, start, ...rest].reduce(
    (acc, step, index, all) => {
      const [last] = acc.slice(-1);

      if (index % 2 === 0 && (!last || last.length === 3)) {
        acc.push([step as number, all[index + 1] as string, all[index + 2] as number | undefined]);
      }

      return acc;
    },
    [] as [number, string, number?][]
  );
};

export const getFillsKey = (source: string) => `${source}-fills`;
export const getBordersKey = (source: string) => `${source}-borders`;

const roundToSignificantDeduped = (num: number, arr: (string | number)[]) => {
  const rounded = roundToSignificant(num);

  if (arr.includes(rounded)) {
    return roundToSignificantDeduped(num + 1, arr);
  }

  return rounded;
};
