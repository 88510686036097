import { useEffect } from 'react';
import cable from 'api/actionCable/consumer';
import { useUpdateThreadMutation } from 'api/llmThreadsApi';
import { LlmAssistantMessageBreakout } from 'types/__generated__/GovlyApi';
import { usePostHog } from 'posthog-js/react';
import { useAssistantStore } from './useAssistantStore';

type AssistantProps = {
  threadId: string;
};

// TODO model message types with discriminated union `type` field
type ThreadChannelMessage = {
  files_processing?: boolean;
  messages_committed?: LlmAssistantMessageBreakout[];
  message_in_progress?: string;
  message_delta?: {
    id: string;
    delta: {
      content: {
        index: number;
        text: {
          value: string;
          annotations?: never[];
        };
      }[];
    };
  };
  message_complete?: string;
  execute_tool_call?: {
    call: string;
    arguments: unknown;
  };
  thread_run_started?: string;
  thread_run_completed?: string;
};

const useAssistant = ({ threadId }: AssistantProps) => {
  const posthog = usePostHog();
  const [updateThread] = useUpdateThreadMutation();
  const {
    messages,
    makeEmptyMessage,
    updateMessage,
    setMessages,
    updateMessageDelta,
    resetMessages,
    removeMessageByExternalId,
    setFilesProcessing
  } = useAssistantStore(threadId);

  useEffect(() => {
    const deltaBuffer: Map<string, string[]> = new Map();
    const deltaCallback = setInterval(() => {
      for (const messageId of deltaBuffer.keys()) {
        const delta = deltaBuffer.get(messageId)?.join('');
        deltaBuffer.delete(messageId);

        if (delta?.length) updateMessageDelta(messageId, delta);
      }
    }, 120);

    const channel = cable.subscriptions.create(
      { channel: 'Llm::Assistant::ThreadChannel', id: threadId },
      {
        received(data: ThreadChannelMessage) {
          if (data.thread_run_started) {
            // no message yet, but we know the thread has started
            const message = makeEmptyMessage('thread_run_started');
            updateMessage('thread_run_started', { ...message, role: 'assistant' });
          } else if (data.message_in_progress) {
            // remove the spinner not attached to a message
            removeMessageByExternalId('thread_run_started');
            // insert a blank placeholder
            const message = makeEmptyMessage(data.message_in_progress);
            updateMessage(data.message_in_progress, { ...message, role: 'assistant' });
          } else if (data.message_delta) {
            const messageId = data.message_delta.id;
            const delta = data.message_delta.delta.content.map(c => c.text.value).join('');

            if (!deltaBuffer.has(messageId)) {
              deltaBuffer.set(messageId, [delta]);
            } else {
              deltaBuffer.get(messageId)?.push(delta);
            }
          } else if (data.message_complete) {
            // no-op, we'll clear is partial on committed
          } else if (data.messages_committed) {
            // messages are complete at this point, upsert the full database version
            data.messages_committed.forEach(message => {
              if (message.externalId) {
                updateMessage(message.externalId, { ...message, isPartial: false });
              } else {
                // this should never be null
                console.warn('upstream sent a message without an external id?', message);
              }
            });
          } else if (data.execute_tool_call) {
            // no-op
            // in the future, maybe this shows the tool use in the thread
          } else if (data.thread_run_completed) {
            // no-op
            // the message may still be printing out, but the thread run is done
          } else if (data.files_processing != null) {
            setFilesProcessing(data.files_processing);
          } else {
            // should be unreachable
            console.warn('strange channel object, ignoring:', data);
          }
        }
      }
    );

    return () => {
      channel.unsubscribe();
      clearInterval(deltaCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  const sendNewMessage = async (content: string) => {
    try {
      await updateThread({ id: threadId, content }).unwrap();
      posthog.capture('assistant_message_sent', { threadId, message_content: content });
    } catch (err) {
      console.error('Failed to update thread:', err);
    }
  };

  return {
    sendNewMessage,
    messages,
    resetMessages,
    setMessages
  };
};

export { useAssistant };
