import { SouptoolsQuote, SouptoolsQuoteFull, Attachment, SouptoolsTrSubmission } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::Quote'] });

const provideTag = <Arg extends { id?: string }>(_result: unknown, _err: unknown, arg: Arg) => [
  { type: 'Souptools::Quote' as const, id: arg.id }
];

type GetSouptoolsQuotes = {
  params: {
    status?: string;
    page?: number;
  };
  result: {
    quotes: SouptoolsQuote[];
    meta: {
      currentPage: number;
      total: number;
      perPage: number;
      totalPages: number;
    };
  };
};

type GetSouptoolsQuote = {
  params: {
    id: string;
  };
  result: SouptoolsQuoteFull;
};

type CreateSouptoolsQuote = {
  params: {
    name: string;
  };
  result: SouptoolsQuote;
};

type UpdateSouptoolsQuote = {
  params: {
    id: string;
    status?: string;
    name?: string;
  };
  result: SouptoolsQuote;
};

type SubmitSouptoolsQuote = {
  params: {
    id: string;
    scheduledAt?: string;
  };
  result: SouptoolsTrSubmission;
};

type ExportSouptoolsClinFile = {
  params: {
    id: string;
    clinIds: string[];
  };
  result: Attachment;
};

type ArchiveSouptoolsQuote = {
  params: {
    id: string;
  };
  result: void;
};

export const souptoolsQuotesApi = api.injectEndpoints({
  endpoints: build => ({
    getSouptoolsQuotes: build.query<GetSouptoolsQuotes['result'], GetSouptoolsQuotes['params']>({
      providesTags: result => [
        'Souptools::Quote',
        ...(result?.quotes || []).map(({ id }) => ({ type: 'Souptools::Quote' as const, id }))
      ],
      query: params => ({
        url: '/v2/souptools/quotes',
        params
      })
    }),

    getSouptoolsQuote: build.query<GetSouptoolsQuote['result'], GetSouptoolsQuote['params']>({
      providesTags: provideTag,
      query: ({ id, ...params }) => ({
        url: `/v2/souptools/quotes/${id}`,
        params
      })
    }),

    createSouptoolsQuote: build.mutation<CreateSouptoolsQuote['result'], CreateSouptoolsQuote['params']>({
      invalidatesTags: ['Souptools::Quote'],
      query: body => ({
        url: `/v2/souptools/quotes/`,
        method: 'POST',
        body
      })
    }),

    updateSouptoolsQuote: build.mutation<UpdateSouptoolsQuote['result'], UpdateSouptoolsQuote['params']>({
      invalidatesTags: provideTag,
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/quotes/${id}`,
        method: 'PATCH',
        body
      })
    }),

    submitSouptoolsQuote: build.mutation<SubmitSouptoolsQuote['result'], SubmitSouptoolsQuote['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/quotes/${id}/tr_submissions`,
        method: 'POST',
        body
      })
    }),

    exportSouptoolsClinFile: build.mutation<ExportSouptoolsClinFile['result'], ExportSouptoolsClinFile['params']>({
      query: ({ id, clinIds }) => ({
        url: `/v2/souptools/quotes/${id}/clin_file`,
        method: 'POST',
        body: { clinIds }
      })
    }),

    archiveSouptoolsQuote: build.mutation<ArchiveSouptoolsQuote['result'], ArchiveSouptoolsQuote['params']>({
      query: ({ id }) => ({
        url: `/v2/souptools/quotes/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetSouptoolsQuotesQuery,
  useGetSouptoolsQuoteQuery,
  useUpdateSouptoolsQuoteMutation,
  useCreateSouptoolsQuoteMutation,
  useExportSouptoolsClinFileMutation
} = souptoolsQuotesApi;
