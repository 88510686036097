import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Tooltip, Tag } from '@blueprintjs/core';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { useUpdateFeedProfileMutation, useCreateFeedProfileMutation } from 'api/feedProfilesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { hasDataErrors, isFetchBaseQueryError } from 'api/utils';
import { hasId } from 'api/utils';

export const FeedProfileUnisonForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();
  const [updateFeedProfile, { isLoading: isUpdating }] = useUpdateFeedProfileMutation();
  const [createFeedProfile, { isLoading: isCreating }] = useCreateFeedProfileMutation();

  if (profileLoading) {
    return <Loading />;
  }

  const { feedProfiles: profiles = [] } = currentOrg ?? {};
  const profile = profiles.find(p => p.type === 'FeedProfile::Unison');

  const { id, username, password, notificationEmail, failedLoginAttempts = 0, active } = profile ?? {};

  const inactive = failedLoginAttempts > 3 || !active;

  let failedLoginAttemptsClass = failedLoginAttempts === 0 ? 'text-green-600' : 'text-orange-600';
  if (failedLoginAttempts > 3) failedLoginAttemptsClass = 'text-red-600';

  const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('A Password is required to run any job')
  });

  return (
    <>
      <Breadcrumbs />
      <Formik
        onSubmit={async (values, { setErrors }) => {
          try {
            if (hasId(values)) {
              await updateFeedProfile(values).unwrap();
              successToast('Profile updated.');
            } else {
              await createFeedProfile(values).unwrap();
              successToast('Profile created.');
            }
          } catch (e) {
            if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
              if (typeof e.status === 'number') formErrorToast(e.status);
              const { data } = e;
              if (data.errors) setErrors(data.errors);
            }
          }
        }}
        initialValues={{
          id,
          type: 'FeedProfile::Unison',
          username: username || '',
          password: password || '',
          notificationEmail: notificationEmail || '',
          active: active || false
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <Card
            className="mb-4"
            title="Unison Ingest Profile"
            rightElement={<Tag intent={inactive ? 'danger' : 'success'}>{inactive ? 'Disabled' : 'Active'}</Tag>}
          >
            <CardBody>
              <SwitchInput name="active" label="Active?" />
              <TextInput label="Unison Username" name="username" labelInfo="(required)" />
              <TextInput
                label="Unison Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                labelInfo="(required)"
                helperText={
                  <>
                    <p className={failedLoginAttemptsClass}>{`Failed login attempts: ${failedLoginAttempts}`}</p>
                    <span>
                      If failed login attempts exceeds 1, all jobs will be paused until the password is updated.
                    </span>
                  </>
                }
                inputProps={{
                  large: true,
                  rightElement: (
                    <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
                      <Button
                        icon={showPassword ? 'unlock' : 'lock'}
                        intent="warning"
                        minimal
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </Tooltip>
                  )
                }}
              />
              <TextInput label="Notification Email" name="notificationEmail" />
            </CardBody>
            <CardFooter>
              <Button large type="submit" text="Save" loading={isCreating || isUpdating} intent="primary" />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </>
  );
};
