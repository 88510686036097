import React, { useEffect, useRef, useState } from 'react';
import {
  useCreateWorkspaceAssistantThreadMutation,
  useGetWorkspaceAssistantThreadQuery
} from 'api/workspaceAssistantThreadsApi';
import { useEffectOnce } from 'app/hooks/useEffectOnce';
import { Tag, Icon, Tooltip, Button } from '@blueprintjs/core';
import { LlmAssistantThreadIndex, LlmAssistantThreadShow } from 'types/__generated__/GovlyApi';
import { cn } from 'app/lib/cn';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { Loading } from 'app/atoms/Loading/Loading';
import { useAssistant } from '../Assistant/useAssistant';
import { AssistantForm } from '../Assistant/AssistantForm';
import { AssistantHeader } from '../Assistant/AssistantHeader';
import { useAssistantStore } from '../Assistant/useAssistantStore';
import { AssistantThreadHistory } from '../Assistant/AssistantThreadHistory';
import { AssistantContext } from '../Assistant/Assistant';
import { AssistantMessageList } from '../Assistant/AssistantMessageList';
import { welcomeMessages } from './OppWorkspaceAssistantWelcomeMessage';

export type OppWorkspaceAssistantProps = {
  workspaceIdProp: string;
};

export const OppWorkspaceAssistant = ({ workspaceIdProp }: OppWorkspaceAssistantProps) => {
  const [createThread, { isLoading: threadIsLoading }] = useCreateWorkspaceAssistantThreadMutation();
  const [thread, setThread] = useState<LlmAssistantThreadShow | LlmAssistantThreadIndex | null>(null);
  const [showThreadHistory, setShowThreadHistory] = useState(false);
  const { isMobile } = useDeviceWidth();
  const { data: user, isLoading: userLoading } = useGetCurrentUserQuery();

  const defaultPrompts = [
    'When is the RFQ for this opportunity due?',
    'Can you summarize all attached documents in this workspace?',
    'Can you explain what you can help with?'
  ];

  const { standardWelcomeMessage } = welcomeMessages(user);
  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(standardWelcomeMessage());

  const { data: threadData } = useGetWorkspaceAssistantThreadQuery(
    { id: thread?.id || '', workspaceId: workspaceIdProp },
    {
      skip: !thread?.id
    }
  );

  const { messages, sendNewMessage, resetMessages } = useAssistant({ threadId: thread?.id || '' });

  useEffectOnce(() => {
    if (!thread?.id) getNewThread();
  });

  const getNewThread = async () => {
    try {
      const result = await createThread({
        workspaceId: workspaceIdProp
      }).unwrap();
      setThread(result);
    } catch (err) {
      console.error('Failed to create thread:', err);
    }
  };

  const handleNewThreadRequest = () => {
    resetMessages();
    getNewThread();
  };

  useEffect(() => {
    if (threadData?.messages?.length) {
      const state = useAssistantStore.getState(threadData.id);
      const hasMessages = state.messages.length > 0;
      if (hasMessages) return;
      state.setMessages(threadData.messages.slice().reverse());
    }
  }, [threadData]);

  return (
    <AssistantContext.Provider
      value={{
        messages,
        sendNewMessage,
        resetMessages,
        getNewThread,
        isLoading: threadIsLoading,
        placeholder: 'Example: Please summarize the opportunity in this workspace.',
        defaultPrompts,
        welcomeMessage,
        setWelcomeMessage
      }}
    >
      <div className="relative">
        {threadIsLoading || userLoading ? (
          <Loading type="card" />
        ) : (
          !!thread?.id && (
            <div className="w-full flex flex-row">
              {showThreadHistory && (
                <AssistantThreadHistory
                  workspaceId={workspaceIdProp}
                  onThreadSelect={thread => {
                    if (isMobile) setShowThreadHistory(false);
                    setThread(thread);
                  }}
                  activeThreadId={thread?.id}
                  setShowThreadHistory={setShowThreadHistory}
                />
              )}
              <div className={cn('relative border rounded w-full bg-white h-[400px] lg:h-[600px]')}>
                <AssistantHeader className="lg:justify-start">
                  <Tooltip content="Show Thread History" placement="right">
                    <Button
                      icon="history"
                      onClick={() => setShowThreadHistory(!showThreadHistory)}
                      minimal
                      aria-label="Show Thread History"
                    />
                  </Tooltip>

                  <Tooltip content="New Thread" placement="right">
                    <Button icon="annotation" text="New Thread" onClick={handleNewThreadRequest} minimal />
                  </Tooltip>

                  <ProcessingTag threadId={thread?.id || ''} filesProcessing={thread?.filesProcessing} />
                </AssistantHeader>
                <AssistantMessageList />
                <AssistantForm className="mt-4 px-4 lg:px-0" />
              </div>
            </div>
          )
        )}
      </div>
    </AssistantContext.Provider>
  );
};

const ProcessingTag = ({
  threadId,
  filesProcessing: filesProcessingInit = false
}: {
  threadId: string;
  filesProcessing?: boolean;
}) => {
  const wasEverProcessing = useRef(filesProcessingInit);
  const { filesProcessing } = useAssistantStore(threadId);
  const isProcessing = filesProcessingInit || filesProcessing;

  if (isProcessing && !wasEverProcessing.current) wasEverProcessing.current = true;

  if (!wasEverProcessing.current) return null;

  return (
    <Tag
      className="self-center"
      minimal
      {...(isProcessing
        ? {
            intent: 'warning',
            icon: <Icon size={12} icon="unresolve" className="animate-spin" />,
            children: 'Processing files'
          }
        : { intent: 'success', icon: 'tick', children: 'Files processed' })}
    />
  );
};
