import React from 'react';
import { Icon, IconName, Intent } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

export type NotificationIndicatorProps = {
  size: 'sm' | 'md' | 'lg' | 'xl';
  intent?: Intent;
  position?: 'top' | 'bottom' | 'center';
  icon?: IconName;
  className?: string;
};

export const NotificationIndicator = ({
  size,
  intent = 'none',
  icon,
  position = 'top',
  className
}: NotificationIndicatorProps) => {
  const classes = cn(
    'absolute right-0 ml-3 flex rounded-full ring-2 ring-white',
    {
      'h-2 w-2': size === 'sm',
      'h-2.5 w-2.5': size === 'md',
      'h-3 w-3': size === 'lg',
      'h-3.5 w-3.5': size === 'xl',
      'bg-gray-300': !intent || intent === 'none',
      'bg-blue-300': intent === 'primary',
      'bg-green-300': intent === 'success',
      'bg-red-300': intent === 'danger',
      'bg-yellow-300': intent === 'warning',
      'top-0': position === 'top',
      'bottom-0': position === 'bottom',
      'top-1/2 -translate-y-1/2': position === 'center'
    },
    className
  );

  return (
    <span className={classes}>
      {icon && size === 'xl' && <Icon icon={icon} size={8} className="m-auto text-white" />}
    </span>
  );
};

export const Notification = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('relative', className)} {...props} />;
};
