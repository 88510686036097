import React from 'react';
import { Icon, Button } from '@blueprintjs/core';

import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { SouptoolsTrSubmissionStatusTag } from 'app/organisms/SouptoolsTrSubmissionStatusTag/SouptoolsTrSubmissionStatusTag';
import { useCancelTrSubmissionMutation } from 'api/souptoolsTrsApi';
import { SouptoolsTrSubmission as SouptoolsTrSubmissionType } from 'types/__generated__/GovlyApi';

type SouptoolsTrSubmissionProps = {
  trSubmission: SouptoolsTrSubmissionType;
};

export const SouptoolsTrSubmission = ({ trSubmission }: SouptoolsTrSubmissionProps) => {
  const {
    id,
    souptoolsTrId,
    submittedBy,
    createdAt,
    scheduledAt,
    submittedAt,
    status,
    updatedAt,
    file,
    submissionScreenshot
  } = trSubmission;

  let text = ` Created at ${formatTime(createdAt, DATETIME_24_SHORT)} (${submittedBy})`;

  if (submittedAt) {
    text = ` Submitted at ${formatTime(submittedAt, DATETIME_24_SHORT)} (${submittedBy})`;
  }

  if (['scheduled', 'submitting'].includes(status) && scheduledAt) {
    text = ` Scheduled for submission at ${formatTime(scheduledAt, DATETIME_24_SHORT)} (${submittedBy})`;
  }

  if (status === 'canceled') {
    text = ` Canceled at ${formatTime(updatedAt, DATETIME_24_SHORT)} (${submittedBy})`;
  }

  if (status === 'failed') {
    text = ` Failed at ${formatTime(updatedAt, DATETIME_24_SHORT)} (${submittedBy})`;
  }

  const [cancelSubmission, { isLoading: isCancelling }] = useCancelTrSubmissionMutation();

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap items-center justify-between py-1">
        <SouptoolsTrSubmissionStatusTag trSubmission={trSubmission} />
        {status === 'scheduled' && (
          <Button
            small
            minimal
            intent="danger"
            text="Cancel"
            loading={isCancelling}
            onClick={() => cancelSubmission({ id, trId: souptoolsTrId })}
          />
        )}
      </div>
      <div className="flex flex-wrap items-center py-1">
        <>
          <Icon icon="time" iconSize={12} />
          <small className="mx-1">{text}</small>
        </>
        {file && status !== 'scheduled' && (
          <>
            <Icon icon="dot" iconSize={12} />
            <small className="mx-1">
              <a href={file.link}>{file.name}</a>
            </small>
          </>
        )}
        {submissionScreenshot && (
          <>
            <Icon icon="dot" iconSize={12} />
            <small className="mx-1">
              <a href={submissionScreenshot.link} target="_blank" rel="noreferrer">
                View Screenshot
              </a>
            </small>
          </>
        )}
      </div>
    </div>
  );
};
