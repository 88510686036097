import { create } from 'zustand';

type State = {
  rightElement?: React.ReactNode;
};

type Action = {
  setRightElement: (rightElement: React.ReactNode) => void;
};

type Store = State & Action;

export const useAppDrawerStore = create<Store>(set => ({
  rightElement: undefined,
  setRightElement: rightElement => set({ rightElement })
}));
