import { WorkspaceAttachmentShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['WorkspaceAttachment'] });

type GetWorkspaceAttachments = {
  params: { id: string };
  result: WorkspaceAttachmentShow[];
};

type CreateWorkspaceAttachments = {
  params: { id: string; attachments: string[] };
  result: void;
};

type DeleteWorkspaceAttachment = {
  params: { workspaceId: string; attachmentId: string };
  result: void;
};

export const workspacesAttachmentsApi = api.injectEndpoints({
  endpoints: build => ({
    getWorkspaceAttachments: build.query<GetWorkspaceAttachments['result'], GetWorkspaceAttachments['params']>({
      query: body => ({
        url: `/v2/workspaces/${body.id}/attachments`,
        method: 'GET'
      }),
      providesTags: (_, __, arg) => [
        'WorkspaceAttachment',
        ...(arg.id ? [{ type: 'WorkspaceAttachment' as const, workspaceId: arg.id }] : [])
      ]
    }),
    createWorkspaceAttachments: build.mutation<
      CreateWorkspaceAttachments['result'],
      CreateWorkspaceAttachments['params']
    >({
      query: body => ({
        url: `/v2/workspaces/${body.id}/attachments`,
        method: 'POST',
        body: { attachments: body.attachments }
      }),
      invalidatesTags: (_, __, arg) => [
        'WorkspaceAttachment',
        ...(arg.id ? [{ type: 'WorkspaceAttachment' as const, workspaceId: arg.id }] : [])
      ]
    }),
    deleteWorkspaceAttachment: build.mutation<DeleteWorkspaceAttachment['result'], DeleteWorkspaceAttachment['params']>(
      {
        query: body => ({
          url: `/v2/workspaces/${body.workspaceId}/attachments/${body.attachmentId}`,
          method: 'DELETE'
        }),
        invalidatesTags: (_, __, arg) => [
          'WorkspaceAttachment',
          ...(arg.workspaceId ? [{ type: 'WorkspaceAttachment' as const, workspaceId: arg.workspaceId }] : [])
        ]
      }
    )
  })
});

export const {
  useGetWorkspaceAttachmentsQuery,
  useCreateWorkspaceAttachmentsMutation,
  useDeleteWorkspaceAttachmentMutation
} = workspacesAttachmentsApi;
