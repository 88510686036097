import React from 'react';

import { useSearchParams } from 'react-router-dom';
import { AWARD_SEARCH_CACHE_KEY, useAwardSearchMutation } from 'api/awardsApi';
import { pluralize } from 'app/lib/strings';
import { Button, ButtonGroup, Tooltip } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { TextLoading } from 'app/atoms/Loading/Loading';
import { RESULTS_VIEW_PARAM, useResultsView } from 'app/organisms/AwardSearchResults/utils';
import { SearchResultsExportButton } from 'app/organisms/SearchResults/SearchResultsExportButton';
import { SearchResultsSort } from '../SearchResults/SearchResultsSort';

type Props = {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
};

export const AwardSearchResultsControls = ({ leftActions, rightActions }: Props) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const view = useResultsView();

  const { values } = useFormikContext<AwardSearchForm>();
  const { query, filters } = values;

  const [
    _search,
    {
      data: {
        meta: { totalRelation, total, currentPage, totalPages } = {
          totalRelation: null,
          total: 0,
          currentPage: 0,
          totalPages: 0
        }
      } = {},
      isLoading
    }
  ] = useAwardSearchMutation({ fixedCacheKey: AWARD_SEARCH_CACHE_KEY });

  let totalRelationPrefix = '';

  if (totalRelation && totalRelation.includes('gt')) {
    totalRelationPrefix = 'More Than ';
  }

  const resultCountLabel = `${totalRelationPrefix}${total.toLocaleString()} ${pluralize('result', 'results', total)}`;

  return (
    <div className="flex items-end justify-between px-6 lg:px-0 flex-col sm:flex-row">
      <div className="hidden sm:block gap-px">
        <span className="line-clamp-1 block text-lg font-medium text-gray-600" data-test="award-search-result-count">
          {isLoading ? <TextLoading>More than 10000 results</TextLoading> : resultCountLabel}
        </span>

        {isLoading || totalPages > 0 ? (
          <span className="inline-block text-sm text-gray-500">
            {isLoading ? <TextLoading>Page N of N</TextLoading> : `Page ${currentPage} of ${totalPages}`}
          </span>
        ) : null}
      </div>

      <div className="flex shrink-0 items-center space-x-2 ml-2">
        {leftActions}

        {view === 'cards' && (
          <SearchResultsSort
            sortItems={[
              { label: 'Action Date', value: 'action_date' },
              { label: 'Start Date', value: 'period_of_performance_start_date' },
              { label: 'End Date', value: 'period_of_performance_current_end_date' },
              { label: 'Obligated Amount', value: 'total_dollars_obligated' },
              { label: 'Potential Amount', value: 'potential_total_value_of_award' }
            ]}
          />
        )}

        <SearchResultsExportButton query={query} filters={filters} resultType="awards" />

        <ButtonGroup>
          <Tooltip content="Card View">
            <Button
              aria-label="Card View"
              intent={view === 'cards' ? 'primary' : undefined}
              icon="grid-view"
              onClick={() => setSearchParams({ [RESULTS_VIEW_PARAM]: 'cards' })}
            />
          </Tooltip>
          <Tooltip content="Table View">
            <Button
              aria-label="Table View"
              intent={view === 'table' ? 'primary' : undefined}
              icon="th"
              onClick={() => setSearchParams({ [RESULTS_VIEW_PARAM]: 'table' })}
            />
          </Tooltip>
        </ButtonGroup>

        {rightActions}
      </div>
    </div>
  );
};
