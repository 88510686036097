import React from 'react';
import { Button, Callout, HTMLSelect } from '@blueprintjs/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import startCase from 'lodash-es/startCase';

import { SouptoolsClin, SouptoolsClinAlternativeClins } from 'types/__generated__/GovlyApi';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { useDeleteClinMutation, useUpdateClinMutation } from 'api/souptoolsClinsApi';
import { useSearchCatalogQuery } from 'api/souptoolsCatalogsApi';

type SouptoolsClinFormProps = {
  validations: Record<string, { validation?: yup.AnySchema; required?: boolean; type?: string }>;
  onClose: () => void;
  clin: SouptoolsClinAlternativeClins | SouptoolsClin;
  stickyFooter?: boolean;
  readOnly?: boolean;
};

export const SouptoolsClinForm = ({
  validations,
  onClose,
  clin,
  stickyFooter = false,
  readOnly = false
}: SouptoolsClinFormProps) => {
  const initialValues: Partial<SouptoolsClinAlternativeClins | SouptoolsClin> = {};
  const { data: { clins: alternateClins = [] } = {}, isLoading } = useSearchCatalogQuery(
    { page: 1, column: 'partnumber', query: clin.partnumber },
    { skip: !clin.partnumber }
  );

  Object.keys(validations).forEach(k => {
    if (k in clin) {
      // @ts-expect-error - this is a dynamic key
      initialValues[k] = clin[k];
    }
  });

  const { id, formatErrors, clinableId, clinableType } = clin || {};

  const [deleteClin, { isLoading: isDeletingClin }] = useDeleteClinMutation();

  const [updateClin, { isLoading: isUpdatingClin }] = useUpdateClinMutation();

  const validationSchema = Object.keys(validations)
    .filter(k => !!validations[k].validation)
    .reduce((obj, key) => {
      return { ...obj, [key]: validations[key].validation };
    }, {});

  const BasicInput = ({ f }: { f: string }) => (
    <TextInput errorOnTouch={false} name={f} label={startCase(f)} inputProps={{ large: false, readOnly }} />
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        initialErrors={formatErrors}
        enableReinitialize
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          await updateClin({ id, clinableId, clinableType, ...values }).unwrap();
          onClose();
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <Card
              className={stickyFooter ? 'overflow-visible' : ''}
              title={`${readOnly ? '' : 'Edit '} Clin`}
              rightElement={onClose && <Button minimal icon="cross" onClick={onClose} />}
            >
              <CardBody>
                <div className="flex flex-col gap-y-4">
                  {!isLoading && alternateClins.length > 1 && (
                    <Callout className="mb-2" intent="primary" title="Alternative Clins">
                      <p>{`There are ${alternateClins.length} clin numbers for this part number. Use the select below to replace the clin number and price.`}</p>
                      <HTMLSelect
                        options={[
                          { value: '', label: 'Select a clin' },
                          ...alternateClins.map(c => ({
                            value: c.clin ?? '',
                            label: `${c.clin}${c.sspart ? ` - sspart: ${c.sspart}` : ''} - sewpprice: ${c.sewpprice}`
                          }))
                        ]}
                        onChange={e => {
                          if (e.target.value === '') return;

                          const { clin } = alternateClins.find(c => c.clin === e.target.value) ?? {};
                          setFieldValue('clin', clin);
                        }}
                      />
                    </Callout>
                  )}

                  {Object.keys(validations).map(f => {
                    const config = validations[f];

                    if (config.type === 'textarea') {
                      return (
                        <TextAreaInput name={f} label={startCase(f)} key={f} inputProps={{ large: false, readOnly }} />
                      );
                    }

                    return <BasicInput key={f} f={f} />;
                  })}
                </div>
              </CardBody>
              {!readOnly && (
                <CardFooter className={stickyFooter ? 'z-100 sticky bottom-0 bg-white lg:rounded-b' : ''}>
                  <Button loading={isUpdatingClin} type="submit" text="Save" intent="primary" />
                  <Button
                    loading={isDeletingClin}
                    onClick={() => deleteClin({ id, clinableId, clinableType })}
                    intent="danger"
                    text="Delete"
                    minimal
                    disabled={!id}
                  />
                </CardFooter>
              )}
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};
