import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import LogoIcon from 'images/components/LogoIcon';
import { CardBody } from 'app/atoms/Card/Card';
import { SignInGoogleButton } from 'app/organisms/SignInGoogleButton/SignInGoogleButton';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';

export const SignInForm = ({ email }: { email?: string }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [searchParams] = useSearchParams();

  const element = document.querySelector('[name="csrf-token"]');
  let csrfToken: string | undefined;
  if (element instanceof HTMLMetaElement) {
    csrfToken = element.content;
  }

  if (email) {
    return (
      <Formik
        initialValues={{ email }}
        onSubmit={() => {
          formRef.current?.submit();
        }}
      >
        {({ isSubmitting }) => (
          <Form className="m-0" action="/sign_in" method="post" ref={formRef}>
            <input type="hidden" name="authenticity_token" value={csrfToken} readOnly={true} />
            <input type="hidden" name="email" value={email} readOnly={true} />

            <Button type="submit" intent="primary" minimal loading={isSubmitting}>
              Resend verification code
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  const paramEmail = searchParams.get('email');
  const invited = searchParams.get('invited');

  return (
    <CardBody>
      <div className="space-y-4 text-center">
        <LogoIcon className="mx-auto h-16 w-auto rounded" />
        <h1 className="m-0 text-2xl font-semibold">{invited ? 'Welcome to Govly!' : 'Sign in to Govly'}</h1>
      </div>
      <div className="space-y-4">
        <Formik
          validateOnBlur={false}
          validationSchema={yup.object({
            email: yup.string().email('Invalid email address.').required('Email is required.')
          })}
          initialValues={{
            email: paramEmail || ''
          }}
          onSubmit={() => {
            formRef.current?.submit();
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4" action="/sign_in" method="post" ref={formRef}>
              <input type="hidden" name="authenticity_token" value={csrfToken} readOnly={true} />
              <input className="hp" autoComplete="off" name="password" id="hp" />
              <TextInput name="email" label="Email" fill inputProps={{ large: true, autoFocus: true }} />

              <Button type="submit" intent="primary" large fill loading={isSubmitting}>
                {invited ? 'Accept invite' : 'Continue'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      {!invited && (
        <>
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-400" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-600 font-semibold">OR</span>
            </div>
          </div>
          <SignInGoogleButton fill />
        </>
      )}
      <div className="text-center mx-16">
        <span>
          By signing in you agree to our{' '}
          <a href="https://www.govly.com/terms" rel="noreferrer" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="https://www.govly.com/privacy" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
        </span>
      </div>
      <div className="text-center mx-16">
        <span>
          Don&apos;t have an account?{' '}
          <a href="https://www.govly.com/book-a-demo" rel="noreferrer" target="_blank">
            Get in touch
          </a>
        </span>
      </div>
    </CardBody>
  );
};
