import { ProviderFeed, ProviderFeedIndex, ProviderFeedShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['ProviderFeed'] });

type GetProviderFeeds = {
  params: { all?: 'true'; asPartnershipConsumer?: 'true' } | void;
  result: ProviderFeedIndex[];
};

type GetProviderFeed = {
  params: { id: string };
  result: ProviderFeedShow;
};

type UpdateProviderFeed = {
  params: {
    id: string;
    name?: string;
    description?: string;
    contractVehicleId?: string;
    partnerFeedsAttributes?: { provider_feed_id: string; organization_id: string }[];
    completionGroups?: string[];
    notifyIds?: string[];
  };
  result: ProviderFeedIndex;
};

type CreateProviderFeed = {
  params: {
    feedId: string;
  };
  result: ProviderFeed;
};

type DeleteProviderFeed = {
  params: string;
  result: void;
};

export const providerFeedsApi = api.injectEndpoints({
  endpoints: build => ({
    getProviderFeeds: build.query<GetProviderFeeds['result'], GetProviderFeeds['params']>({
      query: params => ({ url: '/v2/provider_feeds', ...(params ? { params } : {}) }),
      providesTags: result =>
        Array.isArray(result)
          ? [...result.map(({ id }) => ({ type: 'ProviderFeed' as const, id })), 'ProviderFeed']
          : ['ProviderFeed']
    }),

    getProviderFeed: build.query<GetProviderFeed['result'], GetProviderFeed['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/provider_feeds/${id}`, params }),
      providesTags: (result, err, { id }) => [{ type: 'ProviderFeed', id }]
    }),

    updateProviderFeed: build.mutation<UpdateProviderFeed['result'], UpdateProviderFeed['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/provider_feeds/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, err, { id }) => [{ type: 'ProviderFeed', id }]
    }),

    createProviderFeed: build.mutation<CreateProviderFeed['result'], CreateProviderFeed['params']>({
      query: body => ({
        url: '/v2/provider_feeds',
        method: 'POST',
        body
      })
    }),

    deleteProviderFeed: build.mutation<DeleteProviderFeed['result'], DeleteProviderFeed['params']>({
      query: id => ({
        url: `/v2/provider_feeds/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetProviderFeedsQuery,
  useUpdateProviderFeedMutation,
  useCreateProviderFeedMutation,
  useGetProviderFeedQuery,
  useDeleteProviderFeedMutation
} = providerFeedsApi;
