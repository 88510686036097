import React from 'react';
import { useParams } from 'react-router-dom';

import { OppDetailsCardList } from 'app/organisms/OppDetails/OppDetails';
import { NotFound } from 'app/molecules/NotFound/NotFound';

type OppWorkspaceOppDetailsProps = {
  oppIdProp?: string;
  workspaceIdProp: string;
};

export const OppWorkspaceOppDetails = ({ oppIdProp, workspaceIdProp }: OppWorkspaceOppDetailsProps) => {
  let { oppId, workspaceId } = useParams();

  if (!oppId && !workspaceId) {
    oppId = oppIdProp;
    workspaceId = workspaceIdProp;
  }

  if (!oppId) {
    return <NotFound />;
  }

  return <OppDetailsCardList id={oppId} />;
};
