import React, { useState } from 'react';
import { Menu, MenuItem, Popover, Spinner, Tag } from '@blueprintjs/core';
import { useReward } from 'react-rewards';

import { formatWorkflowStages, workflowStages } from 'app/lib/workflowStages';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import { ProgressPanels } from 'app/molecules/ProgressPanels/ProgressPanels';
import { Formik } from 'formik';
import { WorkspaceShow } from 'types/__generated__/GovlyApi';
import { useWorkspaceForm } from 'app/organisms/WorkspaceForm/useWorkspaceForm';
import { Loading } from 'app/atoms/Loading/Loading';

type OppWorkspaceWorkflowStageProps = {
  workspaceId: string;
  asTag?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'>;

export const OppWorkspaceWorkflowStage = ({ workspaceId, asTag = false, ...rest }: OppWorkspaceWorkflowStageProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: workspace, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({
    id: workspaceId
  });

  if (workspaceLoading || !workspace) {
    return <Loading type="button"></Loading>;
  }
  const { workflowStage } = workspace;
  const stagesToDisplay = formatWorkflowStages(workflowStage);

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={<StageSelect workspace={workspace} onClose={() => setIsOpen(false)} />}
    >
      {asTag ? (
        <div id={`workspace-${workspaceId}`} className="flex items-center gap-2" {...rest}>
          <WorkflowStageTag workflowStage={workflowStage} />
          <Tag round minimal interactive onClick={() => setIsOpen(b => !b)} icon="edit">
            Edit stage
          </Tag>
        </div>
      ) : (
        <ProgressPanels steps={stagesToDisplay} onClick={() => setIsOpen(b => !b)} {...rest} />
      )}
    </Popover>
  );
};

const StageSelect = ({ workspace, onClose }: { workspace: WorkspaceShow; onClose: () => void }) => {
  const { follows = [], workflowStage, id, name = '' } = workspace;

  const { reward: submittedReward } = useReward(`workspace-${id}`, 'confetti', {
    lifetime: 50,
    elementCount: 100,
    elementSize: 12,
    decay: 0.97,
    zIndex: 1001
  });

  const { reward: awardedReward } = useReward(`workspace-${id}`, 'emoji', {
    emoji: ['💰', '🤑'],
    lifetime: 50,
    elementCount: 100,
    decay: 0.97,
    zIndex: 1001
  });

  const form = useWorkspaceForm({
    follows,
    workableTypeForLink: 'opportunities',
    initialValues: {
      id,
      workflowStage,
      name
    },
    notifyNewFollowersOnly: false,
    followersInputs: false,
    onCancel: ({ workflowStage }) => {
      if (workflowStage === 'awarded') {
        awardedReward();
      }
      if (workflowStage === 'submitted') {
        submittedReward();
      }
    }
  });

  return (
    <Formik {...form}>
      {({ setFieldValue, submitForm, values, isSubmitting }) => (
        <Menu>
          {workflowStages.map(stage => (
            <MenuItem
              key={stage.value}
              shouldDismissPopover={false}
              text={
                <span className="flex items-center gap-2">
                  {stage.label}
                  {isSubmitting && values.workflowStage === stage.value ? <Spinner size={12} /> : undefined}
                </span>
              }
              roleStructure="listoption"
              selected={workflowStage === stage.value}
              disabled={isSubmitting}
              onClick={async () => {
                await setFieldValue('workflowStage', stage.value);
                await submitForm();
                onClose();
              }}
            />
          ))}
        </Menu>
      )}
    </Formik>
  );
};
