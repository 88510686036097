import React from 'react';

import { ActorRuns } from 'app/organisms/ActorRuns/ActorRuns';

import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const ContractAutomationPage = () => {
  return (
    <SubscriptionRequiredPageWrapper
      subscriptions={['seatPaid']}
      paywallPageProps={{
        title: 'Contract Automation is a Paid Feature'
      }}
    >
      <ActorRuns />
    </SubscriptionRequiredPageWrapper>
  );
};
