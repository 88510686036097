import React from 'react';
import truncate from 'lodash-es/truncate';
import { EntityTitle, Tag } from '@blueprintjs/core';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { ImageWithFallback } from 'app/atoms/ImageWithFallback/ImageWithFallback';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { OppDetailsOrganizationOppContext } from 'app/organisms/OppDetailsOrganizationOppContext/OppDetailsOrganizationOppContext';
import { asCurrency } from 'app/lib/numbers';
import { presence } from 'app/lib/arrays';
import { DescriptionListRow } from 'app/molecules/DescriptionList/DescriptionList';
import { OppShow } from 'types/__generated__/GovlyApi';

export const OppDetailsDescriptionFields = ({ id, opp }: { id: string; opp: OppShow }) => {
  const {
    postedAt,
    awardedAt,
    cancelledAt,
    modifiedAt,
    modified,
    contractVehicle,
    oppAward,
    setAside,
    latestData: sourceData = {}
  } = opp;

  const publicLink = sourceData.externalUrl ?? sourceData.procurementPortalPublicUrl;

  const rows = [
    { label: 'Solicitation Number', value: sourceData.solicitationNumber },
    { label: 'Respond By', value: formatTime(sourceData.respondBy ?? '', DATETIME_24_SHORT) },
    { label: 'Posted At', value: formatTime(postedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Awarded At', value: formatTime(awardedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Awardee', value: oppAward?.awardeeName },
    { label: 'Award Amount', value: oppAward?.amount ? asCurrency(oppAward?.amount) : null },
    { label: 'Cancelled At', value: formatTime(cancelledAt ?? '', DATETIME_24_SHORT) },
    { label: 'Modified At', value: modified && formatTime(modifiedAt ?? '', DATETIME_24_SHORT) },
    {
      label: 'Contract Vehicle',
      value: contractVehicle?.contractName ? (
        <EntityTitle
          title={contractVehicle?.contractName}
          icon={
            contractVehicle?.logoDomain ? (
              <ImageWithFallback
                src={`https://logo.clearbit.com/${contractVehicle.logoDomain}`}
                className="h-8 w-8 object-contain shrink-0 rounded-sm"
                loading="lazy"
              />
            ) : undefined
          }
        />
      ) : null
    },
    { label: 'Question Deadline', value: formatTime(sourceData.questionDeadline ?? '', DATETIME_24_SHORT) },
    { label: 'Cancel Reason', value: sourceData.cancelReason },
    {
      label: 'Notice Type',
      value: presence(sourceData.noticeTypes) && (
        <div className="flex gap-x-2">
          {sourceData.noticeTypes.map(t => (
            <Tag key={t}>{t}</Tag>
          ))}
        </div>
      )
    },
    { label: 'Created By', value: sourceData.createdBy },
    { label: 'Set Aside', value: setAside },
    { label: 'NAICS Codes', value: sourceData.naicsCodes?.map(({ code }) => code).join(', ') },
    { label: 'Number of Recipients', value: sourceData.contractHolderCount },
    { label: 'Latest Modification Reason', value: sourceData.modificationReason },
    { label: 'Response Location', value: sourceData.responseLocation },
    { label: 'Delivery Instructions', value: sourceData.deliveryInstructions },
    {
      label: 'Public Link',
      value: publicLink && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={publicLink}>
          {truncate(publicLink.replace(/^http?:\/\//, ''), { length: 45 })}
        </LinkTag>
      )
    }
  ];

  return (
    <>
      {rows.map(({ label, value }) => (
        <DescriptionListRow label={label} value={value} key={label} />
      ))}

      <OppDetailsOrganizationOppContext id={id} />
    </>
  );
};
