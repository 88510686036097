import React from 'react';
import { useSubscriptions, Subscription } from 'app/hooks/useSubscription';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { PaywallPage, PaywallPageProps } from 'app/organisms/PaywallPage/PaywallPage';

type SubscriptionRequiredPageWrapperProps = {
  subscriptions: Subscription[];
  paywallPageProps?: PaywallPageProps;
  isLoading?: boolean;
  children: React.ReactNode;
};

export const SubscriptionRequiredPageWrapper = ({
  subscriptions,
  paywallPageProps,
  isLoading,
  children
}: SubscriptionRequiredPageWrapperProps) => {
  const { isLoading: subscriptionsLoading, ...subs } = useSubscriptions({ subscriptions });

  if (subscriptionsLoading || isLoading) {
    return <AppLoading />;
  }

  if (!Object.values(subs).some(Boolean)) {
    return <PaywallPage {...paywallPageProps} />;
  }

  return children;
};
