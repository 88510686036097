import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { Card, CardSection } from 'app/atoms/Card/Card';

type CardErrorProps = {
  title?: string;
  entity?: string;
};

export const CardError = ({ title = 'Report Error', entity = 'report' }: CardErrorProps) => (
  <Card title={title}>
    <CardSection>
      <NonIdealState
        icon="error"
        title="Something went wrong"
        description={`Something went wrong when loading this ${entity}. Please try again later.`}
      />
    </CardSection>
  </Card>
);
