import React, { useState, useMemo } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { ColumnChart, ColumnChartData } from 'react-chartkick';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { ReportReport } from 'types/__generated__/GovlyApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useGetReportQuery } from 'api/reportsApi';
import { useReportSlices, ReportSlice } from 'app/hooks/useReportSlices';
import { CardError } from 'app/atoms/ErrorFallback/CardError';

type OrganizationInsightsFollowedProps = {
  filters: {
    orgUserId: string;
    dateRange: [Date, Date];
  };
};

export const OrganizationInsightsFollowed = ({ filters }: OrganizationInsightsFollowedProps) => {
  const slices = useReportSlices();
  const [view, setView] = useState<ReportSlice>('noSlice');
  const { data, isFetching, isError } = useGetReportQuery({
    report_type: 'Report::OrganizationFollowsReport',
    ...filters
  });

  const report = data as ReportReport | undefined;
  const workflowStages = Object.keys(report?.series ?? {});

  const chartOptions = useMemo(() => {
    return {
      xAxis: {
        categories: workflowStages
      }
    };
  }, [workflowStages]);

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!report || isFetching) {
    return <Loading />;
  }

  let chartData: ColumnChartData = workflowStages.map(stage => [stage, report.series[stage].count] as [string, number]);
  if (view !== 'noSlice') {
    chartData = Object.keys(report[view]).map(key => ({
      name: key,
      data: Object.entries(report[view][key])
    }));
  }

  return (
    <ErrorBoundary action="OrganizationInsightsFollowed" fallback={<CardError title="Workflow Stage Pipeline" />}>
      <Card className="mb-4" title="Workflow Stage Pipeline">
        <CardBody>
          <ColumnChart stacked data={chartData} library={chartOptions} />
        </CardBody>
        <CardBody className="text-center">
          <ButtonGroup>
            {slices.map(({ key, label }) => (
              <Button key={key} active={view === key} onClick={() => setView(key)} text={label} />
            ))}
          </ButtonGroup>
        </CardBody>
      </Card>
    </ErrorBoundary>
  );
};
