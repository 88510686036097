import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Tag, AnchorButton, Dialog, Button } from '@blueprintjs/core';
import { DatePicker, TimePickerProps } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { isToday } from 'app/lib/dates';
import { successToast, errorToast } from 'app/lib/toaster';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { SouptoolsClinUploadsCard } from 'app/organisms/SouptoolsClinUploadsCard/SouptoolsClinUploadsCard';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { SouptoolsSubmitClinCheck } from 'app/organisms/SouptoolsSubmitClinCheck/SouptoolsSubmitClinCheck';
import { useCheckClinListMutation } from 'api/souptoolsClinsApi';
import { useGetTrTemplatesQuery } from 'api/souptoolsTrTemplatesApi';
import { useGetTrQuery, useUpdateTrMutation, useSubmitTrMutation } from 'api/souptoolsTrsApi';
import { SouptoolsTrSubmission } from 'app/organisms/SouptoolsTrSubmission/SouptoolsTrSubmission';
import { SouptoolsClinTable } from 'app/organisms/SouptoolsClinTable/SouptoolsClinTable';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { capitalize } from 'app/lib/strings';
import { NotFound } from 'app/molecules/NotFound/NotFound';

const statusIntent = (status?: string) => {
  if (status === 'complete') {
    return 'success';
  }

  if (status === 'draft') {
    return 'primary';
  }

  return 'danger';
};

export const SouptoolsTrPage = () => {
  const { id } = useParams();
  const [pollingInterval, setPollingInterval] = useState(0);
  const [isSchedulingSubmission, setIsSchedulingSubmission] = useState(false);
  const { trackEvent } = useEventTracking();

  const currentUserId = useCurrentUserAttribute('id');
  const { data: tr, isLoading } = useGetTrQuery({ id: id ?? '' }, { pollingInterval, skip: !id || id === 'new' });
  const [updateTr, { isLoading: isUpdating, isError: updateError, isSuccess: updateSuccess }] = useUpdateTrMutation();
  const { data: trTemplates = [], isLoading: templatesLoading } = useGetTrTemplatesQuery();
  const [checkClinList, { isLoading: isCheckingClinList }] = useCheckClinListMutation();
  const [submitTr, { isLoading: isInitiatingSubmission, isSuccess: trSubmitted }] = useSubmitTrMutation();
  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();

  useEffect(() => {
    if (updateSuccess) {
      successToast('TR updated');
    }

    if (updateError) {
      errorToast('Error updating TR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, updateError, successToast, errorToast]);

  const {
    clinUploadProcessing,
    clinChecklistProcessing,
    clinsCount,
    createdAt,
    createdBy,
    status,
    trTemplateId,
    chopProfileId,
    isSubmitting,
    templatePath,
    trSubmissions = [],
    clins = []
  } = tr ?? {};

  const trSubmitting = trSubmissions.some(s => s.status === 'submitting');

  useEffect(() => {
    if (clinUploadProcessing || clinChecklistProcessing || trSubmitted || trSubmitting) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [clinUploadProcessing, clinChecklistProcessing, trSubmitted, trSubmitting]);

  const { feedProfiles: profiles } = currentOrg || {};
  const profile = profiles?.find(p => p.type === 'FeedProfile::SewpV');
  const submitters = (profile?.context?.submitters as string[]) || [];
  const authorizedToSubmit = submitters.length === 0 || submitters.includes(currentUserId);
  const submissionDisabled = !trTemplateId || !chopProfileId || isSubmitting || !clinsCount || !authorizedToSubmit;
  const minSubmissionDate = new Date();
  const maxSubmissionDate = DateTime.now().plus({ days: 1 }).endOf('day').toJSDate();

  if (isLoading || profileLoading) {
    return <Loading />;
  }

  if (!tr || !id) {
    return <NotFound />;
  }

  return (
    <>
      <Card
        title={
          <>
            <Tag>{id === 'new' ? 'New TR' : `TR ${id}`}</Tag>
            <Tag intent={statusIntent(status)} className="ml-2">
              {capitalize(status)}
            </Tag>
          </>
        }
        rightElement={
          <Formik
            initialValues={{ id, status }}
            onSubmit={async values => {
              await updateTr(values);
              trackEvent({
                object: 'souptools_tr',
                action: 'updated',
                properties: { trId: id, ...values }
              });
            }}
          >
            <Form>
              <SelectInput
                className="mt-0"
                submitOnChange
                filterable={false}
                name="status"
                staticButtonText="Change Status"
                items={[
                  { label: 'Draft', value: 'draft' },
                  { label: 'Complete', value: 'complete' },
                  { label: 'Archived', value: 'archived' }
                ]}
                buttonProps={{ loading: isUpdating, large: false }}
              />
            </Form>
          </Formik>
        }
      >
        <CardBody>
          <div className="flex items-center justify-between">
            <Formik initialValues={{ id, souptoolsTrTemplateId: trTemplateId || '' }} onSubmit={updateTr}>
              <Form>
                <SelectInput
                  label="TR Template"
                  disabled={templatesLoading}
                  submitOnChange
                  filterable={false}
                  name="souptoolsTrTemplateId"
                  buttonProps={{ loading: isUpdating, large: false }}
                  items={trTemplates.map(({ name, contractNumber, id }) => ({
                    label: `${name} (${contractNumber})`,
                    value: id
                  }))}
                />
              </Form>
            </Formik>

            <SouptoolsSubmitClinCheck
              clinable={tr}
              onSubmit={() => checkClinList({ clinableType: 'Souptools::Tr', clinableId: id })}
              isSubmitting={isCheckingClinList}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-2 md:grid-cols-3">
            <DescriptionListField label="Created by:" value={createdBy ?? ''} cols={1} />
            <DescriptionListField
              label="Created at:"
              value={createdAt ? formatTime(createdAt, DATETIME_24_SHORT) : ''}
              cols={1}
            />
          </div>
        </CardBody>
        <CardBody>
          <div className="flex items-center justify-between">
            <AnchorButton
              disabled={!trTemplateId || !clinsCount || !authorizedToSubmit}
              intent="primary"
              text="Process CLINs to txt file"
              href={`/api/v2/souptools/trs/${id}/tr_submissions/new`}
            />
            <Tag large minimal>{`${clinsCount} CLINs`}</Tag>
          </div>
        </CardBody>
        <CardBody>
          <div className="flex justify-end gap-x-2">
            <Button intent="success" onClick={() => setIsSchedulingSubmission(true)} disabled={submissionDisabled}>
              Schedule TR Submission
            </Button>
            <Button
              disabled={submissionDisabled}
              loading={isInitiatingSubmission}
              intent="warning"
              text="Submit TR to CHOP"
              onClick={async () => {
                try {
                  await submitTr({ id });
                  trackEvent({
                    object: 'souptools_tr',
                    action: 'submitted',
                    properties: { trId: id }
                  });
                  successToast('TR submitted');
                } catch (e) {
                  errorToast(e);
                }
              }}
            />
          </div>
        </CardBody>
      </Card>

      <Card
        className="mb-4"
        title={
          <div className="flex items-center gap-x-2">
            <CardHeading className="inline">TR Submissions</CardHeading>
            <div>
              <Tag minimal>{trSubmissions.length}</Tag>
            </div>
          </div>
        }
      >
        {trSubmissions.map(submission => (
          <CardBody key={submission.id}>
            <SouptoolsTrSubmission trSubmission={submission} />
          </CardBody>
        ))}
      </Card>

      <SouptoolsClinUploadsCard uploadable={tr} header="CLIN File Upload" templatePath={templatePath} />

      {!!clins.length && <SouptoolsClinTable clinable={tr} />}

      <Dialog
        title="Schedule TR Submission"
        isOpen={isSchedulingSubmission}
        onClose={() => setIsSchedulingSubmission(false)}
      >
        <Formik
          onSubmit={async values => {
            try {
              await submitTr(values);
              trackEvent({
                object: 'souptools_tr',
                action: 'scheduled',
                properties: { trId: id, ...values }
              });
              successToast('TR scheduled to for submission');
              setIsSchedulingSubmission(false);
            } catch (e) {
              errorToast(e);
            }
          }}
          initialValues={{ id, scheduledAt: new Date() }}
          validationSchema={yup.object().shape({
            scheduled_at: yup.date()
          })}
        >
          {({ values, setFieldValue }) => {
            const value = values['scheduledAt'];
            const timePickerProps: TimePickerProps = {};

            if (isToday(value)) {
              timePickerProps.minTime = new Date();
            }

            return (
              <Form>
                <div className="flex flex-col items-center gap-y-4 p-4">
                  <DatePicker
                    onChange={newDate => setFieldValue('scheduledAt', newDate)}
                    value={value}
                    timePrecision="minute"
                    minDate={minSubmissionDate}
                    maxDate={maxSubmissionDate}
                    timePickerProps={timePickerProps}
                  />

                  <small>{`${Intl.DateTimeFormat().resolvedOptions().timeZone} time zone`}</small>
                  <Button
                    type="submit"
                    intent="primary"
                    disabled={isSubmitting}
                    loading={isInitiatingSubmission}
                    text="Save"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
