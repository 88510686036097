import React from 'react';
import { Formik, Form } from 'formik';

import { ChatForm, ChatFormProps } from './ChatForm';
import { useChat } from './useChat';

type ChatProps = {
  id: string;
  numberOfResults?: number;
} & Pick<ChatFormProps, 'placeholder' | 'questions' | 'cardProps'>;

export type ChatFormState = {
  filters: {
    entityIds?: string[];
  };
  query: string;
  useCustomPrompt: boolean;
};

export const Chat = ({ id, placeholder, questions, numberOfResults, cardProps }: ChatProps) => {
  const { messages, createMessage, resetMessages, isLoading, ref } = useChat({ storeId: id, numberOfResults });

  return (
    <Formik
      initialValues={{ filters: { entityIds: [id] }, query: '', useCustomPrompt: false }}
      onSubmit={({ query, useCustomPrompt, filters: { entityIds } }, { setFieldValue }) =>
        createMessage({
          entityIds,
          message: query,
          clearQuery: () => setFieldValue('query', ''),
          useCustomPrompt
        })
      }
    >
      <Form>
        <ChatForm
          messages={messages}
          isLoading={isLoading}
          placeholder={placeholder}
          questions={questions}
          createMessage={createMessage}
          resetMessages={resetMessages}
          cardProps={cardProps}
          ref={ref}
        />
      </Form>
    </Formik>
  );
};
