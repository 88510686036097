import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, FormGroup, NonIdealState } from '@blueprintjs/core';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { LogoUploader } from 'app/molecules/FileUploaders/LogoUploader/LogoUploader';
import { errorToast, successToast } from 'app/lib/toaster';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { Card, CardFooter, CardSection } from 'app/atoms/Card/Card';
import { useUpdateOrganizationMutation } from 'api/organizationsApi';
import { InputFormGroup } from 'app/molecules/InputFormGroup/InputFormGroup';
import { OrganizationCurrentOrganization } from 'types/__generated__/GovlyApi';

export const OrganizationForm = ({ organization }: { organization: OrganizationCurrentOrganization }) => {
  const adminViewer = useAuthorized({ role: 'admin' });
  const [tempLogoURL, setTempLogoURL] = useState<string>();

  const { id, name, allowSameDomainSignup, sessionActivityTimeoutLength, settings, logo } = organization || {};
  const [updateOrganization, { isLoading }] = useUpdateOrganizationMutation();

  if (!adminViewer) {
    return (
      <Card>
        <CardSection>
          <NonIdealState
            icon="lock"
            title="Not authorized."
            description="You are not authorized to change organization settings. Please contact your Govly admin for help."
          />
        </CardSection>
      </Card>
    );
  }

  return (
    <Formik
      initialValues={{
        id,
        logo,
        sessionActivityTimeoutLength: sessionActivityTimeoutLength ?? 1209600,
        name: name || '',
        allowSameDomainSignup: allowSameDomainSignup !== undefined ? allowSameDomainSignup : true,
        settings: settings || {},
        logoRemoved: false
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required('Name is required.')
      })}
      onSubmit={async values => {
        try {
          const { id, ...body } = values;
          await updateOrganization({ id, organization: body });

          successToast();
        } catch (resp) {
          errorToast(resp);
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Card title={name}>
            <CardSection>
              <div className="flex flex-col gap-y-4">
                <LogoUploader
                  imageUrl={
                    tempLogoURL || (values.logoRemoved ? undefined : values.logo?.variantLink || values.logo?.link)
                  }
                  id="logo"
                  className="m-0"
                  onRemove={() => {
                    setTempLogoURL(undefined);
                    setFieldValue('logo', null);
                    setFieldValue('logoRemoved', true);
                  }}
                  onInitialize={file => {
                    setTempLogoURL(URL.createObjectURL(file));
                  }}
                  onAttach={async attachment => {
                    setFieldValue('logoRemoved', false);
                    setFieldValue('logo', attachment.signedId);
                  }}
                />
                <TextInput name="name" label="Name" className="sm:w-2/3" />
                <FormGroup label="Settings" className="col-span-6">
                  <SwitchInput
                    name="allowSameDomainSignup"
                    label="Allow new team members to join your Govly organization if they have an approved domain"
                  />
                  <SwitchInput
                    name="settings.hideSavedSearchesAcrossOrg"
                    label="Disable sharing saved searches across the organization."
                  />
                  <SwitchInput
                    name="settings.disableOppAttachmentDownloads"
                    label="Disable downloading of opportunity attachments."
                  />
                </FormGroup>

                <InputFormGroup name="sessionActivityTimeoutLength" label="Session Inactivity Timeout">
                  <RadioGroupInput
                    name="sessionActivityTimeoutLength"
                    inline
                    options={[
                      { label: '15 minutes', value: '900' },
                      { label: '30 minutes', value: '1800' },
                      { label: '1 hour', value: '3600' },
                      { label: '12 hours', value: '43200' },
                      { label: '1 day', value: '86400' },
                      { label: '1 week', value: '604800' },
                      { label: '2 weeks', value: '1209600' }
                    ]}
                  />
                </InputFormGroup>
              </div>
            </CardSection>

            <CardFooter>
              <Button intent="primary" type="submit" large loading={isLoading}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
