import { SearchableActions } from 'app/hooks/search/types';
import { create } from 'zustand';
import { useFormikSavableSearch } from 'app/hooks/search/useFormikSavableSearch';
import { Range } from 'app/atoms/inputs/RangeInput/RangeInput';
import { addYears } from 'date-fns';
import { SavedSearchDisplayable } from 'types/__generated__/GovlyApi';
import { useGetSavedSearchesQuery } from 'api/savedSearchesApi';
import { SearchMeta } from './types';

export const AWARD_TYPES = [
  { value: 'definitive_contract', label: 'Definitive Contract' },
  { value: 'delivery_order', label: 'Delivery Order (DO)' },
  { value: 'purchase_order', label: 'Purchase Order (PO)' },
  { value: 'bpa_call', label: 'Blanket Purchase Agreement (BPA) Call' }
] as const;
export type AwardType = (typeof AWARD_TYPES)[number]['value'];

export const IDV_TYPES = [
  { value: 'boa', label: 'Basic Ordering Agreement (BOA)' },
  { value: 'bpa', label: 'Blanket Purchase Agreement (BPA)' },
  { value: 'fss', label: 'Federal Supply Schedule (FSS)' },
  { value: 'gwac', label: 'Government-Wide Acquisition Contract (GWAC)' },
  { value: 'idc', label: 'Indefinite Delivery Contract (IDC)' }
] as const;
export type IDVType = (typeof IDV_TYPES)[number]['value'];

type CompositeFilterType = 'any' | 'all' | 'none';

export type CompositeFilter<Str extends string = string, Kind extends CompositeFilterType = 'any' | 'none'> = Record<
  Kind,
  Str[]
>;

type ExhaustiveCompositeFilter<Str extends string = string> = CompositeFilter<Str, CompositeFilterType>;

export const RANGE_FIELDS = [
  'potentialAwardAmountRange',
  'currentAwardAmountRange',
  'obligatedAwardAmountRange',
  'outlayedAwardAmountRange',
  'remainingPotentialAwardAmountRange',
  'percentRemainingPotentialAwardAmountRange'
] as const;
type RangeFields = Record<(typeof RANGE_FIELDS)[number], Range>;

export const AGENCY_FIELDS = ['awardingAgencyNames', 'fundingAgencyNames'] as const;
type AgencyFields = Record<(typeof AGENCY_FIELDS)[number], CompositeFilter>;

type SpecialFields = RangeFields & AgencyFields;

export type AwardSearchFilters = SpecialFields & {
  awardOrIdvFlag: 'award' | 'idv';
  awardType?: AwardType[];
  idvType?: IDVType[];
  dateRangeParam: 'period_of_performance_start_date' | 'period_of_performance_current_end_date' | 'action_date';
  dateRange: [Date?, Date?];
  naicsCodes: CompositeFilter;
  productOrServiceCodes: CompositeFilter;
  setAsides: CompositeFilter;
  sort:
    | 'period_of_performance_start_date'
    | 'period_of_performance_current_end_date'
    | 'action_date'
    | 'potential_total_value_of_award'
    | 'total_dollars_obligated';
  sortDirection: 'desc' | 'asc';
  programs: CompositeFilter;
  recipientNames: CompositeFilter;
  placeOfPerformance?: {
    countries?: string[];
    regions?: string[];
    congressionalDistricts?: string[];
  };
  following?: ExhaustiveCompositeFilter;
  labels?: ExhaustiveCompositeFilter;
  searchTags?: ExhaustiveCompositeFilter;
};

export type FieldNames = keyof AwardSearchFilters;

export type { State as AwardSearchForm };
type State = {
  query: string;
  filters: AwardSearchFilters;
  meta: SearchMeta & { agencyType: 'awarding' | 'funding' };
};

type Action = SearchableActions;

type Store = State & Action;

export const getFormState = (state: Store): State => {
  const { reset: _, ...formState } = state;
  return formState;
};

export const defaultValues: State = {
  meta: {
    searchState: 'initializing',
    savedSearchId: null,
    agencyType: 'awarding'
  },
  query: '',
  filters: {
    awardOrIdvFlag: 'award',
    awardType: AWARD_TYPES.map(({ value }) => value),
    idvType: IDV_TYPES.map(({ value }) => value),
    potentialAwardAmountRange: [],
    currentAwardAmountRange: [],
    obligatedAwardAmountRange: [],
    outlayedAwardAmountRange: [],
    remainingPotentialAwardAmountRange: [],
    percentRemainingPotentialAwardAmountRange: [],
    awardingAgencyNames: {
      any: [],
      none: []
    },
    dateRangeParam: 'action_date',
    dateRange: [addYears(new Date(), -4), new Date()],
    fundingAgencyNames: {
      any: [],
      none: []
    },
    naicsCodes: {
      any: [],
      none: []
    },
    productOrServiceCodes: {
      any: [],
      none: []
    },
    setAsides: {
      any: [],
      none: []
    },
    sort: 'period_of_performance_start_date',
    sortDirection: 'desc',
    programs: {
      any: [],
      none: []
    },
    recipientNames: {
      any: [],
      none: []
    },
    placeOfPerformance: {
      countries: [],
      regions: [],
      congressionalDistricts: []
    },
    following: {
      any: [],
      all: [],
      none: []
    },
    labels: {
      any: [],
      all: [],
      none: []
    },
    searchTags: {
      any: [],
      all: [],
      none: []
    }
  }
};

type GetStateFromSavedSearchArgs = {
  savedSearch: SavedSearchDisplayable;
  meta: SearchMeta;
};
export const getStateFromSavedSearch = ({ savedSearch, meta }: GetStateFromSavedSearchArgs): State => {
  const {
    query: { query, ...savedFilters }
  } = savedSearch || { query: { query: '' } };

  return {
    query: query || '',
    filters: {
      ...defaultValues.filters,
      ...savedFilters
    },
    meta: { ...meta, savedSearchId: savedSearch.id, agencyType: 'awarding' }
  };
};

export const useAwardSearchCache = create<Store>(set => ({
  ...defaultValues,
  reset: () => set(defaultValues, true)
}));

export const useInitializeAwardSearchCache = () => {
  useFormikSavableSearch({ savedSearches: [], store: useAwardSearchCache });
};

export const useInitializeContactSearchCache = () => {
  const { data: allSavedSearches = [] } = useGetSavedSearchesQuery({
    active: true,
    searchableType: 'USASpendingAward'
  });
  useFormikSavableSearch({
    getStateFromSavedSearch,
    savedSearches: allSavedSearches,
    store: useAwardSearchCache
  });
};
