import React from 'react';
import { AnchorButton, AnchorButtonProps } from '@blueprintjs/core';

type OpenInNewTabButtonProps = {
  path: string;
} & AnchorButtonProps;

export const OpenInNewTabButton = ({ path, ...rest }: OpenInNewTabButtonProps) => (
  <AnchorButton href={path} target="_blank" icon="share" text="Open in new tab" {...rest} />
);
