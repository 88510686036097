import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

const isTest = process.env.NODE_ENV === 'test';

export const rootApi = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: isTest ? 0 : 120,
  baseQuery: fetchBaseQuery({
    // node env needs a full url
    baseUrl: isTest ? new URL('/api', location.origin).href : '/api',
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }),
  endpoints: () => ({})
});

export const { usePrefetch } = rootApi;
