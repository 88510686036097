import { useMemo } from 'react';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { OrganizationLabel, ActivityExtended } from 'types/__generated__/GovlyApi';

export type useOrganizationLabelTaggingTimelineEventArgs = {
  event: ActivityExtended & {
    customData: {
      removedLabelIds: string[];
      addedLabelIds: string[];
      displayName: string;
    };
    formattedCreatedAt: string;
  };
};
export const useOrganizationLabelTaggingTimelineEvent = ({ event }: useOrganizationLabelTaggingTimelineEventArgs) => {
  const { data: organizationLabels = [], isLoading } = useGetOrganizationLabelsQuery();

  const labelMap = useMemo(() => new Map(organizationLabels.map(ol => [ol.id, ol])), [organizationLabels]);

  const { removedLabelIds = [], addedLabelIds = [] } = event.customData;

  const removedLabels = isLoading
    ? []
    : ((removedLabelIds ?? []).map(l => labelMap.get(l)).filter(Boolean) as OrganizationLabel[]);
  const addedLabels = isLoading
    ? []
    : ((addedLabelIds ?? []).map(l => labelMap.get(l)).filter(Boolean) as OrganizationLabel[]);

  return {
    isLoading,
    addedLabels,
    removedLabels,
    email: event.actor?.email,
    createdAt: event.formattedCreatedAt,
    displayName: event.customData.displayName,
    message: event.message
  };
};
