import React from 'react';

import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { useGetContractVehiclesQuery } from 'api/contractVehiclesApi';

type ContractVehicleSelectInputProps = Omit<React.ComponentProps<typeof SelectInput>, 'items'>;

export const ContractVehicleSelectInput = (props: ContractVehicleSelectInputProps) => {
  const { data: contractVehicles = [], isLoading } = useGetContractVehiclesQuery();

  return <SelectInput items={contractVehicles} loading={isLoading} searchKeys={['label']} {...props} />;
};
