import React, { useState, useCallback, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { Checkbox, CheckboxProps, FormGroup, FormGroupProps } from '@blueprintjs/core';
import debounce from 'just-debounce-it';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

type CheckboxInputProps = {
  name: string;
  label?: string;
  submitOnChange?: boolean;
} & Pick<CheckboxProps, 'label' | 'labelElement'> &
  FormGroupProps;

export const CheckboxInput = ({
  name,
  disabled,
  inline,
  label,
  labelElement,
  className,
  submitOnChange,
  ...rest
}: CheckboxInputProps) => {
  const [updatedAt, setUpdatedAt] = useState<Date | undefined>();
  const { submitForm } = useFormikContext();
  const [field, { error, touched }, { setValue }] = useField({ name });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => submitForm(), 500),
    [submitForm]
  );

  useEffect(() => {
    if (submitOnChange && updatedAt) {
      debouncedSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSubmit, field.value, updatedAt]);

  const hasError = touched && error;
  const intent = hasError ? 'danger' : undefined;

  return (
    <FormGroup className={cn('m-0', className)} disabled={disabled} label={<LabelSpan />} intent={intent} {...rest}>
      <Checkbox
        disabled={disabled}
        checked={!!field.value}
        inline={inline}
        name={name}
        label={label}
        labelElement={labelElement}
        onChange={() => {
          setValue(!field.value);
          setUpdatedAt(new Date());
        }}
      />
      {hasError ? <small className="block text-xs text-red-500">{error}</small> : null}
    </FormGroup>
  );
};
