import {
  JSONObject,
  OrganizationCurrentOrganization,
  OrganizationIndex,
  Organization
} from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Organization', 'CurrentOrganization', 'CurrentUser'] });

type UpdateOrganizationAsUserParams = Pick<
  OrganizationCurrentOrganization,
  'id' | 'logo' | 'sessionActivityTimeoutLength' | 'name' | 'allowSameDomainSignup'
> & {
  settings: {
    hideSavedSearchesAcrossOrg?: boolean;
    preventOppAttachmentsInEmails?: boolean;
    disableOppAttachmentDownloads?: boolean;
  };
  logoRemoved?: boolean;
};

type UpdateOrganizationAsSysAdminParams = Pick<
  OrganizationCurrentOrganization,
  | 'id'
  | 'logo'
  | 'sessionActivityTimeoutLength'
  | 'name'
  | 'allowSameDomainSignup'
  | 'workosOrganizationId'
  | 'primaryDomain'
  | 'subscriptionType'
  | 'subscriptionTrialLengthInDays'
  | 'subscriptionHasPrimeIngestion'
  | 'subscriptionHasPrimeAutomations'
  | 'subscriptionMaxPaidSeatCount'
  | 'subscriptionHasApiIntegrations'
  | 'subscriptionHasSLEDActivated'
  | 'subscriptionHasSouptools'
  | 'subscriptionHasNetwork'
  | 'internal'
  | 'ssoEnabled'
  | 'approvedDomains'
  | 'workosDomains'
> & {
  settings: {
    hideSavedSearchesAcrossOrg?: boolean;
    preventOppAttachmentsInEmails?: boolean;
    disableOppAttachmentDownloads?: boolean;
    hasConflictNotifications?: boolean;
  };
  logoRemoved?: boolean;
};

type GetOrganizations = {
  params: void;
  result: OrganizationIndex[];
};

type GetCurrentOrganization = {
  params: void;
  result: OrganizationCurrentOrganization;
};

type CreateOrganization = {
  params: JSONObject;
  result: Organization;
};

type UpdateOrganization = {
  params: { id: string } & {
    organization: Partial<UpdateOrganizationAsUserParams> | Partial<UpdateOrganizationAsSysAdminParams>;
  };
  result: Organization;
};

type DeleteOrganization = {
  params: { id: string };
  result: void;
};

export const organizationsApi = api.injectEndpoints({
  endpoints: build => ({
    getOrganizations: build.query<GetOrganizations['result'], GetOrganizations['params']>({
      query: () => ({ url: '/v2/organizations' }),
      providesTags: result =>
        Array.isArray(result)
          ? [...result.map(({ id }) => ({ type: 'Organization' as const, id })), 'Organization']
          : ['Organization']
    }),

    getCurrentOrganization: build.query<GetCurrentOrganization['result'], GetOrganizations['params']>({
      query: () => ({ url: `/v2/current_organization` }),
      providesTags: result => [
        ...(result ? [{ type: 'Organization' as const, id: result.id }] : []),
        'CurrentOrganization',
        'Organization'
      ]
    }),

    createOrganization: build.mutation<CreateOrganization['result'], CreateOrganization['params']>({
      query: body => ({
        url: `/v2/organizations`,
        method: 'POST',
        body
      }),
      invalidatesTags: () => ['Organization']
    }),

    updateOrganization: build.mutation<UpdateOrganization['result'], UpdateOrganization['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/organizations/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, err, arg) => [
        { type: 'Organization' as const, id: arg.id },
        'Organization',
        'CurrentUser',
        'CurrentOrganization'
      ]
    }),

    deleteOrganization: build.mutation<DeleteOrganization['result'], DeleteOrganization['params']>({
      query: ({ id }) => ({
        url: `/v2/organizations/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const useOrganizationMutation = (organizationId?: string) => {
  const createMutation = useCreateOrganizationMutation();
  const updateMutation = useUpdateOrganizationMutation();

  return organizationId ? updateMutation : createMutation;
};

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetCurrentOrganizationQuery,
  useDeleteOrganizationMutation
} = organizationsApi;
