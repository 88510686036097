import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { ColorInput, colorInputValidation } from 'app/atoms/inputs/ColorInput/ColorInput';
import { CardBody, CardFooter, Card } from 'app/atoms/Card/Card';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { useCreateOrganizationLabelMutation, useUpdateOrganizationLabelMutation } from 'api/organizationLabelsApi';
import { errorToast } from 'app/lib/toaster';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OrganizationLabel } from 'types/__generated__/GovlyApi';
import { hasId } from 'api/utils';

type LabelFormProps = {
  label?: OrganizationLabel;
  onCancel: () => void;
};

export const LabelForm = ({ label, onCancel }: LabelFormProps) => {
  const { trackEvent } = useEventTracking();
  const [update, { isLoading: isUpdating }] = useUpdateOrganizationLabelMutation();
  const [create, { isLoading: isCreating }] = useCreateOrganizationLabelMutation();

  const validationSchema = yup.object({
    name: yup.string().required('Label name is required').max(50),
    color: colorInputValidation()
  });

  const isLoading = isUpdating || isCreating;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const labelArgs = { ...values, id: label?.id };
          if (hasId(labelArgs)) {
            await update(labelArgs);
          } else {
            await create(values);
          }

          setSubmitting(false);
          trackEvent({
            object: 'organization_label',
            action: label?.id ? 'updated' : 'created',
            properties: { id: label?.id, name: values.name, color: values.color, description: values.description }
          });
          onCancel();
        } catch (e) {
          setSubmitting(false);
          errorToast(e);
        }
      }}
      initialValues={{
        name: label?.name || '',
        description: label?.description || '',
        color: label?.color || ''
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Card
            title={`${label?.id ? 'Update' : 'Create a'} Label`}
            rightElement={<Button minimal icon="cross" onClick={onCancel} />}
          >
            <CardBody>
              <div className="block">
                <LabelTag className="mr-16" color={values.color} name={values.name || 'Label Preview'} />
              </div>
              <TextInput name="name" label="Label name" />
              <TextInput name="description" label="Description" />
              <ColorInput name="color" label="Color" setValue={hex => setFieldValue('color', hex)} />
            </CardBody>
            <CardFooter>
              <Button intent="primary" type="submit" loading={isLoading}>
                Save changes
              </Button>
              <Button onClick={onCancel} disabled={isLoading}>
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
