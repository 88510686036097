import React from 'react';
import { BlockMatch, LLMOutputComponent, LLMOutputMatcher, LookBackFunction } from '@llm-ui/react';
import { parseCsv } from '@llm-ui/csv';
import { regexMatcher, removeStartEndChars } from '@llm-ui/shared';
import { LlmAssistantMessageBreakout } from 'types/__generated__/GovlyApi';
import { CitationFileViewer } from './CitationFileViewer';

const CITATION_START_CHAR = '【';
const CITATION_END_CHAR = '】';
const CITATION_CROSS_CHAR = '†';
const CITATION_COLON_CHAR = ':';

type CitationBlockOptions = {
  type?: string;
  startChar?: string;
  endChar?: string;
};

const getDefaultOptions = (options: CitationBlockOptions = {}) => ({
  type: 'file_citation',
  startChar: CITATION_START_CHAR,
  endChar: CITATION_END_CHAR,
  ...options
});

export const citationBlockLookBack = (options: CitationBlockOptions = {}): LookBackFunction => {
  const { startChar, endChar } = getDefaultOptions(options);

  return ({ output, isComplete, visibleTextLengthTarget }) => {
    const cleanOutput = removeStartEndChars(output, { startChar, endChar });

    const [lineNum, rest] = cleanOutput.split(CITATION_COLON_CHAR);
    const [colNum, source] = rest ? rest.split(CITATION_CROSS_CHAR) : ['', ''];

    let visibleText = '';
    let remainingChars = visibleTextLengthTarget;

    if (lineNum) {
      visibleText += lineNum;
      remainingChars -= lineNum.length;

      if (colNum && remainingChars > 0) {
        visibleText += CITATION_COLON_CHAR + colNum;
        remainingChars -= colNum.length + 1;

        if (source && remainingChars > 0) {
          visibleText += CITATION_CROSS_CHAR;
          remainingChars -= CITATION_CROSS_CHAR.length;

          if (remainingChars > 0) {
            visibleText += source.slice(0, remainingChars);
          }
        }
      }
    }

    return {
      output: cleanOutput,
      visibleText: visibleText || (isComplete ? ' ' : '')
    };
  };
};

export const findCompleteCitationBlock = (options: CitationBlockOptions = {}): LLMOutputMatcher => {
  const { startChar, endChar } = getDefaultOptions(options);

  const regex = new RegExp(`${startChar}([0-9]+${CITATION_COLON_CHAR}[0-9]+${CITATION_CROSS_CHAR}[\\w-]+)${endChar}`);
  return regexMatcher(regex);
};

export const findPartialCitationBlock = (options: CitationBlockOptions = {}): LLMOutputMatcher => {
  const { startChar } = getDefaultOptions(options);

  const regex = new RegExp(`${startChar}([0-9]+${CITATION_COLON_CHAR}[0-9]+${CITATION_CROSS_CHAR}[\\w-]+)?`);
  return regexMatcher(regex);
};

type CitationBlockProps = {
  blockMatch: BlockMatch;
  annotations?: LlmAssistantMessageBreakout['annotations'];
  citationIndex?: number;
};

export const CitationComponent: LLMOutputComponent<CitationBlockProps> = ({
  blockMatch,
  annotations,
  citationIndex
}) => {
  if (!blockMatch.isVisible) {
    return null;
  }

  try {
    // Parse the citation from the blockMatch
    const [_type] = parseCsv(blockMatch.output, {
      type: '.*',
      delimiter: CITATION_CROSS_CHAR,
      startChar: CITATION_START_CHAR,
      endChar: CITATION_END_CHAR
    });

    // Create the full citation text format that matches the annotation format
    const citationText = `${CITATION_START_CHAR}${blockMatch.output}${CITATION_END_CHAR}`;

    // Find the matching annotation by comparing the exact citation text
    const matchingAnnotation = annotations?.find(annotation => annotation.text === citationText);
    const externalId = matchingAnnotation?.fileCitation?.fileId;

    return <CitationFileViewer externalId={externalId} citationIndex={citationIndex} />;
  } catch (error) {
    console.error('[CitationComponent] Error parsing citation:', error);
    return null;
  }
};
