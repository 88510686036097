import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';

export const SettingsPage = () => {
  return (
    <Main>
      <MainColumn columnSpan={12}>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </MainColumn>
    </Main>
  );
};
