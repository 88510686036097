import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { Icon, IconName } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

export type NavLinkTabProps = {
  className?: string;
  text: string;
  icon?: IconName;
  prefixMatch?: boolean;
} & (({ to: string } & Omit<LinkProps, 'to'>) | React.HTMLAttributes<HTMLAnchorElement>);

export const NavLinkTab = ({ className, icon, text, prefixMatch, ...rest }: NavLinkTabProps) => {
  const { pathname } = useLocation();
  const to = 'to' in rest ? rest.to : '';
  const current = prefixMatch ? `${to}`.startsWith(pathname) : `${to}` === pathname;
  const Tag = to ? Link : 'a';

  return (
    <Tag
      to={to}
      data-test={`nav-link-${text}`}
      aria-current={current ? 'page' : undefined}
      className={cn(
        className,
        current
          ? 'border-blue-700 text-blue-700 dark:border-blue-100 dark:text-blue-100'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-100',
        'whitespace-nowrap border-b-2 px-1 pb-3 text-base font-medium no-underline'
      )}
      {...rest}
    >
      <div className="flex items-center">
        {icon && <Icon className="mr-1" icon={icon} />}
        {text}
      </div>
    </Tag>
  );
};
