import { ActorRun, ActorRunSysAdmin } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetActorRuns = {
  params: { page?: number; per?: number };
  result: ActorRun[] | ActorRunSysAdmin[];
};

export const actorRunsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getActorRuns: build.query<GetActorRuns['result'], GetActorRuns['params']>({
      query: params => ({
        url: '/v2/actor_runs',
        params
      })
    })
  })
});

export const { useGetActorRunsQuery } = actorRunsApi;
