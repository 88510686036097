import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { useGetFormikFilterCountByField } from 'app/hooks/useGetFormikFilterCountByField';
import { FormKeys } from 'app/lib/formikToBp';
import { useFormikContext, FieldInputProps } from 'formik';
import get from 'lodash-es/get';

const makeGetFormikFilterCardListItemProps = <Values>({
  getFieldProps,
  setFieldValue,
  submitForm,
  getFilterCountByField
}: {
  getFilterCountByField: ({ names }: { names: FormKeys<Values>[]; submitOnChange?: boolean }) => Record<string, number>;
  getFieldProps: (name: string) => FieldInputProps<unknown>;
  setFieldValue: (name: string, value: string[]) => void;
  submitForm: () => void;
}) => {
  return ({ names, submitOnChange = false }: { names: FormKeys<Values>[]; submitOnChange?: boolean }) => {
    const defaultState = useDefaultSearchStateStore.getState().defaultState as Values;
    const fields = names.map(name => getFieldProps(name));
    const filterCountByField = getFilterCountByField({ names });
    const filterCount = Object.values(filterCountByField).reduce((acc, count) => acc + count, 0);

    const onClear = () => {
      fields.forEach(field => {
        const val = defaultState ? get(defaultState, field.name) ?? [] : [];
        setFieldValue(field.name as FormKeys<Values>, val);
      });
      if (submitOnChange) {
        submitForm();
      }
    };

    return { filterCount, onClear };
  };
};

export const useGetFormikFilterCardListItemProps = <Values>() => {
  const { submitForm, getFieldProps, setFieldValue } = useFormikContext<Values>();
  const getFilterCountByField = useGetFormikFilterCountByField<Values>();

  return makeGetFormikFilterCardListItemProps<Values>({
    getFieldProps,
    setFieldValue,
    submitForm,
    getFilterCountByField
  });
};

export const useFormikFilterCardListItem = <Values>({
  names,
  submitOnChange = false
}: {
  names: FormKeys<Values>[];
  submitOnChange?: boolean;
}) => {
  return useGetFormikFilterCardListItemProps<Values>()({ names, submitOnChange });
};
