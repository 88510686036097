import React from 'react';
import { lazily } from 'react-lazily';
import { createBrowserRouter, LoaderFunction, RouteObject } from 'react-router-dom';

import { RouteLayout } from 'app/organisms/RouteLayout/RouteLayout';
import { BrowserWarning } from 'app/molecules/BrowserWarning/BrowserWarning';
import { RouteErrorBoundary } from 'app/organisms/RouteErrorBoundary/RouteErrorBoundary';
import { pageViewLoader } from 'app/lib/trackEvent';
import { OppDetailsAttachmentsPage } from './pages/OppDetailsAttachmentsPage/OppDetailsAttachmentsPage';
import { OppDetailsHistoryPage } from './pages/OppDetailsHistoryPage/OppDetailsHistoryPage';

const { HomePage } = lazily(() => import('app/pages/HomePage/HomePage'));
const { SignInPage } = lazily(() => import('app/pages/SignInPage/SignInPage'));
const { SignInForm } = lazily(() => import('app/organisms/SignInForm/SignInForm'));
const { SignInVerification } = lazily(() => import('app/organisms/SignInVerification/SignInVerification'));
const { RegisterPage } = lazily(() => import('app/pages/RegisterPage/RegisterPage'));
const { RegisterForm } = lazily(() => import('app/organisms/RegisterForm/RegisterForm'));
const { RegisterVerification } = lazily(() => import('app/organisms/RegisterVerification/RegisterVerification'));

const { VendorProfilesShowPage } = lazily(() => import('app/pages/VendorProfilesShowPage/VendorProfilesShowPage'));
const { VendorProfilesSearchPage } = lazily(
  () => import('app/pages/VendorProfilesSearchPage/VendorProfilesSearchPage')
);
const { VendorProfileSummary } = lazily(() => import('app/organisms/VendorProfileSummary/VendorProfileSummary'));
const { VendorProfilePartners } = lazily(() => import('app/organisms/VendorProfilePartners/VendorProfilePartners'));
const { VendorProfileSpending } = lazily(() => import('app/organisms/VendorProfileSpending/VendorProfileSpending'));
const { VendorProfileContracts } = lazily(() => import('app/organisms/VendorProfileContracts/VendorProfileContracts'));

const { AuthenticatedRouteLayout } = lazily(
  () => import('app/organisms/AuthenticatedRouteLayout/AuthenticatedRouteLayout')
);
const { UnsubscribePage } = lazily(() => import('app/pages/UnsubscribePage/UnsubscribePage'));
const { OnboardingPage } = lazily(() => import('app/pages/OnboardingPage/OnboardingPage'));
const { SettingsPage } = lazily(() => import('app/pages/SettingsPage/SettingsPage'));
const { UserProfilePage } = lazily(() => import('app/pages/UserProfilePage/UserProfilePage'));
const { PartnershipsPage } = lazily(() => import('app/pages/PartnershipsPage/PartnershipsPage'));
const { PartnershipShowPage } = lazily(() => import('app/pages/PartnershipShowPage/PartnershipShowPage'));
const { SysAdminPage } = lazily(() => import('app/pages/SysAdminPage/SysAdminPage'));
const { WorklistWorkspaces } = lazily(() => import('app/organisms/WorklistWorkspaces/WorklistWorkspaces'));
const { ActivityNotificationsTable } = lazily(
  () => import('app/organisms/ActivityNotificationsTable/ActivityNotificationsTable')
);

const { OppSourcePage } = lazily(() => import('app/pages/OppSourcePage/OppSourcePage'));
const { OppSavedSearchesPage } = lazily(() => import('app/pages/OppSavedSearchesPage/OppSavedSearchesPage'));
const { ContactSavedSearchesPage } = lazily(
  () => import('app/pages/ContactSavedSearchesPage/ContactSavedSearchesPage')
);
const { AwardSavedSearchesPage } = lazily(() => import('app/pages/AwardSavedSearchesPage/AwardSavedSearchesPage'));
const { OppSearchAnalyticsPage } = lazily(() => import('app/pages/OppSearchAnalyticsPage/OppSearchAnalyticsPage'));
const { OppSearchResultTable } = lazily(() => import('app/organisms/OppSearchResultTable/OppSearchResultTable'));
const { OppSearchDebugger } = lazily(() => import('app/organisms/OppSearchDebugger/OppSearchDebugger'));
const { OppDetailsOverviewPage } = lazily(() => import('app/pages/OppDetailsOverviewPage/OppDetailsOverviewPage'));
const { OppDetailsWorkspacesPage } = lazily(
  () => import('app/pages/OppDetailsWorkspacesPage/OppDetailsWorkspacesPage')
);
const { UserProfile } = lazily(() => import('app/organisms/UserProfile/UserProfile'));
const { UserProfileSettings } = lazily(() => import('app/organisms/UserProfileSettings/UserProfileSettings'));
const { FeedProfilesTable } = lazily(() => import('app/organisms/FeedProfilesTable/FeedProfilesTable'));
const { ProviderFeedsTable } = lazily(() => import('app/organisms/ProviderFeedsTable/ProviderFeedsTable'));
const { VendorsTable } = lazily(() => import('app/organisms/VendorsTable/VendorsTable'));
const { VendorProfileForm } = lazily(() => import('app/organisms/VendorProfileForm/VendorProfileForm'));
const { SysAdminSettings } = lazily(() => import('app/organisms/SysAdminSettings/SysAdminSettings'));
const { SysAdminUsers } = lazily(() => import('app/organisms/SysAdminUsers/SysAdminUsers'));
const { PartnershipRequests } = lazily(() => import('app/organisms/PartnershipRequests/PartnershipRequests'));
const { PartnerPortalLinks } = lazily(() => import('app/organisms/PartnerPortalLinks/PartnerPortalLinks'));
const { ContractAutomationPage } = lazily(() => import('app/pages/ContractAutomationPage/ContractAutomationPage'));
const { ContractManagement } = lazily(() => import('app/organisms/ContractManagement/ContractManagement'));
const { OrganizationSettings } = lazily(() => import('app/organisms/OrganizationSettings/OrganizationSettings'));
const { OrganizationSysAdminSettings } = lazily(
  () => import('app/organisms/OrganizationSysAdminSettings/OrganizationSysAdminSettings')
);
const { OrganizationSysAdminForm } = lazily(
  () => import('app/organisms/OrganizationSysAdminForm/OrganizationSysAdminForm')
);
const { VendorProfileCustomizationForm } = lazily(
  () => import('app/organisms/VendorProfileCustomizationForm/VendorProfileCustomizationForm')
);
const { UsersTable } = lazily(() => import('app/organisms/UsersTable/UsersTable'));
const { ManagedFeeds } = lazily(() => import('app/organisms/ManagedFeeds/ManagedFeeds'));
const { ManagedFeed } = lazily(() => import('app/organisms/ManagedFeed/ManagedFeed'));
const { SearchableFeeds } = lazily(() => import('app/organisms/SearchableFeeds/SearchableFeeds'));
const { SearchableFeedsFed } = lazily(() => import('app/organisms/SearchableFeeds/SearchableFeedsFed'));
const { SearchableFeedsListSLED } = lazily(
  () => import('app/organisms/SearchableFeeds/SearchableFeedsListSLED/SearchableFeedsListSLED')
);
const { LabelSettings } = lazily(() => import('app/organisms/LabelSettings/LabelSettings'));
const { OrganizationInsightsPage } = lazily(
  () => import('app/pages/OrganizationInsightsPage/OrganizationInsightsPage')
);
const { ApiSettingsPage } = lazily(() => import('app/pages/ApiSettingsPage/ApiSettingsPage'));
const { SouptoolsPage } = lazily(() => import('app/pages/SouptoolsPage/SouptoolsPage'));
const { SouptoolsTrsPage } = lazily(() => import('app/pages/SouptoolsTrsPage/SouptoolsTrsPage'));
const { SouptoolsQuotesPage } = lazily(() => import('app/pages/SouptoolsQuotesPage/SouptoolsQuotesPage'));
const { SouptoolsTrTemplatesPage } = lazily(
  () => import('app/pages/SouptoolsTrTemplatesPage/SouptoolsTrTemplatesPage')
);
const { SouptoolsQuotePage } = lazily(() => import('app/pages/SouptoolsQuotePage/SouptoolsQuotePage'));
const { SouptoolsTrPage } = lazily(() => import('app/pages/SouptoolsTrPage/SouptoolsTrPage'));
const { SouptoolsCatalogPage } = lazily(() => import('app/pages/SouptoolsCatalogPage/SouptoolsCatalogPage'));
const { PartnershipRequestsTable } = lazily(
  () => import('app/organisms/PartnershipRequestsTable/PartnershipRequestsTable')
);
const { WorklistCalendar } = lazily(() => import('app/organisms/WorklistCalendar/WorklistCalendar'));
const { PartnershipsTable } = lazily(() => import('app/organisms/PartnershipsTable/PartnershipsTable'));
const { PartnershipDetailsCard } = lazily(() => import('app/organisms/PartnershipDetailsCard/PartnershipDetailsCard'));
const { PartnerFeeds } = lazily(() => import('app/organisms/PartnerFeeds/PartnerFeeds'));
const { PartnershipContactsTable } = lazily(
  () => import('app/organisms/PartnershipContactsTable/PartnershipContactsTable')
);
const { ProfileClaimsTable } = lazily(() => import('app/organisms/ProfileClaimsTable/ProfileClaimsTable'));
const { WebhookPage } = lazily(() => import('app/pages/WebhookPage/WebhookPage'));
const { ContactPage } = lazily(() => import('app/pages/ContactPage/ContactPage'));
const { SLEDDashboardTrawlerConfigs } = lazily(
  () => import('app/organisms/SLEDDashboardTrawlerConfigs/SLEDDashboardTrawlerConfigs')
);
const { AwardSearchResults } = lazily(() => import('app/organisms/AwardSearchResults/AwardSearchResults'));
const { AwardSearchAnalytics } = lazily(() => import('app/organisms/AwardSearchAnalytics/AwardSearchAnalytics'));
const { AwardDetailsOverviewPage } = lazily(() => import('./pages/AwardDetailsOverviewPage/AwardDetailsOverviewPage'));

const { GovernmentEntitiesPage } = lazily(() => import('app/pages/GovernmentEntitiesPage/GovernmentEntitiesPage'));
const { GovernmentEntityPage } = lazily(() => import('app/pages/GovernmentEntityPage/GovernmentEntityPage'));

const { BudgetsOverviewPage } = lazily(() => import('app/pages/BudgetsOverviewPage/BudgetsOverviewPage'));

const routes: RouteObject[] = [
  { index: true, element: <HomePage /> },
  { path: 'browser_warning', element: <BrowserWarning /> },
  {
    path: 'sign_in',
    element: <SignInPage />,
    children: [
      { index: true, element: <SignInForm /> },
      { path: 'verify', element: <SignInVerification /> }
    ]
  },
  {
    path: 'register',
    element: <RegisterPage />,
    children: [
      { index: true, element: <RegisterForm /> },
      {
        path: 'verify',
        element: <RegisterVerification />
      }
    ]
  },
  { path: 'vendors', element: <VendorProfilesSearchPage /> },
  {
    path: 'vendors/:slug',
    element: <VendorProfilesShowPage />,
    children: [
      { index: true, element: <VendorProfileSummary /> },
      { path: 'partners', element: <VendorProfilePartners /> },
      { path: 'spending', element: <VendorProfileSpending /> },
      { path: 'contracts', element: <VendorProfileContracts /> }
    ]
  },
  {
    path: '/',
    element: <AuthenticatedRouteLayout />,
    errorElement: <RouteErrorBoundary />,
    children: [
      { path: 'unsubscribe', element: <UnsubscribePage /> },
      { path: 'onboarding', element: <OnboardingPage /> },
      {
        path: 'opportunities/saved_searches',
        element: <OppSavedSearchesPage />
      },
      {
        path: 'contacts/saved_searches',
        element: <ContactSavedSearchesPage />
      },
      {
        path: 'awards/saved_searches',
        element: <AwardSavedSearchesPage />
      },
      {
        path: 'opportunities/:id/sources/:sourceId',
        loader: pageViewLoader('opp_source'),
        element: <OppSourcePage />
      },
      {
        path: 'souptools',
        element: <SouptoolsPage />,
        children: [
          { path: 'trs', element: <SouptoolsTrsPage /> },
          { path: 'quotes', element: <SouptoolsQuotesPage /> },
          { path: 'tr_templates', element: <SouptoolsTrTemplatesPage /> },
          { path: 'quotes/:id', element: <SouptoolsQuotePage /> },
          { path: 'trs/:id', element: <SouptoolsTrPage /> },
          { path: 'catalog', element: <SouptoolsCatalogPage /> }
        ]
      },
      {
        path: 'opportunities/:oppId/workspaces/:workspaceId',
        lazy: () =>
          import('app/pages/OppWorkspacePage/OppWorkspacePage').then(({ loader, handle }) => ({
            loader: composeLoaders(pageViewLoader('workspace'), loader),
            handle
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage />
              }))
          },
          {
            path: 'opportunity',
            lazy: () =>
              import('app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage tab="Opportunity" />
              }))
          },
          {
            path: 'assistant',
            lazy: () =>
              import('app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage tab="Assistant" />
              }))
          }
        ]
      },
      {
        path: 'partnerships/:id',
        element: <PartnershipShowPage />,
        children: [
          { index: true, element: <PartnershipDetailsCard /> },
          { path: 'shared_feeds', element: <PartnerFeeds /> },
          { path: 'contacts', element: <PartnershipContactsTable /> }
        ]
      },
      {
        path: 'partnerships',
        element: <PartnershipsPage />,
        children: [
          { index: true, element: <PartnershipsTable /> },
          {
            path: 'partner_portals',
            children: [{ index: true, element: <PartnerPortalLinks /> }]
          },
          {
            path: 'invites',
            element: <PartnershipRequests />,
            children: [
              { index: true, element: <PartnershipRequestsTable /> },
              { path: 'sent', element: <PartnershipRequestsTable /> }
            ]
          }
        ]
      },
      {
        path: 'contacts',
        lazy: () =>
          import('app/pages/ContactSearchPage/ContactSearchPage').then(({ loader, ContactSearchPage }) => ({
            loader,
            element: <ContactSearchPage />
          }))
      },
      {
        path: 'contacts/:id',
        loader: pageViewLoader('contact'),
        element: <ContactPage />
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        children: [
          { path: 'users', element: <UsersTable />, index: true },
          { path: 'organization_settings', element: <OrganizationSettings /> },
          { path: 'vendor_profile', element: <VendorProfileCustomizationForm /> },
          { path: 'contract_management', element: <ContractManagement /> },
          { path: 'contract_automation', element: <ContractAutomationPage /> },
          { path: 'labels', element: <LabelSettings /> },
          { path: 'api_settings', element: <ApiSettingsPage /> },
          { path: 'contract_management/:feedProfile', element: <ContractManagement /> },
          { path: 'organization_sys_admin_settings', element: <OrganizationSysAdminSettings /> }
        ]
      },
      {
        path: 'opportunities/:id',
        lazy: () =>
          import('app/pages/OppDetailsLayoutPage/OppDetailsLayoutPage').then(
            ({ loader, OppDetailsLayoutPage, handle }) => ({
              loader: composeLoaders(pageViewLoader('opp'), loader),
              handle,
              element: <OppDetailsLayoutPage />
            })
          ),
        children: [
          { index: true, element: <OppDetailsOverviewPage /> },
          { path: 'workspaces', element: <OppDetailsWorkspacesPage /> },
          { path: 'attachments', element: <OppDetailsAttachmentsPage /> },
          { path: 'history', element: <OppDetailsHistoryPage /> },
          {
            path: 'providers',
            lazy: () =>
              import('app/pages/OppDetailsProvidersPage/OppDetailsProvidersPage').then(
                ({ OppDetailsProvidersPage }) => ({ element: <OppDetailsProvidersPage /> })
              )
          }
        ]
      },
      {
        path: 'opportunities',
        lazy: () =>
          import('app/pages/OppSearchPage/OppSearchPage').then(({ loader, OppSearchPage, handle }) => ({
            loader,
            handle,
            element: <OppSearchPage />
          })),
        children: [
          { index: true, element: <OppSearchResultTable /> },
          { path: 'analytics', element: <OppSearchAnalyticsPage /> },
          { path: 'debug', element: <OppSearchDebugger /> }
        ]
      },
      { path: 'opportunities/insights', element: <OrganizationInsightsPage /> },
      {
        path: 'opportunities/searchable_feeds',
        element: <SearchableFeeds />,
        children: [
          { index: true, element: <SearchableFeedsFed /> },
          { path: 'fed', element: <SearchableFeedsFed /> },
          { path: 'sled', element: <SearchableFeedsListSLED /> },
          { path: 'managed', element: <ManagedFeeds /> },
          { path: 'managed/:id', element: <ManagedFeed /> }
        ]
      },
      {
        path: 'awards/:id',
        lazy: () =>
          import('app/pages/AwardDetailsLayoutPage/AwardDetailsLayoutPage').then(
            ({ loader, AwardDetailsLayoutPage, handle }) => ({
              loader: composeLoaders(pageViewLoader('award'), loader),
              handle,
              element: <AwardDetailsLayoutPage />
            })
          ),
        children: [{ index: true, element: <AwardDetailsOverviewPage /> }]
      },
      {
        path: 'awards',
        lazy: () =>
          import('app/pages/AwardSearchPage/AwardSearchPage').then(({ loader, AwardSearchPage, handle }) => ({
            loader,
            handle,
            element: <AwardSearchPage />
          })),
        children: [
          { index: true, element: <AwardSearchResults /> },
          { path: 'analytics', element: <AwardSearchAnalytics /> },
          { path: 'results', element: <AwardSearchResults /> }
        ]
      },
      {
        path: 'budgets',
        children: [
          { path: 'analysis', element: <BudgetsOverviewPage /> },
          {
            path: 'agencies',
            element: <GovernmentEntitiesPage />
          },
          { path: 'agencies/:id', element: <GovernmentEntityPage /> }
        ]
      },
      {
        path: 'users',
        element: <UserProfilePage />,
        children: [
          { path: 'new', element: <UserProfileSettings /> },
          { path: ':id', element: <UserProfile /> },
          { path: ':id/edit', element: <UserProfileSettings /> }
        ]
      },
      {
        path: 'webhooks/:id',
        element: <WebhookPage />
      },
      {
        path: 'worklist',
        lazy: () =>
          import('app/pages/WorklistPage/WorklistPage').then(({ WorklistPage, loader, handle }) => ({
            loader,
            handle,
            element: <WorklistPage />
          })),
        children: [
          {
            path: 'workspaces',
            index: true,
            element: <WorklistWorkspaces />
          },
          { path: 'calendar', element: <WorklistCalendar /> },
          { path: 'notifications', element: <ActivityNotificationsTable /> }
        ]
      },
      {
        path: 'sys_admin',
        element: <SysAdminPage />,
        children: [
          { index: true, element: <h1>Govly Sys Admin App</h1> },
          {
            path: 'sled_dashboard',
            element: <SLEDDashboardTrawlerConfigs />
          },
          { path: 'settings', element: <SysAdminSettings /> },
          {
            path: 'error_handling',
            lazy: () =>
              import('app/pages/SysAdminErrorHandlingPage/SysAdminErrorHandlingPage').then(
                ({ SysAdminErrorHandlingPage }) => ({ element: <SysAdminErrorHandlingPage /> })
              )
          },
          { path: 'system_admins', element: <SysAdminUsers /> },
          { path: 'vendors', element: <VendorsTable /> },
          { path: 'vendors/:id', element: <VendorProfileForm /> },
          { path: 'organizations/new', element: <OrganizationSysAdminForm /> },
          { path: 'feed_profiles', element: <FeedProfilesTable /> },
          {
            path: 'provider_feeds',
            element: <ProviderFeedsTable />
          },
          { path: 'profile_claims', element: <ProfileClaimsTable /> },
          {
            path: 'partnership_requests',
            element: <PartnershipRequestsTable isAdmin />
          }
        ]
      }
    ]
  }
];

const composeLoaders = (...loaders: LoaderFunction[]): LoaderFunction => {
  return async (...args) => {
    let result = null;

    for (const loader of loaders) {
      result = await loader(...args);
    }

    return result;
  };
};

const root = [
  {
    path: '/',
    element: <RouteLayout />,
    children: routes,
    errorElement: <RouteErrorBoundary />
  }
];

export const router = createBrowserRouter(root);
