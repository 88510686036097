import React, { useEffect } from 'react';
import { useParams, Outlet, LoaderFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { awardsApi, useGetAwardQuery } from 'api/awardsApi';

import { Loading } from 'app/atoms/Loading/Loading';
import { Main } from 'app/atoms/Main/Main';
import { ApplicationError } from 'app/atoms/ErrorFallback/ApplicationError';

import { store } from 'app/store';
import { getNonEmptyString } from 'app/lib/strings';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';

type LoaderData = { awardId?: string };

export const loader: LoaderFunction = ({ params }): LoaderData => {
  return { awardId: params.id };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ awardId }) => {
  if (!awardId) return [];

  /**
   * This is a hack to make the breadcrumb handle wait for a potential navigation to finish
   * in the case where the awardId id actually a contract number.
   */
  if (!/^\d+$/.test(awardId)) {
    throw new Error('Invalid award ID');
  }

  const award = await store
    .dispatch(awardsApi.endpoints.getAward.initiate({ id: awardId }, { subscribe: false }))
    .then(({ data }) => data);

  return award
    ? [
        { to: `/awards`, text: 'Awards' },
        {
          to: `/awards/${award.id}`,
          text: [getNonEmptyString(award.awardType, award.idvType, award.awardOrIdvFlag), award.awardIdPiid].join(' ')
        }
      ]
    : [{ to: `/awards`, text: 'Awards' }];
});

export const AwardDetailsLayoutPage = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetAwardQuery({ id: id ?? '' }, { skip: !id });
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * If the id in the URL is the contract number, navigate to the actual award page.
     * This is necessary to ensure that WorkableDetailActions render correctly.
     */
    if (data && data.id !== id) {
      navigate(`/awards/${data.id}`, { replace: true });
    }
  }, [id, data, navigate]);

  if (isLoading || (data && data.id !== id)) {
    return <Loading type="detail-layout" />;
  }

  if (isError) {
    return <ApplicationError />;
  }

  return (
    <Main>
      <Outlet />
    </Main>
  );
};
