import React from 'react';
import { Icon, IconProps, Popover, PopoverProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { useEventTracking } from 'app/hooks/useEventTracking';

type Props = Omit<Partial<IconProps>, 'children'> & {
  children: React.ReactNode;
  target?: React.ReactNode;
  targetClassName?: string;
  shouldTrack?: boolean;
  trackedEventName?: string;
  skip?: boolean;
  popoverProps?: Partial<PopoverProps>;
};

export const InfoTooltip = ({
  children,
  target,
  targetClassName,
  shouldTrack,
  trackedEventName,
  skip,
  popoverProps: { className: popoverClassName, ...popoverProps } = {},
  ...rest
}: Props) => {
  const { trackEvent } = useEventTracking();

  if (skip) {
    return target;
  }

  const content = <div className="prose prose-sm max-w-md p-4 text-sm dark:prose-invert">{children}</div>;

  const track = () => {
    if (shouldTrack && trackedEventName)
      trackEvent({
        object: 'info_tooltip',
        action: 'opened',
        properties: { tooltipHeading: trackedEventName }
      });
  };

  return (
    <Popover
      onOpened={track}
      className={cn('!cursor-help', popoverClassName)}
      content={content}
      interactionKind="hover"
      {...popoverProps}
    >
      <div className="!cursor-help">
        {target || <Icon icon="info-sign" className={cn('text-gray-500', targetClassName)} {...rest} />}
      </div>
    </Popover>
  );
};
