import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { NonIdealState } from '@blueprintjs/core';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { merge } from 'highcharts';

const defaultOptions: Highcharts.Options = {
  chart: {
    type: 'column'
  },

  legend: {
    enabled: false
  },

  tooltip: {
    shared: false,
    useHTML: true,
    formatter() {
      const x = this.x;

      const chart = this.series.chart;
      const firstSeries = chart.series[0];
      const yAxisOneDataPoint = firstSeries.points.filter(point => point.name === x)[0];
      const secondSeries = chart.series[1];
      const yAxisTwoDataPoint = secondSeries.points.filter(point => point.name === x)[0];

      return `${x}<br><br> Amount Awarded: $${yAxisOneDataPoint.y}<br> New Awards: ${yAxisTwoDataPoint.y}`;
    }
  },

  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      colorByPoint: true
    },
    series: {
      enableMouseTracking: true
    }
  },

  accessibility: {
    enabled: true
  }
};

type ComboGraphProps = {
  title: string;
  options: Highcharts.Options;
};

export const ComboGraph = ({ title, options }: ComboGraphProps) => {
  const chartOptions: Highcharts.Options = useMemo(() => {
    return merge(defaultOptions, options);
  }, [options]);

  return (
    <Card title={title}>
      <CardSection>
        {chartOptions?.series?.length ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        ) : (
          <NonIdealState icon="horizontal-bar-chart-desc" title="Not enough data." />
        )}
      </CardSection>
    </Card>
  );
};
