import React from 'react';
import { useFormikContext } from 'formik';
import enUS from 'date-fns/locale/en-US';

import { useContactAggregationQuery } from 'api/contactsApi';
import { ContactSearchForm, defaultValues } from 'app/hooks/search/useContactSearchCache';
import { FormikCompositeUserMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeUserMultiSelect';
import { EntityTitle, Tag } from '@blueprintjs/core';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { CardList } from 'app/molecules/CardList/CardList';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { getFiltersCount } from 'app/hooks/useGetFormikFilterCountByField';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { FormikCompositeQueryMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeQueryMultiSelect';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { NavLink } from 'react-router-dom';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';
import { makeSafeFormikFields } from 'app/lib/formik';

type Props = {
  wrapperClassName?: string;
  className?: string;
};

const { safeTuples } = makeSafeFormikFields<ContactSearchForm>();

export const ContactSearchFilters = ({ className, wrapperClassName }: Props) => {
  const { values, setValues, submitForm } = useFormikContext<ContactSearchForm>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<ContactSearchForm>();
  const filtersCount = getFiltersCount(values, v => v.filters);
  const getField = useGetFormikField<ContactSearchForm>();

  // Helps filter collapse states reset correctly
  const rerenderKey = [values.meta.savedSearchId, values.meta.searchState].join(':');

  return (
    <CardList
      key={rerenderKey}
      title="Filter"
      wrapperClassName={wrapperClassName}
      className={className}
      rightElement={
        filtersCount > 0 ? (
          <Tag
            onRemove={e => {
              e.preventDefault();
              const { filters } = defaultValues;
              setValues({ ...values, filters });
              submitForm();
            }}
          >
            {filtersCount}
          </Tag>
        ) : undefined
      }
    >
      <InputFilterCardListItem
        title="Date Range (Last Active)"
        defaultIsOpen
        {...getFilterCardProps({ names: ['filters.dateRange'], submitOnChange: true })}
      >
        <div>
          <DateRangeInput3 {...FormikToBp.toDateRange(getField('filters.dateRange'))} locale={enUS} fill shortcuts />
        </div>
      </InputFilterCardListItem>

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(
          ['filters.contractVehicleNames', 'Include'],
          ['filters.contractVehicleNamesNone', 'Exclude']
        )}
        title="Contract Vehicles"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['contract_vehicles_name_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.buyerIds', 'Include'], ['filters.buyerIdsNone', 'Exclude'])}
        title="Buyer"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['buyers_name_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.buyerDomains', 'Include'], ['filters.buyerDomainsNone', 'Exclude'])}
        title="Buyer Email Domain"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['buyer_domain_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.titles', 'Include'], ['filters.excludeTitles', 'Exclude'])}
        title="Titles"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['titles_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeUserMultiSelect
        title="Following"
        namesAndLabels={safeTuples(
          [
            'filters.following',
            'Include',
            <span key="include">
              Only show contacts <b>any</b> of these people are following
            </span>
          ],
          [
            'filters.excludeFollowing',
            'Exclude',
            <span key="exclude">
              <b>Do not</b> show contacts that these people are following
            </span>
          ]
        ).map(toNameAndLabelWithTooltip)}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.labels', 'Include'], ['filters.labelsNone', 'Exclude'])}
        title="Labels"
        useHook={useGetOrganizationLabelsQuery}
        useTypeaheadProps={{ options: { keys: ['name', 'description'] } }}
        getItemTextLabel={item => item.name ?? ''}
        getItemLabel={label => <LabelTag {...label} />}
        getItemValue={item => item.id}
        getInputProps={({ index }) =>
          index === 0
            ? {
                formGroupProps: {
                  helperText: <NavLink to={'/settings/labels'}>Manage labels</NavLink>
                }
              }
            : {}
        }
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.vendorNames', 'All'], ['filters.vendorNamesNone', 'None'])}
        title="Vendor"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['vendors_name_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.regions', 'All'], ['filters.regionsNone', 'None'])}
        title="Regions"
        useHook={useContactAggregationQuery as InputMultiSelectHook<{ label: string; value: string }>}
        hookArgs={[{ aggs: ['global_region_agg'] }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemTextLabel={item => item.label ?? ''}
        getItemValue={item => item.value}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.setAsides', 'Include'], ['filters.setAsidesNone', 'Exclude'])}
        title="Set Aside"
        useHook={useGetSetAsidesQuery}
        getItemValue={item => item.code ?? 'missing code'}
        getItemTextLabel={item => item.name ?? item.code ?? ''}
        useTypeaheadProps={{ options: { keys: ['name', 'code'] } }}
      />
    </CardList>
  );
};

const toNameAndLabelWithTooltip = ([name, label, tooltip]: readonly [string, string, React.ReactNode]): [
  string,
  React.ReactNode
] => [
  name,
  <EntityTitle
    key={name}
    title={label}
    tags={
      <InfoTooltip icon="info-sign" popoverProps={{ className: 'mt-0' }}>
        {tooltip}
      </InfoTooltip>
    }
  />
];
