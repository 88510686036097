import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Button, MenuItem, NonIdealState } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { useGetTrsQuery, useCreateTrMutation } from 'api/souptoolsTrsApi';
import { TablePagination } from 'app/molecules/TablePagination/TablePagination';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { Loading } from 'app/atoms/Loading/Loading';
import { SouptoolsTrTableRow } from 'app/organisms/SouptoolsTrTableRow/SouptoolsTrTableRow';
import { useEventTracking } from 'app/hooks/useEventTracking';

type StatusValue = 'draft' | 'complete';

export const SouptoolsTrsPage = () => {
  const navigate = useNavigate();
  const { trackEvent } = useEventTracking();
  const [status, setStatus] = useState<StatusValue>('draft');
  const [page, setPage] = useState(1);
  const [createTr, { data: newTr, isLoading: isCreatingTr, isSuccess: trCreated }] = useCreateTrMutation();
  const { data, isFetching } = useGetTrsQuery({ status, page });

  const changeStatus = (status: StatusValue) => {
    setStatus(status);
    setPage(1);
  };

  const { trs, meta } = data ?? { trs: [], meta: { total: 0, currentPage: page, totalPages: page, perPage: 10 } };

  useEffect(() => {
    if (trCreated) {
      navigate(`/souptools/trs/${newTr.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trCreated]);

  return (
    <Card
      title={
        <div className="flex items-center gap-x-2">
          <CardHeading className="inline">Trs</CardHeading>
          {!isFetching && <Tag minimal>{meta.total}</Tag>}
        </div>
      }
      rightElement={
        <div className="flex gap-x-2">
          <Button
            intent="success"
            icon="plus"
            text="New TR"
            onClick={async () => {
              await createTr({});
              trackEvent({
                object: 'souptools_tr',
                action: 'created'
              });
            }}
            loading={isCreatingTr}
          />
          <Select
            activeItem={
              status === 'draft' ? { label: 'Draft', value: 'draft' } : { label: 'Complete', value: 'complete' }
            }
            filterable={false}
            items={
              status === 'draft' ? [{ label: 'Complete', value: 'complete' }] : [{ label: 'Draft', value: 'draft' }]
            }
            onItemSelect={({ value }) => changeStatus(value as StatusValue)}
            itemRenderer={(item, { handleClick }) => (
              <MenuItem key={item.value} onClick={handleClick} text={item.label} />
            )}
          >
            <Button
              rightIcon="chevron-down"
              text={status === 'draft' ? 'Draft' : 'Complete'}
              className="flex justify-between"
            />
          </Select>
        </div>
      }
    >
      {isFetching ? (
        <CardBody>
          <Loading type="flex-row" />
        </CardBody>
      ) : (
        trs.map(tr => (
          <CardBody key={tr.id} className="py-2 lg:py-2">
            <SouptoolsTrTableRow tr={tr} />
          </CardBody>
        ))
      )}
      {!isFetching && !trs?.length && (
        <CardBody>
          <NonIdealState icon="folder-open" title={status === 'draft' ? 'No active trs.' : 'No completed trs.'} />
        </CardBody>
      )}
      {!isFetching && !!trs?.length && (
        <CardBody className="py-2 lg:py-2">
          <TablePagination {...(meta || {})} onPageChange={({ page }) => setPage(page)} />
        </CardBody>
      )}
    </Card>
  );
};
