import React from 'react';
import { useFormikContext } from 'formik';

import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { makeSafeFormikFields } from 'app/lib/formik';
import { FormikCompositeMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeMultiSelect';

const { safeTuples } = makeSafeFormikFields<OppSearchState>();

const namesAndLabels = safeTuples(['filters.recordType', '']);

const FORECAST_ENTITY_TYPES = [
  { label: 'Forecast', value: 'Forecast' },
  { label: 'Prediction', value: 'Prediction' }
];
const NON_FORECAST_ENTITY_TYPES = [
  { label: 'Solicitation', value: 'Solicitation' },
  { label: 'Event', value: 'Event' },
  { label: 'Other', value: 'Other' }
];

export const OppSearchEntityTypeFilter = () => {
  const { values } = useFormikContext<OppSearchState>();
  const {
    filters: { forecastStatus }
  } = values;

  const isForecasted = forecastStatus === 'forecasted';

  return (
    <FormikCompositeMultiSelect
      title="Record Type"
      namesAndLabels={namesAndLabels}
      items={isForecasted ? FORECAST_ENTITY_TYPES : NON_FORECAST_ENTITY_TYPES}
      useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
      getItemValue={item => item.value}
      getItemTextLabel={item => item.label}
    />
  );
};
