import React, { useState } from 'react';
import { Button, Alert, Dialog } from '@blueprintjs/core';

import { isFetchBaseQueryError } from 'api/utils';
import { OrganizationLabel } from 'types/__generated__/GovlyApi';
import { CardBody } from 'app/atoms/Card/Card';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { LabelForm } from 'app/organisms/LabelForm/LabelForm';
import { useDeleteOrganizationLabelMutation } from 'api/organizationLabelsApi';
import { formErrorToast } from 'app/lib/toaster';
import { useAuthorized } from 'app/hooks/useAuthorize';

type LabelRowProps = {
  label: OrganizationLabel;
};

export const LabelRow = ({ label }: LabelRowProps) => {
  const [isDeletingAlert, setIsDeletingAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteLabel, { isLoading: isDeleting }] = useDeleteOrganizationLabelMutation();

  const authorized = useAuthorized({ role: 'admin' });

  return (
    <>
      <CardBody>
        <div className="flex flex-wrap items-center justify-between">
          <div>
            <LabelTag className="mr-16" color={label.color} name={label.name} />
            <span className="mt-2 block text-gray-500">{label.description}</span>
          </div>
          {authorized && (
            <div className="flex gap-2">
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
              <Button onClick={() => setIsDeletingAlert(true)} intent="danger" outlined>
                Delete
              </Button>
            </div>
          )}
        </div>
      </CardBody>
      {authorized && (
        <>
          <Dialog className="p-0" lazy isOpen={isEditing} onClose={() => setIsEditing(false)}>
            <LabelForm label={label} onCancel={() => setIsEditing(false)} />
          </Dialog>

          <Alert
            isOpen={isDeletingAlert}
            icon="trash"
            confirmButtonText="Delete"
            cancelButtonText="Cancel"
            intent="danger"
            onClose={() => setIsDeletingAlert(false)}
            onConfirm={async () => {
              try {
                await deleteLabel(label.id).unwrap();
              } catch (e) {
                if (isFetchBaseQueryError(e) && 'number' == typeof e.status) {
                  formErrorToast(e.status);
                } else {
                  formErrorToast(500);
                }
              } finally {
                setIsDeletingAlert(false);
              }
            }}
            loading={isDeleting}
          >
            <p>Are you sure? Deleting a label will remove it from all Workspaces.</p>
          </Alert>
        </>
      )}
    </>
  );
};
