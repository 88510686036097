import React from 'react';
import { DateTime } from 'luxon';
import { Tag } from '@blueprintjs/core';

import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';

export const UsersTableLastActivity = ({
  lastActiveAt,
  inviteSentAt
}: {
  lastActiveAt?: string;
  inviteSentAt?: string;
}) => {
  if (lastActiveAt) {
    const date = DateTime.fromISO(lastActiveAt);
    return date.toLocaleString(DateTime.DATE_SHORT);
  }

  if (!lastActiveAt && inviteSentAt) {
    return (
      <Tag minimal intent="warning">
        {` Invite sent on ${formatTime(inviteSentAt, DATETIME_24_SHORT)}`}
      </Tag>
    );
  }
  return 'Never';
};
