import React from 'react';
import { useFormikContext } from 'formik';

import { useSearchMutation } from 'api/oppsApi';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';

export const OppSearchDebugger = () => {
  const { values } = useFormikContext<OppSearchState>();
  const { query, filters, meta } = values;
  const [_search, resp] = useSearchMutation({ fixedCacheKey: 'opp-search' });

  return (
    <>
      <pre className="prose prose-sm prose-code block overflow-x-scroll whitespace-pre rounded-md bg-gray-700 p-2 text-gray-50 shadow">
        <code>
          {`Endpoint: /v2/opps/search\n`}
          {`Query status: ${resp.status}\n\n`}
          {`Query: ${JSON.stringify(query, null, 2)}\n\n`}
          {`Query meta: \n\n`}
          {JSON.stringify(meta, null, 2)}
          {`\n\nQuery filters: \n\n`}
          {JSON.stringify(filters, null, 2)}
        </code>
      </pre>
      {resp.data && (
        <pre className="prose prose-sm prose-code mt-2 block overflow-x-scroll whitespace-pre rounded-md bg-gray-700 p-2 text-gray-50 shadow">
          <code>{JSON.stringify(resp.data, null, 2)}</code>
        </pre>
      )}
    </>
  );
};
