import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { CrmInteraction } from 'types/__generated__/GovlyApi';

import { useEventTracking } from 'app/hooks/useEventTracking';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { useCreateCrmInteractionMutation } from 'api/crmInteractionsApi';
import { successToast } from 'app/lib/toaster';

export const OppSearchCrmButton = ({
  id,
  interaction,
  isLoading
}: {
  id: string;
  interaction?: CrmInteraction;
  isLoading: boolean;
}) => {
  const { trackEvent } = useEventTracking();
  const { data: organization, isLoading: organizationLoading } = useGetCurrentOrganizationQuery();
  const [interact, { isLoading: isInteracting }] = useCreateCrmInteractionMutation();
  const crmConnection = organization?.activeCrmConnection;

  if (organizationLoading || !crmConnection?.hasOppPushingEnabled) {
    return null;
  }

  return (
    <Tooltip content={interaction?.pushedAt ? `Pushed to ${crmConnection.name} by ${interaction?.pushedBy}` : ''}>
      <Button
        disabled={isLoading || isInteracting || organizationLoading || !!interaction?.pushedAt}
        icon={interaction?.pushedAt ? 'tick-circle' : 'send-to-graph'}
        text={crmConnection?.name as React.ReactNode}
        loading={isInteracting}
        intent={interaction?.pushedAt ? 'success' : 'none'}
        onClick={async () => {
          await interact({ syncableType: 'Opp', syncableId: id, pushed: true });
          successToast(`Opportunity pushed to ${crmConnection.name}`);
          trackEvent({
            object: 'opp',
            action: 'pushed_to_crm',
            properties: {
              oppId: id
            }
          });
        }}
      />
    </Tooltip>
  );
};
