import React, { useState } from 'react';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { Tag, Icon } from '@blueprintjs/core';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import truncate from 'lodash-es/truncate';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { OppSourceWithSource } from 'types/__generated__/GovlyApi';
import { cn } from 'app/lib/cn';
import { getLatestSequencedNoticeTypes } from 'app/organisms/OppAttachmentsTable/OppAttachmentsUtils';
import { OppSourceTimelineEvent } from '../OppHistoryTimeline/OppHistoryTimelineUtils';
import { OppSourceDrawer } from '../OppSourceDrawer/OppSourceDrawer';
import { OpenDrawer } from '../OppDetailsSources/OppDetailsSources';

export type OppHistoryCardProps = {
  oppSource: OppSourceWithSource & OppSourceTimelineEvent;
};

export const OppHistoryCard = ({ oppSource }: OppHistoryCardProps) => {
  const [openDrawer, setOpenDrawer] = useState<OpenDrawer>(undefined);
  const sequencedNoticeTypes = getLatestSequencedNoticeTypes(oppSource.sequencedNoticeTypes);

  return (
    <div className="my-12" data-testid="opp-history-card">
      <div
        className={cn(
          'absolute -left-3 lg:-left-4 rounded-full p-2 w-6 h-6 lg:w-8 lg:h-8 flex items-center justify-center',
          oppSource.iconBgClass
        )}
      >
        <Icon icon={oppSource.icon} className={oppSource.iconTextClass} />
      </div>
      <div className="pl-0 lg:pl-8 space-y-8">
        <p className="text-gray-500 font-light pl-8 lg:pl-0 pt-[3px] lg:pt-[6px]">
          {oppSource.noticeType} was updated on {oppSource.formattedCreatedAt}
        </p>
        <Card>
          <CardSection className="flex flex-col space-y-4">
            <div className="w-full flex items-center justify-between border-b border-gray-200 pb-2">
              <div className="flex items-center space-x-2 max-w-16 md:max-w-md">
                <LinkTag
                  tag="a"
                  data-test="opp-source-link"
                  onClick={event => {
                    openDrawerOrNewTab(event, `/opportunities/${oppSource.oppId}/sources/${oppSource.id}`, () =>
                      setOpenDrawer({
                        id: oppSource.oppId ?? '',
                        sourceId: oppSource.id ?? '',
                        subject: oppSource.title ?? ''
                      })
                    );
                  }}
                  className="font-medium no-underline"
                >
                  <NewTabTooltip title="View Opp Source">
                    <h3 className="inline-block">{truncate(oppSource.title, { length: 200 })}</h3>
                  </NewTabTooltip>
                </LinkTag>
              </div>

              {sequencedNoticeTypes.map(noticeType => (
                <Tag key={noticeType} intent="success" minimal>
                  {noticeType}
                </Tag>
              ))}
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Icon icon="generate" />
              <p className="mb-0">{oppSource.summary || '(No summary available.)'}</p>
            </div>

            <div className="flex flex-row items-center space-x-2">
              {oppSource.newAttachmentsCount > 0 && (
                <Tag minimal aria-label="new-attachments">
                  New Attachments: {oppSource.newAttachmentsCount}
                </Tag>
              )}
              {oppSource.updatedAttachmentsCount > 0 && (
                <Tag minimal aria-label="updated-attachments">
                  Updated Attachments: {oppSource.updatedAttachmentsCount}
                </Tag>
              )}
            </div>
          </CardSection>
        </Card>
      </div>

      {!!openDrawer && <OppSourceDrawer {...openDrawer} onClose={() => setOpenDrawer(undefined)} />}
    </div>
  );
};
