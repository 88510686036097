import React from 'react';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';

export type SearchFilterContainerProps = React.PropsWithChildren;

export const SearchFilterContainer = ({ children }: SearchFilterContainerProps) => {
  return (
    <MainColumn
      tag="aside"
      className={'hidden overflow-y-auto sticky lg:block top-0 py-4 px-px lg:col-span-1 h-[--main-full-height]'}
    >
      {children}
    </MainColumn>
  );
};
