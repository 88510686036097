import { useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

const defaultOptions = { threshold: 0.2 };

export type UseFuzzyTypeaheadProps<Item> = {
  data: Item[];
  options?: IFuseOptions<Item>;
  query: string;
};

export const useFuzzyTypeahead = <Item>({ data, options = {}, query }: UseFuzzyTypeaheadProps<Item>): Item[] => {
  const client = useMemo(() => {
    return new Fuse(data, { ...defaultOptions, ...options });
  }, [data, options]);

  const filteredData = query ? client.search(query).map(r => r.item) : data;

  return filteredData;
};
