import React from 'react';
import { EntityTitle, Checkbox, Icon, RadioGroup, Switch, Tag } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import startCase from 'lodash-es/startCase';
import sortBy from 'lodash-es/sortBy';

import { defaultState, OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { useOppTypeaheadQuery } from 'api/oppsApi';
import { useGetNaicsCodesQuery } from 'api/classificationCodesApi';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import { CardList } from 'app/molecules/CardList/CardList';
import { QueryInputMultiSelect } from 'app/molecules/QueryInputMultiSelect/QueryInputMultiSelect';
import { useContractVehicleMultiSelect } from 'app/molecules/QueryInputMultiSelect/useContractVehicleMultiSelect';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import {
  ClassificationCodeTypeahead,
  GovernmentEntityTypeaheadOpps,
  TypeaheadItem
} from 'types/__generated__/GovlyApi';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';
import { NavLink } from 'react-router-dom';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { FormikCompositeMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeMultiSelect';
import { FormikCompositeQueryMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeQueryMultiSelect';
import { FormikCompositeUserMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeUserMultiSelect';
import { getFiltersCount } from 'app/hooks/useGetFormikFilterCountByField';
import { makeSafeFormikFields } from 'app/lib/formik';
import { prependSelectAll } from 'app/molecules/UserMultiSelect/FormikUserMultiSelectUtils';
import { useGovernmentEntityTypeaheadQuery } from 'api/governmentEntitiesApi';
import { OppSearchEntityTypeFilter } from 'app/organisms/OppSearchEntityTypeFilter/OppSearchEntityTypeFilter';
import { OppSearchTypeFilter } from 'app/organisms/OppSearchTypeFilter/OppSearchTypeFilter';
import { OppSearchForecastFilter } from 'app/organisms/OppSearchForecastFilter/OppSearchForecastFilter';
import { OppSearchDateRangeFilter } from 'app/organisms/OppSearchDateRangeFilter/OppSearchDateRangeFilter';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';

type Props = {
  wrapperClassName?: string;
  className?: string;
};

const { safeTuples, safeFields } = makeSafeFormikFields<OppSearchState>();

export const OppSearchFilters = ({ className, wrapperClassName }: Props) => {
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<OppSearchState>();
  const filtersCount = getFiltersCount(values, v => v.filters);

  const { searchType, forecastStatus } = values.filters;
  const isFed = searchType === 'fed';
  const isSled = searchType === 'sled';

  const isPublished = forecastStatus === 'not_forecasted';
  const isForecasted = forecastStatus === 'forecasted';

  const currentSearchType = values.filters.searchType;

  const getField = useGetFormikField<OppSearchState>();

  // Helps filter collapse states reset correctly
  const rerenderKey = [values.meta.savedSearchId, values.meta.searchState].join(':');

  return (
    <CardList
      key={rerenderKey}
      title="Filter"
      wrapperClassName={wrapperClassName}
      className={className}
      rightElement={
        filtersCount > 0 ? (
          <Tag
            minimal
            interactive
            intent="primary"
            rightIcon="filter-remove"
            onClick={e => {
              e.preventDefault();
              const { filters, semantic } = defaultState;
              setValues({ ...values, filters, semantic });
              submitForm();
            }}
          >
            Reset Filters
          </Tag>
        ) : undefined
      }
    >
      <InputFilterCardListItem defaultIsOpen>
        <OppSearchTypeFilter />
        <OppSearchForecastFilter />
      </InputFilterCardListItem>

      <OppSearchDateRangeFilter />

      {isPublished && (
        <InputFilterCardListItem
          defaultIsOpen
          className="pt-0 space-y-2 sm:space-y-0"
          title="Status"
          {...getFilterCardProps({ names: ['filters.status'], submitOnChange: true })}
        >
          {[
            { label: 'Open', value: 'open' },
            { label: 'Expired', value: 'expired' },
            { label: 'Canceled', value: 'cancelled' },
            { label: 'Awarded', value: 'awarded' }
          ].map(({ label, value }) => (
            <Checkbox
              key={value}
              className="mb-0"
              inline
              label={label}
              {...FormikToBp.toCheckbox({ ...getField('filters.status'), value })}
            />
          ))}
        </InputFilterCardListItem>
      )}

      <FormikCompositeMultiSelect
        namesAndLabels={safeTuples(['filters.searchFields.only', 'Only'], ['filters.searchFields.exclude', 'Exclude'])}
        title="Search Fields"
        getItemTextLabel={item => item.label}
        getItemValue={item => item.value}
        useTypeaheadProps={{ options: { keys: ['label'] } }}
        items={[
          { label: 'Title', value: 'title' },
          { label: 'Details', value: 'details' },
          { label: 'Attachments', value: 'attachments' },
          { label: 'AI Summary', value: 'ai_summary' },
          { label: 'AI Title', value: 'ai_title' }
        ]}
      />

      <OppSearchEntityTypeFilter />

      {!isForecasted && (
        <FormikCompositeQueryMultiSelect
          namesAndLabels={safeTuples(['filters.noticeType', 'Include'], ['filters.noticeTypeNone', 'Exclude'])}
          title="Notice Type"
          useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
          hookArgs={[{ typeahead: 'notice_type', filterValues: ['Unknown'], searchType }]}
          getItemTextLabel={item => startCase(item.label)}
          getItemValue={item => item.value}
          useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        />
      )}

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.feeds', 'Include'], ['filters.feedsNone', 'Exclude'])}
        title="Feed"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[
          {
            typeahead: 'feed_name',
            filterValues: ['Unknown'],
            size: 10000,
            searchType,
            jurisdictionRegions: getField('filters.jurisdictionRegions').field.value,
            recordType: getField('filters.recordType').field.value,
            recordTypeNone: getField('filters.recordTypeNone').field.value
          }
        ]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => startCase(item.label)}
      />

      {isFed && (
        <FormikCompositeQueryMultiSelect
          title="Buyer"
          namesAndLabels={safeTuples(['filters.buyerIds', 'Include'], ['filters.buyerIdsNone', 'Exclude'])}
          additionalNames={safeFields('filters.buyerMatchById', 'filters.includeSubBuyers')}
          useHook={useGovernmentEntityTypeaheadQuery as InputMultiSelectHook<GovernmentEntityTypeaheadOpps>}
          hookArgs={[{ view: 'opps' }]}
          getItemValue={item => item.id}
          getItemTextLabel={item => item.name ?? ''}
          useTypeaheadProps={{ options: { keys: ['name', 'aliases'], shouldSort: false } }}
          filterCardProps={{ collapseProps: { keepChildrenMounted: true } }}
          collapsibleChildren={
            <>
              <RadioGroup
                className="mt-2"
                {...FormikToBp.toRadioGroup(getField('filters.buyerMatchById'))}
                inline
                options={[
                  { label: 'Filter by exact buyer match', value: 'true' },
                  { label: 'Full text search buyer names and aliases', value: 'false' }
                ]}
              />

              <Switch {...FormikToBp.toSwitch(getField('filters.includeSubBuyers'))} label="Include sub-buyers?" />
            </>
          }
        />
      )}

      {isSled && (
        <InputFilterCardListItem
          title="Jurisdiction"
          {...getFilterCardProps({ names: ['filters.jurisdictionRegions'], submitOnChange: true })}
        >
          <QueryInputMultiSelect
            useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
            hookArgs={[{ typeahead: 'jurisdiction_region', searchType, filterValues: ['Unknown', 'US'] }]}
            useTypeaheadProps={{ options: { keys: ['label', 'value', 'state'] } }}
            getItemValue={item => item.value}
            getItemsBasedProps={items =>
              FormikToBp.toMultiSelect({
                ...getField('filters.jurisdictionRegions'),
                items,
                getItemValue: item => item.value
              })
            }
            getItemTextLabel={item => item.label}
          />
        </InputFilterCardListItem>
      )}

      {isFed && isForecasted && (
        <FormikCompositeQueryMultiSelect
          namesAndLabels={safeTuples(
            ['filters.predictedContractVehicleNames', 'Include'],
            ['filters.predictedContractVehicleNamesNone', 'Exclude']
          )}
          title="Predicted Contract Vehicle"
          useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
          hookArgs={[
            {
              typeahead: 'predicted_contract_vehicle',
              filterValues: ['Unknown'],
              searchType,
              recordType: ['Prediction']
            }
          ]}
          getItemTextLabel={item => item.label}
          getItemValue={item => item.value}
          useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        />
      )}

      <FormikCompositeMultiSelect
        title="Workspace Stage"
        namesAndLabels={safeTuples(['filters.workspaceStages', 'Include'], ['filters.workspaceStagesNone', 'Exclude'])}
        items={[
          { label: 'Qualifying', value: 'qualifying' },
          { label: 'Intend to Bid', value: 'intend_to_bid' },
          { label: 'Quoted', value: 'quoted' },
          { label: 'Submitted', value: 'submitted' },
          { label: 'Awarded', value: 'awarded' },
          { label: 'No Bid', value: 'no_bid' },
          { label: 'Not Awarded', value: 'not_awarded' }
        ]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      <FormikCompositeUserMultiSelect
        title="Following"
        modifyItems={prependSelectAll}
        namesAndLabels={safeTuples(
          [
            'filters.following',
            'Include',
            <span key="include">
              Only show opportunities <b>any</b> of these people are following
            </span>
          ],
          [
            'filters.followingAll',
            'Include all',
            <span key="all">
              Only show opportunities <b>all</b> of these people are following
            </span>
          ],
          [
            'filters.excludeFollowing',
            'Exclude',
            <span key="exclude">
              <b>Do not</b> show opportunities that these people are following
            </span>
          ]
        ).map(toNameAndLabelWithTooltip)}
      />

      <FormikCompositeUserMultiSelect
        title="Not Interested"
        namesAndLabels={safeTuples(
          [
            'filters.excludeIgnored',
            'Exclude',
            <span key="exclude">
              <b>Do not</b> show opportunities that these people have marked &apos;Not Interested&apos;
            </span>
          ],
          [
            'filters.ignored',
            'Include',
            <span key="include">
              Only show opportunities <b>any</b> of these people have marked &apos;Not Interested&apos;
            </span>
          ],
          [
            'filters.ignoredAll',
            'Include all',
            <span key="all">
              Only show opportunities <b>all</b> of these people have marked &apos;Not Interested&apos;
            </span>
          ]
        ).map(toNameAndLabelWithTooltip)}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(
          ['filters.labels', 'Include'],
          ['filters.labelsAll', 'Include all'],
          ['filters.labelsNone', 'Exclude']
        )}
        title="Labels"
        useHook={useGetOrganizationLabelsQuery}
        useTypeaheadProps={{ options: { keys: ['name', 'description'] } }}
        getItemTextLabel={item => item.name ?? ''}
        getItemLabel={label => <LabelTag {...label} />}
        getItemValue={item => item.id}
        getInputProps={({ index }) =>
          index === 0
            ? {
                formGroupProps: {
                  helperText: <NavLink to={'/settings/labels'}>Manage labels</NavLink>
                }
              }
            : {}
        }
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(
          ['filters.tags', 'Include'],
          ['filters.tagsAll', 'Include all'],
          ['filters.tagsNone', 'Exclude']
        )}
        title="Tags"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'tags', filterValues: ['Unknown'], searchType }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.buyerDomains', 'Include'], ['filters.buyerDomainsNone', 'Exclude'])}
        title="Buyer Email Domain"
        useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
        hookArgs={[{ typeahead: 'buyer_domain', filterValues: ['Unknown'], searchType }]}
        useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
        getItemValue={item => item.value}
        getItemTextLabel={item => item.label}
      />

      {isFed && (
        <>
          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(['filters.setAside', 'Include'], ['filters.setAsideNone', 'Exclude'])}
            title="Set Aside"
            useHook={useGetSetAsidesQuery}
            getItemValue={item => item.code ?? 'missing code'}
            getItemTextLabel={item => item.name ?? item.code ?? ''}
            useTypeaheadProps={{ options: { keys: ['name', 'code'] } }}
          />

          <InputFilterCardListItem
            title="Recipient Count"
            {...getFilterCardProps({ names: ['filters.contractHolderCount'], submitOnChange: true })}
          >
            {[
              { label: '1-25', value: '1-25' },
              { label: '26-50', value: '26-50' },
              { label: '51-100', value: '51-100' },
              { label: 'More than 100', value: '>100' },
              { label: 'Unknown', value: 'unknown' }
            ].map(({ label, value }) => (
              <Checkbox
                key={value}
                inline
                label={label}
                {...FormikToBp.toCheckbox({ ...getField('filters.contractHolderCount'), value })}
              />
            ))}
          </InputFilterCardListItem>

          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(
              ['filters.regions', 'Include'],
              ['filters.regionsAll', 'Include all'],
              ['filters.regionsNone', 'Exclude']
            )}
            title="Regions"
            useHook={useOppTypeaheadQuery as InputMultiSelectHook<TypeaheadItem>}
            hookArgs={[{ typeahead: 'global_region', filterValues: ['Unknown'], searchType }]}
            getItemValue={item => item.value}
            getItemTextLabel={item => item.label}
            useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
          />

          <FormikCompositeQueryMultiSelect
            namesAndLabels={safeTuples(
              ['filters.naicsCodes', 'Include'],
              ['filters.naicsCodesAll', 'Include all'],
              ['filters.naicsCodesNone', 'Exclude']
            )}
            title="NAICS Codes"
            useHook={useGetNaicsCodesQuery as InputMultiSelectHook<ClassificationCodeTypeahead>}
            hookArgs={[{ view: 'typeahead', searchType }]}
            getItemValue={item => item.value}
            getItemTextLabel={item => item.label}
            useTypeaheadProps={{ options: { keys: ['label', 'value'] } }}
          />
        </>
      )}

      {isFed && (
        <FormikCompositeQueryMultiSelect
          namesAndLabels={safeTuples(
            ['filters.contractVehicleIds', 'Include'],
            ['filters.contractVehicleIdsNone', 'Exclude']
          )}
          title="Contract Vehicle"
          useHook={useContractVehicleMultiSelect}
          getItemTextLabel={item => item.contractName}
          getItemValue={item => item.id}
          useTypeaheadProps={{ options: { keys: ['contractName', 'label'] } }}
        />
      )}

      <FormikCompositeUserMultiSelect
        title="Viewed"
        namesAndLabels={safeTuples(
          ['filters.viewed', 'Include'],
          ['filters.viewedAll', 'Include all'],
          ['filters.excludeViewed', 'Exclude']
        )}
        modifyItems={prependSelectAll}
      />

      <FormikCompositeQueryMultiSelect
        namesAndLabels={safeTuples(['filters.matchedOppSearchIds', 'Include'])}
        title="Matched Saved Searches"
        useHook={useGetOppSearchesQuery}
        useTypeaheadProps={{ options: { keys: ['name', 'labels'] } }}
        hookArgs={[
          { active: true },
          {
            selectFromResult: result => ({
              ...result,
              data: sortBy(result.data?.filter(item => item.query?.searchType === currentSearchType))
            })
          }
        ]}
        getItemValue={item => item.id}
        getItemTextLabel={item => item.name ?? ''}
        getInputProps={({ index }) =>
          index === 0
            ? {
                formGroupProps: {
                  helperText: (
                    <span className="flex items-center">
                      <Icon icon="info-sign" size={10} className="mr-1" />
                      This filter shows results that matched the selected saved searches when the Opportunity was
                      released or last updated.
                    </span>
                  )
                }
              }
            : {}
        }
      />
    </CardList>
  );
};

const toNameAndLabelWithTooltip = ([name, label, tooltip]: readonly [string, string, React.ReactNode]): [
  string,
  React.ReactNode
] => [
  name,
  <EntityTitle
    key={name}
    title={label}
    tags={<InfoTooltip popoverProps={{ className: 'mt-0' }}>{tooltip}</InfoTooltip>}
  />
];
