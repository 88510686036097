import React from 'react';
import { useSearchQueryInputContext } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import Markdown from 'react-markdown';
import { Button } from '@blueprintjs/core';

type KeywordAssistantQueryStatmentProps = {
  statement?: string;
  preventFocus?: boolean;
};

const KeywordAssistantQueryStatement = ({ statement, preventFocus }: KeywordAssistantQueryStatmentProps) => {
  const { setValue, setAssistantIsOpen, submitForm } = useSearchQueryInputContext();

  const handleRunQuery = () => {
    setValue(statement || '');
    setAssistantIsOpen(false);
    submitForm();
  };

  return (
    <>
      <Markdown className="font-bold">{statement}</Markdown>
      <Button
        onClick={handleRunQuery}
        icon="tick"
        className="border rounded bg-grey-100 mt-1 mb-3 p-2 px-3 hover:bg-grey-200"
        tabIndex={preventFocus ? -1 : undefined}
      >
        Use Query
      </Button>
    </>
  );
};

export { KeywordAssistantQueryStatement };
