import React from 'react';
import { Tag } from '@blueprintjs/core';

import { InputCardHeading } from 'app/atoms/Typography/Typography';
import { CardListItem, CardListItemProps } from 'app/molecules/CardList/CardList';
import { cn } from 'app/lib/cn';

export type InputFilterCardListItemProps = CardListItemProps & {
  filterCount?: number;
  onClear?: () => void;
  children: React.ReactNode;
};

export const InputFilterCardListItem = ({
  filterCount = 0,
  onClear,
  children,
  defaultIsOpen,
  className,
  ...rest
}: InputFilterCardListItemProps) => {
  return (
    <CardListItem
      compact
      collapsible
      titleRenderer={InputCardHeading}
      defaultIsOpen={filterCount > 0 || defaultIsOpen}
      className={cn('space-y-4', className)}
      rightElement={
        filterCount > 0 ? (
          <Tag
            rightIcon="filter-remove"
            intent="primary"
            minimal
            round
            interactive
            onClick={e => {
              onClear?.();
              e.stopPropagation();
            }}
          >
            {filterCount && <strong>{filterCount}</strong>}
          </Tag>
        ) : undefined
      }
      {...rest}
    >
      {children}
    </CardListItem>
  );
};
