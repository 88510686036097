import { create } from 'zustand';

export type Message = {
  message: string | React.ReactNode;
  user: 'bot' | 'user';
};

type State = {
  messageHistory: { [key: string]: Message[] };
  sessionHistory: { [key: string]: string };
};

type Action = {
  addMessageToId: (id: string) => (message: Message) => void;
  resetMessages: (id: string) => () => void;
  addSessionToId: (id: string) => (id: string) => void;
};

type Store = State & Action;

const useChatHistoryStore = create<Store>()(set => {
  return {
    messageHistory: {},
    sessionHistory: {},

    addMessageToId: id => (message: Message) => {
      set(state => ({
        messageHistory: {
          ...state.messageHistory,
          [id]: [...(state.messageHistory[id] || []), message]
        }
      }));
    },

    resetMessages: id => () => {
      set(state => ({
        messageHistory: {
          ...state.messageHistory,
          [id]: []
        }
      }));
    },

    addSessionToId: id => (sessionId: string) => {
      set(state => ({
        sessionHistory: {
          ...state.sessionHistory,
          [id]: sessionId
        }
      }));
    }
  };
});

export const getChatStore = (id: string) => (store: Store) => ({
  messages: store.messageHistory[id] || [],
  addMessage: store.addMessageToId(id),
  resetMessages: store.resetMessages(id),
  sessionId: store.sessionHistory[id] || undefined,
  addSessionId: store.addSessionToId(id)
});

export const useChatStore = (id: string) => {
  return useChatHistoryStore(getChatStore(id));
};
