import React from 'react';
import { useParams } from 'react-router-dom';
import { NonIdealState } from '@blueprintjs/core';

import { MainColumn, ColumnSpan } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { USSpendingComboGraph } from 'app/organisms/USSpendingComboGraph/USSpendingComboGraph';
import { useGetVendorQuery } from 'api/vendorsApi';
import { VendorSpending } from 'types/__generated__/GovlyApi';

import { VendorProfileSpendingTable } from './VendorProfileSpendingTable';

export const VendorProfileSpending = () => {
  const { slug } = useParams();

  const { data, isLoading: vendorLoading } = useGetVendorQuery({ id: slug ?? '', view: 'spending' }, { skip: !slug });

  if (vendorLoading) {
    return <Loading />;
  }

  const { name, usSpendingData } = (data as VendorSpending | undefined) ?? {};

  if (!usSpendingData || !Object.keys(usSpendingData).length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState icon="chart" title={`Spending data has not been added for ${name}.`} />
        </CardBody>
      </Card>
    );
  }

  const breakDownGraphs = [
    { key: 'awardingAgency', label: 'Spending by Buyer', colSpan: 12 as ColumnSpan },
    { key: 'stateTerritory', label: 'Spending by Region' },
    { key: 'country', label: 'Spending by Country' }
  ];

  return (
    <>
      <MainColumn columnSpan={12}>
        <USSpendingComboGraph
          newAwardsOverTime={usSpendingData.newAwardsOverTime || []}
          spendingOverTime={usSpendingData.spendingOverTime || []}
        />
      </MainColumn>

      {breakDownGraphs.map(({ key, label, colSpan }) => {
        return (
          <VendorProfileSpendingTable
            key={key}
            label={label}
            colSpan={colSpan}
            spendingData={usSpendingData[key] || []}
          />
        );
      })}
    </>
  );
};
