import React from 'react';
import { useFormikInput, UseInputProps } from 'app/hooks/useFormikInput';

import { FormGroup, InputGroup, FormGroupProps, InputGroupProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

export type TextInputProps = {
  type?: string;
  inputProps?: InputGroupProps;
  errorOnTouch?: boolean;
} & FormGroupProps &
  UseInputProps;

export const TextInput = ({
  name,
  className,
  type = 'text',
  helperText,
  disabled,
  inline,
  label,
  labelInfo,
  submitOnChange = false,
  inputProps = { large: true },
  errorOnTouch = true,
  ...rest
}: TextInputProps) => {
  const { field, meta, onChange } = useFormikInput<string>({ name, type, submitOnChange });
  const error = errorOnTouch ? meta.touched && meta.error : meta.error;
  const intent = error ? 'danger' : undefined;

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      inline={inline}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      {...rest}
    >
      <InputGroup
        {...field}
        id={name}
        name={name}
        type={type}
        disabled={disabled}
        intent={intent}
        onChange={e => onChange(e.target.value)}
        {...inputProps}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
