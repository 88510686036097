import React, { useEffect, useState } from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { useGetOppQuery } from 'api/oppsApi';
import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { useSearchMutation } from 'api/oppsApi';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useAppDrawerStore } from 'app/organisms/AppDrawer/useAppDrawerStore';
import { useSearchDrawerNextPrevButtons } from 'app/organisms/SearchDrawerNextPrevButtons/useSearchDrawerNextPrevButtons';
import { Button, Tooltip } from '@blueprintjs/core';
import { useAppDrawerSubNavTab } from 'app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { DrawerType } from 'app/organisms/AppDrawer/constants';
import { OppWorkspacesTable } from 'app/organisms/OppWorkspacesTable/OppWorkspacesTable';
import { OppAttachmentsTable } from 'app/organisms/OppAttachmentsTable/OppAttachmentsTable';
import { OppDetailsOverviewContent } from 'app/organisms/OppDetailsOverviewContent/OppDetailsOverviewContent';
import { OppDetailsProviderContactsTable } from 'app/organisms/OppDetailsProviderContactsTable/OppDetailsProviderContactsTable';
import { OppDetailsProvidersTable } from 'app/organisms/OppDetailsProvidersTable/OppDetailsProvidersTable';
import { OppHistory } from '../OppHistory/OppHistory';

export const showOppDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/opportunities', '/worklist/workspaces', '/worklist/team_workspaces'].includes(window.location.pathname) &&
  drawerType === 'opportunities' &&
  drawerId;

export const OppDrawerBody = ({ id }: { id: string }) => {
  const tab = useAppDrawerSubNavTab('opportunities');

  const [loadedId, setLoadedId] = useState<string | undefined>();
  const { data, isFetching, isError } = useGetOppQuery({ id });
  const [createOppInteraction] = useCreateOppInteractionMutation();

  useEffect(() => {
    createOppInteraction({ oppId: id, viewed: true, async: true });
  }, [id, createOppInteraction]);

  useEffect(() => {
    if (loadedId !== id && !isFetching) {
      setLoadedId(id);
    }
  }, [id, loadedId, isFetching]);

  useEffect(() => {
    useAppDrawerStore.setState({
      rightElement: <PrevNext id={id} />
    });

    return () => {
      useAppDrawerStore.setState({ rightElement: undefined });
    };
  }, [id]);

  if (loadedId !== id && isFetching) {
    return <AppLoading containerClassName="py-7" />;
  }

  if (isError) {
    return <NotAuthorized />;
  }

  return (
    <Main className="max-w-full bg-gray-50">
      {match(tab)
        .with('Overview', () => data && <OppDetailsOverviewContent id={id} inDrawer />)
        .with('Workspaces', () => data && <WorkspacesTab id={id} />)
        .with('Attachments', () => data && <AttachmentsTab id={id} />)
        .with('History', () => data && <HistoryTab id={id} />)
        .with('Providers', () => data && <ProvidersTab id={id} />)
        .otherwise(() => null)}
    </Main>
  );
};

const WorkspacesTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppWorkspacesTable id={id} />
    </MainColumn>
  );
};

const AttachmentsTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppAttachmentsTable id={id} />
    </MainColumn>
  );
};

const HistoryTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppHistory id={id} />
    </MainColumn>
  );
};

const ProvidersTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <OppDetailsProvidersTable id={id} />
      <OppDetailsProviderContactsTable id={id} />
    </MainColumn>
  );
};

const PrevNext = ({ id }: { id: string }) => {
  const { nextIdLink, prevIdLink, onClickNext, onClickPrev, hasNextPrev } = useSearchDrawerNextPrevButtons({
    id,
    resource: 'opportunities',
    cacheKey: 'opp-search',
    mutation: useSearchMutation
  });

  if (!hasNextPrev) return null;

  return (
    <div className="flex justify-between gap-x-2">
      <Tooltip content="Previous Opportunity" placement="bottom">
        {prevIdLink ? (
          <Link to={prevIdLink} className="no-underline">
            <Button icon="arrow-up" aria-label="Prev" />
          </Link>
        ) : (
          <Button
            {...(onClickPrev ? { onClick: onClickPrev } : { disabled: true })}
            icon="arrow-up"
            aria-label="Prev"
          />
        )}
      </Tooltip>

      <Tooltip content="Next Opportunity" placement="bottom">
        {nextIdLink ? (
          <Link to={nextIdLink} className="no-underline">
            <Button icon="arrow-down" aria-label="Next" />
          </Link>
        ) : (
          <Button
            icon="arrow-down"
            aria-label="Next"
            {...(onClickNext ? { onClick: onClickNext } : { disabled: true })}
          />
        )}
      </Tooltip>
    </div>
  );
};
