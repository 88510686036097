import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { useContactSearchMutation } from 'api/contactsApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { ContactSearchResult } from 'app/organisms/ContactSearchResult/ContactSearchResult';

export const ContactSearchResults = () => {
  const [_search, { data: { results = [] } = {}, isLoading, isUninitialized }] = useContactSearchMutation({
    fixedCacheKey: 'contact-search'
  });

  if (isLoading || isUninitialized) {
    return <Loading type="card-list" />;
  }

  if (!results.length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="search"
            title="No search results"
            description={
              <div>
                {`Your search didn't match any contacts.`}
                <br />
                {`Try adjusting your search filters.`}
              </div>
            }
          />
        </CardBody>
      </Card>
    );
  }

  return results.map(contact => <ContactSearchResult key={contact.id} id={contact.id} />);
};
