import React from 'react';
import { PartnershipsTable } from 'app/organisms/PartnershipsTable/PartnershipsTable';

type ManagedFeedSharedFeedsTableProps = {
  providerFeedPublicId: string;
  providerFeedId: string;
};

export const ManagedFeedSharedFeedsTable = ({
  providerFeedPublicId,
  providerFeedId
}: ManagedFeedSharedFeedsTableProps) => {
  return (
    <PartnershipsTable
      title="Manage Feed Access"
      providerFeedPublicId={providerFeedPublicId}
      providerFeedId={providerFeedId}
      view="with_feeds"
      isSharingFeeds
    />
  );
};
