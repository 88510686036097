import React from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SavedSearchesTable } from 'app/organisms/SavedSearchesTable/SavedSearchesTable';

export const OppSavedSearchesPage = () => {
  return (
    <Main>
      <MainColumn className="empty:hidden">
        <Breadcrumbs />
      </MainColumn>
      <MainColumn>
        <SavedSearchesTable />
      </MainColumn>
    </Main>
  );
};
