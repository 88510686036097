import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';

import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { formErrorToast, errorToast, successToast } from 'app/lib/toaster';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import {
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
  useGetWebhookQuery
} from 'api/webhooksApi';
import { safeParse } from 'app/lib/strings';
import { isFetchBaseQueryError } from 'api/utils';

type WebhookFormProps = {
  id: string;
  onCancel: () => void;
  action?: 'create' | 'edit' | 'delete';
};

export const WebhookForm = ({ id, onCancel, action = 'create' }: WebhookFormProps) => {
  const [createWebhook] = useCreateWebhookMutation();
  const [updateWebhook] = useUpdateWebhookMutation();
  const [deleteWebhook] = useDeleteWebhookMutation();
  const navigate = useNavigate();

  const { data: webhook, isLoading } = useGetWebhookQuery({ id }, { skip: id === 'new' });

  let title = 'Update Webhook';
  let mutation: typeof updateWebhook | typeof createWebhook | typeof deleteWebhook = updateWebhook;
  if (id === 'new') {
    title = 'New Webhook';
    mutation = createWebhook;
  }

  if (action === 'delete') {
    title = 'Delete Webhook';
    mutation = deleteWebhook;
  }

  if (isLoading) return null;

  const validationSchema = yup.object({
    url: yup.string().required('Url is required'),
    subscriptions: yup.array().required('Subscriptions are required'),
    customHeaders: yup.string().test('customHeaders', 'Custom Headers must be valid JSON.', value => safeParse(value)),
    customPayloadTemplate: yup
      .string()
      .test('customPayloadTemplate', 'Custom Payload Template must be valid JSON.', value => safeParse(value))
  });

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        id: webhook?.id || '',
        url: webhook?.url || '',
        subscriptions: webhook?.subscriptions || [],
        enabled: webhook?.enabled || true,
        customHeaders: JSON.stringify(JSON.parse(webhook?.customHeaders || '{}'), null, 2),
        customPayloadTemplate: JSON.stringify(JSON.parse(webhook?.customPayloadTemplate || '{}'), null, 2)
      }}
      validationSchema={validationSchema}
      onSubmit={async values => {
        try {
          await mutation(values);
          successToast(action === 'delete' ? 'Webhook deleted' : 'Webhook saved');
          if (action === 'delete') navigate('/settings/api_settings');
          onCancel();
        } catch (e) {
          if (isFetchBaseQueryError(e) && typeof e.status == 'number') {
            formErrorToast(e.status);
          } else {
            errorToast(e);
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card title={title} rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}>
            <CardBody>
              {action === 'delete' ? (
                <p>Are you sure you want to delete this webhook?</p>
              ) : (
                <>
                  <TextInput
                    name="url"
                    label="Url"
                    inputProps={{ placeholder: 'https://example.com/api/v1/webhook_handler', large: true }}
                  />
                  <SwitchInput large name="enabled" label="Enabled?" />
                  <MultiSelectInput
                    label="Subscriptions"
                    name="subscriptions"
                    items={[
                      'OPP_WORKSPACE.FOLLOWED',
                      'OPP_WORKSPACE.NO_BID',
                      'OPP_WORKSPACE.INTEND_TO_BID',
                      'OPP_WORKSPACE.QUOTED',
                      'OPP_WORKSPACE.SUBMITTED',
                      'OPP_WORKSPACE.AWARDED',
                      'OPP_WORKSPACE.NOT_AWARDED',
                      'FOLLOWED_OPP.MODIFIED',
                      'FOLLOWED_OPP.ENRICHED'
                    ].map(value => ({
                      label: value,
                      value
                    }))}
                  />
                  <TextAreaInput name="customHeaders" label="Custom Headers" />
                  <TextAreaInput name="customPayloadTemplate" label="Custom Payload Template" />
                </>
              )}
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text={action === 'delete' ? 'Delete' : 'Save'}
                intent={action === 'delete' ? 'danger' : 'primary'}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
              {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
