import { SouptoolsTrTemplate } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';
const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::TrTemplate'] });

type GetTrTemplates = {
  params: void;
  result: SouptoolsTrTemplate[];
};

type CreateTrTemplate = {
  params: Partial<
    Pick<SouptoolsTrTemplate, 'name' | 'phone' | 'email' | 'organizationName' | 'contractNumber' | 'forwardNoError'>
  >;
  result: SouptoolsTrTemplate;
};

type UpdateTrTemplate = {
  params: { id: string } & Partial<
    Pick<SouptoolsTrTemplate, 'name' | 'phone' | 'email' | 'organizationName' | 'contractNumber' | 'forwardNoError'>
  >;
  result: SouptoolsTrTemplate;
};

type DeleteTrTemplate = {
  params: {
    id: string;
  };
  result: void;
};

export const souptoolsTrTemplatesApi = api.injectEndpoints({
  endpoints: build => ({
    getTrTemplates: build.query<GetTrTemplates['result'], GetTrTemplates['params']>({
      providesTags: ['Souptools::TrTemplate'],
      query: () => ({
        url: '/v2/souptools/tr_templates'
      })
    }),

    createTrTemplate: build.mutation<CreateTrTemplate['result'], CreateTrTemplate['params']>({
      invalidatesTags: ['Souptools::TrTemplate'],
      query: body => ({
        url: `/v2/souptools/tr_templates`,
        method: 'POST',
        body
      })
    }),

    updateTrTemplate: build.mutation<UpdateTrTemplate['result'], UpdateTrTemplate['params']>({
      invalidatesTags: ['Souptools::TrTemplate'],
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/tr_templates/${id}`,
        method: 'PATCH',
        body
      })
    }),

    deleteTrTemplate: build.mutation<DeleteTrTemplate['result'], DeleteTrTemplate['params']>({
      invalidatesTags: ['Souptools::TrTemplate'],
      query: ({ id }) => ({
        url: `/v2/souptools/tr_templates/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetTrTemplatesQuery,
  useDeleteTrTemplateMutation,
  useCreateTrTemplateMutation,
  useUpdateTrTemplateMutation
} = souptoolsTrTemplatesApi;
