import React, { useState, useMemo } from 'react';
import { AnchorButton, NonIdealState, Dialog, Tag, Popover, Menu, MenuItem } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { useGetTrawlerRunsQuery } from 'api/sysAdminTrawlerRunsApi';
import { useRunTrawlerConfigMutation } from 'api/sysAdminTrawlerConfigsApi';
import { successToast } from 'app/lib/toaster';
import { JsonViewer } from 'app/molecules/JsonViewer/JsonViewer';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { ActorRunTrawler } from 'types/__generated__/GovlyApi';

const columnHelper = createColumnHelper<ActorRunTrawler>();

export const SLEDDashboardTrawlerRuns = ({ trawlerConfigId }: { trawlerConfigId: string }) => {
  const [viewing, setViewing] = useState<ActorRunTrawler | null>(null);
  const { data: trawlerRuns, isLoading, isError } = useGetTrawlerRunsQuery({ trawlerConfigId });
  const [runTrawlerConfigMutation] = useRunTrawlerConfigMutation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', { header: 'ID', sortingFn: 'basic' }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: e => <Tag intent={e.row.original.statusIntent}>{e.row.original.status}</Tag>
      }),
      columnHelper.accessor('processedAt', {
        header: 'Processed At',
        sortingFn: 'alphanumeric',
        cell: e => {
          const date = DateTime.fromISO(
            e.row.original.processedAt || e.row.original.completedAt || e.row.original.createdAt
          );
          return date.toLocaleString(DateTime.DATETIME_SHORT);
        }
      }),
      columnHelper.accessor('actionsCount', { header: 'Records', sortingFn: 'basic' }),
      columnHelper.accessor('stats.duration', {
        header: 'Duration (seconds)',
        sortingFn: 'alphanumeric',
        cell: e => {
          const duration = e.row.original.stats?.duration;
          if (!duration) return 'n/a';

          return duration;
        }
      }),
      columnHelper.accessor('stats.cost', {
        header: 'Cost',
        sortingFn: 'alphanumeric',
        cell: e => {
          const cost = e.row.original.stats?.cost;
          if (!cost) return 'n/a';

          return `$${cost}`;
        }
      }),
      columnHelper.accessor('createdAt', {
        header: 'Actions',
        enableSorting: false,
        cell: e => {
          return (
            <Popover
              interactionKind="click"
              className="max-w-md"
              content={
                <Menu key="menu">
                  <MenuItem icon="eye-open" text="View Details" onClick={() => setViewing(e.row.original)} />
                  <MenuItem
                    icon="share"
                    text="View In Apify"
                    onClick={() => window.open(e.row.original.apifyLink, '_blank')}
                  />
                  {e.row.original.trawlerConfigId && (
                    <MenuItem
                      icon="repeat"
                      text="Retry Run"
                      onClick={async () => {
                        await runTrawlerConfigMutation({ id: e.row.original.trawlerConfigId ?? '' });
                        successToast('Trawler Config is running. Refresh the trawler runs page to see the results.');
                      }}
                    />
                  )}
                </Menu>
              }
            >
              <AnchorButton icon="more" disabled={isLoading} />
            </Popover>
          );
        }
      })
    ],
    [isLoading, runTrawlerConfigMutation]
  );

  if (isError) {
    return (
      <NonIdealState
        icon="warning-sign"
        title="There was an error loading this data."
        description="Please refresh the page to try again."
      />
    );
  }

  return (
    <>
      <GovlyTable
        id="trawler_runs_table"
        columns={columns}
        data={trawlerRuns ?? []}
        isLoading={isLoading}
        initialState={{
          pagination: { pageSize: 100 },
          sorting: [{ id: 'processedAt', desc: true }]
        }}
        enableColumnFilters={false}
      />
      <Dialog className="bg-transparent p-0" isOpen={!!viewing} onClose={() => setViewing(null)}>
        <div className="prose prose-sm">
          <pre className="min-w-fit">
            <JsonViewer
              json={Object.fromEntries(
                Object.entries({
                  stats: viewing?.stats,
                  records: viewing?.records,
                  logs: viewing?.logs,
                  apifyLink: viewing?.apifyLink
                }).filter(entry => entry[1])
              )}
            />
          </pre>
        </div>
      </Dialog>
    </>
  );
};
