import { useNavigate } from 'react-router-dom';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { WorkflowStageValue } from 'app/lib/workflowStages';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { FollowDisplayable } from 'types/__generated__/GovlyApi';
import { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } from 'api/workspacesApi';

export type LimitedFollow = Pick<FollowDisplayable, 'organizationUserId' | 'state' | 'notifications'> & {
  organizationUser?: { organizationId: string; email?: string };
  id?: string;
};

type FollowsAttributes = Omit<LimitedFollow, 'organizationUser'> & { email?: string };

type InitialValues = {
  id?: string;
  name: string;
  workflowStage: WorkflowStageValue;
  oppId?: string;
  organizationDefault?: boolean;
  privateAccess?: boolean;
};

type FormValues = InitialValues & {
  teamFollows: LimitedFollow[];
  partnerFollows: LimitedFollow[];
  customNotifyList?: LimitedFollow[];
  notifyGroup: 'notifiables' | 'nobody' | 'select';
  followsAttributes: FollowsAttributes[];
};

export type UseWorkspaceFormArgs = {
  enableReinitialize?: boolean;
  followersInputs: boolean;
  follows: LimitedFollow[];
  initialValues: InitialValues;
  notifyNewFollowersOnly?: boolean;
  onCancel: (args: { workflowStage?: WorkflowStageValue }) => void;
  redirectOnSuccess?: boolean;
  shouldTrack?: boolean;
  successMessage?: string;
  trackingData?: Record<string, string | null>;
  workableTypeForLink: string;
};

export const useWorkspaceForm = ({
  onCancel,
  initialValues,
  workableTypeForLink,
  followersInputs = true,
  follows,
  redirectOnSuccess = false,
  notifyNewFollowersOnly = true,
  successMessage,
  trackingData = {},
  shouldTrack = true,
  enableReinitialize = false
}: UseWorkspaceFormArgs) => {
  const navigate = useNavigate();
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const { trackEvent } = useEventTracking();
  const [updateWorkspace] = useUpdateWorkspaceMutation();
  const [createWorkspace] = useCreateWorkspaceMutation();

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser: _, ...rest }) => rest),
    'organizationUserId'
  );

  const form: FormikConfig<FormValues> = {
    enableReinitialize,
    onSubmit: async values => {
      try {
        const { teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload } = values;

        if (followersInputs) {
          const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

          const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

          const followsAttributes = allFollowers.map(ouid => {
            const updatedFollow = updatedFollowsByUser[ouid];
            const originalFollow = followsByUser[ouid];

            if (originalFollow && !updatedFollow) {
              return {
                id: originalFollow.id,
                state: 'unfollowed' as const,
                notifications: 'muted' as const,
                organizationUserId: originalFollow.organizationUserId,
                email: undefined
              };
            }

            return {
              id: originalFollow?.id || updatedFollow.id,
              state: updatedFollow.state,
              notifications: updatedFollow.notifications,
              organizationUserId: updatedFollow.organizationUserId,
              email: updatedFollow.organizationUser?.email
            };
          });

          payload.followsAttributes = followsAttributes;
        }

        let notifyIds: string[] = [];

        if (notifyGroup === 'select' && customNotifyList) {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const id = payload.id;
        const data = id
          ? await updateWorkspace({ ...payload, notifyIds, id }).unwrap()
          : await createWorkspace({ ...payload, notifyIds }).unwrap();

        const { followsAttributes, ...remainingData } = payload;
        if (shouldTrack)
          trackEvent({
            object: 'workspace',
            action: 'updated',
            properties: {
              workspaceId: remainingData.id,
              ...trackingData,
              ...remainingData,
              ...data,
              notifyIds,
              follows: followsAttributes.map(f => f.email),
              teamFollows: teamFollows.map(f => f.organizationUser?.email),
              partnerFollows: partnerFollows.map(f => f.organizationUser?.email)
            }
          });

        if (initialValues.id) {
          successToast(successMessage || 'Workspace updated');
          const { workflowStage } = values;
          onCancel({ workflowStage });
        } else {
          successToast(successMessage || 'Workspace created');
          if (redirectOnSuccess) {
            const { workableId, id } = data;
            navigate(`/${workableTypeForLink}/${workableId}/workspaces/${id}`);
          } else {
            onCancel({});
          }
        }
      } catch (e) {
        errorToast(e);
      }
    },
    initialValues: {
      teamFollows,
      partnerFollows,
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: [],
      followsAttributes: []
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required.')
    })
  };

  return form;
};
