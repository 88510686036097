import React from 'react';
import { AnchorButton, Intent } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

type MarketingCtaConfig = {
  bgClass?: string;
  header?: string;
  description?: string;
  buttons?: Array<{ text: string; intent?: Intent; href: string; large?: boolean; outlined?: boolean }>;
  bullets?: string[];
  imageUrl?: string;
};

const variants = {
  default: {
    bgClass: 'bg-gradient-to-r from-blue-500 to-indigo-400',
    header: 'Get started',
    description: 'Join our network of hundreds of OEMs, VARs and Primes today.',
    buttons: [
      {
        text: 'Schedule a Demo',
        large: true,
        intent: 'primary',
        href: 'https://www.govly.com/book-a-demo'
      }
    ]
  } as MarketingCtaConfig,
  bullets: {
    bgClass: 'bg-gray-900',
    variant: 'bullets',
    header: 'Ready to get started?',
    bullets: [
      'Access millions of opportunities',
      'View our database of vendors and buyers',
      'Partner with the Govly network'
    ],
    buttons: [
      { text: 'Schedule a Demo', intent: 'primary', href: 'https://www.govly.com/book-a-demo', large: true },
      { text: 'Learn More', large: true, outlined: true, intent: 'primary', href: '/' }
    ]
  } as MarketingCtaConfig,
  withImage: {
    bgClass: 'bg-gradient-to-r from-blue-500 to-indigo-400',
    header: 'Ready to dive in?',
    description: 'Get started with our free starter template.',
    buttons: [{ text: 'Request Demo', large: true }],
    imageUrl: 'https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg'
  } as MarketingCtaConfig
};

export const MarketingCta = ({ variant = 'default' }: { variant?: 'default' | 'bullets' | 'withImage' }) => {
  const options: MarketingCtaConfig = { ...variants[variant] };

  if (variant === 'bullets') {
    return (
      <section className={cn('py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8', options.bgClass)}>
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">{options.header}</span>
        </h2>
        <div className=" mt-4 text-lg text-blue-300">
          <ul>{options.bullets?.map(bullet => <li key={bullet}>{bullet}</li>)}</ul>
        </div>

        <div className="mt-8 flex justify-center gap-x-2">
          {options.buttons?.map((props, i) => <AnchorButton key={`${i}-button`} {...props} />)}
        </div>
      </section>
    );
  }

  if (variant === 'withImage') {
    return (
      <section className={cn('overflow-hidden rounded-lg shadow-xl lg:grid lg:grid-cols-2 lg:gap-4', options.bgClass)}>
        <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">{options.header}</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-indigo-200">{options.description}</p>
            <div className="mt-4 space-x-2">
              {options.buttons?.map((props, i) => <AnchorButton key={`${i}-button`} {...props} />)}
            </div>
          </div>
        </div>
        <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
          <img
            className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
            src={options.imageUrl}
            alt="Feature Image"
          />
        </div>
      </section>
    );
  }

  return (
    <section className={cn('relative overflow-hidden bg-gradient-to-r py-32', options.bgClass)}>
      <div className="relative mx-auto max-w-lg text-center ">
        <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">{options.header}</h2>
        <p className="m-4 text-lg tracking-tight text-gray-200">{options.description}</p>
        <div className="mt-8 flex justify-center gap-x-2">
          {options.buttons?.map((props, i) => <AnchorButton key={`${i}-button`} {...props} />)}
        </div>
      </div>
    </section>
  );
};
