import React from 'react';
import Markdown from 'react-markdown';
import { cn } from 'app/lib/cn';

import { Message } from './useChatStore';

export const ChatMessage = ({ message, user }: Message) => {
  if (!message) return null;

  return (
    <div className="h-fit flex mt-4">
      <span
        className={cn('p-2 rounded bg-gray-100 inline-block max-w-[80%]', { 'bg-blue-100 ml-auto': user === 'user' })}
      >
        {typeof message == 'string' ? (
          <Markdown className="prose prose-sm prose-ul:m-0 prose-p:mb-0">{message}</Markdown>
        ) : (
          message
        )}
      </span>
    </div>
  );
};
