import React from 'react';

import {
  FuzzyInputMultiSelect,
  FuzzyInputMultiSelectProps
} from 'app/molecules/InputMultiSelect/FuzzyInputMultiSelect';
import { UseFormikMultiSelectArgs, useFormikMultiSelect } from 'app/molecules/InputMultiSelect/useFormikMultiSelect';
import { GetOrganizationUsers, useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { OrgUserMenuItem, UserMenuItem } from 'app/molecules/UserMenuItem/UserMenuItem';
import { cn } from 'app/lib/cn';
import { Loading } from 'app/atoms/Loading/Loading';
import { getNonEmptyString } from 'app/lib/strings';

type ApiView = GetOrganizationUsers['params']['view'];

export type FormikUserMultiSelectProps<Item> = Partial<FuzzyInputMultiSelectProps<Item>> &
  Pick<UseFormikMultiSelectArgs<Item>, 'modifyItems'> & {
    name: string;
    submitOnChange?: boolean;
    apiView?: ApiView;
  };

export const FormikUserMultiSelect = <Item extends OrgUserMenuItem>({
  apiView = 'current_org',
  submitOnChange = true,
  name,
  menuItemProps,
  useTypeaheadProps,
  modifyItems,
  ...rest
}: FormikUserMultiSelectProps<Item>) => {
  const { data: items = [], isLoading = true } = useGetOrganizationUsersQuery({ view: apiView });

  const inputProps = useFormikMultiSelect({
    items: items as unknown as Item[],
    name,
    submitOnChange,
    modifyItems,
    getItemValue: item => item.id
  });

  if (isLoading) return <Loading type="text" />;

  return (
    <FuzzyInputMultiSelect
      loading={isLoading ?? rest.loading}
      menuItemProps={{ className: cn('px-2', menuItemProps?.className), ...menuItemProps }}
      useTypeaheadProps={{ options: { keys: ['email', 'name'] }, ...useTypeaheadProps }}
      getItemLabel={defaultGetItemLabel}
      getItemTextLabel={defaultGetItemTextLabel}
      getItemValue={item => item.id}
      {...inputProps}
      {...rest}
    />
  );
};

const defaultGetItemLabel = <Item extends OrgUserMenuItem>(item: Item) => <UserMenuItem item={item} />;
const defaultGetItemTextLabel = <Item extends OrgUserMenuItem>(item: Item) =>
  getNonEmptyString(item.email, item.name) ?? '';
