import React from 'react';

import { cn } from 'app/lib/cn';
import { Button, ButtonProps } from '@blueprintjs/core';

import { NavLinkTab, NavLinkTabProps } from 'app/molecules/NavLinkTab/NavLinkTab';
import { NavLinkButton, NavLinkButtonProps } from 'app/molecules/NavLinkButton/NavLinkButton';

export type Tab = {
  text: string;
  key?: string;
  buttonProps?: ButtonProps;
  current?: boolean;
} & (
  | {
      onClick: () => void;
    }
  | Omit<NavLinkButtonProps, 'onClick'>
  | Omit<NavLinkTabProps, 'onClick'>
);

export type TabsProps = React.HTMLAttributes<HTMLDivElement> & {
  tabs: Tab[];
  navClassName?: string;
  border?: boolean;
  buttonTabs?: boolean;
  currentTab?: string;
};

export const Tabs = ({ tabs, navClassName, border, buttonTabs, currentTab, ...props }: TabsProps) => {
  return (
    <>
      <div className="block" {...props}>
        {buttonTabs ? (
          <nav className={cn('flex space-x-4', navClassName)} aria-label="Tabs">
            {tabs.map(tab =>
              'onClick' in tab ? (
                <Button
                  key={tab.text}
                  minimal
                  active={!!currentTab && (currentTab === tab.text || currentTab === tab.key)}
                  className={cn('fill rounded font-medium', {
                    'bg-gray-200 dark:bg-gray-800': currentTab === tab.text
                  })}
                  {...{ text: tab.text, onClick: tab.onClick, ...tab.buttonProps }}
                />
              ) : (
                <NavLinkButton key={tab.text} text={tab.text} {...(tab as Partial<Tab>)} />
              )
            )}
          </nav>
        ) : (
          <div className={cn({ 'border-b border-gray-200': border })}>
            <nav className={cn('-mb-px flex space-x-8', navClassName)} aria-label="Tabs">
              {tabs.map(tab => (
                <NavLinkTab key={tab.text} text={tab.text} {...(tab as Partial<Tab>)} />
              ))}
            </nav>
          </div>
        )}
      </div>
    </>
  );
};
