import React from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { OppDetailsOverviewContent } from 'app/organisms/OppDetailsOverviewContent/OppDetailsOverviewContent';

export const OppDetailsOverviewPage = () => {
  const { id } = useParams();

  if (!id) return <NotFound />;

  return <OppDetailsOverviewContent id={id} />;
};
