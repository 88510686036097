import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import * as Yup from 'yup';
import { Button, Callout, FormGroup, Icon } from '@blueprintjs/core';

import { useOppSearchResults } from 'api/oppsApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetOppSearchesQuery, useUpdateOppSearchMutation } from 'api/oppSearchesApi';
import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { FollowDisplayable, OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { isFetchBaseQueryError, hasDataErrors } from 'api/utils';

type FormValues = {
  id: string;
  name: string;
  setAsDefault: boolean;
  follows: FollowDisplayable[];
  query: {
    query?: string;
  };
};

type OppSavedSearchUpdateFormProps = {
  savedSearchId: string;
  onCancel?: () => void;
  handleClose: () => void;
  followersOnly?: boolean;
  initialQuery?: string;
  initialFilters?: Partial<OppSearchState['filters']>;
};

export const OppSavedSearchUpdateForm = ({
  savedSearchId,
  onCancel,
  followersOnly,
  handleClose,
  initialQuery,
  initialFilters = {},
  ...rest
}: OppSavedSearchUpdateFormProps) => {
  const { trackEvent } = useEventTracking();
  const { isLoading: isSearching } = useOppSearchResults();
  const { savedSearch, isLoading: savedSearchesLoading } = useGetOppSearchesQuery(
    { active: true },
    {
      selectFromResult: ({ data, isLoading }) => ({
        savedSearch: data?.find(({ id }) => id === savedSearchId),
        isLoading: isLoading
      })
    }
  );

  const { data = [], isLoading: organizationUsersLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const organizationUsers = data as OrganizationUserCurrentOrg[];

  const [updateSavedSearch, { isLoading: isUpdating }] = useUpdateOppSearchMutation();

  const form: FormikConfig<FormValues> = {
    onSubmit: async (values, { setErrors }) => {
      try {
        const { follows = [], ...rest } = values;
        const persistedFollowIds = savedSearch?.follows?.map(({ id }) => id) ?? [];
        const updatedFollowIds = follows.map(({ id }) => id);
        const removedFollows = persistedFollowIds
          .filter(id => !updatedFollowIds.includes(id))
          .map(id => ({ id, _destroy: '1' }));

        const followsAttributes = follows.map(follow => ({
          id: follow.id,
          state: follow.state,
          notifications: follow.notifications,
          organizationUserId: follow.organizationUserId
        }));

        let payload = {
          id: rest.id,
          followsAttributes: [...followsAttributes, ...removedFollows]
        };
        if (!followersOnly) payload = { ...payload, ...rest };
        await updateSavedSearch(payload).unwrap();
        const { query, setAsDefault, name } = values;
        trackEvent({
          object: 'opp_search',
          action: 'updated',
          properties: {
            savedSearchId,
            ...query,
            setAsDefault,
            name,
            followersOnly,
            follows: follows?.map(f => f?.organizationUser?.email)
          }
        });

        successToast();
        handleClose();
      } catch (e) {
        if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
          const { data } = e;
          if (data.errors) setErrors(data.errors);
        }
      }
    },
    initialValues: {
      id: savedSearchId,
      name: savedSearch?.name ?? '',
      setAsDefault: savedSearch?.isCurrentUserDefault || false,
      follows: savedSearch?.follows ?? [],
      query: savedSearch?.currentUserCanEdit
        ? { query: initialQuery || '', ...initialFilters }
        : (savedSearch?.query ?? { query: '' })
    },
    validationSchema: Yup.object({
      name: Yup.string().max(80, 'Must be 80 characters or less').required('Please give a name to your saved search')
    })
  };

  if (savedSearchesLoading || organizationUsersLoading || isSearching) {
    return <Loading />;
  }

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card>
            <CardBody>
              {!followersOnly && (
                <>
                  <Callout intent="warning" title="Are you sure you want to update this search?">
                    <p>
                      All changes to filters and search terms will be saved. You will not be able to undo this action.
                    </p>
                  </Callout>
                  <TextInput
                    name="name"
                    label="Saved Search Name"
                    inputProps={{ placeholder: 'My Saved Search', large: true }}
                  />
                  <FormGroup
                    label={<LabelSpan label="Default Search" />}
                    className="m-0"
                    helperText={
                      <span className="flex items-center">
                        <Icon icon="info-sign" iconSize={10} className="mr-1" />
                        Default searches are preloaded on your dashboard when you visit Govly.
                      </span>
                    }
                  >
                    <SwitchInput className="m-0" name="setAsDefault" label="Set as your default search?" />
                  </FormGroup>
                </>
              )}
              <FollowerSelectInput
                name="follows"
                label="Followers"
                defaultButtonText="Add a Teammate"
                organizationUsers={organizationUsers}
                disabled={isUpdating}
              />
            </CardBody>
            <CardFooter>
              <Button large type="submit" text="Save" intent="primary" loading={isUpdating} />
              {onCancel && <Button text="Cancel" disabled={isUpdating} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};
