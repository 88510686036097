import React, { useState } from 'react';
import { Tag, Button, NonIdealState } from '@blueprintjs/core';

import { useGetTrTemplatesQuery } from 'api/souptoolsTrTemplatesApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { Loading } from 'app/atoms/Loading/Loading';
import { SouptoolsTrTemplateTableRow } from 'app/organisms/SouptoolsTrTemplateTableRow/SouptoolsTrTemplateTableRow';
import { SouptoolsTrTemplateForm } from 'app/organisms/SouptoolsTrTemplateForm/SouptoolsTrTemplateForm';

export const SouptoolsTrTemplatesPage = () => {
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const { data, isLoading } = useGetTrTemplatesQuery();

  return (
    <>
      <Card
        title={
          <div className="flex items-center gap-x-2">
            <CardHeading className="inline">Tr Templates</CardHeading>
            {!isLoading && <Tag minimal>{data?.length}</Tag>}
          </div>
        }
        rightElement={
          <Button intent="success" icon="plus" text="New Template" onClick={() => setIsCreatingTemplate(true)} />
        }
      >
        {isLoading ? (
          <CardBody>
            <Loading type="flex-row" />
          </CardBody>
        ) : (
          data?.map(trTemplate => (
            <CardBody key={trTemplate.id} className="py-2 lg:py-2">
              <SouptoolsTrTemplateTableRow trTemplate={trTemplate} />
            </CardBody>
          ))
        )}
        {!isLoading && !data?.length && (
          <CardBody>
            <NonIdealState icon="folder-open" title="No saved templates." />
          </CardBody>
        )}
      </Card>
      <SouptoolsTrTemplateForm isOpen={isCreatingTemplate} onClose={() => setIsCreatingTemplate(false)} />
    </>
  );
};
