import { LlmAssistantThread } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Thread'] });

type CreateThread = {
  params: {
    assistantType: string;
  };
  result: LlmAssistantThread;
};

type UpdateThread = {
  params: {
    id: string;
    content: string;
  };
  result: boolean;
};

type GetThread = {
  params: {
    id: string;
  };
  result: LlmAssistantThread;
};

export const llmThreadsApi = api.injectEndpoints({
  endpoints: build => ({
    createThread: build.mutation<CreateThread['result'], CreateThread['params']>({
      query: body => ({
        url: '/v2/llm/threads',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Thread']
    }),
    getThread: build.query<GetThread['result'], GetThread['params']>({
      query: ({ id }) => ({
        url: `/v2/llm/threads/${id}`
      })
    }),
    updateThread: build.mutation<UpdateThread['result'], UpdateThread['params']>({
      query: ({ id, content }) => ({
        url: `/v2/llm/threads/${id}`,
        method: 'PUT',
        body: { content }
      }),
      invalidatesTags: ['Thread']
    })
  })
});

export const { useCreateThreadMutation, useUpdateThreadMutation, useGetThreadQuery } = llmThreadsApi;
