import { OrgUserMenuItem } from 'app/molecules/UserMenuItem/UserMenuItem';

export const SELECT_ALL: OrgUserMenuItem = {
  id: 'all',
  email: '',
  name: 'All Users',
  icon: 'people'
};

export const prependSelectAll = <T extends OrgUserMenuItem>(items: T[]) => [SELECT_ALL, ...items];
