import React, { useState } from 'react';
import { AnchorButton, Button, Dialog, NonIdealState } from '@blueprintjs/core';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { useGetCrmConnectionsQuery } from 'api/crmConnectionsApi';
import { OrganizationForm } from 'app/organisms/OrganizationForm/OrganizationForm';
import { CrmConnectionFormUnanet } from 'app/organisms/CrmConnectionFormUnanet/CrmConnectionFormUnanet';
import { Card, CardBody, CardSection } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { AuthorizationRequiredTooltip } from 'app/molecules/AuthorizationRequiredTooltip/AuthorizationRequiredTooltip';
import { useGetOrganizationTeamsQuery } from 'api/organizationTeamsApi';
import { TeamRow } from '../TeamRow/TeamRow';
import { TeamForm } from '../TeamForm/TeamForm';

export const OrganizationSettings = () => {
  const [openConnectionForm, setOpenConnectionForm] = useState('');
  const { data: organization, isLoading } = useGetCurrentOrganizationQuery();
  const { data: crmConnections } = useGetCrmConnectionsQuery();

  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const { data: teams, isLoading: teamsLoading } = useGetOrganizationTeamsQuery({});

  const unanetConnection = crmConnections?.find(connection => connection?.name === 'Unanet' && connection?.active);

  if (isLoading || !organization) {
    return <Loading />;
  }

  const closeConnectionForm = () => setOpenConnectionForm('');

  return (
    <>
      <OrganizationForm organization={organization} />
      <Card
        title="Teams"
        rightElement={
          <AuthorizationRequiredTooltip role="admin">
            <AnchorButton icon="plus" text="New Team" onClick={() => setIsCreatingTeam(true)} />
          </AuthorizationRequiredTooltip>
        }
      >
        {teamsLoading ? (
          <CardBody>
            <Loading type="flex-row" />
          </CardBody>
        ) : teams?.length ? (
          teams.map(team => <TeamRow key={team.id} team={team} />)
        ) : (
          <CardBody>
            <NonIdealState icon="team" title="No teams yet." description="Teams can help organize your users." />
          </CardBody>
        )}
        <Dialog className="p-0" lazy isOpen={isCreatingTeam} onClose={() => setIsCreatingTeam(false)}>
          <TeamForm onCancel={() => setIsCreatingTeam(false)} />
        </Dialog>
      </Card>

      <Card title="Integrations">
        <CardSection>
          <Button
            text={unanetConnection ? 'Connected to Unanet' : 'Connect Unanet'}
            icon={unanetConnection ? 'tick-circle' : 'plus'}
            intent={unanetConnection ? 'success' : 'primary'}
            onClick={async () => {
              setOpenConnectionForm('Unanet');
            }}
          />
          <Dialog isOpen={!!openConnectionForm} onClose={() => closeConnectionForm()}>
            {openConnectionForm === 'Unanet' && <CrmConnectionFormUnanet onSubmit={() => closeConnectionForm()} />}
          </Dialog>
        </CardSection>
      </Card>
    </>
  );
};
