import React from 'react';

import { Outlet } from 'react-router-dom';
import { Loading } from 'app/atoms/Loading/Loading';
import { Divider } from '@blueprintjs/core';

import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const SearchableFeeds = () => {
  return (
    <SubscriptionRequiredPageWrapper
      subscriptions={['seatPaid']}
      paywallPageProps={{
        title: 'Feed Management is a Paid Feature'
      }}
    >
      <div className="min-h-[100%] flex flex-col gap-4 px-6 py-4">
        <p className="text-gray-500 mb-0">
          A list of all the feeds you are able to activate and manage in your Govly Opportunities dashboard.
        </p>
        <Divider className="w-full m-0" />
        <React.Suspense fallback={<Loading type="table" />}>
          <Outlet />
        </React.Suspense>
      </div>
    </SubscriptionRequiredPageWrapper>
  );
};
