import React from 'react';
import { useFormikFilterCardListItem } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { FormikUserMultiSelect, FormikUserMultiSelectProps } from 'app/molecules/UserMultiSelect/FormikUserMultiSelect';
import { useSubInputsSelected } from 'app/molecules/InputCompositeFilter/useSubInputsSelected';
import { OrgUserMenuItem } from 'app/molecules/UserMenuItem/UserMenuItem';
import { InputCompositeFilter } from './InputCompositeFilter';

type FormikCompositeUserMultiSelectProps<Item> = Omit<
  FormikUserMultiSelectProps<Item>,
  'selectedItems' | 'name' | 'onChange'
> & {
  title: string | JSX.Element;
  namesAndLabels: [string, string | React.ReactNode][];
  getInputProps?: (input: {
    name: string;
    label: string | React.ReactNode;
    index: number;
  }) => Partial<FormikUserMultiSelectProps<Item>>;
  subInputsSelected?: boolean;
  collapsibleChildren?: React.ReactNode;
  preFilterChildren?: React.ReactNode;
  children?: React.ReactNode;
};

export function FormikCompositeUserMultiSelect<Item extends OrgUserMenuItem>({
  title,
  namesAndLabels,
  getInputProps,
  formGroupProps,
  subInputsSelected: subInputsSelectedProp = false,
  collapsibleChildren,
  preFilterChildren,
  children,
  ...props
}: FormikCompositeUserMultiSelectProps<Item>) {
  const names = namesAndLabels.map(([n]) => n);
  const filterCardProps = useFormikFilterCardListItem({ names, submitOnChange: true });

  const subInputsSelected = useSubInputsSelected({ names });

  return (
    <InputFilterCardListItem title={title} {...filterCardProps}>
      {preFilterChildren}

      <InputCompositeFilter subInputsSelected={subInputsSelected || subInputsSelectedProp}>
        {namesAndLabels.map(([name, label], index) => {
          const { formGroupProps: inputFormGroupProps, ...inputProps } = getInputProps?.({ name, label, index }) ?? {};
          return (
            <FormikUserMultiSelect
              key={name}
              submitOnChange
              name={name}
              formGroupProps={{ label, ...formGroupProps, ...inputFormGroupProps }}
              {...inputProps}
              {...props}
            />
          );
        })}

        {collapsibleChildren}
      </InputCompositeFilter>
      {children}
    </InputFilterCardListItem>
  );
}
