import { trackEvent } from 'app/lib/trackEvent';
import { Workspace, WorkspaceShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';
import { worklistApi, GetWorklist } from './worklistApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Opp', 'Worklist'] });

type GetOppWorkspace = {
  params: { id: string; fromSearch?: boolean };
  result: WorkspaceShow;
};

type BulkUpdateOppWorkspaces = {
  params: {
    removeFromWorklist?: boolean;
    unfollow?: boolean;
    ids: string[];
  };
  result: void;
};

type AddWorkspaceInteraction = {
  params: {
    id: string; // maps to param oppWorkspaceId
  };
  result: Workspace;
};

export const oppWorkspacesApi = api.injectEndpoints({
  endpoints: build => ({
    getOppWorkspace: build.query<GetOppWorkspace['result'], GetOppWorkspace['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/opp_workspaces/${id}`, params: { ...params, view: 'show' } }),
      providesTags: (result, _err, { id }) => [
        { type: 'Workspace', id },
        { type: 'Opp', id: result?.workableId }
      ]
    }),

    bulkUpdateOppWorkspaces: build.mutation<BulkUpdateOppWorkspaces['result'], BulkUpdateOppWorkspaces['params']>({
      query: body => ({
        url: `/v2/opp_workspaces/bulk_update`,
        method: 'POST',
        body
      }),
      async onQueryStarted({ ids, removeFromWorklist }, { dispatch }) {
        try {
          trackEvent({
            object: 'worklist',
            action: 'updated',
            properties: { removeFromWorklist, ids }
          });

          if (removeFromWorklist === true) {
            dispatch(
              worklistApi.util.updateQueryData(
                'getWorklist',
                { filter: 'active' },
                (draftWorklist: GetWorklist['result']) => {
                  return draftWorklist.filter(({ id }) => !ids.includes(id));
                }
              )
            );
          } else if (removeFromWorklist === false) {
            dispatch(
              worklistApi.util.updateQueryData(
                'getWorklist',
                { filter: 'active' },
                (draftWorklist: GetWorklist['result']) => {
                  return draftWorklist.filter(({ id }) => !ids.includes(id));
                }
              )
            );
          }
        } catch {}
      },
      invalidatesTags: (_result, _err, { removeFromWorklist }) => (removeFromWorklist === undefined ? ['Worklist'] : [])
    }),

    addWorkspaceInteraction: build.mutation<AddWorkspaceInteraction['result'], AddWorkspaceInteraction['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/opp_workspaces/${id}/opp_workspace_interactions`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetOppWorkspaceQuery, useBulkUpdateOppWorkspacesMutation, useAddWorkspaceInteractionMutation } =
  oppWorkspacesApi;
