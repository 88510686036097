import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import MapboxMap, { Layer, Source } from 'react-map-gl';

import { useLazyOppAggregationQuery } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { useIntersectionObserver } from 'app/hooks/useIntersectionObserver';
import { useOppSearchAnalyticsMapStore } from 'app/organisms/OppSearchUnitedStatesMap/useOppSearchAnalyticsMapStore';
import { colors } from 'app/lib/colors';
import {
  MAPBOX_STYLE_URL,
  TILE_LAYERS,
  getBordersKey,
  getColorRange,
  getFillsKey,
  groupColorRange
} from 'app/lib/mapbox';
import { ChoroplethMapLegend } from 'app/molecules/ChoroplethMapLegend/ChoroplethMapLegend';
import { useOppSearchAnalyticsMapEvents } from 'app/organisms/OppSearchUnitedStatesMap/useOppSearchAnalyticsMapEvents';
import { OppSearchAnalyticsTableDrawer } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsTableDrawer';
import startCase from 'lodash-es/startCase';

type UsAgg = {
  [key: string]: {
    key: string;
    docCount: number;
  };
};

type AggregationQueryResponse = {
  aggs?: {
    usAgg: UsAgg;
  };
};

const { source, sourceLayer, getFeatureKey, getFeatureLabel, url, filter, colorScheme } = TILE_LAYERS.state;

export const OppSearchUnitedStatesMap = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const { values } = useFormikContext<OppSearchState>();
  const { query, filters } = values;
  const title = 'Opportunity Density by State';

  useOppSearchAnalyticsMapEvents();

  const cardRef = useRef<HTMLDivElement>(null);
  const { hasIntersected: hasCardBeenShown } = useIntersectionObserver(cardRef);

  const {
    viewState,
    setViewState,
    mapRef,
    setIsMapReady,
    isMapReady,
    popup,
    drawerIsOpen,
    setDrawerIsOpen,
    clickedFeatureRef
  } = useOppSearchAnalyticsMapStore();

  const [getOppsAggregation, { data = {}, isLoading, isUninitialized, isError, isFetching }] =
    useLazyOppAggregationQuery();

  const colorRange = useMemo(
    () =>
      getColorRange({
        color: colorScheme,
        data: getDocCounts(data as AggregationQueryResponse)
      }),
    [data]
  );

  useEffect(() => {
    if (searchIsLoading || !isMapReady) return;

    getOppsAggregation({
      query,
      ...filters,
      aggs: ['regionAgg'],
      preprocessor: 'Opp::Analytics::RegionAggPreprocessor'
    })
      .unwrap()
      .then(result => {
        setDocCounts(result as AggregationQueryResponse);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIsLoading, isMapReady]);

  useEffect(() => {
    return () => {
      useOppSearchAnalyticsMapStore.setState({ isMapReady: false });
    };
  }, []);

  const setDocCounts = useCallback((result?: AggregationQueryResponse) => {
    if (!result) return;

    const { mapRef } = useOppSearchAnalyticsMapStore.getState();
    const map = mapRef.current;
    if (!map) return;

    const agg = result.aggs?.usAgg;
    const locations = new Map(Object.values(agg ?? {}).map(({ key, docCount }) => [key.toUpperCase(), docCount]));
    const features = map.querySourceFeatures(source, { sourceLayer }) ?? [];

    features.forEach((feature, i) => {
      map.setFeatureState(
        { id: feature.id ?? `feature-${i}`, source, sourceLayer },
        { docCount: locations.get(getFeatureKey(feature)) || 0 }
      );
    });
  }, []);

  const drilldownFilters: Partial<OppSearchState['filters']> = {
    regions: clickedFeatureRef.current ? [startCase(getFeatureKey(clickedFeatureRef.current).toLowerCase())] : []
  };
  const featureLabel = clickedFeatureRef.current
    ? getFeatureLabel(clickedFeatureRef.current)
    : 'Opportunities by State';

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  return (
    <ErrorBoundary action="OppSearchUnitedStatesMap" fallback={<CardError title={title} />}>
      <Card
        title={title}
        rightElement={isLoading || isUninitialized || isFetching ? <Loading type="spinner" /> : undefined}
      >
        <CardSection ref={cardRef}>
          {hasCardBeenShown && (
            <MapboxMap
              {...viewState}
              style={{ height: '500px' }}
              ref={mapRef}
              onLoad={() => setIsMapReady(true)}
              mapboxAccessToken={process.env.MAPBOX_PUBLIC_KEY}
              mapStyle={MAPBOX_STYLE_URL}
              onMove={e => {
                setDocCounts(data);
                setViewState(e.viewState);
              }}
            >
              {popup}
              <Source id={source} type="vector" url={url}>
                <Layer
                  {...{
                    id: getFillsKey(source),
                    type: 'fill',
                    source,
                    'source-layer': sourceLayer,
                    filter,
                    paint: {
                      'fill-color': ['step', ['feature-state', 'docCount'], ...colorRange],
                      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.75]
                    }
                  }}
                />

                <Layer
                  {...{
                    id: getBordersKey(source),
                    type: 'line',
                    source,
                    'source-layer': sourceLayer,
                    paint: {
                      'line-color': colors.gray['300'],
                      'line-width': 2
                    }
                  }}
                />
              </Source>
              <ChoroplethMapLegend
                title="Total Opportunities"
                isLoading={isFetching || isLoading || !isMapReady}
                colorGroups={groupColorRange(
                  getColorRange({
                    color: TILE_LAYERS.state.colorScheme,
                    data: getDocCounts(data)
                  })
                )}
              />
            </MapboxMap>
          )}
        </CardSection>
      </Card>

      <OppSearchAnalyticsTableDrawer
        id="opps_map_table"
        isOpen={drawerIsOpen}
        drawerTitle={featureLabel}
        onClose={() => {
          setDrawerIsOpen(false);
        }}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};

const getDocCounts = (data: AggregationQueryResponse = {}) =>
  Object.values(data?.aggs?.usAgg ?? {}).map(({ docCount }) => docCount);
