import React from 'react';
import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from 'types/__generated__/GovlyApi';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { cn } from 'app/lib/cn';
import { pluralize } from 'app/lib/strings';
import Avatar, { AvatarProps } from '../Avatar/Avatar';

type InputTeam =
  | OrganizationTeam
  | OrganizationTeamWithOrganizationUsers
  | { name: string; avatarColor: string; memberCount: number };

export type TeamTagDetailProps = {
  team: InputTeam;
  size?: AvatarProps['size'];
};

const textSize: { [k in NonNullable<AvatarProps['size']>]?: Record<string, string> } = {
  '2xl': {
    name: 'text-2xl font-bold',
    email: 'text-lg'
  },
  xl: {
    name: 'text-xl font-bold'
  }
};

export const TeamAvatarWithName = ({ team, size = 'md' }: TeamTagDetailProps) => {
  const { isMobile } = useDeviceWidth();

  let sizeForDevice = size;
  if (size === '2xl' && isMobile) {
    sizeForDevice = 'xl';
  }

  return (
    <div className="flex gap items-center">
      <Avatar className="flex-none mr-2" color={team.avatarColor} icon="team" />
      <div className="flex-auto flex flex-col items-start">
        <div className={cn('flex-auto font-medium text-gray-900', textSize[sizeForDevice]?.name)}>{team.name}</div>
        <div
          className={cn('flex-auto text-gray-500', textSize[sizeForDevice]?.email)}
          data-test="team-avatar-with-name-subtext"
        >
          {team.memberCount || 0} {pluralize('Member', 'Members', team.memberCount || 0)}
        </div>
      </div>
    </div>
  );
};
