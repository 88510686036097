import React, { useState } from 'react';
import { FormGroup, Checkbox, Tag, Icon, FormGroupProps, CheckboxProps } from '@blueprintjs/core';

import { Loading } from 'app/atoms/Loading/Loading';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useFormikInput } from 'app/hooks/useFormikInput';

export type CheckboxGroupInputProps = {
  name: string;
  items: {
    label: React.ReactNode;
    value: string;
    aggregation?: number;
    disabled?: boolean;
  }[];
  inputProps?: CheckboxProps;
  hasSelectAll?: boolean;
  withAggregations?: boolean;
  hasCount?: boolean;
  hasClear?: boolean;
  submitOnChange?: boolean;
  isLoading?: boolean;
} & FormGroupProps;

export const CheckboxGroupInput = ({
  items,
  inputProps,
  name,
  hasSelectAll,
  withAggregations,
  hasCount = true,
  hasClear = true,
  submitOnChange,
  isLoading,
  className,
  label,
  labelInfo,
  disabled,
  helperText,
  inline,
  intent,
  ...rest
}: CheckboxGroupInputProps) => {
  const { field, meta, onChange } = useFormikInput<string[]>({ name, submitOnChange });
  const [showMore, setShowMore] = useState(false);
  const error = meta.touched && meta.error;

  if (isLoading) {
    return (
      <div className={className}>
        <Loading type="flex-row" />
      </div>
    );
  }

  if (items.length === 0) return null;

  const clear = () => {
    onChange([]);
  };

  const toggleSelectAll = () => {
    if (field.value?.length !== 0) {
      onChange([]);
    } else {
      const values = items.filter(i => !i.disabled).map(i => i.value);
      onChange(values);
    }
  };

  const initialMaxLength = 9;
  const listLength = showMore ? items.length : initialMaxLength;
  const decoratedLabel =
    field.value?.length > 0 && hasCount
      ? `${label}${labelInfo ? ` ${labelInfo}` : ''} (${field.value.length})`
      : `${label}${labelInfo ? ` ${labelInfo}` : ''}`;

  return (
    <div className={className}>
      <FormGroup
        helperText={helperText}
        disabled={disabled}
        className="m-0"
        label={
          <div className="flex justify-between">
            <LabelSpan label={decoratedLabel} />
            {hasSelectAll && (
              <>
                <LinkTag tag="a" onClick={toggleSelectAll}>
                  {field.value?.length ? 'Clear' : 'Select all'}
                </LinkTag>
              </>
            )}
            {!hasSelectAll && !!field.value?.length && hasClear && (
              <>
                <LinkTag tag="a" onClick={clear}>
                  {' '}
                  Clear
                </LinkTag>
              </>
            )}
          </div>
        }
        intent={intent}
        {...rest}
      >
        {items.slice(0, listLength).map(({ label, value, aggregation, disabled = false }) => (
          <div className={inline ? 'inline' : 'flex justify-between'} key={value}>
            <Checkbox
              checked={field.value?.includes(value)}
              inline={inline}
              disabled={disabled}
              {...inputProps}
              {...(typeof label === 'string' ? { label } : { labelElement: label })}
              onChange={e => {
                const selected = new Set(field.value || []);
                if (e.target.checked) {
                  selected.add(value);
                } else {
                  selected.delete(value);
                }
                onChange(Array.from(selected));
              }}
            />
            {withAggregations && !inline && (
              <div>
                <Tag minimal>{aggregation?.toLocaleString() || 0}</Tag>
              </div>
            )}
          </div>
        ))}
      </FormGroup>
      {items.length > initialMaxLength && (
        <div className="text-center">
          <LinkTag
            tag="a"
            onClick={e => {
              e.preventDefault();
              setShowMore(!showMore);
            }}
          >
            {showMore ? 'Show fewer' : 'Show more'}
            <Icon icon={showMore ? 'chevron-up' : 'chevron-down'} />
          </LinkTag>
        </div>
      )}
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </div>
  );
};
