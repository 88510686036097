import React from 'react';
import { Main, MainProps } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';

export const AppLoading = (props: MainProps) => {
  return (
    <>
      <Main {...props}>
        <MainColumn>
          <Loading />
          <div className="hidden lg:block">
            <Loading />
          </div>
        </MainColumn>
      </Main>
    </>
  );
};
