import React from 'react';
import { OppWorkspaceCommentForm } from 'app/organisms/OppWorkspaceComment/OppWorkspaceCommentForm';
import { useOppWorkspaceStore } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { useCreateOppWorkspaceCommentMutation } from 'api/oppWorkspaceCommentsApi';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { cn } from 'app/lib/cn';
import { Card, CardBody } from 'app/atoms/Card/Card';

export const WorkspaceToolboxCommentButton = () => {
  const setToolboxDialogProps = useOppWorkspaceStore(s => s.setToolboxDialogProps);

  return (
    <button
      className={cn(
        // Mirroring input styling here
        'text-sm text-gray-500 text-left border',
        'rounded px-2 py-2 flex-1',
        'hover:bg-gray-50 focus:bg-gray-50'
      )}
      onClick={() => {
        setToolboxDialogProps({
          isOpen: true,
          //  Some hacky styling to get the trix toolbar inline with the dialog close button
          title: <>&nbsp;</>,
          className: '[&_.bp5-dialog-header]:shadow-none [&_.bp5-dialog-header]:-mb-12',
          children: <DrawerBody />
        });
      }}
    >
      Write a new message&hellip;
    </button>
  );
};

const DrawerBody = () => {
  const setToolboxDialogProps = useOppWorkspaceStore(s => s.setToolboxDialogProps);
  const workspaceId = useOppWorkspaceStore(s => s.workspaceId);
  const { data: oppWorkspace } = useGetOppWorkspaceQuery({ id: workspaceId });
  const oppId = oppWorkspace?.workableId ?? '';
  const [createOppWorkspaceComment, { isLoading: isCreating }] = useCreateOppWorkspaceCommentMutation();

  return (
    <Card>
      <CardBody className="[&_.trix-button-row]:justify-start">
        <OppWorkspaceCommentForm
          autoFocus
          onSubmit={createOppWorkspaceComment}
          afterSubmit={() => {
            setToolboxDialogProps({ isOpen: false });
          }}
          oppId={oppId}
          workspaceId={workspaceId}
          isLoading={isCreating}
        />
      </CardBody>
    </Card>
  );
};
