import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, IconName, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { cn } from 'app/lib/cn';
import { NavSelect } from 'app/molecules/NavSelect/NavSelect';

const paths = [
  { value: 'opportunities/searchable_feeds/fed', label: 'Federal' },
  { value: 'opportunities/searchable_feeds/sled', label: 'SLED' },
  { value: 'opportunities/searchable_feeds/managed', label: 'Managed' }
];

export type Sort = 'none' | 'asc' | 'desc';

type Props = {
  className?: string;
  searchDisabled?: boolean;
  sortDisabled?: boolean;
};

export const SEARCH_KEY = 'query';
export const SORT_KEY = 'sort';

export const SearchableFeedsSwitcher = ({ className, searchDisabled, sortDisabled }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get(SEARCH_KEY) || '';
  const sort = (searchParams.get(SORT_KEY) as Sort) ?? 'none';

  return (
    <div className={cn('flex items-center gap-3 w-full', className)}>
      <NavSelect items={paths} />

      {searchDisabled ? null : (
        <InputGroup
          placeholder="Search for a feed"
          value={search}
          onChange={e => {
            setSearchParams(prev => {
              prev.set(SEARCH_KEY, e.target.value);
              return prev;
            });
          }}
        />
      )}

      <div className="flex-1" />

      {sortDisabled ? null : (
        <Popover
          placement="bottom"
          content={
            <Menu>
              <MenuItem
                active={sort === 'none'}
                onClick={() =>
                  setSearchParams(prev => {
                    prev.set(SORT_KEY, 'none');
                    return prev;
                  })
                }
                text="None"
              />
              <MenuItem
                active={sort === 'asc'}
                onClick={() =>
                  setSearchParams(prev => {
                    prev.set(SORT_KEY, 'asc');
                    return prev;
                  })
                }
                text="Ascending"
              />
              <MenuItem
                active={sort === 'desc'}
                onClick={() =>
                  setSearchParams(prev => {
                    prev.set(SORT_KEY, 'desc');
                    return prev;
                  })
                }
                text="Descending"
              />
            </Menu>
          }
        >
          <Button
            minimal
            rightIcon="chevron-down"
            icon={match<typeof sort, IconName>(sort)
              .with('asc', () => 'sort-asc')
              .with('desc', () => 'sort-desc')
              .otherwise(() => 'sort')}
          >
            {match(sort)
              .with('asc', () => 'Ascending')
              .with('desc', () => 'Descending')
              .otherwise(() => 'Sort')}
          </Button>
        </Popover>
      )}
    </div>
  );
};
