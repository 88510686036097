import React from 'react';

import { OppSearchResultsControls } from 'app/organisms/OppSearchResultsControls/OppSearchResultsControls';
import { OppSearchResults } from 'app/organisms/OppSearchResults/OppSearchResults';
import { SearchPagination } from 'app/molecules/SearchPagination/SearchPagination';
import { useOppSearchPagination } from 'api/oppsApi';

export const OppSearchResultTable = () => {
  return (
    <>
      <div className="hidden lg:block">
        <OppSearchResultsControls />
      </div>
      <OppSearchResults />
      <SearchPagination paginationHook={useOppSearchPagination} />
    </>
  );
};
