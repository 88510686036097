import React from 'react';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { ContactsCard } from 'app/molecules/ContactsCard/ContactsCard';
import { AddressesCard } from 'app/molecules/AddressesCard/AddressesCard';
import { OppDetailsCardList } from 'app/organisms/OppDetails/OppDetails';
import { OppDetailsActions } from 'app/organisms/OppDetailsActions/OppDetailsActions';
import { useGetOppQuery } from 'api/oppsApi';
import { QuestionsAndAnswers } from 'app/molecules/QuestionsAndAnswers/QuestionsAndAnswers';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { CardList } from 'app/molecules/CardList/CardList';
import { SimilarOppsCard } from 'app/molecules/SimilarOppsCard/SimilarOppsCard';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { Card } from 'app/atoms/Card/Card';
import { OppDetailsDescriptionFieldsPrediction } from 'app/organisms/OppDetailsDescriptionFieldsPrediction/OppDetailsDescriptionFieldsPrediction';
import { OppDetailsDescriptionFields } from 'app/organisms/OppDetailsDescriptionFields/OppDetailsDescriptionFields';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';

export const OppDetailsOverviewContent = ({ id, inDrawer }: { id: string; inDrawer?: boolean }) => {
  const { data } = useGetOppQuery({ id: id ?? '' }, { skip: !id });
  const showSimilarCard = useFeatureFlag('show-opp-similar-tab');

  if (!id) return <NotFound />;

  const { latestData, contacts = [], recordLocations = [], addresses = [] } = data || {};
  const locations = recordLocations.length > 0 ? recordLocations : addresses;
  const questions = latestData?.questions || [];
  const details = data ? (
    <Card title="Details" collapsible titleRenderer={CardHeadingSmall}>
      {data.isForecasted ? (
        <OppDetailsDescriptionFieldsPrediction id={id} opp={data} />
      ) : (
        <OppDetailsDescriptionFields id={id} opp={data} />
      )}
    </Card>
  ) : null;

  return (
    <>
      <MainColumn className="lg:hidden" data-test="opp-details-mobile">
        <OppDetailsActions id={id} showDrawerButtons={inDrawer} />
        <OppDetailsCardList id={id}>
          <ContactsCard contacts={contacts} />
          <AddressesCard addresses={locations} />
          <QuestionsAndAnswers questions={questions} />
          {details}

          {showSimilarCard && <SimilarOppsCard id={id} inDrawer={inDrawer} />}
        </OppDetailsCardList>
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block" data-test="opp-details-left-col">
        <OppDetailsCardList id={id}>
          <ContactsCard contacts={contacts} />
          <AddressesCard addresses={locations} />
          <QuestionsAndAnswers questions={questions} />
        </OppDetailsCardList>
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block" data-test="opp-details-right-col">
        <OppDetailsActions id={id} showDrawerButtons={inDrawer} />

        <CardList>
          {details}

          {showSimilarCard && <SimilarOppsCard id={id} inDrawer={inDrawer} />}
        </CardList>
      </MainColumn>
    </>
  );
};
