import React, { useMemo, useState } from 'react';
import { Button, Dialog } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';

import { PartnerFeed } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedQuery } from 'api/providerFeedsApi';
import { ManagedFeedSharedFeedsTable } from 'app/organisms/ManagedFeedSharedFeedsTable/ManagedFeedSharedFeedsTable';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

type ManagedFeedSharedInfoProps = {
  id: string;
  publicId: string;
};

const columnHelper = createColumnHelper<PartnerFeed>();

export const ManagedFeedSharedInfo = ({ id, publicId }: ManagedFeedSharedInfoProps) => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const { data, isLoading } = useGetProviderFeedQuery({ id });

  const { partnerFeeds = [] } = data || {};

  const columns = useMemo(
    () => [
      columnHelper.accessor('organizationName', {
        header: 'Organization',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('createdAt', {
        header: 'Shared Since',
        sortingFn: 'basic',
        enableColumnFilter: false,
        cell: e => <span>{formatTime(e.row.original.createdAt, DATETIME_24_SHORT)}</span>
      })
    ],
    []
  );

  const tableData = useMemo(() => partnerFeeds, [partnerFeeds]);

  return (
    <>
      <GovlyTable
        id="managed_feed_shared_info_table"
        columns={columns}
        data={tableData}
        title={<GovlyTableTitle title="Shared With" />}
        isLoading={isLoading}
        emptyStateProps={{
          icon: undefined,
          title: 'No shared feeds',
          description: 'You have not shared this feed with other organizations.'
        }}
        rightElement={
          <GovlyTableToolbar>
            <Button icon="plus" intent="primary" onClick={() => setOpenShareModal(true)}>
              Manage Sharing
            </Button>
          </GovlyTableToolbar>
        }
        initialState={{
          sorting: [{ id: 'name', desc: false }],
          pagination: { pageSize: 50 }
        }}
      />
      <Dialog isOpen={openShareModal} onClose={() => setOpenShareModal(false)} className="min-w-[50%] p-0">
        <ManagedFeedSharedFeedsTable providerFeedPublicId={publicId} providerFeedId={id} />
      </Dialog>
    </>
  );
};
