import React from 'react';
import { Icon, IconName } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import {
  NotificationIndicator,
  NotificationIndicatorProps
} from 'app/atoms/NotificationIndicator/NotificationIndicator';

const textSizes: { [k in NonNullable<AvatarProps['size']>]?: string } = {
  xs: 'text-xxs',
  sm: 'text-xs',
  md: 'text-sm',
  xl: 'text-xl',
  '2xl': 'text-2xl'
};
const sizeClasses = {
  xs: 'w-6 h-6',
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-12 h-12',
  xl: 'w-16 h-16',
  '2xl': 'w-20 h-20'
};
const iconSizes = {
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
  '2xl': 32
};

export type AvatarProps = {
  initials?: string;
  imgSrc?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
  icon?: IconName;
  color?: string;
  notificationProps?: NotificationIndicatorProps;
};

export const Avatar = ({
  initials,
  imgSrc,
  size = 'sm',
  className,
  notificationProps,
  icon,
  color,
  ...rest
}: AvatarProps) => {
  let colorClass = null;
  let colorStyle = undefined;

  if (color) {
    if (color?.startsWith('#')) {
      colorStyle = { backgroundColor: color };
    } else {
      colorClass = color;
    }
  }
  // default if we don't have a color at all
  else if (!className?.includes('bg-')) {
    colorClass = 'bg-gray-700';
  }

  const sizeClass = size ? sizeClasses[size] : undefined;
  const baseClass = cn(
    'inline-flex items-center justify-center rounded-full relative cursor-default shrink-0',
    sizeClass,
    className,
    colorClass
  );
  const initialsClass = cn(size ? textSizes[size] : undefined, 'font-medium leading-none text-white');
  if (imgSrc) {
    return (
      <span className={cn('rounded-full', sizeClass, className)} style={colorStyle} {...rest}>
        <img className={cn('inline-block rounded-full', sizeClass, className)} src={imgSrc} {...rest} />
        {notificationProps && <NotificationIndicator {...notificationProps} />}
      </span>
    );
  }
  if (initials) {
    return (
      <span className={baseClass} style={colorStyle} {...rest}>
        <span className={initialsClass}>{initials.substring(0, 2)}</span>
        {notificationProps && <NotificationIndicator {...notificationProps} />}
      </span>
    );
  }
  if (icon) {
    return (
      <span className={baseClass} style={colorStyle} {...rest}>
        <Icon icon={icon} className={cn('text-white')} size={size ? iconSizes[size] : undefined} />
        {notificationProps && <NotificationIndicator {...notificationProps} />}
      </span>
    );
  }
  return <span className={baseClass} style={colorStyle} {...rest} />;
};

export default Avatar;
