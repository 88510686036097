import React from 'react';

import { ScheduledDateTag } from 'app/molecules/ScheduledDateTag/ScheduledDateTag';

type Props = {
  postedAt?: string;
  modifiedAt?: string;
  modified?: boolean;
};
export const ModifiedAtTags = ({ postedAt, modifiedAt, modified = false }: Props) => {
  if (!postedAt) {
    return null;
  }

  return (
    <>
      <ScheduledDateTag postedAt={postedAt} />
      {modified && modifiedAt ? <ScheduledDateTag modifiedAt={modifiedAt} /> : null}
    </>
  );
};
