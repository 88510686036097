import { DateTime } from 'luxon';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { todayEnd, sixMonthsAgo } from 'app/lib/dates';
import { SearchableActions } from 'app/hooks/search/types';
import { create } from 'zustand';
import { OppSearchDisplayable } from 'types/__generated__/GovlyApi';
import { useFormikSavableSearch } from 'app/hooks/search/useFormikSavableSearch';
import { SearchMeta } from './types';

const currentUserId = globalThis.process?.env?.CURRENT_USER_ID;

export type DateRangeParam = 'posted_at' | 'respond_by' | 'modified_at';

export type RecordTypeParam = 'Solicitation' | 'Prediction' | 'Forecast' | 'Event' | 'Other';

export type { State as OppSearchState };
type State = {
  query: string;
  semantic: boolean;
  meta: SearchMeta;
  filters: {
    searchFields: {
      only: string[];
      exclude: string[];
    };
    aggs: string[];
    aggSlices: string[];
    dateRangeParam: DateRangeParam;
    dateRange: [Date | null, Date | null];
    secondaryDateRanges: { range: [Date | null, Date | null]; param: DateRangeParam }[];
    contractHolderCount: string[];
    contractVehicleIds: string[];
    contractVehicleIdsNone: string[];
    feeds: string[];
    feedsNone: string[];
    predictedContractVehicleNames: string[];
    predictedContractVehicleNamesNone: string[];
    status: string[];
    groups: string[];
    setAside: string[];
    setAsideAll: string[];
    setAsideNone: string[];
    naicsCodes: string[];
    naicsCodesAll: string[];
    naicsCodesNone: string[];
    interest: string[];
    following: string[];
    followingAll: string[];
    excludeFollowing: string[];
    viewed: string[];
    viewedAll: string[];
    excludeViewed: string[];
    ignored: string[];
    ignoredAll: string[];
    excludeIgnored: string[];
    recordType: RecordTypeParam[];
    recordTypeNone: RecordTypeParam[];
    regions: string[];
    regionsAll: string[];
    regionsNone: string[];
    labels: string[];
    labelsAll: string[];
    labelsNone: string[];
    tags: string[];
    tagsAll: string[];
    tagsNone: string[];
    matchedOppSearchIds: string[];
    matchedOppSearchIdsAll: string[];
    matchedOppSearchIdsNone: string[];
    noticeType: string[];
    noticeTypeNone: string[];
    workspaceStages: string[];
    workspaceStagesNone: string[];
    sort: 'posted';
    sortDirection: 'asc' | 'desc';
    buyerDomains: string[];
    buyerDomainsNone: string[];
    buyerIds: string[];
    buyerIdsNone: string[];
    jurisdictionRegions: string[];
    buyerMatchById: boolean;
    includeSubBuyers: boolean;
    forecastStatus: 'not_forecasted' | 'forecasted';
    searchType: 'fed' | 'sled';
  };
};

type Action = SearchableActions;

type Store = State & Action;

export const defaultState: State = {
  query: '',
  semantic: false,
  meta: {
    savedSearchId: null,
    searchState: 'initializing'
  },
  filters: {
    searchFields: {
      only: [],
      exclude: []
    },
    aggs: [],
    aggSlices: [],
    dateRangeParam: 'posted_at',
    dateRange: [sixMonthsAgo(), todayEnd()],
    secondaryDateRanges: [],
    contractHolderCount: [],
    contractVehicleIds: [],
    contractVehicleIdsNone: [],
    feeds: [],
    feedsNone: [],
    predictedContractVehicleNames: [],
    predictedContractVehicleNamesNone: [],
    status: [],
    groups: [],
    setAside: [],
    setAsideAll: [],
    setAsideNone: [],
    naicsCodes: [],
    naicsCodesAll: [],
    naicsCodesNone: [],
    interest: [],
    following: [],
    followingAll: [],
    excludeFollowing: [],
    viewed: [],
    viewedAll: [],
    excludeViewed: [],
    ignored: [],
    ignoredAll: [],
    excludeIgnored: currentUserId ? [currentUserId] : [],
    recordType: [],
    recordTypeNone: [],
    regions: [],
    regionsAll: [],
    regionsNone: [],
    labels: [],
    labelsAll: [],
    labelsNone: [],
    tags: [],
    tagsAll: [],
    tagsNone: [],
    matchedOppSearchIds: [],
    matchedOppSearchIdsAll: [],
    matchedOppSearchIdsNone: [],
    noticeType: [],
    noticeTypeNone: [],
    workspaceStages: [],
    workspaceStagesNone: [],
    sort: 'posted',
    sortDirection: 'desc',
    buyerDomains: [],
    buyerDomainsNone: [],
    buyerIds: [],
    buyerIdsNone: [],
    buyerMatchById: true,
    includeSubBuyers: true,
    jurisdictionRegions: [],
    searchType: 'fed',
    forecastStatus: 'not_forecasted'
  }
};

export const getFormState = (state: Store): State => {
  const { reset: _, ...formState } = state;
  return formState;
};

export const useOppSearchCache = create<Store>(set => ({
  ...defaultState,
  reset: () => set(c => ({ ...c, ...defaultState }), true)
}));

type CalculateDateRangeFromSearchArgs = { relativeStartDayPivot?: number; relativeEndDayPivot?: number };
const calculateDateRangeFromSearch = ({
  relativeStartDayPivot,
  relativeEndDayPivot
}: CalculateDateRangeFromSearchArgs): [Date, Date] => {
  const end = DateTime.local().endOf('day').plus({ days: relativeEndDayPivot });
  const start = DateTime.local().startOf('day').plus({ days: relativeStartDayPivot });

  return [start.toJSDate(), end.toJSDate()];
};

type GetStateFromSavedSearchArgs = {
  savedSearch: { id: string | null; query: OppSearchDisplayable['query']; context?: OppSearchDisplayable['context'] };
  meta: State['meta'];
};
export const getStateFromSavedSearch = ({ savedSearch, meta }: GetStateFromSavedSearchArgs): State => {
  const {
    context = {} as OppSearchDisplayable['context'],
    query: { query, ...savedFilters }
  } = savedSearch || { query: { query: '' } };

  if (context.relativeStartDayPivot != null && context.relativeEndDayPivot != null) {
    savedFilters.dateRange = calculateDateRangeFromSearch(context);
  }

  return {
    query: query || '',
    semantic: false,
    filters: { ...defaultState.filters, excludeIgnored: [], ...savedFilters },
    meta: { ...meta, savedSearchId: savedSearch.id }
  };
};

export const useInitializeOppSearchCache = () => {
  const { data: savedSearches = [] } = useGetOppSearchesQuery({ active: true });
  useFormikSavableSearch({ getStateFromSavedSearch, savedSearches, store: useOppSearchCache });
};
