import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { Button, ButtonProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

export type NavLinkButtonProps = (
  | ({ to: string } & Omit<LinkProps, 'to'>)
  | React.HTMLAttributes<HTMLAnchorElement>
) & {
  buttonProps?: ButtonProps;
  className?: string;
  text?: string;
  prefixMatch?: boolean;
  prefix?: string;
  forceCurrent?: boolean;
};

export const NavLinkButton = ({
  buttonProps,
  className,
  text,
  prefixMatch,
  prefix,
  forceCurrent,
  ...rest
}: NavLinkButtonProps) => {
  const { pathname } = useLocation();
  const to = 'to' in rest ? rest.to : '';
  const current =
    (prefixMatch ? `${pathname}`.startsWith(prefix || to) : [`/${to}`, to].includes(pathname)) || !!forceCurrent;
  const Tag = to ? Link : 'a';

  return (
    <Tag to={to} className={cn('no-underline', className)} {...rest}>
      <Button
        tabIndex={-1}
        data-test={`nav-link-${text}`}
        minimal
        active={current}
        alignText="left"
        className={cn('fill w-full rounded ', {
          'bg-gray-200 dark:bg-gray-800': current
        })}
        {...{ text, ...buttonProps }}
      />
    </Tag>
  );
};
