import { AwardSearchFilters } from 'app/hooks/search/useAwardSearchCache';
import { TileLayer } from 'app/lib/mapbox';
import { FIPS_TO_STATE_ABBR } from 'app/lib/usStates';
import { MapAgg } from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsMapStore';
import * as mapboxUtils from 'app/lib/mapbox';
import { MapboxGeoJSONFeature, VectorSourceRaw } from 'react-map-gl';

type FeatureWithProperties<Properties> = Omit<MapboxGeoJSONFeature, 'properties'> & {
  properties: Properties;
};

export type CongressionalDistrictFeature = FeatureWithProperties<{
  ALAND: number;
  AWATER: number;
  CD118FP: string;
  CDSESSN: string;
  GEOID: string;
  GEOIDFQ: string;
  LSAD: string;
  NAMELSAD: string;
  STATEFP: string;
}>;

export type StateFeature = FeatureWithProperties<{
  ALAND: number;
  AWATER: number;
  GEOID: string;
  GEOIDFQ: string;
  LSAD: string;
  NAME: string;
  STATEFP: string;
  STATENS: string;
  STUSPS: string;
}>;

const getCDKey = (feature: MapboxGeoJSONFeature) => {
  const { CD118FP: cd, STATEFP: fips } = (feature as CongressionalDistrictFeature).properties;
  return `${FIPS_TO_STATE_ABBR[fips as keyof typeof FIPS_TO_STATE_ABBR]}-${cd}`;
};

export const ZOOM_THRESHHOLD = 5;

export const TILE_LAYERS: Record<
  MapAgg,
  TileLayer & {
    title: string;
    agg: MapAgg;
    filterKey: keyof NonNullable<AwardSearchFilters['placeOfPerformance']>;
    sourceProps: Partial<VectorSourceRaw>;
  }
> = {
  usPopRegionAgg: {
    ...mapboxUtils.TILE_LAYERS.state,
    title: 'Contract Awards by State',
    agg: 'usPopRegionAgg',
    filterKey: 'regions',
    sourceProps: {
      maxzoom: ZOOM_THRESHHOLD
    }
  },
  usPopCdAgg: {
    title: 'Contract Awards by Congressional District',
    url: 'mapbox://govly.3s1n03sr',
    sourceLayer: 'cb_2023_us_cd118_20m-15umum',
    agg: 'usPopCdAgg',
    source: 'congressional-districts',
    colorScheme: 'green',
    getFeatureKey: getCDKey,
    getFeatureLabel: getCDKey,
    filterKey: 'congressionalDistricts',
    sourceProps: {
      minzoom: ZOOM_THRESHHOLD
    },
    filter: ['has', 'CD118FP' satisfies keyof CongressionalDistrictFeature['properties']]
  }
} as const;

export const getAggInfo = (
  aggregation: MapAgg,
  feature?: MapboxGeoJSONFeature | null
): {
  title: string;
  drilldownFilters: Partial<AwardSearchFilters>;
  featureLabel: string;
} => {
  const { title, filterKey, getFeatureKey, getFeatureLabel } = TILE_LAYERS[aggregation];

  return {
    title,
    drilldownFilters: {
      placeOfPerformance: {
        countries: [],
        regions: [],
        congressionalDistricts: [],
        [filterKey]: feature ? [(getFeatureKey as (f: typeof feature) => string)(feature)] : []
      }
    },
    featureLabel: feature ? (getFeatureLabel as (f: typeof feature) => string)(feature) : ''
  };
};
