import { RecordChannelPartner } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['ChannelPartner'] });

type GetChannelPartners = {
  params: {
    vendorId: string;
  };
  result: RecordChannelPartner[];
};

type CreateRecordChannelPartner = {
  params: {
    vendorId: string;
  } & Pick<RecordChannelPartner, 'channelPartnerableId' | 'channelPartnerableType' | 'tier' | 'sortOrder'>;
  result: RecordChannelPartner;
};

type UpdateRecordChannelPartner = {
  params: Pick<RecordChannelPartner, 'id' | 'channelPartnerableId' | 'tier' | 'sortOrder'>;
  result: RecordChannelPartner;
};

type DeleteRecordChannelPartner = {
  params: Pick<RecordChannelPartner, 'id' | 'channelPartnerableId'>;
  result: void;
};

export const channelPartnersApi = api.injectEndpoints({
  endpoints: build => ({
    getChannelPartners: build.query<GetChannelPartners['result'], GetChannelPartners['params']>({
      query: params => ({
        url: '/v2/channel_partners',
        params
      }),
      providesTags: (result, error, arg) => ['ChannelPartner', { type: 'ChannelPartner', id: `VENDOR:${arg.vendorId}` }]
    }),
    createRecordChannelPartner: build.mutation<
      CreateRecordChannelPartner['result'],
      CreateRecordChannelPartner['params']
    >({
      query: body => ({
        url: `/v2/channel_partners`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ChannelPartner', id: `VENDOR:${arg.channelPartnerableId}` }]
    }),
    updateRecordChannelPartner: build.mutation<
      UpdateRecordChannelPartner['result'],
      UpdateRecordChannelPartner['params']
    >({
      query: ({ id, ...body }) => ({
        url: `/v2/channel_partners/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ChannelPartner', id: `VENDOR:${arg.channelPartnerableId}` }]
    }),
    deleteRecordChannelPartner: build.mutation<
      DeleteRecordChannelPartner['result'],
      DeleteRecordChannelPartner['params']
    >({
      query: ({ id }) => ({
        url: `/v2/channel_partners/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ChannelPartner', id: `VENDOR:${arg.channelPartnerableId}` }]
    })
  })
});

export const {
  useGetChannelPartnersQuery,
  useCreateRecordChannelPartnerMutation,
  useDeleteRecordChannelPartnerMutation,
  useUpdateRecordChannelPartnerMutation
} = channelPartnersApi;
