import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { TrackableEvent, trackEvent } from 'app/lib/trackEvent';

export const getPosthogAttr = (value: string, key = 'govly-component') => ({
  [`data-ph-capture-attribute-${key}`]: value
});

export const useEventTracking = () => {
  const { search, pathname } = useLocation();

  return useMemo(
    () => ({
      trackEvent: ((event: TrackableEvent) =>
        trackEvent({
          ...event,
          properties: {
            ...event.properties,
            path: pathname,
            queryParams: search
          }
        })) as typeof trackEvent
    }),
    [pathname, search]
  );
};
