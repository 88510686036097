import React from 'react';
import { InputCompositeFilter } from 'app/molecules/InputCompositeFilter/InputCompositeFilter';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useFormikFilterCardListItem } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { FormGroup } from '@blueprintjs/core';
import { PriceRangeInput } from 'app/atoms/inputs/RangeInput/PriceRangeInput';
import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { useSubInputsSelected } from 'app/molecules/InputCompositeFilter/useSubInputsSelected';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { RangeInput } from 'app/atoms/inputs/RangeInput/RangeInput';
import { DeepKeys } from '@tanstack/react-table';

type Key = DeepKeys<AwardSearchForm>;

const NAMES_AND_LABELS: [Key, string][] = [
  ['filters.potentialAwardAmountRange', 'Potential'],
  ['filters.currentAwardAmountRange', 'Current'],
  ['filters.obligatedAwardAmountRange', 'Obligated'],
  ['filters.outlayedAwardAmountRange', 'Outlayed'],
  ['filters.remainingPotentialAwardAmountRange', 'Remaining Potential']
];

const NAMES = NAMES_AND_LABELS.map(([name]) => name);

export const AwardSearchFiltersAmounts = () => {
  const getField = useGetFormikField({ debounceTime: 500 });

  const props = useFormikFilterCardListItem({
    names: NAMES,
    submitOnChange: true
  });

  const subInputsSelected = useSubInputsSelected({ names: NAMES });

  return (
    <InputFilterCardListItem title="Award Amounts" {...props}>
      <InputCompositeFilter subInputsSelected={subInputsSelected}>
        {NAMES_AND_LABELS.map(([name, label]) => (
          <FormGroup label={label} key={name}>
            <PriceRangeInput {...FormikToBp.toRange(getField(name))} />
          </FormGroup>
        ))}

        <FormGroup label="Percent of Potential Remaining">
          <RangeInput
            {...FormikToBp.toRange(getField('filters.percentRemainingPotentialAwardAmountRange'))}
            majorStepSize={25}
            stepSize={10}
            minorStepSize={1}
            leftIcon={'percentage'}
          />
        </FormGroup>
      </InputCompositeFilter>
    </InputFilterCardListItem>
  );
};
