import React from 'react';
import { UIMatch, useLocation, useMatches } from 'react-router-dom';
import { marketingRoutes, noFooterRoutes } from 'app/lib/routing';
import { MarketingFooter } from 'app/molecules/MarketingFooter/MarketingFooter';
import isObject from 'lodash-es/isObject';
import { AppFooter } from './AppFooter';

function shouldHideFooter(match: UIMatch) {
  return isObject(match.handle) && 'hideFooter' in match.handle && match.handle.hideFooter;
}

export const Footer = () => {
  const { pathname } = useLocation();
  const matches = useMatches();

  if (noFooterRoutes.includes(pathname) || matches.some(shouldHideFooter)) {
    return null;
  }

  if (marketingRoutes.includes(pathname)) {
    return <MarketingFooter />;
  }

  return <AppFooter />;
};
