import React, { useState } from 'react';
import { Collapse, FormGroup, Button } from '@blueprintjs/core';

import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

type SearchFacetProps = {
  label: string;
  children: React.ReactNode;
};

export const SearchFacet = ({ children, label }: SearchFacetProps) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="mr-0 lg:mr-4">
      <FormGroup
        label={
          <div className="flex items-center justify-between">
            <LabelSpan label={label} className="text-base font-bold" />
            <Button minimal icon={isOpen ? 'minus' : 'plus'} small onClick={() => setIsOpen(!isOpen)} />
          </div>
        }
      >
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </FormGroup>
    </div>
  );
};
