import React from 'react';
import { ApiSettings } from 'app/organisms/ApiSettings/ApiSettings';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const ApiSettingsPage = () => {
  return (
    <SubscriptionRequiredPageWrapper
      subscriptions={['api']}
      paywallPageProps={{
        title: 'API Integration is a paid feature'
      }}
    >
      <ApiSettings />
    </SubscriptionRequiredPageWrapper>
  );
};
