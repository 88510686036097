import { cn } from 'app/lib/cn';
import React from 'react';

type AssistantHeaderProps = {
  className?: string;
  children: React.ReactNode;
};

export const AssistantHeader = ({ children, className }: AssistantHeaderProps) => {
  return (
    <div className="absolute top-0 left-0 w-full bg-white border-b-2 border-gray-100 rounded-t">
      <div className={cn('flex flex-row w-full justify-between p-2 gap-1', className)}>{children}</div>
    </div>
  );
};
