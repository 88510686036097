import React from 'react';
import { Button } from '@blueprintjs/core';

import { useDeletePartnerFeedMutation } from 'api/partnerFeedsApi';

type RevokeFeedAcessButtonProps = {
  id: string;
  providerFeedId: string;
};

export const RevokeFeedAcessButton = ({ id, providerFeedId }: RevokeFeedAcessButtonProps) => {
  const [deletePartnerFeed] = useDeletePartnerFeedMutation();

  return (
    <Button
      small
      icon="minus"
      intent="danger"
      onClick={() => {
        deletePartnerFeed({ id, providerFeedId });
      }}
    >
      Revoke Access
    </Button>
  );
};
