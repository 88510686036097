import React from 'react';
import { useParams } from 'react-router-dom';
import { ContactPageContents } from 'app/organisms/ContactPageContents/ContactPageContents';
import { NotFound } from 'app/molecules/NotFound/NotFound';

import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

type ContactPageProps = {
  id?: string;
  inDrawer?: boolean;
};

export const ContactPage = ({ id: propsId, inDrawer }: ContactPageProps) => {
  const { id: paramsId } = useParams();
  const id = propsId || paramsId;

  if (!id) {
    return <NotFound />;
  }

  return (
    <SubscriptionRequiredPageWrapper
      subscriptions={['seatPaid']}
      paywallPageProps={{
        title: 'Contact Search is a Paid Feature',
        description: <p>We utilize AI to extract contact data from opportunities and their attachments.</p>
      }}
    >
      <ContactPageContents id={id} inDrawer={inDrawer} />
    </SubscriptionRequiredPageWrapper>
  );
};
