import React from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import {
  selectedSeriesSelector,
  sliceLabelSelector,
  useAwardSearchAnalyticsHistogramStore
} from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsHistogramStore';

export const AwardSearchAnalyticsHistogramDrawerTitle = () => {
  const { sliceType, lastClickedPoint: point } = useAwardSearchAnalyticsHistogramStore();
  const selectedSeries = useAwardSearchAnalyticsHistogramStore(selectedSeriesSelector);
  const sliceLabel = useAwardSearchAnalyticsHistogramStore(sliceLabelSelector);

  return (
    <>
      <span>Awards Over Time</span>

      {match({ selectedSeries, sliceType })
        .with({ sliceType: 'actionDateTotalAgg' }, () => {
          if (!point?.x) return null;
          const date = new Date(point.x);
          const utcStr = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', timeZone: 'UTC' }).format(
            date
          );
          return <Tag minimal>{utcStr}</Tag>;
        })
        .when(
          ({ selectedSeries }) => selectedSeries.length > 1,
          () => (
            <Tooltip content={selectedSeries.join(', ')} className="inline-flex">
              <Tag minimal>
                {sliceLabel} ({selectedSeries.length})
              </Tag>
            </Tooltip>
          )
        )
        .when(
          ({ selectedSeries }) => selectedSeries.length === 1,
          () => (
            <Tag minimal>
              {sliceLabel}: {selectedSeries[0]}
            </Tag>
          )
        )
        .otherwise(() => null)}
    </>
  );
};
