/**
 * This helper automatically handles matching pairs.
 * Specifically, it does the following:
 *
 * 1. If the key is a closer, and the character in front of the cursor is the
 *    same, simply move the cursor forward.
 * 2. If the key is an opener, insert the opener at the beginning of the
 *    selection, and the closer at the end of the selection, and move the
 *    selection forward.
 * 3. If the backspace is hit, and the characters before and after the cursor
 *    are a pair, remove both characters and move the cursor backward.
 */
const pairs = ['()', '[]', '{}', `''`, '""'];
const openers = pairs.map(pair => pair[0]);
const closers = pairs.map(pair => pair[1]);

const isAlphanumeric = (value = '') => {
  return value.match(/[a-zA-Z0-9_]/);
};

const shouldMoveCursorForward = (key: string, value: string, selectionStart: number, selectionEnd: number) => {
  if (!closers.includes(key)) {
    return false;
  }
  // Never move selection forward for multi-character selections
  if (selectionStart !== selectionEnd) {
    return false;
  }
  // Move selection forward if the key is the same as the closer in front of the selection
  return value.charAt(selectionEnd) === key;
};

const shouldInsertMatchingCloser = (key: string, value: string, selectionStart: number, selectionEnd: number) => {
  if (!openers.includes(key)) {
    return false;
  }
  // Always insert for multi-character selections
  if (selectionStart !== selectionEnd) {
    return true;
  }
  const precedingCharacter = value.charAt(selectionStart - 1);
  const followingCharacter = value.charAt(selectionStart + 1);
  // Don't insert if the preceding character is a backslash
  if (precedingCharacter === '\\') {
    return false;
  }
  // Don't insert if it's a quote and the either of the preceding/following characters is alphanumeric
  return !(['"', `'`].includes(key) && (isAlphanumeric(precedingCharacter) || isAlphanumeric(followingCharacter)));
};

const shouldRemovePair = (
  key: string,
  metaKey: boolean,
  value: string,
  selectionStart: number,
  selectionEnd: number
) => {
  if (key !== 'Backspace' || metaKey) {
    return false;
  }
  // Never remove for multi-character selections
  if (selectionStart !== selectionEnd) {
    return false;
  }
  // Remove if the preceding/following characters are a pair
  return pairs.includes(value.substr(selectionEnd - 1, 2));
};

export const insidePair = (str: string, idx: number, open: string, close?: string) => {
  let count = 0;
  for (let i = 0; i < idx; i += 1) {
    if (str[i] === open) count += 1;
    if (close) {
      if (str[i] === close) count -= 1;
    }
  }
  if (!close || open === close) {
    return count % 2 === 1;
  }
  return count !== 0;
};

export const insideQuote = (str: string, idx: number) => {
  return insidePair(str, idx, '"') || insidePair(str, idx, "'");
};

export const matchPairs = ({
  value,
  selectionStart,
  selectionEnd,
  key,
  metaKey,
  updateQuery,
  preventDefault
}: {
  value: string;
  selectionStart: number;
  selectionEnd: number;
  key: string;
  metaKey: boolean;
  updateQuery: (val: string, start: number, end: number) => void;
  preventDefault: () => void;
}) => {
  if (!value.trim().length && [',', '|', ' '].includes(key)) {
    preventDefault();
  } else if (shouldMoveCursorForward(key, value, selectionStart, selectionEnd)) {
    preventDefault();
    updateQuery(value, selectionStart + 1, selectionEnd + 1);
  } else if (shouldInsertMatchingCloser(key, value, selectionStart, selectionEnd)) {
    preventDefault();
    const newValue =
      value.substr(0, selectionStart) +
      key +
      value.substring(selectionStart, selectionEnd) +
      closers[openers.indexOf(key)] +
      value.substr(selectionEnd);
    updateQuery(newValue, selectionStart + 1, selectionEnd + 1);
  } else if (shouldRemovePair(key, metaKey, value, selectionStart, selectionEnd)) {
    preventDefault();
    const newValue = value.substr(0, selectionEnd - 1) + value.substr(selectionEnd + 1);
    updateQuery(newValue, selectionStart - 1, selectionEnd - 1);
  } else if (['|', ','].includes(key) && !insideQuote(value, selectionStart)) {
    preventDefault();
    const newValue = `${value.substr(0, selectionStart)} OR ${value.substr(selectionEnd)}`;
    updateQuery(newValue, selectionStart + 4, selectionEnd + 4);
  } else if (key === '+' && !insideQuote(value, selectionStart)) {
    preventDefault();
    const newValue = `${value.substr(0, selectionStart)} AND ${value.substr(selectionEnd)}`;
    updateQuery(newValue, selectionStart + 5, selectionEnd + 5);
  } else if (
    key === '-' &&
    !insideQuote(value, selectionStart) &&
    [undefined, ' '].includes(value[selectionStart - 1])
  ) {
    preventDefault();
    const newValue = `${value.substr(0, selectionStart)} NOT()${value.substr(selectionEnd)}`;
    updateQuery(newValue, selectionStart + 5, selectionEnd + 5);
  }
};

export const formatQuery = (query: string, verbose: boolean) => {
  let q: string | string[] = query.trim();
  q = q.replace(/(\||\s+or\s+)(?=(?:[^"]|"[^"]*")*$)/gi, ' OR ');
  q = q.replace(/(\+|\s+and\s+)(?=(?:[^"]|"[^"]*")*$)/gi, ' AND ');
  q = q.replace(/\s+-(?=(?:[^'"]*['"][^'"]*['"])*[^'"]*$)/g, ' NOT');
  q = q.replace(/(OR\s+)+/gi, 'OR ');
  q = q.split(/\s+OR\s+(?=(?:[^'"]*['"][^'"]*['"])*[^'"]*$)/g);
  if (q.length > 1) {
    q = q
      .map(s => {
        if (/\s+(?=(?:[^'"]*['"][^'"]*['"])*[^'"]*$)/g.test(s)) {
          return s.includes('(') || s.includes(')') ? s : `(${s.trim()})`;
        }
        return s;
      })
      .join(' OR ');
  } else {
    [q] = q;
  }
  q = q.replace(/'(\s+|$)/g, '" ');
  q = q.replace(/(\s+|^)'/g, ' "');
  q = q.replace(/\s+/g, ' ');
  q = q.replace(/\s+(?=(?:[^'"]*['"][^'"]*['"])*[^'"]*$)/g, ' AND ');
  q = q.replace(/AND\s+OR\s+AND/gi, 'OR');
  q = q.replace(/(AND\s+)+/gi, 'AND ');
  q = q.trim();
  if (verbose) return q;
  q = q.replaceAll(' OR ', ' | ');
  q = q.replaceAll('AND', ' ');
  q = q.replaceAll(' NOT', ' -');
  q = q.replace(/\s+(?=(?:[^'"]*['"][^'"]*['"])*[^'"]*$)/g, ' ');
  q = q.trim();
  return q;
};

export const onQueryChange = ({ value, setContent }: { value: string; setContent: (c: string) => void }) => {
  setContent(value);
};

export const onQueryChangeEvent = ({
  e,
  ...rest
}: {
  e: React.ChangeEvent<HTMLTextAreaElement>;
  setContent: (c: string) => void;
}) => {
  onQueryChange({ value: e.target.value, ...rest });
};

export const onQueryKeyDownEvent = <
  E extends Partial<Omit<React.KeyboardEvent<HTMLTextAreaElement>, 'target'> & { target?: HTMLTextAreaElement | null }>
>({
  e,
  onEnter,
  setContent,
  setSelectionStart,
  setSelectionEnd
}: {
  e: E;
  onEnter?: (e: E) => void;
  setContent: (c: string) => void;
  setSelectionStart: (s: number) => void;
  setSelectionEnd: (s: number) => void;
}) => {
  const { key = '', metaKey = false, shiftKey, preventDefault, target } = e;
  const { value = '', selectionStart = 0, selectionEnd = 0 } = target ?? {};
  const prevent = preventDefault?.bind(e);
  if (key === 'Enter' && !shiftKey) {
    prevent?.();
    onEnter?.(e);
  } else {
    const update = (q: string, newSelectionStart: number, newSelectionEnd: number) => {
      setContent(q);
      setSelectionStart(newSelectionStart);
      setSelectionEnd(newSelectionEnd);
    };
    matchPairs({
      value,
      selectionStart,
      selectionEnd,
      key,
      metaKey,
      updateQuery: update,
      preventDefault: prevent ?? (() => {})
    });
  }
};

export const querystringMarkup = {
  'text-purple-700 dark:text-purple-400': /[()]/,
  'text-orange-700 dark:text-orange-400': /[*~]=?/,
  'text-green-700 dark:text-green-400': [
    {
      pattern: /(^|[^.]|\.\.\.\s*)\b(?:AND)\b/i,
      lookbehind: true
    },
    {
      pattern: /\s+[-]=?/
    }
  ],
  'text-cyan-700 dark:text-cyan-400': [
    {
      pattern: /(^|[^.]|\.\.\.\s*)\b(?:OR)\b/i,
      lookbehind: true
    },
    {
      pattern: /[|]=?/
    }
  ],
  'text-red-600 dark:text-red-400': [
    {
      pattern: /(^|[^.]|\.\.\.\s*)\b(?:NOT)\b/i,
      lookbehind: true
    },
    {
      pattern: /[|]=?/
    },
    {
      pattern: /\s+[-]=?/
    }
  ],
  'text-blue-700 dark:text-blue-400': {
    pattern: /(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/g,
    greedy: true
  }
};
