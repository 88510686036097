import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';

export const OppSearchAnalyticsFallback = () => {
  return (
    <Card>
      <CardBody>
        <NonIdealState
          icon="graph"
          title="There has been an error"
          description={
            <div>
              {`Our team has been notified and we will investigate the issue.`}
              <br />
              {`In the meantime, please refresh the page to try again.`}
            </div>
          }
        />
      </CardBody>
    </Card>
  );
};
