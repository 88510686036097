import { useSearchPagination } from 'app/hooks/useSearchPagination';
import {
  OppSearchResult,
  OppIndex,
  OppShow,
  SearchQuery,
  OutreachRecipientShow,
  SearchQueryMeta,
  OppAttachmentWithSummary
} from 'types/__generated__/GovlyApi';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { TypeaheadQuery } from 'types/__generated__/GovlyApi';
import { oppWorkspacesApi } from './oppWorkspacesApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Opp', 'OppsAggregations', 'OppAttachments'] });

export type { Search as SearchOpps };
type Search = {
  params: { query?: string; semantic?: boolean; page?: number; per?: number; contactIds?: string[] } & Partial<
    OppSearchState['filters']
  >;

  result: Search['response'] & { query: Search['params'] };
  response: {
    results: OppSearchResult[];
    meta: SearchQueryMeta;
  };
};

export type GetOpps = {
  params: {
    ids: string[];
  };
  result: OppIndex[];
};

export type GetSimilarOpps = {
  params: {
    id: string;
  };
  result: OppIndex[];
};

export type GetOpp = {
  params: {
    id: string;
    fromSearch?: boolean;
  };
  result: OppShow;
};

type OppAggregation = {
  params: {
    query?: string;
    aggs: Array<string> | string;
    size?: number;
    method?: string;
    preprocessor?: string;
  } & Partial<OppSearchState['filters']>;
  result: SearchQuery;
};

type OppTypeahead = {
  params: { query?: string; typeahead: string; filterValues?: Array<string> };
  result: TypeaheadQuery['results'];
  response: TypeaheadQuery;
};

export type ShareOpp = {
  params: {
    id: string;
    subject: string;
    recipients: { name?: string; email: string; company?: string }[];
    customMessage?: string;
  };
  result: OutreachRecipientShow[];
};

type Export = {
  params: { [key: string]: string | number | boolean };
  result: void;
};

export type GetOppAttachments = {
  params: { id: string };
  result: {
    results: OppAttachmentWithSummary[];
  };
};

export const oppsApi = api.injectEndpoints({
  endpoints: build => ({
    search: build.mutation<Search['result'], Search['params']>({
      query: body => ({
        url: `/v2/opps/search`,
        method: 'POST',
        body
      }),
      transformResponse: (response: Search['response'], meta, arg) => ({ query: arg, ...response })
    }),

    oppSearch: build.query<Search['result'], Search['params']>({
      query: body => ({
        url: `/v2/opps/search`,
        method: 'POST',
        body
      }),
      transformResponse: (response: Search['response'], meta, arg) => ({ query: arg, ...response })
    }),

    getOpps: build.query<GetOpps['result'], GetOpps['params']>({
      query: params => ({ url: '/v2/opps', params }),
      providesTags: result => (result ? [...result.map(({ id }) => ({ type: 'Opp' as const, id }))] : []),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: opps } = await queryFulfilled;

          for (const opp of opps) {
            dispatch(
              oppsApi.util.upsertQueryData(
                'getOpp',
                { id: opp.id, fromSearch: true },
                { ...opp, __typename: 'OppShow' }
              )
            );

            for (const workspace of opp.workspaces) {
              dispatch(
                oppWorkspacesApi.util.upsertQueryData(
                  'getOppWorkspace',
                  { id: workspace.id, fromSearch: true },
                  { ...workspace, __typename: 'WorkspaceShow', meta: {} }
                )
              );
            }
          }
        } catch {}
      }
    }),

    getOpp: build.query<GetOpp['result'], GetOpp['params']>({
      query: ({ id, ...params }) => ({
        url: `/v2/opps/${id}`,
        params
      }),
      providesTags: (_result, _err, { id }) => [{ type: 'Opp', id }]
    }),

    getSimilarOpps: build.query<GetSimilarOpps['result'], GetSimilarOpps['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/opps/${id}/similar`, params }),
      providesTags: result => (result ? [...result.map(({ id }) => ({ type: 'Opp' as const, id }))] : []),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: opps } = await queryFulfilled;

          for (const opp of opps) {
            dispatch(
              oppsApi.util.upsertQueryData(
                'getOpp',
                { id: opp.id, fromSearch: true },
                { ...opp, __typename: 'OppShow' }
              )
            );

            for (const workspace of opp.workspaces) {
              dispatch(
                oppWorkspacesApi.util.upsertQueryData(
                  'getOppWorkspace',
                  { id: workspace.id, fromSearch: true },
                  { ...workspace, __typename: 'WorkspaceShow', meta: {} }
                )
              );
            }
          }
        } catch {}
      }
    }),

    oppAggregation: build.query<OppAggregation['result'], OppAggregation['params']>({
      query: params => ({
        url: `/v2/opps/aggregations`,
        method: 'POST',
        params
      })
    }),

    oppTypeahead: build.query<OppTypeahead['result'], OppTypeahead['params']>({
      query: params => ({
        url: `/v2/opps/typeahead`,
        params
      }),
      transformResponse: (response: OppTypeahead['response'], _meta, _arg) => response.results || []
    }),

    shareOpp: build.mutation<ShareOpp['result'], ShareOpp['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/opps/${id}/share`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Opp']
    }),

    export: build.mutation<Export['result'], Export['params']>({
      query: body => ({
        url: `/v2/opps/export`,
        method: 'POST',
        body
      })
    }),

    getOppAttachments: build.query<GetOppAttachments['result'], GetOppAttachments['params']>({
      query: ({ id }) => ({ url: `/v2/opps/${id}/attachments` }),
      providesTags: (_result, _err, { id }) => [{ type: 'OppAttachments', oppId: id }]
    })
  })
});

export const useOppSearchResults = () => {
  const [_search, results] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  return results;
};

export const useOppSearchPagination = () =>
  useSearchPagination({
    mutation: useSearchMutation,
    cacheKey: 'opp-search'
  });

const useOppSearchResultIds = () => {
  const [_search, { data: { results = [] } = {} }] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  return results.map(({ id }) => id);
};

export const useOppSearchResult = (id: string) => {
  const [_search, { result, resultIds }] = useSearchMutation({
    fixedCacheKey: 'opp-search',
    selectFromResult: ({ data: { results = [] } = {} }) => ({
      result: results.find(({ id: oppId }) => oppId === id),
      resultIds: results.map(({ id: oppId }) => oppId)
    })
  });

  return { oppSearchResult: result, oppSearchResultIds: resultIds };
};

export const useOppFromSearchDecorator = (id: string) => {
  const { oppSearchResultIds } = useOppSearchResult(id);

  const { opp, isLoading } = useGetOppsQuery(
    { ids: oppSearchResultIds },
    {
      selectFromResult: ({ data = [], isLoading }) => ({
        opp: data.find(({ id: oppId }) => oppId === id),
        isLoading
      }),
      skip: !oppSearchResultIds?.length
    }
  );

  return { opp, isLoading };
};

export const useWorkspaceFromSearchDecorator = ({ id }: { id: string }) => {
  const oppIds = useOppSearchResultIds();

  const { data: opps = [] } = useGetOppsQuery({ ids: oppIds });

  return opps.flatMap(({ workspaces }) => workspaces).find(({ id: workspaceId }) => workspaceId === id);
};

export const {
  useSearchMutation,
  useLazyOppAggregationQuery,
  useOppAggregationQuery,
  useOppTypeaheadQuery,
  useGetOppsQuery,
  useGetOppQuery,
  useGetSimilarOppsQuery,
  useShareOppMutation,
  useExportMutation,
  useOppSearchQuery,
  useGetOppAttachmentsQuery
} = oppsApi;
