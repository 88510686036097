import { Webhook, WebhookEvent } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';
const api = rootApi.enhanceEndpoints({ addTagTypes: ['Webhook', 'WebhookList', 'WebhookEventList'] });

const invalidatesTags = <Arg extends { id: string }>(result: unknown, err: unknown, arg: Arg) => [
  { type: 'Webhook' as const, id: arg.id }
];

type GetWebhooks = {
  params: void;
  result: Webhook[];
};

type GetWebhook = {
  params: { id: string };
  result: Webhook;
};

type UpdateWebhook = {
  params: Pick<Webhook, 'id' | 'url' | 'enabled' | 'customHeaders' | 'customPayloadTemplate' | 'subscriptions'>;
  result: Webhook;
};

type CreateWebhook = {
  params: Pick<Webhook, 'url' | 'enabled' | 'customHeaders' | 'customPayloadTemplate' | 'subscriptions'>;
  result: Webhook;
};

type DeleteWebhook = {
  params: {
    id: string;
  };
  result: void;
};

type GetWebhookEvents = {
  params: {
    webhookId: string;
    page?: number;
    per?: number;
    filter?: string;
  };
  result: {
    results: WebhookEvent[];
    meta: {
      total: number;
    };
  };
};

type RetryWebhookEvent = {
  params: {
    id: string;
  };
  result: void;
};

export const webhooksApi = api.injectEndpoints({
  endpoints: build => ({
    getWebhooks: build.query<GetWebhooks['result'], GetWebhooks['params']>({
      query: () => ({
        url: '/v2/webhooks'
      }),
      providesTags: (result = [], _error, _arg) => [
        'Webhook',
        'WebhookList',
        ...result.map(({ id }) => ({ type: 'Webhook' as const, id }))
      ]
    }),
    getWebhook: build.query<GetWebhook['result'], GetWebhook['params']>({
      query: ({ id, ...params }) => ({
        url: `/v2/webhooks/${id}`,
        params
      }),
      providesTags: invalidatesTags
    }),
    updateWebhook: build.mutation<UpdateWebhook['result'], UpdateWebhook['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/webhooks/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['WebhookList', 'Webhook']
    }),
    createWebhook: build.mutation<CreateWebhook['result'], CreateWebhook['params']>({
      query: body => ({
        url: `/v2/webhooks`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['WebhookList']
    }),
    deleteWebhook: build.mutation<DeleteWebhook['result'], DeleteWebhook['params']>({
      query: ({ id }) => ({
        url: `/v2/webhooks/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['WebhookList']
    }),
    getWebhookEvents: build.query<GetWebhookEvents['result'], GetWebhookEvents['params']>({
      query: ({ webhookId, ...params }) => ({
        url: `/v2/webhooks/${webhookId}/webhook_events`,
        params
      }),
      providesTags: ['WebhookEventList']
    }),
    retryWebhookEvent: build.mutation<RetryWebhookEvent['result'], RetryWebhookEvent['params']>({
      query: ({ id }) => ({
        url: `/v2/webhook_events/${id}/retry`,
        method: 'POST'
      }),
      invalidatesTags: ['WebhookEventList']
    })
  })
});

export const {
  useGetWebhooksQuery,
  useGetWebhookQuery,
  useUpdateWebhookMutation,
  useCreateWebhookMutation,
  useDeleteWebhookMutation,
  useGetWebhookEventsQuery,
  useRetryWebhookEventMutation
} = webhooksApi;
