import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';

export const HomePage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isLoading: currentUserIsLoading, data: currentUser } = useGetCurrentUserQuery();
  useEffect(() => {
    if (!currentUserIsLoading) {
      if (!currentUser) {
        navigate('/sign_in');
      } else if (currentUser.onboardingStep === 'completed' || currentUser.subscriptionActive || currentUser.sysAdmin) {
        if (currentUser.subscriptionSeatPaid) {
          navigate('/opportunities', { state: { search } });
        } else {
          navigate('/worklist/workspaces');
        }
      } else {
        navigate('/onboarding');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentUserIsLoading]);
  return <AppLoading />;
};
