import React from 'react';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { JsonViewer } from 'app/molecules/JsonViewer/JsonViewer';

import { safeParse } from 'app/lib/strings';

import { WebhookEvent as WebhookEventType, JSONObject } from 'types/__generated__/GovlyApi';

type ParsedWebhookEvent = Omit<WebhookEventType, 'response'> & {
  response: {
    status: number;
    headers: Record<string, string>;
    body: JSONObject;
  };
};

type WebhookEventProps = {
  webhookEvent: WebhookEventType;
};

export const WebhookEvent = ({ webhookEvent }: WebhookEventProps) => {
  const parsedResponse = safeParse(webhookEvent.response);
  if ('body' in parsedResponse && typeof parsedResponse.body === 'string') {
    parsedResponse.body = safeParse(parsedResponse.body);
  }

  const parsedEvent = {
    ...webhookEvent,
    response: parsedResponse
  } as ParsedWebhookEvent;

  return (
    <Card title={`${webhookEvent.event} - ${webhookEvent.id}`}>
      <CardBody>
        <div className="prose prose-sm max-w-none">
          <pre>
            <JsonViewer json={parsedEvent} />
          </pre>
        </div>
      </CardBody>
    </Card>
  );
};
