import { useMemo } from 'react';
import Nango from '@nangohq/frontend';

export const useNango = () => {
  const nango = useMemo(() => {
    return new Nango({ publicKey: process.env.NANGO_PUBLIC_KEY as string });
  }, []);

  return nango;
};
