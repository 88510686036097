import React, { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ButtonGroup, Drawer, Tag } from '@blueprintjs/core';
import { useAwardSearchQueryQuery } from 'api/awardsApi';
import { AwardSearchFilters, AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { DeepKeys, PaginationState, SortingState, VisibilityState, createColumnHelper } from '@tanstack/react-table';
import { USASpendingAwardSearch } from 'types/__generated__/GovlyApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { asCurrency } from 'app/lib/numbers';
import { GovlyTableProps, GovlyTableRoot } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableCard } from 'app/molecules/GovlyTable/GovlyTableCard';
import { getFiltersCount, getNonDefaultFilters } from 'app/hooks/useGetFormikFilterCountByField';
import { pluralize } from 'app/lib/strings';
import { OpenInNewTabButton } from 'app/molecules/OpenInNewTabButton/OpenInNewTabButton';
import { RESULTS_VIEW_PARAM } from 'app/organisms/AwardSearchResults/utils';
import { SearchResultsExportButton } from 'app/organisms/SearchResults/SearchResultsExportButton';

const columnHelper = createColumnHelper<USASpendingAwardSearch>();

type Props = {
  onClose?: () => void;
  filters?: Partial<AwardSearchFilters>;
  drawerTitle?: string | React.ReactNode;
  isOpen?: boolean;
  columnVisibility?: { [k in DeepKeys<USASpendingAwardSearch>]?: boolean };
} & Pick<GovlyTableProps<USASpendingAwardSearch>, 'id'>;

export const AwardSearchAnalyticsTableDrawer = ({
  onClose,
  filters: filtersProp,
  drawerTitle,
  isOpen,
  id,
  columnVisibility
}: Props) => {
  const { values } = useFormikContext<AwardSearchForm>();
  const filtersCount = getFiltersCount(values, v => v.filters);
  const { query, filters } = values;

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 30,
    pageIndex: 0
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'periodOfPerformanceStartDate',
      desc: true
    }
  ]);

  const [sort] = sorting.map(
    ({ id, desc }): Pick<AwardSearchFilters, 'sort' | 'sortDirection'> => ({
      sort: id as AwardSearchForm['filters']['sort'],
      sortDirection: desc ? 'desc' : 'asc'
    })
  );

  const tableResult = useAwardSearchQueryQuery(
    {
      query,
      ...filters,
      ...sort,
      ...filtersProp,
      page: pagination.pageIndex + 1,
      per: pagination.pageSize
    },
    { skip: !isOpen }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('awardIdPiid', {
        enableSorting: false,
        header: 'Award ID',
        cell: e => (
          <LinkTag
            tag="a"
            href={`/awards/${e.row.original.id}`}
            target="_blank"
            className="max-w-32 inline-block truncate text-left"
          >
            {e.getValue()}
          </LinkTag>
        )
      }),
      columnHelper.accessor('actionDate', { header: 'Action Date', size: 80 }),
      columnHelper.accessor('periodOfPerformanceStartDate', { header: 'Start Date', size: 80 }),
      columnHelper.accessor('periodOfPerformanceCurrentEndDate', { header: 'End Date', size: 80 }),
      columnHelper.accessor('potentialTotalValueOfAward', {
        header: 'Potential Award Amount',
        cell: e => <div>{asCurrency(e.getValue())}</div>
      }),
      columnHelper.accessor('totalDollarsObligated', {
        header: 'Obligated Award Amount',
        cell: e => <div>{asCurrency(e.getValue())}</div>
      }),
      columnHelper.accessor('recipientName', {
        header: 'Recipient',
        cell: e => <div className="text-wrap line-clamp-2">{e.getValue()}</div>
      }),
      columnHelper.accessor('awardingOfficeName', {
        header: 'Awarding Office',
        cell: e => <div className="text-wrap line-clamp-2">{e.getValue()}</div>
      }),
      columnHelper.accessor('fundingAgencyName', {
        header: 'Funding Agency',
        cell: e => <div className="text-wrap line-clamp-2">{e.getValue()}</div>
      }),
      columnHelper.accessor('awardingAgencyName', {
        header: 'Awarding Agency',
        cell: e => <div className="text-wrap line-clamp-2">{e.getValue()}</div>
      })
    ],
    []
  );

  const viewInSearchPath = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.append(RESULTS_VIEW_PARAM, 'table');
    const nonDefaultFilters = getNonDefaultFilters(values, v => v.filters);
    const combinedFilters = [...Object.entries(filtersProp ?? {}), ...nonDefaultFilters];
    searchParams.append(`query`, encodeURIComponent(query));
    combinedFilters.forEach(([filter, value]) => {
      searchParams.append(`filters.${filter}`, encodeURIComponent(JSON.stringify(value)));
    });
    return `/awards/results?${searchParams.toString()}`;
  }, [filtersProp, query, values]);

  return (
    <GovlyTableRoot
      id={id}
      data={tableResult.data?.results ?? []}
      isLoading={tableResult.isLoading}
      hasConfigurablePageSize
      columns={columns}
      isFixedLayout
      paginationSteps={[20, 50, 100]}
      initialState={{
        pagination: {
          pageSize: 30
        }
      }}
      enableColumnFilters={false}
      manualSorting
      manualPagination
      manualFiltering
      state={{ sorting, pagination, columnVisibility: columnVisibility as VisibilityState }}
      onSortingChange={setSorting}
      onPaginationChange={setPagination}
      rowCount={tableResult.data?.meta.total ?? 0}
      enableHiding={false}
    >
      <Drawer
        enforceFocus={false}
        isOpen={isOpen}
        onClose={() => {
          onClose?.();
        }}
        position="bottom"
        title={
          <div className="flex justify-between items-center">
            <span className="flex items-center gap-2">
              {typeof drawerTitle === 'string' ? <span>{drawerTitle}</span> : drawerTitle}
              {filtersCount > 0 && (
                <Tag minimal>
                  +{filtersCount} custom {pluralize('filter', 'filters', filtersCount)}
                </Tag>
              )}
            </span>

            <ButtonGroup>
              <OpenInNewTabButton path={viewInSearchPath} text="Full Table View" />

              <SearchResultsExportButton
                query={query}
                filters={{
                  ...filters,
                  ...sort,
                  ...filtersProp
                }}
                resultType="awards"
              />
            </ButtonGroup>
          </div>
        }
      >
        <div className="h-full overflow-y-scroll">
          <GovlyTableCard />
        </div>
      </Drawer>
    </GovlyTableRoot>
  );
};
