import { create } from 'zustand';

type State = {
  defaultState?: unknown;
};

type Store = State;

export type { Store as DefaultSearchStateStore };

export const useDefaultSearchStateStore = create<Store>(() => ({
  defaultState: undefined
}));
