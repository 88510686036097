import React, { useState } from 'react';
import { Button, Tooltip } from '@blueprintjs/core';

import { LabelFormDialog } from 'app/organisms/LabelFormDialog/LabelFormDialog';
import { ContactIndex, USASpendingAwardIndex } from 'types/__generated__/GovlyApi';

export type SearchLabelInteractionProps = {
  taggableType: 'Contact' | 'USASpendingAward';
  resource: ContactIndex | USASpendingAwardIndex;
  iconOnly?: boolean;
};

export const SearchLabelInteraction = ({ taggableType, resource, iconOnly }: SearchLabelInteractionProps) => {
  const [addingLabel, setAddingLabel] = useState(false);

  const emptyResource = JSON.stringify(resource) === '{}';

  return (
    <>
      <Tooltip content="Add a label" disabled={!iconOnly}>
        <Button
          icon="tag"
          text={iconOnly ? null : 'Add a label'}
          disabled={emptyResource}
          onClick={() => setAddingLabel(true)}
        />
      </Tooltip>
      {!emptyResource && (
        <LabelFormDialog
          isOpen={addingLabel}
          onClose={() => setAddingLabel(false)}
          initialValues={{
            organizationLabelIds: resource?.labels?.map(({ id }) => id) || [],
            taggableId: resource.id,
            taggableType
          }}
        />
      )}
    </>
  );
};
