import React from 'react';

import { Divider } from '@blueprintjs/core';
import { BudgetsChat } from 'app/organisms/BudgetsChat/BudgetsChat';

export const BudgetsOverviewPage = () => {
  return (
    <>
      <div className="mx-auto lg:px-4 pt-5">
        <p className="text-gray-500">Use budget analysis to get insights across multiple agencies.</p>
        <Divider className="w-full m-0" />
      </div>
      <BudgetsChat />
    </>
  );
};
