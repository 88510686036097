import React from 'react';
import startCase from 'lodash-es/startCase';
import { OppSearchResult } from 'types/__generated__/GovlyApi';

const getTopHighlight = (highlights: NonNullable<OppSearchResult['highlight']>) => {
  const f: Record<string, { label: string; value: string }> = {};

  Object.keys(highlights || {}).forEach(key => {
    const label = key.split('.')[0];
    const str = highlights[key][0];
    const start = Math.max(str.indexOf('<mark>') - 500, 0);
    const end = Math.min(str.indexOf('</mark>') + 500, str.length);
    const value = `${start !== 0 ? '...' : ''}${str.substring(start, end)}${end !== str.length ? '...' : ''}`;

    f[label] = { label, value };
  });

  return (
    f['identifiers'] ||
    f['title'] ||
    f['description'] ||
    f['source'] ||
    f['attachments'] ||
    f['aiSummary'] ||
    f['aiTitle']
  );
};

export const OppSearchResultHighlight = ({ highlights }: { highlights: OppSearchResult['highlight'] }) => {
  if (!highlights) return null;
  const highlight = getTopHighlight(highlights);
  if (!highlight) return null;

  let label = startCase(highlight.label);

  if (highlight.label === 'aiTitle') {
    label = 'AI Title';
  }

  if (highlight.label === 'aiSummary') {
    label = 'AI Summary';
  }

  return (
    <blockquote className="bp5-blockquote mt-2">
      <small>
        <em>
          <strong>{`Matched in ${label}:`}</strong>
        </em>
        <em>
          <div dangerouslySetInnerHTML={{ __html: highlight.value }} />
        </em>
      </small>
    </blockquote>
  );
};
