import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { usePingCurrentUserQuery, useGetCurrentUserQuery } from 'api/currentUserApi';

export const useMonitorCurrentUser = () => {
  const ref = useRef<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();
  const { data: currentUserPing, isLoading: pollLoading } = usePingCurrentUserQuery(undefined, {
    pollingInterval: 30000
  });

  const isLoading = currentUserLoading || pollLoading;

  const id = currentUserPing?.id;

  useEffect(() => {
    if (!id && !isLoading) {
      // signed out
      navigate('/sign_in', { state: { requestedLocation: location, unauthorized: true } });
      return;
    }

    if (ref.current && ref.current !== id) {
      // switched orgs
      window.location.reload();
      return;
    }

    ref.current = id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoading]);

  return { currentUser, isLoading };
};
