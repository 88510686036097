import React from 'react';

import { cn } from 'app/lib/cn';
import { CheckboxGroupInput, CheckboxGroupInputProps } from 'app/atoms/inputs/CheckboxGroupInput/CheckboxGroupInput';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { GetOrganizationUsers, useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { UserMenuItem } from 'app/molecules/UserMenuItem/UserMenuItem';
import { MultiSelectProps } from '@blueprintjs/select';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';

type Item = { label: React.ReactNode; value: string; email: string; name?: string };
type Props = Partial<MultiSelectProps<Item>> &
  Partial<CheckboxGroupInputProps> & {
    name: string;
    queryView?: GetOrganizationUsers['params']['view'];
    preventCheckboxes?: boolean;
  };

export const UserMultiSelectInput = ({
  queryView = 'current_org',
  preventCheckboxes = false,
  name,
  ...props
}: Props) => {
  const { data = [], isLoading } = useGetOrganizationUsersQuery({ view: queryView });
  const organizationUsers = data as OrganizationUserCurrentOrg[];

  if (isLoading) {
    return null;
  }

  const asCheckboxes = !preventCheckboxes && organizationUsers.length < 8;

  const items = organizationUsers.map(item => ({
    label: <UserMenuItem item={item} className={cn({ 'inline-flex': asCheckboxes, flex: !asCheckboxes })} />,
    value: item.id,
    email: item.email,
    name: item.name
  }));

  if (asCheckboxes) {
    return (
      <CheckboxGroupInput
        name={name}
        hasSelectAll
        items={items}
        className="py-6"
        {...(props as Partial<CheckboxGroupInputProps>)}
      />
    );
  }

  return (
    <MultiSelectInput
      name={name}
      items={items}
      searchKeys={['name', 'email']}
      className="py-6"
      tagInputProps={{ large: true }}
      {...(props as Partial<MultiSelectProps<Item>>)}
    />
  );
};
