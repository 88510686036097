import { DeepKeys } from '@tanstack/react-table';

export function makeSafeFormikFields<Values>() {
  return {
    safeFields: (...values: DeepKeys<Values>[]) => values,
    safeField: (value: DeepKeys<Values>) => value,
    safeTuple: <Tuple extends [DeepKeys<Values>, ...unknown[]]>(tuple: Tuple) => tuple,
    safeTuples: <Tuple extends [DeepKeys<Values>, ...unknown[]]>(...tuples: Tuple[]) => tuples
  };
}
