import React from 'react';
import { Formik, Form, useFormikContext, FormikConfig } from 'formik';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetSavedSearchesQuery, useCreateSavedSearchMutation } from 'api/savedSearchesApi';
import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { SavedSearchVolumeCallout } from 'app/organisms/SavedSearchVolumeCallout/SavedSearchVolumeCallout';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { ContactSearchForm } from 'app/hooks/search/useContactSearchCache';
import { OrganizationUserCurrentOrg, OrganizationUserCurrentUser } from 'types/__generated__/GovlyApi';
import { hasDataErrors, isFetchBaseQueryError } from 'api/utils';

type FormValues = {
  name: string;
  setAsDefault: boolean;
  follows: {
    id?: string;
    organizationUser?: OrganizationUserCurrentUser;
    organizationUserId?: string;
    notifications: string;
    state: string;
  }[];
  query: {
    query?: string;
  };
};

type SavedSearchCreateFormProps = {
  templateSavedSearchId?: string;
  searchableType: 'Contact' | 'USASpendingAward';
  onCancel: () => void;
  handleClose: () => void;
};

export const SavedSearchCreateForm = ({
  templateSavedSearchId,
  searchableType,
  onCancel,
  handleClose,
  ...rest
}: SavedSearchCreateFormProps) => {
  const { values: searchFormValues, setValues } = useFormikContext<AwardSearchForm | ContactSearchForm>();
  const { query, filters } = searchFormValues;
  const { trackEvent } = useEventTracking();
  const { templateSavedSearch, isLoading: savedSearchesLoading } = useGetSavedSearchesQuery(
    { active: true, searchableType },
    {
      selectFromResult: ({ data, isLoading }) => ({
        templateSavedSearch: data?.find(({ id }) => id === templateSavedSearchId),
        isLoading: isLoading
      })
    }
  );

  const { data = [], isLoading: organizationUsersLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const organizationUsers = data as OrganizationUserCurrentOrg[];

  const { data: currentUser } = useGetCurrentUserQuery();
  const [createSavedSearch, { isLoading: isCreating }] = useCreateSavedSearchMutation();

  const form: FormikConfig<FormValues> = {
    onSubmit: async (createValues, { setErrors }) => {
      try {
        const { follows = [], ...rest } = createValues;
        const followsAttributes = follows.map(follow => ({
          id: follow.id,
          state: follow.state,
          notifications: follow.notifications,
          organizationUserId: follow.organizationUserId
        }));

        // @ts-expect-error - TODO createSavedSearch is not typed correctly in the API
        const createdSavedSearch = await createSavedSearch({ ...rest, followsAttributes, searchableType }).unwrap();
        const { query, setAsDefault, name } = createValues;

        trackEvent({
          object: `${searchableType.toLowerCase()}_saved_search` as Lowercase<string>,
          action: 'created',
          properties: {
            ...query,
            setAsDefault,
            name,
            follows: follows?.map(f => f?.organizationUser?.email)
          }
        });

        const savedSearchState = {
          query: searchFormValues.query,
          filters: searchFormValues.filters,
          meta: {
            ...searchFormValues.meta,
            savedSearchId: createdSavedSearch.id
          }
        };

        // @ts-expect-error - TODO setValues.filters types mismatch
        setValues(savedSearchState);

        successToast();
        handleClose();
      } catch (e) {
        if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
          const { data } = e;
          if (data.errors) setErrors(data.errors);
        }
      }
    },
    initialValues: {
      query: { query, ...filters },
      name: templateSavedSearch?.name ? `${templateSavedSearch.name} (copy)` : '',
      setAsDefault: false,
      follows: [
        {
          organizationUser: currentUser,
          organizationUserId: currentUser?.id,
          notifications: 'user_setting',
          state: 'following'
        }
      ]
    },
    validationSchema: Yup.object({
      name: Yup.string().max(80, 'Must be 80 characters or less').required('Please give a name to your saved search')
    })
  };

  if (savedSearchesLoading || organizationUsersLoading) {
    return <Loading />;
  }

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card>
            <CardBody>
              <SavedSearchVolumeCallout />
              <TextInput
                name="name"
                label="Saved Search Name"
                inputProps={{ placeholder: 'My Saved Search', large: true }}
                data-test="saved-search-name-input"
              />
              <FollowerSelectInput
                name="follows"
                label="Followers"
                defaultButtonText="Add a Teammate"
                organizationUsers={organizationUsers}
                disabled={isCreating}
                hideFollowSettings
              />
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text="Save"
                intent="primary"
                loading={isCreating}
                data-test="saved-search-form-save"
              />
              {onCancel && <Button text="Cancel" disabled={isCreating} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};
