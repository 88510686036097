import { useSearchParams } from 'react-router-dom';

export const RESULTS_VIEW_PARAM = 'resultsView';

type ResultView = 'cards' | 'table';

export const useResultsView = (): ResultView => {
  const [searchParams] = useSearchParams();
  return (searchParams.get(RESULTS_VIEW_PARAM) as ResultView) || 'cards';
};
