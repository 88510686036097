import React from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';

type NewTabTooltipProps = {
  children: React.ReactNode;
  title: string;
};

export const NewTabTooltip = ({ children, title }: NewTabTooltipProps) => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const tooltip = (
    <>
      <p>{title}</p>
      <span>
        <Tag>{isMac ? '⌘' : 'CTRL'} + click</Tag> to open in a new tab
      </span>
    </>
  );

  return <Tooltip content={tooltip}>{children}</Tooltip>;
};
