import React from 'react';

import { useOppSearchResults, useSearchMutation } from 'api/oppsApi';
import { SearchQueryInput } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { OppSearchSavedSearchSelectInput } from 'app/organisms/OppSearchSavedSearchSelectInput/OppSearchSavedSearchSelectInput';
import { OppSavedSearchControls } from 'app/organisms/OppSavedSearchControls/OppSavedSearchControls';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { useField } from 'formik';
import {
  RadioGroupInput,
  RadioGroupInputProps,
  RadioGroupOptionProps
} from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

export const OppSearchQuery = () => {
  const [_search, { isLoading }] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  // if the search type changes to semantic, default to _relevance_ sort

  const [_semanticField, semanticMeta] = useField('semantic');
  const [_sortField, _sortMeta, sortHelper] = useField('filters.sort');

  const hasSemanticSearch = useFeatureFlag('semantic-search');
  const isSemanticSearch = hasSemanticSearch && semanticMeta.value;

  // default search to relevance when semantic search
  const onSemanticChange: RadioGroupInputProps['beforeChange'] = value => {
    if (value === 'true') {
      sortHelper.setValue('relevance');
    } else {
      sortHelper.setValue('posted');
    }
  };

  const {
    data: { results = [] } = {},
    isLoading: isLoadingResults,
    isUninitialized: resultsUninitialized
  } = useOppSearchResults();
  const resultsLoadedSuccess = !isLoadingResults && !resultsUninitialized;
  const showAssistant = useFeatureFlag('keyword-assistant');

  const semanticOptions: RadioGroupOptionProps[] = [
    {
      value: 'false',
      labelElement: (
        <LabelSpan label="Keyword Search" tooltipContent="Search for exact keyword matches, with boolean operators." />
      )
    },
    {
      value: 'true',
      labelElement: (
        <LabelSpan
          label="Semantic Search"
          tooltipContent="Search for opportunities that are similar in meaning to your query."
        />
      )
    }
  ];

  const placeholder = isSemanticSearch
    ? 'iPads or similar tablets, accessories and maintenance contracts'
    : 'servers AND (oracle OR dell) NOT(printers OR monitors)';

  return (
    <div className="grid grid-cols-1 gap-4">
      {!isSemanticSearch && (
        <div className="grid grid-cols-1 sm:grid-cols-2 mb-2">
          <OppSearchSavedSearchSelectInput />
          <OppSavedSearchControls large />
        </div>
      )}
      <div className="-mt-2">
        {hasSemanticSearch && (
          <RadioGroupInput
            name="semantic"
            submitOnChange
            inline
            options={semanticOptions}
            beforeChange={onSemanticChange}
          />
        )}
        <SearchQueryInput
          name="query"
          className="m-0 pt-4 border-none"
          loading={isLoading}
          placeholder={placeholder}
          isSemantic={isSemanticSearch}
          showAssistant={showAssistant}
          hasNoResults={resultsLoadedSuccess && !results.length}
        />
      </div>
    </div>
  );
};
