import React from 'react';
import { Tag } from '@blueprintjs/core';
import { useOppSearchAnalyticsUpcomingExpirationStore } from 'app/organisms/OppSearchAnalyticsUpcomingExpiration/useOppSearchAnalyticsUpcomingExpirationStore';

export const OppSearchAnalyticsUpcomingExpirationDrawerTitle = () => {
  const name = useOppSearchAnalyticsUpcomingExpirationStore(s => s.lastClickedPoint?.name);

  return (
    <>
      <span>Upcoming Expirations</span>

      {name ? <Tag minimal>{name}</Tag> : null}
    </>
  );
};
