import React, { useState } from 'react';
import { Button, Alert, Dialog } from '@blueprintjs/core';

import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from 'types/__generated__/GovlyApi';
import { CardBody } from 'app/atoms/Card/Card';
import { errorToast } from 'app/lib/toaster';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useDeleteOrganizationTeamMutation } from 'api/organizationTeamsApi';
import { TeamForm } from 'app/organisms/TeamForm/TeamForm';
import { TeamAvatarWithName } from 'app/molecules/TeamAvatarWithName/TeamAvatarWithName';

type TeamRowProps = {
  team: OrganizationTeam | OrganizationTeamWithOrganizationUsers;
};

export const TeamRow = ({ team }: TeamRowProps) => {
  const [isDeletingAlert, setIsDeletingAlert] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteTeam, { isLoading: isDeleting }] = useDeleteOrganizationTeamMutation();

  const authorized = useAuthorized({ role: 'admin' });
  return (
    <>
      <CardBody>
        <div className="flex flex-wrap items-center justify-between">
          <div>
            <TeamAvatarWithName team={team} />
          </div>
          {authorized && (
            <div className="flex gap-2">
              <Button onClick={() => setIsEditing(true)}>Edit</Button>
              <Button onClick={() => setIsDeletingAlert(true)} intent="danger" outlined>
                Delete
              </Button>
            </div>
          )}
        </div>
      </CardBody>
      {authorized && (
        <>
          <Dialog className="p-0" lazy isOpen={isEditing} onClose={() => setIsEditing(false)}>
            <TeamForm team={team} onCancel={() => setIsEditing(false)} />
          </Dialog>

          <Alert
            isOpen={isDeletingAlert}
            icon="trash"
            confirmButtonText="Delete"
            cancelButtonText="Cancel"
            intent="danger"
            onClose={() => setIsDeletingAlert(false)}
            onConfirm={async () => {
              try {
                await deleteTeam({ id: team.id || '' }).unwrap();
              } catch (e) {
                errorToast(e);
              } finally {
                setIsDeletingAlert(false);
              }
            }}
            loading={isDeleting}
          >
            <p>Are you sure? Recreating a team will require adding members again.</p>
          </Alert>
        </>
      )}
    </>
  );
};
