import React, { useState, createContext, useContext } from 'react';
import { useEffectOnce } from 'app/hooks/useEffectOnce';
import { useCreateThreadMutation } from 'api/llmThreadsApi';
import { useAssistant } from './useAssistant';
import { Message } from './useAssistantStore';

export type KeywordAssistantType = 'Llm::Assistants::KeywordQueryAssistant';

type AssistantProps = {
  type: KeywordAssistantType;
  placeholder?: string;
  welcomeMessage?: string;
  setWelcomeMessage?: (message: string) => void;
  defaultPrompts?: string[];
  children: React.ReactNode;
};

export type AssistantContextType = Omit<AssistantProps, 'type' | 'children'> & {
  messages: Message[];
  sendNewMessage: (message: string) => void;
  resetMessages: () => void;
  getNewThread: () => void;
  isLoading: boolean;
};

export const AssistantContext = createContext<AssistantContextType | undefined>(undefined);

export const useAssistantContext = () => {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error('useAssistantContext must be used within a AssistantProvider');
  }
  return context;
};

const AssistantProvider = ({
  placeholder,
  type,
  welcomeMessage,
  setWelcomeMessage,
  defaultPrompts,
  children
}: AssistantProps) => {
  const [createThread, { isLoading: threadIsLoading }] = useCreateThreadMutation();
  const [threadId, setThreadId] = useState<string>('');
  const { messages, sendNewMessage, resetMessages } = useAssistant({ threadId });

  useEffectOnce(() => {
    getNewThread();
  });

  const getNewThread = async () => {
    try {
      const result = await createThread({ assistantType: type }).unwrap();
      setThreadId(result.id);
    } catch (err) {
      console.error('Failed to create thread:', err);
    }
  };

  return (
    <AssistantContext.Provider
      value={{
        placeholder,
        getNewThread,
        messages,
        sendNewMessage,
        resetMessages,
        welcomeMessage,
        setWelcomeMessage,
        defaultPrompts,
        isLoading: threadIsLoading
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
};

export { AssistantProvider };
