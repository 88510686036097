import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';

import { useCreatePartnerFeedMutation, useDeletePartnerFeedMutation } from 'api/partnerFeedsApi';
import { errorToast, successToast } from 'app/lib/toaster';

type PartnerFeedTableCheckboxProps = {
  id?: string;
  partnershipId: string;
  organizationId: string;
  providerFeedPublicId: string;
} & Partial<React.HTMLAttributes<HTMLDivElement>>;

export const PartnerFeedTableCheckbox = ({
  id,
  partnershipId,
  organizationId,
  providerFeedPublicId,
  ...rest
}: PartnerFeedTableCheckboxProps) => {
  const [createPartnerFeed, { isLoading: isCreating }] = useCreatePartnerFeedMutation();
  const [deletePartnerFeed, { isLoading: isDeleting }] = useDeletePartnerFeedMutation();

  const handleSubmit = async () => {
    try {
      if (id) {
        await deletePartnerFeed({ id: id }).unwrap();
      } else {
        await createPartnerFeed({
          organizationId,
          partnershipId,
          providerFeedPublicId
        }).unwrap();
      }
      successToast();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <Checkbox
      checked={!!id}
      disabled={isCreating || isDeleting}
      labelElement={<span className={cn('inline-block text-gray-600 dark:text-gray-400')}>Share with partner?</span>}
      className="m-0"
      onChange={handleSubmit}
      {...rest}
    />
  );
};
