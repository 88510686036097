import React from 'react';
import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from 'types/__generated__/GovlyApi';
import { TagProps } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import { TeamTag } from '../TeamTag/TeamTag';

export type TeamTagListProps = {
  teams: (OrganizationTeam | OrganizationTeamWithOrganizationUsers)[];
} & TagProps;

export const TeamTagList = ({ teams, className, ...tagProps }: TeamTagListProps) => {
  return (
    <div className="gap flex items-center">
      {teams.map(t => (
        <TeamTag key={t.id} team={t} className={cn(className, 'mr-2')} {...tagProps} />
      ))}
    </div>
  );
};
