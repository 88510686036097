import React from 'react';
import { Button } from '@blueprintjs/core';
import { Popover, Menu, MenuItem } from '@blueprintjs/core';
import {
  SLICES,
  sliceLabelSelector,
  useAwardSearchAnalyticsHistogramStore
} from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsHistogramStore';

export const AwardSearchAnalyticsHistogramToolbar = () => {
  const { sliceType, setSliceType } = useAwardSearchAnalyticsHistogramStore();

  const sliceLabel = useAwardSearchAnalyticsHistogramStore(sliceLabelSelector);

  return (
    <div className="flex items-center gap-2">
      <Popover
        placement="bottom-start"
        minimal
        modifiers={{ offset: { enabled: true } }}
        content={
          <Menu>
            {SLICES.map(({ key, label }) => (
              <MenuItem key={String(key)} active={sliceType === key} onClick={() => setSliceType(key)} text={label} />
            ))}
          </Menu>
        }
      >
        <Button rightIcon="chevron-down">{sliceLabel ?? 'View'}</Button>
      </Popover>
    </div>
  );
};
