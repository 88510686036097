import React from 'react';

import { PartnershipRequestsTableAdmin } from './PartnershipRequestsTableAdmin';
import { PartnershipRequestsTableUser } from './PartnershipRequestsTableUser';

export const PartnershipRequestsTable = ({ isAdmin }: { isAdmin?: boolean }) => {
  if (isAdmin) {
    return <PartnershipRequestsTableAdmin />;
  } else {
    return <PartnershipRequestsTableUser />;
  }
};
