import React, { useMemo, useState } from 'react';
import debounce from 'just-debounce-it';
import { useFuzzyTypeahead } from 'app/hooks/useFuzzyTypeahead';
import { InputMultiSelectHook, GetHookItem } from '../InputMultiSelect/utils';
import { InputMultiSelect, InputMultiSelectProps } from '../InputMultiSelect/InputMultiSelect';
import { QueryInputMultiSelectProps } from './QueryInputMultiSelect';

/**
 * This component is a wrapper around the InputMultiSelect component.
 * It allows you to use an async typeahead hook to fetch items based on the query
 *
 * It differs from the QueryInputMultiSelect component in that it retriggers the hook on every query change
 */
export function SearchQueryInputMultiSelect<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<InputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
>({
  useHook,
  hookArgs,
  getItemsBasedProps,
  useTypeaheadProps,
  ...props
}: QueryInputMultiSelectProps<Hook, Item, ItemsBasedProps>) {
  const [query, setQuery] = useState<string>('');

  const args = (hookArgs ?? [])[0] ?? {};
  const { data = [], isLoading } = useHook({ query, ...args });
  // TODO Fuse.js won't be needed if we can get ES to sort data better cc @lygaret
  const filteredResults = useFuzzyTypeahead({ data: data as Item[], query, ...useTypeaheadProps });

  const debouncedSetQuery = useMemo(() => debounce(setQuery, 300), []);

  return (
    <InputMultiSelect
      {...getItemsBasedProps(data as Item[])}
      {...props}
      loading={isLoading}
      items={filteredResults}
      onQueryChange={debouncedSetQuery}
      query={query}
    />
  );
}
