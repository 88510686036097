import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { FocusStyleManager, BlueprintProvider } from '@blueprintjs/core';

import 'app/lib/highcharts';
import { reportWebVitals } from 'app/lib/reportWebVitals';
import { ApplicationErrorBoundary } from 'app/atoms/ErrorBoundary/ApplicationErrorBoundary';
import { store } from 'app/store';
import { App } from 'app/App';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

import 'trix';
import '@rails/actiontext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

FocusStyleManager.onlyShowFocusOnTabs();

const POSTHOG_OPTIONS: Partial<PostHogConfig> = { api_host: 'https://us.posthog.com' };

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app');
  if (!container) {
    throw new Error('Could not find the app container');
  }
  const root = createRoot(container);
  // We reuse the posthog client initialized in _posthog.html.erb
  const posthogProps =
    'posthog' in window
      ? { client: window.posthog }
      : { apiKey: process.env.POSTHOG_PROJECT_TOKEN, options: POSTHOG_OPTIONS };

  root.render(
    <React.StrictMode>
      <PostHogProvider {...posthogProps}>
        <Provider store={store}>
          <ApplicationErrorBoundary action="app">
            <BlueprintProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </BlueprintProvider>
          </ApplicationErrorBoundary>
        </Provider>
      </PostHogProvider>
    </React.StrictMode>
  );
});

if (process.env.RAILS_ENV === 'development') {
  reportWebVitals(console.debug);
}
