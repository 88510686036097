import React, { useState } from 'react';
import { FormGroup, Button, Tooltip } from '@blueprintjs/core';
import truncate from 'lodash-es/truncate';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

type SecretKeyProps = {
  secret: string;
  label: string;
  description?: string;
};

export const SecretKey = ({ secret, label, description }: SecretKeyProps) => {
  const [copyTooltipContent, setCopyTooltipContent] = useState('Click to copy');
  const [hidden, setHidden] = useState(true);

  if (!secret) return null;

  return (
    <FormGroup label={<LabelSpan label={label} />} helperText={description}>
      <input
        className="bp5-input"
        readOnly
        type={hidden ? 'password' : 'text'}
        value={hidden ? 'passfjlksafkldsajfkldsajlkfword' : truncate(secret, { length: 20 })}
      />
      <Tooltip content={hidden ? 'Show' : 'Hide'}>
        <Button
          icon={hidden ? 'lock' : 'unlock'}
          intent="warning"
          className="ml-2"
          onClick={() => setHidden(!hidden)}
          text={hidden ? 'View' : 'Hide'}
        />
      </Tooltip>
      <Tooltip content={copyTooltipContent} onClose={() => setCopyTooltipContent('Click to copy')}>
        <Button
          icon="clipboard"
          className="ml-2"
          onClick={() => {
            navigator.clipboard.writeText(secret);
            setCopyTooltipContent('✅ Copied!');
          }}
          text="Copy"
        />
      </Tooltip>
    </FormGroup>
  );
};
