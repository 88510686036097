import React from 'react';
import startCase from 'lodash-es/startCase';

export type Highlight = {
  [key: string]: string[];
};

const getTopHighlight = (highlights: Highlight) => {
  const f: { [key: string]: { label: string; value: string } } = {};

  Object.keys(highlights || {}).forEach(key => {
    const label = startCase(key.split('.')[0])?.split('_')?.join(' ');
    const str = highlights[key][0];
    const start = Math.max(str.indexOf('<mark>') - 500, 0);
    const end = Math.min(str.indexOf('</mark>') + 500, str.length);
    const value = `${start !== 0 ? '...' : ''}${str.substring(start, end)}${end !== str.length ? '...' : ''}`;

    f[label] = { label, value };
  });

  return f['Title'] || f['Description'] || f['Solicitation Number'] || f['Source'] || f[Object.keys(f)[0]];
};

export const AwardSearchResultHighlight = ({ highlights, ...rest }: { highlights: Highlight }) => {
  const highlight = getTopHighlight(highlights);
  if (!highlight) return null;

  return (
    <div {...rest}>
      <blockquote key={highlight.label} className="bp5-blockquote mt-2">
        <small>
          <em>
            <strong>{`Matched in ${highlight.label}:`}</strong>
          </em>
          <em>
            <div dangerouslySetInnerHTML={{ __html: highlight.value }} />
          </em>
        </small>
      </blockquote>
    </div>
  );
};
