import React from 'react';
import { Tag, TagProps } from '@blueprintjs/core';
import orderBy from 'lodash-es/orderBy';

import { pluralize } from 'app/lib/strings';

type OppSearchEntityExtractTagListProps = {
  entities: { id: number | string; name: string; frequency?: number; attachmentsFrequency?: number }[];
  pluralizedLabels: [string, string];
  tagProps?: TagProps;
};

export const OppSearchEntityExtractTagList = ({
  entities,
  pluralizedLabels,
  tagProps = {}
}: OppSearchEntityExtractTagListProps) => {
  if (!entities.length) return null;

  const list = orderBy(entities, ['frequency', 'attachmentsFrequency'], ['desc', 'desc']).slice(0, 11);

  return (
    <>
      {list.map(({ id, name, frequency }, i) => (
        <Tag key={`${id}-${i}`} minimal intent={frequency && frequency > 0 ? 'success' : 'warning'} {...tagProps}>
          {`${name} ${frequency && frequency > 0 ? frequency : ''}`}
        </Tag>
      ))}
      {entities.length > 10 && (
        <Tag minimal intent="warning" {...tagProps}>
          <strong>{`${entities.length - 10} `}</strong>
          {`additional ${pluralize(pluralizedLabels[0], pluralizedLabels[1], entities.length - 10)} not listed...`}
        </Tag>
      )}
    </>
  );
};
