import { TrawlerConfig } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['TrawlerConfigList'] });

type GetTrawlerConfigs = {
  params: {
    page?: number;
    per?: number;
    sort?: string;
    sortDirection?: string;
    filter?: string;
  };
  result: {
    results: TrawlerConfig[];
    meta: {
      total: number;
    };
  };
};

type GetTrawlerConfig = {
  params: { id: string };
  result: TrawlerConfig;
};

type UpdateTrawlerConfig = {
  params: Pick<TrawlerConfig, 'id' | 'job' | 'active'> & { input: string };
  result: void;
};

type BulkUpdateTrawerConfigs = {
  params: { ids?: string[]; job: string; tier: string; active: boolean };
  result: void;
};

type CreateTrawlerConfig = {
  params: Pick<TrawlerConfig, 'job' | 'active'> & { input: string };
  result: void;
};

type DeleteTrawlerConfig = {
  params: { id: string };
  result: void;
};

type RunTrawlerConfig = {
  params: { id: string };
  result: void;
};
export const sysAdminTrawlerConfigsApi = api.injectEndpoints({
  endpoints: build => ({
    getTrawlerConfigs: build.query<GetTrawlerConfigs['result'], GetTrawlerConfigs['params']>({
      query: params => ({
        url: '/v2/sys_admin_trawler_configs',
        params
      }),
      providesTags: ['TrawlerConfigList']
    }),
    getTrawlerConfig: build.query<GetTrawlerConfig['result'], GetTrawlerConfig['params']>({
      query: ({ id }) => ({ url: `/v2/sys_admin_trawler_configs/${id}` })
    }),
    updateTrawlerConfig: build.mutation<UpdateTrawlerConfig['result'], UpdateTrawlerConfig['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/sys_admin_trawler_configs/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['TrawlerConfigList']
    }),
    bulkUpdateTrawlerConfigs: build.mutation<BulkUpdateTrawerConfigs['result'], BulkUpdateTrawerConfigs['params']>({
      query: body => ({
        url: '/v2/sys_admin_trawler_configs/bulk_update',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['TrawlerConfigList']
    }),
    createTrawlerConfig: build.mutation<CreateTrawlerConfig['result'], CreateTrawlerConfig['params']>({
      query: body => ({
        url: `/v2/sys_admin_trawler_configs`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['TrawlerConfigList']
    }),
    deleteTrawlerConfig: build.mutation<DeleteTrawlerConfig['result'], DeleteTrawlerConfig['params']>({
      query: ({ id }) => ({
        url: `/v2/sys_admin_trawler_configs/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TrawlerConfigList']
    }),
    runTrawlerConfig: build.mutation<RunTrawlerConfig['result'], RunTrawlerConfig['params']>({
      query: ({ id }) => ({
        url: `/v2/sys_admin_trawler_configs/${id}/run`,
        method: 'POST'
      })
    })
  })
});

export const {
  useGetTrawlerConfigsQuery,
  useGetTrawlerConfigQuery,
  useUpdateTrawlerConfigMutation,
  useBulkUpdateTrawlerConfigsMutation,
  useCreateTrawlerConfigMutation,
  useDeleteTrawlerConfigMutation,
  useRunTrawlerConfigMutation
} = sysAdminTrawlerConfigsApi;
