import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { successToast } from 'app/lib/toaster';
import { useToggleDeactivatedFeedsMutation } from 'api/deactivatedFeedsApi';

type PrivateFeedDeactivationButtonProps = {
  isDisabled?: boolean;
  activated: boolean;
  partnerFeedIds: string[];
};

export const PrivateFeedDeactivationButton = ({
  activated,
  isDisabled,
  partnerFeedIds
}: PrivateFeedDeactivationButtonProps) => {
  const [toggleDeactivatedFeed, { isLoading }] = useToggleDeactivatedFeedsMutation();

  const toggleActivated = async ({
    activated,
    partnerFeedIds
  }: Pick<PrivateFeedDeactivationButtonProps, 'activated' | 'partnerFeedIds'>) => {
    await toggleDeactivatedFeed({ partnerFeedIds: partnerFeedIds, active: !activated });
    successToast();
  };

  return (
    <Tooltip
      content={<div className="w-56">Set your preference for displaying this feed in Opportunities</div>}
      hoverOpenDelay={300}
    >
      <Button
        intent={activated ? 'success' : 'none'}
        disabled={isDisabled}
        loading={isLoading}
        onClick={() => {
          toggleActivated({ activated, partnerFeedIds });
        }}
      >
        {activated ? 'Feed activated' : 'Activate feed'}
      </Button>
    </Tooltip>
  );
};
