import { useGetQuotesForWorkspaceQuery } from 'api/quotesApi';
import {
  useOppWorkspaceStore,
  useOppWorkspaceStoreRef
} from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { useEffect } from 'react';

export const useWorkspaceQuotesWithPolling = ({ workspaceId }: { workspaceId: string }) => {
  const storeRef = useOppWorkspaceStoreRef();
  const shouldPoll = useOppWorkspaceStore(state => state.shouldPollQuotes);

  const query = useGetQuotesForWorkspaceQuery({ workspaceId }, { pollingInterval: 5000, skip: !shouldPoll });
  const { data: quotes } = query;

  // turn off polling once all quotes are completed
  useEffect(() => {
    if (query.isFetching || !quotes) return;

    const noQuotes = quotes.length === 0;
    const notProcessing = quotes.every(quote => quote.textExtractStatus !== 'processing');

    if (noQuotes || notProcessing) storeRef.setState({ shouldPollQuotes: false });
  }, [quotes, query.isFetching, storeRef]);

  return query;
};
