import { TagTypeahead } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetTags = {
  params: {
    context: string;
  };
  result: TagTypeahead[];
};

export const tagsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getTags: build.query<GetTags['result'], GetTags['params']>({
      query: params => ({
        url: '/v2/tags',
        params
      })
    })
  })
});

export const { useGetTagsQuery } = tagsApi;
