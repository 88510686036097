import React, { useState } from 'react';
import { useDeletePartnerPortalLinkMutation } from 'api/partnerPortalLinksApi';
import { Alert, Button } from '@blueprintjs/core';
import { formErrorToast } from 'app/lib/toaster';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { PartnerPortalLink as PartnerPortalLinkType } from 'types/__generated__/GovlyApi';

type PartnerPortalLinkProps = {
  partnerPortalLink: PartnerPortalLinkType;
  onClickEdit: (partnerPortalLink: PartnerPortalLinkType) => void;
};

export const PartnerPortalLink = ({ partnerPortalLink, onClickEdit }: PartnerPortalLinkProps) => {
  const isAdmin = useAuthorized({ role: 'admin' });
  const { id, label, url, description } = partnerPortalLink;
  const [deleteMutation, { isLoading: isDeleting }] = useDeletePartnerPortalLinkMutation();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isDeletingAlert, setIsDeletingAlert] = useState(false);

  const [hasOverflow, setHasOverflow] = useState(false);

  return (
    <>
      <div className="flex flex-col p-6 gap-2 border-b border-b-gray-300">
        <div className="flex items-center justify-between">
          <a className="font-medium text-blue-500" href={url} target="_blank" rel="noopener noreferrer">
            {label}
          </a>

          {isAdmin ? (
            <div className="flex gap-2">
              <Button onClick={() => onClickEdit(partnerPortalLink)} disabled={isDeleting}>
                Edit
              </Button>
              <Button onClick={() => setIsDeletingAlert(true)} intent="danger" outlined loading={isDeleting}>
                Delete
              </Button>
            </div>
          ) : null}
        </div>

        <div className="relative w-3/4">
          <p
            ref={node => {
              if (node) {
                setHasOverflow(node.scrollHeight > node.clientHeight);
              }
            }}
            className={`mb-0 ${showFullDescription ? '' : 'line-clamp-2'}`}
          >
            {description}
          </p>

          {(hasOverflow || showFullDescription) && (
            <button
              className="absolute bottom-0 right-0 underline text-blue-500 translate-x-full"
              onClick={() => setShowFullDescription(x => !x)}
            >
              {showFullDescription ? 'show less' : 'show more'}
            </button>
          )}
        </div>
      </div>

      <Alert
        isOpen={isDeletingAlert}
        icon="trash"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        intent="danger"
        onClose={() => setIsDeletingAlert(false)}
        onConfirm={() => {
          deleteMutation({ id })
            .catch(formErrorToast)
            .finally(() => setIsDeletingAlert(false));
        }}
        loading={isDeleting}
      >
        <p>Are you sure? Deleting a partner portal link will remove it for all users.</p>
      </Alert>
    </>
  );
};
