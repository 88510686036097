import React, { useState, useMemo } from 'react';
import {
  AnchorButton,
  NonIdealState,
  Dialog,
  Tag,
  Popover,
  Menu,
  MenuItem,
  Button,
  Drawer,
  Intent
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { PaginationState, SortingState, createColumnHelper } from '@tanstack/react-table';

import { useGetTrawlerConfigsQuery, useRunTrawlerConfigMutation } from 'api/sysAdminTrawlerConfigsApi';
import { TrawlerConfigForm } from 'app/organisms/TrawlerConfigForm/TrawlerConfigForm';
import { successToast } from 'app/lib/toaster';
import { JsonViewer } from 'app/molecules/JsonViewer/JsonViewer';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { TrawlerConfig } from 'types/__generated__/GovlyApi';
import { SLEDDashboardTrawlerRuns } from 'app/organisms/SLEDDashboardTrawlerRuns/SLEDDashboardTrawlerRuns';
import { GovlyTableFilterForm } from 'app/molecules/GovlyTable/GovlyTableFilterForm';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<TrawlerConfig>();

export const SLEDDashboardTrawlerConfigs = () => {
  const [viewing, setViewing] = useState<TrawlerConfig>();
  const [action, setAction] = useState<`${'new' | 'edit' | 'delete'}:${string}`>();
  const [filter, setFilter] = useState<string>('');
  const [showRuns, setShowRuns] = useState<TrawlerConfig>();
  const [runTrawlerConfigMutation] = useRunTrawlerConfigMutation();
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 50,
    pageIndex: 0
  });

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'lastRunCompletedAt',
      desc: true
    }
  ]);
  const [sort] = sorting.map(({ id, desc }: { id: string; desc: boolean }) => ({
    sort: id,
    sortDirection: desc ? 'desc' : 'asc'
  }));

  const {
    data: { results: trawlerConfigs, meta } = {},
    isLoading,
    isError
  } = useGetTrawlerConfigsQuery({
    page: pagination.pageIndex + 1,
    per: pagination.pageSize,
    ...sort,
    filter
  });

  const onCancel = () => setAction(undefined);

  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: 'ID / Trawler',
        cell: e => {
          const name = e.row.original.input?.contract?.name;
          return (
            <div>
              <div>{e.row.original.id}</div>
              <div>{name}</div>
            </div>
          );
        }
      }),
      columnHelper.accessor('input.automationId', { header: 'Automation ID', enableSorting: false }),
      columnHelper.accessor('active', {
        header: 'Active',
        cell: e => {
          return (
            <Tag intent={e.row.original.active ? 'success' : 'danger'} minimal>
              {e.row.original.active ? 'Yes' : 'No'}
            </Tag>
          );
        }
      }),
      columnHelper.accessor('tier', {
        header: 'Tier'
      }),
      columnHelper.accessor('lastRunCompletedAt', {
        header: 'Last Run At',
        cell: e => {
          if (!e.row.original.lastRunCompletedAt) return 'Not run yet';

          const date = DateTime.fromISO(e.row.original.lastRunCompletedAt);
          return date.toLocaleString(DateTime.DATETIME_SHORT);
        },
        enableColumnFilter: false
      }),
      columnHelper.accessor('consecutiveEmptyRuns', {
        header: 'Consecutive Empty Runs',
        enableColumnFilter: false,
        cell: e => {
          let intent: Intent = 'success';
          if (e.row.original.consecutiveEmptyRuns > 6) intent = 'warning';
          if (e.row.original.consecutiveEmptyRuns > 18) intent = 'danger';
          return <Tag intent={intent}>{e.row.original.consecutiveEmptyRuns}</Tag>;
        }
      }),
      columnHelper.accessor('id', {
        header: 'Actions',
        enableColumnFilter: false,
        enableSorting: false,
        cell: e => {
          return (
            <Popover
              interactionKind="click"
              className="max-w-md"
              content={
                <Menu key="menu">
                  <MenuItem icon="eye-open" text="View Details" onClick={() => setViewing(e.row.original)} />
                  <MenuItem icon="list" text="View Runs" onClick={() => setShowRuns(e.row.original)} />
                  <MenuItem
                    icon="repeat"
                    text="Run"
                    onClick={async () => {
                      await runTrawlerConfigMutation({ id: e.row.original.id });
                      successToast('Trawler Config is running. Refresh the trawler runs page to see the results.');
                    }}
                  />
                  {/* Delegated to Airtable when airtable_id is present */}
                  {!e.row.original.airtableId && (
                    <MenuItem
                      icon="edit"
                      text="Edit Trawler Config"
                      onClick={() => setAction(`edit:${e.row.original.id}`)}
                    />
                  )}
                  <MenuItem
                    icon="ban-circle"
                    text="Delete Trawler Config"
                    onClick={() => setAction(`delete:${e.row.original.id}`)}
                  />
                </Menu>
              }
            >
              <AnchorButton icon="more" disabled={isLoading} />
            </Popover>
          );
        }
      })
    ],
    [isLoading, runTrawlerConfigMutation]
  );

  if (isError) {
    return (
      <NonIdealState
        icon="warning-sign"
        title="There was an error loading this data."
        description="Please refresh the page to try again."
      />
    );
  }

  return (
    <>
      <GovlyTable
        id="trawler_configs_table"
        title={
          <GovlyTableTitle title="Trawler Configs">
            <GovlyTableFilterForm setFilter={setFilter} filter={filter} />
          </GovlyTableTitle>
        }
        rightElement={
          <GovlyTableToolbar>
            <Button intent="success" onClick={() => setAction('new:new')}>
              Create Trawler Config(s)
            </Button>
            <Dialog isOpen={!!action} onClose={onCancel} className="p-0" lazy>
              {action && (
                <TrawlerConfigForm id={action.split(':')?.[1]} onCancel={onCancel} action={action?.split(':')?.[0]} />
              )}
            </Dialog>
          </GovlyTableToolbar>
        }
        isLoading={isLoading}
        columns={columns}
        data={trawlerConfigs ?? []}
        paginationSteps={[20, 50, 100]}
        initialState={{
          pagination: {
            pageSize: pagination.pageSize
          }
        }}
        manualFiltering
        manualSorting
        manualPagination
        enableColumnFilters={false}
        state={{ sorting, pagination }}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        rowCount={meta?.total}
      />

      <Drawer
        position="bottom"
        isOpen={!!showRuns}
        onClose={() => setShowRuns(undefined)}
        title={`${showRuns?.input?.contract?.name} runs`}
      >
        <div className="h-full overflow-y-scroll">
          <SLEDDashboardTrawlerRuns trawlerConfigId={showRuns ? showRuns.id : ''} />
        </div>
      </Drawer>
      <Dialog className="bg-transparent p-0" isOpen={!!viewing} onClose={() => setViewing(undefined)}>
        <div className="prose prose-sm">
          <pre className="min-w-fit">
            <JsonViewer json={viewing as object} />
          </pre>
        </div>
      </Dialog>
    </>
  );
};
