import React from 'react';
import Markdown from 'react-markdown';

import { Callout, EntityTitle, Icon, Tag } from '@blueprintjs/core';
import { CardList, CardListItem, CardListItemProps, CardListProps } from 'app/molecules/CardList/CardList';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { useGetOppQuery } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { ScheduledDateTag } from 'app/molecules/ScheduledDateTag/ScheduledDateTag';
import { ImageWithFallback } from 'app/atoms/ImageWithFallback/ImageWithFallback';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { OppDetailsSources } from 'app/organisms/OppDetailsSources/OppDetailsSources';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { OppLineItemsTable, LineItem } from 'app/organisms/OppLineItemsTable/OppLineItemsTable';
import { asCurrency } from 'app/lib/numbers';
import { GOV_DOMAIN } from 'app/lib/regex';
import { OppSearchResultTags } from 'app/organisms/OppSearchResultTags/OppSearchResultTags';
import { OppDetailsDescriptionExpander } from 'app/organisms/OppDetailsDescription/OppDetailsDescription';

type SourceData = {
  contacts?: {
    email?: string;
  }[];
  buyer?: {
    hierarchy: string[];
  };
};

type SummaryData = {
  title: string;
  summary: string;
  primaryOems: string[];
  primaryCompetitors: string[];
  competingProducts: string[];
  estimatedContractValue: string[];
  productLineItems: LineItem[];
  servicesLineItems: LineItem[];
};

interface VendorWithWebsite {
  name: string;
  website: number;
}

type Vendor = VendorWithWebsite | string;

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: false,
  defaultIsOpen: false,
  titleRenderer: props => <CardHeadingSmall {...props} />,
  className: 'space-y-2 pt-0'
};

export const OppDetailsCardList = ({ id, children }: { id: string; children?: CardListProps['children'] }) => {
  const { data: opp, isLoading, isError } = useGetOppQuery({ id });

  const analysis = (opp?.llmFullSummary || {}) as unknown as SummaryData;

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !opp) {
    return <NotAuthorized />;
  }

  const { isForecasted, displayName, respondBy, cancelledAt, awardedAt, title, oppSources, latestData } = opp;

  const sourceData = (latestData || {}) as unknown as SourceData;

  const domains =
    sourceData.contacts?.map(contact => contact.email?.split('@')[1]).filter(d => d && GOV_DOMAIN.test(d)) ?? [];
  const buyer = sourceData.buyer ?? opp.buyer;

  return (
    <CardList
      title={
        <div className="flex items-center gap-2">
          <Tag>{displayName}</Tag>
          {isForecasted ? undefined : (
            <ScheduledDateTag respondBy={respondBy} cancelledAt={cancelledAt} awardedAt={awardedAt} />
          )}
        </div>
      }
      wrapperClassName="[&_.bp5-section-header-left]:py-0"
      className="bt-0 [&>.bp5-tab-panel>*]:rounded-none"
    >
      <CardListItem
        title={title || analysis?.title || displayName}
        className="pt-0"
        titleRenderer={cardListItemProps.titleRenderer}
        defaultIsOpen
      >
        <div className="space-y-4">
          {buyer && buyer.hierarchy?.length && (
            <EntityTitle
              data-test="opp-details-display-name"
              title={buyer.hierarchy.join(', ')}
              subtitle={domains?.[0]}
              icon={
                <ImageWithFallback
                  src={`https://logo.clearbit.com/${domains?.[0]}`}
                  className="h-8 w-8 object-contain shrink-0 rounded-sm"
                  loading="lazy"
                >
                  <Icon icon="office" />
                </ImageWithFallback>
              }
            />
          )}

          {!!opp?.taggings?.length && (
            <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
              <OppSearchResultTags tags={opp?.taggings} />
            </div>
          )}
        </div>
      </CardListItem>

      {analysis.summary ? (
        <CardListItem
          {...cardListItemProps}
          icon={<LlmGeneratedIndicator icon="generate" />}
          title="Summary"
          subtitle={'AI generated summary that considers all attachments and amendments.'}
          defaultIsOpen
        >
          <Markdown className="prose prose-sm pt-0 pb-4">{analysis.summary}</Markdown>
          <div className="flex gap-y-4 flex-col">
            {!!analysis?.primaryOems?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="generate" />}
                  heading={CardHeadingSmall}
                  title="Likely Vendors"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.primaryOems.map((vendor: Vendor) => (
                    <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                      {typeof vendor === 'string' ? vendor : vendor.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.primaryCompetitors?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="predictive-analysis" />}
                  heading={CardHeadingSmall}
                  title="Possible Competing Vendors"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.primaryCompetitors.map((vendor: Vendor) => (
                    <Tag key={typeof vendor === 'string' ? vendor : vendor.name} minimal intent="primary">
                      {typeof vendor === 'string' ? vendor : vendor.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.competingProducts?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle
                  icon={<LlmGeneratedIndicator icon="predictive-analysis" />}
                  heading={CardHeadingSmall}
                  title="Possible Competing Products"
                />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  {analysis.competingProducts.map((product: string) => (
                    <Tag key={product} minimal>
                      {product}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {!!analysis?.estimatedContractValue?.length && (
              <div className="flex flex-col gap-y-2">
                <EntityTitle icon="predictive-analysis" heading={CardHeadingSmall} title="Estimated Contract Value" />
                <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
                  <Tag minimal intent="success">
                    {analysis.estimatedContractValue.map(value => asCurrency(value, {})).join('-')}
                  </Tag>
                </div>
              </div>
            )}
          </div>
        </CardListItem>
      ) : (
        <CardListItem>
          <Callout intent="warning" icon="warning-sign" className="text-gray-900 font-bold">
            This opportunity is in queue for an AI-generated analysis
          </Callout>
        </CardListItem>
      )}

      {opp.latestData?.description ? (
        <CardListItem
          titleRenderer={cardListItemProps.titleRenderer}
          title="Original description"
          icon="document"
          collapsible
        >
          <OppDetailsDescriptionExpander description={opp.latestData.description} />
        </CardListItem>
      ) : null}

      {!!analysis?.productLineItems?.length && (
        <OppLineItemsTable data={analysis.productLineItems} title="Product Line Items" />
      )}
      {!!analysis?.servicesLineItems?.length && (
        <OppLineItemsTable data={analysis.servicesLineItems} title="Services Line Items" />
      )}

      {!isForecasted && (
        <CardListItem
          title={
            <EntityTitle
              title="Solicitation Notices"
              heading={CardHeadingSmall}
              tags={<Tag minimal>{oppSources?.length || 0}</Tag>}
            />
          }
          icon="inbox-update"
          defaultIsOpen={false}
          collapsible
          className="p-0 space-y-4"
        >
          <OppDetailsSources id={id} />
        </CardListItem>
      )}

      {children}
    </CardList>
  );
};
