import React from 'react';
import { Formik, Form } from 'formik';
import { Dialog, Classes, Button } from '@blueprintjs/core';
import * as yup from 'yup';
import startCase from 'lodash-es/startCase';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import {
  useCreateTrTemplateMutation,
  useDeleteTrTemplateMutation,
  useUpdateTrTemplateMutation
} from 'api/souptoolsTrTemplatesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { SouptoolsTrTemplate } from 'types/__generated__/GovlyApi';
import { isFetchBaseQueryError, hasDataErrors } from 'api/utils';

type SouptoolsTrTemplateFormProps = {
  trTemplate?: SouptoolsTrTemplate;
  isOpen: boolean;
  onClose: () => void;
};

export const SouptoolsTrTemplateForm = ({ trTemplate, isOpen, onClose }: SouptoolsTrTemplateFormProps) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { trackEvent } = useEventTracking();

  const { phoneNumber, name, email, organizationName } = currentUser ?? {};
  const { id, forwardNoError = true } = trTemplate || {};

  const [deleteTrTemplate, { isLoading: isDeleting }] = useDeleteTrTemplateMutation();
  const [createTrTemplate] = useCreateTrTemplateMutation();
  const [updateTrTemplate] = useUpdateTrTemplateMutation();

  if (!isOpen) return null;

  return (
    <Dialog
      className="w-11/12 max-w-lg"
      title={id ? 'Update TR Template' : 'New TR Template'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: trTemplate?.name || name || '',
          phone: trTemplate?.phone || phoneNumber || '',
          email: trTemplate?.email || email || '',
          organization_name: trTemplate?.organizationName || organizationName || '',
          contract_number: trTemplate?.contractNumber || '',
          forwardNoError
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          try {
            if (id) {
              await updateTrTemplate({ id, ...values }).unwrap();
              trackEvent({
                object: 'souptools_tr_template',
                action: 'updated',
                properties: { templateId: id, ...values }
              });
            } else {
              await createTrTemplate(values).unwrap();
              trackEvent({
                object: 'souptools_tr_template',
                action: 'created',
                properties: { ...values }
              });
            }
            resetForm();
            successToast('Template saved.');
            onClose();
          } catch (e) {
            setSubmitting(false);
            if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
              if (typeof e.status === 'number') formErrorToast(e.status);
              const { data } = e;
              if (data.errors) setErrors(data.errors);
            }
          }
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Name is required'),
          email: yup.string().required('Email is required'),
          organization_name: yup.string().required('Organization Name is required'),
          contract_number: yup.string().required('Contract Number is required'),
          phone: yup.string().required('Phone is required')
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={Classes.DIALOG_BODY}>
              <div className="flex flex-col gap-y-4">
                {['name', 'phone', 'email', 'organization_name', 'contract_number'].map(f => (
                  <TextInput key={f} label={startCase(f)} name={f} />
                ))}
                <SwitchInput name="forwardNoError" label="Forward No Error" />
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className="flex justify-between">
                <Button
                  loading={isDeleting}
                  onClick={async () => {
                    if (!id) return;
                    await deleteTrTemplate({ id });
                    trackEvent({
                      object: 'souptools_tr_template',
                      action: 'deleted',
                      properties: { templateId: id }
                    });
                    successToast('Template deleted.');
                  }}
                  intent="danger"
                  text="Delete"
                  minimal
                  disabled={!id}
                />
                <Button type="submit" loading={isSubmitting} text="Save" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
