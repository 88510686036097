import React, { useEffect, useMemo } from 'react';
import { useGetGovernmentEntitiesQuery } from 'api/governmentEntitiesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { GovernmentEntitiesCard } from 'app/organisms/GovernmentEntities/GovernmentEntitiesCard';
import { useSearchParams } from 'react-router-dom';
import { SearchPagination } from 'app/molecules/SearchPagination/SearchPagination';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';
import { GovernmentEntitiesNotFound } from 'app/organisms/GovernmentEntities/GovernmentEntitiesNotFound';

export const GovernmentEntities = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const scrollAppBody = useScrollAppBody();

  const search = searchParams.get('search');

  const {
    data: { results: governmentEntities = [], meta: pageMeta } = {},
    isLoading,
    isFetching,
    isSuccess
  } = useGetGovernmentEntitiesQuery({ page, per: 30, ...(search ? { search } : {}) });

  useEffect(() => {
    scrollAppBody({ top: 0, behavior: 'smooth' });
  }, [page, scrollAppBody]);

  const paginationHook = useMemo(() => {
    return () => ({
      isFetching,
      isSuccess,
      total: pageMeta?.total,
      totalPages: pageMeta ? Math.ceil(pageMeta.total / pageMeta.per) : 0,
      per: pageMeta?.per,
      currentPage: pageMeta?.currentPage,
      totalRelationPrefix: '',
      onPageChange: (newPage: number) => {
        setSearchParams({ page: newPage.toString() });
      }
    });
  }, [isFetching, isSuccess, setSearchParams, pageMeta]);

  if (isLoading || isFetching) return <Loading type="card-list" />;
  if (!governmentEntities?.length) return <GovernmentEntitiesNotFound search={search} />;

  return (
    <div className="space-y-4">
      {governmentEntities.map(governmentEntity => (
        <GovernmentEntitiesCard key={governmentEntity.id} governmentEntity={governmentEntity} />
      ))}
      <SearchPagination paginationHook={paginationHook} />
    </div>
  );
};
