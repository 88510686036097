import React from 'react';
import { useParams } from 'react-router-dom';
import groupBy from 'lodash-es/groupBy';
import { NonIdealState } from '@blueprintjs/core';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { useGetResellersQuery } from 'api/resellersApi';
import { useGetChannelPartnersQuery } from 'api/channelPartnersApi';
import { useGetVendorQuery } from 'api/vendorsApi';
import { H2 } from 'app/atoms/Typography/Typography';

export const VendorProfilePartners = () => {
  const { slug = '' } = useParams();
  const { data: { id: vendorId = '', name } = {}, isLoading } = useGetVendorQuery({ id: slug }, { skip: !slug });
  const { data: resellers, isLoading: resellersLoading } = useGetResellersQuery({ vendorId }, { skip: !vendorId });
  const { data: channelPartners, isLoading: channelPartnersLoading } = useGetChannelPartnersQuery({ vendorId });
  if (isLoading) return <AppLoading />;

  if (resellersLoading || channelPartnersLoading) return <Loading />;

  if (!resellers?.length && !channelPartners?.length)
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="office"
            title={`No partners have been added for ${name}`}
            description="Claim this profile to add partners and resellers."
          />
        </CardBody>
      </Card>
    );

  const lists = [
    { name: 'Partners', tiers: groupBy(Array.from(channelPartners ?? []), 'tier') },
    { name: 'Resellers', tiers: groupBy(Array.from(resellers ?? []), 'tier') }
  ];

  return (
    <MainColumn columnSpan={12}>
      {lists.map(({ name, tiers }) =>
        Object.keys(tiers).map((tier, i) => {
          const items = tiers[tier];

          if (!items?.length) {
            return null;
          }

          return (
            <Card key={name + i} title={tier == null || tier === 'null' ? name : `${tier} ${name}`}>
              <CardBody padded={false}>
                <div className="divide grid grid-cols-2 gap-0.5 md:grid-cols-4">
                  {items.map((partner: (typeof items)[0], i) => {
                    return (
                      <div key={`partner-${i}`} className="col-span-1 flex justify-center p-4">
                        <a
                          href={`/vendors/${partner.vendor?.slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline"
                        >
                          <H2>{partner.vendor?.name}</H2>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          );
        })
      )}
    </MainColumn>
  );
};
