import React, { memo } from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { useGetContactsQuery, useContactSearchMutation } from 'api/contactsApi';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { OppSearchResultBuyer } from 'app/organisms/OppSearchResultBuyer/OppSearchResultBuyer';
import { OppSearchEntityExtractTagList } from 'app/organisms/OppSearchEntityExtractTagList/OppSearchEntityExtractTagList';
import { ContactIdTag } from 'app/organisms/ContactIdTag/ContactIdTag';
import { pluralize } from 'app/lib/strings';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { SearchResultInteractions } from 'app/organisms/SearchResultInteractions/SearchResultInteractions';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { presence } from 'app/lib/arrays';

type ContactSearchResultProps = {
  id: string;
};

export const ContactSearchResult = memo(({ id }: ContactSearchResultProps) => {
  const [_searchParams, setSearchParams] = useSearchParams();

  const [_search, { contact, resultIds }] = useContactSearchMutation({
    fixedCacheKey: 'contact-search',
    selectFromResult: ({ data: { results = [] } = {} }) => ({
      resultIds: results.map(({ id: contactId }) => contactId),
      contact: results.find(({ id: contactId }) => contactId === id)
    })
  });

  const { decoratedContact } = useGetContactsQuery(
    { ids: resultIds },
    {
      skip: !resultIds.length,
      selectFromResult: ({ data = [] }) => ({
        decoratedContact: data.find(({ id: contactId }) => contactId === id)
      })
    }
  );
  const { workspace } = decoratedContact ?? {};
  const displayableFollows = workspace?.follows?.filter(({ state }) => state !== 'unfollowed');

  const {
    email,
    name,
    oppCount,
    titles,
    phoneNumbers,
    setAsides = [],
    lastActive,
    buyers,
    locations,
    vendors,
    contractVehicles
  } = contact ?? {};

  const regions = locations
    ? [
        ...new Set(
          locations
            ?.filter(({ region, countryCode }) => region && countryCode)
            ?.map(({ region, countryCode }) => (countryCode === 'US' ? region : `${region}, ${countryCode}`))
        )
      ]
    : null;
  const places = [...new Set(locations?.map(({ place }) => place))];

  const openContact = (event: React.MouseEvent<HTMLElement>) => {
    openDrawerOrNewTab(event, `/contacts/${id}`, () => setSearchParams({ drawerType: 'contacts', drawerId: id }));
  };

  return (
    <Card>
      <CardBody className="space-y-0">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-wrap items-center justify-between gap-2">
            <NewTabTooltip title="View Contact">
              <LinkTag tag="a" onClick={openContact}>
                <ContactIdTag name={name} email={email ?? ''} />
              </LinkTag>
            </NewTabTooltip>
            <SearchResultInteractions resource={decoratedContact} />
          </div>
          <div className="flex flex-wrap items-center gap-2">
            {lastActive && <Tag icon="time" minimal>{`Last Active ${formatTime(lastActive, DATETIME_24_SHORT)}`}</Tag>}
            {!!oppCount && (
              <Tag icon="chart" minimal>{`${oppCount} ${pluralize('Opportunity', 'Opportunities', oppCount)}`}</Tag>
            )}
            {!!setAsides?.length && (
              <Tooltip content={<div className="whitespace-pre">{setAsides.map(({ name }) => name).join('\n')}</div>}>
                <div className="flex items-baseline">
                  <Tag icon="pie-chart" minimal>{`${setAsides.length} ${pluralize(
                    'Set Aside',
                    'Set Asides',
                    setAsides.length
                  )}`}</Tag>
                </div>
              </Tooltip>
            )}
            {!!titles?.length && (
              <Tooltip content={<div className="whitespace-pre">{titles.join('\n')}</div>}>
                <div className="flex items-baseline">
                  <Tag icon="briefcase" minimal>
                    {titles.length > 1 ? `${titles[0]} +${titles.length - 1} More` : titles[0]}
                  </Tag>
                </div>
              </Tooltip>
            )}
            {!!phoneNumbers?.length && (
              <Tooltip content={<div className="whitespace-pre">{phoneNumbers.join('\n')}</div>}>
                <div className="flex items-baseline">
                  <Tag icon="phone" minimal>
                    {`${phoneNumbers.length} ${pluralize('Phone Number', 'Phone Numbers', phoneNumbers.length)}`}
                  </Tag>
                </div>
              </Tooltip>
            )}
            {!!regions?.length && (
              <Tooltip content={<div className="whitespace-pre">{places.join('\n\n')}</div>}>
                <div className="flex items-baseline">
                  <Tag icon="map-marker" minimal>
                    {regions.length > 1
                      ? `${regions.length} ${pluralize('Region', 'Regions', regions.length)}`
                      : regions[0]}
                  </Tag>
                </div>
              </Tooltip>
            )}
          </div>
          {presence(decoratedContact?.labels) && (
            <div className="flex flex-wrap items-center gap-x-2 gap-y-4">
              <LabelList labels={decoratedContact?.labels ?? []} />
            </div>
          )}
          {(!!buyers?.length || !!contractVehicles?.length) && (
            <div className="flex flex-wrap items-center gap-2">
              {buyers && (
                <>
                  {buyers
                    .slice(0, 3)
                    .map(buyer =>
                      buyer.name ? (
                        <OppSearchResultBuyer
                          key={buyer.name}
                          buyerName={buyer.name}
                          buyerHierarchy={buyer.hierarchy}
                        />
                      ) : null
                    )}
                  {buyers.length > 3 && (
                    <Tag intent="success" minimal>
                      <strong>{buyers.length - 3}</strong>
                      {` additional ${pluralize('buyer', 'buyers', buyers.length - 3)} not listed...`}
                    </Tag>
                  )}
                </>
              )}

              {!!contractVehicles?.length && (
                <Tooltip
                  content={<div className="whitespace-pre">{contractVehicles.map(({ name }) => name).join('\n')}</div>}
                >
                  <div className="flex items-baseline">
                    <Tag icon="label" intent="primary" minimal>
                      {`${contractVehicles.length} ${pluralize(
                        'Contract Vehicle',
                        'Contract Vehicles',
                        contractVehicles.length
                      )}`}
                    </Tag>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {!!vendors?.length && (
            <div className="flex flex-wrap items-center gap-2">
              <div className="flex flex-wrap items-center gap-2">
                <OppSearchEntityExtractTagList entities={vendors || []} pluralizedLabels={['vendor', 'vendors']} />
              </div>
            </div>
          )}
          {!!displayableFollows?.length && (
            <AvatarList
              avatarData={displayableFollows?.map(
                ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                  initials,
                  name,
                  organizationName,
                  avatar,
                  avatarColor,
                  notificationProps: {
                    intent: active ? 'success' : 'danger',
                    icon: active ? 'thumbs-up' : 'thumbs-down',
                    size: 'xl',
                    position: 'bottom'
                  }
                })
              )}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
});

ContactSearchResult.displayName = 'ContactSearchResult';
