import React from 'react';
import { MenuItemProps } from '@blueprintjs/core';
import { ItemModifiers, MultiSelect, MultiSelectProps } from '@blueprintjs/select';

import { InputErrorMessage } from 'app/molecules/InputErrorMessage/InputErrorMessage';
import { InputFormGroupProps, InputFormGroup } from 'app/molecules/InputFormGroup/InputFormGroup';

import {
  defaultRenderItem,
  defaultOnItemSelect,
  defaultMenuItemProps,
  defaultTagInputProps,
  DefaultNoResults
} from './utils';

export type InputMultiSelectProps<Item> = {
  name: string;
  items: Item[];
  selectedItems: Item[];
  onChange: (items: Item[]) => void;
  getItemTextLabel: (item: Item) => string;
  formGroupProps?: InputFormGroupProps;
  getItemLabel?: (item: Item) => React.ReactNode;
  getItemValue: (item: Item) => string | number;
  menuItemProps?: Partial<MenuItemProps>;
  getMenuItemProps?: (item: Item, modifiers: ItemModifiers) => Partial<MenuItemProps>;
  error?: string;
  loading?: boolean;
  maxItems?: number;
} & Partial<MultiSelectProps<Item>>;

/**
 * This component is a wrapper around the BlueprintJS MultiSelect component.
 * It displays a list of items and allows the user to select multiple items from the list.
 */
export function InputMultiSelect<Item>({
  name,
  items = [],
  error,
  loading,
  onChange,
  getItemTextLabel,
  getItemLabel,
  getItemValue,
  maxItems = 200,
  selectedItems = [],
  tagInputProps = {},
  menuItemProps = {},
  getMenuItemProps,
  formGroupProps = {},
  popoverProps,
  placeholder,
  ...rest
}: InputMultiSelectProps<Item>) {
  const resetOnSelect = typeof rest.query === 'string' ? rest.query !== '' : true;

  return (
    <InputFormGroup name={name} intent={error ? 'danger' : 'none'} {...formGroupProps}>
      <MultiSelect
        resetOnSelect={resetOnSelect}
        itemsEqual={(a, b) => getItemValue(a) === getItemValue(b)}
        items={items.slice(0, maxItems)}
        itemRenderer={(item, options) =>
          defaultRenderItem({
            options,
            menuItemProps: {
              ...defaultMenuItemProps({ getItemTextLabel, getItemLabel, getItemValue, selectedItems, item, options }),
              ...menuItemProps,
              ...getMenuItemProps?.(item, options.modifiers)
            }
          })
        }
        noResults={<DefaultNoResults loading={loading} />}
        placeholder={loading ? 'Loading...' : placeholder}
        onItemSelect={item => defaultOnItemSelect(item, selectedItems, onChange, getItemTextLabel)}
        tagRenderer={getItemTextLabel}
        resetOnQuery={false}
        selectedItems={selectedItems}
        popoverProps={{ minimal: true, matchTargetWidth: true, ...popoverProps }}
        tagInputProps={{
          ...defaultTagInputProps({ error, selectedItems, onChange }),
          ...tagInputProps
        }}
        {...rest}
      />
      <InputErrorMessage error={error} />
    </InputFormGroup>
  );
}
