import React from 'react';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { OrganizationZapForm } from 'app/organisms/OrganizationZapForm/OrganizationZapForm';
import { ProviderFeedForm } from 'app/organisms/ProviderFeedForm/ProviderFeedForm';
import { OrganizationDeleteForm } from 'app/organisms/OrganizationDeleteForm/OrganizationDeleteForm';
import { Loading } from 'app/atoms/Loading/Loading';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { OrganizationSysAdminForm } from 'app/organisms/OrganizationSysAdminForm/OrganizationSysAdminForm';

export const OrganizationSysAdminSettings = () => {
  const sysAdminViewer = useAuthorized({ role: 'sys_admin' });
  const { data: organization, isLoading } = useGetCurrentOrganizationQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (!sysAdminViewer) {
    return null;
  }

  return (
    <div className="space-y-8">
      <OrganizationSysAdminForm organization={organization} />
      <OrganizationZapForm organization={organization || {}} />
      <ProviderFeedForm />
      <OrganizationDeleteForm />
    </div>
  );
};
