import React from 'react';
import { Button, ButtonProps } from '@blueprintjs/core';
import { Select, SelectProps } from '@blueprintjs/select';
import { MenuItemLink } from 'app/atoms/MenuItemLink/MenuItemLink';
import { useLocation } from 'react-router-dom';
import { cn } from 'app/lib/cn';
import { AuthenticatedNavItemsUtils } from 'app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';

export type NavSelectProps = {
  items: { value: string; label: string }[];
  buttonProps?: Partial<ButtonProps>;
  selectProps?: Partial<SelectProps<{ value: string; label: string }>>;
};

export const NavSelect = ({ items, buttonProps, selectProps }: NavSelectProps) => {
  const { pathname } = useLocation();
  const pathDoesMatch = AuthenticatedNavItemsUtils.makePathDoesMatch(pathname);

  return (
    <Select
      key={pathname}
      items={items}
      itemRenderer={path => (
        <MenuItemLink key={path.value} to={path.value} text={path.label} active={pathDoesMatch(path.value)} />
      )}
      onItemSelect={() => {}}
      {...selectProps}
      popoverProps={{
        ...selectProps?.popoverProps,
        minimal: true,
        modifiers: { ...selectProps?.popoverProps?.modifiers, offset: { enabled: true } }
      }}
    >
      <Button
        text={items.find(path => pathDoesMatch(path.value))?.label}
        rightIcon="caret-down"
        outlined
        {...buttonProps}
        className={cn('w-32 justify-between', buttonProps?.className)}
      />
    </Select>
  );
};
