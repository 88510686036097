import { DialogProps } from '@blueprintjs/core';
import { FileViewer } from 'app/molecules/FileViewer/FileViewer';
import { ToolboxDialog } from 'app/organisms/OppWorkspacePageContents/ToolboxDialog';
import { OppWorkspaceQuoteDrawer } from 'app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteDrawer';
import React, { createContext, useContext, useRef } from 'react';
import { QuoteShow } from 'types/__generated__/GovlyApi';
import { createStore, StoreApi, useStore } from 'zustand';

type State = {
  workspaceId: string;
  fileToView?: { name: string; link: string };
  quoteToView?: QuoteShow;
  shouldPollQuotes: boolean;
  toolboxDialogProps: DialogProps;
};

type Action = {
  setFileToView: (file?: { name: string; link: string }) => void;
  setQuoteToView: (quote?: QuoteShow) => void;
  setShouldPollQuotes: (shouldPoll: boolean) => void;
  createQuote?: (attachment: { signedId: string }) => Promise<void>;
  deleteQuote?: (id: string) => Promise<void>;
  setToolboxDialogProps: (toolboxDialogProps: State['toolboxDialogProps']) => void;
};

type OppWorkspaceStore = State & Action;

type OppWorkspaceStoreProps = {
  workspaceId: string;
};

export const createOppWorkspaceStore = ({ workspaceId }: OppWorkspaceStoreProps) =>
  createStore<OppWorkspaceStore>()(set => ({
    workspaceId,
    fileToView: undefined,
    quoteToView: undefined,
    shouldPollQuotes: true,
    toolboxDialogProps: {
      isOpen: false
    },

    setFileToView: file => set({ fileToView: file }),
    setQuoteToView: quote => set({ quoteToView: quote }),
    setShouldPollQuotes: shouldPoll => set({ shouldPollQuotes: shouldPoll }),
    setToolboxDialogProps: toolboxDialogProps => set({ toolboxDialogProps })
  }));

export const OppWorkspaceStoreContext = createContext<ReturnType<typeof createOppWorkspaceStore> | null>(null);

export const OppWorkspaceStoreProvider = ({
  workspaceId,
  children
}: {
  workspaceId: string;
  children: React.ReactNode;
}) => {
  const store = useRef(createOppWorkspaceStore({ workspaceId })).current;
  const setFileToView = useStore(store, state => state.setFileToView);
  const fileToView = useStore(store, state => state.fileToView);
  return (
    <OppWorkspaceStoreContext.Provider value={store}>
      {children}

      <OppWorkspaceQuoteDrawer />
      <ToolboxDialog />

      <FileViewer
        isOpen={!!fileToView?.link}
        downloadLink={fileToView?.link ?? ''}
        fileName={fileToView?.name ?? ''}
        fileUrl={fileToView?.link ?? ''}
        onClose={() => setFileToView(undefined)}
      />
    </OppWorkspaceStoreContext.Provider>
  );
};

export function useOppWorkspaceStoreRef(): StoreApi<OppWorkspaceStore> {
  const store = useContext(OppWorkspaceStoreContext);
  if (!store) {
    throw new Error('useOppWorkspaceStore must be used within a OppWorkspaceStoreProvider');
  }
  return store;
}

export function useOppWorkspaceStore<T = OppWorkspaceStore>(selector: (state: OppWorkspaceStore) => T): T {
  return useStore(useOppWorkspaceStoreRef(), selector);
}
