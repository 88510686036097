import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { ProviderFeedIndex } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';

import { formatTime } from 'app/lib/dates';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<ProviderFeedIndex>();

export const ProviderFeedsTable = () => {
  const { data = [], isLoading } = useGetProviderFeedsQuery({ all: 'true' });

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID'
      }),
      columnHelper.accessor('organization.name', {
        header: 'Organization',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('contractVehicle.contractName', {
        header: 'Contract Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('lastActive', {
        header: 'Last Active',
        sortingFn: 'basic',
        cell: e => <span>{!e.row.original.lastActive ? 'Never Received' : formatTime(e.row.original.lastActive)}</span>
      })
    ],
    []
  );

  return (
    <GovlyTable
      id="provider_feeds_table"
      columns={columns}
      data={data}
      title={<GovlyTableTitle title="Provider Feeds" />}
      isLoading={isLoading}
      initialState={{
        pagination: { pageSize: 50 },
        sorting: [{ id: 'type', desc: false }]
      }}
    />
  );
};
