import React from 'react';
import { Tooltip, TooltipProps } from '@blueprintjs/core';
import { useAuthorized, Role } from 'app/hooks/useAuthorize';

type AuthorizationRequiredTooltipProps = {
  children: React.ReactElement;
  role: Role;
} & Partial<TooltipProps>;

export const AuthorizationRequiredTooltip = ({ children, role, ...rest }: AuthorizationRequiredTooltipProps) => {
  const authorized = useAuthorized({ role });

  if (authorized) {
    return children;
  }

  return (
    <Tooltip content={`Not authorized. You need to have ${role} permissions to perform this action.`} {...rest}>
      {React.cloneElement(children, { disabled: !authorized })}
    </Tooltip>
  );
};
