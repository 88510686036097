import { ActivityExtended, ActivityNotification } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['ActivityNotification', 'CurrentUser'] });

export type ActivityNotificationWithData = Omit<ActivityNotification, 'activity'> & {
  activity?: Omit<ActivityExtended, 'customData'> & {
    customData: {
      mentions?: string[];
      displayId?: string;
      workspaceName?: string;
      displayName?: string;
      path?: string;
    };
  };
};

type GetActivityNotifications = {
  params: { page?: number; per_page?: number } | void;
  result: ActivityNotificationWithData[];
};

type BulkUpdateActivityNotifications = {
  params: { ids: string[] };
  result: { ok: boolean };
};

type UpdateActivityNotification = {
  params: { id: string; readAt: string };
  result: { id: string };
};

export const activityNotificationsApi = api.injectEndpoints({
  endpoints: build => ({
    getActivityNotifications: build.query<GetActivityNotifications['result'], GetActivityNotifications['params']>({
      query: params => ({ url: '/v2/activity_notifications', params: params ?? {} }),
      providesTags: result =>
        result ? result.map(({ id }) => ({ type: 'ActivityNotification', id })) : ['ActivityNotification']
    }),

    bulkUpdateActivityNotifications: build.mutation<
      BulkUpdateActivityNotifications['result'],
      BulkUpdateActivityNotifications['params']
    >({
      query: body => ({
        url: `/v2/activity_notifications/bulk_update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ActivityNotification', 'CurrentUser']
    }),

    updateActivityNotification: build.mutation<
      UpdateActivityNotification['result'],
      UpdateActivityNotification['params']
    >({
      query: ({ id, ...body }) => ({
        url: `/v2/activity_notifications/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['ActivityNotification', 'CurrentUser']
    })
  })
});

export const {
  useGetActivityNotificationsQuery,
  useBulkUpdateActivityNotificationsMutation,
  useUpdateActivityNotificationMutation
} = activityNotificationsApi;
