import React from 'react';
import { Button } from '@blueprintjs/core';
import { useInfiniteHits } from 'react-instantsearch';

import { AlgoliaSearchVendorProfile } from 'types/shared/AlgoliaSearchVendorProfile';
import { VendorProfileSearchHit } from 'app/organisms/VendorProfileSearchHit/VendorProfileSearchHit';

export const VendorProfileSearchHits = () => {
  const { hits, isLastPage, showMore } = useInfiniteHits<AlgoliaSearchVendorProfile>({ showPrevious: false });

  return (
    <>
      <section className="space-y-8">
        {hits.map(hit => (
          <VendorProfileSearchHit key={hit.objectID} hit={hit} />
        ))}
      </section>

      {!isLastPage && (
        <div className="flex justify-center">
          <Button onClick={showMore} text="Show More" />
        </div>
      )}
    </>
  );
};
