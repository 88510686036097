import { SearchableActions } from 'app/hooks/search/types';
import { create } from 'zustand';
import { useFormikSavableSearch } from 'app/hooks/search/useFormikSavableSearch';
import { tenYearsAgo, todayEnd } from 'app/lib/dates';
import { useGetSavedSearchesQuery } from 'api/savedSearchesApi';
import { SavedSearchDisplayable } from 'types/__generated__/GovlyApi';
import { SearchMeta } from './types';

export type ContactSearchFilters = {
  dateRangeParam: 'last_active';
  dateRange: [Date | null, Date | null];
  contractVehicleNames: string[];
  contractVehicleNamesNone: string[];
  buyerDomains: string[];
  buyerDomainsNone: string[];
  buyerIds: string[];
  buyerIdsNone: string[];
  buyerMatchById: boolean;
  includeSubBuyers: boolean;
  labels: string[];
  labelsNone: string[];
  naicsCodes: string[];
  naicsCodesNone: string[];
  regions: string[];
  regionsNone: string[];
  setAsides: string[];
  setAsidesNone: string[];
  titles: string[];
  excludeTitles: string[];
  vendorNames: string[];
  vendorNamesNone: string[];
  following: string[];
  excludeFollowing: string[];
  excludeIgnored: string[];
  sort: 'last_active';
  sortDirection: 'desc' | 'asc';
};

export type FieldNames = keyof ContactSearchFilters;

export type { State as ContactSearchForm };
type State = {
  query: string;
  filters: ContactSearchFilters;
  meta: SearchMeta;
};

type Action = SearchableActions;

type Store = State & Action;

export const getFormState = (state: Store): State => {
  const { reset: _, ...formState } = state;
  return formState;
};

const currentUserId = globalThis.process?.env?.CURRENT_USER_ID;

export const defaultValues: State = {
  query: '',
  meta: {
    searchState: 'initializing',
    savedSearchId: null
  },
  filters: {
    dateRangeParam: 'last_active',
    dateRange: [tenYearsAgo(), todayEnd()],
    contractVehicleNames: [],
    contractVehicleNamesNone: [],
    buyerDomains: [],
    buyerDomainsNone: [],
    buyerIds: [],
    buyerIdsNone: [],
    buyerMatchById: true,
    includeSubBuyers: false,
    labels: [],
    labelsNone: [],
    naicsCodes: [],
    naicsCodesNone: [],
    regions: [],
    regionsNone: [],
    setAsides: [],
    setAsidesNone: [],
    titles: [],
    excludeTitles: [],
    vendorNames: [],
    vendorNamesNone: [],
    following: [],
    excludeFollowing: [],
    excludeIgnored: currentUserId ? [currentUserId] : [],
    sort: 'last_active',
    sortDirection: 'desc'
  }
};

type GetStateFromSavedSearchArgs = { savedSearch: SavedSearchDisplayable; meta: State['meta'] };
export const getStateFromSavedSearch = ({ savedSearch, meta }: GetStateFromSavedSearchArgs): State => {
  const {
    query: { query, ...savedFilters }
  } = savedSearch || { query: { query: '' } };

  return {
    query: query || '',
    filters: {
      ...defaultValues.filters,
      ...savedFilters,
      // Ensure that excludeIgnored is properly set as a string array
      excludeIgnored: Array.isArray(savedFilters.excludeIgnored)
        ? savedFilters.excludeIgnored
        : defaultValues.filters.excludeIgnored
    },
    meta: { ...meta, savedSearchId: savedSearch.id }
  };
};

export const useContactSearchCache = create<Store>(set => ({
  ...defaultValues,
  reset: () => set(defaultValues, true)
}));

export const useInitializeContactSearchCache = () => {
  const { data: allSavedSearches = [] } = useGetSavedSearchesQuery({ active: true, searchableType: 'Contact' });
  useFormikSavableSearch({
    getStateFromSavedSearch,
    savedSearches: allSavedSearches,
    store: useContactSearchCache
  });
};
