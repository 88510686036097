import React from 'react';

import { useFeedProvidersQuery } from 'api/queries/useFeedProvidersQuery';

type ProviderLogosProps = {
  providerFeedIds: string[];
} & React.HTMLAttributes<HTMLDivElement>;

export const ProviderLogos = ({ providerFeedIds, ...rest }: ProviderLogosProps) => {
  const { data: authFeeds } = useFeedProvidersQuery({ providerFeedIds });

  if (!authFeeds.some(({ logoUrl }) => logoUrl)) {
    return null;
  }

  const displayed: Record<string, boolean> = {};

  return (
    <div {...rest}>
      {authFeeds.map(({ id, logoUrl, providerName }) => {
        if (logoUrl && !displayed[providerName]) {
          displayed[providerName] = true;
          return <img src={logoUrl} key={id} className="img-fluid mr-2" alt={providerName}></img>;
        }
      })}
    </div>
  );
};
