import React from 'react';

import { useAwardSearchPagination, useAwardSearchMutation, AWARD_SEARCH_CACHE_KEY } from 'api/awardsApi';
import { SearchPagination } from 'app/molecules/SearchPagination/SearchPagination';
import { Loading } from 'app/atoms/Loading/Loading';
import { AwardSearchResultsControls } from 'app/organisms/AwardSearchResults/AwardSearchResultsControls';
import { AwardSearchResultCard } from './AwardSearchResultCard';

export const AwardSearchResultsCards = () => {
  const [_search, { data: { results = [] } = {}, isLoading }] = useAwardSearchMutation({
    fixedCacheKey: AWARD_SEARCH_CACHE_KEY
  });

  if (isLoading) return <Loading type="card-list" />;

  return (
    <>
      <AwardSearchResultsControls />

      {results.map(award => (
        <AwardSearchResultCard key={award.id} award={award} />
      ))}

      <SearchPagination paginationHook={useAwardSearchPagination} />
    </>
  );
};
