import React from 'react';
import { Icon, Spinner } from '@blueprintjs/core';
import { useOppWorkspaceStore } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { PdfIcon } from 'images/components/PdfIcon';
import { match, P } from 'ts-pattern';
import { QuoteShow } from 'types/__generated__/GovlyApi';

export function useOppWorkspaceQuoteListItem(quote: QuoteShow) {
  const setQuoteToView = useOppWorkspaceStore(state => state.setQuoteToView);

  const QuoteIcon = ({ size = 36 }: { size?: number }) =>
    match(quote.textExtractStatus)
      .with('completed', () => <PdfIcon size={size} />)
      .with('processing', () => <Spinner size={size} intent="primary" />)
      .otherwise(() => <Icon icon="document" size={size} />);

  const subtext = match(quote)
    .with({ textExtractStatus: 'processing' }, () => 'Analyzing and extracting information from your document…')
    .with({ lineItemsTotal: P.not(P.nullish) }, ({ lineItemsTotal, lineItemsRaw }) => {
      const totalAsCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(lineItemsTotal);

      return `${lineItemsRaw.length} line items totalling ${totalAsCurrency}`;
    })
    .with(
      { textExtractStatus: 'completed', lineItemsRaw: P.when(l => l.length > 0) },
      ({ lineItemsRaw }) => `${lineItemsRaw.length} line items`
    )
    .otherwise(() => 'No line items found.');

  const disableLineItems =
    quote.textExtractStatus !== 'completed' || !quote.lineItemsRaw || quote.lineItemsRaw.length === 0;

  return {
    title: quote.file?.name ?? '(untitled)',
    subtext,
    disableLineItems,
    QuoteIcon,
    viewQuote: () => setQuoteToView(quote)
  };
}
