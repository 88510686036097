import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { PageHeading } from 'app/atoms/Typography/Typography';
import { formatTime } from 'app/lib/dates';
import { useGetPartnershipQuery } from 'api/partnershipsApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { NavSelect } from 'app/molecules/NavSelect/NavSelect';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { NotFound } from 'app/molecules/NotFound/NotFound';

export const PartnershipShowPage = () => {
  const { id } = useParams();
  const { data: { partnerName, createdAt } = {}, isLoading } = useGetPartnershipQuery({ id: id ?? '' }, { skip: !id });

  const tabs = [
    {
      label: 'Settings',
      value: `partnerships/${id}`
    },
    {
      label: 'Shared Feeds',
      value: `partnerships/${id}/shared_feeds`
    },
    {
      label: 'Contacts',
      value: `partnerships/${id}/contacts`
    }
  ];

  if (isLoading) {
    return <AppLoading />;
  }

  if (!partnerName) {
    return <NotFound />;
  }

  return (
    <Main>
      <MainColumn>
        <div className="space-y-2">
          <Breadcrumbs items={[{ text: 'Partnerships', to: '/partnerships' }, { text: partnerName }]} />

          <div className="inline-block">
            <NavSelect items={tabs} buttonProps={{ className: 'w-40' }} />
          </div>
        </div>

        <div>
          <PageHeading>Partnership with {partnerName}</PageHeading>

          {createdAt && (
            <>
              <span>Partnered Since </span>
              <span>{formatTime(createdAt)}</span>
            </>
          )}
        </div>
        <Outlet />
      </MainColumn>
    </Main>
  );
};
