import { create } from 'zustand';

type State = {
  isOpen: boolean;
  isSwitching: boolean;
};

type Action = {
  setIsOpen: (v: boolean) => void;
  setIsSwitching: (v: boolean) => void;
};

type Store = State & Action;

export type { Store as OrganizationSwitcherStore };

export const useOrganizationSwitcherStore = create<Store>(set => ({
  isOpen: false,
  isSwitching: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setIsSwitching: (isSwitching: boolean) => set({ isSwitching })
}));
