import React from 'react';
import startCase from 'lodash-es/startCase';
import { Tag } from '@blueprintjs/core';

export const UsersTableRole = ({ role }: { role: string }) => {
  if (role === 'admin') {
    return <Tag intent="warning">{startCase(role)}</Tag>;
  } else {
    return <Tag minimal>{startCase(role)}</Tag>;
  }
};
