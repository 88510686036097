import React from 'react';
import { useField } from 'formik';
import { RadioGroup, Radio } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { FollowDisplayable, OrganizationUserFollow } from 'types/__generated__/GovlyApi';

export const WorkspaceFormNotificationsInput = ({
  notifyNewFollowersOnly = true
}: {
  notifyNewFollowersOnly: boolean;
}) => {
  const currentUserId = useCurrentUserAttribute('id');
  const [{ value: notifyGroup }, _meta, { setValue: setNotifyGroup }] = useField<'notifiables' | 'nobody' | 'select'>({
    name: 'notifyGroup'
  });
  const [{ value: currentTeamFollows }] = useField<FollowDisplayable[]>({ name: 'teamFollows' });
  const [{ value: currentPartnerFollows }] = useField<FollowDisplayable[]>({ name: 'partnerFollows' });

  const follows = [...currentTeamFollows, ...currentPartnerFollows];

  const notifiableFollows = follows.filter(({ organizationUserId }) => organizationUserId !== currentUserId);

  const notifiableUsers = notifiableFollows
    .map(({ organizationUser }) => organizationUser)
    .filter(Boolean) as OrganizationUserFollow[];

  const hasNewFollows = notifiableFollows.some(({ id }) => !id);

  if ((notifyNewFollowersOnly && !hasNewFollows) || !notifiableUsers?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <p className="mb-2 text-base font-medium">When I save this, notify...</p>

      <RadioGroup
        selectedValue={notifyGroup}
        onChange={e => setNotifyGroup(e.currentTarget.value as typeof notifyGroup)}
      >
        <Radio large label={notifyNewFollowersOnly ? 'New followers' : 'All followers'} value="notifiables" />
        <Radio large label="No one" value="nobody" data-test="workspace-notify-nobody" />
        {notifiableUsers.length > 1 && <Radio large label="Only the people I select..." value="select" />}
      </RadioGroup>
      {notifyGroup === 'select' && notifiableUsers.length > 1 && (
        <FollowerSelectInput
          hideFollowSettings
          name="customNotifyList"
          defaultButtonText="Select notification recipients"
          organizationUsers={notifiableUsers}
        />
      )}
    </div>
  );
};
