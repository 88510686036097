import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AnchorButton, Menu, MenuItem, MenuDivider, Popover } from '@blueprintjs/core';

import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useUpdateOrganizationUserMutation, useInviteOrganizationUserMutation } from 'api/organizationUsersApi';
import { successToast, errorToast } from 'app/lib/toaster';

interface UsersTableRowProps {
  organizationUser: OrganizationUserCurrentOrg;
}

export const UsersTableRowActions = ({
  organizationUser: {
    subscriptionSeatPaid,
    subscriptionOrganizationPaid,
    lastLoginAt,
    deactivatedAt,
    inviteSentAt,
    id,
    primaryRole
  }
}: UsersTableRowProps) => {
  const currentUserId = useCurrentUserAttribute('id');
  const email = useCurrentUserAttribute('email');
  const isAdmin = useAuthorized({ role: 'admin' });
  const viewingSelf = id === currentUserId;

  const [updateOrganizationUser, { isLoading, error, isError }] = useUpdateOrganizationUserMutation();

  const [inviteOrganizationUser, { isLoading: isInviting, isSuccess: inviteSent }] =
    useInviteOrganizationUserMutation();

  useEffect(() => {
    if (isError) {
      errorToast(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (inviteSent) {
      successToast('Invitation sent.');
    }
  }, [inviteSent]);

  return (
    <Popover
      className="self-end"
      content={
        <Menu key="menu">
          {isLoading && <MenuItem icon="refresh" text="Loading..." disabled />}
          {!deactivatedAt && !lastLoginAt && (
            <MenuItem
              icon="send-message"
              onClick={() => inviteOrganizationUser({ id })}
              text={inviteSentAt ? 'Resend Invite' : 'Send Invite'}
              disabled={isInviting}
            />
          )}

          <Link
            to={`/users/${id}`}
            state={{
              breadcrumbs: [{ to: '/settings/users', text: 'Users' }, { text: email }]
            }}
            className="no-underline text-black"
          >
            <MenuItem icon="id-number" text="View Profile" tagName="span" />
          </Link>

          {(isAdmin || viewingSelf) && (
            <Link
              to={`/users/${id}/edit`}
              state={{
                breadcrumbs: [
                  { to: '/settings/users', text: 'Users' },
                  { to: `/users/${id}`, text: email },
                  { text: 'Edit' }
                ]
              }}
              className="no-underline text-black"
            >
              <MenuItem icon="edit" text="Edit Profile" tagName="span" />
            </Link>
          )}

          {isAdmin && (subscriptionOrganizationPaid || !viewingSelf) && <MenuDivider />}

          {isAdmin && subscriptionOrganizationPaid && (
            <MenuItem
              icon={subscriptionSeatPaid ? 'blocked-person' : 'new-person'}
              onClick={() => updateOrganizationUser({ id, subscriptionSeatPaid: !subscriptionSeatPaid })}
              intent={subscriptionSeatPaid ? 'danger' : 'success'}
              text={subscriptionSeatPaid ? 'Remove paid seat' : 'Add paid seat'}
              disabled={isLoading}
            />
          )}

          {isAdmin && !viewingSelf && (
            <MenuItem
              intent="warning"
              icon="warning-sign"
              onClick={() => updateOrganizationUser({ id, role: primaryRole === 'admin' ? 'member' : 'admin' })}
              text={primaryRole === 'admin' ? 'Remove admin access' : 'Grant admin acess'}
              disabled={isLoading}
            />
          )}
        </Menu>
      }
    >
      <AnchorButton icon="more" />
    </Popover>
  );
};
