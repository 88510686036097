import React from 'react';
import { markdownLookBack } from '@llm-ui/markdown';
import { LLMOutputComponent, throttleBasic, useLLMOutput } from '@llm-ui/react';
import { codeBlockLookBack, findCompleteCodeBlock, findPartialCodeBlock } from '@llm-ui/code';

import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';
import { LogoIcon } from 'images/components/LogoIcon';
import { LlmAssistantMessageBreakout } from 'types/__generated__/GovlyApi';
import { AssistantLlmMarkdown } from './AssistantLlmMarkdown';
import { AssistantLlmBlockProps, CodeBlock } from './AssistantLlmBlocks';
import {
  CitationComponent,
  findCompleteCitationBlock,
  findPartialCitationBlock,
  citationBlockLookBack
} from './CitationBlock';

type AssistantMessageProps = {
  content: string;
  isPartial?: boolean;
  preventFocus?: boolean;
  annotations?: LlmAssistantMessageBreakout['annotations'];
};

export const AssistantMessage = ({ content, isPartial, preventFocus, annotations }: AssistantMessageProps) => {
  const throttle = throttleBasic({
    readAheadChars: 4,
    targetBufferChars: 2
  });

  const { blockMatches } = useLLMOutput({
    llmOutput: content,
    fallbackBlock: {
      component: AssistantLlmMarkdown,
      lookBack: markdownLookBack()
    },
    blocks: [
      {
        component: CodeBlock,
        findCompleteMatch: findCompleteCodeBlock(),
        findPartialMatch: findPartialCodeBlock(),
        lookBack: codeBlockLookBack()
      },
      {
        component: CitationComponent as LLMOutputComponent<AssistantLlmBlockProps>,
        findCompleteMatch: findCompleteCitationBlock(),
        findPartialMatch: findPartialCitationBlock(),
        lookBack: citationBlockLookBack()
      }
    ],
    isStreamFinished: !isPartial,
    throttle
  });

  return (
    <div className="h-fit flex mt-4">
      <LogoIcon className="h-8 w-8 mr-2 rounded-full" />
      <span className={'p-2 rounded bg-gray-100 inline-block max-w-[80%]'}>
        {isPartial && content === '' && <LoadingSpinner />}
        {blockMatches.map((blockMatch, i) => {
          const Component = blockMatch.block.component as LLMOutputComponent<AssistantLlmBlockProps>;

          const isCitationComponent = Component === CitationComponent;
          const props = {
            preventFocus,
            blockMatch,
            annotations,
            ...(isCitationComponent && { citationIndex: i })
          };

          return <Component key={i} {...props} />;
        })}
      </span>
    </div>
  );
};
