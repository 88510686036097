import React from 'react';
import { Button } from '@blueprintjs/core';

import { useCreateReportMutation } from 'api/reportsApi';
import { successToast, errorToast } from 'app/lib/toaster';
import { useEventTracking } from 'app/hooks/useEventTracking';

type ReportExportButtonProps = {
  reportType: 'Report::OrganizationFollowsReport';
  filters: object;
};

export const ReportExportButton = ({ reportType, filters }: ReportExportButtonProps) => {
  const [exportMutation, { isLoading }] = useCreateReportMutation();
  const { trackEvent } = useEventTracking();

  return (
    <Button
      text="Export"
      icon="import"
      loading={isLoading}
      onClick={async () => {
        try {
          await exportMutation({ reportType, ...filters });
          successToast('Your report is being generated and will be emailed to you shortly.');
          trackEvent({
            object: 'insights',
            action: 'exported',
            properties: { reportType, ...filters }
          });
        } catch (e) {
          errorToast(e);
        }
      }}
    />
  );
};
