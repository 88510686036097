import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Button, Pre, Tooltip } from '@blueprintjs/core';

import { errorToast, successToast } from 'app/lib/toaster';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { useCreateZapMutation, useDeleteZapMutation } from 'api/zapsApi';
import { useGetOrganizationsQuery } from 'api/organizationsApi';
import { OrganizationCurrentOrganization } from 'types/__generated__/GovlyApi';

type OrganizationZapFormProps = {
  organization: OrganizationCurrentOrganization | Record<string, never>;
};

type FormValues = {
  zap: {
    action: 'create_workspace';
    trigger: 'workspace_follow';
    params: {
      followerOrganizationId: string;
      followerEmail: string;
      triggerEmail: string;
    };
  };
};

export const OrganizationZapForm = ({ organization }: OrganizationZapFormProps) => {
  const { zaps = [] } = organization;
  const { data: organizations = [] } = useGetOrganizationsQuery();
  const [createZap, { isLoading: isCreating }] = useCreateZapMutation();
  const [deleteZap, { isLoading: isDeleting }] = useDeleteZapMutation();

  const form: FormikConfig<FormValues> = {
    enableReinitialize: true,
    initialValues: {
      zap: {
        action: 'create_workspace',
        trigger: 'workspace_follow',
        params: {
          followerOrganizationId: '',
          followerEmail: '',
          triggerEmail: ''
        }
      }
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await createZap(values).unwrap();
        successToast();
        resetForm();
      } catch (e) {
        errorToast(e);
      }
    },
    validationSchema: yup.object({
      zap: yup.object({
        trigger: yup.string().required(),
        action: yup.string().required()
      })
    })
  };

  return (
    <Card title="Zaps">
      <Formik {...form}>
        <Form>
          <CardBody>
            <div>
              <span className="my-2 rounded bg-green-50 p-2">When a Workspace is created in</span>
              <SelectInput
                name="zap.params.followerOrganizationId"
                items={organizations.map(({ id, name }) => ({ value: id, label: name }))}
                searchKeys={['name']}
                className="my-2"
              />
            </div>
            <div className="space-y-2">
              <span className="my-2 inline-block rounded bg-orange-50 p-2">Create a separate Workspace</span>
              <div className="grid gap-4 lg:grid-cols-2">
                <TextInput
                  name="zap.params.followerEmail"
                  label="Email of the user that will follow the new Workspace"
                  labelInfo="(required)"
                  type="email"
                />
                <TextInput
                  name="zap.params.triggerEmail"
                  label="Email of the user that causes this Zap"
                  labelInfo="(optional)"
                  type="email"
                />
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button large type="submit" loading={isCreating}>
              Save
            </Button>
          </CardFooter>
        </Form>
      </Formik>
      {Object.values(zaps).map(zap => (
        <CardBody key={zap.id}>
          <div className="flex justify-between">
            <strong>{`Zap (ID: ${zap.id})`}</strong>
            <Tooltip content="Remove">
              <Button
                small
                disabled={isDeleting}
                icon="cross"
                intent="danger"
                outlined
                onClick={() => deleteZap(zap.id)}
              />
            </Tooltip>
          </div>
          <Pre>{JSON.stringify(zap, null, 2)}</Pre>
        </CardBody>
      ))}
    </Card>
  );
};
