import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

export type GetOrganizationTeams = {
  params: {
    view?: 'with_organization_users';
  };
  result: OrganizationTeam[] | OrganizationTeamWithOrganizationUsers[];
};

export type GetOrganizationTeamsView<View extends GetOrganizationTeams['params']['view']> =
  View extends 'with_organization_users' ? OrganizationTeamWithOrganizationUsers[] : OrganizationTeam[];

export type GetOrganizationTeamApiView = GetOrganizationTeams['params']['view'];

type GetOrganizationTeam = {
  params: {
    id: string;
    view?: 'with_organization_users';
  };
  result: OrganizationTeam | OrganizationTeamWithOrganizationUsers;
};

type CreateOrganizationTeam = {
  params: {
    name: string;
    avatarColor?: string;
    organizationUserIds?: string[];
  };

  result: OrganizationTeam;
};

type UpdateOrganizationTeam = {
  params: {
    id: string;
    name?: string;
    avatarColor?: string;
    organizationUserIds?: string[];
  };

  result: OrganizationTeam;
};

type DeleteOrganizationTeam = {
  params: {
    id: string;
  };
  result: void;
};

type AddMemberToOrganizationTeam = {
  params: {
    id: string;
    organizationUserId: string;
  };

  result: void;
};

type RemoveMemberFromOrganizationTeam = {
  params: {
    id: string;
    organizationUserId: string;
  };

  result: void;
};

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OrganizationTeam'] });

const OrganizationTeamsApi = api.injectEndpoints({
  endpoints: build => ({
    getOrganizationTeams: build.query<GetOrganizationTeams['result'], GetOrganizationTeams['params']>({
      query: params => ({ url: '/v2/organization_teams', params }),
      providesTags: ['OrganizationTeam']
    }),

    getOrganizationTeam: build.query<GetOrganizationTeam['result'], GetOrganizationTeam['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/organization_teams/${id}`, params }),
      providesTags: (_res, _err, params) => {
        if (params.id) {
          return [{ type: 'OrganizationTeam', id: params.id }];
        }

        return [];
      }
    }),

    createOrganizationTeam: build.mutation<CreateOrganizationTeam['result'], CreateOrganizationTeam['params']>({
      query: body => ({ url: `/v2/organization_teams`, method: 'POST', body }),
      invalidatesTags: () => ['OrganizationTeam']
    }),

    updateOrganizationTeam: build.mutation<UpdateOrganizationTeam['result'], UpdateOrganizationTeam['params']>({
      query: ({ id, ...body }) => ({ url: `/v2/organization_teams/${id}`, method: 'PATCH', body }),
      invalidatesTags: result => [
        ...(result ? [{ type: 'OrganizationTeam' as const, id: result.id }] : []),
        'OrganizationTeam'
      ]
    }),

    deleteOrganizationTeam: build.mutation<DeleteOrganizationTeam['result'], DeleteOrganizationTeam['params']>({
      query: ({ id }) => ({ url: `/v2/organization_teams/${id}`, method: 'DELETE' }),
      invalidatesTags: () => ['OrganizationTeam']
    }),

    addMemberToOrganizationTeam: build.mutation<
      AddMemberToOrganizationTeam['result'],
      AddMemberToOrganizationTeam['params']
    >({
      query: ({ id, ...body }) => ({ url: `/v2/organization_teams/${id}/members`, method: 'PUT', body }),
      invalidatesTags: (_res, _err, params) => [{ type: 'OrganizationTeam', id: params.id }, 'OrganizationTeam']
    }),

    removeMemberFromOrganizationTeam: build.mutation<
      RemoveMemberFromOrganizationTeam['result'],
      RemoveMemberFromOrganizationTeam['params']
    >({
      query: ({ id, organizationUserId }) => ({
        url: `/v2/organization_teams/${id}/members/${organizationUserId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_res, _err, params) => [{ type: 'OrganizationTeam', id: params.id }, 'OrganizationTeam']
    })
  })
});

export const {
  useGetOrganizationTeamQuery,
  useGetOrganizationTeamsQuery,
  useCreateOrganizationTeamMutation,
  useUpdateOrganizationTeamMutation,
  useDeleteOrganizationTeamMutation,
  useAddMemberToOrganizationTeamMutation,
  useRemoveMemberFromOrganizationTeamMutation
} = OrganizationTeamsApi;
