import React, { useEffect } from 'react';

import { useConfirmEmailMutation } from 'api/signupRequestsApi';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NonIdealState } from '@blueprintjs/core';
import { errorToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardBody } from 'app/atoms/Card/Card';

import { isFetchBaseQueryError, hasDataErrors } from 'api/utils';

const isOrganizatonSetupError = (error: unknown) => {
  if (isFetchBaseQueryError(error) && hasDataErrors(error)) {
    const errors = error.data.errors;
    if (typeof errors === 'object' && errors !== null && 'organization' in errors) {
      return true;
    }
  }

  return false;
};

export const RegisterVerification = () => {
  const [confirmEmail, { isLoading: isConfirmingEmail, error: confirmationError }] = useConfirmEmailMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const from = searchParams.get('from');

  useEffect(() => {
    if (confirmationError) errorToast(confirmationError);
  }, [confirmationError]);

  useEffect(() => {
    const confirmToken = async (token: string | null) => {
      if (!token) return;

      try {
        const { signInUrl } = await confirmEmail({ token }).unwrap();
        if (signInUrl) {
          const { pathname, search } = new URL(signInUrl);
          navigate(pathname + search);
        }
      } catch (error) {
        errorToast(error);
      }
    };

    confirmToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (isConfirmingEmail) {
    return <Loading type="icon" icon="lock" />;
  }

  if (isOrganizatonSetupError(confirmationError)) {
    return (
      <CardBody className="text-lg">
        <p>Your organization does not allow direct sign up. We are reaching out to the organization admin for you.</p>
        <p>
          If you have any questions or concerns, please reach out to{' '}
          <a href="mailto:support@govly.com">support@govly.com</a>
        </p>
      </CardBody>
    );
  }

  if (from === 'register') {
    return (
      <CardBody className="text-lg">
        <p>
          We sent you an email with a link to verify your email address. Please check your inbox and click on the link
          to complete the registration process.
        </p>
        <p>
          If you have any questions or concerns, please reach out to{' '}
          <a href="mailto:support@govly.com">support@govly.com</a>
        </p>
      </CardBody>
    );
  }

  return (
    <CardBody className="text-lg">
      <NonIdealState
        icon="error"
        title="Something went wrong"
        description={
          <p>
            If you have any questions or concerns, please reach out to{' '}
            <a href="mailto:support@govly.com">support@govly.com</a>
          </p>
        }
      />
    </CardBody>
  );
};
