import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { router } from 'app/routes';
import { useFeatureFlagsStore } from './hooks/useFeatureFlagsStore';

export const App = () => {
  useEffect(() => {
    useFeatureFlagsStore.getState().initialize(process.featureFlags);
  }, []);

  return (
    <Suspense fallback={<AppLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
