import React from 'react';
import { useParams } from 'react-router-dom';

import { FeedProfileChessForm } from 'app/organisms/FeedProfileChessForm/FeedProfileChessForm';
import { FeedProfileChessReports } from 'app/organisms/FeedProfileChessReports/FeedProfileChessReports';
import { FeedProfileUnisonForm } from 'app/organisms/FeedProfileUnisonForm/FeedProfileUnisonForm';
import { FeedProfileSewpVForm } from 'app/organisms/FeedProfileSewpVForm/FeedProfileSewpVForm';
import { FeedProfileGsaForm } from 'app/organisms/FeedProfileGsaForm/FeedProfileGsaForm';
import { ContractManagementChessCard } from 'app/organisms/ContractManagementChessCard/ContractManagementChessCard';
import { ContractManagementSewpVCard } from 'app/organisms/ContractManagementSewpVCard/ContractManagementSewpVCard';
import { ContractManagementUnisonCard } from 'app/organisms/ContractManagementUnisonCard/ContractManagementUnisonCard';
import { ContractManagementGsaAutomateCard } from 'app/organisms/ContractManagementGsaAutomateCard/ContractManagementGsaAutomateCard';

import { useSubscriptions } from 'app/hooks/useSubscription';
import { PaywallPage } from 'app/organisms/PaywallPage/PaywallPage';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';

export const ContractManagement = () => {
  const { feedProfile } = useParams();

  const {
    seatPaid,
    souptools: hasSouptools,
    primeAutomations: hasPrimeAutomations,
    isLoading
  } = useSubscriptions({
    subscriptions: ['seatPaid', 'souptools', 'primeAutomations']
  });

  if (isLoading) {
    return <AppLoading />;
  }

  if (!seatPaid) {
    return <PaywallPage />;
  }

  if (feedProfile === 'chess_profile' && hasPrimeAutomations) {
    return (
      <>
        <FeedProfileChessReports />
        <FeedProfileChessForm />
      </>
    );
  }

  if (feedProfile === 'unison_profile' && hasPrimeAutomations) {
    return <FeedProfileUnisonForm />;
  }

  if (feedProfile === 'sewp_v_profile' && hasSouptools) {
    return <FeedProfileSewpVForm />;
  }

  if (feedProfile === 'gsa_profile' && hasPrimeAutomations) {
    return <FeedProfileGsaForm />;
  }

  return (
    <div className="space-y-8">
      <ContractManagementChessCard />
      <ContractManagementSewpVCard />
      <ContractManagementGsaAutomateCard />
      <ContractManagementUnisonCard />
    </div>
  );
};
