import React from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { VendorProfileSearchFacets } from 'app/organisms/VendorProfileSearchFacets/VendorProfileSearchFacets';
import { VendorProfileSearchHits } from 'app/organisms/VendorProfileSearchHits/VendorProfileSearchHits';
import { useCurrentUserAttribute } from 'api/currentUserApi';

const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_SEARCH_PKEY);

export const VendorProfilesSearchPage = () => {
  const subscriptionActive = useCurrentUserAttribute('subscriptionActive');
  return (
    <InstantSearch searchClient={searchClient} indexName={`VendorProfile_${process.env.RAILS_ENV}`} routing={true}>
      <Configure ruleContexts={[subscriptionActive ? 'paid_account' : 'free_account']} />
      <section className="bg-gray-900">
        <div className="mx-auto py-10 lg:px-12 lg:py-16">
          <div className="grid grid-cols-12 gap-y-8 sm:gap-x-4">
            <MainColumn>
              <h1 className="text-4xl font-bold text-white">Search Vendors</h1>
              <SearchBox
                placeholder="Search..."
                classNames={{
                  form: 'bp5-input-group bp5-large',
                  input: 'bp5-input px-6',
                  reset: 'hidden',
                  resetIcon: 'hidden',
                  submit: 'hidden',
                  submitIcon: 'hidden'
                }}
              />
            </MainColumn>
          </div>
        </div>
      </section>
      <Main>
        <MainColumn columnSpan={3}>
          <nav>
            <VendorProfileSearchFacets />
          </nav>
        </MainColumn>
        <MainColumn columnSpan={9}>
          <VendorProfileSearchHits />
        </MainColumn>
      </Main>
    </InstantSearch>
  );
};
