import { ClassificationCodeTypeahead } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['NaicsCode', 'PscCode'] });

type GetNaicsCodes = {
  params: void;
  result: ClassificationCodeTypeahead[];
};

type GetPscCodes = {
  params: void;
  result: ClassificationCodeTypeahead[];
};

export const ClassificationCodesApi = api.injectEndpoints({
  endpoints: build => ({
    getNaicsCodes: build.query<GetNaicsCodes['result'], GetNaicsCodes['params']>({
      query: () => ({ url: '/v2/classification_codes/naics' }),
      providesTags: ['NaicsCode']
    }),

    getPscCodes: build.query<GetPscCodes['result'], GetPscCodes['params']>({
      query: () => ({ url: '/v2/classification_codes/psc' }),
      providesTags: ['PscCode']
    })
  })
});

export const { useGetNaicsCodesQuery, useGetPscCodesQuery } = ClassificationCodesApi;
