import React, { useState } from 'react';

import { TimelineEventListItem } from 'app/molecules/TimelineEvent/TimelineEventListItem';
import { TimelineEvent } from 'app/organisms/OppWorkspaceTimeline/OppWorkspaceTimelineUtils';

function isTimelineObject(event: TimelineEvent | React.ReactElement): event is TimelineEvent {
  return Boolean(event && typeof event === 'object' && 'message' in event);
}

export const Timeline = <Evt extends TimelineEvent | React.ReactElement>({ events = [] }: { events: Evt[] }) => {
  const [expanded, setExpanded] = useState(false);

  if (!events?.length) {
    return null;
  }

  let count = 0;
  events.forEach(event => {
    if (!isTimelineObject(event)) {
      count = 0;
      return;
    }

    if (!event.collapsible) count = 0;

    if (event.collapsible && count < 2) {
      event.collapsible = false;
      count++;
    }
  });

  let visibleEvents = events;
  if (visibleEvents.length > 5 && !expanded) {
    visibleEvents = events.filter((event, i) => !isTimelineObject(event) || !event.collapsible || i < 5);
  }

  const hasCollabsibleEvents = visibleEvents.length < events.length;

  return (
    <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-2">
      {visibleEvents.map(event => {
        if (isTimelineObject(event)) {
          return <TimelineEventListItem key={event.id} {...event} />;
        } else {
          return (
            <div key={event.key} className="-ml-2 my-6">
              {event}
            </div>
          );
        }
      })}
      {hasCollabsibleEvents && (
        <TimelineEventListItem
          message="<a href='#' onclick='return false;'>Show more activity</a>"
          icon="double-caret-vertical"
          onClick={() => setExpanded(true)}
        />
      )}
    </ol>
  );
};
