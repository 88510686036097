import React, { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Drawer, Button } from '@blueprintjs/core';

import { Loading } from 'app/atoms/Loading/Loading';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { AwardSearchQuery } from 'app/organisms/AwardSearchQuery/AwardSearchQuery';
import { AwardSearchFilters } from 'app/organisms/AwardSearchFilters/AwardSearchFilters';
import { useInitializeAwardSearchCache } from 'app/hooks/search/useAwardSearchCache';
import { SearchFilterContainer } from 'app/molecules/SearchFilterContainer/SearchFilterContainer';

export const AwardSearch = () => {
  useInitializeAwardSearchCache();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return (
    <>
      <Main className="py-0" gridClassName="lg:grid-cols-[360px,repeat(11,1fr)] 2xl:grid-cols-[420px,repeat(11,1fr)]">
        <SearchFilterContainer>
          <AwardSearchFilters />
        </SearchFilterContainer>

        <MainColumn tag="section" className="pt-4 pb-32 lg:col-span-11">
          <div className="p-6 lg:p-0">
            <Button
              onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
              className="mb-8 lg:hidden"
              text="Edit Filters"
              rightIcon="chevron-right"
              large
            />
            <AwardSearchQuery />
          </div>
          <Suspense fallback={<Loading type="card-list" />}>
            <Outlet />
          </Suspense>
        </MainColumn>
      </Main>
      <Drawer
        isOpen={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        size="100%"
        position="left"
        title=""
        className="overflow-scroll"
      >
        <AwardSearchFilters wrapperClassName="h-full overflow-y-auto" className="pb-12" />
      </Drawer>
    </>
  );
};
