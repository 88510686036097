import { useGetContractVehiclesQuery } from 'api/contractVehiclesApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { ContractVehicle } from 'types/__generated__/GovlyApi';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';

export type ContractVehicleItem = ContractVehicle;

export const useContractVehicleMultiSelect: InputMultiSelectHook<ContractVehicleItem> = () => {
  const { data = [], isLoading } = useGetContractVehiclesQuery({ filter: 'searchable' });
  const contractVehicles = data as ContractVehicle[];
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const items = contractVehicles.filter(({ public: isPublic }) => {
    if (currentUser?.subscriptionStatus === 'free') return isPublic;

    return true;
  });

  return { data: items, isLoading: isLoading || currentUserLoading };
};
