import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useGetVendorQuery } from 'api/vendorsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { VendorSummaryForm } from 'app/organisms/VendorSummaryForm/VendorSummaryForm';
import { VendorSysAdminForm } from 'app/organisms/VendorSysAdminForm/VendorSysAdminForm';
import { VendorResellerForm } from 'app/organisms/VendorResellerForm/VendorResellerForm';
import { VendorResellersFormList } from 'app/organisms/VendorResellersFormList/VendorResellersFormList';
import { VendorChannelPartnerForm } from 'app/organisms/VendorChannelPartnerForm/VendorChannelPartnerForm';
import { VendorChannelPartnersFormList } from 'app/organisms/VendorChannelPartnersFormList/VendorChannelPartnersFormList';
import { NonIdealState } from '@blueprintjs/core';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { VendorContractHoldersFormList } from 'app/organisms/VendorContractHoldersFormList/VendorContractHoldersFormList';
import { VendorContractHolderForm } from 'app/organisms/VendorContractHolderForm/VendorContractHolderForm';
import { useCreateProfileClaimMutation, useGetProfileClaimQuery } from 'api/profileClaimsApi';

export const VendorProfileCustomizationForm = () => {
  const [params, setParams] = useSearchParams();
  const claimRequest = params.get('claim_request');
  const vendorIdParam = params.get('vendor_id');

  const [createProfileClaim, { isUninitialized, isLoading: creatingProfileClaim }] = useCreateProfileClaimMutation();
  const { isLoading: organizationLoading, data: { id: organizationId, vendorProfileRequested, vendorId } = {} } =
    useGetCurrentOrganizationQuery();

  useEffect(() => {
    if (vendorIdParam && claimRequest && isUninitialized && !vendorProfileRequested) {
      createProfileClaim({
        claimableId: vendorIdParam,
        claimableType: 'Vendor'
      });
      setParams({}, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized, createProfileClaim, claimRequest]);

  const {
    data: vendor,
    isLoading: vendorLoading,
    isError: vendorLoadingError
  } = useGetVendorQuery({ id: vendorId ?? '' }, { skip: !vendorId });

  const { data: profileClaim, isLoading: profileClaimLoading } = useGetProfileClaimQuery(
    { id: `${vendorProfileRequested}` },
    {
      skip: !vendorProfileRequested
    }
  );

  const sysAdmin = useAuthorized({ role: 'sys_admin' });

  if (organizationLoading || vendorLoading || creatingProfileClaim || profileClaimLoading) return <Loading />;

  if (vendorLoadingError) {
    return <NotFound />;
  }

  if (vendorProfileRequested && !vendor) {
    return (
      <Card title="Vendor Profile">
        <CardBody>
          ✅ Your request to claim{' '}
          <LinkTag
            target="_blank"
            to={`/vendors/${profileClaim?.claimable?.slug}`}
          >{`${profileClaim?.claimable?.name}'s Vendor Profile`}</LinkTag>{' '}
          has been submitted. You will be notified when it has been approved.
        </CardBody>
      </Card>
    );
  }

  if (!vendor) {
    return (
      <Card title="Vendor Profile">
        <CardBody>
          <NonIdealState
            icon="folder-open"
            title="No Vendor Profile Has Been Claimed"
            description={
              <div>
                {`If you would like to edit your vendor profile, you can `}
                <LinkTag to="/vendors">search for the profile</LinkTag>
                {` and request to claim it. If you cannot find your
                profile, please reach out to govly support.`}
              </div>
            }
          />
        </CardBody>
      </Card>
    );
  }

  const id = vendor?.id || 'new';
  const newRecord = id === 'new';
  return (
    <>
      <VendorSummaryForm id={id} organizationId={organizationId} />
      {!newRecord && (
        <Card title="Resellers">
          <VendorResellersFormList vendorId={id} />
          <VendorResellerForm vendorId={id} />
        </Card>
      )}
      {!newRecord && (
        <Card title="Channel Partners">
          <VendorChannelPartnersFormList vendorId={id} />
          <VendorChannelPartnerForm vendorId={id} />
        </Card>
      )}
      {!newRecord && (
        <Card title="Prime Contracts">
          <VendorContractHoldersFormList vendorId={id} />
          <VendorContractHolderForm vendorId={id} initialValues={{ vendorId: id }} />
        </Card>
      )}
      {!newRecord && sysAdmin && <VendorSysAdminForm id={id} />}
    </>
  );
};
