import React, { useMemo, useState } from 'react';
import { Button, Dialog, Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import orderBy from 'lodash-es/orderBy';

import { FollowDisplayable } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedQuery } from 'api/providerFeedsApi';

import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { ContractVehicleForm } from 'app/organisms/ContractVehicleForm/ContractVehicleForm';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

type Solicitor = FollowDisplayable['organizationUser'] & { followState: string };
const columnHelper = createColumnHelper<Solicitor>();

export const ManagedFeedSolicitors = ({ id }: { id: string }) => {
  const [openFollowsForm, setOpenFollowsForm] = useState(false);
  const { data: { follows } = {}, isLoading } = useGetProviderFeedQuery({ id });

  const solicitors = useMemo(
    () =>
      orderBy(follows, ['stateInteger', 'organizationUser.organizationName', 'organizationUser.name']).map(
        ({ organizationUser, state }) =>
          ({
            ...organizationUser,
            followState: state
          }) as Solicitor
      ),
    [follows]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Solicitor',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          const disinterested = ['disinterested', 'unfollowed'].includes(e.row.original.followState);
          return (
            <>
              <AvatarWithName
                initials={e.row.original.initials}
                imgSrc={e.row.original.avatar?.thumbUrl}
                avatarColor={e.row.original.avatarColor}
                id={e.row.original.id}
                email={e.row.original.email}
                name={e.row.original.name}
                notificationProps={{
                  intent: disinterested ? 'danger' : 'success',
                  icon: disinterested ? 'thumbs-down' : 'thumbs-up',
                  size: 'xl',
                  position: 'bottom'
                }}
              />
              <Tag minimal className="mt-2">
                {e.row.original.organizationName}
              </Tag>
            </>
          );
        }
      })
    ],
    []
  );

  const tableData = useMemo(() => solicitors, [solicitors]);

  if (isLoading || !follows?.length) return null;

  return (
    <>
      <GovlyTable
        id="managed_feed_solicitors_table"
        columns={columns}
        data={tableData}
        title={<GovlyTableTitle title="Solicitors" />}
        subtitle="Solicitors are users who can post opportunities to this feed"
        rightElement={
          <GovlyTableToolbar>
            <Button icon="plus" intent="success" onClick={() => setOpenFollowsForm(true)}>
              Add/Remove Solicitors
            </Button>
          </GovlyTableToolbar>
        }
        isLoading={isLoading}
        initialState={{
          pagination: { pageSize: 5 }
        }}
      />

      <Dialog isOpen={openFollowsForm} onClose={() => setOpenFollowsForm(false)} className="min-w-[50%] p-0">
        <ContractVehicleForm follows={follows} initialValues={{ id }} onCancel={() => setOpenFollowsForm(false)} />
      </Dialog>
    </>
  );
};
