import React, { useEffect, useMemo } from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { Formik, Form } from 'formik';

import { cn } from 'app/lib/cn';

import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { successToast, errorToast } from 'app/lib/toaster';
import { WorklistFormDialog } from 'app/organisms/WorklistFormDialog/WorklistFormDialog';

import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

import { hasComments, hasMentions, hasOppActionTargetType, hasReactions } from 'app/lib/activityMessageDecorator';

import { useWorklist } from './useWorklist';

export const WorklistWorkspaces = () => {
  const {
    workspaces,
    columns,
    updateReward,
    worklistOwnerId,
    bulkUpdateOppWorkspaces,
    setWorkspaceFormAttrs,
    workspaceFormAttrs,
    selectedRows,
    setSelectedRows,
    filterInTransition,
    setFilterTransition,
    setWorkspaceFilter,
    workspacesFetching,
    workspacesLoading,
    isUpdatingWorklistState,
    workspaceFilter,
    columnVisibility,
    team,
    setWorklistOwnerId,
    teamLoading,
    viewer,
    viewingOwnWorklist,
    onActiveFilter,
    onInactiveFilter
  } = useWorklist();

  const worklistOptions = [
    ...team.map(({ email, id }) => ({
      value: id,
      label: viewer.id === id ? 'My Worklist' : email
    }))
  ];
  worklistOptions.splice(1, 0, { value: 'all', label: 'Team Worklist' });

  useEffect(() => {
    if (workspacesFetching === false) setFilterTransition(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspacesFetching]);

  const OrgUserSelectInput = () => {
    return (
      <Formik
        initialValues={{
          orgUserId: worklistOwnerId
        }}
        onSubmit={({ orgUserId }) => {
          setFilterTransition(true);
          setWorklistOwnerId(orgUserId);
        }}
      >
        <Form
          // Override heading style from table h2 title
          className="font-normal text-sm"
        >
          <SelectInput
            filterable={true}
            searchKeys={['label']}
            loading={teamLoading}
            disabled={teamLoading}
            contentClassName="mt-0"
            name="orgUserId"
            items={worklistOptions}
            submitOnChange
            buttonProps={{
              small: true,
              outlined: true,
              icon: 'person'
            }}
          />
        </Form>
      </Formik>
    );
  };

  const RightElement = () => {
    return !Object.keys(selectedRows).length ? (
      <Formik
        initialValues={{ selected: workspaceFilter }}
        onSubmit={({ selected }) => {
          setFilterTransition(true);
          setWorkspaceFilter(selected);
        }}
      >
        <Form>
          <SelectInput
            contentClassName="m-0"
            name="selected"
            items={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' }
            ]}
            searchKeys={['label']}
            filterable={false}
            buttonProps={{
              small: true,
              outlined: true,
              icon: 'filter'
            }}
            submitOnChange
          />
        </Form>
      </Formik>
    ) : (
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-500">{selectedRows.length} selected</span>
        <Tooltip content={'Remove the selected from your worklist and stop their notifications'}>
          <Button
            outlined
            intent="danger"
            text="Stop Following"
            onClick={async () => {
              try {
                await bulkUpdateOppWorkspaces({ unfollow: true, ids: Object.keys(selectedRows) }).unwrap();
                setSelectedRows({});
                successToast();
              } catch (e) {
                errorToast(e);
              }
            }}
          />
        </Tooltip>
        <Tooltip
          content={
            onInactiveFilter
              ? 'Move back to your active worklist.'
              : "You'll still have access in your worklist, under the inactive filter."
          }
        >
          <Button
            icon={onInactiveFilter ? 'undo' : 'tick'}
            intent={onInactiveFilter ? 'warning' : 'success'}
            text={onInactiveFilter ? 'Mark as Active' : 'Check off my list'}
            loading={isUpdatingWorklistState}
            onClick={async () => {
              try {
                await bulkUpdateOppWorkspaces({
                  removeFromWorklist: onActiveFilter,
                  ids: Object.keys(selectedRows)
                });
                setSelectedRows({});
                if (onActiveFilter) {
                  updateReward();
                }
              } catch (e) {
                errorToast(e);
              }
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const emptyStateProps = useMemo(() => {
    return workspaceFilter === 'active'
      ? {
          icon: (
            <span role="img" aria-label="check icon">
              {['✅', '🎉', '👏', '👍'][Math.floor(Math.random() * 4)]}
            </span>
          ),
          title: ['All done here', 'Inbox zero', 'Nice work', 'Keep it up'][Math.floor(Math.random() * 4)],
          description: 'Not seeing what you expect? Try using the filter dropdown in the top right corner.'
        }
      : {
          icon: 'inbox' as const,
          title: 'Nothing has been checked off your Worklist',
          description: 'Not seeing what you expect? Try using the filter dropdown in the top right corner.'
        };
  }, [workspaceFilter]);

  return (
    <>
      <GovlyTable
        id="worklist_workspaces"
        columns={columns}
        data={workspaces}
        title={<GovlyTableTitle title={<OrgUserSelectInput />} />}
        getRowProps={row => {
          const { updates = [], expiringSoon } = row.original;
          return {
            className: cn({
              'border-l-4 border-l-green-500': updates.length > 0,
              'border-l-4 border-l-orange-200':
                hasOppActionTargetType(updates) || hasComments(updates) || hasReactions(updates),
              'border-l-4 border-l-red-400': expiringSoon || (viewingOwnWorklist && hasMentions(updates, viewer.email))
            })
          };
        }}
        rightElement={
          <GovlyTableToolbar>
            <RightElement />
          </GovlyTableToolbar>
        }
        isLoading={workspacesLoading || (workspacesFetching && filterInTransition)}
        getRowId={row => row.id}
        onRowSelectionChange={setSelectedRows}
        state={{ columnVisibility, rowSelection: selectedRows }}
        initialState={{
          sorting: [{ id: 'respondBy', desc: workspaceFilter === 'inactive' }],
          pagination: { pageSize: 50 }
        }}
        emptyStateProps={emptyStateProps}
      />

      {viewingOwnWorklist && (
        <WorklistFormDialog
          workspaces={workspaces}
          setWorkspaceFormAttrs={setWorkspaceFormAttrs}
          workspaceFormAttrs={workspaceFormAttrs}
        />
      )}
    </>
  );
};
