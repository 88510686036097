export type ReportSlice = 'noSlice' | 'setAsideSlice' | 'feedSlice' | 'feedSetAsideSlice' | 'labelSlice' | 'userSlice';

export type ReportSliceItem = {
  key: ReportSlice;
  label: string;
};

export const useReportSlices: () => ReportSliceItem[] = () => {
  return [
    { key: 'noSlice', label: 'No Slice' },
    { key: 'setAsideSlice', label: 'Set Asides' },
    { key: 'feedSlice', label: 'Feeds' },
    { key: 'feedSetAsideSlice', label: 'Feeds & Set Asides' },
    { key: 'labelSlice', label: 'Labels' }
  ];
};
