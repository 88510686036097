import React from 'react';
import { Tag } from '@blueprintjs/core';

import { Avatar } from 'app/molecules/Avatar/Avatar';
import { initialFromName } from 'app/lib/contact';

type ContactIdTagProps = {
  email: string;
  name?: string;
  hideAvatar?: boolean;
};

export const ContactIdTag = ({ email, name, hideAvatar = false }: ContactIdTagProps) => {
  const initials = name ? initialFromName(name) : undefined;

  return (
    <div className="flex items-center">
      {!hideAvatar && (
        <>
          <Avatar initials={initials} icon="person" className="bg-gray-500" />
        </>
      )}
      <div className="ml-2">
        <div className="flex items-baseline">
          <Tag>{email}</Tag>
        </div>
        {name && <p className="mb-0 mt-1 font-medium text-gray-500">{name}</p>}
      </div>
    </div>
  );
};
