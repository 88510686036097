import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSetSavedSearchIdParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSavedSearchIdParam = useCallback(
    (savedSearchId: string) => {
      searchParams.set('savedSearchId', savedSearchId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return setSavedSearchIdParam;
};
