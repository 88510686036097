import {
  Vendor,
  VendorShow,
  VendorContractHolders,
  VendorViewerContext,
  VendorSpending
} from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Vendors'] });

const invalidatesTags = <Arg extends Partial<{ id: string }> | undefined>(result: unknown, err: unknown, arg: Arg) =>
  arg?.id ? [{ type: 'Vendors' as const, id: arg.id }] : [];

type CreateVendorParams = Partial<Omit<VendorShow, 'id' | 'certifications' | 'setAsides'>> & {
  certifications?: string[];
  setAsides?: string[];
};

type GetVendors = {
  params: {
    view?: string;
  } | void;
  result: Vendor[];
};

type GetVendor = {
  params: {
    id: string;
    organizationId?: string;
    duns?: string;
    uei?: string;
    view?: 'viewer_context' | 'contract_holders' | 'spending';
  };
  result: Vendor | VendorShow | VendorViewerContext | VendorContractHolders | VendorSpending;
};

type UpdateVendor = {
  params: CreateVendorParams & { id: string };
  result: Vendor;
};

type CreateVendor = {
  params: CreateVendorParams;
  result: Vendor;
};

type DeleteVendor = {
  params: string;
  result: void;
};

type ScrapeVendor = {
  params: {
    ids: string[];
    scrapeMethod: 'scrape_us_spending';
  };
  result: void;
};

export const vendorsApi = api.injectEndpoints({
  endpoints: build => ({
    getVendors: build.query<GetVendors['result'], GetVendors['params']>({
      query: params => ({
        url: '/v2/vendors',
        params: params || {}
      }),
      providesTags: ['Vendors'],
      keepUnusedDataFor: 300
    }),

    getVendor: build.query<GetVendor['result'], GetVendor['params']>({
      query: ({ id, ...params }) => ({
        url: `/v2/vendors/${id}`,
        params: { view: 'show', ...params }
      }),
      providesTags: invalidatesTags,
      keepUnusedDataFor: 300
    }),

    updateVendor: build.mutation<UpdateVendor['result'], UpdateVendor['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/vendors/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags
    }),

    createVendor: build.mutation<CreateVendor['result'], CreateVendor['params']>({
      query: body => ({
        url: `/v2/vendors`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Vendors']
    }),

    deleteVendor: build.mutation<DeleteVendor['result'], DeleteVendor['params']>({
      query: id => ({
        url: `/v2/vendors/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Vendors']
    }),

    scrapeVendor: build.mutation<ScrapeVendor['result'], ScrapeVendor['params']>({
      query: body => ({
        url: `/v2/vendors/scrape`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetVendorsQuery,
  useGetVendorQuery,
  useUpdateVendorMutation,
  useCreateVendorMutation,
  useDeleteVendorMutation,
  useScrapeVendorMutation
} = vendorsApi;
