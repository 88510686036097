import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, AnchorButton, Tag } from '@blueprintjs/core';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { useGetWebhookQuery } from 'api/webhooksApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { DescriptionListLabel } from 'app/molecules/DescriptionList/DescriptionList';
import { WebhookForm } from 'app/organisms/WebhookForm/WebhookForm';
import { WebhookEvents } from 'app/organisms/WebhookEvents/WebhookEvents';
import { JsonViewer } from 'app/molecules/JsonViewer/JsonViewer';

export const WebhookPage = () => {
  const [action, setAction] = useState<'edit' | 'delete' | undefined>(undefined);
  const { id } = useParams();
  const { data: webhook, isLoading } = useGetWebhookQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card-in-main" />;
  if (!id || !webhook) return <NotFound />;

  const onCancel = () => setAction(undefined);

  const breadcrumbs = [
    { text: 'API Settings', to: '/settings/api_settings' },
    { text: 'Webhooks' },
    { text: webhook.url }
  ];

  return (
    <Main>
      <MainColumn>
        <Breadcrumbs items={breadcrumbs} />
        <Card
          title="Webhook Details"
          rightElement={
            <div className="space-x-2">
              <AnchorButton onClick={() => setAction('edit')} intent="primary">
                Edit
              </AnchorButton>
              <AnchorButton onClick={() => setAction('delete')} intent="danger" minimal>
                Delete
              </AnchorButton>
            </div>
          }
        >
          <CardBody>
            <dl className="grid grid-cols-1 gap-y-4">
              <DescriptionListField label="Url" value={webhook.url} cols={1} />
              <DescriptionListField
                label="Enabled?"
                value={
                  <Tag minimal intent={webhook.enabled ? 'success' : 'danger'}>
                    {webhook.enabled.toString().toUpperCase()}
                  </Tag>
                }
                cols={1}
              />

              <div>
                <DescriptionListLabel>Subscriptions</DescriptionListLabel>
                <div className="mt-1 flex flex-wrap gap-2">
                  {webhook.subscriptions.map(s => (
                    <Tag key={s}>{s}</Tag>
                  ))}
                </div>
              </div>

              <div>
                <DescriptionListLabel>Custom Headers</DescriptionListLabel>
                <div className="prose prose-sm mt-1">
                  <pre>
                    <JsonViewer json={JSON.parse(webhook.customHeaders)} />
                  </pre>
                </div>
              </div>

              <div>
                <DescriptionListLabel>Custom Payload</DescriptionListLabel>

                <div className="prose prose-sm mt-1">
                  <pre>
                    <JsonViewer json={JSON.parse(webhook.customPayloadTemplate)} />
                  </pre>
                </div>
              </div>
            </dl>
          </CardBody>
        </Card>
        <WebhookEvents webhookId={id} />
        <Dialog isOpen={!!action} onClose={onCancel} className="p-0" lazy>
          {<WebhookForm id={id} onCancel={onCancel} action={action} />}
        </Dialog>
      </MainColumn>
    </Main>
  );
};
