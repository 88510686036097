import React, { createContext, useContext, useRef } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

type State = {
  commentId: string;
  isOpen: boolean;
};

type Action = {
  setIsOpen: (isOpen: boolean) => void;
};

type ReactionsStore = State & Action;

type ReactionsStoreProps = {
  commentId: string;
};

export const createReactionsStore = ({ commentId }: ReactionsStoreProps) =>
  createStore<ReactionsStore>()(set => ({
    commentId,
    isOpen: false,
    setIsOpen: isOpen => set({ isOpen })
  }));

export const ReactionsStoreContext = createContext<ReturnType<typeof createReactionsStore> | null>(null);

export const ReactionsStoreProvider = ({ commentId, children }: { commentId: string; children: React.ReactNode }) => {
  const store = useRef(createReactionsStore({ commentId })).current;

  return <ReactionsStoreContext.Provider value={store}>{children}</ReactionsStoreContext.Provider>;
};

export function useReactionsStoreRef(): StoreApi<ReactionsStore> {
  const store = useContext(ReactionsStoreContext);
  if (!store) {
    throw new Error('useReactionsStore must be used within a ReactionsStoreProvider');
  }
  return store;
}

export function useReactionsStore<T = ReactionsStore>(selector: (state: ReactionsStore) => T): T {
  return useStore(useReactionsStoreRef(), selector);
}
