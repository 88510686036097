import Avatar from 'app/molecules/Avatar/Avatar';
import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type UserMessageProps = {
  content: string;
  initials?: string;
};

const UserMessage = ({ content, initials }: UserMessageProps) => {
  return (
    <div className="h-fit flex mt-4">
      <span className="p-2 pb-0 rounded inline-block max-w-[80%] bg-blue-100 ml-auto">
        <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
      </span>
      <Avatar initials={initials} size="sm" color="bg-blue-700" className="ml-2 static" />
    </div>
  );
};

export { UserMessage };
