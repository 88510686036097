import { create } from 'zustand';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { DateTime } from 'luxon';

type Agg = {
  ranges: {
    buckets: {
      key: string;
      to: number;
      docCount: number;
    }[];
  };
};

export type AggregationQueryResponse = {
  aggs?: {
    upcomingExpirationAgg: Agg;
  };
};

export type Category = 'Today' | 'Tomorrow' | 'Next 7 Days' | 'Next 30 Days';

type State = {
  isDrawerOpen: boolean;
  lastClickedPoint?: Highcharts.Point;
};

type Action = {
  setIsDrawerOpen: (isDrawerOpen: State['isDrawerOpen']) => void;
};

type Store = State & Action;

export const useOppSearchAnalyticsUpcomingExpirationStore = create<Store>(set => {
  return {
    lastClickedPoint: undefined,
    isDrawerOpen: false,

    setIsDrawerOpen: isDrawerOpen => set({ isDrawerOpen })
  };
});

const dateRangeFromCategory = (category: Category) => {
  const current = DateTime.utc();

  const categories: Record<Category, [DateTime, DateTime]> = {
    Today: [current.startOf('day'), current.endOf('day')],
    Tomorrow: [current.plus({ hours: 24 }).startOf('day'), current.plus({ hours: 24 }).endOf('day')],
    'Next 7 Days': [current.startOf('day'), current.plus({ days: 7 }).endOf('day')],
    'Next 30 Days': [current.startOf('day'), current.plus({ days: 30 }).endOf('day')]
  };

  const range = categories[category];

  return range.map(r => r.toJSDate()) as [Date, Date];
};

export const makeDrilldownFiltersSelector = (existing: Partial<OppSearchState['filters']>) => (store: Store) => {
  const point = store.lastClickedPoint;
  if (!point) return {};

  const range = dateRangeFromCategory(point.name as Category);

  const dateFilter: Partial<OppSearchState['filters']> = {
    secondaryDateRanges: [...(existing.secondaryDateRanges ?? []), { param: 'respond_by', range }],
    status: ['not_cancelled']
  };

  return dateFilter;
};
