import React from 'react';
import { Tag, Spinner, Intent } from '@blueprintjs/core';
import { SouptoolsTrSubmission } from 'types/__generated__/GovlyApi';

export const SouptoolsTrSubmissionStatusTag = ({
  trSubmission: { status }
}: {
  trSubmission: SouptoolsTrSubmission;
}) => {
  let intent: Intent;
  let text;

  if (status === 'failed') {
    intent = 'danger';
    text = 'Submission Failed';
  } else if (status === 'submitting') {
    intent = 'primary';
    text = 'Submitting';
  } else if (status === 'scheduled') {
    intent = 'warning';
    text = 'Submission Scheduled';
  } else if (status === 'submitted') {
    intent = 'success';
    text = 'Submitted';
  } else if (status === 'canceled') {
    intent = 'danger';
    text = 'Submission Canceled';
  } else {
    return null;
  }

  return (
    <Tag minimal intent={intent}>
      <div className="flex">
        {status === 'submitting' && <Spinner className="mr-1" intent={intent} size={12} />}
        {text}
      </div>
    </Tag>
  );
};
