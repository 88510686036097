import React from 'react';
import { RadioGroup } from '@blueprintjs/core';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { FormikToBp } from 'app/lib/formikToBp';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import enUS from 'date-fns/locale/en-US';
import { addYears } from 'date-fns';
import { mixedDateShortcuts } from 'app/lib/dates';

export const AwardSearchFiltersDateRange = () => {
  const getFilterCardProps = useGetFormikFilterCardListItemProps();
  const getField = useGetFormikField<AwardSearchForm>();

  return (
    <InputFilterCardListItem
      title="Date Range"
      defaultIsOpen
      {...getFilterCardProps({ names: ['filters.dateRange'], submitOnChange: true })}
    >
      <RadioGroup
        inline
        options={[
          { label: 'Action Date', value: 'action_date' },
          { label: 'Start Date', value: 'period_of_performance_start_date' },
          { label: 'End Date', value: 'period_of_performance_current_end_date' }
        ]}
        {...FormikToBp.toRadioGroup(getField('filters.dateRangeParam'))}
      />
      <DateRangeInput3
        {...FormikToBp.toDateRange(getField('filters.dateRange'))}
        shortcuts={mixedDateShortcuts()}
        locale={enUS}
        fill
        startInputProps={{ placeholder: 'From' }}
        endInputProps={{ placeholder: 'To' }}
        maxDate={addYears(new Date(), 5)}
      />
    </InputFilterCardListItem>
  );
};
