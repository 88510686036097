import React, { useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Dialog, Icon, Tag, AnchorButton } from '@blueprintjs/core';
import truncate from 'lodash-es/truncate';

import { Webhook } from 'types/__generated__/GovlyApi';
import { useGetWebhooksQuery } from 'api/webhooksApi';

import { WebhookForm } from 'app/organisms/WebhookForm/WebhookForm';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<Webhook>();

export const WebhooksTable = () => {
  const [creatingWebhook, setCreatingWebhook] = useState(false);
  const { data: webhooks = [], isLoading } = useGetWebhooksQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor('url', {
        header: 'Url',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          const text = truncate(e.row.original.url.replace(/^https?:\/\//, ''), { length: 45 });
          return (
            <LinkTag
              to={`/webhooks/${e.row.original.id}`}
              state={{
                breadcrumbs: [{ to: '/settings/webhooks', text: 'Webhooks' }, { text }]
              }}
            >
              {text}
            </LinkTag>
          );
        }
      }),
      columnHelper.accessor('subscriptions', {
        header: 'Subscriptions',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <div className="space-x-2">
            {e.row.original.subscriptions.map((subscription, index) => (
              <Tag key={index} minimal intent="success">
                {subscription}
              </Tag>
            ))}
          </div>
        )
      }),
      columnHelper.accessor('enabled', {
        header: 'Enabled?',
        enableColumnFilter: false,
        enableSorting: false,
        cell: e =>
          e.row.original.enabled ? <Icon icon="tick" intent="success" /> : <Icon icon="cross" intent="danger" />
      })
    ],
    []
  );

  return (
    <>
      <GovlyTable
        id="webhooks_table"
        columns={columns}
        data={webhooks}
        title={<GovlyTableTitle title="Webhooks" />}
        rightElement={
          <GovlyTableToolbar>
            <AnchorButton key="create-webhook" onClick={() => setCreatingWebhook(true)} intent="success" icon="plus">
              Add Webhook
            </AnchorButton>
          </GovlyTableToolbar>
        }
        isLoading={isLoading}
        initialState={{ pagination: { pageSize: 50 } }}
        emptyStateProps={{
          icon: undefined,
          title: 'No Webhooks',
          description: 'You have not created any webhooks.'
        }}
      />
      <Dialog isOpen={creatingWebhook} onClose={() => setCreatingWebhook(false)} className="p-0" lazy>
        <WebhookForm id="new" onCancel={() => setCreatingWebhook(false)} />
      </Dialog>
    </>
  );
};
