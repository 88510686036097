import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { useField } from 'formik';

import { cn } from 'app/lib/cn';
import {
  RadioGroupStackedCards,
  RadioGroupStackedCardsProps
} from 'app/atoms/RadioGroupStackedCards/RadioGroupStackedCards';

export type RadioGroupStackedCardsInputProps = {
  name: string;
} & Omit<RadioGroupStackedCardsProps, 'onChange'>;

export const RadioGroupStackedCardsInput = ({ name, className, ...rest }: RadioGroupStackedCardsInputProps) => {
  const [field, meta, { setValue }] = useField({ name });
  const error = meta.touched && meta.error;

  return (
    <FormGroup className={cn('m-0', className)}>
      <RadioGroupStackedCards
        {...rest}
        name={name}
        onChange={e => setValue(e.currentTarget.value)}
        selectedValue={field.value}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
