import React from 'react';
import { Tag, TagProps } from '@blueprintjs/core';
import { getDynamicTextColor } from 'app/lib/colors';

type LabelTagProps = {
  color: string;
  name: string;
} & TagProps;

export const LabelTag = ({ color, name, ...rest }: LabelTagProps) => {
  return (
    <Tag round style={{ color: getDynamicTextColor(color), backgroundColor: color }} {...rest}>
      {name}
    </Tag>
  );
};
