import React from 'react';
import { SegmentedControl } from '@blueprintjs/core';
import { useFormikContext } from 'formik';

import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { OppSearchState, DateRangeParam } from 'app/hooks/search/useOppSearchCache';
import { sixMonthsAgo, oneYearFromNow, todayEnd } from 'app/lib/dates';

type ForecastStatuses = 'not_forecasted' | 'forecasted';

const filtersAfterChange = (status: ForecastStatuses) => {
  const filters = {
    recordType: [],
    recordTypeNone: [],
    noticeType: [],
    noticeTypeNone: [],
    forecastStatus: status
  };

  if (status === 'forecasted') {
    return {
      ...filters,
      dateRangeParam: 'respond_by' as DateRangeParam,
      dateRange: [sixMonthsAgo(), oneYearFromNow()] as [Date, Date]
    };
  }

  return {
    ...filters,
    dateRangeParam: 'posted_at' as DateRangeParam,
    dateRange: [sixMonthsAgo(), todayEnd()] as [Date, Date]
  };
};

export const OppSearchForecastFilter = () => {
  const getField = useGetFormikField<OppSearchState>();
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();

  return (
    <SegmentedControl
      {...FormikToBp.toSegmentedControl(getField('filters.forecastStatus'))}
      fill
      onValueChange={value => {
        setValues({
          ...values,
          filters: {
            ...values.filters,
            ...filtersAfterChange(value as ForecastStatuses)
          }
        });
        submitForm();
      }}
      options={[
        { value: 'not_forecasted', label: 'Published' },
        { value: 'forecasted', label: 'Forecasted' }
      ]}
    />
  );
};
