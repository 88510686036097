import React from 'react';
import { OppSearchAnalytics } from 'app/organisms/OppSearchAnalytics/OppSearchAnalytics';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const OppSearchAnalyticsPage = () => {
  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']}>
      <OppSearchAnalytics />
    </SubscriptionRequiredPageWrapper>
  );
};
