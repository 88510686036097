import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { successToast } from 'app/lib/toaster';
import { useToggleDeactivatedFeedsMutation } from 'api/deactivatedFeedsApi';

type PublicFeedDeactivationButtonProps = {
  isDisabled?: boolean;
  activated: boolean;
  feedId: string;
};

export const PublicFeedDeactivationButton = ({ activated, isDisabled, feedId }: PublicFeedDeactivationButtonProps) => {
  const [toggleDeactivatedFeed, { isLoading }] = useToggleDeactivatedFeedsMutation();

  const toggleActivated = async ({
    activated,
    feedId
  }: Pick<PublicFeedDeactivationButtonProps, 'activated' | 'feedId'>) => {
    await toggleDeactivatedFeed({ feedIds: [feedId], active: !activated });
    successToast();
  };

  return (
    <Tooltip
      content={<div className="w-56">Set your preference for displaying this feed in Opportunities</div>}
      hoverOpenDelay={300}
    >
      <Button
        intent={activated ? 'success' : 'none'}
        disabled={isDisabled}
        loading={isLoading}
        onClick={() => {
          toggleActivated({ feedId, activated });
        }}
      >
        {activated ? 'Feed activated' : 'Activate feed'}
      </Button>
    </Tooltip>
  );
};
