import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { errorToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardBody } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { CheckboxInput } from 'app/atoms/inputs/CheckboxInput/CheckboxInput';
import { useRegisterMutation } from 'api/signupRequestsApi';
import { useGetOutreachRecipientByInviteTokenQuery } from 'api/outreachRecipientsApi';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address.').required('Email is required.'),
  firstName: yup.string().required('First Name is required.'),
  lastName: yup.string().required('Last Name is required.')
});

export const RegisterForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inviteToken = searchParams.get('inviteToken');

  const [register, { isLoading: isRegistering }] = useRegisterMutation();
  const { data: outreach, isLoading: isFetchingOutreach } = useGetOutreachRecipientByInviteTokenQuery(
    { inviteToken: inviteToken ?? '' },
    { skip: !inviteToken }
  );

  const initialValues = useMemo(() => {
    return {
      email: outreach?.recipientEmail ?? '',
      firstName: outreach?.firstName ?? '',
      lastName: outreach?.lastName ?? '',
      tosPpAgreed: true,
      ...(inviteToken ? { inviteToken } : {})
    };
  }, [outreach, inviteToken]);

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const { signInUrl } = await register(values).unwrap();

      if (signInUrl) {
        const { pathname, search } = new URL(signInUrl);
        navigate(pathname + search);
      } else {
        navigate('/register/verify?from=register');
      }
    } catch (error) {
      errorToast(error);
    }
  };

  // No Invite Token or Outreach Recipient found
  if (!inviteToken || (!outreach && !isFetchingOutreach)) {
    return (
      <CardBody>
        <div className="text-lg">
          <h2 className="text-3xl font-bold mb-4">Thanks for your interest!</h2>

          <p>Govly currently requires an invitation to sign up.</p>

          <p>
            If you&apos;re interested in joining, please reach out to{' '}
            <a href="mailto:support@govly.com">support@govly.com</a>
          </p>
        </div>
      </CardBody>
    );
  }

  if (isFetchingOutreach) {
    return (
      <CardBody>
        <Loading type="icon" icon="lock" />
      </CardBody>
    );
  }

  // Outreach expired
  if (outreach?.expired) {
    return (
      <CardBody>
        <div className="text-lg">
          <h2 className="text-3xl font-bold mb-2">Your invitation has expired</h2>

          <p>
            If you believe this is an error, please reach out to{' '}
            <a href="mailto:support@govly.com">support@govly.com</a>
          </p>
        </div>
      </CardBody>
    );
  }

  return (
    <CardBody>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form className="space-y-4">
          <div>
            <h2 className="text-3xl font-bold">Create an account</h2>
            <p className="mt-2 text-sm text-gray-500">
              We currently only allow new accounts to be created with a company email address.
            </p>
          </div>
          <TextInput name="email" label="Email" type="email" />
          <TextInput name="firstName" label="First Name" type="text" />
          <TextInput name="lastName" label="Last Name" type="text" />
          <Button loading={isRegistering} type="submit" intent="primary" large fill>
            Create Account
          </Button>
          <CheckboxInput
            inline
            labelElement={
              <span>
                By signing up you agree to our{' '}
                <a href="https://www.govly.com/terms" rel="noreferrer" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="https://www.govly.com/privacy" rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </span>
            }
            name="tosPpAgreed"
          />
          <div className="flex justify-end gap-x-2">
            <Link to="/sign_in">Sign in instead?</Link>
          </div>
        </Form>
      </Formik>
    </CardBody>
  );
};
