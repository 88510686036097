import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Tooltip, Tag, ControlGroup, FormGroup, InputGroup } from '@blueprintjs/core';

import { formatTime, DATETIME_24_SHORT_WITH_SECONDS } from 'app/lib/dates';
import { CopyButton } from 'app/molecules/CopyButton/CopyButton';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import {
  useUpdateFeedProfileMutation,
  useCreateFeedProfileMutation,
  useCallFeedProfileMutation
} from 'api/feedProfilesApi';
import { formErrorToast, errorToast, successToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { hasDataErrors, isFetchBaseQueryError } from 'api/utils';
import { hasId } from 'api/utils';

type FeedProfileGsaContext = {
  loginCode?: string;
  loginCodeUpdatedAt?: string;
  noBidActor?: boolean;
  noBidLogic?: {
    defaultMessage?: string;
    unavailable?: string;
  };
  preferredLoginContract?: string;
};
export const FeedProfileGsaForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const authorized = useAuthorized({ role: 'admin' });
  const sysAdmin = useAuthorized({ role: 'sys_admin' });

  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();
  const [updateFeedProfile, { isLoading: isUpdating }] = useUpdateFeedProfileMutation();
  const [createFeedProfile, { isLoading: isCreating }] = useCreateFeedProfileMutation();
  const [callFeedProfileAction, { isLoading: isCalling }] = useCallFeedProfileMutation();

  const callAction = async (values: { id: string; profileAction: 'crawl' }) => {
    try {
      await callFeedProfileAction(values).unwrap();
      successToast('Action called. Check status in Apify.');
    } catch (e) {
      if (isFetchBaseQueryError(e) && typeof e.status == 'number') {
        formErrorToast(e.status);
      } else {
        errorToast(e);
      }
    }
  };

  if (profileLoading) {
    return <Loading />;
  }

  const { feedProfiles: profiles } = currentOrg || {};
  const profile = profiles?.find(p => p.type === 'FeedProfile::Gsa');

  const { id, username, password, failedLoginAttempts = 0, inboundEmail, active, context } = profile ?? {};
  const {
    loginCode,
    loginCodeUpdatedAt,
    noBidActor,
    noBidLogic = {},
    preferredLoginContract
  } = (context as FeedProfileGsaContext | undefined) ?? {};

  const inactive = failedLoginAttempts > 3 || !active;

  let failedLoginAttemptsClass = failedLoginAttempts === 0 ? 'text-green-600' : 'text-orange-600';
  if (failedLoginAttempts > 3) failedLoginAttemptsClass = 'text-red-600';

  const validationSchema = yup.object({
    username: yup.string().required('Username is required').email('Username should be a valid email address'),
    password: yup.string().required('A Password is required to run any job')
  });

  return (
    <>
      <Breadcrumbs />
      <Formik
        onSubmit={async (values, { setErrors }) => {
          try {
            if (hasId(values)) {
              await updateFeedProfile(values).unwrap();
              successToast('Profile updated.');
            } else {
              await createFeedProfile(values).unwrap();
              successToast('Profile created.');
            }
          } catch (e) {
            if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
              if (typeof e.status === 'number') formErrorToast(e.status);
              const { data } = e;
              if (data.errors) setErrors(data.errors);
            }
          }
        }}
        initialValues={{
          id,
          type: 'FeedProfile::Gsa',
          username: username || '',
          password: password || '',
          active: active || false,
          context: {
            noBidActor: noBidActor || false,
            noBidLogic: {
              defaultMessage: noBidLogic.defaultMessage || 'Other',
              unavailable: noBidLogic.unavailable || ''
            },
            preferredLoginContract: preferredLoginContract || ''
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Card
              className="mb-4"
              title="GSA Automate Profile"
              rightElement={<Tag intent={inactive ? 'danger' : 'success'}>{inactive ? 'Disabled' : 'Active'}</Tag>}
            >
              <CardBody>
                <FormGroup labelFor="receivingEmail" label="Receiving Email">
                  <ControlGroup fill>
                    <InputGroup disabled value={inboundEmail} readOnly large />
                    <CopyButton copyText={inboundEmail ?? ''} large />
                  </ControlGroup>
                </FormGroup>
                {loginCode && (
                  <FormGroup
                    labelFor="loginCode"
                    label="Login Code"
                    helperText={`Updated: ${
                      loginCodeUpdatedAt ? formatTime(loginCodeUpdatedAt, DATETIME_24_SHORT_WITH_SECONDS) : ''
                    }`}
                  >
                    <ControlGroup fill>
                      <InputGroup disabled value={loginCode} readOnly large />
                      <CopyButton copyText={loginCode} large />
                    </ControlGroup>
                  </FormGroup>
                )}
                <SwitchInput name="active" label="Active?" />
                <TextInput label="Notification Email" name="notificationEmail" />
                <TextInput label="GSA EBuy Username" name="username" labelInfo="(required)" />
                <TextInput
                  label="GSA EBuy Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  labelInfo="(required)"
                  helperText={
                    <>
                      <p className={failedLoginAttemptsClass}>{`Failed login attempts: ${failedLoginAttempts}`}</p>
                      <span>
                        If failed login attempts exceeds 1, all jobs will be paused until the password is updated.
                      </span>
                    </>
                  }
                  inputProps={{
                    large: true,
                    rightElement: (
                      <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
                        <Button
                          icon={showPassword ? 'unlock' : 'lock'}
                          intent="warning"
                          minimal
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </Tooltip>
                    )
                  }}
                />
                <TextInput
                  label="Preferred Login Contract"
                  helperText="If your GSA account requires you to select a contract when logging in, this is the contract number the automation will choose."
                  name="context.preferredLoginContract"
                />
                <SwitchInput name="context.noBidActor" label="Automate no bids?" />
                {values.context.noBidActor && (
                  <>
                    <p>
                      <strong>No Bid Logic</strong>
                    </p>
                    <TextInput
                      label="Default No Bid Message"
                      helperText="Any opportunities not matched with specified logic with be no bid with the 'I would quote if' option selected and using your default text."
                      name="context.noBidLogic.defaultMessage"
                    />
                    <div className="bp5-form-helper-text">
                      <p>
                        {
                          'No bid logic for each type of no bid below will match words or phrases separate by |. You can also negate terms with "-", which will match the absence of a term.'
                        }
                      </p>
                      <p>Example logic: Cisco | Palo Alto | -Printers</p>
                    </div>
                    <TextAreaInput
                      name="context.noBidLogic.unavailable"
                      label="I do not sell these products or services (Example, no letter of supply)."
                    />
                  </>
                )}
              </CardBody>
              <CardFooter>
                <Button
                  large
                  type="submit"
                  text="Save"
                  disabled={!authorized}
                  loading={isCreating || isUpdating}
                  intent="primary"
                />
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>

      {sysAdmin && id && (
        <Card title="Manually Trigger Jobs" subtitle="Only system admin can see this card.">
          <CardBody>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'crawl' })}
                intent="danger"
                outlined
                large
                text="Crawl"
                disabled={isCalling}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
