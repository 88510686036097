import React from 'react';
import { Button } from '@blueprintjs/core';
import { Avatar } from 'app/molecules/Avatar/Avatar';
import { FileUploader, FileUploaderProps } from 'app/molecules/FileUploaders/FileUploader/FileUploader';

type AvatarUploaderProps = {
  id: string;
  imgSrc?: string;
  initials?: string;
  className?: string;
  onRemove?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
} & FileUploaderProps;

export const AvatarUploader = ({ id, onRemove, imgSrc, initials, className, ...rest }: AvatarUploaderProps) => {
  return (
    <div className="flex items-center space-x-4">
      <Avatar size="xl" imgSrc={imgSrc} initials={initials} className={className} />
      <FileUploader
        id={id}
        wrapperElement="div"
        className="relative"
        useUploadArgs={{ maxFiles: 1 }}
        inputProps={{
          className: 'absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md',
          id: `${id}-input`
        }}
        {...rest}
      >
        <Button text={imgSrc ? 'Change' : 'Add Image'} />
      </FileUploader>
      {imgSrc && <Button onClick={onRemove} text="Remove" minimal intent="danger" />}
    </div>
  );
};
export default AvatarUploader;
