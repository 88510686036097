import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { CardBody, CardFooter, Card } from 'app/atoms/Card/Card';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCreatePartnerPortalLinkMutation, useUpdatePartnerPortalLinkMutation } from 'api/partnerPortalLinksApi';
import { PartnerPortalLink } from 'types/__generated__/GovlyApi';

type PartnerPortalLinkFormProps = {
  partnerPortalLink: Partial<PartnerPortalLink>;
  onCancel: () => void;
};

export const PartnerPortalLinkForm = ({ partnerPortalLink: link, onCancel }: PartnerPortalLinkFormProps) => {
  const [updatePartnerPortalLink, { isLoading: isUpdating }] = useUpdatePartnerPortalLinkMutation();
  const [createPartnerPortalLink, { isLoading: isCreating }] = useCreatePartnerPortalLinkMutation();

  const isLoading = isUpdating || isCreating;

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result = link.id
            ? await updatePartnerPortalLink({ ...values, id: link.id })
            : await createPartnerPortalLink(values);
          if (result.error) throw result.error;
          setSubmitting(false);
          successToast();
          onCancel();
        } catch (e) {
          setSubmitting(false);
          errorToast(e);
        }
      }}
      initialValues={{
        label: link.label ?? '',
        url: link.url ?? '',
        description: link.description ?? ''
      }}
      validationSchema={yup.object({
        label: yup.string().required('Label is required').max(50),
        url: yup.string().required('URL is required').url('URL must be a valid URL'),
        description: yup.string()
      })}
    >
      <Form>
        <Card
          title={`${link.id ? 'Edit' : 'Create a'} portal link`}
          rightElement={<Button minimal icon="cross" onClick={onCancel} />}
        >
          <CardBody>
            <TextInput name="label" label="Link label" />
            <TextInput name="url" label="Link URL" />
            <TextAreaInput name="description" label="Description" />
          </CardBody>
          <CardFooter>
            <Button intent="primary" type="submit" loading={isLoading}>
              Save
            </Button>
            <Button onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};
