import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';

export const UserProfilePage = () => {
  return (
    <Main>
      <MainColumn>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </MainColumn>
    </Main>
  );
};
