import React from 'react';

import { useFormikFilterCardListItem } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import { useSubInputsSelected } from 'app/molecules/InputCompositeFilter/useSubInputsSelected';
import { FuzzyInputMultiSelect, FuzzyInputMultiSelectProps } from '../InputMultiSelect/FuzzyInputMultiSelect';
import { InputCompositeFilter } from './InputCompositeFilter';

type FormikCompositeMultiSelectProps<Item> = Omit<
  FuzzyInputMultiSelectProps<Item>,
  'selectedItems' | 'name' | 'onChange'
> & {
  title: string | JSX.Element;
  namesAndLabels: [string, string][];
  items: Item[];
  getInputProps?: (input: { name: string; label: string; index: number }) => Partial<FuzzyInputMultiSelectProps<Item>>;
  subInputsSelected?: boolean;
  filterCardProps?: Partial<React.ComponentProps<typeof InputFilterCardListItem>>;
};

export function FormikCompositeMultiSelect<Item>({
  title,
  namesAndLabels,
  items,
  getItemValue,
  getInputProps,
  formGroupProps,
  subInputsSelected: subInputsSelectedProp = false,
  filterCardProps: filterCardPropsProp,
  ...props
}: FormikCompositeMultiSelectProps<Item>) {
  const names = namesAndLabels.map(([n]) => n);
  const filterCardProps = useFormikFilterCardListItem({ names, submitOnChange: true });

  const getField = useGetFormikField();

  const subInputsSelected = useSubInputsSelected({ names });

  return (
    <InputFilterCardListItem title={title} {...filterCardProps} {...filterCardPropsProp}>
      <InputCompositeFilter subInputsSelected={subInputsSelected || subInputsSelectedProp}>
        {namesAndLabels.map(([name, label], index) => {
          const { formGroupProps: inputFormGroupProps, ...inputProps } = getInputProps?.({ name, label, index }) ?? {};
          return (
            <FuzzyInputMultiSelect
              key={name}
              getItemValue={getItemValue}
              items={items}
              formGroupProps={{ label, ...formGroupProps, ...inputFormGroupProps }}
              {...FormikToBp.toMultiSelect({ ...getField(name), items, getItemValue })}
              {...inputProps}
              {...props}
            />
          );
        })}
      </InputCompositeFilter>
    </InputFilterCardListItem>
  );
}
