import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { createColumnHelper } from '@tanstack/react-table';

import { Partnership, PartnershipWithFeeds } from 'types/__generated__/GovlyApi';
import { useGetPartnershipsQuery, useCreatePartnershipMutation } from 'api/partnershipsApi';
import { useGetOrganizationsQuery } from 'api/organizationsApi';

import { errorToast, successToast } from 'app/lib/toaster';

import { useAuthorized } from 'app/hooks/useAuthorize';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';

import { GrantFeedAccessButton } from 'app/organisms/GrantFeedAccessButton/GrantFeedAccessButton';
import { RevokeFeedAcessButton } from 'app/organisms/RevokeFeedAccessButton/RevokeFeedAccessButton';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<Partnership | PartnershipWithFeeds>();

type PartnershipsTableProps = {
  providerFeedPublicId?: string;
  providerFeedId?: string;
  isSharingFeeds?: boolean;
  title?: string;
  pageSize?: number;
  view?: 'with_feeds';
};

export const PartnershipsTable = ({
  providerFeedId,
  providerFeedPublicId,
  isSharingFeeds = false,
  view,
  title,
  pageSize = 25
}: PartnershipsTableProps) => {
  const [createPartnerships] = useCreatePartnershipMutation();
  const isSysAdmin = useAuthorized({ role: 'sys_admin' });
  const { data: partnerships = [], isLoading } = useGetPartnershipsQuery({ view });
  const { data: organizations = [], isLoading: organizationsLoading } = useGetOrganizationsQuery();

  const hiddenColumns = useMemo(() => {
    if (!providerFeedPublicId) return { feed: false };
    return { feed: true };
  }, [providerFeedPublicId]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('partnerName', {
        header: providerFeedPublicId ? 'Partner' : 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e =>
          isSharingFeeds ? (
            e.row.original.partnerName
          ) : (
            <LinkTag to={`/partnerships/${e.row.original.id}`}>{e.row.original.partnerName}</LinkTag>
          )
      }),
      columnHelper.display({
        id: 'feed',
        header: 'Access',
        cell: e => {
          const { id, partnerId } = e.row.original;
          if (!providerFeedId || !providerFeedPublicId) return null;

          const partnerFeeds = 'partnerFeeds' in e.row.original ? (e.row.original.partnerFeeds ?? []) : [];
          const partnerFeed = partnerFeeds.find(f => f.providerFeedId === providerFeedId && f.partnershipId === id);

          if (partnerFeed && partnerFeed.publicId) {
            return (
              <div className="space-x-4">
                <span>✅</span>
                <RevokeFeedAcessButton id={partnerFeed.publicId} providerFeedId={providerFeedId} />
              </div>
            );
          } else {
            return (
              <div className="space-x-4">
                <span>🚫</span>
                <GrantFeedAccessButton
                  partnershipId={id}
                  organizationId={partnerId}
                  providerFeedPublicId={providerFeedPublicId}
                />
              </div>
            );
          }
        }
      })
    ],
    [isSharingFeeds, providerFeedId, providerFeedPublicId]
  );

  const ToolbarComponents = () => {
    if (isSysAdmin && !organizationsLoading) {
      return (
        <Formik
          initialValues={{ organizationId: null }}
          onSubmit={async (values: { organizationId: string | null }) => {
            if (!values.organizationId) return;
            try {
              await createPartnerships({ partnerId: values.organizationId }).unwrap();
              successToast('Partnership created');
            } catch (error) {
              errorToast(error);
            }
          }}
        >
          {() => (
            <Form>
              <SelectInput
                name="organizationId"
                contentClassName="m-0"
                items={organizations.map(({ id, name }) => ({ value: id, label: name }))}
                searchKeys={['label']}
                defaultButtonText="Create Partnership"
                buttonProps={{
                  large: false
                }}
                submitOnChange
              />
            </Form>
          )}
        </Formik>
      );
    }

    return null;
  };

  const tableData = useMemo(() => partnerships, [partnerships]);

  return (
    <GovlyTable
      id="partnerships_table"
      columns={columns}
      data={tableData}
      title={<GovlyTableTitle title={title || 'Partnerships'} />}
      subtitle={!providerFeedPublicId ? 'Use partnerships to collaborate on opportunities' : undefined}
      isLoading={isLoading}
      state={{ columnVisibility: hiddenColumns }}
      rightElement={
        <GovlyTableToolbar>
          <ToolbarComponents />
        </GovlyTableToolbar>
      }
      emptyStateProps={{
        icon: 'folder-open',
        title: 'No partnerships found',
        description: 'Invite a partner to get started'
      }}
      initialState={{
        pagination: { pageSize: pageSize }
      }}
    />
  );
};
