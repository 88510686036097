import { rootApi } from './rootApi';

type Register = {
  params: {
    email: string;
    firstName: string;
    lastName: string;
    tosPpAgreed: boolean;
    inviteToken?: string;
  };
  result: {
    signInUrl?: string;
  };
};

type ConfirmEmail = {
  params: {
    token: string;
  };
  result: {
    signInUrl: string;
  };
};

export const signupRequestsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    register: build.mutation<Register['result'], Register['params']>({
      query: ({ ...body }) => ({
        url: `/v2/signup_requests`,
        method: 'POST',
        body
      })
    }),

    confirmEmail: build.mutation<ConfirmEmail['result'], ConfirmEmail['params']>({
      query: body => ({
        url: `/v2/signup_requests`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const { useRegisterMutation, useConfirmEmailMutation } = signupRequestsApi;
