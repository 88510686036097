import React from 'react';
import { Tag } from '@blueprintjs/core';
import { P, match } from 'ts-pattern';
import {
  selectedSeriesSelector,
  sliceLabelSelector,
  useOppSearchAnalyticsPostedAtStore
} from 'app/organisms/OppSearchAnalytics/useOppSearchAnalyticsPostedAtStore';

export const OppSearchAnalyticsPostedAtDrawerTitle = () => {
  const { sliceType, lastClickedPoint: point } = useOppSearchAnalyticsPostedAtStore();
  const selectedSeries = useOppSearchAnalyticsPostedAtStore(selectedSeriesSelector);
  const sliceLabel = useOppSearchAnalyticsPostedAtStore(sliceLabelSelector);

  return (
    <>
      <span>Opportunities Posted Over Time</span>

      {sliceType !== 'total' && selectedSeries.length === 1 && (
        <Tag minimal>
          {sliceLabel}: {selectedSeries[0]}
        </Tag>
      )}

      {match(point)
        .with({ x: P.not(P.nullish) }, ({ x }) => {
          const date = new Date(x);
          const utcStr = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            timeZone: 'UTC'
          }).format(date);
          return <Tag minimal>{utcStr}</Tag>;
        })
        .otherwise(() => null)}
    </>
  );
};
