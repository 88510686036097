import React, { useState } from 'react';
import { Button, Icon, Dialog, Tooltip } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { pluralize } from 'app/lib/strings';
import { H3 } from 'app/atoms/Typography/Typography';
import { MarketingCta } from 'app/molecules/MarketingCta/MarketingCta';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { ContactBase } from 'types/__generated__/GovlyApi';

type CrmContactProps = {
  contact: ContactBase;
  authorized?: boolean;
};

export const CrmContact = ({ contact, authorized }: CrmContactProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ctaModalOpen, setCtaModalOpen] = useState(false);

  const expandedContent = () => (
    <div className="lg:col-start-2">
      <div className="p-2">
        <Icon icon="envelope" />
        {authorized && <span className="ml-4">{contact.email}</span>}
      </div>
      <div className="p-2">
        {contact.phoneNumbers?.length ? <Icon icon="phone" /> : null}
        <span className="ml-2">
          {authorized &&
            contact.phoneNumbers?.map((phone, i) =>
              i === 0 ? (
                <span className="ml-2" key={phone}>
                  {phone}
                </span>
              ) : (
                <div key={phone} className="ml-8">
                  {phone}
                </div>
              )
            )}
        </span>
      </div>
    </div>
  );

  const closedContent = () => (
    <div className="lg:col-start-2">
      <div className="p-2">
        <Icon icon="envelope" className="text-blue-500" />
        <span className="ml-2">1 email found</span>
      </div>
      {contact.phoneNumbers?.length ? (
        <div className="p-2">
          <Icon icon="phone" className="text-blue-500" />
          <span className="ml-2">{`${contact.phoneNumbers.length} ${pluralize(
            'phone number',
            'phone numbers',
            contact.phoneNumbers.length
          )} found`}</span>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <div className="grid grid-cols-1 items-center justify-between gap-1 divide-y-0 px-4 py-5 md:grid-cols-3 lg:p-6">
        <div className="lg:col-start-1">
          <H3>{contact.names.length ? contact.names[0] : 'Unknown Name'}</H3>
          {contact.titles?.length ? (
            <Tooltip content={contact.titles.length > 1 ? contact.titles.join(', ') : undefined}>
              <p className={cn('font-semibold text-gray-500', { 'cursor-help': contact.titles.length > 1 })}>{`${
                contact.titles[0]
              }${contact.titles.length > 1 ? `, +${contact.titles.length - 1} more titles...` : ''}`}</p>
            </Tooltip>
          ) : null}
        </div>
        {authorized && isVisible && expandedContent()}
        {(!authorized || !isVisible) && closedContent()}
        <div className="flex flex-col items-center justify-center gap-y-2 lg:col-start-3">
          {!isVisible && (
            <Button
              icon="eye-open"
              text="View Contact"
              onClick={() => (authorized ? setIsVisible(!isVisible) : setCtaModalOpen(true))}
            />
          )}
          <span className="flex h-1/5 w-1/2 items-center justify-center gap-x-4">
            <LinkTag tag="a" href={authorized ? `mailto:${contact.email}` : undefined}>
              <Button
                intent="primary"
                icon="send-message"
                text="Send Message"
                onClick={() => !authorized && setCtaModalOpen(true)}
              />
            </LinkTag>
          </span>
        </div>
      </div>
      <Dialog isOpen={ctaModalOpen} onClose={() => setCtaModalOpen(false)} className="bg-gray-900">
        <MarketingCta variant="bullets" />
      </Dialog>
    </>
  );
};
