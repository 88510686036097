import React from 'react';

import { cn } from 'app/lib/cn';
import { Avatar } from 'app/molecules/Avatar/Avatar';
import { OrgUserWithView } from 'api/organizationUsersApi';
import { IconName } from '@blueprintjs/core';

export type UserMenuItemProps<T extends OrgUserMenuItem> = {
  item: T;
} & React.HTMLAttributes<HTMLSpanElement>;

export type OrgUserMenuItem = Pick<OrgUserWithView, 'id' | 'avatar' | 'name' | 'email' | 'avatarColor' | 'initials'> & {
  icon?: IconName;
};

export const UserMenuItem = <T extends OrgUserMenuItem>({ item, className, ...rest }: UserMenuItemProps<T>) => {
  const { avatar, name, email, avatarColor, initials, icon } = item;
  return (
    <span className={cn('items-center gap-x-2 flex', className)} {...rest}>
      <Avatar className={cn(avatarColor)} initials={initials} imgSrc={avatar?.thumbUrl} icon={icon} size="sm" />
      <div className="flex gap-x-2">
        <strong className="text-black">{name}</strong>
        <span className="text-gray-500">{email}</span>
      </div>
    </span>
  );
};
