import { LlmAssistantMessageBreakout } from 'types/__generated__/GovlyApi';
import { create } from 'zustand';

export type Message = LlmAssistantMessageBreakout & { isPartial?: boolean };

type State = {
  messagesHistory: { [key: string]: Message[] };
  filesProcessing: { [key: string]: boolean };
};

type Action = {
  makeEmptyMessage: (externalId: string) => Message;
  removeMessageById: (id: string) => (externalId: string) => void;
  setMessagesById: (id: string) => (messages: Message[]) => void;
  updateMessageByIdForId: (id: string) => (externalId: string, message: Partial<Message>) => void;
  updateMessageByDeltaForId: (id: string) => (externalId: string, content: string) => void;
  resetMessagesForId: (id: string) => () => void;
  setFilesProcessing: (id: string) => (processing: boolean) => void;
};

type Store = State & Action;

const upsertInHistory = (state: State, threadId: string, externalId: string, partialMessage: Partial<Message>) => {
  const messagesHistory = state.messagesHistory[threadId] || [];
  const extantIdx = messagesHistory.findIndex(m => m.externalId === externalId);

  if (extantIdx >= 0) {
    const extant = messagesHistory[extantIdx];

    return {
      messagesHistory: {
        ...state.messagesHistory,
        [threadId]: [
          ...messagesHistory.slice(0, extantIdx),
          { ...extant, ...partialMessage },
          ...messagesHistory.slice(extantIdx + 1)
        ]
      }
    };
  } else {
    return {
      messagesHistory: {
        ...state.messagesHistory,
        [threadId]: [...messagesHistory, partialMessage as Message]
      }
    };
  }
};

const useAssistantHistoryStore = create<State & Action>((set, get) => {
  return {
    messagesHistory: {},
    filesProcessing: {},

    setFilesProcessing: id => processing => {
      set(state => ({
        filesProcessing: { ...state.filesProcessing, [id]: processing }
      }));
    },

    makeEmptyMessage: (externalId: string) => {
      const message: Message = {
        __typename: 'LlmAssistantMessageBreakout',
        id: '',
        externalId,
        role: 'assistant',
        content: '',
        postedAt: '',
        isPartial: true
      };

      return message;
    },

    setMessagesById: (id: string) => (messages: Message[]) => {
      set(state => ({
        messagesHistory: { ...state.messagesHistory, [id]: messages }
      }));
    },

    updateMessageByDeltaForId: (id: string) => (externalId: string, content: string) => {
      const state = get();
      const messagesHistory = state.messagesHistory[id] || [];
      const extant = messagesHistory.find(m => m.externalId === externalId);

      if (extant) {
        set(state => upsertInHistory(state, id, externalId, { content: extant.content + content }));
      }
    },

    updateMessageByIdForId: id => (externalId: string, message: Partial<Message>) => {
      set(state => upsertInHistory(state, id, externalId, message));
    },

    removeMessageById: (id: string) => (externalId: string) => {
      set(state => {
        const messagesHistory = state.messagesHistory[id] || [];
        const updatedMessages = messagesHistory.filter(message => message.externalId !== externalId);

        return {
          messagesHistory: {
            ...state.messagesHistory,
            [id]: updatedMessages
          }
        };
      });
    },

    resetMessagesForId: id => () => {
      set(state => ({
        messagesHistory: {
          ...state.messagesHistory,
          [id]: []
        }
      }));
    }
  };
});

const getAssistantStore = (id: string) => (store: Store) => {
  return {
    messages: store.messagesHistory[id] || [],
    updateMessage: store.updateMessageByIdForId(id),
    updateMessageDelta: store.updateMessageByDeltaForId(id),
    resetMessages: store.resetMessagesForId(id),
    removeMessageByExternalId: store.removeMessageById(id),
    setMessages: store.setMessagesById(id),
    makeEmptyMessage: store.makeEmptyMessage,
    filesProcessing: store.filesProcessing[id] || false,
    setFilesProcessing: store.setFilesProcessing(id)
  };
};

export const useAssistantStore = (id: string) => {
  return useAssistantHistoryStore(getAssistantStore(id));
};
useAssistantStore.getState = (id: string) => {
  return getAssistantStore(id)(useAssistantHistoryStore.getState());
};
