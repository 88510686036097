import React from 'react';
import { RangeInput, RangeInputProps } from 'app/atoms/inputs/RangeInput/RangeInput';

export type PriceRangeInputProps = RangeInputProps;

export const PriceRangeInput = (props: PriceRangeInputProps) => {
  return (
    <RangeInput
      {...{
        majorStepSize: 1000000,
        stepSize: 1000,
        minorStepSize: 100,
        buttonPosition: 'none',
        leftIcon: 'dollar',
        ...props
      }}
    />
  );
};
