import React, { useMemo, useState } from 'react';
import truncate from 'lodash-es/truncate';
import { Formik, Form } from 'formik';
import { Button, Tag, Dialog } from '@blueprintjs/core';
import { createColumnHelper, sortingFns, Row } from '@tanstack/react-table';

import { OppSearchDisplayable, SavedSearchDisplayable } from 'types/__generated__/GovlyApi';
import { useGetOppSearchesQuery, useUpdateOppSearchMutation } from 'api/oppSearchesApi';
import { useGetSavedSearchesQuery, useUpdateSavedSearchMutation, SearchableType } from 'api/savedSearchesApi';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { successToast, errorToast } from 'app/lib/toaster';
import { H2 } from 'app/atoms/Typography/Typography';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { OppSavedSearchUpdateForm } from 'app/organisms/OppSavedSearchUpdateForm/OppSavedSearchUpdateForm';
import { SavedSearchUpdateForm } from 'app/organisms/SavedSearchUpdateForm/SavedSearchUpdateForm';
import { CopyButton } from 'app/molecules/CopyButton/CopyButton';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';

import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

type SavedSearchesTableProps = {
  searchableType?: SearchableType;
} & React.HTMLAttributes<HTMLDivElement>;

type DataType = Omit<OppSearchDisplayable, 'matchCount'> | SavedSearchDisplayable;
const columnHelper = createColumnHelper<DataType>();

const filterFollows = function (row: Row<DataType>, _columnId: string, filterValue: string) {
  if (!filterValue) return true;

  const follows = 'follows' in row.original ? (row.original.follows ?? []) : [];
  const searchTerm = filterValue.toLowerCase();

  return follows.some(
    f =>
      f.organizationUser?.name?.toLowerCase().includes(searchTerm) ||
      f.organizationUser?.email.toLowerCase().includes(searchTerm)
  );
};

export const SavedSearchesTable = ({ searchableType, ...rest }: SavedSearchesTableProps) => {
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [savedSearchFilter, setSavedSearchFilter] = useState('active');
  const { isMobile, isTablet, isDesktop } = useDeviceWidth();
  const [filterInTransition, setFilterTransition] = useState(false);
  const [updateOppSearch] = useUpdateOppSearchMutation();
  const [updateSavedSearch] = useUpdateSavedSearchMutation();

  const options = savedSearchFilter === 'active' ? { active: true } : {};
  const {
    data: oppData = [],
    isLoading: oppLoading,
    isFetching: oppFetching
  } = useGetOppSearchesQuery(options, { refetchOnMountOrArgChange: true, skip: !!searchableType });
  const {
    data: searchableData = [],
    isLoading: searchableLoading,
    isFetching: searchableFetching
  } = useGetSavedSearchesQuery(
    { ...options, searchableType },
    { refetchOnMountOrArgChange: true, skip: !searchableType }
  );

  const data: DataType[] = searchableType ? searchableData : oppData;
  const isLoading = searchableType ? searchableLoading : oppLoading;
  const isFetching = searchableType ? searchableFetching : oppFetching;

  const hiddenColumns = useMemo(() => {
    if (isMobile) {
      return { 'query.query': false, follows: false, lastMatchedAt: true };
    }
    if (searchableType) {
      return { 'query.query': true, follows: true, lastMatchedAt: false };
    }
    return {
      'query.query': true,
      follows: true,
      lastMatchedAt: true
    };
  }, [isMobile, searchableType]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          const link = `/opportunities?savedSearchId=${e.row.original.id}`;
          return (
            <div>
              <H2>{truncate(e.row.original.name, { length: isMobile ? 40 : 60 })}</H2>
              <div className="mt-2 flex space-x-5">
                {e.row.original.active && !searchableType && (
                  <LinkTag to={link} target="_blank">
                    <Button
                      small={isDesktop ?? true}
                      intent="primary"
                      icon={isMobile || isTablet ? 'list-detail-view' : null}
                      text={isDesktop ? 'View Results' : 'Results'}
                    />
                  </LinkTag>
                )}
                <Button
                  small={isDesktop ?? true}
                  icon={isMobile || isTablet ? 'archive' : null}
                  intent={e.row.original.active ? 'danger' : 'success'}
                  text={e.row.original.active ? 'Archive' : 'Unarchive'}
                  onClick={async () => {
                    try {
                      if (searchableType) {
                        await updateSavedSearch({
                          id: e.row.original.id,
                          searchableType,
                          active: !e.row.original.active
                        }).unwrap();
                      } else {
                        await updateOppSearch({
                          id: e.row.original.id,
                          active: !e.row.original.active
                        }).unwrap();
                      }
                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                />
                <Button
                  small={isDesktop ?? true}
                  icon={isMobile || isTablet ? 'new-person' : null}
                  text={isDesktop ? 'Update Followers' : ''}
                  onClick={() => setIsEditing(e.row.original.id)}
                />

                {e.row.original.active && !searchableType && (
                  <CopyButton
                    instructions="Copy URL to Clipboard"
                    copyText={`${location.host}${link}`}
                    small={isDesktop ?? true}
                  />
                )}
              </div>
            </div>
          );
        }
      }),
      columnHelper.accessor('query.query', {
        header: 'Query',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <div className="max-w-md whitespace-normal break-words">
            {truncate(e.row.original.query.query, { length: 200 })}
          </div>
        )
      }),
      columnHelper.accessor('follows', {
        header: 'Followers',
        filterFn: filterFollows,
        cell: e => {
          const follows = 'follows' in e.row.original ? (e.row.original.follows ?? []) : [];

          return (
            <AvatarList
              limit={2}
              avatarData={follows.map(
                ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                  initials,
                  name,
                  organizationName,
                  avatar,
                  avatarColor,
                  active,
                  notificationProps: {
                    intent: active ? 'success' : 'danger',
                    icon: active ? 'thumbs-up' : 'thumbs-down',
                    size: 'xl',
                    position: 'bottom'
                  }
                })
              )}
            />
          );
        }
      }),
      columnHelper.accessor('lastMatchedAt', {
        header: 'Last Matched',
        sortingFn: sortingFns.basic,
        cell: e => {
          const lastMatchedAt = 'lastMatchedAt' in e.row.original ? e.row.original.lastMatchedAt : null;
          return lastMatchedAt ? (
            <Tag intent="success">{formatTime(lastMatchedAt, DATETIME_24_SHORT)}</Tag>
          ) : (
            <Tag intent="danger">No matches</Tag>
          );
        }
      })
    ],
    [isDesktop, isMobile, isTablet, searchableType, updateOppSearch, updateSavedSearch]
  );

  const ToolbarComponent = () => (
    <Formik
      initialValues={{ selected: savedSearchFilter }}
      onSubmit={({ selected }) => {
        setFilterTransition(true);
        setSavedSearchFilter(selected);
      }}
    >
      {() => (
        <Form>
          <SelectInput
            contentClassName="m-0"
            name="selected"
            items={[
              { value: 'active', label: 'Active' },
              { value: 'all', label: 'All' }
            ]}
            searchKeys={['label']}
            filterable={false}
            buttonProps={{
              small: true,
              outlined: true,
              icon: 'filter'
            }}
            submitOnChange
          />
        </Form>
      )}
    </Formik>
  );

  return (
    <div {...rest}>
      <GovlyTable
        id="saved_searches_table"
        columns={columns}
        data={data}
        title={<GovlyTableTitle title="Saved Searches" />}
        rightElement={
          <GovlyTableToolbar>
            <ToolbarComponent />
          </GovlyTableToolbar>
        }
        isLoading={isLoading || (isFetching && filterInTransition)}
        state={{ columnVisibility: hiddenColumns }}
        emptyStateProps={{
          title: 'No Saved Searches',
          icon: 'folder-open'
        }}
        initialState={{
          sorting: [{ id: 'name', desc: false }],
          pagination: { pageSize: 25 }
        }}
      />

      {isEditing && (
        <Dialog
          isOpen={!!isEditing}
          onClose={() => setIsEditing(null)}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          {searchableType ? (
            <SavedSearchUpdateForm
              savedSearchId={isEditing}
              searchableType={searchableType}
              onCancel={() => setIsEditing(null)}
              handleClose={() => setIsEditing(null)}
              followersOnly={false}
            />
          ) : (
            <OppSavedSearchUpdateForm
              savedSearchId={isEditing}
              followersOnly
              onCancel={() => setIsEditing(null)}
              handleClose={() => setIsEditing(null)}
            />
          )}
        </Dialog>
      )}
    </div>
  );
};
