import { appsignal } from './appsignal';

type TrackErrorOptions = {
  tags?: Record<string, string>;
  action?: string;
};

export const defaultTags = {
  email: process.env.CURRENT_USER_EMAIL,
  orgUserId: process.env.CURRENT_USER_ID,
  orgId: process.env.CURRENT_USER_ORGANIZATION_ID
};

export const trackError = (e: Error, options: TrackErrorOptions = {}) => {
  if (process.env.RAILS_ENV === 'test') return;

  const tags = {
    ...defaultTags,
    ...(options.tags || {})
  };

  return appsignal.sendError(e, span => {
    span.setTags(tags);

    if (options.action) {
      span.setAction(options.action);
    }
  });
};

export const safely = (fn: () => void, options: TrackErrorOptions = {}) => {
  try {
    fn();
  } catch (e) {
    if (process.env.RAILS_ENV === 'development' || process.env.RAILS_ENV === 'test') {
      console.error(e);
      throw e;
    } else {
      trackError(e as Error, options);
    }
  }
};
