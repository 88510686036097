import React from 'react';
import { LoaderFunction, Outlet } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';
import { match, P } from 'ts-pattern';
import { oppWorkspacesApi } from 'api/oppWorkspacesApi';
import { store } from 'app/store';

type LoaderData = { drawerType?: string | null; drawerId?: string | null };

export const loader: LoaderFunction = async ({ request }): Promise<LoaderData> => {
  const url = new URL(request.url);
  return {
    drawerType: url.searchParams.get(DRAWER_PARAMS.type),
    drawerId: url.searchParams.get(DRAWER_PARAMS.id)
  };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ drawerType, drawerId }) => {
  const drawerCrumbs = await match({ drawerType, drawerId })
    .with({ drawerType: 'workspaces', drawerId: P.string }, async ({ drawerId }) => {
      const workspace = await store
        .dispatch(oppWorkspacesApi.endpoints.getOppWorkspace.initiate({ id: drawerId }, { subscribe: false }))
        .then(({ data }) => data);

      return workspace ? [{ to: `/worklist/workspaces/${workspace.id}`, text: workspace.name }] : [];
    })
    .otherwise(() => []);

  return [{ to: `/worklist`, text: 'Worklist' }, ...drawerCrumbs];
});

export const WorklistPage = () => {
  return (
    <Main>
      <MainColumn>
        <Outlet />
      </MainColumn>
    </Main>
  );
};
