import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

import { GovernmentEntityShow } from 'types/__generated__/GovlyApi';
import { cn } from 'app/lib/cn';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export const GovernmentEntityLink = ({
  entity,
  className
}: {
  entity: Pick<GovernmentEntityShow, 'name' | 'id'>;
  className?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const inDrawer = searchParams.get(DRAWER_PARAMS.id);

  return inDrawer ? (
    <LinkTag
      className={className}
      tag="a"
      onClick={() =>
        setSearchParams({ ...Object.fromEntries(searchParams), drawerType: 'entity', drawerId: entity.id })
      }
    >
      {entity.name}
    </LinkTag>
  ) : (
    <LinkTag className={className} to={`/budgets/agencies/${entity.id}`}>
      {entity.name}
    </LinkTag>
  );
};

const LOGO_BACKGROUND_COLORS = [
  'bg-red-800',
  'bg-orange-900',
  'bg-yellow-800',
  'bg-blue-500',
  'bg-green-900',
  'bg-teal-900',
  'bg-cyan-900',
  'bg-indigo-900',
  'bg-purple-800',
  'bg-gray-900'
];

export const GovernmentEntityLogo = ({
  entity,
  className
}: {
  entity: Pick<GovernmentEntityShow, 'logoUrl' | 'name' | 'id'>;
  className?: string;
}) => {
  const baseClassName = 'h-8 w-8 object-contain mr-2 shrink-0';

  if (entity.logoUrl) {
    return <img src={entity.logoUrl} className={cn(baseClassName, className)} loading="lazy" />;
  } else {
    const colorIndex = parseInt(entity.id, 10) % LOGO_BACKGROUND_COLORS.length;
    const initials = entity.name
      .split(' ')
      .filter(word => word.length > 4)
      .map(word => word[0])
      .slice(0, 2);

    return (
      <div
        className={cn(
          baseClassName,
          'rounded-full text-white font-bold flex items-center justify-center',
          LOGO_BACKGROUND_COLORS[colorIndex],
          className
        )}
      >
        {initials}
      </div>
    );
  }
};
