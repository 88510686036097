import React, { useState } from 'react';
import { NumericInput, NumericInputProps } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';

export type Range = [number?, number?];

export type RangeInputProps = {
  name: string;
  className?: string;
  value?: Range;
  onMinChange: NumericInputProps['onValueChange'];
  onMaxChange: NumericInputProps['onValueChange'];
} & Omit<NumericInputProps, 'value'>;

export const RangeInput = ({ name, className, value, onMinChange, onMaxChange, ...props }: RangeInputProps) => {
  const [rangeMin, rangeMax] = value ?? [0, 0];
  const [relativeMin, setRelativeMin] = useState(rangeMin);
  const [relativeMax, setRelativeMax] = useState(rangeMax);

  const sharedProps: Partial<NumericInputProps> = {
    buttonPosition: 'none',
    ...props
  };

  return (
    <div className={cn('flex gap-[2px]', className)}>
      <NumericInput
        className="bp5-fill"
        {...sharedProps}
        defaultValue={rangeMin?.toString()}
        onValueChange={onMinChange}
        name={name}
        max={relativeMax}
        onBlur={() => setRelativeMin(rangeMin)}
        disabled={!value}
      />

      <NumericInput
        className="bp5-fill"
        {...sharedProps}
        defaultValue={rangeMax?.toString()}
        onValueChange={onMaxChange}
        name={name}
        min={relativeMin}
        onBlur={() => setRelativeMax(rangeMax)}
        disabled={!value}
      />
    </div>
  );
};
