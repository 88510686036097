import React, { useEffect, useMemo, useState } from 'react';
import { NonIdealState, Button } from '@blueprintjs/core';

import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { successToast, errorToast } from 'app/lib/toaster';
import { ActivityNotificationWithData, useUpdateActivityNotificationMutation } from 'api/activityNotificationsApi';
import { useGetActivityNotificationsQuery } from 'api/activityNotificationsApi';
import { OppWorkspaceHeader } from 'app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { formatTime, isToday, DATE_SHORT, TIME_HOUR_AND_MINUTE } from 'app/lib/dates';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { ActivityMessage } from 'app/molecules/ActivityMessage/ActivityMessage';
import { linkDecorator, linkNameDecorator } from 'app/lib/activityMessageDecorator';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { createColumnHelper } from '@tanstack/react-table';
import { makeGovlyTableRowSelectionColumn } from 'app/molecules/GovlyTable/makeGovlyTableRowSelectionColumn';
import { GovlyTableColumnVisibilityToggles } from 'app/molecules/GovlyTable/GovlyTableColumnVisibilityToggles';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';
import { MarkAsReadBulk } from './MarkAsReadBulk';

const columnHelper = createColumnHelper<ActivityNotificationWithData>();

const getHiddenColumns = ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => {
  const cols = isMobile
    ? ['readAt', 'activity.customData.displayName', 'activity.createdAt']
    : isTablet
      ? ['activity.customData.displayName']
      : [];

  return Object.fromEntries(cols.map(x => [x, false]));
};

export const ActivityNotificationsTable = () => {
  const { trackEvent } = useEventTracking();
  const { data: { email } = {} } = useGetCurrentUserQuery();

  const { data = [], isLoading } = useGetActivityNotificationsQuery();
  const [updateActivityNotification] = useUpdateActivityNotificationMutation();

  const { isMobile, isTablet } = useDeviceWidth();

  const [hiddenColumns, setHiddenColumns] = useState(() => getHiddenColumns({ isMobile, isTablet }));

  useEffect(() => {
    setHiddenColumns(getHiddenColumns({ isMobile, isTablet }));
  }, [isMobile, isTablet]);

  const columns = useMemo(
    () => [
      makeGovlyTableRowSelectionColumn({ columnHelper }),
      columnHelper.accessor('activity.customData.displayName', {
        id: 'activity.customData.displayName',
        header: 'Opportunity',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        enableColumnFilter: true,
        cell: e => {
          const { activity } = e.row.original;
          if (!activity) return null;
          const { customData } = activity;
          return (
            <div>
              {customData.workspaceName && customData.displayId ? (
                <OppWorkspaceHeader
                  id={activity.actionTargetId}
                  oppId={customData.displayId.toString()}
                  name={customData.workspaceName}
                  organizationDefault={activity.actor?.organizationName === customData.workspaceName}
                  showLink
                  visibleId={customData.displayName}
                  path={customData.path}
                  linkOpp
                />
              ) : (
                <LinkTag to={linkDecorator(activity)} className=" font-medium no-underline">
                  <NewTabTooltip title={linkNameDecorator(activity)}>
                    <dt className="text-base font-semibold">{customData.displayName}</dt>
                  </NewTabTooltip>
                </LinkTag>
              )}
            </div>
          );
        }
      }),
      columnHelper.accessor('activity.message', {
        id: 'activity.message',
        header: 'Message',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        enableColumnFilter: true,
        cell: e =>
          e.row.original.activity && email ? <ActivityMessage activity={e.row.original.activity} email={email} /> : null
      }),
      columnHelper.accessor('activity.createdAt', {
        id: 'activity.createdAt',
        enableColumnFilter: false,
        header: 'Date',
        size: 48,
        cell: e => {
          const date = e.row.original.activity?.createdAt;
          if (!date) return '-';

          if (isToday(date)) {
            return formatTime(date, TIME_HOUR_AND_MINUTE);
          } else {
            return formatTime(date, DATE_SHORT);
          }
        }
      }),
      columnHelper.accessor('readAt', {
        header: '',
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        minSize: 160,
        cell: e => {
          const { id } = e.row.original;
          const readAt = new Date().toISOString();
          return (
            <Button
              small
              intent="success"
              text="Mark as Read"
              onClick={async () => {
                try {
                  await updateActivityNotification({ id, readAt });
                  trackEvent({ object: 'notification', action: 'marked_as_read', properties: { id, readAt } });
                  successToast();
                } catch (e) {
                  errorToast(e);
                }
              }}
            />
          );
        }
      })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data.length) {
    return (
      <Card title="Notifications">
        <CardSection>
          <NonIdealState title="You're all caught up 🎉" icon="inbox-update" />
        </CardSection>
      </Card>
    );
  }

  return (
    <div>
      <GovlyTable
        id="activity_notifications_table"
        title={<GovlyTableTitle title="Notifications" />}
        rightElement={
          <GovlyTableToolbar>
            <MarkAsReadBulk />
            <GovlyTableColumnVisibilityToggles />
          </GovlyTableToolbar>
        }
        columns={columns}
        data={data}
        enableHiding
        onColumnVisibilityChange={setHiddenColumns}
        state={{ columnVisibility: hiddenColumns }}
        getRowId={a => a.id}
      />
    </div>
  );
};
