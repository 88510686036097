import { rootApi } from './rootApi';

type ResetRackAttack = {
  params: void;
  result: void;
};

type ResetFeedProfiles = {
  params: { type: string };
  result: void;
};

export const sysAdminSettings = rootApi.injectEndpoints({
  endpoints: build => ({
    resetRackAttack: build.mutation<ResetRackAttack['result'], ResetRackAttack['params']>({
      query: body => ({
        url: `/v2/sys_admin_settings/reset_rack_attack`,
        method: 'POST',
        body
      })
    }),
    resetFeedProfiles: build.mutation<ResetFeedProfiles['result'], ResetFeedProfiles['params']>({
      query: body => ({
        url: `/v2/sys_admin_settings/reset_feed_profiles`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useResetRackAttackMutation, useResetFeedProfilesMutation } = sysAdminSettings;
