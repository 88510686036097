import React, { useEffect, useState } from 'react';
import { Callout, FormGroup, Button, InputGroup } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useDeleteOrganizationUserMutation } from 'api/organizationUsersApi';
import { deleteToast, errorToast } from 'app/lib/toaster';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';

type DeleteUserCardProps = {
  organizationUser?: OrganizationUserCurrentOrg;
};

export const DeleteUserCard = ({ organizationUser }: DeleteUserCardProps) => {
  const { id, email } = organizationUser ?? {};
  const { trackEvent } = useEventTracking();
  const navigate = useNavigate();
  const [deleteOrganizationUser, { error: deleteError, isLoading: isDeleting, isSuccess: deleteSuccess }] =
    useDeleteOrganizationUserMutation();
  const [confirmation, setConfirmation] = useState('');

  useEffect(() => {
    if (deleteSuccess) {
      deleteToast(`User ${email} has been deleted.`);
      trackEvent({
        object: 'user',
        action: 'deleted',
        properties: {
          userEmail: email,
          userId: id
        }
      });
      navigate('/settings/users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useEffect(() => {
    if (deleteError) {
      errorToast(deleteError);
    }
  }, [deleteError]);

  const currentOrgUserId = useCurrentUserAttribute('id');

  if (!id || currentOrgUserId === id) return null;

  return (
    <Card title="Delete User">
      <CardBody>
        <Callout className="mb-4" intent="danger" title={`Are you sure you want to delete ${email}?`}>
          {`Deleting ${email} cannot be reversed and all data related to them will be lost. Consider changing their subscription to 'Free Account' instead.`}
        </Callout>
        <FormGroup label="Type DELETE to confirm." labelFor="confirmation">
          <InputGroup
            value={confirmation}
            onChange={e => setConfirmation(e.target.value)}
            id="confirmation"
            autoComplete="off"
            large
          />
        </FormGroup>
        <div className="flex flex-row-reverse">
          <Button
            intent="danger"
            disabled={confirmation !== 'DELETE' || isDeleting}
            loading={isDeleting}
            text="Delete User"
            onClick={() => deleteOrganizationUser({ id })}
          />
        </div>
      </CardBody>
    </Card>
  );
};
