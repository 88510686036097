import React, { useState } from 'react';
import { Drawer, DrawerSize, Switch, Tooltip } from '@blueprintjs/core';
import { useNavStore } from 'app/organisms/AuthenticatedNav/useNavStore';
import { useHotkeys } from 'react-hotkeys-hook';
import { useFeatureFlagsStore } from './hooks/useFeatureFlagsStore';
import { NavLinkButton } from './molecules/NavLinkButton/NavLinkButton';

const LABEL = 'Feature Flags';

export const DevTools = () => {
  const { setFeatureFlag, featureFlags } = useFeatureFlagsStore();
  const { isExpanded } = useNavStore();

  const [isOpen, setIsOpen] = useState(false);
  useHotkeys('meta+shift+f', () => setIsOpen(true));
  useHotkeys('esc', () => setIsOpen(false), { enabled: isOpen });

  return (
    <div className="w-full">
      <Tooltip content={LABEL} disabled={isExpanded} className="w-full">
        <NavLinkButton
          buttonProps={{
            icon: 'flag',
            onClick: () => setIsOpen(true),
            text: isExpanded ? LABEL : undefined,
            intent: 'warning'
          }}
        />
      </Tooltip>
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} size={DrawerSize.SMALL}>
        <div className="px-4 py-2 flex flex-col gap-4">
          <h2 className="font-bold uppercase text-gray-700">{LABEL}</h2>
          <ul className="flex flex-col gap-2">
            {(Object.entries(featureFlags) as [keyof typeof featureFlags, boolean][]).map(([flag, value]) => (
              <li key={flag}>
                <Switch
                  className="mb-0"
                  checked={value}
                  label={flag}
                  onChange={e => {
                    setFeatureFlag(flag, e.target.checked);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </Drawer>
    </div>
  );
};
