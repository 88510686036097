import React from 'react';
import { useParams } from 'react-router-dom';
import { AnchorButton, Intent, Tag } from '@blueprintjs/core';
import truncate from 'lodash-es/truncate';

import { VendorShow } from 'types/__generated__/GovlyApi';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { DatalistLeftAligned } from 'app/molecules/DatalistLeftAligned/DatalistLeftAligned';
import { CrmContact } from 'app/molecules/CrmContact/CrmContact';
import { useGetVendorQuery } from 'api/vendorsApi';
import { useMetaTags } from 'app/hooks/useMetaTags';
import { NotFound } from 'app/molecules/NotFound/NotFound';

type VendorDetails = {
  hq?: string;
  linkedin?: string;
  stockSymbol?: string;
  estimatedRevenue?: string;
  numberOfEmployees?: string;
  crunchbaseUrl?: string;
};

const companyTypeMap: Record<string, Intent> = {
  'Prime Contractor': 'primary',
  Reseller: 'warning',
  OEM: 'success'
};
export const VendorProfileSummary = () => {
  const sysAdmin = useAuthorized({ role: 'sys_admin' });
  const authorized = useAuthorized({ role: 'member' });
  const { slug } = useParams();
  const { data, isLoading } = useGetVendorQuery({ id: slug ?? '' }, { skip: !slug });
  const vendor = data as VendorShow | undefined;

  useMetaTags({
    title: `${vendor?.name} - Govly Vendor Profile`,
    description: vendor?.description?.split('. ')[0] || `View ${vendor?.name}'s profile on Govly`
  });

  if (isLoading) return <AppLoading />;

  if (!vendor) {
    return <NotFound />;
  }

  const {
    id,
    primaryWebsite: website,
    name,
    description,
    phone,
    duns,
    uei,
    companyTypeList,
    tags = [],
    organizationId,
    claimed,
    contacts
  } = vendor;
  const details: VendorDetails = vendor.details;

  const datalistItems = [
    {
      label: 'Name',
      value: name
    },
    {
      label: 'Description',
      value: description
    },
    {
      label: 'Website',
      value: website && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={website}>
          {truncate(website.replace(/^https?:\/\//, '').replace(/\/$/, ''), { length: 45 })}
        </LinkTag>
      )
    },
    {
      label: 'Phone',
      value: phone
    },
    {
      label: 'Identifiers',
      className: 'gap-x-2 flex',
      value: [
        { label: 'DUNS', value: duns },
        { label: 'UEI', value: uei }
      ]
        .filter(({ value }) => value)
        .map(({ label, value }) => (
          <Tag minimal key={label}>
            <strong className="mr-2">{label}</strong>
            <span>{value}</span>
          </Tag>
        ))
    },
    {
      label: 'Vendor Type',
      className: 'gap-2 flex flex-wrap',
      value: companyTypeList?.map(type => {
        return (
          <Tag key={type} intent={companyTypeMap[type]}>
            {type}
          </Tag>
        );
      })
    },
    {
      label: 'Headquarters',
      value: details?.hq
    },
    {
      label: 'Linkedin',
      value: details?.linkedin && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={details.linkedin}>
          {details.linkedin}
        </LinkTag>
      )
    },
    { label: 'Stock Symbol', value: details?.stockSymbol },
    { label: 'Estimated Revenue', value: details?.estimatedRevenue },
    { label: 'Number of Employees', value: details?.numberOfEmployees },
    {
      label: 'Tags',
      className: 'gap-2 flex flex-wrap',
      value: tags.map((type, i) => {
        return (
          <Tag key={`${i}-${type}`} minimal intent="primary">
            {type}
          </Tag>
        );
      })
    }
  ];

  return (
    <MainColumn columnSpan={12}>
      <Card
        title="Summary"
        rightElement={
          <div className="flex gap-x-2">
            {organizationId && (
              <Tag intent="success" icon="endorsed" large>
                Govly Partner
              </Tag>
            )}
            <div className="flex space-x-2 items-center">
              {claimed && (
                <div>
                  <Tag minimal large intent="success" icon="endorsed">
                    Govly Vendor
                  </Tag>
                </div>
              )}
              {sysAdmin && (
                <AnchorButton href={`/sys_admin/vendors/${id}`} icon="edit" target="_blank" rel="noreferrer" />
              )}
            </div>
          </div>
        }
      >
        <CardBody padded={false}>
          <DatalistLeftAligned items={datalistItems} excludeNullRows />
        </CardBody>
      </Card>
      {contacts?.length ? (
        <Card title="Contacts">
          <CardBody padded={false}>
            <ul role="list" className="divide-y divide-gray-200">
              {contacts.map((contact, i) => (
                <li key={`contact-${i}`}>
                  <CrmContact contact={contact} authorized={authorized} />
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      ) : null}
    </MainColumn>
  );
};
