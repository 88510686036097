import React from 'react';
import { Tag, SegmentedControl } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { AgencyDepthUtils, useAwardTypeaheadQuery } from 'api/awardsApi';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { TypeaheadItemWithAliases } from 'types/__generated__/GovlyApi';
import { FormikCompositeQueryMultiSelect } from 'app/molecules/InputCompositeFilter/FormikCompositeQueryMultiSelect';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { match } from 'ts-pattern';

export const AwardSearchFiltersAgency = () => {
  const { values, setValues } = useFormikContext<AwardSearchForm>();
  const isAwardingAgency = values.meta.agencyType === 'awarding';

  const getField = useGetFormikField<AwardSearchForm>();

  return (
    <FormikCompositeQueryMultiSelect
      namesAndLabels={
        isAwardingAgency
          ? [
              ['filters.awardingAgencyNames.any', 'Include'],
              ['filters.awardingAgencyNames.none', 'Exclude']
            ]
          : [
              ['filters.fundingAgencyNames.any', 'Include'],
              ['filters.fundingAgencyNames.none', 'Exclude']
            ]
      }
      title="Agency"
      useHook={useAwardTypeaheadQuery as InputMultiSelectHook<TypeaheadItemWithAliases>}
      hookArgs={[
        {
          typeahead: isAwardingAgency ? 'awarding_agency_name' : 'funding_agency_name',
          size: 1000,
          view: 'with_aliases'
        }
      ]}
      getItemValue={AgencyDepthUtils.getItemValue}
      getItemTextLabel={item => item.label}
      useTypeaheadProps={{ options: { keys: ['label', 'value', 'aliases'], shouldSort: false } }}
      resetOnQuery
      getItemFallback={value => ({ value, label: AgencyDepthUtils.parseValueLabel(value) }) as TypeaheadItemWithAliases}
      multiselectType="async"
      getMenuItemProps={item => ({
        key: [item.value, item.parent, item.depth].filter(Boolean).join(':'),
        labelElement: match(item.depth)
          .with(1, () => (
            <Tag minimal intent="primary" round>
              Sub-agency
            </Tag>
          ))
          .with(2, () => (
            <Tag minimal round>
              Office
            </Tag>
          ))
          .otherwise(() => (
            <Tag round intent="primary">
              Agency
            </Tag>
          ))
      })}
      preFilterChildren={
        <SegmentedControl
          fill
          {...FormikToBp.toSegmentedControl({
            ...getField('meta.agencyType'),
            submitForm: undefined,
            onValueChange: val => {
              setTimeout(() => {
                setValues(v => ({
                  ...v,
                  filters: {
                    ...v.filters,
                    ...(val === 'awarding'
                      ? { fundingAgencyNames: { any: [], none: [] } }
                      : { awardingAgencyNames: { any: [], none: [] } })
                  }
                }));
              });
            }
          })}
          options={[
            { value: 'awarding', label: 'Awarding' },
            { value: 'funding', label: 'Funding' }
          ]}
        />
      }
    />
  );
};
