import React from 'react';

import { ApplicationError, ApplicationErrorProps } from 'app/atoms/ErrorFallback/ApplicationError';

export const NotAuthorized = (props: ApplicationErrorProps) => {
  return (
    <ApplicationError
      title="Not Authorized."
      errorCode="401 ERROR"
      description="Sorry, you do not have permission to visit this page."
      {...props}
    />
  );
};
