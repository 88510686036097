import { WorkspaceBase } from 'types/__generated__/GovlyApi';

export type WorkflowStageValue = WorkspaceBase['workflowStage'];
export type WorkflowStageLabel =
  | 'No Bid'
  | 'Qualifying'
  | 'Intend to Bid'
  | 'Quoted'
  | 'Submitted'
  | 'Awarded'
  | 'Not Awarded';

export type WorkflowStage = {
  label: WorkflowStageLabel;
  value: WorkflowStageValue;
  status?: 'complete' | 'current' | 'upcoming';
  intent?: 'success' | 'danger';
};

export const workflowStages: WorkflowStage[] = [
  { label: 'No Bid', value: 'no_bid' },
  { label: 'Qualifying', value: 'qualifying' },
  { label: 'Intend to Bid', value: 'intend_to_bid' },
  { label: 'Quoted', value: 'quoted' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Awarded', value: 'awarded' },
  { label: 'Not Awarded', value: 'not_awarded' }
];

export const formatWorkflowStages = (stage: WorkflowStageValue): WorkflowStage[] => {
  let stages = [...workflowStages].map(i => ({ ...i })).filter(i => i.value !== 'qualifying');
  switch (stage) {
    case 'no_bid': {
      stages = stages.slice(2, 5);
      return [
        {
          label: 'No Bid',
          value: 'no_bid',
          status: 'complete',
          intent: 'danger'
        },
        ...stages
      ];
    }
    case 'intend_to_bid': {
      stages = stages.slice(1, 5);
      return stages.map((stage, i) => (i === 0 ? { ...stage, status: 'complete' } : stage));
    }
    case 'quoted': {
      stages = stages.slice(1, 5);
      stages[0].status = 'complete';
      stages[1].status = 'complete';
      return stages;
    }
    case 'submitted': {
      stages = stages.slice(1, 5);
      stages[0].status = 'complete';
      stages[1].status = 'complete';
      stages[2].status = 'complete';
      return stages;
    }
    case 'awarded': {
      stages = stages.slice(1, 5);
      return stages.map(stage => ({ ...stage, status: 'complete' }));
    }
    case 'not_awarded': {
      stages = stages.slice(1, 5);
      stages = stages.map(stage => ({ ...stage, status: 'complete' }));
      stages.splice(-1, 1, {
        label: 'Not Awarded',
        value: 'not_awarded',
        status: 'complete',
        intent: 'danger'
      });
      return stages;
    }
    default:
      return stages.slice(1, 5);
  }
};
