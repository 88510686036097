import React, { useState } from 'react';
import { AnchorButton, Collapse } from '@blueprintjs/core';

export type InputCompositeFilterProps = {
  noPrimaryInput?: boolean;
  subInputsSelected?: boolean;
  advancedShowLabel?: string;
  advancedHideLabel?: string;
  children: React.ReactNode;
};

export function InputCompositeFilter({
  subInputsSelected = false,
  noPrimaryInput = false,
  advancedShowLabel = 'Show advanced options',
  advancedHideLabel = 'Hide advanced options',
  children
}: InputCompositeFilterProps) {
  let [primaryInput, ...subInputs] = React.Children.toArray(children);
  const [advancedIsOpen, setAdvancedIsOpen] = useState<boolean>(subInputsSelected);

  if (noPrimaryInput) {
    primaryInput = <></>;
    subInputs = React.Children.toArray(children);
  }

  return (
    <div className="flex flex-col gap-y-2">
      {primaryInput}
      {subInputs.length > 0 ? (
        <>
          <Collapse isOpen={advancedIsOpen}>
            <div className="flex flex-col gap-y-2">{subInputs}</div>
          </Collapse>

          {advancedIsOpen ? (
            <AnchorButton
              className="fill outline-none"
              intent="primary"
              text={advancedHideLabel}
              onClick={() => setAdvancedIsOpen(false)}
              disabled={subInputsSelected}
              minimal
            />
          ) : (
            <AnchorButton
              className="fill outline-none"
              intent="primary"
              onClick={() => setAdvancedIsOpen(true)}
              minimal
              text={advancedShowLabel}
            />
          )}
        </>
      ) : null}
    </div>
  );
}
