import { OrganizationUserCurrentUser } from 'types/__generated__/GovlyApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

export type Role = OrganizationUserCurrentUser['roles'][number];

export const useAuthorized = ({ role }: { role: Role }) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser) return false;

  const { roles, subscriptionActive } = currentUser;

  if (roles.includes('sys_admin')) return true;

  if (!subscriptionActive) return false;

  return roles.includes(role);
};
