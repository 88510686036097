import { CrmInteraction } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['CrmInteraction', 'Opp']
});

type CreateCrmInteraction = {
  params: {
    syncableType: string;
    syncableId: string;
    pushed: boolean;
  };
  result: CrmInteraction;
};

export const crmInteractionsApi = api.injectEndpoints({
  endpoints: build => ({
    createCrmInteraction: build.mutation<CreateCrmInteraction['result'], CreateCrmInteraction['params']>({
      query: body => ({
        url: `/v2/crm_interactions`,
        method: 'POST',
        body
      }),
      invalidatesTags: result => ['CrmInteraction', ...(result ? [result.syncableType] : [])]
    })
  })
});

export const { useCreateCrmInteractionMutation } = crmInteractionsApi;
