import React from 'react';
import { Intent, Tag, TagProps, Tooltip } from '@blueprintjs/core';

type Props = TagProps & {
  buyerName: string;
  buyerHierarchy?: string[];
  intent?: Intent;
};

export const OppSearchResultBuyer = ({ buyerName, buyerHierarchy = [], intent = 'success', ...rest }: Props) => {
  return (
    <>
      {buyerName && (
        <>
          {Array.isArray(buyerHierarchy) && buyerHierarchy.length > 1 ? (
            <Tooltip
              content={
                <div className="flex flex-col space-y-2">
                  {buyerHierarchy.map((n, i) => (
                    <span key={`${n}-${i}`}>{n}</span>
                  ))}
                </div>
              }
            >
              <Tag minimal className="cursor-pointer" intent={intent} icon="office" {...rest}>
                {buyerName}
              </Tag>
            </Tooltip>
          ) : (
            <Tag minimal intent={intent} icon="office" {...rest}>
              {buyerName}
            </Tag>
          )}
        </>
      )}
    </>
  );
};
