import React from 'react';
import { Loading } from 'app/atoms/Loading/Loading';
import { P, match } from 'ts-pattern';

export const ChoroplethMapLegend = ({
  isLoading,
  colorGroups,
  title
}: {
  isLoading: boolean;
  colorGroups: [number, string, number?][];
  title: string;
}) => {
  return (
    <div className="absolute bottom-10 right-2 bg-white rounded p-2">
      <h4 className="font-medium">{title}</h4>
      {isLoading ? (
        <Loading type="spinner" />
      ) : (
        colorGroups.map(([start, color, end], index) => (
          <div key={index} className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full opacity-75" style={{ backgroundColor: color }} />
            <span>
              {match({ start, end })
                .with({ end: P.nullish }, ({ start }) => `> ${start}`)
                .otherwise(({ start, end }) => `${start} - ${end}`)}
            </span>
          </div>
        ))
      )}
    </div>
  );
};
