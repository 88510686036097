import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Tag, InputGroup } from '@blueprintjs/core';
import { useRefinementList } from 'react-instantsearch';

import { SearchFacet } from 'app/molecules/SearchFacet/SearchFacet';

type RefinementListItem = ReturnType<typeof useRefinementList>['items'][0];

type SearchFacetCheckboxesProps = {
  label: string;
  refine: (value: string) => void;
  searchForItems?: (value: string) => void;
  hasExhaustiveItems?: boolean;
  facets: RefinementListItem[];
};

export const SearchFacetCheckboxes = ({
  facets,
  refine,
  searchForItems,
  hasExhaustiveItems,
  label
}: SearchFacetCheckboxesProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const items = useMemo(() => facets, [facets]);

  useEffect(() => {
    if (searchForItems) {
      searchForItems(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchFacet label={label}>
      {!hasExhaustiveItems && searchForItems && (
        <InputGroup
          small
          placeholder="Search"
          className="mt-2 mb-4"
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      )}
      {items.map(facet => (
        <div className="flex items-center justify-between" key={facet.label}>
          <Checkbox
            key={facet.label}
            label={facet.label}
            checked={facet.isRefined}
            onChange={() => {
              setSearchTerm('');
              refine(facet.value);
            }}
          />
          <div>
            <Tag minimal round>
              {facet.count || 1}
            </Tag>
          </div>
        </div>
      ))}
    </SearchFacet>
  );
};
