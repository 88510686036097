export const companySizes = [
  '1-10',
  '11-50',
  '51-100',
  '101-250',
  '251-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10000+'
];
export const teamSizes = ['1-5', '6-10', '11-20', '21-50', '51-100', '100+'];
export const estimatedRevenues = [
  'Less than $1M',
  '$1M to $10M',
  '$10M to $50M',
  '$50M to $100M',
  '$100M to $500M',
  '$500M to $1B',
  '$1B to $10B',
  '$10B+'
];
