import React, { useState } from 'react';
import { Icon, Button } from '@blueprintjs/core';

import { formatTime } from 'app/lib/dates';
import { SouptoolsTrTemplateForm } from 'app/organisms/SouptoolsTrTemplateForm/SouptoolsTrTemplateForm';
import { SouptoolsTrTemplate } from 'types/__generated__/GovlyApi';

type SouptoolsTrTemplateTableRowProps = {
  trTemplate: SouptoolsTrTemplate;
};

export const SouptoolsTrTemplateTableRow = ({ trTemplate }: SouptoolsTrTemplateTableRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { name, email, contractNumber, updatedAt } = trTemplate;

  return (
    <>
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <strong>{name}</strong>
          <div className="flex flex-wrap items-center py-1">
            <>
              <Icon icon="time" iconSize={12} />
              <small className="ml-1">{` Updated ${formatTime(updatedAt)}`}</small>
            </>
            <>
              <Icon icon="dot" iconSize={12} />

              <small>
                <strong>{email}</strong>
              </small>
            </>
            <>
              <Icon icon="dot" iconSize={12} />

              <small>
                <strong>{contractNumber}</strong>
              </small>
            </>
          </div>
        </div>
        <div>
          <Button icon="edit" onClick={() => setIsEditing(true)} small />
        </div>
      </div>
      <SouptoolsTrTemplateForm isOpen={isEditing} onClose={() => setIsEditing(false)} trTemplate={trTemplate} />
    </>
  );
};
