import { Zap } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Zap', 'CurrentOrganization'] });

type CreateZap = {
  params: {
    zap: Pick<Zap, 'action' | 'trigger' | 'params'>;
  };
  result: Zap;
};

type DeleteZap = {
  params: string;
  result: void;
};

export const zapsApi = api.injectEndpoints({
  endpoints: build => ({
    createZap: build.mutation<CreateZap['result'], CreateZap['params']>({
      query: body => ({
        url: `/v2/zaps`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['CurrentOrganization']
    }),

    deleteZap: build.mutation<DeleteZap['result'], DeleteZap['params']>({
      query: id => ({
        url: `/v2/zaps/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CurrentOrganization']
    })
  })
});

export const { useCreateZapMutation, useDeleteZapMutation } = zapsApi;
