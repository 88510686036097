import React from 'react';
import { Tag, Spinner, NonIdealState, AnchorButton, Icon } from '@blueprintjs/core';
import compact from 'lodash-es/compact';

import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';
import { pluralize } from 'app/lib/strings';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { SouptoolsClinUpload } from 'app/organisms/SouptoolsClinUpload/SouptoolsClinUpload';
import { useCreateClinUploadMutation } from 'api/souptoolsClinsApi';
import { SouptoolsQuoteFull, SouptoolsTrFull } from 'types/__generated__/GovlyApi';

type SouptoolsClinUploadsCard = {
  uploadable: SouptoolsTrFull | SouptoolsQuoteFull;
  maxUploads?: number;
  header?: string;
  templatePath?: string;
};

export const SouptoolsClinUploadsCard = ({
  uploadable,
  maxUploads = 10000,
  header = 'CLIN Uploads',
  templatePath
}: SouptoolsClinUploadsCard) => {
  const clinUploads =
    uploadable.__typename === 'SouptoolsTrFull' ? (uploadable.clinUploads ?? []) : compact([uploadable.clinUpload]);
  const [createClinUpload] = useCreateClinUploadMutation();

  return (
    <Card
      title={
        <div className="flex items-center gap-x-2">
          <CardHeading className="inline">{header}</CardHeading>
          {clinUploads.length > 1 && (
            <Tag className="ml-1 text-center" minimal>
              {clinUploads.length}
            </Tag>
          )}
        </div>
      }
      rightElement={
        <div className="flex items-center">
          {templatePath && (
            <AnchorButton small className="mr-2" text="Template File" minimal intent="primary" href={templatePath} />
          )}
          {uploadable.clinUploadProcessing && (
            <Tag intent="danger" minimal>
              <div className="flex justify-between">
                <Spinner intent="danger" size={5} />
                <span className="ml-2">Attachments processing</span>
              </div>
            </Tag>
          )}
          {!uploadable.clinUploadProcessing && !!clinUploads.length && (
            <Tag intent="success" icon="tick" minimal>
              Attachments processed
            </Tag>
          )}
        </div>
      }
    >
      {clinUploads.length ? (
        <>
          {[...clinUploads].reverse().map(clinUpload => (
            <CardBody key={clinUpload.id}>
              <SouptoolsClinUpload
                uploadableId={uploadable.id}
                uploadableType={uploadable.type}
                clinUpload={clinUpload}
              />
            </CardBody>
          ))}
        </>
      ) : (
        <CardBody>
          <NonIdealState icon="upload" title={`No ${pluralize('CLIN Upload', 'CLIN Uploads ', maxUploads)}`} />
        </CardBody>
      )}
      {clinUploads.length < maxUploads && (
        <FileUploader
          id="ClinUpload"
          className="flex cursor-pointer items-center justify-center border-t-2 border-dashed"
          onAttach={async ({ signedId }) => {
            createClinUpload({ signedId, uploadableId: uploadable.id, uploadableType: uploadable.type });
          }}
        >
          <div className="m-5 text-center">
            <Icon icon="cloud-upload" size={20} className="text-gray-500" />
            <p className="m-0 text-sm text-gray-500">Add or drop documents here</p>
          </div>
        </FileUploader>
      )}
    </Card>
  );
};
