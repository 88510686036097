import React, { Suspense, useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import capitalize from 'lodash-es/capitalize';
import { useSearchParams } from 'react-router-dom';

import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Footer } from 'app/organisms/Footer/Footer';
import { AppDrawer } from 'app/organisms/AppDrawer/AppDrawer';
import { AppModal } from 'app/organisms/AppModal/AppModal';
import { AppBody, AppLayout, AppMobileNav, AppSideNav, AppTopNav } from 'app/organisms/AppLayout/AppLayout';
import { TopNav, TopNavLoading } from 'app/organisms/AuthenticatedNav/TopNav';
import { SideNav, SideNavLoading } from 'app/organisms/AuthenticatedNav/SideNav';
import { marketingRoutes, noNavbarRoutes, userOverrideRoutes } from 'app/lib/routing';
import { Pattern, match } from 'ts-pattern';
import { OrganizationSwitcher } from 'app/molecules/OrganizationSwitcher/OrganizationSwitcher';
import { trackEvent } from 'app/lib/trackEvent';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { MarketingNavbar } from '../MarketingNavbar/MarketingNavbar';
import { MobileNav } from '../AuthenticatedNav/MobileNav';
import {
  AuthenticatedNavContextProvider,
  useAuthenticatedNavContextQuery
} from '../AuthenticatedNav/useAuthenticatedNavContext';

export const RouteLayout = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    window.posthog?.capture('$pageview');
    document.title = location.pathname
      .substring(1)
      .split('/')
      .map(segment =>
        isNaN(parseInt(segment))
          ? segment
              .match(/[A-Za-z][a-z]*/g) // split multiplies
              ?.map(capitalize)
              .join(' ')
          : segment
      )
      .slice(0, 2)
      .join(' | ');
  }, [location]);

  useEffect(() => {
    const drawerType = searchParams.get(DRAWER_PARAMS.type);
    const drawerId = searchParams.get(DRAWER_PARAMS.id);
    const translations: Record<string, Lowercase<string>> = {
      opportunities: 'opp',
      workspaces: 'workspace',
      contacts: 'contact',
      award: 'award'
    };
    const object = drawerType && translations[drawerType];
    if (!object) return;

    trackEvent({ object, action: 'viewed', properties: { id: drawerId, inDrawer: true } });
  }, [searchParams]);

  const authenticatedNavContextQuery = useAuthenticatedNavContextQuery();
  const { data: { currentUser } = {} } = authenticatedNavContextQuery;

  const showAppNavbar = Boolean(currentUser && userOverrideRoutes.includes(location.pathname));
  const isMarketingPage = marketingRoutes.includes(location.pathname);
  const isNoNavbarPage = noNavbarRoutes.includes(location.pathname);

  const common = [
    <AppBody key="body">
      <Suspense fallback={<AppLoading />}>
        <Outlet />
        <ScrollRestoration
          getKey={({ pathname }) => {
            return pathname;
          }}
        />
      </Suspense>
      <Footer />
    </AppBody>
  ];

  return match({ isMarketingPage, isNoNavbarPage, showAppNavbar, authenticatedNavContextQuery })
    .with({ isNoNavbarPage: true }, () => common)
    .with({ isMarketingPage: true, showAppNavbar: false }, () => (
      <>
        <MarketingNavbar />
        {common}
      </>
    ))
    .with({ authenticatedNavContextQuery: { isLoading: true } }, () => (
      <AppLayout>
        <AppSideNav>
          <SideNavLoading />
        </AppSideNav>

        <AppTopNav>
          <TopNavLoading />
        </AppTopNav>

        <AppMobileNav>
          <TopNavLoading />
        </AppMobileNav>

        {common}
      </AppLayout>
    ))
    .with(
      { authenticatedNavContextQuery: { data: Pattern.not(Pattern.nullish) } },
      ({ authenticatedNavContextQuery: { data } }) => (
        <AuthenticatedNavContextProvider authenticatedNavContext={data}>
          <AppLayout>
            <AppSideNav>
              <SideNav />
            </AppSideNav>

            <AppTopNav>
              <TopNav />
            </AppTopNav>

            <AppMobileNav>
              <MobileNav />
            </AppMobileNav>

            {common}
            <AppDrawer key="drawer" />
            <AppModal key="modal" />
          </AppLayout>

          <OrganizationSwitcher />
        </AuthenticatedNavContextProvider>
      )
    )
    .otherwise(() => <Outlet />);
};
