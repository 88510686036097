import React from 'react';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { SearchableFeedsListFed } from 'app/organisms/SearchableFeeds/SearchableFeedsListFed/SearchableFeedsListFed';

export const SearchableFeedsFed = () => {
  return (
    <ErrorBoundary action="SearchableFeedsFed" fallback={<CardError title="FED Feeds" />}>
      <SearchableFeedsListFed />
    </ErrorBoundary>
  );
};
