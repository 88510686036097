import React from 'react';
import { NonIdealState, NonIdealStateProps } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';

export type PaywallPageProps = Pick<NonIdealStateProps, 'icon' | 'title' | 'description'>;

export const PaywallPage = ({ icon = 'lock', title = 'This is a paid feature', description }: PaywallPageProps) => {
  description = (
    <>
      {description}
      <p>
        Please contact <a href="mailto:support@govly.com<">support@govly.com</a> to upgrade your account and gain access
        to this feature.
      </p>
    </>
  );

  return (
    <Main data-test="paywall">
      <MainColumn columnSpan={12} className="mx-auto pt-12 pb-8">
        <Card>
          <CardBody>
            <NonIdealState icon={icon} title={title} description={description} />
          </CardBody>
        </Card>
      </MainColumn>
    </Main>
  );
};
