import React from 'react';
import { ControlGroup as BlueprintControlGroup, Button, HTMLSelect, InputGroup } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';

type ControlGroupProps = {
  filterName: string;
  filterOptions: { value: string; label: string }[];
  inputName: string;
  onClear?: () => void;
};

export const ControlGroup = ({ filterName, filterOptions, inputName, onClear }: ControlGroupProps) => {
  const [filterField] = useField({ name: filterName });
  const [inputField] = useField({ name: inputName });
  const { values } = useFormikContext<Record<string, string>>();

  return (
    <BlueprintControlGroup>
      <HTMLSelect {...filterField} multiple={undefined} name={filterName} options={filterOptions} />
      <InputGroup
        {...inputField}
        name={inputName}
        placeholder="Search filter..."
        rightElement={
          values[inputName] && onClear ? <Button icon="small-cross" minimal={true} onClick={onClear} /> : undefined
        }
      />
      <Button type="submit" icon="search" />
    </BlueprintControlGroup>
  );
};
