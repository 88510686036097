import React from 'react';
import { QueryInputMultiSelect } from 'app/molecules/QueryInputMultiSelect/QueryInputMultiSelect';
import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { GovernmentEntityTypeahead } from 'types/__generated__/GovlyApi';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';
import { useGovernmentEntityTypeaheadQuery } from 'api/governmentEntitiesApi';
import { ChatFormState } from 'app/organisms/Chat/Chat';
import { CardList } from 'app/molecules/CardList/CardList';

export const BudgetsChatFilters = () => {
  const getField = useGetFormikField<ChatFormState>();

  return (
    <CardList className="m-4">
      <p className="text-base text-gray-500 mb-4">Select specific agencies to generate cross-agency budget insights.</p>
      <QueryInputMultiSelect
        useHook={useGovernmentEntityTypeaheadQuery as InputMultiSelectHook<GovernmentEntityTypeahead>}
        getItemValue={item => item.id}
        getItemsBasedProps={(items: GovernmentEntityTypeahead[]) =>
          FormikToBp.toMultiSelect({
            ...getField('filters.entityIds'),
            items,
            getItemValue: item => item.id
          })
        }
        getItemTextLabel={item => item.name}
        useTypeaheadProps={{ options: { keys: ['name', 'abbreviation'] } }}
        placeholder="Government Agencies"
      />
    </CardList>
  );
};
