import React from 'react';

import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { ManagedFeedInstructions } from 'app/organisms/ManagedFeedInstructions/ManagedFeedInstructions';
import { ManagedFeedsTable } from 'app/organisms/ManagedFeedsTable/ManagedFeedsTable';
import { supportChatMessage } from 'app/lib/support-chat';
import { SearchableFeedsSwitcher } from '../SearchableFeeds/SearchableFeedsSwitcher';

export const ManagedFeeds = () => {
  const { isLoading } = useGetProviderFeedsQuery();

  const handleAskForHelp = () => {
    supportChatMessage('Hi! Can you help me with a feed?');
  };
  if (isLoading) return <Loading />;

  return (
    <>
      <div className="w-full flex justify-between items-center">
        <SearchableFeedsSwitcher className="w-auto" searchDisabled sortDisabled />

        <p className="text-gray-500 text-xs mb-0">
          Need to add a feed?{' '}
          <span role="button" className="text-blue-500" onClick={handleAskForHelp}>
            Ask for help
          </span>
          .
        </p>
      </div>

      <ManagedFeedInstructions />
      <ManagedFeedsTable />
    </>
  );
};
