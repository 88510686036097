import React from 'react';
import { useField } from 'formik';
import {
  FormGroup,
  TagInput as BlueprintTagInput,
  FormGroupProps,
  TagInputProps as BlueprintTagInputProps
} from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

type TagInputProps = {
  name: string;
  type?: string;
  tagInputProps?: Partial<Omit<BlueprintTagInputProps, 'fields'>>;
} & FormGroupProps;

export const TagInput = ({
  name,
  className,
  type = 'text',
  helperText,
  disabled,
  inline,
  label,
  labelInfo,
  tagInputProps = {},
  ...rest
}: TagInputProps) => {
  const [field, meta, { setValue }] = useField<string[]>({ name, type });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      inline={inline}
      label={label}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      {...rest}
    >
      <BlueprintTagInput
        addOnBlur
        onAdd={v => {
          setValue([...field.value, ...v]);
        }}
        onRemove={v => {
          setValue(field.value.filter(s => s !== v));
        }}
        placeholder="Separate values with commas..."
        values={field.value}
        large
        tagProps={{ minimal: true }}
        onKeyDown={onKeyDown}
        {...tagInputProps}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
