import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AWARD_SEARCH_CACHE_KEY, useAwardSearchMutation } from 'api/awardsApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { asCurrency } from 'app/lib/numbers';
import { OnChangeFn, PaginationState, SortingState, createColumnHelper } from '@tanstack/react-table';
import { GovlyTableRoot } from 'app/molecules/GovlyTable/GovlyTable';
import { USASpendingAwardSearch } from 'types/__generated__/GovlyApi';
import { useFormikContext } from 'formik';
import { AwardSearchForm, defaultValues } from 'app/hooks/search/useAwardSearchCache';
import camelCase from 'lodash-es/camelCase';
import snakeCase from 'lodash-es/snakeCase';
import { AwardSearchResultsControls } from 'app/organisms/AwardSearchResults/AwardSearchResultsControls';
import { GovlyTableCard } from 'app/molecules/GovlyTable/GovlyTableCard';
import { GovlyTableColumnVisibilityToggles } from 'app/molecules/GovlyTable/GovlyTableColumnVisibilityToggles';
import { useAppLayoutContext } from 'app/organisms/AppLayout/AppLayoutContext';
import { GovlyTableOverflowCell } from 'app/molecules/GovlyTable/GovlyTableOverflowCell';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

const columnHelper = createColumnHelper<USASpendingAwardSearch>();

export const AwardSearchResultsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { values, setValues, submitForm } = useFormikContext<AwardSearchForm>();

  const [search, { data, isLoading }] = useAwardSearchMutation({
    fixedCacheKey: AWARD_SEARCH_CACHE_KEY
  });

  const prevData = useRef(data);
  useLayoutEffect(() => {
    if (prevData.current !== data && data) {
      prevData.current = data;
    }
  }, [data]);

  const total = (data ?? prevData.current)?.meta.total;
  const results = (data ?? prevData.current)?.results ?? [];
  const page = (data ?? prevData.current)?.meta.currentPage ?? 1;

  const [per, setPer] = useState(30);
  const pagination = useMemo<PaginationState>(
    () => ({
      pageSize: per,
      pageIndex: page - 1
    }),
    [page, per]
  );
  const { appBodyRef } = useAppLayoutContext();

  const setPagination = useCallback<OnChangeFn<PaginationState>>(
    async valOrFn => {
      const newPagination = typeof valOrFn === 'function' ? valOrFn(pagination) : valOrFn;
      const { pageSize, pageIndex } = newPagination;

      setPer(pageSize);

      const scrollContainer = appBodyRef.current;
      const yAnchor = pagination.pageIndex < pageIndex ? 'top' : 'bottom';
      setTimeout(() => {
        scrollContainer?.scrollTo({ [yAnchor]: 0, behavior: 'smooth' });
      });

      await search({
        query: values.query,
        ...values.filters,
        per: pageSize,
        page: pageIndex + 1
      });
    },
    [appBodyRef, pagination, search, values.filters, values.query]
  );

  const sorting = useMemo<SortingState>(
    () => [
      {
        id: camelCase(values.filters.sort),
        desc: values.filters.sortDirection === 'desc'
      }
    ],
    [values.filters.sort, values.filters.sortDirection]
  );

  const setSorting = useCallback<OnChangeFn<SortingState>>(
    valOrFn => {
      const [newSorting] = typeof valOrFn === 'function' ? valOrFn(sorting) : valOrFn;
      const { id, desc } = newSorting ?? { id: defaultValues.filters.sort, desc: true };
      const sort = snakeCase(id) as AwardSearchForm['filters']['sort'];
      const sortDirection = desc ? 'desc' : 'asc';

      setValues(s => ({
        ...s,
        filters: {
          ...s.filters,
          sort,
          sortDirection
        }
      }));
      submitForm();
    },
    [setValues, sorting, submitForm]
  );

  const openAward = useCallback(
    (piid: string) => {
      searchParams.set(DRAWER_PARAMS.type, 'award');
      searchParams.set(DRAWER_PARAMS.id, piid);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('awardIdPiid', {
          header: 'Award ID',
          size: 160,
          cell: e => (
            <LinkTag tag="button" onClick={() => openAward(e.row.original.id)} className="w-36 truncate text-left">
              {e.getValue()}
            </LinkTag>
          )
        }),
        columnHelper.accessor('primeAwardBaseTransactionDescription', {
          size: 200,
          header: 'Description',
          enableSorting: false,
          cell: e => <GovlyTableOverflowCell context={e} />
        }),
        columnHelper.accessor('actionDate', {
          size: 100,
          header: 'Action Date'
        }),
        columnHelper.accessor('periodOfPerformanceStartDate', {
          size: 100,
          header: 'Start Date'
        }),
        columnHelper.accessor('periodOfPerformanceCurrentEndDate', {
          size: 100,
          header: 'End Date'
        }),
        columnHelper.accessor('potentialTotalValueOfAward', {
          header: 'Potential Award Amount',
          cell: e => <div>{asCurrency(e.getValue())}</div>
        }),
        columnHelper.accessor('totalDollarsObligated', {
          header: 'Obligated Award Amount',
          cell: e => <div>{asCurrency(e.getValue())}</div>
        }),
        columnHelper.accessor('parentAwardIdPiid', {
          header: 'Parent Award PIID'
        }),
        ...(values.filters.awardOrIdvFlag === 'award'
          ? [
              columnHelper.accessor('awardType', {
                header: 'Award Type'
              })
            ]
          : [
              columnHelper.accessor('idvType', {
                header: 'IDV Type'
              })
            ]),
        columnHelper.accessor('recipientName', {
          header: 'Recipient',
          cell: e => <GovlyTableOverflowCell context={e} />
        }),
        columnHelper.accessor('awardingOfficeName', {
          header: 'Awarding Office',
          cell: e => <GovlyTableOverflowCell context={e} />
        }),
        columnHelper.accessor('awardingSubAgencyName', {
          header: 'Awarding Sub-Agency',
          cell: e => <GovlyTableOverflowCell context={e} />
        }),
        columnHelper.accessor('awardingAgencyName', {
          header: 'Awarding Agency',
          cell: e => <GovlyTableOverflowCell context={e} />
        })
      ].map(column => ({ ...column, disableSortBy: true })),
    [openAward, values.filters.awardOrIdvFlag]
  );

  return (
    <GovlyTableRoot
      isFixedLayout
      id="award-search-results-table"
      columns={columns}
      data={results}
      isLoading={isLoading}
      hasConfigurablePageSize
      paginationSteps={[30, 50, 100]}
      enableColumnFilters={false}
      manualSorting
      manualPagination
      manualFiltering
      state={{ sorting, pagination }}
      onSortingChange={setSorting}
      onPaginationChange={setPagination}
      rowCount={total}
      enableHiding
    >
      <AwardSearchResultsControls leftActions={<GovlyTableColumnVisibilityToggles />} />

      <GovlyTableCard />
    </GovlyTableRoot>
  );
};
