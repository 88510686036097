import { Follow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OppSearch', 'SavedSearch'] });

type CommonSavedSearchParams = {
  savedSearchId: string;
  savedSearchType: 'OppSearch' | 'SavedSearch';
  organizationUserId: string;
};

type CreateSavedSearchFollow = {
  params: CommonSavedSearchParams;
  result: Follow;
};

type UpdateSavedSearchFollow = {
  params: CommonSavedSearchParams & { notifications?: number };
  result: Follow;
};

type DeleteSavedSearchFollow = {
  params: CommonSavedSearchParams;
  result: void;
};

export const savedSearchFollowsApi = api.injectEndpoints({
  endpoints: build => ({
    createSavedSearchFollow: build.mutation<CreateSavedSearchFollow['result'], CreateSavedSearchFollow['params']>({
      query: body => ({
        url: `/v2/saved_search_follows`,
        method: 'POST',
        body
      }),
      invalidatesTags: (_result, _error, { savedSearchType }) => [savedSearchType]
    }),

    updateSavedSearchFollow: build.mutation<UpdateSavedSearchFollow['result'], UpdateSavedSearchFollow['params']>({
      query: body => ({
        url: `/v2/saved_search_follows`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (_result, _error, { savedSearchType }) => [savedSearchType]
    }),

    deleteSavedSearchFollow: build.mutation<DeleteSavedSearchFollow['result'], DeleteSavedSearchFollow['params']>({
      query: body => ({
        url: `/v2/saved_search_follows`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: (_result, _error, { savedSearchType }) => [savedSearchType]
    })
  })
});

export const {
  useCreateSavedSearchFollowMutation,
  useUpdateSavedSearchFollowMutation,
  useDeleteSavedSearchFollowMutation
} = savedSearchFollowsApi;
