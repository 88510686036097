import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { OppSearchResult } from 'types/__generated__/GovlyApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { Loading } from 'app/atoms/Loading/Loading';

const EmptyState = () => (
  <CardBody>
    <NonIdealState
      title="No Opportunities"
      icon="lock"
      description="Either this contact has no opportunities or you don't have permission to view them."
    />
  </CardBody>
);

type ContactOppsProps = {
  results: OppSearchResult[];
  isLoading: boolean;
};

export const ContactOpps = ({ results, isLoading }: ContactOppsProps) => {
  if (isLoading) {
    return (
      <>
        <Loading type="feed" />
      </>
    );
  }

  return (
    <Card title="Latest Opportunities">
      {!results.length ? (
        <EmptyState />
      ) : (
        results.map(result => (
          <CardBody key={result.id}>
            <div>
              <LinkTag to={`/opportunities/${result.id}`} target="_blank">
                {result.displayName}
              </LinkTag>
              <p className="m-0 text-sm text-gray-500">{result.title}</p>
            </div>
          </CardBody>
        ))
      )}
    </Card>
  );
};
