import React from 'react';
import { useFormikFilterCardListItem } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import {
  InputFilterCardListItem,
  InputFilterCardListItemProps
} from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import {
  QueryInputMultiSelect,
  QueryInputMultiSelectProps
} from 'app/molecules/QueryInputMultiSelect/QueryInputMultiSelect';
import { InputMultiSelectProps } from 'app/molecules/InputMultiSelect/InputMultiSelect';
import { useSubInputsSelected } from 'app/molecules/InputCompositeFilter/useSubInputsSelected';
import { SearchQueryInputMultiSelect } from '../QueryInputMultiSelect/SearchQueryInputMultiSelect';
import { GetHookItem, InputMultiSelectHook } from '../InputMultiSelect/utils';
import { InputCompositeFilter } from './InputCompositeFilter';

type FormikCompositeQueryMultiSelectProps<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<InputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
> = Omit<
  QueryInputMultiSelectProps<Hook, Item, ItemsBasedProps>,
  'selectedItems' | 'name' | 'onChange' | 'getItemsBasedProps'
> & {
  title: string;
  namesAndLabels: [string, string][];
  additionalNames?: string[];
  getItemValue: (item: Item) => string;
  getInputProps?: (input: {
    name: string;
    label: string;
    index: number;
  }) => Partial<QueryInputMultiSelectProps<Hook, Item, ItemsBasedProps>>;
  getItemFallback?: (value: string) => Item;
  children?: React.ReactNode;
  collapsibleChildren?: React.ReactNode;
  preFilterChildren?: React.ReactNode;
  subInputsSelected?: boolean;
  multiselectType?: 'query' | 'async';
  filterCardProps?: Partial<InputFilterCardListItemProps>;
};

function MultiSelectComponent<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<InputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
>({
  multiselectType,
  ...rest
}: { multiselectType?: 'query' | 'async' } & QueryInputMultiSelectProps<Hook, Item, ItemsBasedProps>) {
  return multiselectType === 'async' ? <SearchQueryInputMultiSelect {...rest} /> : <QueryInputMultiSelect {...rest} />;
}

export function FormikCompositeQueryMultiSelect<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<InputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
>({
  title,
  namesAndLabels,
  additionalNames = [],
  getItemValue,
  formGroupProps,
  getInputProps,
  getItemFallback,
  children,
  collapsibleChildren,
  preFilterChildren,
  subInputsSelected: subInputsSelectedProp = false,
  filterCardProps: filterCardPropsProp,
  ...props
}: FormikCompositeQueryMultiSelectProps<Hook, Item, ItemsBasedProps>) {
  const names = [...namesAndLabels.map(([n]) => n), ...additionalNames];
  const filterCardProps = useFormikFilterCardListItem({ names, submitOnChange: true });

  const getField = useGetFormikField();

  const subInputsSelected = useSubInputsSelected({ names });

  return (
    <InputFilterCardListItem title={title} {...filterCardProps} {...filterCardPropsProp}>
      {preFilterChildren}

      <InputCompositeFilter subInputsSelected={subInputsSelected || subInputsSelectedProp}>
        {namesAndLabels.map(([name, label], index) => {
          const { formGroupProps: inputFormGroupProps, ...inputProps } = getInputProps?.({ name, label, index }) ?? {};
          return (
            <MultiSelectComponent
              key={name}
              getItemsBasedProps={items =>
                FormikToBp.toMultiSelect({
                  ...getField(name),
                  items,
                  getItemValue,
                  getItemFallback,
                  shouldCacheValueMap: props.multiselectType === 'async'
                })
              }
              formGroupProps={{ label, ...formGroupProps, ...inputFormGroupProps }}
              getItemValue={getItemValue}
              {...inputProps}
              {...props}
            />
          );
        })}

        {collapsibleChildren}
      </InputCompositeFilter>

      {children}
    </InputFilterCardListItem>
  );
}
