import { useUpdateOrganizationMutation } from 'api/organizationsApi';
import { successToast } from 'app/lib/toaster';

export const useActivateSLED = () => {
  const [update, { isLoading: isUpdating }] = useUpdateOrganizationMutation();

  return {
    handleChange: (organizationId: string, activated = true) => {
      update({
        id: organizationId,
        organization: {
          subscriptionHasSLEDActivated: activated
        }
      }).then(() => {
        successToast();
      });
    },
    isUpdating
  };
};
