import React from 'react';
import { Link } from 'react-router-dom';

import { getLogoUrl } from 'app/lib/logo';
import { useIsDarkMode } from 'app/hooks/useThemeToggle';

type CenterPageHighlightProps = {
  showLogo?: boolean;
  children: React.ReactNode;
};

export const CenterPageHighlight = ({ showLogo, children }: CenterPageHighlightProps) => {
  const isDarkMode = useIsDarkMode();
  const logoUrl = getLogoUrl({ theme: isDarkMode ? 'dark' : 'light' });

  return (
    <div className="grid w-full place-items-center">
      <div className="mt-36 flex min-w-[65%] flex-col justify-center space-y-16 py-12 sm:px-6 lg:px-8">
        {showLogo && (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img className="mx-auto h-16 w-auto" src={logoUrl} alt="Govly Logo" />
            </Link>
          </div>
        )}

        <div className="mb-24 sm:mx-auto sm:w-full sm:max-w-md">{children}</div>
      </div>
    </div>
  );
};
