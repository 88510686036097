import { useCallback, useState } from 'react';
import { useFormikContext, useField } from 'formik';
import debounce from 'just-debounce-it';
import isEqual from 'lodash-es/isEqual';

export type UseInputProps = {
  name: string;
  type?: string;
  submitOnChange?: boolean;
  debounceTime?: number;
};

export const useFormikInput = <Val>({ name, type, submitOnChange = false, debounceTime = 500 }: UseInputProps) => {
  const { submitForm } = useFormikContext();
  const [field, meta, { setValue, ...helpers }] = useField<Val>({ name, type });
  const [prevValue, setPrevValue] = useState(field.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => submitForm(), debounceTime),
    [submitForm]
  );

  const onChange = (newValue: typeof prevValue, { submit = submitOnChange }: { submit?: boolean } = {}) => {
    setValue(newValue);

    if (submit && !isEqual(prevValue, newValue)) {
      setPrevValue(newValue);
      debouncedSubmit();
    }
  };

  return { name, field, meta, helpers, onChange, submitForm };
};
