import React from 'react';
import { useRefinementList, useClearRefinements, useInstantSearch } from 'react-instantsearch';

import { Button } from '@blueprintjs/core';
import { SearchFacetCheckboxes } from 'app/molecules/SearchFacetCheckboxes/SearchFacetCheckboxes';

type NumberOfEmployeesFacet = {
  name: string;
  count: number;
  isRefined: boolean;
};

const sortNumberOfEmployeesFacets = (a: NumberOfEmployeesFacet, b: NumberOfEmployeesFacet) => {
  return parseInt(b.name.replace(/[,+]/, '')) - parseInt(a.name.replace(/[,+]/, ''));
};

export const VendorProfileSearchFacets = () => {
  const { indexUiState } = useInstantSearch();
  const hasFilters = !!indexUiState?.refinementList;

  const { refine: clearRefinements } = useClearRefinements();
  const { items: numberOfEmployeesFacets, refine: numberOfEmployeesRefine } = useRefinementList({
    attribute: 'numberOfEmployees',
    sortBy: sortNumberOfEmployeesFacets
  });

  const { items: tagFacets, refine: tagFacetsRefine } = useRefinementList({ attribute: '_tags' });

  const {
    items: contractVehicleFacets,
    refine: contractVehicleFacetsRefine,
    searchForItems: searchForContractVehicles,
    hasExhaustiveItems: hasExhaustiveContractVehicles
  } = useRefinementList({
    attribute: 'contractVehicles'
  });

  const {
    items: channelPartnerFacets,
    refine: channelPartnerFacetsRefine,
    searchForItems: searchForChannelPartners,
    hasExhaustiveItems: hasExhaustiveChannelPartners
  } = useRefinementList({
    attribute: 'channelPartners'
  });

  const {
    items: resellerFacets,
    refine: resellerFacetsRefine,
    searchForItems: searchForResellers,
    hasExhaustiveItems: hasExhaustiveResellers
  } = useRefinementList({
    attribute: 'resellers'
  });

  const { items: setAsideFacets, refine: setAsideFacetsRefine } = useRefinementList({
    attribute: 'setAsides.name'
  });

  return (
    <div className="mx-4 lg:mx-0">
      {hasFilters && (
        <div className="mb-4 flex justify-end">
          <Button rightIcon="cross" text="Clear Filters" minimal intent="primary" onClick={() => clearRefinements()} />
        </div>
      )}
      <SearchFacetCheckboxes
        facets={contractVehicleFacets}
        refine={contractVehicleFacetsRefine}
        searchForItems={searchForContractVehicles}
        hasExhaustiveItems={hasExhaustiveContractVehicles}
        label="Contract Holders"
      />
      <SearchFacetCheckboxes facets={setAsideFacets} refine={setAsideFacetsRefine} label="Set Asides" />
      <SearchFacetCheckboxes
        facets={channelPartnerFacets}
        refine={channelPartnerFacetsRefine}
        searchForItems={searchForChannelPartners}
        hasExhaustiveItems={hasExhaustiveChannelPartners}
        label="Channel Partners"
      />
      <SearchFacetCheckboxes
        facets={resellerFacets}
        refine={resellerFacetsRefine}
        label="Resellers"
        searchForItems={searchForResellers}
        hasExhaustiveItems={hasExhaustiveResellers}
      />
      <SearchFacetCheckboxes facets={tagFacets} refine={tagFacetsRefine} label="Tags" />
      <SearchFacetCheckboxes
        facets={numberOfEmployeesFacets}
        refine={numberOfEmployeesRefine}
        label="Number of Employees"
      />
    </div>
  );
};
