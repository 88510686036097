import React from 'react';
import { Tag } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';
import { Intent } from '@blueprintjs/core';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';

type UserProfileSubscriptionTagProps = Pick<OrganizationUserCurrentOrg, 'subscriptionType' | 'subscriptionSeatPaid'>;

export const getUserProfileSubscriptionTagInfo = ({
  subscriptionType,
  subscriptionSeatPaid
}: UserProfileSubscriptionTagProps) => {
  let label = subscriptionType;
  let intent: Intent = 'success';

  if (subscriptionType === 'trial') {
    intent = 'warning';
  }
  if (subscriptionType === 'free' || !subscriptionSeatPaid) {
    label = 'free';
    intent = 'primary';
  }
  return {
    label,
    intent
  };
};

export const UserProfileSubscriptionTag = ({
  subscriptionType,
  subscriptionSeatPaid
}: UserProfileSubscriptionTagProps) => {
  const { label, intent } = getUserProfileSubscriptionTagInfo({
    subscriptionType,
    subscriptionSeatPaid
  });

  return (
    <Tag minimal intent={intent}>
      {startCase(label)}
    </Tag>
  );
};
