import { notNullish } from 'app/lib/notNullish';

type Address = {
  street?: string;
  unit?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export const formatAddress = (address: Address) => {
  let cityState = [address.city, address.state].filter(Boolean).join(', ');
  if (address.zip) cityState += ` ${address.zip}`;

  return [address.street, address.unit, cityState, address.country].filter(notNullish);
};
