import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { BarChart } from 'react-chartkick';
import { CSVLink } from 'react-csv';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useSearchMutation } from 'api/oppsApi';

const chartOptions = {
  plotOptions: {
    bar: {
      colorByPoint: true
    },
    column: {
      colorByPoint: true
    }
  }
};

const slices = [
  { key: 'topVendorsAgg', label: 'Top Vendors' },
  { key: 'trendingVendorsAgg', label: 'Trending Vendors' }
];

type OppAggregationVendorsProps = {
  query: string;
  filters: { contactIds: string[]; contactAggregation: boolean };
  fixedCacheKey?: string;
  withTrending?: boolean;
};

type VendorAgg = {
  topVendorsAgg?: [string, number][];
  trendingVendorsAgg?: [string, number][];
};

type AggregationQueryResponse = {
  aggs: VendorAgg;
};

export const OppAggregationVendors = ({ query, filters, fixedCacheKey, withTrending }: OppAggregationVendorsProps) => {
  const [viewAgg, setViewAgg] = useState('topVendorsAgg');

  const aggTypes = withTrending ? ['topVendorsAgg', 'trendingVendorsAgg'] : ['topVendorsAgg'];

  const payload = {
    query,
    ...filters,
    aggs: aggTypes,
    preprocessor: 'Opp::Analytics::VendorAggPreprocessor',
    aggregationQuery: true
  };

  const [aggregate, { data: aggData = {}, isLoading, isError }] = useSearchMutation({ fixedCacheKey });
  const aggs = (aggData as AggregationQueryResponse)?.aggs ?? {};

  useEffect(() => {
    aggregate(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let data = [];
  let csv: string;

  const agg = viewAgg === 'trendingVendorsAgg' ? aggs.trendingVendorsAgg : aggs.topVendorsAgg;

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!agg || isLoading) {
    return <Loading />;
  }

  csv = 'Vendor,Count';
  data = agg.map(point => {
    csv += `\n${point.join(',')}`;
    return point;
  });

  return (
    <Card
      className="mb-4"
      title="Vendors"
      rightElement={
        csv.length ? (
          <CSVLink data={csv} filename={`govly-posted-at-results.csv`} className="text-gray-400 no-underline">
            <Button outlined icon="download">
              Download Data
            </Button>
          </CSVLink>
        ) : undefined
      }
    >
      <CardBody>
        <BarChart data={data} library={chartOptions} />
      </CardBody>
      {withTrending && (
        <CardBody className="text-center">
          <ButtonGroup>
            {slices.map(({ key, label }) => (
              <Button key={key} active={viewAgg === key} onClick={() => setViewAgg(key)} text={label} />
            ))}
          </ButtonGroup>
        </CardBody>
      )}
    </Card>
  );
};
