import React from 'react';
import { Dialog } from '@blueprintjs/core';

import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';
import { WorkspaceWorklist } from 'types/__generated__/GovlyApi';
import { WorkspaceFormAttrs } from '../WorklistWorkspaces/useWorklist';

type WorklistFormDialogProps = {
  workspaces: WorkspaceWorklist[];
  workspaceFormAttrs: WorkspaceFormAttrs | null;
  setWorkspaceFormAttrs: (arg: WorkspaceFormAttrs | null) => void;
};

export const WorklistFormDialog = ({
  workspaces,
  workspaceFormAttrs,
  setWorkspaceFormAttrs
}: WorklistFormDialogProps) => {
  if (!workspaceFormAttrs) return null;

  const space = workspaces.find(w => w.id === workspaceFormAttrs.id);

  if (!space) return null;

  return (
    <Dialog
      onClose={() => setWorkspaceFormAttrs(null)}
      isOpen={workspaceFormAttrs?.id === space.id}
      className="min-w-[50%] p-0"
    >
      <WorkspaceForm
        title={space.organizationDefault && space.name === 'Govly' ? 'Default Workspace' : space.name}
        titleDescription={space.oppData?.displayName}
        onCancel={() => setWorkspaceFormAttrs(null)}
        workableTypeForLink="opportunities"
        initialValues={{
          id: space.id,
          name: space.name,
          oppId: space.oppData.id,
          organizationDefault: space.organizationDefault,
          privateAccess: space.privateAccess,
          workflowStage: space.workflowStage
        }}
        {...workspaceFormAttrs}
        follows={space.follows ?? []}
        basicInputs
        followersInputs
        stageInputs
      />
    </Dialog>
  );
};
