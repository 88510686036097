import React from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { AwardDetailsOverviewContent } from 'app/organisms/AwardDetailsOverviewContent/AwardDetailsOverviewContent';

export const AwardDetailsOverviewPage = () => {
  const { id } = useParams();

  if (!id) return <NotFound />;

  return <AwardDetailsOverviewContent id={id} />;
};
