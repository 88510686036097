import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { H3 } from 'app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { WorkspaceFormNotificationsInput } from 'app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { workflowStages } from 'app/lib/workflowStages';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { OrganizationUserDisplayable, OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';
import { useWorkspaceForm, UseWorkspaceFormArgs } from 'app/organisms/WorkspaceForm/useWorkspaceForm';

type WorkspaceFormProps = UseWorkspaceFormArgs & {
  basicInputs?: boolean;
  organizationDefault?: boolean;
  stageInputs?: boolean;
  submitButtonText?: string;
  subTitle?: string;
  titleDescription?: string;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'>>;

export const WorkspaceForm = ({
  onCancel,
  title,
  titleDescription,
  initialValues,
  workableTypeForLink,
  basicInputs = false,
  stageInputs = false,
  followersInputs = true,
  follows,
  redirectOnSuccess = false,
  notifyNewFollowersOnly = true,
  organizationDefault = false,
  submitButtonText,
  successMessage,
  trackingData = {},
  shouldTrack = true,
  subTitle,
  enableReinitialize = false,
  ...rest
}: WorkspaceFormProps) => {
  const { data: teamData = [], isLoading: teamsLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const team = (teamData as OrganizationUserCurrentOrg[]) ?? [];

  const { data: partnersData = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'follower_select_input'
  });
  const partners = partnersData as OrganizationUserDisplayable[];

  const form = useWorkspaceForm({
    onCancel,
    initialValues,
    workableTypeForLink,
    followersInputs,
    follows,
    redirectOnSuccess,
    notifyNewFollowersOnly,
    successMessage,
    trackingData,
    shouldTrack,
    enableReinitialize
  });

  const stageInputStep = (step: number) => {
    if (basicInputs && !organizationDefault) step += 1;
    if (basicInputs) step += 1;

    return step;
  };

  const followersInputStep = (step: number) => {
    if (basicInputs && !organizationDefault) step += 1;
    if (basicInputs && stageInputs) return step + 2;
    if (stageInputs) return step + 1;
    if (basicInputs) return step + 1;

    return step;
  };

  const followerText = subTitle || 'added to this Workspace?';

  return (
    <div {...rest}>
      <Formik {...form}>
        {({ isSubmitting }) => (
          <Form>
            <Card
              title={title}
              subtitle={titleDescription}
              rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}
            >
              <CardBody>
                {basicInputs && (
                  <>
                    <div className="space-y-4">
                      <H3>1. What should we call this Workspace?</H3>
                      <TextInput name="name" inputProps={{ placeholder: 'Planning', large: true }} />
                    </div>
                    {!organizationDefault && (
                      <div className="space-y-4">
                        <H3>2. Should people not following the Workspace be able to view it?</H3>
                        <SwitchInput large name="privateAccess" label="Private?" data-test="private-workspace-toggle" />
                      </div>
                    )}
                  </>
                )}
                {stageInputs && (
                  <>
                    <div className="space-y-4">
                      <H3>{stageInputStep(1)}. What is the current stage of this Workspace?</H3>
                      <SelectInput
                        name="workflowStage"
                        items={workflowStages}
                        filterable={false}
                        filterActive={false}
                        shouldDismissPopover={true}
                      />
                    </div>
                  </>
                )}
                {followersInputs && (
                  <>
                    <div className="space-y-4">
                      <H3>{`${followersInputStep(1)}. Which team members should be ${followerText}`}</H3>
                      <FollowerSelectInput
                        name="teamFollows"
                        defaultButtonText="Add a team member"
                        organizationUsers={team || []}
                        disabled={teamsLoading}
                        buttonProps={{ loading: teamsLoading }}
                        data-test="workspace-followers-input"
                      />
                    </div>
                    <div className="space-y-4">
                      <H3>{`${followersInputStep(2)}. Which partners should be ${followerText}`}</H3>
                      <FollowerSelectInput
                        name="partnerFollows"
                        defaultButtonText="Add a partner"
                        organizationUsers={partners || []}
                        disabled={partnersLoading}
                        buttonProps={{ loading: partnersLoading }}
                        data-test="workspace-partners-input"
                      />
                    </div>
                  </>
                )}

                <WorkspaceFormNotificationsInput notifyNewFollowersOnly={notifyNewFollowersOnly} />
              </CardBody>

              <CardFooter>
                <Button
                  large
                  type="submit"
                  text={submitButtonText || (initialValues.id ? 'Save' : 'Create')}
                  intent="primary"
                  loading={isSubmitting}
                  disabled={teamsLoading || isSubmitting}
                  data-test="workspace-submit-button"
                />
                {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};
