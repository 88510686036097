import React from 'react';
import { Button } from '@blueprintjs/core';
import { useSearchQueryInputContext } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { useAssistantContext } from '../Assistant/Assistant';

type KeywordAssistantUserQueryStatmentProps = {
  preventFocus?: boolean;
};

const KeywordAssistantUserQueryStatement = ({ preventFocus }: KeywordAssistantUserQueryStatmentProps) => {
  const { sendNewMessage } = useAssistantContext();
  const { value } = useSearchQueryInputContext();

  const sendUserQuery = () => {
    sendNewMessage(`Please help me refine this query: ${value}`);
  };

  if (!value) {
    return null;
  }

  return (
    <>
      <br />
      <p>Would you like assistance refining your current search query?</p>
      <Button
        onClick={sendUserQuery}
        className="border rounded bg-grey-100 mt-1 p-2 px-3 hover:bg-grey-200"
        tabIndex={preventFocus ? -1 : undefined}
      >
        Yes, please!
      </Button>
    </>
  );
};

export { KeywordAssistantUserQueryStatement };
