import { create } from 'zustand';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { match } from 'ts-pattern';
import { AwardSearchFilters } from 'app/hooks/search/useAwardSearchCache';
import { AwardSearchAnalyticsTableDrawer } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsTableDrawer';

export type SliceType =
  | 'actionDateTotalAgg'
  | 'actionDateAwardingAgencyAgg'
  | 'actionDateFundingAgencyAgg'
  | 'actionDateRecipientAgg'
  | 'actionDateSetAsideAgg';

export const SLICES: Array<{ key: SliceType; label: string }> = [
  { key: 'actionDateTotalAgg', label: 'Total' },
  { key: 'actionDateFundingAgencyAgg', label: 'Funding Agency' },
  { key: 'actionDateAwardingAgencyAgg', label: 'Awarding Agency' },
  { key: 'actionDateRecipientAgg', label: 'Recipient' },
  { key: 'actionDateSetAsideAgg', label: 'Set Asides' }
];

type State = {
  sliceType: SliceType;
  view: 'amount' | 'total';
  isDrawerOpen: boolean;
  lastClickedPoint?: Highcharts.Point;
};

type Action = {
  setSliceType: (sliceType: State['sliceType']) => void;
  setView: (view: State['view']) => void;
  setIsDrawerOpen: (isDrawerOpen: State['isDrawerOpen']) => void;
};

type Store = State & Action;

export const useAwardSearchAnalyticsHistogramStore = create<Store>(set => {
  return {
    sliceType: 'actionDateTotalAgg',
    view: 'amount',
    lastClickedPoint: undefined,
    isDrawerOpen: false,

    setSliceType: sliceType => set({ sliceType }),
    setView: view => set({ view }),
    setIsDrawerOpen: isDrawerOpen => set({ isDrawerOpen })
  };
});

export const selectedSeriesSelector = (state: Store) => {
  return [state.lastClickedPoint?.series?.name].filter((s): s is string => Boolean(s));
};

const getUTCMonthRange = (dateOrNumber: Date | number): [Date, Date] => {
  const date = new Date(dateOrNumber);
  const utcMonth = date.getUTCMonth();
  const utcYear = date.getUTCFullYear();
  const utcStart = new Date(Date.UTC(utcYear, utcMonth, 1));
  const utcEnd = new Date(Date.UTC(utcYear, utcMonth + 1, 0, 23, 59, 59));
  return [utcStart, utcEnd];
};

export const drilldownFiltersSelector = (store: Store) => {
  const { sliceType } = store;
  const selectedSeries = selectedSeriesSelector(store);

  const drilldownFiltersPattern = { sliceType };
  return match<typeof drilldownFiltersPattern, Partial<AwardSearchFilters>>(drilldownFiltersPattern)
    .with({ sliceType: 'actionDateAwardingAgencyAgg' }, () => ({
      awardingAgencyNames: { any: selectedSeries, none: [] }
    }))
    .with({ sliceType: 'actionDateFundingAgencyAgg' }, () => ({
      fundingAgencyNames: { any: selectedSeries, none: [] }
    }))
    .with({ sliceType: 'actionDateRecipientAgg' }, () => ({ recipientNames: { any: selectedSeries, none: [] } }))
    .with({ sliceType: 'actionDateSetAsideAgg' }, () => ({ setAsides: { any: selectedSeries, none: [] } }))
    .with({ sliceType: 'actionDateTotalAgg' }, () => {
      const point = store.lastClickedPoint;
      if (!point) return {};
      const [utcStart, utcEnd] = getUTCMonthRange(point.x);

      return { dateRangeParam: 'action_date', dateRange: [utcStart, utcEnd] };
    })
    .otherwise(() => ({}));
};

export const sliceLabelSelector = (store: Store) => {
  const { sliceType } = store;
  return SLICES.find(slice => slice.key && slice.key === sliceType)?.label;
};

export const columnVisibilitySelector = (store: Store) => {
  const { sliceType } = store;

  return match<typeof sliceType, React.ComponentProps<typeof AwardSearchAnalyticsTableDrawer>['columnVisibility']>(
    sliceType
  )
    .with('actionDateAwardingAgencyAgg', 'actionDateFundingAgencyAgg', () => ({
      awardingAgencyName: false,
      fundingAgencyName: false
    }))
    .otherwise(() => ({}));
};
