import React from 'react';
import { Checkbox, SegmentedControl } from '@blueprintjs/core';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import { AWARD_TYPES, AwardSearchForm, IDV_TYPES } from 'app/hooks/search/useAwardSearchCache';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { useFormikContext } from 'formik';

export const AwardSearchFiltersAwardType = () => {
  const { values, setFieldValue } = useFormikContext<AwardSearchForm>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<AwardSearchForm>();

  const getField = useGetFormikField<AwardSearchForm>();

  return (
    <InputFilterCardListItem
      defaultIsOpen
      title={
        <span className="space-x-2">
          <span>Award Type</span>

          <InfoTooltip>
            IDV (Indefinite Delivery Vehicle, ie SEWP, MAS, or ITES) is a specific type of contract that allows for an
            indefinite quantity of supplies or services, while a contract award is a broader term that includes all
            types of binding agreements between a buyer and seller.
          </InfoTooltip>
        </span>
      }
      {...getFilterCardProps({
        names: ['filters.awardType', 'filters.idvType', 'filters.awardOrIdvFlag'],
        submitOnChange: true
      })}
    >
      <SegmentedControl
        fill
        options={[
          { label: 'Award', value: 'award' },
          { label: 'IDV', value: 'idv' }
        ]}
        {...FormikToBp.toSegmentedControl({
          ...getField('filters.awardOrIdvFlag'),
          onValueChange: val => {
            if (val === 'award') {
              setFieldValue(
                'filters.awardType',
                AWARD_TYPES.map(({ value }) => value)
              );
            } else if (val === 'idv') {
              setFieldValue(
                'filters.idvType',
                IDV_TYPES.map(({ value }) => value)
              );
            }
          }
        })}
      />

      {(values.filters.awardOrIdvFlag === 'award' ? AWARD_TYPES : IDV_TYPES).map(({ label, value }) => (
        <Checkbox
          key={value}
          label={label}
          {...FormikToBp.toCheckbox({
            ...getField(values.filters.awardOrIdvFlag === 'award' ? 'filters.awardType' : 'filters.idvType'),
            value
          })}
        />
      ))}
    </InputFilterCardListItem>
  );
};
