import React from 'react';
import { Button } from '@blueprintjs/core';
import { useEventTracking } from 'app/hooks/useEventTracking';

import { useCreatePartnerFeedMutation } from 'api/partnerFeedsApi';
import { successToast } from 'app/lib/toaster';

type GrantFeedAccessButtonProps = {
  partnershipId: string;
  organizationId: string;
  providerFeedPublicId: string;
};

export const GrantFeedAccessButton = ({
  partnershipId,
  organizationId,
  providerFeedPublicId
}: GrantFeedAccessButtonProps) => {
  const { trackEvent } = useEventTracking();
  const [createdPartnerFeed] = useCreatePartnerFeedMutation();

  return (
    <Button
      small
      icon="plus"
      intent="success"
      onClick={async () => {
        const { data } = await createdPartnerFeed({
          partnershipId,
          organizationId,
          providerFeedPublicId
        });

        successToast('Access granted! An email has been sent to their admin to activate the feed in their dashboad.');
        trackEvent({
          object: 'contract_feed',
          action: 'access_granted',
          properties: { ...data }
        });
      }}
    >
      Grant Access
    </Button>
  );
};
