import React from 'react';
import { Button, RadioGroup } from '@blueprintjs/core';
import { DateRangeInput3 } from '@blueprintjs/datetime2';
import { useFormikContext, FieldArray } from 'formik';
import { match } from 'ts-pattern';
import enUS from 'date-fns/locale/en-US';

import { defaultState, OppSearchState, DateRangeParam } from 'app/hooks/search/useOppSearchCache';
import { InputFilterCardListItem } from 'app/molecules/InputFilterCardListItem/InputFilterCardListItem';
import { useGetFormikFilterCardListItemProps } from 'app/molecules/InputFilterCardListItem/useFormikFilterCardListItem';
import { FormikToBp } from 'app/lib/formikToBp';
import { makeSafeFormikFields } from 'app/lib/formik';
import { DeepKeys } from '@tanstack/react-table';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { tenYearsFromNow, pastDateShortcuts, futureDateShortcuts, tenYearsAgo } from 'app/lib/dates';

const { safeField } = makeSafeFormikFields<OppSearchState>();

const DEFAULT_DATE_RANGE = {
  range: defaultState.filters.dateRange,
  param: defaultState.filters.dateRangeParam
};

const DATE_RANGE_OPTIONS: { label: string; value: DateRangeParam }[] = [
  { label: 'Posted At', value: 'posted_at' },
  { label: 'Modified At', value: 'modified_at' },
  { label: 'Respond By', value: 'respond_by' }
];

const FORECASTED_DATE_RANGE_OPTIONS: { label: string; value: DateRangeParam }[] = [
  { label: 'Forecasted At', value: 'respond_by' },
  { label: 'Posted At', value: 'posted_at' }
];

export const OppSearchDateRangeFilter = () => {
  const { values } = useFormikContext<OppSearchState>();
  const getFilterCardProps = useGetFormikFilterCardListItemProps<OppSearchState>();

  const { forecastStatus } = values.filters;

  const isForecasted = forecastStatus === 'forecasted';

  const getField = useGetFormikField<OppSearchState>();

  const existingDateRangeParams = new Set([
    values.filters.dateRangeParam,
    ...values.filters.secondaryDateRanges.map(x => x.param)
  ]);

  const dateRangeOptions = isForecasted ? FORECASTED_DATE_RANGE_OPTIONS : DATE_RANGE_OPTIONS;

  return (
    <InputFilterCardListItem
      title="Date Range"
      defaultIsOpen
      {...getFilterCardProps({
        names: ['filters.dateRange', 'filters.dateRangeParam', 'filters.secondaryDateRanges'],
        submitOnChange: true
      })}
    >
      <div>
        <div className="space-y-4">
          <FieldArray name={safeField('filters.secondaryDateRanges')}>
            {helpers => (
              <>
                {[
                  { range: values.filters.dateRange, param: values.filters.dateRangeParam },
                  ...values.filters.secondaryDateRanges
                ].map((dateRange, index, all) => {
                  const isPrimary = index === 0;
                  const isSecondary = index > 0;
                  const secondaryIndex = index - 1;

                  const options = dateRangeOptions.filter(({ value }) => {
                    const isNotLast = index + 1 < all.length;
                    if (isNotLast) {
                      const valueMatches = value === dateRange.param;
                      return valueMatches;
                    }

                    const notFoundInPrecedingRanges = !all.slice(0, index).find(x => x.param === value);
                    return notFoundInPrecedingRanges;
                  });

                  return (
                    <div key={index} className="relative" role="group" aria-label="date range group">
                      <RadioGroup
                        inline
                        options={options}
                        {...FormikToBp.toRadioGroup(
                          getField(
                            isPrimary
                              ? 'filters.dateRangeParam'
                              : (`filters.secondaryDateRanges.${secondaryIndex}.param` as DeepKeys<OppSearchState>)
                          )
                        )}
                      />

                      <DateRangeInput3
                        {...FormikToBp.toDateRange(
                          getField(
                            isPrimary
                              ? 'filters.dateRange'
                              : (`filters.secondaryDateRanges.${secondaryIndex}.range` as DeepKeys<OppSearchState>)
                          )
                        )}
                        maxDate={tenYearsFromNow()}
                        minDate={tenYearsAgo()}
                        locale={enUS}
                        allowSingleDayRange
                        fill
                        shortcuts={match({
                          isForecasted,
                          dateRangeParam: dateRange.param
                        })
                          .with({ dateRangeParam: 'respond_by' }, () => futureDateShortcuts)
                          .with({ isForecasted: true }, () => futureDateShortcuts)
                          .otherwise(() => pastDateShortcuts)()}
                      />

                      {isSecondary && (
                        <Button
                          className="absolute -top-1 -right-1"
                          aria-label={`Remove ${dateRange.param} date range`}
                          small
                          intent="danger"
                          minimal
                          icon="remove"
                          onClick={() => helpers.remove(secondaryIndex)}
                        />
                      )}
                    </div>
                  );
                })}

                <Button
                  fill
                  disabled={values.filters.secondaryDateRanges.length === 2}
                  outlined
                  small
                  icon="add"
                  aria-label="Add date range"
                  onClick={() => {
                    const param = dateRangeOptions.find(x => !existingDateRangeParams.has(x.value))?.value;
                    helpers.push({ param, range: DEFAULT_DATE_RANGE.range });
                  }}
                />
              </>
            )}
          </FieldArray>
        </div>
      </div>
    </InputFilterCardListItem>
  );
};
