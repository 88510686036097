import { LlmFile } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['LlmFile'] });

type GetLlmFile = {
  params: { externalId: string };
  result: LlmFile;
};

export const llmFilesApi = api.injectEndpoints({
  endpoints: build => ({
    getLlmFile: build.query<GetLlmFile['result'], GetLlmFile['params']>({
      query: ({ externalId }) => ({
        url: `/v2/llm/files/${externalId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetLlmFileQuery } = llmFilesApi;
