import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';

export const useGovlyTablePagination = () => {
  const { table } = useGovlyTableContext();
  const total = table.getRowCount();

  const { pageSize, pageIndex } = table.getState().pagination;
  const currentPage = pageIndex + 1;

  const currentRangeEnd = Math.min(pageSize * currentPage, total);
  const currentRangeStart = pageSize * (currentPage - 1) + 1;

  return {
    total,
    currentRangeStart,
    currentRangeEnd,
    pageSize
  };
};
