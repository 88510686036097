import { ActorRunTrawler } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetTrawlerRuns = {
  params: {
    trawlerConfigId: string;
  };
  result: ActorRunTrawler[];
};

export const sysAdminTrawlerRunsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getTrawlerRuns: build.query<GetTrawlerRuns['result'], GetTrawlerRuns['params']>({
      query: params => ({
        url: '/v2/sys_admin_trawler_runs',
        params
      })
    })
  })
});

export const { useGetTrawlerRunsQuery } = sysAdminTrawlerRunsApi;
