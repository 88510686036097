import React, { useEffect } from 'react';

import { useGetContactsQuery, useGetContactQuery, useContactSearchMutation } from 'api/contactsApi';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Card } from 'app/atoms/Card/Card';
import { AddressesCard } from 'app/molecules/AddressesCard/AddressesCard';
import { SearchDrawerNextPrevButtons } from 'app/organisms/SearchDrawerNextPrevButtons/SearchDrawerNextPrevButtons';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { ContactPageDescriptionFields } from 'app/organisms/ContactPageDescriptionFields/ContactPageDescriptionFields';
import { ContactOpps } from 'app/organisms/ContactOpps/ContactOpps';
import { useSearchMutation } from 'api/oppsApi';
import { OppAggregationPostedAt } from 'app/organisms/OppAggregationPostedAt/OppAggregationPostedAt';
import { OppAggregationVendors } from 'app/organisms/OppAggregationVendors/OppAggregationVendors';
import { ContactIdTag } from 'app/organisms/ContactIdTag/ContactIdTag';
import { ContactPhoneNumbers } from 'app/organisms/ContactPhoneNumbers/ContactPhoneNumbers';
import { ContactTitles } from 'app/organisms/ContactTitles/ContactTitles';
import { useCreateWorkableInteractionMutation } from 'api/workableInteractionsApi';
import { WorkableDetailsActions } from 'app/organisms/WorkableDetailsActions/WorkableDetailsActions';
import { useMetaTags } from 'app/hooks/useMetaTags';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { ContactIndex } from 'types/__generated__/GovlyApi';

type ContactPageContentsProps = {
  id: string;
  inDrawer?: boolean;
};

export const ContactPageContents = ({ id, inDrawer }: ContactPageContentsProps) => {
  const [searchLatestOpps, { data: { results: latestOpps = [] } = {}, isLoading: latestOppsAreLoading }] =
    useSearchMutation();
  const [createInteraction] = useCreateWorkableInteractionMutation();
  const { data: result, isLoading: resultLoading } = useGetContactQuery({ id });
  const { indexContact = {} as ContactIndex, indexContactLoading } = useGetContactsQuery(
    { ids: [id] },
    {
      selectFromResult: ({ data = [], isLoading: indexContactLoading }) => ({
        indexContact: data.find(({ id: contactId }) => contactId === id),
        indexContactLoading
      })
    }
  );

  useMetaTags(
    {
      title: indexContact?.name
        ? `${indexContact.name} (Contact)`
        : indexContact?.email
          ? `${indexContact.email} (Contact)`
          : 'Contact Details'
    },
    [indexContact]
  );

  useEffect(() => {
    searchLatestOpps({ contactIds: [id], per: 5 });
    createInteraction({ interactableType: 'Contact', interactableId: id, viewed: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (resultLoading) return <AppLoading />;
  if (!result) return <NotFound />;

  return (
    <Main className="min-h-screen max-w-full bg-gray-50">
      {inDrawer && (
        <SearchDrawerNextPrevButtons
          id={id}
          mutation={useContactSearchMutation}
          resource="contacts"
          cacheKey="contact-search"
        />
      )}
      <MainColumn columnSpan={8} className="order-1">
        <Card title={<ContactIdTag name={result.name} email={result.email} />}>
          <ContactPageDescriptionFields contact={result} />
        </Card>
        <div className="lg:hidden space-y-8">
          <WorkableDetailsActions resource={indexContact} isLoading={indexContactLoading} />
          <ContactOpps results={latestOpps} isLoading={latestOppsAreLoading} />
        </div>
        <OppAggregationPostedAt
          query=""
          filters={{ contactIds: [id], contactAggregation: true }}
          title="Opportunities Over Time"
        />
        <OppAggregationVendors query="" filters={{ contactIds: [id], contactAggregation: true }} />
        <AddressesCard addresses={result?.locations || []} />
      </MainColumn>
      <MainColumn columnSpan={4} className="order-2 hidden lg:block">
        <WorkableDetailsActions resource={indexContact} isLoading={indexContactLoading} />
        <ContactOpps results={latestOpps} isLoading={latestOppsAreLoading} />
        <ContactPhoneNumbers contact={result} />
        <ContactTitles contact={result} />
      </MainColumn>
    </Main>
  );
};
