export const US_CONGRESSIONAL_DISTRICT_ABBR_TO_NAME = {
  'AK-00': 'AK Congressional District (at Large)',
  'AL-01': 'AL Congressional District 1',
  'AL-02': 'AL Congressional District 2',
  'AL-03': 'AL Congressional District 3',
  'AL-04': 'AL Congressional District 4',
  'AL-05': 'AL Congressional District 5',
  'AL-06': 'AL Congressional District 6',
  'AL-07': 'AL Congressional District 7',
  'AR-01': 'AR Congressional District 1',
  'AR-02': 'AR Congressional District 2',
  'AR-03': 'AR Congressional District 3',
  'AR-04': 'AR Congressional District 4',
  'AZ-01': 'AZ Congressional District 1',
  'AZ-02': 'AZ Congressional District 2',
  'AZ-03': 'AZ Congressional District 3',
  'AZ-04': 'AZ Congressional District 4',
  'AZ-05': 'AZ Congressional District 5',
  'AZ-06': 'AZ Congressional District 6',
  'AZ-07': 'AZ Congressional District 7',
  'AZ-08': 'AZ Congressional District 8',
  'AZ-09': 'AZ Congressional District 9',
  'CA-01': 'CA Congressional District 1',
  'CA-02': 'CA Congressional District 2',
  'CA-03': 'CA Congressional District 3',
  'CA-04': 'CA Congressional District 4',
  'CA-05': 'CA Congressional District 5',
  'CA-06': 'CA Congressional District 6',
  'CA-07': 'CA Congressional District 7',
  'CA-08': 'CA Congressional District 8',
  'CA-09': 'CA Congressional District 9',
  'CA-10': 'CA Congressional District 10',
  'CA-11': 'CA Congressional District 11',
  'CA-12': 'CA Congressional District 12',
  'CA-13': 'CA Congressional District 13',
  'CA-14': 'CA Congressional District 14',
  'CA-15': 'CA Congressional District 15',
  'CA-16': 'CA Congressional District 16',
  'CA-17': 'CA Congressional District 17',
  'CA-18': 'CA Congressional District 18',
  'CA-19': 'CA Congressional District 19',
  'CA-20': 'CA Congressional District 20',
  'CA-21': 'CA Congressional District 21',
  'CA-22': 'CA Congressional District 22',
  'CA-23': 'CA Congressional District 23',
  'CA-24': 'CA Congressional District 24',
  'CA-25': 'CA Congressional District 25',
  'CA-26': 'CA Congressional District 26',
  'CA-27': 'CA Congressional District 27',
  'CA-28': 'CA Congressional District 28',
  'CA-29': 'CA Congressional District 29',
  'CA-30': 'CA Congressional District 30',
  'CA-31': 'CA Congressional District 31',
  'CA-32': 'CA Congressional District 32',
  'CA-33': 'CA Congressional District 33',
  'CA-34': 'CA Congressional District 34',
  'CA-35': 'CA Congressional District 35',
  'CA-36': 'CA Congressional District 36',
  'CA-37': 'CA Congressional District 37',
  'CA-38': 'CA Congressional District 38',
  'CA-39': 'CA Congressional District 39',
  'CA-40': 'CA Congressional District 40',
  'CA-41': 'CA Congressional District 41',
  'CA-42': 'CA Congressional District 42',
  'CA-43': 'CA Congressional District 43',
  'CA-44': 'CA Congressional District 44',
  'CA-45': 'CA Congressional District 45',
  'CA-46': 'CA Congressional District 46',
  'CA-47': 'CA Congressional District 47',
  'CA-48': 'CA Congressional District 48',
  'CA-49': 'CA Congressional District 49',
  'CA-50': 'CA Congressional District 50',
  'CA-51': 'CA Congressional District 51',
  'CA-52': 'CA Congressional District 52',
  'CA-53': 'CA Congressional District 53',
  'CO-01': 'CO Congressional District 1',
  'CO-02': 'CO Congressional District 2',
  'CO-03': 'CO Congressional District 3',
  'CO-04': 'CO Congressional District 4',
  'CO-05': 'CO Congressional District 5',
  'CO-06': 'CO Congressional District 6',
  'CO-07': 'CO Congressional District 7',
  'CT-01': 'CT Congressional District 1',
  'CT-02': 'CT Congressional District 2',
  'CT-03': 'CT Congressional District 3',
  'CT-04': 'CT Congressional District 4',
  'CT-05': 'CT Congressional District 5',
  'DC-98': 'DC Delegate District (at Large)',
  'DE-00': 'DE Congressional District (at Large)',
  'FL-01': 'FL Congressional District 1',
  'FL-02': 'FL Congressional District 2',
  'FL-03': 'FL Congressional District 3',
  'FL-04': 'FL Congressional District 4',
  'FL-05': 'FL Congressional District 5',
  'FL-06': 'FL Congressional District 6',
  'FL-07': 'FL Congressional District 7',
  'FL-08': 'FL Congressional District 8',
  'FL-09': 'FL Congressional District 9',
  'FL-10': 'FL Congressional District 10',
  'FL-11': 'FL Congressional District 11',
  'FL-12': 'FL Congressional District 12',
  'FL-13': 'FL Congressional District 13',
  'FL-14': 'FL Congressional District 14',
  'FL-15': 'FL Congressional District 15',
  'FL-16': 'FL Congressional District 16',
  'FL-17': 'FL Congressional District 17',
  'FL-18': 'FL Congressional District 18',
  'FL-19': 'FL Congressional District 19',
  'FL-20': 'FL Congressional District 20',
  'FL-21': 'FL Congressional District 21',
  'FL-22': 'FL Congressional District 22',
  'FL-23': 'FL Congressional District 23',
  'FL-24': 'FL Congressional District 24',
  'FL-25': 'FL Congressional District 25',
  'FL-26': 'FL Congressional District 26',
  'FL-27': 'FL Congressional District 27',
  'GA-01': 'GA Congressional District 1',
  'GA-02': 'GA Congressional District 2',
  'GA-03': 'GA Congressional District 3',
  'GA-04': 'GA Congressional District 4',
  'GA-05': 'GA Congressional District 5',
  'GA-06': 'GA Congressional District 6',
  'GA-07': 'GA Congressional District 7',
  'GA-08': 'GA Congressional District 8',
  'GA-09': 'GA Congressional District 9',
  'GA-10': 'GA Congressional District 10',
  'GA-11': 'GA Congressional District 11',
  'GA-12': 'GA Congressional District 12',
  'GA-13': 'GA Congressional District 13',
  'GA-14': 'GA Congressional District 14',
  'HI-01': 'HI Congressional District 1',
  'HI-02': 'HI Congressional District 2',
  'IA-01': 'IA Congressional District 1',
  'IA-02': 'IA Congressional District 2',
  'IA-03': 'IA Congressional District 3',
  'IA-04': 'IA Congressional District 4',
  'ID-01': 'ID Congressional District 1',
  'ID-02': 'ID Congressional District 2',
  'IL-01': 'IL Congressional District 1',
  'IL-02': 'IL Congressional District 2',
  'IL-03': 'IL Congressional District 3',
  'IL-04': 'IL Congressional District 4',
  'IL-05': 'IL Congressional District 5',
  'IL-06': 'IL Congressional District 6',
  'IL-07': 'IL Congressional District 7',
  'IL-08': 'IL Congressional District 8',
  'IL-09': 'IL Congressional District 9',
  'IL-10': 'IL Congressional District 10',
  'IL-11': 'IL Congressional District 11',
  'IL-12': 'IL Congressional District 12',
  'IL-13': 'IL Congressional District 13',
  'IL-14': 'IL Congressional District 14',
  'IL-15': 'IL Congressional District 15',
  'IL-16': 'IL Congressional District 16',
  'IL-17': 'IL Congressional District 17',
  'IL-18': 'IL Congressional District 18',
  'IN-01': 'IN Congressional District 1',
  'IN-02': 'IN Congressional District 2',
  'IN-03': 'IN Congressional District 3',
  'IN-04': 'IN Congressional District 4',
  'IN-05': 'IN Congressional District 5',
  'IN-06': 'IN Congressional District 6',
  'IN-07': 'IN Congressional District 7',
  'IN-08': 'IN Congressional District 8',
  'IN-09': 'IN Congressional District 9',
  'KS-01': 'KS Congressional District 1',
  'KS-02': 'KS Congressional District 2',
  'KS-03': 'KS Congressional District 3',
  'KS-04': 'KS Congressional District 4',
  'KY-01': 'KY Congressional District 1',
  'KY-02': 'KY Congressional District 2',
  'KY-03': 'KY Congressional District 3',
  'KY-04': 'KY Congressional District 4',
  'KY-05': 'KY Congressional District 5',
  'KY-06': 'KY Congressional District 6',
  'LA-01': 'LA Congressional District 1',
  'LA-02': 'LA Congressional District 2',
  'LA-03': 'LA Congressional District 3',
  'LA-04': 'LA Congressional District 4',
  'LA-05': 'LA Congressional District 5',
  'LA-06': 'LA Congressional District 6',
  'MA-01': 'MA Congressional District 1',
  'MA-02': 'MA Congressional District 2',
  'MA-03': 'MA Congressional District 3',
  'MA-04': 'MA Congressional District 4',
  'MA-05': 'MA Congressional District 5',
  'MA-06': 'MA Congressional District 6',
  'MA-07': 'MA Congressional District 7',
  'MA-08': 'MA Congressional District 8',
  'MA-09': 'MA Congressional District 9',
  'MD-01': 'MD Congressional District 1',
  'MD-02': 'MD Congressional District 2',
  'MD-03': 'MD Congressional District 3',
  'MD-04': 'MD Congressional District 4',
  'MD-05': 'MD Congressional District 5',
  'MD-06': 'MD Congressional District 6',
  'MD-07': 'MD Congressional District 7',
  'MD-08': 'MD Congressional District 8',
  'ME-01': 'ME Congressional District 1',
  'ME-02': 'ME Congressional District 2',
  'MI-01': 'MI Congressional District 1',
  'MI-02': 'MI Congressional District 2',
  'MI-03': 'MI Congressional District 3',
  'MI-04': 'MI Congressional District 4',
  'MI-05': 'MI Congressional District 5',
  'MI-06': 'MI Congressional District 6',
  'MI-07': 'MI Congressional District 7',
  'MI-08': 'MI Congressional District 8',
  'MI-09': 'MI Congressional District 9',
  'MI-10': 'MI Congressional District 10',
  'MI-11': 'MI Congressional District 11',
  'MI-12': 'MI Congressional District 12',
  'MI-13': 'MI Congressional District 13',
  'MI-14': 'MI Congressional District 14',
  'MN-01': 'MN Congressional District 1',
  'MN-02': 'MN Congressional District 2',
  'MN-03': 'MN Congressional District 3',
  'MN-04': 'MN Congressional District 4',
  'MN-05': 'MN Congressional District 5',
  'MN-06': 'MN Congressional District 6',
  'MN-07': 'MN Congressional District 7',
  'MN-08': 'MN Congressional District 8',
  'MO-01': 'MO Congressional District 1',
  'MO-02': 'MO Congressional District 2',
  'MO-03': 'MO Congressional District 3',
  'MO-04': 'MO Congressional District 4',
  'MO-05': 'MO Congressional District 5',
  'MO-06': 'MO Congressional District 6',
  'MO-07': 'MO Congressional District 7',
  'MO-08': 'MO Congressional District 8',
  'MS-01': 'MS Congressional District 1',
  'MS-02': 'MS Congressional District 2',
  'MS-03': 'MS Congressional District 3',
  'MS-04': 'MS Congressional District 4',
  'MT-00': 'MT Congressional District (at Large)',
  'NC-01': 'NC Congressional District 1',
  'NC-02': 'NC Congressional District 2',
  'NC-03': 'NC Congressional District 3',
  'NC-04': 'NC Congressional District 4',
  'NC-05': 'NC Congressional District 5',
  'NC-06': 'NC Congressional District 6',
  'NC-07': 'NC Congressional District 7',
  'NC-08': 'NC Congressional District 8',
  'NC-09': 'NC Congressional District 9',
  'NC-10': 'NC Congressional District 10',
  'NC-11': 'NC Congressional District 11',
  'NC-12': 'NC Congressional District 12',
  'NC-13': 'NC Congressional District 13',
  'ND-00': 'ND Congressional District (at Large)',
  'NE-01': 'NE Congressional District 1',
  'NE-02': 'NE Congressional District 2',
  'NE-03': 'NE Congressional District 3',
  'NH-01': 'NH Congressional District 1',
  'NH-02': 'NH Congressional District 2',
  'NJ-01': 'NJ Congressional District 1',
  'NJ-02': 'NJ Congressional District 2',
  'NJ-03': 'NJ Congressional District 3',
  'NJ-04': 'NJ Congressional District 4',
  'NJ-05': 'NJ Congressional District 5',
  'NJ-06': 'NJ Congressional District 6',
  'NJ-07': 'NJ Congressional District 7',
  'NJ-08': 'NJ Congressional District 8',
  'NJ-09': 'NJ Congressional District 9',
  'NJ-10': 'NJ Congressional District 10',
  'NJ-11': 'NJ Congressional District 11',
  'NJ-12': 'NJ Congressional District 12',
  'NM-01': 'NM Congressional District 1',
  'NM-02': 'NM Congressional District 2',
  'NM-03': 'NM Congressional District 3',
  'NV-01': 'NV Congressional District 1',
  'NV-02': 'NV Congressional District 2',
  'NV-03': 'NV Congressional District 3',
  'NV-04': 'NV Congressional District 4',
  'NY-01': 'NY Congressional District 1',
  'NY-02': 'NY Congressional District 2',
  'NY-03': 'NY Congressional District 3',
  'NY-04': 'NY Congressional District 4',
  'NY-05': 'NY Congressional District 5',
  'NY-06': 'NY Congressional District 6',
  'NY-07': 'NY Congressional District 7',
  'NY-08': 'NY Congressional District 8',
  'NY-09': 'NY Congressional District 9',
  'NY-10': 'NY Congressional District 10',
  'NY-11': 'NY Congressional District 11',
  'NY-12': 'NY Congressional District 12',
  'NY-13': 'NY Congressional District 13',
  'NY-14': 'NY Congressional District 14',
  'NY-15': 'NY Congressional District 15',
  'NY-16': 'NY Congressional District 16',
  'NY-17': 'NY Congressional District 17',
  'NY-18': 'NY Congressional District 18',
  'NY-19': 'NY Congressional District 19',
  'NY-20': 'NY Congressional District 20',
  'NY-21': 'NY Congressional District 21',
  'NY-22': 'NY Congressional District 22',
  'NY-23': 'NY Congressional District 23',
  'NY-24': 'NY Congressional District 24',
  'NY-25': 'NY Congressional District 25',
  'NY-26': 'NY Congressional District 26',
  'NY-27': 'NY Congressional District 27',
  'OH-01': 'OH Congressional District 1',
  'OH-02': 'OH Congressional District 2',
  'OH-03': 'OH Congressional District 3',
  'OH-04': 'OH Congressional District 4',
  'OH-05': 'OH Congressional District 5',
  'OH-06': 'OH Congressional District 6',
  'OH-07': 'OH Congressional District 7',
  'OH-08': 'OH Congressional District 8',
  'OH-09': 'OH Congressional District 9',
  'OH-10': 'OH Congressional District 10',
  'OH-11': 'OH Congressional District 11',
  'OH-12': 'OH Congressional District 12',
  'OH-13': 'OH Congressional District 13',
  'OH-14': 'OH Congressional District 14',
  'OH-15': 'OH Congressional District 15',
  'OH-16': 'OH Congressional District 16',
  'OK-01': 'OK Congressional District 1',
  'OK-02': 'OK Congressional District 2',
  'OK-03': 'OK Congressional District 3',
  'OK-04': 'OK Congressional District 4',
  'OK-05': 'OK Congressional District 5',
  'OR-01': 'OR Congressional District 1',
  'OR-02': 'OR Congressional District 2',
  'OR-03': 'OR Congressional District 3',
  'OR-04': 'OR Congressional District 4',
  'OR-05': 'OR Congressional District 5',
  'PA-01': 'PA Congressional District 1',
  'PA-02': 'PA Congressional District 2',
  'PA-03': 'PA Congressional District 3',
  'PA-04': 'PA Congressional District 4',
  'PA-05': 'PA Congressional District 5',
  'PA-06': 'PA Congressional District 6',
  'PA-07': 'PA Congressional District 7',
  'PA-08': 'PA Congressional District 8',
  'PA-09': 'PA Congressional District 9',
  'PA-10': 'PA Congressional District 10',
  'PA-11': 'PA Congressional District 11',
  'PA-12': 'PA Congressional District 12',
  'PA-13': 'PA Congressional District 13',
  'PA-14': 'PA Congressional District 14',
  'PA-15': 'PA Congressional District 15',
  'PA-16': 'PA Congressional District 16',
  'PA-17': 'PA Congressional District 17',
  'PA-18': 'PA Congressional District 18',
  'RI-01': 'RI Congressional District 1',
  'RI-02': 'RI Congressional District 2',
  'SC-01': 'SC Congressional District 1',
  'SC-02': 'SC Congressional District 2',
  'SC-03': 'SC Congressional District 3',
  'SC-04': 'SC Congressional District 4',
  'SC-05': 'SC Congressional District 5',
  'SC-06': 'SC Congressional District 6',
  'SC-07': 'SC Congressional District 7',
  'SD-00': 'SD Congressional District (at Large)',
  'TN-01': 'TN Congressional District 1',
  'TN-02': 'TN Congressional District 2',
  'TN-03': 'TN Congressional District 3',
  'TN-04': 'TN Congressional District 4',
  'TN-05': 'TN Congressional District 5',
  'TN-06': 'TN Congressional District 6',
  'TN-07': 'TN Congressional District 7',
  'TN-08': 'TN Congressional District 8',
  'TN-09': 'TN Congressional District 9',
  'TX-01': 'TX Congressional District 1',
  'TX-02': 'TX Congressional District 2',
  'TX-03': 'TX Congressional District 3',
  'TX-04': 'TX Congressional District 4',
  'TX-05': 'TX Congressional District 5',
  'TX-06': 'TX Congressional District 6',
  'TX-07': 'TX Congressional District 7',
  'TX-08': 'TX Congressional District 8',
  'TX-09': 'TX Congressional District 9',
  'TX-10': 'TX Congressional District 10',
  'TX-11': 'TX Congressional District 11',
  'TX-12': 'TX Congressional District 12',
  'TX-13': 'TX Congressional District 13',
  'TX-14': 'TX Congressional District 14',
  'TX-15': 'TX Congressional District 15',
  'TX-16': 'TX Congressional District 16',
  'TX-17': 'TX Congressional District 17',
  'TX-18': 'TX Congressional District 18',
  'TX-19': 'TX Congressional District 19',
  'TX-20': 'TX Congressional District 20',
  'TX-21': 'TX Congressional District 21',
  'TX-22': 'TX Congressional District 22',
  'TX-23': 'TX Congressional District 23',
  'TX-24': 'TX Congressional District 24',
  'TX-25': 'TX Congressional District 25',
  'TX-26': 'TX Congressional District 26',
  'TX-27': 'TX Congressional District 27',
  'TX-28': 'TX Congressional District 28',
  'TX-29': 'TX Congressional District 29',
  'TX-30': 'TX Congressional District 30',
  'TX-31': 'TX Congressional District 31',
  'TX-32': 'TX Congressional District 32',
  'TX-33': 'TX Congressional District 33',
  'TX-34': 'TX Congressional District 34',
  'TX-35': 'TX Congressional District 35',
  'TX-36': 'TX Congressional District 36',
  'UT-01': 'UT Congressional District 1',
  'UT-02': 'UT Congressional District 2',
  'UT-03': 'UT Congressional District 3',
  'UT-04': 'UT Congressional District 4',
  'VA-01': 'VA Congressional District 1',
  'VA-02': 'VA Congressional District 2',
  'VA-03': 'VA Congressional District 3',
  'VA-04': 'VA Congressional District 4',
  'VA-05': 'VA Congressional District 5',
  'VA-06': 'VA Congressional District 6',
  'VA-07': 'VA Congressional District 7',
  'VA-08': 'VA Congressional District 8',
  'VA-09': 'VA Congressional District 9',
  'VA-10': 'VA Congressional District 10',
  'VA-11': 'VA Congressional District 11',
  'VT-00': 'VT Congressional District (at Large)',
  'WA-01': 'WA Congressional District 1',
  'WA-02': 'WA Congressional District 2',
  'WA-03': 'WA Congressional District 3',
  'WA-04': 'WA Congressional District 4',
  'WA-05': 'WA Congressional District 5',
  'WA-06': 'WA Congressional District 6',
  'WA-07': 'WA Congressional District 7',
  'WA-08': 'WA Congressional District 8',
  'WA-09': 'WA Congressional District 9',
  'WA-10': 'WA Congressional District 10',
  'WI-01': 'WI Congressional District 1',
  'WI-02': 'WI Congressional District 2',
  'WI-03': 'WI Congressional District 3',
  'WI-04': 'WI Congressional District 4',
  'WI-05': 'WI Congressional District 5',
  'WI-06': 'WI Congressional District 6',
  'WI-07': 'WI Congressional District 7',
  'WI-08': 'WI Congressional District 8',
  'WV-01': 'WV Congressional District 1',
  'WV-02': 'WV Congressional District 2',
  'WV-03': 'WV Congressional District 3',
  'WY-00': 'WY Congressional District (at Large)'
};

export const US_CONGRESSIONAL_DISTRICT_NAMES = Object.values(US_CONGRESSIONAL_DISTRICT_ABBR_TO_NAME);
export const US_CONGRESSIONAL_DISTRICT_ABBRS = Object.keys(US_CONGRESSIONAL_DISTRICT_ABBR_TO_NAME);
