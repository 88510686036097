import { useFormikFilterCountByField } from 'app/hooks/useGetFormikFilterCountByField';

export function useSubInputsSelected({ names }: { names: string[] }) {
  const filterCountByField = useFormikFilterCountByField({ names });
  const subInputsFilterCount = getSubInputsFilterCount({ filterCountByField });
  const subInputsSelected = subInputsFilterCount > 0;
  return subInputsSelected;
}

function getSubInputsFilterCount({ filterCountByField }: { filterCountByField: Record<string, number> }) {
  const subInputsFilterCount = Object.values(filterCountByField)
    .slice(1)
    .reduce((acc, val) => acc + val, 0);

  return subInputsFilterCount;
}
