import React, { useState } from 'react';
import { useField } from 'formik';
import { FormGroup, MenuItem, Button, ButtonProps, FormGroupProps } from '@blueprintjs/core';
import { Select, SelectProps } from '@blueprintjs/select';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';

const renderCreateOption: SelectProps<unknown>['createNewItemRenderer'] = (query, active, handleClick) => (
  <MenuItem
    icon="add"
    text={`Create "${query}"`}
    roleStructure="listoption"
    active={active}
    onClick={handleClick}
    shouldDismissPopover={false}
  />
);

const renderItem = (
  item: React.ReactNode,
  {
    modifiers,
    handleClick,
    index
  }: {
    modifiers: { active?: boolean; matchesPredicate?: boolean };
    handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    index: number;
  }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={`${item}-${index}`}
      onClick={handleClick}
      text={item}
      shouldDismissPopover={false}
    />
  );
};

type DynamicSelectInputProps = {
  name: string;
  items: React.ReactNode[];
  buttonProps?: ButtonProps;
  loading?: boolean;
} & FormGroupProps;

export const DynamicSelectInput = ({
  name,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  items,
  loading,
  buttonProps,
  ...rest
}: DynamicSelectInputProps) => {
  const [query, setQuery] = useState<string>('');
  const [field, meta, { setValue }] = useField({ name });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  const results = useFuzzySearch({
    data: items.filter(tag => !field.value.includes(tag)),
    query
  });

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <Select
        disabled={disabled}
        items={results.slice(0, 200)}
        itemRenderer={(item, options) => renderItem(item, { ...options })}
        onQueryChange={setQuery}
        noResults={noResults}
        onItemSelect={v => setValue(v)}
        resetOnSelect
        resetOnQuery={false}
        createNewItemFromQuery={name => name}
        createNewItemRenderer={renderCreateOption}
        {...rest}
      >
        <Button
          large
          rightIcon="chevron-down"
          text={field.value || 'Select'}
          className="flex justify-between"
          disabled={disabled}
          {...(buttonProps || {})}
        />
      </Select>
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
