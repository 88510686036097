import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Alert, Callout, NonIdealState } from '@blueprintjs/core';
import { LineChart } from 'react-chartkick';

import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import {
  useGetProviderFeedQuery,
  useGetProviderFeedsQuery,
  useUpdateProviderFeedMutation,
  useDeleteProviderFeedMutation
} from 'api/providerFeedsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { ProviderFeedLastActive } from 'app/organisms/ProviderFeedLastActive/ProviderFeedLastActive';
import { ManagedFeedSharedInfo } from 'app/organisms/ManagedFeedSharedInfo/ManagedFeedSharedInfo';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { ManagedFeedSolicitors } from 'app/organisms/ManagedFeedSolicitors/ManagedFeedSolicitors';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';

export const ManagedFeed = () => {
  const [deletingAlert, setDeletingAlert] = useState<boolean>();
  const [searchParams] = useSearchParams();
  const { id: queryParam } = useParams();

  const id = queryParam || searchParams.get(DRAWER_PARAMS.id);

  const { data: { id: organizationId } = {}, isLoading: organizationLoading } = useGetCurrentOrganizationQuery();

  const { feed, feedLoading } = useGetProviderFeedsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      feed: data?.find(({ publicId }) => publicId === id),
      feedLoading: isLoading
    })
  });

  const { data: fullFeed, isLoading: analyticsLoading } = useGetProviderFeedQuery(
    { id: feed?.id ?? '' },
    { skip: !feed }
  );

  const [updateFeed, { isLoading: isUpdating }] = useUpdateProviderFeedMutation();
  const [deleteFeed, { isLoading: isDeleting }] = useDeleteProviderFeedMutation();

  if (organizationLoading || feedLoading || !feed || analyticsLoading) return <Loading />;

  const { name, subContractor, description, lastActive } = feed;

  const title = organizationId === subContractor?.id ? `${name} via ${subContractor?.name}` : name;

  const completionGroupItems =
    fullFeed?.contractVehicle?.completionGroups?.map(group => ({
      value: group,
      label: group
    })) ?? [];

  return (
    <>
      <Breadcrumbs />
      <Formik
        validationSchema={Yup.object({
          name: Yup.string().required('A name for this feed is required'),
          description: Yup.string(),
          completionGroups: Yup.array().of(Yup.string())
        })}
        initialValues={{
          id: feed?.id,
          name,
          description: description || '',
          completionGroups: feed?.completionGroups || []
        }}
        onSubmit={async values => {
          try {
            await updateFeed(values).unwrap();
            successToast();
          } catch (e) {
            errorToast(e);
          }
        }}
      >
        <Form>
          <Card title={title} rightElement={<ProviderFeedLastActive lastActive={lastActive} />}>
            <CardBody>
              <TextInput name="name" label="Name" />
              <TextAreaInput name="description" label="Description" />

              <MultiSelectInput
                items={completionGroupItems}
                name="completionGroups"
                label="Completion groups"
                submitOnChange={false}
              />
            </CardBody>
            <CardFooter>
              <Button type="submit" large loading={isUpdating} text="Save" />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
      {analyticsLoading ? (
        <Loading />
      ) : (
        <Card title="Inputs Received">
          <CardBody>
            {Object.keys(fullFeed?.inputAnalytics ?? {}).length ? (
              <LineChart data={fullFeed?.inputAnalytics} />
            ) : (
              <NonIdealState
                icon="warning-sign"
                title="No inputs received"
                description="No inputs have been received for this feed yet."
              />
            )}
          </CardBody>
        </Card>
      )}

      {feed && (
        <>
          <ManagedFeedSharedInfo id={feed.id} publicId={feed.publicId} />
          <ManagedFeedSolicitors id={feed.id} />
          <Card title="Delete Feed" className="!bg-red-50">
            <CardBody>
              <Callout intent="danger" title="Deleting a feed is permanent and cannot be undone.">
                <Button large text="Delete Feed" intent="danger" onClick={() => setDeletingAlert(true)} />
              </Callout>
            </CardBody>
            <Formik
              validationSchema={Yup.object({
                name: Yup.string().oneOf([name], 'Confirmation is invalid.').required('Confirmation is invalid.')
              })}
              initialValues={{ name: '' }}
              onSubmit={async () => {
                try {
                  await deleteFeed(feed?.id).unwrap();
                  window.location.href = `/settings/managed_feeds?toast=${encodeURIComponent('Feed deleted')}`;
                } catch (e) {
                  errorToast(e);
                }
              }}
            >
              {({ handleSubmit }) => (
                <Alert
                  cancelButtonText="Cancel"
                  icon="trash"
                  intent="danger"
                  isOpen={deletingAlert}
                  onCancel={() => setDeletingAlert(false)}
                  onConfirm={handleSubmit}
                  loading={isDeleting}
                  confirmButtonText="Delete Feed"
                >
                  <Form>
                    <p>{name}</p>
                    <TextInput name="name" label="Type the name of the feed and submit to confirm deletion" />
                  </Form>
                </Alert>
              )}
            </Formik>
          </Card>
        </>
      )}
    </>
  );
};
