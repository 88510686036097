import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  isExpanded: boolean;
};

type Actions = {
  toggleExpanded: () => void;
};

type Store = State & Actions;

export const useNavStore = create<Store>()(
  persist(
    set => ({
      isExpanded: true,
      toggleExpanded: () => set(state => ({ isExpanded: !state.isExpanded }))
    }),
    {
      name: 'nav-store'
    }
  )
);
