import React from 'react';

import { PageHeading } from 'app/atoms/Typography/Typography';
import { Attachment } from 'types/__generated__/GovlyApi';

type LogoOrNameProps = {
  name: string;
  logo?: Pick<Attachment, 'svg' | 'variantLink' | 'link'>;
};

export const LogoOrName = ({ logo, name }: LogoOrNameProps) => {
  return (
    <div>
      {logo ? (
        <h1>
          <img
            className="m-0 max-h-16 w-auto object-contain"
            src={
              logo?.svg ? `data:image/svg+xml;utf8,${encodeURIComponent(logo?.svg)}` : logo?.variantLink || logo?.link
            }
            alt={`Logo for ${name}`}
          />
        </h1>
      ) : (
        <PageHeading>{name}</PageHeading>
      )}
    </div>
  );
};
