import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { rootApi } from 'api/rootApi';
import { rtkQueryLogger } from 'app/lib/rtkQueryLogger';

const reducers = combineReducers({ [rootApi.reducerPath]: rootApi.reducer });

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(rootApi.middleware).concat(rtkQueryLogger)
});

setupListeners(store.dispatch);
