import React from 'react';
import { Callout } from '@blueprintjs/core';
import { Outlet } from 'react-router-dom';

import { Card } from 'app/atoms/Card/Card';
import { CenterPageHighlight } from 'app/molecules/CenterPageHighlight/CenterPageHighlight';
import { useMetaTags } from 'app/hooks/useMetaTags';
import { safeParse } from 'app/lib/strings';

export const RegisterPage = () => {
  useMetaTags({ title: 'Register - Govly' });

  const flashMessages = safeParse(document.querySelector('body')?.dataset?.flash);

  return (
    <CenterPageHighlight showLogo={true}>
      <Card>
        {flashMessages.error && (
          <Callout intent="danger" title="Error" data-test="SessionError">
            {flashMessages.error}
          </Callout>
        )}

        <Outlet />
      </Card>
    </CenterPageHighlight>
  );
};
