import { ContractHolder } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';
const api = rootApi.enhanceEndpoints({ addTagTypes: ['ContractHolder'] });

type GetContractHolders = {
  params: {
    vendorId: string;
  };
  result: ContractHolder[];
};

type CreateContractHolder = {
  params: {
    vendorId: string;
    contractVehicleId: string;
  };
  result: ContractHolder;
};

type UpdateContractHolder = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
};

type DeleteContractHolder = {
  params: { id: string; vendorId: string };
  result: void;
};

export const contractHoldersApi = api.injectEndpoints({
  endpoints: build => ({
    getContractHolders: build.query<GetContractHolders['result'], GetContractHolders['params']>({
      query: params => ({
        url: '/v2/contract_holders',
        params
      }),
      providesTags: (result, error, arg) => ['ContractHolder', { type: 'ContractHolder', id: `VENDOR:${arg.vendorId}` }]
    }),
    createContractHolder: build.mutation<CreateContractHolder['result'], CreateContractHolder['params']>({
      query: body => ({
        url: `/v2/contract_holders`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ContractHolder', id: `VENDOR:${arg.vendorId}` }]
    }),
    updateContractHolder: build.mutation<UpdateContractHolder['result'], UpdateContractHolder['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/contract_holders/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ContractHolder', id: `VENDOR:${arg.vendorId}` }]
    }),
    deleteContractHolder: build.mutation<DeleteContractHolder['result'], DeleteContractHolder['params']>({
      query: ({ id }) => ({
        url: `/v2/contract_holders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ContractHolder', id: `VENDOR:${arg.vendorId}` }]
    })
  })
});

export const {
  useGetContractHoldersQuery,
  useCreateContractHolderMutation,
  useDeleteContractHolderMutation,
  useUpdateContractHolderMutation
} = contractHoldersApi;
