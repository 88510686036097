import React from 'react';
import { useGetGovernmentEntityQuery } from 'api/governmentEntitiesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { Chat } from 'app/organisms/Chat/Chat';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { Icon } from '@blueprintjs/core';

const PLACEHOLDER_QUESTIONS = [
  'Give me more detail on the top 5 initiatives with the largest funding requested in the budget',
  'Tell me more about any Research and Development efforts mentioned in the budget.',
  'What budget initiatives are related to an executive order or presidential memorandum?'
];

export const GovernmentEntityChat = ({ id }: { id: string }) => {
  const chatEnabled = useFeatureFlag('budget-chat');
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (!chatEnabled) return null;
  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity?.latestUpload) return null;

  const fy = governmentEntity.latestUpload.fiscalYear;

  return (
    <Chat
      id={id}
      placeholder={
        <span>
          Ask questions about the {governmentEntity?.name}&apos;s {fy ? `FY${fy} ` : ''}budget document. <br />
          Expand the chat to search the broader internet by clicking the <Icon icon="globe-network" /> icon.
        </span>
      }
      questions={PLACEHOLDER_QUESTIONS}
      cardProps={{
        icon: <LlmGeneratedIndicator icon="generate" />,
        title: 'Budget Chat',
        subtitle: `AI powered chat that searches the ${governmentEntity.name}'s${fy ? ` FY${fy} ` : ' '}budget document.`,
        titleRenderer: CardHeadingSmall,
        className: 'max-h-[768px]'
      }}
    />
  );
};
