import { PartnerPortalLink } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['PartnerPortalLinks'] });

type GetPartnerPortalLinks = {
  params: void;
  result: PartnerPortalLink[];
};

type CreatePartnerPortalLink = {
  params: Omit<PartnerPortalLink, 'id' | '__typename'>;
  result: PartnerPortalLink;
};

type UpdatePartnerPortalLink = {
  params: Omit<PartnerPortalLink, '__typename'>;
  result: PartnerPortalLink;
};

type DeletePartnerPortalLink = {
  params: {
    id: string;
  };
  result: void;
};

const partnerPortalLinksApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnerPortalLinks: build.query<GetPartnerPortalLinks['result'], GetPartnerPortalLinks['params']>({
      query: () => ({ url: '/v2/partner_portal_links' }),
      providesTags: ['PartnerPortalLinks']
    }),

    createPartnerPortalLink: build.mutation<CreatePartnerPortalLink['result'], CreatePartnerPortalLink['params']>({
      query: body => ({
        url: '/v2/partner_portal_links',
        method: 'POST',
        body
      }),
      invalidatesTags: ['PartnerPortalLinks']
    }),

    updatePartnerPortalLink: build.mutation<UpdatePartnerPortalLink['result'], UpdatePartnerPortalLink['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/partner_portal_links/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['PartnerPortalLinks']
    }),

    deletePartnerPortalLink: build.mutation<DeletePartnerPortalLink['result'], DeletePartnerPortalLink['params']>({
      query: ({ id }) => ({
        url: `/v2/partner_portal_links/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PartnerPortalLinks']
    })
  })
});

export const {
  useCreatePartnerPortalLinkMutation,
  useGetPartnerPortalLinksQuery,
  useUpdatePartnerPortalLinkMutation,
  useDeletePartnerPortalLinkMutation
} = partnerPortalLinksApi;
