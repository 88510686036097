import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import * as yup from 'yup';

import { deleteToast, errorToast, successToast } from 'app/lib/toaster';
import { CardBody, CardFooter } from 'app/atoms/Card/Card';
import { useGetVendorQuery } from 'api/vendorsApi';
import { NumericInput } from 'app/atoms/inputs/NumericInput/NumericInput';
import { DynamicSelectInput } from 'app/atoms/inputs/DynamicSelectInput/DynamicSelectInput';
import { VendorSelectInput } from 'app/organisms/inputs/VendorSelectInput';
import { cn } from 'app/lib/cn';
import { hasId } from 'api/utils';
import {
  useCreateRecordChannelPartnerMutation,
  useUpdateRecordChannelPartnerMutation,
  useDeleteRecordChannelPartnerMutation,
  useGetChannelPartnersQuery
} from 'api/channelPartnersApi';
import {
  useCreateRecordResellerMutation,
  useUpdateRecordResellerMutation,
  useDeleteRecordResellerMutation,
  useGetResellersQuery
} from 'api/resellersApi';

type FormValues = {
  id: string;
  vendorId: string;
  tier: string;
  sortOrder: number;
};

type ChannelPartnerInitialValues = {
  channelPartnerableId: string;
  channelPartnerableType: string;
} & Partial<FormValues>;

export type ChannelPartnerProps = {
  vendorId: string;
  recordTitle: string;
  initialValues: ChannelPartnerInitialValues;
  useOnCreate: typeof useCreateRecordChannelPartnerMutation;
  useOnUpdate: typeof useUpdateRecordChannelPartnerMutation;
  useGetList: typeof useGetChannelPartnersQuery;
  useOnDelete: typeof useDeleteRecordChannelPartnerMutation;
};

type ResellerInitialValues = {
  resellerableId: string;
  resellerableType: string;
} & Partial<FormValues>;

export type RecordPartnerProps = {
  vendorId: string;
  recordTitle: string;
  initialValues: ResellerInitialValues;
  useOnCreate: typeof useCreateRecordResellerMutation;
  useOnUpdate: typeof useUpdateRecordResellerMutation;
  useGetList: typeof useGetResellersQuery;
  useOnDelete: typeof useDeleteRecordResellerMutation;
};

export type VendorPartnerFormProps = ChannelPartnerProps | RecordPartnerProps;

export const VendorPartnerForm = ({
  vendorId,
  recordTitle,
  initialValues,
  useOnCreate,
  useOnUpdate,
  useGetList,
  useOnDelete
}: VendorPartnerFormProps) => {
  const { isLoading: vendorLoading, isError: vendorLoadingError } = useGetVendorQuery({ id: vendorId });
  const { data: list, isLoading: listLoading } = useGetList({ vendorId });

  const [create, { isLoading: isCreating, isSuccess: created, error: createError }] = useOnCreate();
  const [update, { isLoading: isUpdating, isSuccess: updated, error: updateError }] = useOnUpdate();
  const [remove, { isLoading: isRemoving, isSuccess: removed, error: removeError }] = useOnDelete();

  const isSubmitting = isCreating || isUpdating;
  const success = created || updated;
  const error = createError || updateError || removeError;

  useEffect(() => {
    if (success) {
      successToast(`${recordTitle} ${initialValues.id ? 'updated' : 'created'}.`);
    }

    if (error) {
      errorToast(error);
    }

    if (removed) {
      deleteToast(`${recordTitle} deleted.`);
    }
  }, [success, error, initialValues.id, removed, recordTitle]);

  if (vendorLoading || vendorLoadingError) {
    return null;
  }

  const tiers = [...new Set(list?.map(record => record.tier))];

  const validationSchema = yup.object().shape({
    vendorId: yup.string().required('Required'),
    tier: yup.string().required('Required'),
    sortOrder: yup.number().required('Required')
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        vendorId: '',
        sortOrder: 1,
        tier: '',
        ...(initialValues || {})
      }}
      onSubmit={async (values, { resetForm }) => {
        /**
         * Casting to any here because values is overload and it's too much work to type correctly.
         */
        if (hasId(values)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await update(values as any);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await create(values as any);
        }

        resetForm();
      }}
    >
      <Form>
        <CardBody>
          <div
            className={cn({
              'flex flex-col gap-y-4': initialValues.id,
              'grid justify-items-stretch gap-4 sm:grid-cols-3': !initialValues.id
            })}
          >
            {!initialValues.id && (
              <VendorSelectInput buttonProps={{ fill: true }} name="vendorId" label={recordTitle} />
            )}
            <DynamicSelectInput
              name="tier"
              label="Tier/Group"
              loading={listLoading}
              items={tiers}
              className="sm:justify-self-center"
            />
            <NumericInput
              name="sortOrder"
              label="Sort Order in Group"
              className="sm:justify-self-end"
              inputProps={{ min: 1 }}
            />
          </div>
        </CardBody>
        <CardFooter>
          <Button
            icon={initialValues.id ? null : 'add'}
            large
            intent="primary"
            type="submit"
            loading={isSubmitting}
            text={initialValues.id ? 'Update' : 'Add'}
          />

          {initialValues.id ? (
            <Button
              icon="trash"
              large
              intent="danger"
              loading={isRemoving}
              text="Delete"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onClick={() => remove(initialValues as any)}
            />
          ) : undefined}
        </CardFooter>
      </Form>
    </Formik>
  );
};
