import React, { useEffect, useState } from 'react';
import { Tag, Button, Dialog } from '@blueprintjs/core';

import { CardBody } from 'app/atoms/Card/Card';
import { useGetVendorQuery } from 'api/vendorsApi';
import {
  VendorPartnerForm,
  VendorPartnerFormProps,
  ChannelPartnerProps,
  RecordPartnerProps
} from 'app/organisms/VendorPartnerForm/VendorPartnerForm';
import { RecordReseller, RecordChannelPartner } from 'types/__generated__/GovlyApi';

type VendorPartnersFormListProps = Omit<VendorPartnerFormProps, 'initialValues'>;

export const VendorPartnersFormList = ({ vendorId, useGetList, recordTitle, ...rest }: VendorPartnersFormListProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading: vendorLoading, isError: vendorLoadingError } = useGetVendorQuery({ id: vendorId });
  const { data = [], isLoading: listLoading, isFetching } = useGetList({ vendorId });

  const list = data as RecordReseller[] | RecordChannelPartner[];

  useEffect(() => {
    if (isEditing) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  if (vendorLoading || vendorLoadingError || listLoading) {
    return null;
  }

  return list?.map(record => {
    const { vendor, ...initialValues } = record;

    const formProps =
      'resellerableId' in initialValues
        ? ({ vendorId, recordTitle, initialValues, useGetList, ...rest } as unknown as RecordPartnerProps)
        : ({ vendorId, recordTitle, initialValues, useGetList, ...rest } as unknown as ChannelPartnerProps);

    return (
      <div key={record.id}>
        <CardBody>
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 block text-base font-medium">{record.vendor?.name}</p>
              <div className="flex space-x-2">
                <Tag minimal intent="primary">
                  {record.tier}
                </Tag>
                <Tag minimal>{`Sort Order ${record.sortOrder}`}</Tag>
              </div>
            </div>
            <div>
              <Button icon="edit" small onClick={() => setIsEditing(!!record.id)} />
            </div>
          </div>
        </CardBody>
        <Dialog title={`Edit ${vendor?.name}`} isOpen={isEditing} className="p-0" onClose={() => setIsEditing(false)}>
          <CardBody>
            <VendorPartnerForm {...formProps} />
          </CardBody>
        </Dialog>
      </div>
    );
  });
};
