import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, ButtonGroup, ButtonGroupProps, Dialog, Tooltip, TooltipProps } from '@blueprintjs/core';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { useCreateSavedSearchFollowMutation, useDeleteSavedSearchFollowMutation } from 'api/savedSearchFollowsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { OppSavedSearchCreateForm } from 'app/organisms/OppSavedSearchCreateForm/OppSavedSearchCreateForm';
import { OppSavedSearchUpdateForm } from 'app/organisms/OppSavedSearchUpdateForm/OppSavedSearchUpdateForm';
import { defaultState, OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { cn } from 'app/lib/cn';

export const OppSavedSearchControls = ({
  className: buttonGroupClassName,
  ...buttonGroupProps
}: Partial<ButtonGroupProps> = {}) => {
  const navigate = useNavigate();
  const {
    setValues,
    initialValues,
    submitForm,
    values: { query, filters, meta }
  } = useFormikContext<OppSearchState>();
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [templateSearchId, setTemplateSearchId] = useState<string>();

  useEffect(() => {
    setIsDirty(
      JSON.stringify({ query, filters }) !==
        JSON.stringify({ query: initialValues.query, filters: initialValues.filters })
    );
  }, [query, filters, initialValues]);

  const selected = meta.savedSearchId;

  const currentOrgUserId = useCurrentUserAttribute('id');
  const [createOppSearchFollow] = useCreateSavedSearchFollowMutation();
  const [deleteOppSearchFollow] = useDeleteSavedSearchFollowMutation();

  const { selectedSavedSearch, isLoading } = useGetOppSearchesQuery(
    { active: true },
    {
      selectFromResult: ({ data, isLoading }) => ({
        selectedSavedSearch: data?.find(({ id }) => id === selected),
        isLoading
      })
    }
  );

  const toggleIsCreating = () => setIsCreating(!isCreating);
  const toggleIsEditing = () => setIsEditing(!isEditing);

  const tooltipTargetProps: TooltipProps['targetProps'] = {
    className: 'bp5-popover-target flex-grow-0'
  };

  return (
    <>
      {selected && selectedSavedSearch && (
        <>
          <ButtonGroup {...buttonGroupProps} className={cn('justify-end', buttonGroupClassName)}>
            <Tooltip content="Clear this search" targetProps={tooltipTargetProps}>
              <Button
                disabled={isLoading}
                onClick={() => {
                  setValues(defaultState);
                  setTemplateSearchId(undefined);
                  navigate('/opportunities');
                  submitForm();
                }}
                icon="filter-remove"
              >
                Clear
              </Button>
            </Tooltip>
            <Tooltip content="Save this search" targetProps={tooltipTargetProps}>
              <Button disabled={isLoading} onClick={toggleIsEditing} icon="floppy-disk" />
            </Tooltip>
            <Tooltip content="Duplicate this search" targetProps={tooltipTargetProps}>
              <Button
                disabled={isLoading}
                onClick={() => {
                  toggleIsCreating();
                  setTemplateSearchId(selected);
                }}
                data-test="save-new-search-button"
                icon="duplicate"
              />
            </Tooltip>
            {selectedSavedSearch.currentUserFollowing ? (
              <Tooltip content="Following" targetProps={tooltipTargetProps}>
                <Button
                  className="bp5-intent-success"
                  onClick={async () => {
                    try {
                      await deleteOppSearchFollow({
                        organizationUserId: currentOrgUserId,
                        savedSearchId: selected,
                        savedSearchType: 'OppSearch'
                      }).unwrap();
                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                  icon="thumbs-up"
                />
              </Tooltip>
            ) : (
              <Tooltip content="Follow this search" targetProps={tooltipTargetProps}>
                <Button
                  onClick={async () => {
                    try {
                      await createOppSearchFollow({
                        organizationUserId: currentOrgUserId,
                        savedSearchId: selected,
                        savedSearchType: 'OppSearch'
                      }).unwrap();

                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                  icon="thumbs-up"
                />
              </Tooltip>
            )}
          </ButtonGroup>
        </>
      )}
      {!selected && (
        <>
          <ButtonGroup {...buttonGroupProps} className={cn('justify-end', buttonGroupClassName)}>
            {isDirty && (
              <Tooltip content="Clear this search" targetProps={tooltipTargetProps}>
                <Button
                  onClick={() => {
                    setValues(defaultState);
                    setTemplateSearchId(undefined);
                    navigate('/opportunities');
                    submitForm();
                  }}
                  icon="filter-remove"
                >
                  Clear
                </Button>
              </Tooltip>
            )}
            <Tooltip content="Save this search" targetProps={tooltipTargetProps}>
              <Button onClick={toggleIsCreating} data-test="save-new-search-button" icon="floppy-disk" />
            </Tooltip>
          </ButtonGroup>
        </>
      )}
      <Dialog
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        shouldReturnFocusOnClose={false}
        className="min-w-[50%] bg-white p-0"
        title="Create a new saved search"
      >
        <OppSavedSearchCreateForm
          templateSavedSearchId={templateSearchId}
          onCancel={toggleIsCreating}
          handleClose={toggleIsCreating}
        />
      </Dialog>
      {selected && (
        <Dialog
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          shouldReturnFocusOnClose={false}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          <OppSavedSearchUpdateForm
            savedSearchId={selected}
            onCancel={toggleIsEditing}
            handleClose={toggleIsEditing}
            initialQuery={query}
            initialFilters={filters}
          />
        </Dialog>
      )}
    </>
  );
};
