import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';

import { useCreateWorkableInteractionMutation } from 'api/workableInteractionsApi';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { InteractionProps } from 'types/shared/InteractionProps';

export const SearchFollowButton = ({ type, id, interaction, isLoading, iconOnly }: InteractionProps) => {
  const { isMobile } = useDeviceWidth();
  const { trackEvent } = useEventTracking();
  const [interact, { isLoading: isInteracting }] = useCreateWorkableInteractionMutation();

  const text = interaction?.followedAt ? 'Following' : 'Follow';
  return (
    <Tooltip content={text} disabled={!isMobile && !iconOnly}>
      <Button
        disabled={isLoading || isInteracting}
        icon="thumbs-up"
        text={isMobile || iconOnly ? '' : text}
        data-testid={text.toLowerCase()}
        loading={isInteracting}
        intent={interaction?.followedAt ? 'success' : 'none'}
        onClick={async () => {
          const followed = !interaction?.followedAt;
          await interact({ interactableType: type, interactableId: id, followed });
          trackEvent({
            object: type.toLowerCase() as Lowercase<string>,
            action: followed ? 'followed' : 'unfollowed',
            properties: { type, id }
          });
        }}
        data-test="follow-button"
      />
    </Tooltip>
  );
};
