import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';

import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { errorToast, successToast } from 'app/lib/toaster';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import {
  useGetTrawlerConfigQuery,
  useCreateTrawlerConfigMutation,
  useUpdateTrawlerConfigMutation,
  useBulkUpdateTrawlerConfigsMutation,
  useDeleteTrawlerConfigMutation
} from 'api/sysAdminTrawlerConfigsApi';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';
import { safeParse } from 'app/lib/strings';
import { TrawlerConfig } from 'types/__generated__/GovlyApi';

type TrawlerConfigFormProps = {
  id: string;
  onCancel: () => void;
  action: 'delete' | 'edit' | 'new' | string;
};

export const TrawlerConfigForm = ({ id, onCancel, action }: TrawlerConfigFormProps) => {
  const [createTrawlerConfig] = useCreateTrawlerConfigMutation();
  const [updateTrawlerConfig] = useUpdateTrawlerConfigMutation();
  const [bulkUpdateTrawlerConfigs] = useBulkUpdateTrawlerConfigsMutation();
  const [deleteTrawlerConfig] = useDeleteTrawlerConfigMutation();

  const { table } = useGovlyTableContext<TrawlerConfig>();
  const selectedIds = table.getSelectedRowModel().rows.map(r => r.original.id);
  const isBulkUpdate = action === 'edit' && id === 'bulk';

  const { data: trawlerConfig, isLoading } = useGetTrawlerConfigQuery({ id }, { skip: !id });

  const [title, mutation] = isBulkUpdate
    ? ['Bulk Update', bulkUpdateTrawlerConfigs]
    : action === 'new'
      ? ['New Trawler Config(s)', createTrawlerConfig]
      : action === 'delete'
        ? ['Delete Trawler Config', deleteTrawlerConfig]
        : ['Update Trawler Config', updateTrawlerConfig];

  if (isLoading) return null;

  const validationSchema = yup.object({
    job: yup.string().required('Job is required'),
    input: yup.string().test('input', 'Input must be valid JSON.', value => safeParse(value))
  });

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        id: trawlerConfig?.id || '',
        job: trawlerConfig?.job || 'Trawler::CheerioTrawlerJob',
        tier: trawlerConfig?.tier || 'sequential',
        active: trawlerConfig?.active || false,
        bulk: isBulkUpdate,
        input: JSON.stringify(JSON.parse(trawlerConfig?.inputJson || '{}'), null, 2)
      }}
      validationSchema={validationSchema}
      onSubmit={async values => {
        const payload = isBulkUpdate ? { ...values, ids: selectedIds } : values;
        try {
          await mutation(payload);
          successToast(action === 'delete' ? 'TrawlerConfig deleted' : 'TrawlerConfig(s) saved');
          table.resetRowSelection();
          onCancel();
        } catch (e) {
          errorToast(e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card title={title} rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}>
            <CardBody>
              {action === 'delete' ? (
                <p>Are you sure you want to delete this trawlerConfig?</p>
              ) : (
                <>
                  <SelectInput
                    name="job"
                    label="Job"
                    items={[
                      { label: 'Cheerio', value: 'Trawler::CheerioTrawlerJob' },
                      { label: 'Playwright', value: 'Trawler::PlaywrightTrawlerJob' }
                    ]}
                    filterable={false}
                  />
                  <SelectInput
                    name="tier"
                    label="Tier"
                    items={['tri_daily', 'sequential'].map(n => ({ label: n, value: n }))}
                    filterable={false}
                  />
                  <SwitchInput large name="active" label="Active?" />
                  {!isBulkUpdate && (
                    <>
                      <SwitchInput
                        disabled={!!trawlerConfig?.id}
                        large
                        name="bulk"
                        label="Bulk Create? (Pass an array as an input to create many at once)"
                      />
                      <TextAreaInput name="input" label="Input" />
                    </>
                  )}
                </>
              )}
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text={action === 'delete' ? 'Delete' : 'Save'}
                intent={action === 'delete' ? 'danger' : 'primary'}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
              {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
