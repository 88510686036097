import { OrganizationUserCurrentUser } from 'types/__generated__/GovlyApi';

import { useGetCurrentUserQuery } from 'api/currentUserApi';

export type Subscription =
  | 'organizationPaid'
  | 'seatPaid'
  | 'api'
  | 'sled'
  | 'souptools'
  | 'primeAutomations'
  | 'primeIngestion';

const hasSubscription = (subscription: Subscription, currentUser?: OrganizationUserCurrentUser) => {
  if (!currentUser) return false;

  const {
    subscriptionHasApiIntegrations,
    subscriptionHasSLED,
    subscriptionHasPrimeAutomations,
    subscriptionHasPrimeIngestion,
    subscriptionSeatPaid,
    subscriptionHasSouptools,
    subscriptionOrganizationPaid,
    subscriptionActive
  } = currentUser;

  if (!subscriptionActive) return false;

  switch (subscription) {
    case 'organizationPaid':
      return !!subscriptionOrganizationPaid;
    case 'seatPaid':
      return subscriptionSeatPaid;
    case 'api':
      return subscriptionHasApiIntegrations;
    case 'sled':
      return subscriptionHasSLED;
    case 'souptools':
      return subscriptionHasSouptools;
    case 'primeAutomations':
      return subscriptionHasPrimeAutomations;
    case 'primeIngestion':
      return subscriptionHasPrimeIngestion;
  }
};

/**
 * Returns whether the current user has the given subscriptions.
 */
export const useSubscriptions = <T extends Subscription>({
  subscriptions
}: {
  subscriptions: T[];
}): Record<T, boolean> & { isLoading: boolean } => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  const subs = Object.fromEntries(
    subscriptions.map(subscription => [subscription, hasSubscription(subscription, currentUser)])
  ) as Record<T, boolean> & { isLoading: boolean };

  subs.isLoading = isLoading;
  return subs;
};

/**
 * Returns whether the current user has the given subscription.
 */
export const useSubscription = ({
  subscription
}: {
  subscription: Subscription;
}): [boolean, { isLoading: boolean }] => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  return [hasSubscription(subscription, currentUser), { isLoading: isLoading }];
};
