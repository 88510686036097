import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { NotFound } from 'app/molecules/NotFound/NotFound';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';

export const SouptoolsPage = () => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  if (isLoading) {
    return <AppLoading />;
  }

  if (!currentUser?.subscriptionHasSouptools) {
    return <NotFound />;
  }

  return (
    <Main>
      <MainColumn>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </MainColumn>
    </Main>
  );
};
