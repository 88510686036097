import React from 'react';

import {
  useCreateRecordChannelPartnerMutation,
  useUpdateRecordChannelPartnerMutation,
  useDeleteRecordChannelPartnerMutation,
  useGetChannelPartnersQuery
} from 'api/channelPartnersApi';
import { VendorPartnerForm } from 'app/organisms/VendorPartnerForm/VendorPartnerForm';

type VendorChannelPartnerFormProps = {
  vendorId: string;
};

export const VendorChannelPartnerForm = ({ vendorId }: VendorChannelPartnerFormProps) => (
  <VendorPartnerForm
    vendorId={vendorId}
    recordTitle="Channel Partner"
    initialValues={{ channelPartnerableId: vendorId, channelPartnerableType: 'Vendor' }}
    useOnCreate={useCreateRecordChannelPartnerMutation}
    useOnUpdate={useUpdateRecordChannelPartnerMutation}
    useOnDelete={useDeleteRecordChannelPartnerMutation}
    useGetList={useGetChannelPartnersQuery}
  />
);
