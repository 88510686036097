import { LlmAssistantThreadIndex, LlmAssistantThreadShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['WorkspaceAssistantThreads'] });

type GetWorkspaceAssistantThreads = {
  params: { workspaceId: string };
  result: LlmAssistantThreadIndex[];
};

type GetWorkspaceAssistantThread = {
  params: { workspaceId: string; id: string };
  result: LlmAssistantThreadShow;
};

type CreateWorkspaceAssistantThread = {
  params: { workspaceId: string };
  result: LlmAssistantThreadShow;
};

export const workspaceAssistantThreadsApi = api.injectEndpoints({
  endpoints: build => ({
    getWorkspaceAssistantThreads: build.query<
      GetWorkspaceAssistantThreads['result'],
      GetWorkspaceAssistantThreads['params']
    >({
      query: ({ workspaceId }) => ({
        url: `/v2/llm/workspace_assistant_threads`,
        params: { workspaceId },
        method: 'GET'
      }),
      providesTags: ['WorkspaceAssistantThreads']
    }),

    getWorkspaceAssistantThread: build.query<
      GetWorkspaceAssistantThread['result'],
      GetWorkspaceAssistantThread['params']
    >({
      query: ({ id, workspaceId }) => ({
        url: `/v2/llm/workspace_assistant_threads/${id}`,
        params: { workspaceId },
        method: 'GET'
      }),
      providesTags: ['WorkspaceAssistantThreads']
    }),

    createWorkspaceAssistantThread: build.mutation<
      CreateWorkspaceAssistantThread['result'],
      CreateWorkspaceAssistantThread['params']
    >({
      query: body => ({
        url: `/v2/llm/workspace_assistant_threads`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['WorkspaceAssistantThreads']
    })
  })
});

export const {
  useGetWorkspaceAssistantThreadsQuery,
  useGetWorkspaceAssistantThreadQuery,
  useCreateWorkspaceAssistantThreadMutation
} = workspaceAssistantThreadsApi;
