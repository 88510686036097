import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { NonIdealState } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useLazyOppAggregationQuery } from 'api/oppsApi';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { OppSearchAnalyticsTableDrawer } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsTableDrawer';
import {
  AggregationQueryResponse,
  makeDrilldownFiltersSelector,
  useOppSearchAnalyticsUpcomingExpirationStore
} from 'app/organisms/OppSearchAnalyticsUpcomingExpiration/useOppSearchAnalyticsUpcomingExpirationStore';
import { OppSearchAnalyticsUpcomingExpirationDrawerTitle } from 'app/organisms/OppSearchAnalyticsUpcomingExpiration/OppSearchAnalyticsUpcomingExpirationDrawerTitle';

const title = 'Upcoming Expirations';

export const OppSearchAnalyticsUpcomingExpiration = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const { values } = useFormikContext<OppSearchState>();
  const { query, filters } = values;

  const { isDrawerOpen, setIsDrawerOpen } = useOppSearchAnalyticsUpcomingExpirationStore();
  const drilldownFiltersSelector = useMemo(() => makeDrilldownFiltersSelector(filters), [filters]);
  const drilldownFilters = useOppSearchAnalyticsUpcomingExpirationStore(drilldownFiltersSelector);

  const [getOppsAggregation, { data = {}, isLoading, isError }] = useLazyOppAggregationQuery();

  useEffect(() => {
    useOppSearchAnalyticsUpcomingExpirationStore.setState({ lastClickedPoint: undefined });
    getOppsAggregation({ query, ...filters, aggs: ['upcoming_expiration_agg'] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIsLoading]);

  const agg = useMemo(() => (data as AggregationQueryResponse).aggs?.upcomingExpirationAgg, [data]);

  const options: Highcharts.Options = useMemo(() => {
    const sorted = agg ? [...agg.ranges.buckets].sort((a, b) => a.to - b.to) : [];
    const data: Highcharts.SeriesBarOptions['data'] = sorted.map(({ key, docCount }) => {
      return { name: startCase(key), y: docCount };
    });
    const seriesData = [{ type: 'bar' as const, name: 'Opportunities', data: data }];

    return {
      series: seriesData,
      chart: {
        type: 'bar'
      },
      title: {
        text: undefined
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: undefined
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: e => {
                useOppSearchAnalyticsUpcomingExpirationStore.setState(current => ({
                  ...current,
                  isDrawerOpen: true,
                  lastClickedPoint: e.point
                }));
              }
            }
          }
        }
      }
    };
  }, [agg]);

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (isLoading || !agg) {
    return <Loading />;
  }

  if (!agg?.ranges?.buckets?.find(b => b.docCount > 0)) {
    return (
      <Card title="Upcoming Expirations">
        <CardBody>
          <NonIdealState icon="warning-sign" title="No upcoming expirations" />
        </CardBody>
      </Card>
    );
  }

  return (
    <ErrorBoundary action="OppSearchAnalyticsUpcomingExpiration" fallback={<CardError title={title} />}>
      <Card title={title}>
        <CardBody>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </CardBody>
      </Card>

      <OppSearchAnalyticsTableDrawer
        drawerTitle={<OppSearchAnalyticsUpcomingExpirationDrawerTitle />}
        id="opps_posted_at"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        key={JSON.stringify({ ...drilldownFilters })}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};
