import React from 'react';
import { Tag, Icon, Tooltip } from '@blueprintjs/core';

import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { OppWorkspaceHeader } from 'app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { showWorkspace } from 'app/lib/authorize';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { FollowDisplayable, WorkspaceIndex } from 'types/__generated__/GovlyApi';

type OppWorkspacePreviewProps = {
  workspace: WorkspaceIndex;
  displayableFollows?: FollowDisplayable[];
  followsClass: string;
  headerClass: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

export const OppWorkspacePreview = ({
  workspace,
  displayableFollows,
  followsClass,
  headerClass,
  onClick
}: OppWorkspacePreviewProps) => {
  const {
    id,
    name,
    workflowStage,
    follows = [],
    privateAccess,
    commentsCount,
    createdById,
    organizationDefault,
    createdByOrganization,
    organizationLabels = [],
    workableId: oppId
  } = workspace;

  const followsList = displayableFollows || follows;

  const { data } = useGetCurrentUserQuery();
  const { id: currentOrgUserId, role: currentOrgUserRole } = data ?? {};

  const participatingOrganizations = [
    ...new Set(followsList.map(({ organizationUser: { organizationName } = {} }) => organizationName))
  ].sort(n => (n === createdByOrganization ? -1 : 1));

  const canView = showWorkspace({ privateAccess, follows, currentOrgUserRole, createdById, currentOrgUserId });

  return (
    <>
      <div className={headerClass}>
        <OppWorkspaceHeader
          id={id}
          onClick={onClick}
          oppId={oppId}
          name={name}
          organizationDefault={organizationDefault}
          showLink={canView}
          createdByOrganization={createdByOrganization}
        />
        <div className="flex flex-wrap items-center gap-2">
          <WorkflowStageTag workflowStage={workflowStage} />
          {!!commentsCount && (
            <Tag
              minimal
              icon={<Icon size={12} icon="chat" />}
              intent="primary"
              interactive={canView}
              onClick={canView ? onClick : undefined}
            >
              {commentsCount}
            </Tag>
          )}
          {privateAccess && (
            <Tooltip content="Only invited team members may join">
              <Tag minimal intent="danger" icon={<Icon size={12} icon="lock" />} />
            </Tooltip>
          )}
        </div>

        <div className="w-full">
          <LabelList labels={organizationLabels} />
        </div>
      </div>

      {!!followsList.length && (
        <div className={followsClass}>
          <AvatarList
            avatarData={followsList.map(
              ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                initials,
                name,
                organizationName,
                avatar,
                avatarColor,
                notificationProps: {
                  intent: active ? 'success' : 'danger',
                  icon: active ? 'thumbs-up' : 'thumbs-down',
                  size: 'xl',
                  position: 'bottom'
                }
              })
            )}
          />
          <div className="flex flex-wrap gap-1">
            {participatingOrganizations.map(organizationName => {
              if (participatingOrganizations.length > 1 && organizationName === createdByOrganization) {
                return (
                  <Tag key={organizationName} minimal intent="warning">
                    {`Created by ${organizationName}`}
                  </Tag>
                );
              }

              return (
                <Tag key={organizationName} minimal>
                  {organizationName}
                </Tag>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
