import React from 'react';

import {
  useCreateRecordResellerMutation,
  useUpdateRecordResellerMutation,
  useDeleteRecordResellerMutation,
  useGetResellersQuery
} from 'api/resellersApi';
import { VendorPartnersFormList } from 'app/organisms/VendorPartnersFormList/VendorPartnersFormList';

export const VendorResellersFormList = ({ vendorId }: { vendorId: string }) => (
  <VendorPartnersFormList
    vendorId={vendorId}
    recordTitle="Reseller"
    useGetList={useGetResellersQuery}
    useOnCreate={useCreateRecordResellerMutation}
    useOnUpdate={useUpdateRecordResellerMutation}
    useOnDelete={useDeleteRecordResellerMutation}
  />
);
