import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { type LLMOutputComponent } from '@llm-ui/react';
import { AssistantLlmBlockProps } from './AssistantLlmBlocks';

const AssistantLlmMarkdown: LLMOutputComponent<AssistantLlmBlockProps> = ({ blockMatch }) => {
  const markdown = blockMatch.output;
  return (
    <Markdown className="[&>p:last-of-type]:last-of-type:mb-0" remarkPlugins={[remarkGfm]}>
      {markdown}
    </Markdown>
  );
};

export { AssistantLlmMarkdown };
