import React, { useState } from 'react';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { ContactSearchQuery } from 'app/organisms/ContactSearchQuery/ContactSearchQuery';
import { ContactSearchResults } from 'app/organisms/ContactSearchResults/ContactSearchResults';
import { ContactSearchFilters } from 'app/organisms/ContactSearchFilters/ContactSearchFilters';
import { ContactSearchResultsControls } from 'app/organisms/ContactSearchResultsControls/ContactSearchResultsControls';
import { SearchPagination } from 'app/molecules/SearchPagination/SearchPagination';
import { useContactSearchPagination } from 'api/contactsApi';
import { useInitializeContactSearchCache } from 'app/hooks/search/useContactSearchCache';
import { Button, Drawer } from '@blueprintjs/core';
import { SearchFilterContainer } from 'app/molecules/SearchFilterContainer/SearchFilterContainer';

export const ContactSearch = () => {
  useInitializeContactSearchCache();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return (
    <>
      <Main className="py-0" gridClassName="lg:grid-cols-[360px,repeat(11,1fr)] xl:grid-cols-[420px,repeat(11,1fr)]">
        <SearchFilterContainer>
          <ContactSearchFilters />
        </SearchFilterContainer>

        <MainColumn tag="section" className="pt-4 pb-32 lg:col-span-11">
          <div className="p-6 lg:p-0">
            <Button
              onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
              className="mb-8 lg:hidden"
              text="Edit Filters"
              rightIcon="chevron-right"
              large
            />

            <ContactSearchQuery />
          </div>
          <ContactSearchResultsControls />
          <ContactSearchResults />
          <SearchPagination paginationHook={useContactSearchPagination} />
        </MainColumn>
      </Main>

      <Drawer
        isOpen={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        size="100%"
        position="left"
        title=""
        className="overflow-scroll"
      >
        <ContactSearchFilters wrapperClassName="h-full overflow-y-auto" className="pb-12" />
      </Drawer>
    </>
  );
};
