import React from 'react';
import { FormGroup, Checkbox, Tooltip } from '@blueprintjs/core';
import { Formik, Form } from 'formik';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { CheckboxInput } from 'app/atoms/inputs/CheckboxInput/CheckboxInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { useUpdateNotificationSettingMutation } from 'api/organizationUsersApi';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { successToast, errorToast } from 'app/lib/toaster';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';
import { Entries } from 'app/lib/typeUtils';

type UserNotificationSettings = {
  matched?: string[];
  updateWorkable?: string[];
  digestFrequency?: string;
  updateWorkspace?: string[];
  orgDigestFrequency?: string;
};

type UserProfileNotificationSettingsProps = {
  organizationUser?: OrganizationUserCurrentOrg;
};

export const UserProfileNotificationSettings = ({ organizationUser }: UserProfileNotificationSettingsProps) => {
  const { trackEvent } = useEventTracking();
  const isAdmin = useAuthorized({ role: 'admin' });
  const currentOrgUserId: string | undefined = useCurrentUserAttribute('id');
  const subscriptionActive: boolean | undefined = useCurrentUserAttribute('subscriptionActive');
  const [updateNotificationSetting] = useUpdateNotificationSettingMutation();
  const organizationUserId = organizationUser?.id;
  const notificationSettings = organizationUser?.notificationSettings as UserNotificationSettings;

  const isSelf = organizationUserId === currentOrgUserId;
  const authorized = isSelf || isAdmin;

  if (!organizationUserId) return null;

  const DIGEST_CONFIG = {
    digestFrequency: {
      description: 'We will send you a digest of all your updates:',
      disabled: false,
      tooltip: undefined,
      customOption: { label: 'Hourly', value: 'hourly', className: 'mb-0' }
    },
    orgDigestFrequency: {
      description: "We will send you a digest of your organization's updates:",
      disabled: false,
      tooltip: undefined,
      customOption: { label: 'Weekly', value: 'weekly', className: 'mb-0' }
    }
  };

  const NOTIFICATION_CONFIG = {
    updateWorkspace: {
      description: 'When Workspaces you are following are updated or you are added to a new Workspace:',
      disabled: false,
      tooltip: undefined
    },
    updateWorkable: {
      description: 'When opportunities you are following are modified:',
      disabled: false,
      tooltip: undefined
    },
    matched: {
      description: 'When you have a new saved search match on an opportunity:',
      disabled: !subscriptionActive,
      tooltip: 'Upgrade your account to enable instant match notifications.'
    }
  };

  const updateSetting = async (settingInfo: {
    organizationUserId: string;
    setting: string;
    value: string;
    checked?: boolean;
  }) => {
    const { error } = await updateNotificationSetting(settingInfo);
    if (error) return errorToast(error);
    successToast('Notification settings updated.');
  };

  const sendUpdates = (updates: { organizationUserId: string; setting: string; value: string; checked?: boolean }) => {
    updateSetting(updates);
    trackEvent({
      object: 'user',
      action: 'updated',
      properties: { ...updates }
    });
  };

  return (
    <Card className="mb-4" title="Notification Settings">
      <CardBody>
        {(Object.entries(DIGEST_CONFIG) as Entries<typeof DIGEST_CONFIG>).map(([key, config]) => {
          return (
            <Formik
              key={key}
              initialValues={{ [key]: notificationSettings[key] || 'never' }}
              onSubmit={values => {
                const updates = {
                  organizationUserId,
                  setting: key,
                  value: values[key]
                };
                sendUpdates(updates);
              }}
            >
              {() => (
                <Form className="flex items-center space-x-4">
                  <RadioGroupInput
                    submitOnChange
                    inline
                    label={config.description}
                    name={key}
                    disabled={!authorized || config.disabled}
                    options={[
                      config.customOption,
                      { label: 'Daily', value: 'daily', className: 'mb-0' },
                      { label: 'Never', value: 'never', className: 'mb-0' }
                    ]}
                  />
                </Form>
              )}
            </Formik>
          );
        })}

        {(Object.entries(NOTIFICATION_CONFIG) as Entries<typeof NOTIFICATION_CONFIG>).map(([key, config]) => {
          return (
            <FormGroup key={key} label={config.description}>
              <Formik
                initialValues={{
                  [key]: config.disabled
                    ? false
                    : notificationSettings[key] && notificationSettings[key].includes('email')
                }}
                onSubmit={() => {
                  const values = {
                    organizationUserId,
                    setting: key,
                    checked: !(notificationSettings[key] && notificationSettings[key].includes('email')),
                    value: 'email'
                  };
                  sendUpdates(values);
                }}
              >
                {() => (
                  <Form className="flex items-center space-x-4">
                    <FormGroup className="m-0" disabled label={<LabelSpan />}>
                      <Checkbox
                        checked={notificationSettings.digestFrequency !== 'never'}
                        label="Digest Notification"
                        disabled
                      />
                    </FormGroup>
                    <Tooltip
                      lazy
                      content={config.tooltip}
                      disabled={authorized && !config.disabled}
                      className="bp5-inline m-0"
                    >
                      <CheckboxInput
                        label="Email Notification"
                        submitOnChange
                        name={key}
                        disabled={!authorized || config.disabled}
                      />
                    </Tooltip>
                  </Form>
                )}
              </Formik>
            </FormGroup>
          );
        })}
      </CardBody>
    </Card>
  );
};
