import { useMemo } from 'react';

import { useGetOppQuery } from 'api/oppsApi';
import { notNullish } from 'app/lib/notNullish';
import { OrganizationUserFollow, PartnershipProvider } from 'types/__generated__/GovlyApi';

export type ProviderContactRow = OrganizationUserFollow & { peerId: string | undefined };
export type ProviderRow = PartnershipProvider;

export type OppDetailsProps = {
  id: string;
};

export const useOppDetailsProviders = ({ id }: { id: string }) => {
  const { data, isLoading } = useGetOppQuery({ id });
  const { providerPartnerships = [] } = data ?? {};

  return { providerPartnerships, isLoading };
};

export const useOppDetailsProviderContactsTableData = ({ id }: { id: string }) => {
  const { data, isLoading } = useGetOppQuery({ id });

  const { providerPartnerships = [] } = data ?? {};

  const providerContacts = useMemo<ProviderContactRow[]>(() => {
    if (!providerPartnerships) return [];

    return providerPartnerships
      .flatMap(
        ({ representatives, peerId }) => representatives?.map(representative => ({ ...representative, peerId })) ?? []
      )
      .map(({ peerId, organizationUser }) => (organizationUser ? { ...organizationUser, peerId } : undefined))
      .filter(notNullish);
  }, [providerPartnerships]);

  return { providerContacts, isLoading };
};
