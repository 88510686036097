import React from 'react';
import { FuzzyInputMultiSelect, FuzzyInputMultiSelectProps } from '../InputMultiSelect/FuzzyInputMultiSelect';
import { InputMultiSelectHook, GetHookItem } from '../InputMultiSelect/utils';

/**
 * This component is a wrapper around the FuzzyInputMultiSelect component.
 * It allows you to use an async typeahead hook to fetch items on mount
 */
export type QueryInputMultiSelectProps<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<FuzzyInputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
> = Omit<FuzzyInputMultiSelectProps<Item>, 'items' | 'loading' | 'selectedItems' | 'onChange' | 'name'> & {
  useHook: Hook;
  hookArgs?: Parameters<Hook>;
  getItemsBasedProps: (items: Item[]) => ItemsBasedProps;
};

export function QueryInputMultiSelect<
  Hook extends InputMultiSelectHook,
  Item extends GetHookItem<Hook>,
  ItemsBasedProps extends Pick<FuzzyInputMultiSelectProps<Item>, 'selectedItems' | 'onChange' | 'name'>
>({ useHook, hookArgs, getItemsBasedProps, ...props }: QueryInputMultiSelectProps<Hook, Item, ItemsBasedProps>) {
  const { data = [], isLoading } = useHook(...(hookArgs ?? []));

  return (
    <FuzzyInputMultiSelect
      disabled={!isLoading && data.length === 0}
      items={data as Item[]}
      loading={isLoading}
      {...getItemsBasedProps(data as Item[])}
      {...props}
    />
  );
}
