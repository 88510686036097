import { QuoteShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Quotes'] });

type GetQuotesForWorkspace = {
  params: { workspaceId: string };
  result: QuoteShow[];
};

type CreateQuote = {
  params: { workspaceId: string; file: string };
  result: QuoteShow;
};

type DeleteQuote = {
  params: { id: string };
  result: void;
};

export const quotesApi = api.injectEndpoints({
  endpoints: build => ({
    getQuotesForWorkspace: build.query<GetQuotesForWorkspace['result'], GetQuotesForWorkspace['params']>({
      query: ({ workspaceId }) => ({ url: '/v2/quotes', params: { workspaceId } }),
      providesTags: ['Quotes']
    }),

    createQuote: build.mutation<CreateQuote['result'], CreateQuote['params']>({
      query: body => ({
        url: `/v2/quotes`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Quotes']
    }),

    deleteQuote: build.mutation<DeleteQuote['result'], DeleteQuote['params']>({
      query: ({ id }: { id: string }) => ({ url: `/v2/quotes/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Quotes']
    })
  })
});

export const { useCreateQuoteMutation, useDeleteQuoteMutation, useGetQuotesForWorkspaceQuery } = quotesApi;
