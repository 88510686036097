import React, { useMemo } from 'react';
import { ProgressBar, Tag, Tooltip, Button } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { FeedShow } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';

import { cn } from 'app/lib/cn';
import { Card, CardBody } from 'app/atoms/Card/Card';

import { PublicFeedDeactivationButton } from './PublicFeedDeactivationButton';
import { PrivateFeedDeactivationButton } from './PrivateFeedDeactivationButton';
import { SearchableFeedsListFedCardShowInterestButton } from './SearchableFeedsListFedCardShowInterestButton';

export type FeedShowExtended = FeedShow & {
  isDisabled?: boolean;
  activated: boolean;
  owned: boolean;
  partnerFeedIds: string[];
};

export type SearchableFeedsListFedCardProps = {
  feed: FeedShowExtended;
};

export const SearchableFeedsListFedCard = ({ feed }: SearchableFeedsListFedCardProps) => {
  const [_searchParams, setSearchParams] = useSearchParams();

  const openFeed = (id: string) => {
    setSearchParams({ drawerType: 'feed', drawerId: id });
  };

  const description = feed.contractVehicle?.description || feed.contractVehicle?.shortDescription;
  const providerFeedsQuery = useGetProviderFeedsQuery(
    { asPartnershipConsumer: 'true' },
    {
      skip: feed.public,
      selectFromResult: result => ({
        ...result,
        data: result.data?.filter(pf => pf.feedId === feed.id)
      })
    }
  );
  const currentOrganizationQuery = useGetCurrentOrganizationQuery(undefined, {
    skip: feed.public
  });

  const ocfCompletionGroups = useMemo(
    () => new Set(providerFeedsQuery.data?.flatMap(ocf => ocf.completionGroups)),
    [providerFeedsQuery.data]
  );

  const completionGroupStats = useMemo(() => {
    const total = feed.completionGroups.length;
    const intersection = new Set(feed.completionGroups).intersection(ocfCompletionGroups);

    return {
      total,
      percentage: intersection.size / total,
      formattedPercentage: ((intersection.size / total) * 100).toFixed(0)
    };
  }, [feed.completionGroups, ocfCompletionGroups]);

  const activePartnersCount = useMemo(() => {
    const orgs = providerFeedsQuery.data?.filter(ocf => ocf.organization.id !== currentOrganizationQuery.data?.id);
    const uniqueOrgs = new Set(orgs?.map(ocf => ocf.organization.id));
    return uniqueOrgs.size;
  }, [currentOrganizationQuery.data?.id, providerFeedsQuery.data]);

  return (
    <Card>
      <CardBody className="p-6">
        <div className="flex flex-col gap-4" role="listitem" aria-label={`feed ${feed.name}`}>
          <div className="flex justify-between">
            <div className="flex gap-3">
              <div className="w-14 h-14 flex justify-center items-center bg-gray-100 p-px">
                {feed.contractVehicle?.logo?.link ? (
                  <img
                    src={feed.contractVehicle?.logo.link}
                    alt="logo"
                    className="max-w-full max-h-full object-contain"
                  />
                ) : (
                  <div className="text-lg text-gray-500">{feed.name.replace(/[a-z -]/g, '').slice(0, 3)}</div>
                )}
              </div>
              <div className="flex flex-col items-start gap-px">
                <h4>{feed.name}</h4>

                <div className="flex gap-1 items-center">
                  {feed.public ? (
                    <Tag intent="primary">Public Feed</Tag>
                  ) : (
                    <Tag minimal icon="lock">
                      Private Feed
                    </Tag>
                  )}

                  {activePartnersCount > 0 ? (
                    <Tag minimal intent="success">
                      Active Partners: {activePartnersCount}
                    </Tag>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              {feed.public ? (
                <PublicFeedDeactivationButton
                  activated={feed.activated}
                  isDisabled={feed.isDisabled}
                  feedId={feed.id}
                />
              ) : (
                <div className="flex gap-2">
                  {feed.isDisabled ? null : <Button onClick={() => openFeed(feed.id)}>Customize</Button>}

                  <PrivateFeedDeactivationButton
                    activated={feed.activated}
                    isDisabled={feed.isDisabled}
                    partnerFeedIds={feed.partnerFeedIds}
                  />

                  {feed.isDisabled ? <SearchableFeedsListFedCardShowInterestButton feed={feed} /> : null}
                </div>
              )}
            </div>
          </div>

          {description ? <p className="text-gray-500 mb-0">{description}</p> : null}

          {completionGroupStats.total > 0 ? (
            <div className="flex flex-col gap-8">
              <ul className="flex items-center flex-wrap gap-1" aria-label="completion groups">
                {feed.completionGroups
                  .slice()
                  .sort((a, b) => {
                    if (ocfCompletionGroups.has(a) && !ocfCompletionGroups.has(b)) return -1;
                    if (!ocfCompletionGroups.has(a) && ocfCompletionGroups.has(b)) return 1;
                    return a.localeCompare(b);
                  })
                  .map(group => (
                    <li key={group}>
                      <Tooltip
                        placement="top"
                        content={
                          <div className="w-52">
                            {ocfCompletionGroups.has(group)
                              ? 'You have access to solicitations with this set-aside.'
                              : 'You do not have access to solicitations with this set-aside'}
                          </div>
                        }
                      >
                        <Tag
                          {...(ocfCompletionGroups.has(group)
                            ? { intent: 'warning', icon: 'eye-on', minimal: true }
                            : { icon: 'eye-off', minimal: true })}
                        >
                          {group}
                        </Tag>
                      </Tooltip>
                    </li>
                  ))}
              </ul>

              <div>
                <div>
                  <Tooltip
                    content={
                      <div className="w-52">
                        Completion Status measures your visibility into solicitations within this contract vehicle,
                        based on the set-asides you have access to
                      </div>
                    }
                  >
                    <h4 className="text-gray-500 text-xxs">Completion status:</h4>
                  </Tooltip>
                </div>

                <div className="flex items-center gap-2">
                  <span
                    className={cn('text-xl font-bold', {
                      'text-blue-500': completionGroupStats.percentage > 0,
                      'text-gray-500': completionGroupStats.percentage === 0
                    })}
                  >
                    {completionGroupStats.formattedPercentage}%
                  </span>

                  <ProgressBar
                    className="h-3 flex-1"
                    animate={false}
                    stripes={false}
                    intent="primary"
                    value={completionGroupStats.percentage}
                  />
                </div>
              </div>
            </div>
          ) : undefined}
        </div>
      </CardBody>
    </Card>
  );
};
