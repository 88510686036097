import React from 'react';

export type InputErrorMessageProps = {
  error?: string | false | undefined;
};

export const InputErrorMessage = ({ error }: InputErrorMessageProps) => {
  if (!error) {
    return null;
  }

  return <small className="text-xs text-red-500">{error}</small>;
};
