import React, { useEffect, useState } from 'react';
import { Callout } from '@blueprintjs/core';
import { Outlet, useSearchParams } from 'react-router-dom';

import { Card } from 'app/atoms/Card/Card';
import { CenterPageHighlight } from 'app/molecules/CenterPageHighlight/CenterPageHighlight';
import { useMetaTags } from 'app/hooks/useMetaTags';
import { safeParse } from 'app/lib/strings';

export const SignInPage = () => {
  useMetaTags({ title: 'Sign In - Govly' });
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const paramError = searchParams.get('error');

    if (paramError) {
      setError(paramError);
      searchParams.delete('error');
      setSearchParams(searchParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flashMessages = safeParse(document.querySelector('body')?.dataset?.flash);

  return (
    <CenterPageHighlight>
      <Card>
        {flashMessages.error && (
          <Callout intent="danger" title="Error" data-test="SessionError">
            {flashMessages.error}
          </Callout>
        )}

        {!flashMessages.error && error === '401' && (
          <Callout intent="danger" title="Not Authorized" data-test="SessionError">
            Please login to access this page.
          </Callout>
        )}
        <Outlet />
      </Card>
    </CenterPageHighlight>
  );
};
