import React from 'react';
import truncate from 'lodash-es/truncate';

import { DescriptionListRow } from 'app/molecules/DescriptionList/DescriptionList';
import { OppDetailsDescription } from 'app/organisms/OppDetailsDescription/OppDetailsDescription';
import { useGetOppQuery } from 'api/oppsApi';
import { formatTime, DATETIME_24_SHORT, DATE_SHORT } from 'app/lib/dates';
import { OppDetailsOrganizationOppContext } from 'app/organisms/OppDetailsOrganizationOppContext/OppDetailsOrganizationOppContext';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { OppShow } from 'types/__generated__/GovlyApi';

export const OppDetailsDescriptionFieldsPrediction = ({ id, opp }: { id: string; opp: OppShow }) => {
  const { postedAt, awardedAt, cancelledAt, modifiedAt, modified, parentOppId, latestData: sourceData = {} } = opp;

  const {
    isLoading,
    isSuccess: isAuthorized,
    data: sourceOpp
  } = useGetOppQuery({ id: parentOppId ?? '' }, { skip: !parentOppId });

  const publicLink = sourceData.externalUrl ?? sourceData.procurementPortalPublicUrl;

  const rows = [
    {
      label: 'Source Opportunity',
      value: !isLoading && isAuthorized && sourceOpp?.id && sourceOpp?.displayName && (
        <LinkTag target="_blank" to={`/opportunities/${sourceOpp.id}`}>
          {sourceOpp.displayName}
        </LinkTag>
      )
    },
    { label: 'Predicted Post Date', value: formatTime(postedAt ?? '', DATE_SHORT) },
    { label: 'Awarded At', value: formatTime(awardedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Cancelled At', value: formatTime(cancelledAt ?? '', DATETIME_24_SHORT) },
    { label: 'Modified At', value: modified && modifiedAt && formatTime(modifiedAt, DATETIME_24_SHORT) },
    { label: 'Predicted Contract Vehicle', value: sourceData.contract?.name || sourceData.contract?.contractName },
    { label: 'Clearance?', value: sourceData.clearance_designation },
    { label: 'Predicted Set Aside', value: sourceData.setAside },
    { label: 'Predicted Groups', value: sourceData.groups?.join(', ') },
    {
      label: 'NAICS Codes',
      value: sourceData.naicsCodes?.map(({ code }) => code).join(', ')
    },
    { label: 'Latest Modification Reason', value: sourceData.modificationReason },
    { label: 'Response Location', value: sourceData.responseLocation },
    { label: 'Delivery Instructions', value: sourceData.deliveryInstructions },
    {
      label: 'Public Link',
      value: publicLink && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={publicLink}>
          {truncate(publicLink.replace(/^http?:\/\//, ''), { length: 45 })}
        </LinkTag>
      )
    }
  ];

  return (
    <>
      {rows.map(({ label, value }) => (
        <DescriptionListRow label={label} value={value} key={label} />
      ))}

      <OppDetailsOrganizationOppContext id={id} />
      <OppDetailsDescription description={sourceData.description ?? ''} />
    </>
  );
};
