import React from 'react';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

const year = new Date().getFullYear();

export const AppFooter = () => (
  <footer>
    <div className="mx-auto max-w-7xl overflow-hidden py-6 px-4 sm:px-6 lg:px-8">
      <div className="flex content-center justify-center">
        <LinkTag tag="a" href="https://www.govly.com/terms">
          <div className="px-4 py-2">Terms</div>
        </LinkTag>
        <LinkTag tag="a" href="https://www.govly.com/privacy">
          <div className="px-4 py-2">Privacy</div>
        </LinkTag>
      </div>
      <p className="mt-4 text-center text-base text-gray-400">{`© ${year} Govly, Inc. All rights reserved.`}</p>
    </div>
  </footer>
);
