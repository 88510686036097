import React from 'react';

import { AwardSearchResultsCards } from 'app/organisms/AwardSearchResultsCards/AwardSearchResultsCards';
import { AwardSearchResultsTable } from 'app/organisms/AwardSearchResultsTable/AwardSearchResultsTable';

import { useResultsView } from 'app/organisms/AwardSearchResults/utils';

export const AwardSearchResults = () => {
  const resultsView = useResultsView();

  return resultsView === 'cards' ? <AwardSearchResultsCards /> : <AwardSearchResultsTable />;
};
