import React from 'react';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { useField } from 'formik';
import { Tooltip } from '@blueprintjs/core';

type Props = {
  sortItems: { label: string; value: string }[];
};

export const SearchResultsSort = ({ sortItems }: Props) => {
  const [sortDirection] = useField({ name: 'filters.sortDirection' });

  return (
    <>
      <SelectInput
        submitOnChange
        name="filters.sort"
        defaultButtonText="Modified"
        items={sortItems}
        filterable={false}
        filterActive={false}
        contentClassName="mt-0"
        buttonProps={{ large: false, icon: 'property' }}
      />
      <Tooltip content="Sort Order">
        <SelectInput
          submitOnChange
          name="filters.sortDirection"
          aria-label={`Sort Order: ${sortDirection.value === 'asc' ? 'Ascending' : 'Descending'}`}
          items={[
            { value: 'desc', label: 'Descending' },
            { value: 'asc', label: 'Ascending' }
          ]}
          filterable={false}
          filterActive={false}
          contentClassName="mt-0"
          buttonProps={{
            text: undefined,
            rightIcon: undefined,
            large: false,
            icon: sortDirection.value === 'asc' ? 'sort-asc' : 'sort-desc',
            className: 'p-l-[13]'
          }}
        />
      </Tooltip>
    </>
  );
};
