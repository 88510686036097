import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Alert, Callout } from '@blueprintjs/core';
import * as yup from 'yup';

import { errorToast } from 'app/lib/toaster';
import { useGetCurrentOrganizationQuery, useDeleteOrganizationMutation } from 'api/organizationsApi';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';

export const OrganizationDeleteForm = () => {
  const [deletingAlert, setDeletingAlert] = useState(false);
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const { data: organization, isLoading: organizationLoading } = useGetCurrentOrganizationQuery();

  if (organizationLoading) {
    return <Loading />;
  }

  if (!organization) {
    return <NotFound />;
  }

  const validationSchema = yup.object({
    name: yup.string().oneOf([organization.name], 'Confirmation is invalid.').required('Confirmation is invalid.')
  });

  return (
    <Card title="Delete Organization" className="!bg-red-50">
      <CardBody>
        <Callout intent="danger" title="Deleting an organization is permanent and cannot be undone.">
          <Button large text="Delete Organization" intent="danger" onClick={() => setDeletingAlert(true)} />
        </Callout>
      </CardBody>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ name: '' }}
        onSubmit={async () => {
          try {
            await deleteOrganization({ id: organization.id }).unwrap();
            window.location.href = `/organizations?organizationDeletionQueued=${organization.name}`;
          } catch (e) {
            errorToast(e);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Alert
            cancelButtonText="Cancel"
            icon="trash"
            intent="danger"
            isOpen={deletingAlert}
            onCancel={() => setDeletingAlert(false)}
            onConfirm={handleSubmit}
            loading={isSubmitting}
            confirmButtonText="Delete Organization"
          >
            <Form>
              <TextInput name="name" label="Type the name of the organization and submit to confirm deletion" />
            </Form>
          </Alert>
        )}
      </Formik>
    </Card>
  );
};
