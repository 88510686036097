import {
  ReportReport,
  ReportOrganizationUserReport,
  ReportOrganizationOppSharesReport
} from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetReport = {
  params: {
    report_type:
      | 'Report::ChessRfqReport'
      | 'Report::OrganizationFollowsReport'
      | 'Report::OrganizationFollowsTimelineReport'
      | 'Report::OrganizationOppInteractionsReport'
      | 'Report::OrganizationOppSharesReport'
      | 'Report::OrganizationUserReport';
    dateRange?: [Date, Date];
    orgUserId?: string;
    contractVehicleSlug?: string;
    interaction?: string;
  };
  result: ReportReport | ReportOrganizationUserReport[] | ReportOrganizationOppSharesReport[];
};

type CreateReport = {
  params: {
    reportType: 'Report::ChessRfqReport' | 'Report::OrganizationFollowsReport';
    [key: string]: string;
  };
  result: void;
};

export const reportsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getReport: build.query<GetReport['result'], GetReport['params']>({
      query: ({ report_type, ...params }) => ({
        url: `/v2/reports/${report_type}`,
        params
      })
    }),
    createReport: build.mutation<CreateReport['result'], CreateReport['params']>({
      query: body => ({
        url: `/v2/reports`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetReportQuery, useCreateReportMutation } = reportsApi;
