import React from 'react';
import isEmpty from 'lodash-es/isEmpty';

import { cn } from 'app/lib/cn';
import { Text } from 'app/atoms/Typography/Typography';

export type DescriptionListProps = {
  type?: 'rows' | 'columns';
  className?: string;
  children: React.ReactNode;
};

export const DescriptionList = ({ type, className, children, ...rest }: DescriptionListProps) => {
  if (type === 'columns') {
    return (
      <dl className={cn('grid grid-cols-1 sm:grid-cols-2 gap-y-4', className)} {...rest}>
        {children}
      </dl>
    );
  }

  return (
    <dl className={cn('divide-y divide-gray-100')} {...rest}>
      {children}
    </dl>
  );
};

export const DescriptionListLabel = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <Text tagName="dt" className={cn('text-sm font-medium leading-6', className)}>
    {children}
  </Text>
);

export const DescriptionListValue = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => (
  <Text tagName="dd" className={cn('text-sm leading-6', className)}>
    {children}
  </Text>
);

export type DescriptionListEntryProps = {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
};

export const DescriptionListRow = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName
}: DescriptionListEntryProps) => {
  if (isEmpty(value) || isEmpty(label)) {
    return null;
  }

  return (
    <div className={cn('px-5 py-2 sm:grid sm:grid-cols-3 sm:gap-4 flex flex-col items-center', className)}>
      <DescriptionListLabel className={cn(labelClassName)}>{label}</DescriptionListLabel>
      <DescriptionListValue className={cn('col-span-2', valueClassName)}>{value}</DescriptionListValue>
    </div>
  );
};

export const DescriptionListColumn = ({
  label,
  value,
  className,
  labelClassName,
  valueClassName
}: DescriptionListEntryProps) => {
  if (isEmpty(value) || isEmpty(label)) {
    return null;
  }

  return (
    <div className={cn('sm:col-span-1', className)}>
      <DescriptionListLabel className={cn(labelClassName)}>{label}</DescriptionListLabel>
      <DescriptionListValue className={cn(valueClassName)}>{value}</DescriptionListValue>
    </div>
  );
};
