import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { useFormikContext } from 'formik';
import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import { match } from 'ts-pattern';

export function useGetFormikFilterCountByField<Values>() {
  const { getFieldProps } = useFormikContext();
  const defaultValues = useDefaultSearchStateStore(store => store.defaultState as Values);

  return ({ names }: { names: string[]; submitOnChange?: boolean }) => {
    const fields = names.map(name => getFieldProps(name));

    const filterCountByField = fields.reduce(
      (acc, field) => {
        acc[field.name] = getFilterCount(field.value, get(defaultValues, field.name));
        return acc;
      },
      {} as Record<string, number>
    );

    return filterCountByField;
  };
}

export function useFormikFilterCountByField({ names }: { names: string[] }) {
  const getFilterCountByField = useGetFormikFilterCountByField();
  return getFilterCountByField({ names });
}

const normalizeArrayElement = (val: unknown) =>
  match(val)
    .when(
      (x): x is Date => x instanceof Date,
      x => x.getTime()
    )
    .otherwise(x => x);

export function getFilterCount<Value>(value: Value, defaultValue?: Value): number {
  if (Array.isArray(value)) {
    const normalizedValue = value.map(normalizeArrayElement);
    const normalizedDefaultValue = ((Array.isArray(defaultValue) ? defaultValue : []) as typeof value).map(
      normalizeArrayElement
    );

    if (normalizedDefaultValue.length > 0) {
      const [larger, smaller] =
        normalizedValue.length > normalizedDefaultValue.length
          ? [normalizedValue, normalizedDefaultValue]
          : [normalizedDefaultValue, normalizedValue];

      const diff = new Set(larger).difference(new Set(smaller));
      return diff.size;
    }

    return normalizedValue.reduce<number>((acc, valA, i) => {
      const valB = ((defaultValue ?? []) as typeof value)[i];
      if (valA == null && valB == null) return acc;
      if (!isEqual(valA, valB)) return acc + 1;
      return acc;
    }, 0);
  }

  if (typeof value === 'object' && value != null) {
    return Object.keys(value).reduce((acc, key) => {
      return acc + getFilterCount((value as Record<string, unknown>)[key], get(defaultValue, key));
    }, 0);
  }

  if (value !== defaultValue) {
    return 1;
  }
  return 0;
}

export function getFiltersCount<Value extends object, Selected extends object>(
  state: Value,
  selector: (store: Value) => Selected | undefined = store => store as unknown as Selected
) {
  const values = selector(state);

  let initialValues: Selected | undefined;
  try {
    initialValues = selector(useDefaultSearchStateStore.getState().defaultState as Value);
  } catch {
    initialValues = undefined;
  }

  if (!values) return 0;

  return (Object.entries(values) as [keyof typeof values, unknown][]).reduce((acc, [name, val]) => {
    return acc + getFilterCount(val, initialValues?.[name]);
  }, 0);
}

export function getNonDefaultFilters<Value extends object, Selected extends object>(
  state: Value,
  selector: (store: Value) => Selected | undefined = store => store as unknown as Selected
) {
  const values = selector(state);

  let initialValues: Selected | undefined;
  try {
    initialValues = selector(useDefaultSearchStateStore.getState().defaultState as Value);
  } catch {
    initialValues = undefined;
  }

  if (!values) return [];

  return (Object.entries(values) as [keyof typeof values, unknown][]).filter(([name, val]) => {
    return getFilterCount(val, initialValues?.[name]) > 0;
  }, 0);
}
