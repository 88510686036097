import React from 'react';
import { Outlet } from 'react-router-dom';

import { useGetPartnershipRequestsIndexQuery } from 'api/partnershipRequestApi';
import { Loading } from 'app/atoms/Loading/Loading';

export const PartnershipRequests = () => {
  const { isLoading } = useGetPartnershipRequestsIndexQuery({});

  if (isLoading) return <Loading />;

  return (
    <>
      <Outlet />
    </>
  );
};
