import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { AnchorButton, Callout, Menu, MenuItem, InputGroup, Popover, Classes } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import truncate from 'lodash-es/truncate';
import keyBy from 'lodash-es/keyBy';

import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { Tabs } from 'app/molecules/Tabs/Tabs';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { getStateFromSavedSearch } from 'app/hooks/search/useOppSearchCache';
import { useSetSavedSearchIdParam } from 'app/hooks/useSetSavedSearchIdParam';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { match, P } from 'ts-pattern';

export const OppSearchSavedSearchSelectInput = () => {
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();
  const { isMobile } = useDeviceWidth();
  const currentUserId = useCurrentUserAttribute('id');
  const selected = values.meta.savedSearchId;
  const [query, setQuery] = useState<string>('');
  const [savedSearchType, setSavedSearchType] = useState<'following' | 'team' | 'suggested'>('following');

  const setSavedSearchIdParam = useSetSavedSearchIdParam();

  const suggestedSearches = [
    {
      name: 'My Active Opportunities',
      key: 'myActiveOpportunities',
      id: null,
      query: {
        status: ['open'],
        createdBy: currentUserId
      }
    }
  ];

  const { data = [], isLoading } = useGetOppSearchesQuery({ active: true });

  let items = data;

  if (savedSearchType === 'following') {
    items = items.filter(item => item.currentUserFollowing);
  }

  const results = useFuzzySearch({
    options: { keys: ['name', 'follows.organizationUser.email', 'follows.organizationUser.name'] },
    data: items,
    query
  });

  const itemValueMap = useMemo(() => keyBy(data, 'id'), [data]);
  const selectedSearch = selected ? itemValueMap[selected] : undefined;

  const tabs = [
    {
      text: 'My Searches',
      key: 'following',
      tag: 'a',
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setSavedSearchType('following');
      }
    },
    {
      text: 'Team Searches',
      key: 'team',
      tag: 'a',
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setSavedSearchType('team');
      }
    }
  ];

  if (!isMobile) {
    tabs.push({
      text: 'Suggested',
      key: 'suggested',
      tag: 'a',
      onClick: e => {
        e.preventDefault();
        setSavedSearchType('suggested');
      }
    });
  }

  const content = (
    <Menu
      large
      className={cn('max-h-96 max-w-md space-y-4 overflow-y-auto px-4', {
        'py-4': results.length === 0,
        'pt-4 pb-2': results.length > 0
      })}
    >
      <Tabs tabs={tabs} currentTab={savedSearchType} navClassName="justify-between" data-test="saved-search-tabs" />

      {match({ itemsLength: items.length, savedSearchType })
        .with({ itemsLength: 0, savedSearchType: 'team' }, () => (
          <Callout className="mt-4" intent="primary">
            Your team has not shared any saved searches.
          </Callout>
        ))
        .with({ itemsLength: 0, savedSearchType: 'following' }, () => (
          <Callout className="mt-4" intent="danger">
            You are not following any saved searches.
          </Callout>
        ))
        .with({ itemsLength: P.number.gte(3), savedSearchType: P.not('suggested') }, () => (
          <InputGroup
            autoFocus
            name="searchFilter"
            data-test="search-filter-input"
            placeholder="Filter saved searches"
            onChange={e => setQuery(e.target.value)}
          />
        ))
        .otherwise(() => null)}

      <div className="divide-y">
        {match({ savedSearchType })
          .with({ savedSearchType: 'suggested' }, () => {
            return suggestedSearches.map(suggestion => (
              <MenuItem
                key={suggestion.key}
                data-test="saved-search-item"
                onClick={() => {
                  const savedSearchState = getStateFromSavedSearch({
                    savedSearch: suggestion,
                    meta: { ...values.meta }
                  });
                  setValues(savedSearchState);
                  submitForm();
                }}
                text={
                  <div className="flex items-center justify-between">
                    <span className="flex-1">{truncate(suggestion.name, { length: isMobile ? 20 : 36 })}</span>
                  </div>
                }
                className={Classes.POPOVER_DISMISS}
              />
            ));
          })
          .otherwise(() => {
            return results.map(savedSearch => (
              <MenuItem
                key={savedSearch.id}
                data-test="saved-search-item"
                onClick={() => {
                  const savedSearchState = getStateFromSavedSearch({
                    savedSearch,
                    meta: { ...values.meta, savedSearchId: savedSearch.id, savedSearchUpdatedAt: Date.now() }
                  });

                  setValues(savedSearchState);
                  setSavedSearchIdParam(savedSearch.id);
                  submitForm();
                }}
                text={
                  <div className="flex items-center justify-between">
                    <span className="flex-1">{truncate(savedSearch.name, { length: isMobile ? 20 : 36 })}</span>
                    <AvatarList
                      limit={2}
                      avatarProps={{
                        size: 'sm'
                      }}
                      avatarData={
                        savedSearch.follows?.map(
                          ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {} }) => ({
                            initials,
                            name,
                            organizationName,
                            avatar,
                            avatarColor
                          })
                        ) ?? []
                      }
                    />
                  </div>
                }
                active={!!savedSearch.id && selected === savedSearch.id}
                className={Classes.POPOVER_DISMISS}
              />
            ));
          })}
      </div>
    </Menu>
  );

  return (
    <Popover interactionKind="click" placement="bottom" fill content={content} className="mb-2 sm:w-full sm:mb-0">
      <AnchorButton
        rightIcon="chevron-down"
        data-test="saved-search-dropdown"
        text={truncate(selectedSearch?.name, { length: 30 }) || 'Saved Searches'}
        className="inline-flex w-full justify-between truncate sm:max-w-72"
        fill
        large
        disabled={isLoading}
      />
    </Popover>
  );
};
