import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Tooltip, Intent, Tag, Callout } from '@blueprintjs/core';

import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { NumericInput } from 'app/atoms/inputs/NumericInput/NumericInput';
import { CheckboxGroupInput } from 'app/atoms/inputs/CheckboxGroupInput/CheckboxGroupInput';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import {
  useUpdateFeedProfileMutation,
  useCreateFeedProfileMutation,
  useCallFeedProfileMutation
} from 'api/feedProfilesApi';
import { formErrorToast, errorToast, successToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { hasDataErrors, isFetchBaseQueryError } from 'api/utils';
import { hasId } from 'api/utils';

const noBidReasons = {
  unavailable: 'No Bid, Expertise/Product Unavailable',
  unclear: 'No Bid, Insufficient/Unclear information provided',
  insufficientTime: 'No Bid, Insufficient Time to Respond',
  conflict: 'No Bid, Organizational Conflict of Interest',
  outOfScope: 'No Bid, Out of Scope',
  notAuthorized: 'No Bid, Not an authorized reseller'
};

const CONTRACTS = [
  { value: 'ites-3h', label: 'ITES-3H' },
  { value: 'ites-3s', label: 'ITES-3S' },
  { value: 'ites-sw2', label: 'ITES-SW2' },
  { value: 'admc3', label: 'ADMC3' }
];

type FeedProfileChessContext = {
  accountRecoveryLink?: string;
  noBidActor?: boolean;
  parseActor?: boolean;
  acknowledgeActor?: boolean;
  resetPasswordActor?: boolean;
  noBidFrequency?: number;
  noBidLogic?: {
    default?: string;
    unavailable?: string;
    unclear?: string;
    insufficientTime?: number;
    conflict?: string;
    outOfScope?: string;
    notAuthorized?: string;
  };
  contractSlugs?: string[];
  contracts?: {
    ites3h?: string;
    ites3s?: string;
    itesSw2?: string;
    admc3?: string;
  };
};

export const FeedProfileChessForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const authorized = useAuthorized({ role: 'admin' });
  const sysAdmin = useAuthorized({ role: 'sys_admin' });

  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();
  const [updateFeedProfile, { isLoading: isUpdating }] = useUpdateFeedProfileMutation();
  const [createFeedProfile, { isLoading: isCreating }] = useCreateFeedProfileMutation();
  const [callFeedProfileAction, { isLoading: isCalling }] = useCallFeedProfileMutation();

  const callAction = async (values: {
    id: string;
    profileAction: 'parse' | 'no_bid' | 'acknowledge' | 'update_password';
  }) => {
    try {
      await callFeedProfileAction(values).unwrap();
      successToast('Action called. Check status in Apify.');
    } catch (e) {
      if (isFetchBaseQueryError(e) && typeof e.status == 'number') {
        formErrorToast(e.status);
      } else {
        errorToast(e);
      }
    }
  };

  if (profileLoading) {
    return <Loading />;
  }

  const { feedProfiles: profiles } = currentOrg || {};
  const profile = profiles?.find(p => p.type === 'FeedProfile::Chess');

  const { id, username, password, notificationEmail, active, failedLoginAttempts = 0, context } = profile ?? {};
  const {
    accountRecoveryLink,
    parseActor,
    noBidActor,
    acknowledgeActor,
    resetPasswordActor,
    noBidFrequency,
    noBidLogic = {},
    contractSlugs,
    contracts
  } = (context as FeedProfileChessContext) ?? {};
  const { ites3h, ites3s, itesSw2, admc3 } = contracts ?? {};

  const defaultNoBidReasonOptions = [
    { value: 'No Bid, Other', label: 'No Bid, Other' },
    ...Object.values(noBidReasons).map(key => ({ value: key, label: key }))
  ];

  let failedLoginAttemptsClass = failedLoginAttempts === 0 ? 'text-green-600' : 'text-red-600';
  if (failedLoginAttempts > 1) failedLoginAttemptsClass = 'text-red-600';

  const isSubmitting = isUpdating || isCreating;

  const initialValues = {
    id,
    type: 'FeedProfile::Chess',
    username: username || '',
    password: password || '',
    notificationEmail: notificationEmail || '',
    active: !!active,
    context: {
      noBidActor: !!noBidActor,
      parseActor: !!parseActor,
      acknowledgeActor: !!acknowledgeActor,
      resetPasswordActor: !!resetPasswordActor,
      noBidFrequency: noBidFrequency || 6,
      noBidLogic: {
        default: noBidLogic.default || 'No Bid, Other',
        unavailable: noBidLogic.unavailable || '',
        unclear: noBidLogic.unclear || '',
        insufficientTime: noBidLogic.insufficientTime || 24,
        conflict: noBidLogic.conflict || '',
        outOfScope: noBidLogic.outOfScope || '',
        notAuthorized: noBidLogic.notAuthorized || ''
      },
      contractSlugs: contractSlugs || [],
      contracts: {
        ites_3h: ites3h || '',
        ites_3s: ites3s || '',
        ites_sw2: itesSw2 || '',
        admc3: admc3 || ''
      }
    }
  };

  const validationSchema = yup.object({
    username: yup.string().required('Username is required').email('Username should be a valid email address'),
    password: yup.string().required('A Password is required to run any job'),
    context: yup.object({
      contractSlugs: yup.array().min(1, 'At least one contract is required'),
      contracts: yup.object().when('contractSlugs', {
        is: (contractSlugs: string[]) => contractSlugs.length > 1,
        then: () =>
          yup
            .object()
            .test(
              'contractNumbers',
              'Contract numbers from Chess portal are required when you have more than one contract.',
              item => {
                return Object.values(item).filter(Boolean).length > 1;
              }
            )
      })
    })
  });

  const inactive = failedLoginAttempts > 1 || !active;

  return (
    <>
      <Breadcrumbs />
      <Formik
        onSubmit={async (values, { setErrors }) => {
          try {
            if (hasId(values)) {
              await updateFeedProfile(values).unwrap();
              successToast('Profile updated.');
            } else {
              await createFeedProfile(values).unwrap();
              successToast('Profile created.');
            }
          } catch (e) {
            if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
              if (typeof e.status === 'number') formErrorToast(e.status);
              const { data } = e;
              if (data.errors) setErrors(data.errors);
            }
          }
        }}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ values, errors }) => (
          <Form>
            <Card
              title="Chess Automate Profile"
              rightElement={
                <div className="flex items-center">
                  <Tag intent={inactive ? 'danger' : 'success'}>{inactive ? 'Disabled' : 'Active'}</Tag>
                  {accountRecoveryLink && (
                    <a href={accountRecoveryLink} target="_blank" rel="noreferrer" className="ml-2">
                      <Tag icon="link" intent="danger" interactive>
                        Recover Account
                      </Tag>
                    </a>
                  )}
                </div>
              }
            >
              <CardBody>
                <SwitchInput name="active" label="Active?" />
                <TextInput label="Chess Username" name="username" labelInfo="(required)" />
                <TextInput
                  label="Chess Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  labelInfo="(required)"
                  helperText={
                    <>
                      <p className={failedLoginAttemptsClass}>{`Failed login attempts: ${failedLoginAttempts}`}</p>
                      <span>
                        If failed login attempts exceeds 1, all jobs will be paused until the password is updated.
                      </span>
                    </>
                  }
                  inputProps={{
                    large: true,
                    rightElement: (
                      <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
                        <Button
                          icon={showPassword ? 'unlock' : 'lock'}
                          intent={Intent.WARNING}
                          minimal
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </Tooltip>
                    )
                  }}
                />
                <CheckboxGroupInput
                  inline
                  hasCount={false}
                  hasClear={false}
                  label="Contracts"
                  labelInfo="(required)"
                  name="context.contractSlugs"
                  items={CONTRACTS}
                />
                {values.context.contractSlugs.length > 1 && (
                  <>
                    {errors.context?.contracts && (
                      <Callout intent={Intent.DANGER}>{JSON.stringify(errors.context.contracts)}</Callout>
                    )}
                    {values.context.contractSlugs.map(contract => (
                      <TextInput
                        key={contract}
                        label={`${contract.toUpperCase()} Contract ID`}
                        name={`context.contracts.${contract.replace('-', '_')}`}
                        helperText="The Contract ID is an identifier that can be found in the Chess Portal and is different from the Contract Number."
                      />
                    ))}
                  </>
                )}
                <SwitchInput
                  name="context.resetPasswordActor"
                  label="Auto manage password?"
                  helperText="We can automate the resetting of the Chess profile password. It updates the password once per week. Do not use this job unless this account is using a dedicated profile for Govly."
                />
                <SwitchInput
                  name="context.parseActor"
                  label="Fetch attachments from Chess?"
                  helperText="We can fetch attachments from Chess. This action will happen once every hour for any new opportunities."
                />
                <SwitchInput name="context.acknowledgeActor" label="Automate acknowledgements?" />
                <SwitchInput name="context.noBidActor" label="Automate no bids?" />
                {values.context.noBidActor && (
                  <>
                    <p>
                      <strong>No Bid Logic</strong>
                    </p>
                    <SelectInput
                      filterActive={false}
                      label="Default No Bid Reason"
                      name="context.noBidLogic.default"
                      items={defaultNoBidReasonOptions}
                    />
                    <NumericInput
                      name="context.noBidLogic.insufficientTime"
                      helperText="Specify the number of hours between when the opportunity opened and when it will close you consider to be insufficient time. Note that the opened time will always be the beginning of the day (00:00), while the closing time will be the exact time of closing. Set to 0 to skip this type of no bid."
                      label="No Bid, Insufficient Time to Respond"
                    />
                    <div className="bp5-form-helper-text">
                      <p>No bid logic for each type of no bid below will match words or phrases separate by |.</p>
                      <p>Example logic: Cisco | Palo Alto | Printers</p>
                    </div>
                    {(Object.keys(noBidReasons) as Array<keyof typeof noBidReasons>).map(key => {
                      if (key === 'insufficientTime') {
                        return null;
                      }

                      return <TextAreaInput key={key} name={`context.noBidLogic.${key}`} label={noBidReasons[key]} />;
                    })}
                  </>
                )}
              </CardBody>

              <CardFooter>
                <Button
                  large
                  type="submit"
                  text="Save"
                  disabled={!authorized}
                  loading={isSubmitting}
                  intent="primary"
                />
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
      {sysAdmin && id && (
        <Card title="Manually Trigger Jobs" subtitle="Only system admin can see this card.">
          <CardBody>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'parse' })}
                intent="danger"
                outlined
                large
                text="Run Parse Job"
                disabled={isCalling}
              />
            </div>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'no_bid' })}
                intent="danger"
                outlined
                large
                text="Run No Bid Job"
                disabled={isCalling}
              />
            </div>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'acknowledge' })}
                intent="danger"
                outlined
                large
                text="Run Acknowledge Job"
                disabled={isCalling}
              />
            </div>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'update_password' })}
                intent="danger"
                outlined
                large
                text="Run Reset Password Job"
                disabled={isCalling}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
