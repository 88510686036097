import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { usePostHog } from 'posthog-js/react';

import { FeedShow } from 'types/__generated__/GovlyApi';
import { useLocalStorage } from 'app/hooks/useLocalStorage';
import { SurveyModal } from 'app/organisms/SurveyModal/SurveyModal';

type Props = {
  feed: FeedShow;
};
export type { Props as SearchableFeedsListFedCardShowInterestButtonProps };

const getHasShownInterestKey = ({ distinctId, feedId }: { distinctId: string; feedId: string }) =>
  `hasShownInterest:${distinctId}:${feedId}`;

export const SearchableFeedsListFedCardShowInterestButton = ({ feed }: Props) => {
  const posthog = usePostHog();
  const key = getHasShownInterestKey({
    distinctId: posthog.get_distinct_id?.(),
    feedId: feed.id
  });
  const [hasShownInterest, setHasShownInterest] = useLocalStorage(key, false);

  return (
    <SearchableFeedsListFedCardShowInterestButtonUI
      hasShownInterest={hasShownInterest}
      feed={feed}
      afterSubmit={() => setHasShownInterest(true)}
    />
  );
};

type UIProps = {
  hasShownInterest?: boolean;
  feed: FeedShow;
  afterSubmit?: () => void;
};
export type { UIProps as SearchableFeedsListFedCardShowInterestButtonUIProps };

export const SearchableFeedsListFedCardShowInterestButtonUI = ({ hasShownInterest, feed, afterSubmit }: UIProps) => {
  const [isSurveyOpen, setSurveyOpen] = React.useState(false);

  return (
    <>
      <Tooltip disabled={!hasShownInterest} content="You have shown interest in this contract">
        <Button onClick={() => setSurveyOpen(true)} icon="hand" disabled={hasShownInterest}>
          Show Interest
        </Button>
      </Tooltip>
      {isSurveyOpen && (
        <SurveyModal
          type="contract-vehicle-interest"
          afterSubmit={afterSubmit}
          afterClose={() => setSurveyOpen(false)}
          surveyResponsePrefix={feed.name}
        />
      )}
    </>
  );
};
