import React from 'react';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { ErrorBoundaryTest } from 'app/organisms/SysAdminSettings/ErrorBoundaryTest';

export const SysAdminErrorHandlingPage = () => {
  return (
    <Card>
      <CardBody className="flex flex-col items-start">
        <ErrorBoundaryTest />
      </CardBody>
    </Card>
  );
};
