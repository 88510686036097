import { mkConfig, generateCsv, download } from 'export-to-csv';
import { AccessorKeyColumnDef, Table } from '@tanstack/react-table';

import { formatTime } from 'app/lib/dates';

const isIsoString = (value: unknown): value is string => {
  return typeof value == 'string' && /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z/.test(value);
};

function isObject(value: unknown): value is object {
  return typeof value === 'object' && value !== null;
}

export const santizedFilename = (filename?: string) => {
  if (!filename) {
    return `govly_export_${new Date().toISOString().split('T')[0].replace(/-/g, '_')}`;
  }

  const sanitized = filename
    .replace(/\.csv$/, '')
    .replace(/[ -.]/g, '_')
    .toLowerCase();

  return filename.startsWith('govly') ? sanitized : `govly_${sanitized}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string, any>;

export const createExportData = <T>(table: Table<T>) => {
  const columns = table.getAllColumns();
  const rows = table.getCoreRowModel().rows;

  const exportData = rows.map(row => {
    const obj: AnyRecord = {};

    columns.forEach(column => {
      const { header: key, accessorKey } = column.columnDef as AccessorKeyColumnDef<T>;

      if (!key || !accessorKey || typeof accessorKey !== 'string' || typeof key !== 'string') {
        return;
      }

      const value = row.getValue(column.id);

      if (isIsoString(value)) {
        obj[key] = formatTime(value);
        return;
      }

      if (Array.isArray(value) && accessorKey === 'followers') {
        obj[key] = value
          .map((f: unknown) => {
            if (isObject(f) && 'organizationUser' in f) {
              if (isObject(f.organizationUser) && 'name' in f.organizationUser) {
                return f.organizationUser?.name;
              }
            }
          })
          .filter(Boolean)
          .join(', ');
        return;
      }

      if (Array.isArray(value)) {
        obj[key] = value.join(', ');
        return;
      }

      obj[key] = value;
    });
    return obj;
  });

  return exportData;
};

export const govlyTableCSVExport = function <T>(table: Table<T>, filename?: string) {
  const exportData = createExportData(table);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: santizedFilename(filename)
  });
  const csv = generateCsv(csvConfig)(exportData);
  download(csvConfig)(csv);
};
