import React, { useEffect } from 'react';
import { DEFAULT_LOGO_IMAGE } from 'app/lib/marketingSiteImages';

const defaultMetaTags = {
  title: 'Govly',
  description: 'Govly is the #1 Market Network for Government Contractors.',
  'og:title': 'Govly',
  'og:description': 'Govly is the #1 Market Network for Government Contractors.',
  'og:site_name': 'Govly',
  'og:type': 'website',
  'og:url': 'https://app.govly.com',
  'og:image': DEFAULT_LOGO_IMAGE
};

type useMetaTagsArgs = {
  title?: string;
} & {
  [key: string]: string;
};

export const useMetaTags = ({ title, ...rest }: useMetaTagsArgs, dependencies: React.DependencyList = []) => {
  useEffect(() => {
    document.title = title || document.title;

    for (const [key, value] of Object.entries(rest)) {
      document.head.querySelector(`meta[name="${key}"]`)?.setAttribute('content', value);
      document.head.querySelector(`meta[property="${key}"]`)?.setAttribute('content', value);
    }

    return () => {
      document.title = 'Govly';

      for (const [key, value] of Object.entries(defaultMetaTags)) {
        document.head.querySelector(`meta[name="${key}"]`)?.setAttribute('content', value);
        document.head.querySelector(`meta[property="${key}"]`)?.setAttribute('content', value);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
