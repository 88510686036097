import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

export const GovernmentEntitiesNotFound = ({ search }: { search: string | null }) => {
  const description = search ? (
    <span>
      Looks like you&apos;re interested in information about &quot;{search}&quot;. We don&apos;t have their summary or
      budget data yet, but we&apos;ll see if we can add it. In the meantime, use the filters on{' '}
      <LinkTag to="/opportunities">Opportunities</LinkTag> and <LinkTag to="/awards">Awards</LinkTag> to get insights on
      &quot;{search}&quot; activity.
    </span>
  ) : (
    <span>
      We&apos;ll see if we can add it, so please check back later! In the meantime, use the filters on{' '}
      <LinkTag to="/opportunities">Opportunities</LinkTag> and <LinkTag to="/awards">Awards</LinkTag> to get insights on
      agency activity.
    </span>
  );

  return <NonIdealState title="Oops! We don't have that information yet." description={description} icon="search" />;
};
