import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useOppWorkspaceStore } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const ToolboxDialog = () => {
  const toolboxDialogProps = useOppWorkspaceStore(s => s.toolboxDialogProps);
  const setToolboxDialogProps = useOppWorkspaceStore(s => s.setToolboxDialogProps);

  return (
    <Dialog
      onClose={() => setToolboxDialogProps({ isOpen: false })}
      lazy
      isCloseButtonShown={true}
      {...toolboxDialogProps}
    />
  );
};
