import { RecordReseller } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Reseller'] });

type GetResellers = {
  params: {
    vendorId: string;
  };
  result: RecordReseller[];
};

type CreateRecordReseller = {
  params: {
    vendorId: string;
  } & Pick<RecordReseller, 'resellerableId' | 'resellerableType' | 'sortOrder' | 'tier'>;
  result: RecordReseller;
};

type UpdateRecordReseller = {
  params: Pick<RecordReseller, 'id' | 'resellerableId' | 'tier' | 'sortOrder'>;
  result: RecordReseller;
};

type DeleteRecordReseller = {
  params: Pick<RecordReseller, 'id' | 'resellerableId'>;
  result: void;
};

export const resellersApi = api.injectEndpoints({
  endpoints: build => ({
    getResellers: build.query<GetResellers['result'], GetResellers['params']>({
      query: params => ({
        url: '/v2/resellers',
        params
      }),
      providesTags: (result, error, arg) => ['Reseller', { type: 'Reseller', id: `VENDOR:${arg.vendorId}` }]
    }),
    createRecordReseller: build.mutation<CreateRecordReseller['result'], CreateRecordReseller['params']>({
      query: body => ({
        url: `/v2/resellers`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Reseller', id: `VENDOR:${arg.resellerableId}` }]
    }),
    updateRecordReseller: build.mutation<UpdateRecordReseller['result'], UpdateRecordReseller['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/resellers/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Reseller', id: `VENDOR:${arg.resellerableId}` }]
    }),
    deleteRecordReseller: build.mutation<DeleteRecordReseller['result'], DeleteRecordReseller['params']>({
      query: ({ id }) => ({
        url: `/v2/resellers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Reseller', id: `VENDOR:${arg.resellerableId}` }]
    })
  })
});

export const {
  useGetResellersQuery,
  useCreateRecordResellerMutation,
  useDeleteRecordResellerMutation,
  useUpdateRecordResellerMutation
} = resellersApi;
