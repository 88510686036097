import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { ContactSearch, ContactJobTitle } from 'types/__generated__/GovlyApi';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

type ContactTitlesProps = {
  contact: ContactSearch;
};

const columnHelper = createColumnHelper<ContactJobTitle>();

export const ContactTitles = ({ contact }: ContactTitlesProps) => {
  const titles = contact.contactJobTitles ?? [];

  const columns = useMemo(
    () => [
      columnHelper.accessor('raw', {
        header: 'Title',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => <span className="text-xs">{e.row.original.raw}</span>
      }),
      columnHelper.accessor('lastActive', {
        header: 'Last Active',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => (
          <span className="text-xs">
            {e.row.original.lastActive ? formatTime(e.row.original.lastActive, DATETIME_24_SHORT) : undefined}
          </span>
        )
      })
    ],
    []
  );

  if (titles.length < 2) return null;

  return (
    <GovlyTable
      id="contact_titles"
      columns={columns}
      data={titles}
      title="Job Titles"
      initialState={{
        sorting: [{ id: 'lastActive', desc: true }],
        pagination: {
          pageSize: 5
        }
      }}
    />
  );
};
