import React from 'react';

import { Card, CardProps, CardSection } from 'app/atoms/Card/Card';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';

export type QuestionsAndAnswersProps = {
  questions: { question?: string; answer?: string }[];
  cardProps?: CardProps;
};

export const QuestionsAndAnswers = ({ questions, cardProps }: QuestionsAndAnswersProps) => {
  if (!questions.length) {
    return null;
  }

  return (
    <Card
      title="Questions & Answers"
      collapsible
      titleRenderer={CardHeadingSmall}
      collapseProps={{ defaultIsOpen: false, ...cardProps?.collapseProps }}
      icon="help"
      {...cardProps}
    >
      <CardSection>
        <div className="grid gap-y-2">
          {questions.map(q => (
            <DescriptionListField key={q.question} label={q.question} value={q.answer} />
          ))}
        </div>
      </CardSection>
    </Card>
  );
};
