import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { useAwardSearchMutation } from 'api/awardsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';

import { AwardSearchAnalyticsHistogram } from './AwardSearchAnalyticsHistogram';
import { AwardSearchAnalyticsMap } from './AwardSearchAnalyticsMap';
import { AwardSearchAnalyticsEntities } from './AwardSearchAnalyticsEntities';

export const AwardSearchAnalytics = () => {
  const [_search, { data: { results = [] } = {}, isLoading, isUninitialized, isSuccess }] = useAwardSearchMutation({
    fixedCacheKey: 'award-search'
  });

  if (isUninitialized) {
    return <Loading type="card-list" />;
  }

  if (isSuccess && !results.length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="search"
            title="No search results"
            description={
              <div>
                {`Your search didn't match any opportunities.`}
                <br />
                {`Try adjusting your search filters.`}
              </div>
            }
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <AwardSearchAnalyticsHistogram searchIsLoading={isLoading} />
      <AwardSearchAnalyticsEntities searchIsLoading={isLoading} />
      <AwardSearchAnalyticsMap searchIsLoading={isLoading} />
    </>
  );
};
