import React, { useEffect, useRef } from 'react';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useAssistantContext } from './Assistant';
import { AssistantMessage } from './AssistantMessage';
import { UserMessage } from './UserMessage';

const AssistantMessageList = () => {
  const { data: user, isLoading } = useGetCurrentUserQuery();
  const { messages, welcomeMessage } = useAssistantContext();
  const messageField = useRef<HTMLDivElement>(null);

  // Ensure that as new messages are added, the messageField scrolls to the bottom
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageField.current) {
      messageField.current.scrollTop = messageField.current.scrollHeight;
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col h-full pb-4 pt-9 overflow-y-scroll" ref={messageField}>
      <div className="m-4 flex flex-col">
        {welcomeMessage && <AssistantMessage content={welcomeMessage} isPartial={false} />}
        {messages.map(({ content, role, metadata, isPartial, annotations }, index) => {
          if (metadata?.internal !== 'true') {
            return role === 'assistant' ? (
              <AssistantMessage key={index} content={content || ''} isPartial={isPartial} annotations={annotations} />
            ) : (
              <UserMessage key={index} content={content || ''} initials={user?.initials} />
            );
          }
        })}
      </div>
    </div>
  );
};

export { AssistantMessageList };
