import React from 'react';
import { Formik, Form } from 'formik';
import { Button, FormGroup } from '@blueprintjs/core';

import { formatTime } from 'app/lib/dates';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { SouptoolsQuoteFull, SouptoolsTrFull } from 'types/__generated__/GovlyApi';

type SouptoolsSubmitClinCheckProps = {
  clinable: SouptoolsQuoteFull | SouptoolsTrFull;
  onSubmit: () => void;
  isSubmitting?: boolean;
};

export const SouptoolsSubmitClinCheck = ({ onSubmit, isSubmitting, clinable }: SouptoolsSubmitClinCheckProps) => {
  const { data: currentOrg } = useGetCurrentOrganizationQuery();
  const { feedProfiles: profiles } = currentOrg || {};
  const profile = profiles?.find(p => p.type === 'FeedProfile::SewpV');
  const { clinsCount, clinCheckListStatus, clinsCheckedAt, clinCheckScreenshot } = clinable;

  if (!profile) return null;

  const clinCheckInProgress = isSubmitting || clinCheckListStatus === 'checking';

  let helperText;

  if (clinCheckListStatus === 'unchecked' && !isSubmitting) {
    helperText = 'Unchecked since last save.';
  }

  if (clinCheckListStatus === 'checked' && clinsCheckedAt) {
    helperText = `Check completed on ${formatTime(clinsCheckedAt)}`;
  }

  if (clinCheckListStatus === 'failed') {
    helperText = 'Check failed. Please try again.';
  }

  const helperDiv = (
    <div>
      <span className="block">{helperText}</span>
      {clinCheckScreenshot && (
        <a href={clinCheckScreenshot.link} target="_blank" rel="noreferrer">
          View Screenshot
        </a>
      )}
    </div>
  );

  return (
    // @ts-expect-error TODO I don't know what this is, but am too scared to remove it
    <Formik>
      <Form>
        <FormGroup className="mb-0" helperText={helperDiv}>
          <Button
            disabled={!clinsCount || clinCheckInProgress}
            className="mr-2"
            text={clinCheckInProgress ? 'Running CLIN Check File...' : 'Run CLIN Check File'}
            intent="success"
            onClick={onSubmit}
          />
        </FormGroup>
      </Form>
    </Formik>
  );
};
