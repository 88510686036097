import React from 'react';
import { Popover, SegmentedControl, Button } from '@blueprintjs/core';
import { useFormikContext } from 'formik';

import { FormikToBp } from 'app/lib/formikToBp';
import { useGetFormikField } from 'app/hooks/useGetFormikField';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { useActivateSLED } from 'app/organisms/SearchableFeeds/SearchableFeedsListSLED/useActivateSLED';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { supportChatMessage } from 'app/lib/support-chat';

export const OppSearchTypeFilter = () => {
  const getField = useGetFormikField<OppSearchState>();
  const { values, setValues, submitForm } = useFormikContext<OppSearchState>();

  const { data: organization, isLoading: organizationLoading } = useGetCurrentOrganizationQuery();
  const { handleChange, isUpdating } = useActivateSLED();
  const hasSled = organization?.subscriptionHasSLED;
  const sledActivated = organization?.subscriptionHasSLEDActivated;
  const sledEnabled = sledActivated && hasSled;

  const sledTooltip = (
    <div className="prose prose-sm p-4">
      {!hasSled ? (
        <span className="block text-xs text-gray-500">
          SLED is now on Govly!{' '}
          <LinkTag
            tag="button"
            onClick={() => supportChatMessage('Hi, I would like to see SLED opportunities in Govly.')}
          >
            Request Access
          </LinkTag>
        </span>
      ) : !sledActivated ? (
        <span className="text-xs text-gray-500 flex items-center gap-1">
          <span>Your SLED feed is not activated.</span>
          <Button small intent="primary" outlined onClick={() => handleChange(organization?.id)} loading={isUpdating}>
            Activate
          </Button>
        </span>
      ) : undefined}
    </div>
  );

  return (
    <Popover
      content={sledTooltip}
      disabled={sledEnabled || organizationLoading}
      className="w-full"
      interactionKind="hover"
      placement="top"
    >
      <SegmentedControl
        {...FormikToBp.toSegmentedControl(getField('filters.searchType'))}
        fill
        intent="primary"
        onValueChange={value => {
          setValues({
            ...values,
            filters: {
              ...values.filters,
              searchType: value as OppSearchState['filters']['searchType']
            }
          });
          submitForm();
        }}
        options={[
          { value: 'fed', label: 'Federal' },
          { value: 'sled', label: 'State & Local', disabled: !sledEnabled }
        ]}
      />
    </Popover>
  );
};
