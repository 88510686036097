import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import { HighchartsReact } from 'highcharts-react-official';
import { NonIdealState, Button } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { merge } from 'highcharts';

const defaultOptions: Highcharts.Options = {
  chart: {
    type: 'bar'
  },
  title: {
    text: undefined
  },
  drilldown: {
    breadcrumbs: {
      showFullPath: true,
      format: '{level.name}'
    }
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  xAxis: {
    type: 'category'
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:,.0f}'
      }
    }
  }
};

type BarGraphWithDrilldownProps = {
  title: string;
  subtitle?: string;
  options: Highcharts.Options;
  csv?: string;
};

export const BarGraphWithDrilldown = ({ title, subtitle, options, csv }: BarGraphWithDrilldownProps) => {
  const chartOptions: Highcharts.Options = useMemo(() => {
    return merge(defaultOptions, options);
  }, [options]);

  const rightElement = csv ? (
    <CSVLink data={csv} filename={`govly-${title.toLowerCase()}-results.csv`} className="text-gray-400 no-underline">
      <Button icon="download">Download Data</Button>
    </CSVLink>
  ) : undefined;

  return (
    <Card title={title} subtitle={subtitle} rightElement={rightElement}>
      <CardSection>
        {chartOptions?.series?.length ? (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        ) : (
          <NonIdealState
            icon="horizontal-bar-chart-desc"
            title="Not enough data."
            description="Try broadening your search."
          />
        )}
      </CardSection>
    </Card>
  );
};
