import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Drawer } from '@blueprintjs/core';
import { match } from 'ts-pattern';

import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { OppDrawerBody, showOppDrawer } from 'app/organisms/OppDrawer/OppDrawer';
import { OppWorkspaceDrawerBody, showWorkspaceDrawer } from 'app/organisms/OppWorkspaceDrawer/OppWorkspaceDrawer';
import { ContactDrawerTitle, ContactDrawerBody, showContactDrawer } from 'app/organisms/ContactDrawer/ContactDrawer';
import {
  SearchableFeedsFeedDrawerBody,
  SearchableFeedsFeedDrawerTitle,
  showSearchableFeedsFeedDrawer
} from 'app/organisms/SearchableFeeds/SearchableFeedsFeedDrawer';
import {
  ManagedFeedDrawerBody,
  ManagedFeedDrawerTitle,
  showManagedFeedDrawer
} from 'app/organisms/ManagedFeedDrawer/ManagedFeedDrawer';
import { AwardDrawerBody, showAwardDrawer } from 'app/organisms/AwardDrawer/AwardDrawer';
import {
  GovernmentEntityDrawerBody,
  GovernmentEntityDrawerTitle,
  showGovernmentEntityDrawer
} from 'app/organisms/GovernmentEntityDrawer/GovernmentEntityDrawer';
import { AppDrawerNav } from 'app/organisms/AppDrawer/AppDrawerNav';
import { cn } from 'app/lib/cn';
import { DRAWER_PARAMS, DrawerType } from 'app/organisms/AppDrawer/constants';

const getDrawer = ({ drawerType, drawerId }: { drawerType?: DrawerType | null; drawerId?: string | null }) => {
  if (!drawerType || !drawerId) return [];

  return match({ drawerType, drawerId })
    .when(showOppDrawer, () => [undefined, OppDrawerBody])
    .when(showWorkspaceDrawer, () => [undefined, OppWorkspaceDrawerBody])
    .when(showContactDrawer, () => [ContactDrawerTitle, ContactDrawerBody])
    .when(showSearchableFeedsFeedDrawer, () => [SearchableFeedsFeedDrawerTitle, SearchableFeedsFeedDrawerBody])
    .when(showManagedFeedDrawer, () => [ManagedFeedDrawerTitle, ManagedFeedDrawerBody])
    .when(showAwardDrawer, () => [undefined, AwardDrawerBody])
    .when(showGovernmentEntityDrawer, () => [GovernmentEntityDrawerTitle, GovernmentEntityDrawerBody])
    .otherwise(() => []);
};

export const AppDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useDeviceWidth();
  const drawerRef = useRef<HTMLDivElement>(null);

  const drawerType = searchParams.get(DRAWER_PARAMS.type);
  const drawerTab = searchParams.get(DRAWER_PARAMS.tab);
  const drawerId = searchParams.get(DRAWER_PARAMS.id);
  const drawerSize = searchParams.get(DRAWER_PARAMS.size) ?? '85%';

  const [Title, Body] = getDrawer({ drawerType: drawerType as DrawerType, drawerId });

  const scrollToTop = () => {
    if (drawerRef.current) {
      drawerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [drawerTab]);

  if (!Body || !drawerId || !drawerType) {
    return null;
  }

  const clearSearchParams = () => {
    Object.values(DRAWER_PARAMS).forEach(param => {
      searchParams.delete(param);
    });

    setSearchParams(searchParams);
  };

  return (
    <Drawer
      lazy
      onClose={clearSearchParams}
      isOpen={true}
      position="right"
      title={Title ? <Title id={drawerId} /> : <AppDrawerNav />}
      size={isMobile ? '100%' : drawerSize}
      className={cn('overflow-y-auto', {
        '[&_.bp5-drawer-header]:pb-0': !Title // undo padding to show tab indicator
      })}
    >
      <div ref={drawerRef} className="flex flex-col flex-grow">
        <Body id={drawerId} />
      </div>
    </Drawer>
  );
};
