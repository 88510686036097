import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';

import {
  FollowDisplayable,
  OrganizationUserDisplayable,
  OrganizationUserCurrentOrg
} from 'types/__generated__/GovlyApi';
import { H3 } from 'app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { errorToast, successToast } from 'app/lib/toaster';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { WorkspaceFormNotificationsInput } from 'app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { useUpdateProviderFeedMutation } from 'api/providerFeedsApi';

export type LimitedFollow = Pick<FollowDisplayable, 'organizationUserId' | 'state' | 'notifications'> & {
  organizationUser?: { organizationId: string; email?: string };
  id?: string;
};
type FollowsAttributes = Omit<LimitedFollow, 'organizationUser'> & { email?: string };

type FormValues = {
  id: string;
  contractName: string;
  teamFollows: LimitedFollow[];
  partnerFollows: LimitedFollow[];
  customNotifyList?: LimitedFollow[];
  notifyGroup: 'notifiables' | 'select';
  followsAttributes: FollowsAttributes[];
};

type ContractVehicleFormProps = {
  basicInputs?: boolean;
  followersInputs?: boolean;
  follows: FollowDisplayable[];
  initialValues: {
    id: string;
  };
  notifyNewFollowersOnly?: boolean;
  onCancel: () => void;
};

export const ContractVehicleForm = ({
  basicInputs = false,
  followersInputs = true,
  follows,
  initialValues,
  notifyNewFollowersOnly = true,
  onCancel
}: ContractVehicleFormProps) => {
  const { trackEvent } = useEventTracking();
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const [updateProviderFeed, { isLoading: isUpdating }] = useUpdateProviderFeedMutation();

  const { data: teamData = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const team = teamData as OrganizationUserCurrentOrg[];

  const { data: partnersData = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });
  const partners = partnersData as OrganizationUserDisplayable[];

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser: _, ...rest }) => rest),
    'organizationUserId'
  );

  const form: FormikConfig<FormValues> = {
    onSubmit: async values => {
      try {
        const { teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload } = values;

        if (followersInputs) {
          const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

          const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

          const followsAttributes = allFollowers.map(ouid => {
            const updatedFollow = updatedFollowsByUser[ouid];
            const originalFollow = followsByUser[ouid];

            if (originalFollow && !updatedFollow) {
              return {
                id: originalFollow.id,
                state: 'unfollowed' as const,
                notifications: 'muted' as const,
                organizationUserId: originalFollow.organizationUserId,
                email: undefined
              };
            }

            return {
              id: originalFollow?.id || updatedFollow.id,
              state: updatedFollow.state,
              notifications: updatedFollow.notifications,
              organizationUserId: updatedFollow.organizationUserId,
              email: updatedFollow.organizationUser?.email
            };
          });

          payload.followsAttributes = followsAttributes;
        }

        let notifyIds: string[] = [];

        if (notifyGroup === 'select' && customNotifyList) {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const data = await updateProviderFeed({
          ...payload,
          notifyIds
        }).unwrap();

        const isUpdate = !!initialValues.id;

        trackEvent({
          object: 'contract_feed',
          action: isUpdate ? 'updated' : 'created',
          properties: {
            contractVehicleId: isUpdate ? data.contractVehicle.id : data.id,
            contractName: isUpdate ? data.contractVehicle.contractName : data.name,
            notifyIds,
            follows: payload.followsAttributes?.map(f => f.email),
            teamFollows: teamFollows?.map(f => f.organizationUser?.email),
            partnerFollows: partnerFollows?.map(f => f.organizationUser?.email)
          }
        });

        successToast(isUpdate ? 'Solicitors Updated' : 'Contract Created');
        onCancel();
      } catch (e) {
        errorToast(e);
      }
    },
    initialValues: {
      ...initialValues,
      teamFollows,
      partnerFollows,
      contractName: '',
      notifyGroup: 'notifiables',
      customNotifyList: [],
      followsAttributes: []
    },
    validationSchema: yup.object({
      contractName: basicInputs ? yup.string().required('Contract Name is required.') : yup.string()
    })
  };

  const followersInputStep = (step: number) => {
    if (basicInputs) return step + 1;

    return step;
  };

  const followerText = 'able to post opportunities to this Contract Feed?';

  return (
    <Formik {...form}>
      <Form>
        <Card
          title={'Add/Remove Solicitors'}
          rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}
        >
          <CardBody>
            {basicInputs && (
              <>
                <div className="space-y-4">
                  <H3>1. What should we call this Contract Feed?</H3>
                  <TextInput name="contractName" inputProps={{ large: true }} />
                </div>
              </>
            )}
            {followersInputs && (
              <>
                <div className="space-y-4">
                  <H3>{`${followersInputStep(1)}. Which team members should be ${followerText}`}</H3>
                  <FollowerSelectInput
                    name="teamFollows"
                    defaultButtonText="Add a team member"
                    organizationUsers={team || []}
                    disabled={teamLoading}
                    buttonProps={{ loading: teamLoading }}
                    data-test="workspace-followers-input"
                  />
                </div>
                <div className="space-y-4">
                  <H3>{`${followersInputStep(2)}. Which partners should be ${followerText}`}</H3>
                  <FollowerSelectInput
                    name="partnerFollows"
                    defaultButtonText="Add a partner"
                    organizationUsers={partners || []}
                    disabled={partnersLoading}
                    buttonProps={{ loading: partnersLoading }}
                    data-test="workspace-partners-input"
                  />
                </div>
              </>
            )}

            <WorkspaceFormNotificationsInput notifyNewFollowersOnly={notifyNewFollowersOnly} />
          </CardBody>
          <CardFooter>
            <Button
              large
              type="submit"
              text={'Update Solicitors'}
              intent="primary"
              loading={isUpdating}
              disabled={teamLoading || partnersLoading || isUpdating}
              data-test="workspace-submit-button"
            />
            {onCancel && <Button text="Cancel" disabled={isUpdating} onClick={onCancel} />}
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};
