import { create } from 'zustand';

type State = { featureFlags: { [k in keyof typeof process.featureFlags]?: boolean } };

type Action = {
  setFeatureFlag: (flag: keyof typeof process.featureFlags, value: boolean) => void;
  initialize: (flags: Partial<State['featureFlags']>) => void;
};

type Store = State & Action;

export const useFeatureFlagsStore = create<Store>((set, _get) => ({
  featureFlags: {},
  setFeatureFlag(flag, value) {
    set(x => ({ ...x, featureFlags: { ...x.featureFlags, [flag]: value } }));
  },
  initialize(flags) {
    set({ featureFlags: flags });
  }
}));
