import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from 'api/governmentEntitiesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { GovernmentEntityLink, GovernmentEntityLogo } from 'app/organisms/GovernmentEntity/utils';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { GovernmentEntityShow } from 'types/__generated__/GovlyApi';

type TreeNode = Pick<GovernmentEntityShow, 'id' | 'logoUrl' | 'name'>;
const TreeNode = ({ entity }: { entity: TreeNode }) => {
  return (
    <li key={entity.id}>
      <div className="flex items-center pb-4">
        <GovernmentEntityLogo entity={entity} />
        <GovernmentEntityLink className="text-black visited:text-black hover:underline" entity={entity} />
      </div>
    </li>
  );
};

const ButtonOrDiv = ({
  expandable,
  onClick,
  children
}: {
  expandable: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return expandable ? (
    <Button minimal fill textClassName="flex items-center w-full" onClick={onClick}>
      {children}
    </Button>
  ) : (
    <div className="flex items-center p-2 w-full">{children}</div>
  );
};

const MIN_CHILDREN = 10;
type Tree = TreeNode & { children?: TreeNode[] };
const Tree = ({ entity }: { entity: Tree }) => {
  const [expanded, setExpanded] = React.useState(false);

  const expandable = !!(entity.children && entity.children.length - 1 > MIN_CHILDREN);

  const children = entity.children?.toSorted((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <ButtonOrDiv expandable={expandable} onClick={() => setExpanded(!expanded)}>
        <GovernmentEntityLogo entity={entity} />
        <span className="text-black">{entity.name}</span>
        {expandable && <Icon className="ml-auto bp5-text-muted" icon={expanded ? 'chevron-up' : 'chevron-down'} />}
      </ButtonOrDiv>

      {children && (
        <ul className="pl-5 pt-3">
          {children.slice(0, expanded ? -1 : MIN_CHILDREN).map(child => (
            <TreeNode key={child.id} entity={child} />
          ))}
        </ul>
      )}

      {expandable && (
        <Button minimal intent="primary" className="w-full" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </>
  );
};

export const Hierarchy = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity.children?.length) return null;

  return (
    <Card title={'Hierarchy'} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardSection padded={false} className="p-2">
        <Tree entity={governmentEntity} />
      </CardSection>
    </Card>
  );
};
