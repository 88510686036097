import React, { useState } from 'react';
import { Button, Dialog, Tooltip } from '@blueprintjs/core';

import { ShareViaEmailForm } from 'app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useShareWorkableMutation } from 'api/workableInteractionsApi';
import { WorkableInteraction } from 'types/__generated__/GovlyApi';

export type SearchShareInteractionProps = {
  type: 'USASpendingAward' | 'Contact';
  id: string;
  formTitle: string;
  isLoading: boolean;
  iconOnly?: boolean;
  interaction?: WorkableInteraction;
};

export const SearchShareInteraction = ({
  type,
  id,
  formTitle,
  isLoading,
  iconOnly,
  interaction
}: SearchShareInteractionProps) => {
  const entityType = type === 'USASpendingAward' ? 'Award' : type;
  const [isSharing, setIsSharing] = useState(false);
  const { isMobile } = useDeviceWidth();
  const [shareWorkable, { isLoading: isSubmittingShare }] = useShareWorkableMutation();

  const sharedTo = interaction?.sharedTo.length ? interaction?.sharedTo : [];
  return (
    <div>
      <Tooltip
        content={sharedTo.length ? `Previously shared to: ${sharedTo.join(', ')}` : ''}
        disabled={!sharedTo.length}
      >
        <Button
          intent={sharedTo.length ? 'primary' : 'none'}
          icon="send-message"
          disabled={isLoading}
          text={iconOnly || isMobile ? null : 'Share'}
          onClick={() => setIsSharing(true)}
        />
      </Tooltip>
      <Dialog isOpen={isSharing} className="min-w-[50%] p-0">
        <ShareViaEmailForm
          title={`Share ${entityType}`}
          onCancel={() => setIsSharing(false)}
          initialValues={{
            subject: formTitle,
            id
          }}
          isSubmitting={isSubmittingShare}
          onSubmit={values => shareWorkable({ ...values, type }).unwrap()}
        />
      </Dialog>
    </div>
  );
};
