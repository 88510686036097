import React from 'react';
import { type CodeToHtmlOptions } from '@llm-ui/code';
import { type LLMOutputComponent } from '@llm-ui/react';
import { parseCompleteMarkdownCodeBlock, useCodeToHtml, loadHighlighter, allLangs, allLangsAlias } from '@llm-ui/code';
import { getSingletonHighlighterCore } from 'shiki/core';
import { bundledLanguagesInfo } from 'shiki/langs';
import { bundledThemes } from 'shiki/themes';
import getWasm from 'shiki/wasm';
import parseHtml from 'html-react-parser';
import { LlmAssistantMessageBreakout } from 'types/__generated__/GovlyApi';
import { KeywordAssistantQueryStatement } from '../KeywordAssistant/KeywordAssitantQueryStatement';
import { KeywordAssistantUserQueryStatement } from '../KeywordAssistant/KeywordAssistantUserQueryStatement';

export type AssistantLlmBlockProps = {
  preventFocus?: boolean;
  annotations?: LlmAssistantMessageBreakout['annotations'];
};

const highlighter = loadHighlighter(
  getSingletonHighlighterCore({
    langs: allLangs(bundledLanguagesInfo),
    langAlias: allLangsAlias(bundledLanguagesInfo),
    themes: Object.values(bundledThemes),
    loadWasm: getWasm
  })
);

const codeToHtmlOptions: CodeToHtmlOptions = {
  theme: 'github-light'
};

// Customize this component with your own styling
const CodeBlock: LLMOutputComponent<AssistantLlmBlockProps> = ({ blockMatch, preventFocus }) => {
  const { language, code } = parseCompleteMarkdownCodeBlock(blockMatch.output);
  const html = useCodeToHtml({
    code: code ?? '',
    highlighter,
    codeToHtmlOptions: {
      ...codeToHtmlOptions,
      lang: language === 'query' || language === 'userQuery' ? 'plain' : (language ?? 'plain')
    }
  });

  if (language === 'query') {
    return <KeywordAssistantQueryStatement preventFocus={preventFocus} statement={code} />;
  } else if (language === 'userQuery') {
    return <KeywordAssistantUserQueryStatement preventFocus={preventFocus} />;
  } else {
    if (!html) {
      // fallback to <pre> if Shiki is not loaded yet
      return (
        <pre className="shiki">
          <code>{code}</code>
        </pre>
      );
    }
    return <>{parseHtml(html)}</>;
  }
};

export { CodeBlock };
