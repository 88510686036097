import React from 'react';
import { Outlet } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { useGetPartnershipsQuery } from 'api/partnershipsApi';

import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const PartnershipsPage = () => {
  const { isLoading } = useGetPartnershipsQuery();

  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']} isLoading={isLoading}>
      <Main>
        <MainColumn>
          <Outlet />
        </MainColumn>
      </Main>
    </SubscriptionRequiredPageWrapper>
  );
};
