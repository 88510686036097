import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tag } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';

import { formatTime } from 'app/lib/dates';
import { SouptoolsTrSubmissionStatusTag } from 'app/organisms/SouptoolsTrSubmissionStatusTag/SouptoolsTrSubmissionStatusTag';
import { SouptoolsTr } from 'types/__generated__/GovlyApi';

type SouptoolsTrTableRowProps = {
  tr: SouptoolsTr;
};

const statusIntent = (status: string) => {
  if (status === 'complete') {
    return 'success';
  }

  if (status === 'draft') {
    return 'primary';
  }

  return 'danger';
};

export const SouptoolsTrTableRow = ({
  tr: { id, createdAt, status, clinsCount, createdBy, trSubmissions }
}: SouptoolsTrTableRowProps) => (
  <div className="flex flex-wrap justify-between">
    <div>
      <Link to={`/souptools/trs/${id}`}>
        <strong>{`TR ${id}`}</strong>
      </Link>
      {!!createdBy && (
        <small className="ml-2">
          <strong>{`Created by ${createdBy}`}</strong>
        </small>
      )}
      {!!trSubmissions?.length && (
        <span className="ml-2">
          <SouptoolsTrSubmissionStatusTag
            trSubmission={[...trSubmissions].sort((a, b) => Number(b.id) - Number(a.id))[0]}
          />
        </span>
      )}
      <span className="ml-2">
        <Tag minimal intent={statusIntent(status)}>
          {startCase(status)}
        </Tag>
      </span>
      <div className="flex flex-wrap items-center py-1">
        <>
          <Icon icon="time" iconSize={12} />
          <small className="ml-1">{` Created ${formatTime(createdAt)}`}</small>
        </>
        <>
          <Icon icon="dot" iconSize={12} />

          <small>
            <strong>{`${clinsCount} CLINs`}</strong>
          </small>
        </>
      </div>
    </div>
  </div>
);
