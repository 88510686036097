import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';

export const SysAdminPage = () => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  if (isLoading) {
    return null;
  }

  if (!currentUser?.sysAdmin) {
    return <NotAuthorized />;
  }

  return (
    <Main>
      <MainColumn>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </MainColumn>
    </Main>
  );
};
