import React from 'react';
import { useParams } from 'react-router-dom';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppHistory } from 'app/organisms/OppHistory/OppHistory';

export const OppDetailsHistoryPage = () => {
  const { id } = useParams();

  return (
    <MainColumn columnSpan={12}>
      <OppHistory id={id ?? ''} />
    </MainColumn>
  );
};
