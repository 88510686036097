import React from 'react';
import { useSearchParams } from 'react-router-dom';
import truncate from 'lodash-es/truncate';

import { GovernmentEntityIndex } from 'types/__generated__/GovlyApi';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { H2 } from 'app/atoms/Typography/Typography';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { GovernmentEntityLogo } from 'app/organisms/GovernmentEntity/utils';
import { Tag } from '@blueprintjs/core';
import { asCurrencyCompact } from 'app/lib/numbers';

export const GovernmentEntitiesCard = ({ governmentEntity }: { governmentEntity: GovernmentEntityIndex }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openGovernmentEntity: React.MouseEventHandler<HTMLElement> = event => {
    openDrawerOrNewTab(event, `/budgets/agencies/${governmentEntity.id}`, () => {
      setSearchParams({ ...Object.fromEntries(searchParams), drawerType: 'entity', drawerId: governmentEntity.id });
    });
  };

  return (
    <Card>
      <CardSection className="flex flex-col space-y-2">
        <div className="flex items-center">
          <GovernmentEntityLogo entity={governmentEntity} className="h-10 w-10" />

          <LinkTag
            tag="a"
            onClick={openGovernmentEntity}
            className="font-medium no-underline"
            data-test="government-entities-card"
          >
            <NewTabTooltip title="View Agency">
              <H2>{governmentEntity.name}</H2>
            </NewTabTooltip>
          </LinkTag>
        </div>

        {governmentEntity.description && (
          <h3 className="line-clamp-2">{truncate(governmentEntity.description, { length: 500 })}</h3>
        )}

        <div>
          {governmentEntity.latestUpload?.total && (
            <Tag minimal intent="warning" className="text-xs">
              {governmentEntity.latestUpload.fiscalYear} Budget Requested: $
              {asCurrencyCompact(governmentEntity.latestUpload.total)}
            </Tag>
          )}
        </div>
      </CardSection>
    </Card>
  );
};
