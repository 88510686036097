import React from 'react';
import { Formik, Form } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { GovernmentEntities } from 'app/organisms/GovernmentEntities/GovernmentEntities';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { Divider } from '@blueprintjs/core';

export const GovernmentEntitiesForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchFromParams = searchParams.get('search') || '';

  return (
    <Formik
      initialValues={{ search: searchFromParams }}
      onSubmit={values => {
        searchParams.delete('search');
        if (values.search) {
          setSearchParams({ ...Object.fromEntries(searchParams), search: values.search });
        } else {
          setSearchParams({ ...Object.fromEntries(searchParams) });
        }
      }}
    >
      <Form>
        <p className="text-gray-500">Search for a Federal Agency</p>
        <Divider className="w-full m-0 mb-4" />
        <TextInput
          name="search"
          className="mb-10"
          inputProps={{ placeholder: 'DOD, Navy, Institutes of Health...', large: true }}
          submitOnChange={true}
        />
        <GovernmentEntities />
      </Form>
    </Formik>
  );
};
