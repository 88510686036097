import React from 'react';
import { colors } from 'app/lib/colors';

type LogoIconProps = {
  size?: string | number;
  letterColor?: string;
  dotColor?: string;
  backgroundColor?: string;
  title?: string;
} & React.SVGProps<SVGSVGElement>;

export const LogoIcon = ({
  size = '240px',
  letterColor = colors.white,
  dotColor = colors.blue[500],
  backgroundColor = colors.gray[700],
  title = 'govly',
  ...rest
}: LogoIconProps) => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 240 240"
    className="rounded"
    {...rest}
  >
    <title>{title}</title>
    <rect width="240px" height="240px" fill={backgroundColor} />
    <path
      d="M141.28,73.38a22.58,22.58,0,0,1,1.47,8,21.5,21.5,0,0,1-.2,3l10.08,5.22a22.61,22.61,0,1,1-6,15.35,14.68,14.68,0,0,1,.11-2.2L136.21,97.3a22.51,22.51,0,0,1-9.1,5.6v2a42.16,42.16,0,1,0,14.24-31.6Z"
      fill={letterColor}
      fillRule="evenodd"
    />
    <path
      d="M120.15,91.82a10.41,10.41,0,1,0-10.41-10.41h0A10.43,10.43,0,0,0,120.15,91.82Z"
      fill={dotColor}
      fillRule="evenodd"
    />
    <path
      d="M99.06,73.27A42.17,42.17,0,1,0,93.24,141q-.1,9.06-5.52,14t-15.15,5.05Q57.9,160.05,51.9,149l-17.35,9.94A34.13,34.13,0,0,0,49.46,173.3a49.25,49.25,0,0,0,22.48,4.89q17,0,29.11-9.62t12.07-27.93c0-12.45.84-25.26.27-37.66a22.61,22.61,0,0,1-14.8-28.36C98.73,74.17,98.89,73.72,99.06,73.27ZM49.16,105h0a22.1,22.1,0,1,1,22.1,22.1A22.09,22.09,0,0,1,49.16,105Z"
      fill={letterColor}
      fillRule="evenodd"
    />
  </svg>
);

export default LogoIcon;
