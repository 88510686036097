import { Certification, CertificationTypeahead } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetCertifications = {
  params: { view?: 'typeahead' } | void;
  result: Certification[] | CertificationTypeahead[];
};

export const certificationsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getCertifications: build.query<GetCertifications['result'], GetCertifications['params']>({
      query: params => ({
        url: '/v2/certifications',
        params: params || {}
      })
    })
  })
});

export const { useGetCertificationsQuery } = certificationsApi;
