import React from 'react';
import { Icon } from '@blueprintjs/core';
import { formatDistance, parseISO, differenceInBusinessDays } from 'date-fns';

type ProviderFeedLastActiveProps = React.HTMLAttributes<HTMLDivElement> & {
  lastActive?: string;
  isExpired?: boolean;
};

export const ProviderFeedLastActive = ({ isExpired, lastActive, ...rest }: ProviderFeedLastActiveProps) => {
  let label: string;
  let daysAgo = 100;

  if (!lastActive) {
    label = 'This feed has not received data';
  } else if (isExpired === true) {
    label = 'Contract Expired';
  } else {
    const now = new Date();
    daysAgo = differenceInBusinessDays(now, parseISO(lastActive));
    label = `Last received ${formatDistance(now, parseISO(lastActive))}`;
  }

  return (
    <div className="my-2" {...rest}>
      <Icon className="mr-1" icon={daysAgo < 8 ? 'tick' : 'warning-sign'} intent={daysAgo < 8 ? 'success' : 'danger'} />
      {label}
    </div>
  );
};
