import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { FeedProfileAll } from 'types/__generated__/GovlyApi';
import { useGetFeedProfilesQuery } from 'api/feedProfilesApi';

import { formatTime } from 'app/lib/dates';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';

const columnHelper = createColumnHelper<FeedProfileAll>();

export const FeedProfilesTable = () => {
  const { data, isLoading } = useGetFeedProfilesQuery({ all: true });
  const feedProfiles = (data ?? []) as FeedProfileAll[];

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID'
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('organizationName', {
        header: 'Organization',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('failedLoginAttempts', {
        header: 'Failed Login Attempts'
      }),
      columnHelper.accessor('recordsProcessedInPrevDay', {
        header: 'Records Processed in Previous 24 Hours'
      }),
      columnHelper.accessor('lastActorRunProcessedAt', {
        header: 'Last Ran At',
        sortingFn: 'basic',
        cell: e => (
          <span>
            {!e.row.original.lastActorRunProcessedAt ? 'Never Ran' : formatTime(e.row.original.lastActorRunProcessedAt)}
          </span>
        )
      })
    ],
    []
  );

  return (
    <GovlyTable
      id="feed_profiles_table"
      columns={columns}
      data={feedProfiles}
      title={<GovlyTableTitle title="Feed Profiles" />}
      isLoading={isLoading}
      initialState={{
        pagination: { pageSize: 50 },
        sorting: [{ id: 'type', desc: false }]
      }}
    />
  );
};
