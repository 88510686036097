import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { OppWorkspacePreview } from 'app/organisms/OppWorkspacePreview/OppWorkspacePreview';
import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { OppIndex } from 'types/__generated__/GovlyApi';

type Action = Partial<{
  isSharing: boolean;
  isUpdatingWorkspace: boolean;
  createWorkspace: boolean;
}>;

type OppSearchWorkspacesProps = {
  opp?: OppIndex;
  action: Action;
  setAction: (arg: Action) => void;
  hideWorkspaces?: boolean;
};

export const OppSearchWorkspaces = ({ opp, action, setAction, hideWorkspaces }: OppSearchWorkspacesProps) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const organizationName = useCurrentUserAttribute('organizationName') as string | undefined;

  if (!opp) return null;

  const { workspaces = [] } = opp;

  const defaultWorkspace = workspaces.find(({ organizationDefault }) => organizationDefault);

  const openWorkspace = (e: React.MouseEvent<HTMLElement>, workspaceId: string) => {
    const path = `/opportunities/${opp.id}/workspaces/${workspaceId}`;

    openDrawerOrNewTab(e, path, () =>
      setSearchParams({
        drawerType: 'workspaces',
        drawerId: workspaceId
      })
    );
  };

  return (
    <>
      {!hideWorkspaces && workspaces.length > 0 && (
        <div className="flex flex-col gap-y-4">
          {workspaces.map(workspace => {
            const { id: workspaceId, follows = [] } = workspace;
            const displayableFollows = follows.filter(({ state }) => state !== 'unfollowed');

            return (
              <Card key={workspaceId}>
                <CardBody className="flex space-y-0 flex-wrap gap-y-2 justify-between items-center">
                  <OppWorkspacePreview
                    workspace={workspace}
                    onClick={(e: React.MouseEvent<HTMLElement>) => openWorkspace(e, workspaceId)}
                    displayableFollows={displayableFollows}
                    headerClass="flex flex-col gap-y-2 items-start"
                    followsClass="flex flex-col gap-y-2 items-start sm:items-end mt-2"
                  />
                </CardBody>
              </Card>
            );
          })}
        </div>
      )}

      <Dialog isOpen={action.isUpdatingWorkspace} className="min-w-[50%] p-0">
        <WorkspaceForm
          title={action.createWorkspace ? 'Create a Workspace' : 'Default Workspace'}
          onCancel={() => setAction({})}
          follows={action.createWorkspace ? [] : (defaultWorkspace?.follows ?? [])}
          basicInputs={!!action.createWorkspace}
          followersInputs={true}
          stageInputs={true}
          trackingData={{ event: action.createWorkspace ? 'Opp Workspace Created' : null }}
          workableTypeForLink="opportunities"
          initialValues={{
            id: action.createWorkspace ? undefined : defaultWorkspace?.id,
            name: action.createWorkspace ? '' : (defaultWorkspace?.name ?? organizationName ?? ''),
            organizationDefault: !action.createWorkspace,
            oppId: opp.id,
            workflowStage: action.createWorkspace ? 'qualifying' : (defaultWorkspace?.workflowStage ?? 'qualifying')
          }}
          enableReinitialize
        />
      </Dialog>
    </>
  );
};
