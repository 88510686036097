import React from 'react';
import { Formik, Form } from 'formik';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { ChatForm } from 'app/organisms/Chat/ChatForm';
import { useChat } from 'app/organisms/Chat/useChat';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';

import { BudgetsChatFilters } from './BudgetsChatFilters';

const PLACEHOLDER_QUESTIONS = [
  'Which agency has the highest budget requested for IT networking?',
  'Can you list the agencies that have requested budget for cybersecurity?',
  'Can you tell me the total budget requested for each agency?'
];

export const BudgetsChat = () => {
  const { messages, createMessage, resetMessages, isLoading, ref } = useChat({
    storeId: 'budgets-chat',
    numberOfResults: 50
  });

  return (
    <Formik
      initialValues={{ filters: { entityIds: [] }, query: '', useCustomPrompt: false }}
      onSubmit={({ query, useCustomPrompt, filters: { entityIds } }, { setFieldValue }) =>
        createMessage({ entityIds, message: query, clearQuery: () => setFieldValue('query', ''), useCustomPrompt })
      }
    >
      <Form className="min-h-full">
        <Main
          className="py-0 max-h-[80vh]"
          gridClassName="max-h-[inherit] lg:grid-cols-[360px,repeat(11,1fr)] 2xl:grid-cols-[420px,repeat(11,1fr)]"
        >
          <MainColumn tag="aside" className={'top-0 py-4 px-px overflow-y-auto sticky hidden lg:block lg:col-span-1'}>
            <BudgetsChatFilters />
          </MainColumn>
          <MainColumn tag="section" className="pt-4 pb-32 lg:col-span-11">
            <ChatForm
              messages={messages}
              isLoading={isLoading}
              placeholder={`Use this chat to ask questions about projected budget documents across different agencies.`}
              questions={PLACEHOLDER_QUESTIONS}
              createMessage={createMessage}
              resetMessages={resetMessages}
              cardProps={{
                title: (
                  <span className="space-x-2">
                    <LlmGeneratedIndicator icon="generate" /> Budget Analysis
                  </span>
                ),
                titleRenderer: CardHeadingSmall,
                collapsible: false
              }}
              ref={ref}
            />
          </MainColumn>
        </Main>
      </Form>
    </Formik>
  );
};
