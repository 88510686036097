import { CrmConnectionShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['CrmConnection', 'CurrentOrganization', 'CrmInteraction'] });

type GetCrmConnections = {
  params: void;
  result: CrmConnectionShow[];
};

type CreateOrUpdateConnection = {
  params: {
    integration: string;
    active: boolean;
    username: string;
    password: string;
    connectionConfig: {
      apiKey: string;
      firmId: string;
    };
    hasModSyncingEnabled: boolean;
    hasOppPushingEnabled: boolean;
  };
  result: CrmConnectionShow;
};

export const crmConnectionsApi = api.injectEndpoints({
  endpoints: build => ({
    getCrmConnections: build.query<GetCrmConnections['result'], GetCrmConnections['params']>({
      query: () => ({
        url: '/v2/crm_connections'
      }),
      providesTags: ['CrmConnection']
    }),
    createOrUpdateCrmConnection: build.mutation<CreateOrUpdateConnection['result'], CreateOrUpdateConnection['params']>(
      {
        query: body => ({
          url: `/v2/crm_connections`,
          method: 'POST',
          body
        }),
        invalidatesTags: ['CrmConnection', 'CurrentOrganization', 'CrmInteraction']
      }
    )
  })
});

export const { useGetCrmConnectionsQuery, useCreateOrUpdateCrmConnectionMutation } = crmConnectionsApi;
