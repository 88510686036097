import React, { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Drawer, Button } from '@blueprintjs/core';
import { Form } from 'formik';

import { Loading } from 'app/atoms/Loading/Loading';
import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppSearchQuery } from 'app/organisms/OppSearchQuery/OppSearchQuery';
import { OppSearchFilters } from 'app/organisms/OppSearchFilters/OppSearchFilters';
import { useInitializeOppSearchCache } from 'app/hooks/search/useOppSearchCache';
import { SearchFilterContainer } from 'app/molecules/SearchFilterContainer/SearchFilterContainer';

export const OppSearchFormFields = () => {
  useInitializeOppSearchCache();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  return (
    <Form>
      <Main className="py-0" gridClassName="lg:grid-cols-[360px,repeat(11,1fr)] xl:grid-cols-[420px,repeat(11,1fr)]">
        <SearchFilterContainer>
          <OppSearchFilters />
        </SearchFilterContainer>

        <MainColumn columnSpan={8} className="pt-4 pb-32 lg:col-span-11">
          <div className="p-6 lg:p-0">
            <Button
              onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
              className="mb-8 lg:hidden"
              text="Edit Filters"
              rightIcon="chevron-right"
              large
            />
            <OppSearchQuery />
          </div>
          <Suspense fallback={<Loading type="card-list" />}>
            <Outlet />
          </Suspense>
        </MainColumn>
      </Main>
      <Drawer
        isOpen={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        size="100%"
        position="left"
        title=""
        className="overflow-scroll"
      >
        <OppSearchFilters wrapperClassName="h-full overflow-y-auto" className="pb-12" />
      </Drawer>
    </Form>
  );
};
