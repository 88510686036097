import React from 'react';
import { Notification, NotificationIndicator } from 'app/atoms/NotificationIndicator/NotificationIndicator';
import { NavLinkButton } from 'app/molecules/NavLinkButton/NavLinkButton';
import { NavItem } from 'app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useAuthenticatedNavContext } from 'app/organisms/AuthenticatedNav/useAuthenticatedNavContext';
import { useNavStore } from 'app/organisms/AuthenticatedNav/useNavStore';
import { Tooltip } from '@blueprintjs/core';

export type WorklistNavItemProps = {
  node: NavItem;
  index: number;
};

export const WorklistNavItem = ({ node, index }: WorklistNavItemProps) => {
  const { currentUser } = useAuthenticatedNavContext();
  const notificationIntent = currentUser.hasUnreadNotifications ? 'success' : undefined;
  const { isExpanded } = useNavStore();

  const btn = (
    <Tooltip content={node.label} disabled={isExpanded} className="w-full">
      <NavLinkButton
        key={index}
        to={node.to}
        buttonProps={{
          icon: node.icon,
          text: isExpanded ? node.label : undefined
        }}
        forceCurrent={node.current}
      />
    </Tooltip>
  );

  return notificationIntent ? (
    <Notification key={index} className="w-full">
      {btn}
      <NotificationIndicator
        intent={notificationIntent}
        {...(isExpanded
          ? {
              size: 'md',
              position: 'center',
              className: '-translate-x-3'
            }
          : {
              size: 'sm',
              className: 'right-1'
            })}
      />
    </Notification>
  ) : (
    btn
  );
};
