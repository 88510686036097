import { SouptoolsClinUpload, SouptoolsClin } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::Tr', 'Souptools::Quote'] });

type ClinableType = 'Souptools::Tr' | 'Souptools::Quote';
type UploadableType = 'Souptools::Tr' | 'Souptools::Quote';

type CheckClinList = {
  params: {
    clinableId: string;
    clinableType: ClinableType;
  };
  result: File;
};

type CreateClinUpload = {
  params: {
    signedId: string;
    uploadableId: string;
    uploadableType: UploadableType;
  };
  result: SouptoolsClinUpload;
};

type RejectClinUpload = {
  params: {
    id: string;
    rejectionReason: string;
    uploadableId: string;
    uploadableType: UploadableType;
  };
  result: void;
};

type DeleteClinUpload = {
  params: {
    id: string;
    uploadableId: string;
    uploadableType: UploadableType;
  };
  result: void;
};

type UpdateClin = {
  params: {
    id: string;
    clinableId: string;
    clinableType: ClinableType;
  };
  result: SouptoolsClin;
};

type DeleteClin = {
  params: {
    id: string;
    clinableId: string;
    clinableType: ClinableType;
  };
  result: void;
};

export const souptoolsClinsApi = api.injectEndpoints({
  endpoints: build => ({
    checkClinList: build.mutation<CheckClinList['result'], CheckClinList['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.clinableType, id: arg.clinableId }],
      query: ({ clinableId, clinableType }) => ({
        url: `/v2/souptools/clinable/${clinableId}/clin_list`,
        method: 'POST',
        body: { clinableType }
      })
    }),

    createClinUpload: build.mutation<CreateClinUpload['result'], CreateClinUpload['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.uploadableType, id: arg.uploadableId }],
      query: ({ signedId, uploadableId, uploadableType }) => ({
        url: `/v2/souptools/clin_uploads`,
        method: 'POST',
        body: { signedId, uploadableId, uploadableType }
      })
    }),

    rejectClinUpload: build.mutation<RejectClinUpload['result'], RejectClinUpload['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.uploadableType, id: arg.uploadableId }],
      query: ({ id, rejectionReason }) => ({
        url: `/v2/souptools/clin_uploads/${id}/reject`,
        method: 'POST',
        body: { rejectionReason }
      })
    }),

    deleteClinUpload: build.mutation<DeleteClinUpload['result'], DeleteClinUpload['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.uploadableType, id: arg.uploadableId }],
      query: ({ id }) => ({
        url: `/v2/souptools/clin_uploads/${id}`,
        method: 'DELETE'
      })
    }),

    updateClin: build.mutation<UpdateClin['result'], UpdateClin['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.clinableType, id: arg.clinableId }],
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/clins/${id}`,
        method: 'PATCH',
        body
      })
    }),

    deleteClin: build.mutation<DeleteClin['result'], DeleteClin['params']>({
      invalidatesTags: (result, err, arg) => [{ type: arg.clinableType, id: arg.clinableId }],
      query: ({ id }) => ({
        url: `/v2/souptools/clins/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useCheckClinListMutation,
  useRejectClinUploadMutation,
  useDeleteClinUploadMutation,
  useCreateClinUploadMutation,
  useDeleteClinMutation,
  useUpdateClinMutation
} = souptoolsClinsApi;
