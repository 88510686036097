import React from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as yup from 'yup';
import { colors } from 'app/lib/colors';
import { TextInput, TextInputProps } from 'app/atoms/inputs/TextInput/TextInput';

export const colorInputValidation = () =>
  yup
    .string()
    .required('Please enter a color.')
    .matches(/^#[0-9A-F]{6}$/i, 'A valid color is required.');

type ColorInputProps = {
  setValue: (value: string) => void;
} & TextInputProps;

export const ColorInput = ({ setValue, ...rest }: ColorInputProps) => {
  return (
    <Popover
      placement="bottom"
      usePortal={false}
      content={
        <div className="p-2">
          <span className="text-gray-500">Choose from our default colors.</span>
          <div className="my-2 flex gap-2">
            <div
              className="h-6 w-6 cursor-pointer rounded bg-orange-500"
              onClick={() => setValue(colors.orange[500])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-teal-500" onClick={() => setValue(colors.teal[500])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-cyan-500" onClick={() => setValue(colors.cyan[500])} />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-indigo-500"
              onClick={() => setValue(colors.indigo[500])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-red-500" onClick={() => setValue(colors.red[500])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-green-500" onClick={() => setValue(colors.green[500])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-blue-500" onClick={() => setValue(colors.blue[500])} />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-yellow-500"
              onClick={() => setValue(colors.yellow[500])}
            />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-purple-500"
              onClick={() => setValue(colors.purple[500])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-pink-500" onClick={() => setValue(colors.pink[500])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-gray-500" onClick={() => setValue(colors.gray[500])} />
          </div>
          <div className="mb-4 flex gap-2">
            <div
              className="h-6 w-6 cursor-pointer rounded bg-orange-200"
              onClick={() => setValue(colors.orange[200])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-teal-200" onClick={() => setValue(colors.teal[200])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-cyan-200" onClick={() => setValue(colors.cyan[200])} />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-indigo-200"
              onClick={() => setValue(colors.indigo[200])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-red-200" onClick={() => setValue(colors.red[200])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-green-200" onClick={() => setValue(colors.green[200])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-blue-200" onClick={() => setValue(colors.blue[200])} />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-yellow-200"
              onClick={() => setValue(colors.yellow[200])}
            />
            <div
              className="h-6 w-6 cursor-pointer rounded bg-purple-200"
              onClick={() => setValue(colors.purple[200])}
            />
            <div className="h-6 w-6 cursor-pointer rounded bg-pink-200" onClick={() => setValue(colors.pink[200])} />
            <div className="h-6 w-6 cursor-pointer rounded bg-gray-200" onClick={() => setValue(colors.gray[200])} />
          </div>
          <Button
            fill
            outlined
            small
            onClick={() => setValue(`#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')}`)}
          >
            Generate random color
          </Button>
        </div>
      }
    >
      <TextInput {...rest} />
    </Popover>
  );
};
