import { useEffect, useState } from 'react';

export const useThemeToggle = () => {
  const [isDark, setIsDark] = useState(false);
  const toggleDarkMode = () => {
    setIsDark(!isDark);
  };

  useEffect(() => {
    if (isDark) {
      document.querySelector('html')?.classList.add('dark', 'bp5-dark');
      document.body.classList.add('dark', 'bp5-dark');
    } else {
      document.querySelector('html')?.classList.remove('dark', 'bp5-dark');
      document.body.classList.remove('dark', 'bp5-dark');
    }
  }, [isDark, setIsDark]);

  return {
    toggleDarkMode,
    isDark
  };
};

export const useIsDarkMode = () => {
  return document.body.classList.contains('dark');
};
