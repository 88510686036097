import React, { useState } from 'react';
import { Drawer, DrawerSize } from '@blueprintjs/core';
import { useSearchQueryInputContext } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { AssistantProvider } from '../Assistant/Assistant';
import { AssistantMessageList } from '../Assistant/AssistantMessageList';
import { AssistantForm } from '../Assistant/AssistantForm';
import { KeywordAssistantHeader } from './KeywordAssistantHeader';
import { getWelcomeMessages } from './KeywordAssistantWelcomeMessages';

const KeywordAssistant = () => {
  const { assistantIsOpen, setAssistantIsOpen } = useSearchQueryInputContext();
  const { isMobile, isTablet } = useDeviceWidth();
  const { data: user, isLoading: userLoading } = useGetCurrentUserQuery();
  const { standardWelcomeMessage } = getWelcomeMessages(user);
  const defaultPrompts = [
    `Find opportunities related to ${user?.organization?.primaryDomain}`,
    'Find opportunities related to cybersecurity',
    "Find keywords related to 'AI'"
  ];
  const [welcomeMessage, setWelcomeMessage] = useState<string | undefined>(standardWelcomeMessage());

  if (userLoading) {
    return null;
  }

  return (
    <AssistantProvider
      placeholder="Example: I need to find opportunities related to continuous integration and delivery."
      type="Llm::Assistants::KeywordQueryAssistant"
      welcomeMessage={welcomeMessage}
      setWelcomeMessage={setWelcomeMessage}
      defaultPrompts={defaultPrompts}
    >
      <Drawer
        isOpen={assistantIsOpen}
        onClose={() => setAssistantIsOpen(!assistantIsOpen)}
        position={isMobile || isTablet ? 'bottom' : 'right'}
        size={isMobile || isTablet ? DrawerSize.LARGE : DrawerSize.STANDARD}
      >
        <KeywordAssistantHeader />
        <AssistantMessageList />
        <AssistantForm className="p-4" />
      </Drawer>
    </AssistantProvider>
  );
};

export { KeywordAssistant };
