import React from 'react';
import { Link } from 'react-router-dom';
import { Highlight } from 'react-instantsearch';
import { Tag } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { pluralize } from 'app/lib/strings';

import { AlgoliaSearchVendorProfile } from 'types/shared/AlgoliaSearchVendorProfile';

type Hit = React.ComponentProps<typeof Highlight>['hit'];

type VendorProfileSearchHitProps = {
  hit: Hit & AlgoliaSearchVendorProfile;
};

export const VendorProfileSearchHit = ({ hit }: VendorProfileSearchHitProps) => {
  const {
    slug,
    isClaimed,
    shortDescription,
    numberOfEmployees,
    contacts,
    contractVehicles,
    certifications,
    estimatedRevenue,
    _tags,
    setAsides
  } = hit;

  return (
    <Card>
      <CardBody>
        <Link to={`/vendors/${slug}`}>
          <div className="flex items-center justify-between">
            <Highlight attribute="name" hit={hit} className="pr-2" />
            {isClaimed && (
              <Tag minimal intent="success" icon="endorsed">
                Govly Vendor
              </Tag>
            )}
          </div>
        </Link>
        <p>{shortDescription}</p>
        <div className="flex flex-wrap items-center gap-2">
          {contacts.length > 0 && (
            <Tag minimal>
              {contacts.length} {pluralize('Contact', 'Contacts', contacts.length)}
            </Tag>
          )}
          {contractVehicles.length > 0 && (
            <Tag minimal>
              {contractVehicles.length} {pluralize('Contract', 'Contracts', contractVehicles.length)}
            </Tag>
          )}
          {certifications.length > 0 && (
            <Tag minimal>
              {certifications.length} {pluralize('Certification', 'Certifications', certifications.length)}
            </Tag>
          )}
          {setAsides.length > 0 && (
            <Tag minimal>
              {setAsides.length} {pluralize('Set Aside', 'Set Asides', setAsides.length)}
            </Tag>
          )}
          {numberOfEmployees !== 'Unknown' && <Tag minimal>{numberOfEmployees} Employees</Tag>}
          {estimatedRevenue && <Tag minimal>{estimatedRevenue} Estimated Revenue</Tag>}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          {_tags.map((tag, tagIndex) => {
            if (tagIndex < 5) {
              return (
                <Tag key={tagIndex} minimal intent="warning">
                  {tag}
                </Tag>
              );
            }
          })}
        </div>
      </CardBody>
    </Card>
  );
};
