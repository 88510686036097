import React from 'react';

import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { useGetVendorsQuery } from 'api/vendorsApi';

type VendorSelectInputProps = Omit<React.ComponentProps<typeof SelectInput>, 'items'>;

export const VendorSelectInput = (props: VendorSelectInputProps) => {
  const { data: vendors = [], isLoading } = useGetVendorsQuery();

  return (
    <SelectInput items={vendors} loading={isLoading} valueAttr="id" labelAttr="name" searchKeys={['name']} {...props} />
  );
};
