import React from 'react';

import { Alignment, RadioCard, RadioCardProps, RadioGroup, RadioGroupProps } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';

type RadioGroupStackedCardsOption = {
  name: string;
  value: string;
  subtitle?: string;
  extendedSubtitle?: string;
  price?: string | number;
  recurrence?: string;
};

export type RadioGroupStackedCardsProps = {
  options: RadioGroupStackedCardsOption[];
  cardProps?: Partial<RadioCardProps>;
} & Omit<RadioGroupProps, 'options'>;

export const RadioGroupStackedCards = ({
  options,
  className,
  cardProps,
  ...radioGroupProps
}: RadioGroupStackedCardsProps) => {
  return (
    <RadioGroup {...radioGroupProps} className={cn('space-y-4', className)}>
      {options.map(option => (
        <RadioCard key={option.value} value={option.value} alignIndicator={Alignment.LEFT} {...cardProps}>
          <span className="flex flex-col gap-1">
            <span className="font-bold">{option.name}</span>
            {option.subtitle && (
              <Subtext>
                <span className="block sm:inline">{option.subtitle}</span>{' '}
                <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                  &middot;
                </span>{' '}
                {option.extendedSubtitle && <span className="block sm:inline">{option.extendedSubtitle}</span>}
              </Subtext>
            )}
            <Subtext className="text-lg font-medium text-gray-900">
              {option.price}
              {option.recurrence}
            </Subtext>
          </span>
        </RadioCard>
      ))}
    </RadioGroup>
  );
};

function Subtext({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) {
  return <span className={cn('text-sm text-gray-500', className)} {...props} />;
}
