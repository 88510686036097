import React, { useState } from 'react';
import { FileViewer } from 'app/molecules/FileViewer/FileViewer';
import { useGetLlmFileQuery } from 'api/llmFilesApi';
import { OppAttachmentBasic, WorkspaceAttachmentShow } from 'types/__generated__/GovlyApi';
import { match } from 'ts-pattern';

interface CitationFileViewerProps {
  externalId?: string;
  citationIndex?: number;
}

export const CitationFileViewer = ({ externalId, citationIndex }: CitationFileViewerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <span className="inline-block">
      <span className="ml-2 w-0 flex-1 truncate">
        <button className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={() => setIsOpen(true)}>
          {`[${citationIndex}]`}
        </button>
      </span>

      {isOpen && externalId && <FileViewerWithQuery externalId={externalId} onClose={() => setIsOpen(false)} />}
    </span>
  );
};

const FileViewerWithQuery = ({ externalId, onClose }: { externalId: string; onClose: () => void }) => {
  const { data: fileData } = useGetLlmFileQuery({ externalId });

  const fileable = fileData?.llmFileable as WorkspaceAttachmentShow | OppAttachmentBasic | undefined;
  const fileToView = match(fileable)
    .with({ __typename: 'WorkspaceAttachmentShow' }, ({ attachment }) => attachment)
    .with({ __typename: 'OppAttachmentBasic' }, ({ file }) => file)
    .otherwise(() => undefined);

  if (!fileToView) return null;

  return (
    <FileViewer
      isOpen={true}
      downloadLink={fileToView.link ?? ''}
      fileName={fileToView.name ?? ''}
      fileUrl={fileToView.link ?? ''}
      onClose={onClose}
      disableDownload={false}
    />
  );
};
