import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { errorToast, successToast } from 'app/lib/toaster';
import { useGetFeedsQuery } from 'api/feedsApi';
import { useCreateProviderFeedMutation } from 'api/providerFeedsApi';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';

export const ProviderFeedForm = () => {
  const { data: feeds = [], isLoading: feedsLoading } = useGetFeedsQuery({
    filter: 'all'
  });
  const [createProviderFeed, { isLoading: createProviderFeedLoading }] = useCreateProviderFeedMutation();

  const options = feeds.map(({ name, id }) => ({
    label: name,
    value: id
  }));

  if (feedsLoading) return <Loading />;

  return (
    <Formik
      enableReinitialize
      initialValues={{ feedId: '' }}
      onSubmit={async values => {
        try {
          await createProviderFeed(values).unwrap();
          successToast();
        } catch (e) {
          errorToast(e);
        }
      }}
    >
      <Form>
        <Card title="Create Feed">
          <CardBody>
            <SelectInput name="feedId" label="Feed" items={options} searchKeys={['label']} />
          </CardBody>
          <CardFooter>
            <Button large type="submit" loading={createProviderFeedLoading} text="Create Feed" intent="primary" />
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};
