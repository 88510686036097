import React from 'react';
import { Formik, Form } from 'formik';

import { AWARD_SEARCH_CACHE_KEY, useAwardSearchMutation, awardsApi } from 'api/awardsApi';

import { useEventTracking } from 'app/hooks/useEventTracking';
import { AwardSearch } from 'app/organisms/AwardSearch/AwardSearch';
import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { LoaderFunction, useLocation } from 'react-router-dom';
import { useHydrateSearchForm } from 'app/hooks/search/useHydrateSearchForm';
import { useAwardSearchCache, getFormState, defaultValues } from 'app/hooks/search/useAwardSearchCache';
import { getUsedFilters } from 'app/lib/tracking';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';
import { useGetSavedSearchesQuery } from 'api/savedSearchesApi';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { match, P } from 'ts-pattern';
import { store } from 'app/store';

type LoaderData = { drawerType?: string | null; drawerId?: string | null };

export const loader: LoaderFunction = async ({ request }): Promise<LoaderData> => {
  useDefaultSearchStateStore.setState({ defaultState: defaultValues });
  const url = new URL(request.url);
  return {
    drawerType: url.searchParams.get(DRAWER_PARAMS.type),
    drawerId: url.searchParams.get(DRAWER_PARAMS.id)
  };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ drawerType, drawerId }) => {
  const drawerCrumbs = await match({ drawerType, drawerId })
    .with({ drawerType: 'awards', drawerId: P.string }, async ({ drawerId }) => {
      return store
        .dispatch(awardsApi.endpoints.getAward.initiate({ id: drawerId }, { subscribe: false }))
        .then(({ data }) => [
          data
            ? {
                to: `/awards/${data.id}`,
                text: [data.awardType || data.idvType || data.awardOrIdvFlag, data.awardIdPiid].join(' ')
              }
            : {}
        ]);
    })
    .otherwise(() => []);

  return [{ text: 'Awards', to: '/awards' }, ...drawerCrumbs];
});

export const AwardSearchPage = () => {
  const { pathname } = useLocation();
  const { trackEvent } = useEventTracking();
  const { initialValues, isInitializing } = useHydrateSearchForm({
    store: useAwardSearchCache,
    getFormState,
    parse: (s, queryParam) => {
      return JSON.parse(s, (_k, v) => {
        if (queryParam?.endsWith('dateRange') && Array.isArray(v)) {
          return v.map(s => new Date(s));
        }
        return v;
      });
    }
  });
  const scrollAppBody = useScrollAppBody();

  const [search] = useAwardSearchMutation({ fixedCacheKey: AWARD_SEARCH_CACHE_KEY });
  const { isLoading } = useGetSavedSearchesQuery({ active: true, searchableType: 'USASpendingAward' });

  if (isLoading || (isInitializing && !initialValues)) {
    return <AppLoading />;
  }

  return (
    <Formik
      key={isInitializing.toString()}
      initialValues={initialValues}
      onSubmit={({ query, filters }) => {
        if (isInitializing) return;

        search({ query, ...filters });

        const usedFilters = getUsedFilters(filters);
        trackEvent({ object: 'awards', action: 'searched', properties: { query, ...filters, usedFilters } });
        if (pathname === '/awards/results') {
          scrollAppBody({ top: 0, behavior: 'smooth' });
        }
      }}
    >
      <Form>
        <AwardSearch />
      </Form>
    </Formik>
  );
};
