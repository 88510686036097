import React from 'react';
import { match } from 'ts-pattern';

import { SearchFollowButton } from 'app/organisms/SearchFollowButton/SearchFollowButton';
import { SearchShareInteraction } from 'app/organisms/SearchShareInteraction/SearchShareInteraction';
import { SearchLabelInteraction } from 'app/organisms/SearchLabelInteraction/SearchLabelInteraction';
import { ContactIndex, USASpendingAwardIndex } from 'types/__generated__/GovlyApi';

export type SearchResultInteractionsProps = {
  resource?: ContactIndex | USASpendingAwardIndex;
};

export const SearchResultInteractions = ({ resource }: SearchResultInteractionsProps) => {
  if (!resource?.id) return;

  const { interactableType, shareFormTitle } = match(resource)
    .with({ __typename: 'ContactIndex' }, ({ name, email }) => ({
      interactableType: 'Contact' as const,
      shareFormTitle: `Share Contact: ${name ? name : email}`
    }))
    .with({ __typename: 'USASpendingAwardIndex' }, ({ title }) => ({
      interactableType: 'USASpendingAward' as const,
      shareFormTitle: `Share Award: ${title}`
    }))
    .exhaustive();

  return (
    <div className="flex flex-wrap items-center gap-2">
      <SearchFollowButton
        type={interactableType}
        id={resource.id}
        interaction={resource.workableInteraction}
        isLoading={JSON.stringify(resource) === '{}'}
      />
      <SearchShareInteraction
        type={interactableType}
        interaction={resource?.workableInteraction}
        id={resource.id}
        isLoading={JSON.stringify(resource) === '{}'}
        formTitle={shareFormTitle}
      />
      <SearchLabelInteraction taggableType={interactableType} resource={resource} />
    </div>
  );
};
