import React from 'react';

import { OppWorkspacePageContents } from 'app/organisms/OppWorkspacePageContents/OppWorkspacePageContents';
import { useAppDrawerSubNavTab } from 'app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { DrawerType } from 'app/organisms/AppDrawer/constants';
import { OppWorkspaceStoreProvider } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const showWorkspaceDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/opportunities', '/worklist/workspaces', '/worklist/team_workspaces'].includes(window.location.pathname) &&
  drawerType === 'workspaces' &&
  drawerId;

export const OppWorkspaceDrawerBody = ({ id }: { id: string }) => {
  const tab = useAppDrawerSubNavTab('workspaces');

  return (
    <OppWorkspaceStoreProvider workspaceId={id}>
      <OppWorkspacePageContents id={id} inDrawer tab={tab} />
    </OppWorkspaceStoreProvider>
  );
};
