import React from 'react';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from 'api/governmentEntitiesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';
import { capitalize } from 'app/lib/strings';

export const BudgetUploads = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity.uploads?.length) return null;

  return (
    <Card title={'Budget Docs'} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardBody>
        <ul>
          {governmentEntity.uploads?.map(
            upload =>
              upload.file && (
                <li key={upload.id}>
                  <a href={upload.file.link} target="_blank" rel="noreferrer">
                    {capitalize(upload.category)} (FY{upload.fiscalYear})
                  </a>
                </li>
              )
          )}
        </ul>
      </CardBody>
    </Card>
  );
};
