import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { useGetReactionsQuery, useCreateReactionMutation } from 'api/reactionsApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { reactionsWithDescriptions } from 'app/lib/reactions';
import { useReactionsStore } from 'app/molecules/Reactions/useReactionsStore';

export const Reactions = ({ className }: { className?: string }) => {
  const { commentId, setIsOpen } = useReactionsStore(s => ({ commentId: s.commentId, setIsOpen: s.setIsOpen }));
  const { data: { name: currentUserName } = {} } = useGetCurrentUserQuery();
  const { data: reactions = [] } = useGetReactionsQuery({ reactableId: commentId });
  const [createCommentReaction] = useCreateReactionMutation();

  const reactionsToDisplay = reactions.reduce(
    (acc, reaction) => {
      if (!acc[reaction.name ?? '']) acc[reaction.name ?? ''] = [];
      acc[reaction.name ?? ''].push(reaction.orgUserName ?? '');
      return acc;
    },
    {} as Record<string, string[]>
  );

  const handleClick = (reaction: string) => {
    setIsOpen(false);
    createCommentReaction({
      name: reaction,
      reactableType: 'Comment',
      reactableId: commentId
    });
  };

  const reactedButtons = Object.entries(reactionsToDisplay).map(([name, users]) => (
    <Tooltip
      content={`${users.join(', ')} reacted with ${reactionsWithDescriptions[name as keyof typeof reactionsWithDescriptions]}`}
      key={`${name} ${users.length}`}
    >
      <Button
        className="rounded-full"
        intent={users.includes(currentUserName as keyof typeof reactionsWithDescriptions) ? 'primary' : undefined}
        outlined
        text={`${name} ${users.length}`}
        onClick={() => handleClick(name)}
        small
      />
    </Tooltip>
  ));

  return (
    <div className={className}>
      <div data-test="reacted-emoji-buttons" className="flex gap-x-1">
        {reactions.length > 0 && reactedButtons}
      </div>
    </div>
  );
};
