import React, { useState, useEffect } from 'react';
import { Tag, Button, MenuItem, NonIdealState, Dialog } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';

import { useGetSouptoolsQuotesQuery, useCreateSouptoolsQuoteMutation } from 'api/souptoolsQuotesApi';
import { TablePagination } from 'app/molecules/TablePagination/TablePagination';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { Loading } from 'app/atoms/Loading/Loading';
import { SouptoolsQuoteTableRow } from 'app/organisms/SouptoolsQuoteTableRow/SouptoolsQuoteTableRow';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { useEventTracking } from 'app/hooks/useEventTracking';

type StatusValue = 'draft' | 'complete';

export const SouptoolsQuotesPage = () => {
  const navigate = useNavigate();
  const { trackEvent } = useEventTracking();
  const [status, setStatus] = useState<StatusValue>('draft');
  const [page, setPage] = useState(1);
  const [createQuote, { data: newQuote, isLoading: isCreatingQuote, isSuccess: quoteCreated }] =
    useCreateSouptoolsQuoteMutation();
  const [createQuoteModalIsOpen, setCreateQuoteModalIsOpen] = useState(false);

  const changeStatus = (status: StatusValue) => {
    setStatus(status);
    setPage(1);
  };

  const { data, isFetching } = useGetSouptoolsQuotesQuery({ status, page });
  const { quotes, meta } = data ?? { quotes: [], meta: { total: 0, currentPage: page, totalPages: page, perPage: 10 } };

  useEffect(() => {
    if (quoteCreated) {
      navigate(`/souptools/quotes/${newQuote.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteCreated, newQuote]);

  return (
    <>
      <Card
        title={
          <div className="flex items-center gap-x-2">
            <CardHeading className="inline">Quotes</CardHeading>
            {!isFetching && <Tag minimal>{meta.total}</Tag>}
          </div>
        }
        rightElement={
          <div className="flex gap-x-2">
            <Button intent="success" icon="plus" text="New Quote" onClick={() => setCreateQuoteModalIsOpen(true)} />
            <Select
              activeItem={
                status === 'draft' ? { label: 'Draft', value: 'draft' } : { label: 'Complete', value: 'complete' }
              }
              filterable={false}
              items={
                status === 'draft' ? [{ label: 'Complete', value: 'complete' }] : [{ label: 'Draft', value: 'draft' }]
              }
              onItemSelect={({ value }) => changeStatus(value as StatusValue)}
              itemRenderer={(item, { handleClick }) => (
                <MenuItem key={item.value} onClick={handleClick} text={item.label} />
              )}
            >
              <Button
                rightIcon="chevron-down"
                text={status === 'draft' ? 'Draft' : 'Complete'}
                className="flex justify-between"
              />
            </Select>
          </div>
        }
      >
        {isFetching ? (
          <CardBody>
            <Loading type="flex-row" />
          </CardBody>
        ) : (
          quotes.map(quote => (
            <CardBody key={quote.id} className="py-2 lg:py-2">
              <SouptoolsQuoteTableRow quote={quote} />
            </CardBody>
          ))
        )}
        {!isFetching && !quotes?.length && (
          <CardBody>
            <NonIdealState
              icon="folder-open"
              title={status === 'draft' ? 'No active quotes.' : 'No completed quotes.'}
            />
          </CardBody>
        )}
        {!isFetching && !!quotes?.length && (
          <CardBody className="py-2 lg:py-2">
            <TablePagination {...(meta || {})} onPageChange={({ page }) => setPage(page)} />
          </CardBody>
        )}
      </Card>
      <Dialog isOpen={createQuoteModalIsOpen} onClose={() => setCreateQuoteModalIsOpen(false)} title="Create New Quote">
        <Formik
          onSubmit={async values => {
            await createQuote(values);
            trackEvent({
              object: 'souptools_quote',
              action: 'created',
              properties: { ...values }
            });
          }}
          initialValues={{ name: '' }}
          validationSchema={yup.object({
            name: yup.string().required('Name is required.')
          })}
        >
          <Form>
            <CardBody>
              <TextInput name="name" label="Quote Name" />
            </CardBody>
            <CardFooter>
              <Button type="submit" loading={isCreatingQuote} intent="success" text="Create Quote" />
            </CardFooter>
          </Form>
        </Formik>
      </Dialog>
    </>
  );
};
