import React from 'react';
import { ButtonGroup, Button } from '@blueprintjs/core';

type TablePaginationProps = {
  onPageChange: (args: { page: number }) => void;
  perPage: number;
  currentPage: number;
  total: number;
  maxResults?: number;
};

/**
 * @deprecated Use GovlyTable with GovlyTablePagination instead.
 */
export const TablePagination = ({
  total,
  currentPage,
  perPage,
  maxResults = undefined,
  onPageChange
}: TablePaginationProps) => {
  if (total === 0) {
    return null;
  }

  let displayableTotal = total;

  if (maxResults) {
    displayableTotal = total < maxResults ? total : maxResults;
  }

  const currentRangeEnd = Math.min(perPage * currentPage, total);
  const currentRangeStart = perPage * (currentPage - 1) + 1;
  const totalPages = Math.ceil(displayableTotal / perPage);

  return (
    <div className="flex items-center justify-between">
      <div className="text-muted">{`${currentRangeStart}-${currentRangeEnd} of ${displayableTotal}`}</div>
      <ButtonGroup alignText="center" large>
        <Button
          icon="chevron-backward"
          minimal
          disabled={currentPage === 1 || total === 0}
          onClick={() => onPageChange({ page: 1 })}
        />
        <Button
          icon="chevron-left"
          minimal
          disabled={currentPage === 1 || total === 0}
          onClick={() => onPageChange({ page: currentPage - 1 })}
        />
        <Button
          icon="chevron-right"
          minimal
          disabled={currentPage === totalPages || total === 0}
          onClick={() => onPageChange({ page: currentPage + 1 })}
        />
        <Button
          icon="chevron-forward"
          minimal
          disabled={currentPage === totalPages || total === 0}
          onClick={() => onPageChange({ page: totalPages })}
        />
      </ButtonGroup>
    </div>
  );
};
