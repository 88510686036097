import React from 'react';
import { useParams } from 'react-router-dom';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppDetailsProviderContactsTable } from 'app/organisms/OppDetailsProviderContactsTable/OppDetailsProviderContactsTable';
import { OppDetailsProvidersTable } from 'app/organisms/OppDetailsProvidersTable/OppDetailsProvidersTable';

export const OppDetailsProvidersPage = () => {
  const { id } = useParams();

  return (
    <MainColumn columnSpan={12}>
      <OppDetailsProvidersTable id={id ?? ''} />
      <OppDetailsProviderContactsTable id={id ?? ''} />
    </MainColumn>
  );
};
