import React from 'react';

import {
  useCreateRecordChannelPartnerMutation,
  useUpdateRecordChannelPartnerMutation,
  useDeleteRecordChannelPartnerMutation,
  useGetChannelPartnersQuery
} from 'api/channelPartnersApi';
import { VendorPartnersFormList } from 'app/organisms/VendorPartnersFormList/VendorPartnersFormList';

export const VendorChannelPartnersFormList = ({ vendorId }: { vendorId: string }) => (
  <VendorPartnersFormList
    vendorId={vendorId}
    recordTitle="Channel Partner"
    useGetList={useGetChannelPartnersQuery}
    useOnCreate={useCreateRecordChannelPartnerMutation}
    useOnUpdate={useUpdateRecordChannelPartnerMutation}
    useOnDelete={useDeleteRecordChannelPartnerMutation}
  />
);
