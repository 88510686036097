import { create } from 'zustand';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';

type State = {
  isDrawerOpen: boolean;
  lastClickedPoint?: Highcharts.Point;
};

type Action = {
  setIsDrawerOpen: (isDrawerOpen: State['isDrawerOpen']) => void;
};

type Store = State & Action;

export const useOppSearchAnalyticsBuyerStore = create<Store>(set => {
  return {
    lastClickedPoint: undefined,
    isDrawerOpen: false,

    setIsDrawerOpen: isDrawerOpen => set({ isDrawerOpen })
  };
});

export const buyerNameSelector = (store: Store): string => {
  const point = store.lastClickedPoint;
  if (!point) return 'Buyer';

  return point.name;
};

export const drilldownFiltersSelector = (store: Store): Partial<OppSearchState['filters']> => {
  const point = store.lastClickedPoint;
  if (!point) return {};

  const ids = 'id' in point ? [point.id as string] : [];

  return { buyerIds: ids };
};
