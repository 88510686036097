import { OrganizationLabel } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OrganizationLabel'] });

type GetOrganizationLabels = {
  params: void;
  result: OrganizationLabel[];
};

type UpdateOrganizationLabel = {
  params: Omit<OrganizationLabel, '__typename'>;
  result: OrganizationLabel;
};

type CreateOrganizationLabel = {
  params: Omit<OrganizationLabel, '__typename' | 'id'>;
  result: OrganizationLabel;
};

type DeleteOrganizationLabel = {
  params: string;
  result: void;
};

export const organizationLabelsApi = api.injectEndpoints({
  endpoints: build => ({
    getOrganizationLabels: build.query<GetOrganizationLabels['result'], GetOrganizationLabels['params']>({
      keepUnusedDataFor: 120,
      query: () => ({ url: '/v2/organization_labels' }),
      providesTags: ['OrganizationLabel']
    }),
    updateOrganizationLabel: build.mutation<UpdateOrganizationLabel['result'], UpdateOrganizationLabel['params']>({
      query: ({ id, ...rest }) => ({
        url: `/v2/organization_labels/${id}`,
        method: 'PATCH',
        body: rest
      }),
      invalidatesTags: ['OrganizationLabel']
    }),
    createOrganizationLabel: build.mutation<CreateOrganizationLabel['result'], CreateOrganizationLabel['params']>({
      query: body => ({
        url: `/v2/organization_labels`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['OrganizationLabel']
    }),
    deleteOrganizationLabel: build.mutation<DeleteOrganizationLabel['result'], DeleteOrganizationLabel['params']>({
      query: id => ({
        url: `/v2/organization_labels/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OrganizationLabel']
    })
  })
});

export const {
  useGetOrganizationLabelsQuery,
  useUpdateOrganizationLabelMutation,
  useCreateOrganizationLabelMutation,
  useDeleteOrganizationLabelMutation
} = organizationLabelsApi;
