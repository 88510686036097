import React, { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';
import { Tag, Checkbox, InputGroup, Button } from '@blueprintjs/core';
import { useVirtualizer } from '@tanstack/react-virtual';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';

import { SouptoolsQuoteClin } from 'app/organisms/SouptoolsQuoteClin/SouptoolsQuoteClin';
import { SouptoolsTrClin } from 'app/organisms/SouptoolsTrClin/SouptoolsTrClin';
import { useGetTrFileQuery, useExportTrFileMutation } from 'api/souptoolsTrFilesApi';
import { useExportSouptoolsClinFileMutation } from 'api/souptoolsQuotesApi';
import { successToast } from 'app/lib/toaster';
import { SouptoolsQuoteFull, SouptoolsTrFull, SouptoolsClinAlternativeClins } from 'types/__generated__/GovlyApi';

const ROW_HEIGHT_ESTIMATE = 170;

const quoteClinIsValid = ({
  salePrice,
  saleQuantity,
  clin
}: {
  salePrice: number;
  saleQuantity: number;
  clin: string;
}) => !!salePrice && !!saleQuantity && !!clin;

const trClinIsValid = () => true;

type SouptoolsClinTableProps = {
  clinable: SouptoolsQuoteFull | SouptoolsTrFull;
};

export const SouptoolsClinTable = ({ clinable }: SouptoolsClinTableProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { id, type, clins = [] } = clinable;
  const isQuote = type === 'Souptools::Quote';
  const [invalidOnly, setInvalidOnly] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedClins, setSelectedClins] = useState<SouptoolsClinAlternativeClins[]>([]);
  const [trExportRequestedAt, setTrExportRequestedAt] = useState<number>();

  const isValid = isQuote ? quoteClinIsValid : trClinIsValid;
  const [validClins, invalidClins] = partition(clins, isValid) as [
    SouptoolsClinAlternativeClins[],
    SouptoolsClinAlternativeClins[]
  ];
  const uncheckedClins = clins.filter(({ onContractData }) => isEmpty(onContractData));
  const recommendedClins = validClins.filter(({ onContractData: { status } }) =>
    ['Invalid Price', 'Excessive Discount', 'CLIN Not Found'].includes(status as string)
  );

  const allSelected = isEqual(validClins, selectedClins);
  const recommendedSelected = !isEmpty(recommendedClins) && isEqual(recommendedClins, selectedClins);

  const selectAll = (checked: boolean) => {
    setSelectedClins(checked ? validClins : []);
  };

  const selectRecommended = (checked: boolean) => {
    setSelectedClins(checked ? recommendedClins : []);
  };

  const { data } = useGetTrFileQuery(
    { id, type },
    {
      skip: !trExportRequestedAt,
      pollingInterval: 1000
    }
  );

  useEffect(() => {
    if (!data || !trExportRequestedAt) return;
    const trFileCreatedAt = Date.parse(data.createdAt ?? '');

    if (trFileCreatedAt >= trExportRequestedAt) {
      window.open(data.link, '_blank');
      setTrExportRequestedAt(undefined);
    }
  }, [data, trExportRequestedAt]);

  const [exportSelectedToClinFile, { isLoading: isExportingClinFile }] = useExportSouptoolsClinFileMutation();
  const [exportSelectedToTr] = useExportTrFileMutation();

  let clinArray = clins;

  if (invalidOnly) {
    clinArray = invalidClins;
  }

  const results = useFuzzySearch({
    data: clinArray,
    options: { keys: ['clin', 'itemdesc', 'onContractData.status'] },
    query
  });

  const selectedMap = keyBy(selectedClins, 'id');

  const rowVirtualizer = useVirtualizer({
    count: results.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT_ESTIMATE
  });

  const items = rowVirtualizer.getVirtualItems();

  return (
    <Card
      title={
        <div className="flex items-center gap-x-2">
          <CardHeading>{results.length === 1 ? 'CLIN' : 'CLINs'}</CardHeading>
          <Tag minimal>{results.length}</Tag>
          {!isEmpty(invalidClins) && (
            <Tag className="ml-1 text-center" minimal intent="danger">
              {`${invalidClins.length} Need Additional Info`}
            </Tag>
          )}
          {!isEmpty(uncheckedClins) && (
            <Tag className="ml-1 text-center" minimal intent="warning">
              Incomplete Checkfile
            </Tag>
          )}
        </div>
      }
      rightElement={
        <div>
          {!isEmpty(invalidClins) && isQuote && (
            <Checkbox
              inline
              checked={invalidOnly}
              label="Show Only Invalid"
              className="m-0 mr-2"
              onChange={() => setInvalidOnly(!invalidOnly)}
            />
          )}
          <InputGroup onChange={e => setQuery(e.target.value)} placeholder="Filter CLINs..." />
        </div>
      }
    >
      <CardBody className="flex items-center justify-between  space-y-0 bg-blue-50/50 lg:py-4">
        <>
          <div key="select" className="flex flex-wrap gap-x-4">
            {validClins.length !== recommendedClins.length && (
              <Checkbox
                label={recommendedSelected ? 'Deselect All' : `Select Recommended CLINs for ${!isQuote ? 'Re-' : ''}TR`}
                checked={recommendedSelected}
                onChange={({ target: { checked } }) => selectRecommended(checked)}
                disabled={isEmpty(recommendedClins)}
                className="m-0"
              />
            )}
            <Checkbox
              label={allSelected ? 'Deselect All' : 'Select All CLINs'}
              checked={allSelected}
              onChange={({ target: { checked } }) => selectAll(checked)}
              disabled={isEmpty(validClins)}
              className="m-0"
            />
          </div>
          {!isEmpty(selectedClins) && (
            <div className="flex-end flex flex-wrap gap-x-2">
              <Button
                small
                loading={!!trExportRequestedAt}
                intent="success"
                onClick={() => {
                  setTrExportRequestedAt(Date.now());
                  exportSelectedToTr({
                    id,
                    type,
                    clinIds: selectedClins.map(({ id }) => id)
                  }).unwrap();
                  successToast('Your download is being processed. Please stand by.');
                }}
              >
                {`Export ${selectedClins.length} CLINs to TR`}
              </Button>
              {isQuote && (
                <Button
                  small
                  loading={isExportingClinFile}
                  intent="primary"
                  onClick={async () => {
                    const { link } = await exportSelectedToClinFile({
                      id,
                      clinIds: selectedClins.map(({ id }) => id)
                    }).unwrap();

                    window.open(link, '_blank');
                  }}
                >
                  {`Export ${selectedClins.length} CLINs to Clin File`}
                </Button>
              )}
            </div>
          )}
        </>
      </CardBody>
      <div ref={parentRef} className="min-h-[40px] overflow-y-auto max-h-[640px]">
        <div className="relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0]?.start ?? 0}px)`
            }}
          >
            {items.map(virtualRow => (
              <CardBody className="lg:py-4" key={virtualRow.index} ref={rowVirtualizer.measureElement}>
                {isQuote ? (
                  <SouptoolsQuoteClin
                    clin={results[virtualRow.index]}
                    onSelect={({ target: { checked } }) => {
                      if (checked) {
                        setSelectedClins([...selectedClins, results[virtualRow.index]]);
                      } else {
                        setSelectedClins(selectedClins.filter(({ id }) => id !== results[virtualRow.index].id));
                      }
                    }}
                    selected={!!selectedMap[results[virtualRow.index].id]}
                  />
                ) : (
                  <SouptoolsTrClin
                    clin={results[virtualRow.index]}
                    onSelect={({ target: { checked } }) => {
                      if (checked) {
                        setSelectedClins([...selectedClins, results[virtualRow.index]]);
                      } else {
                        setSelectedClins(selectedClins.filter(({ id }) => id !== results[virtualRow.index].id));
                      }
                    }}
                    selected={!!selectedMap[results[virtualRow.index].id]}
                  />
                )}
              </CardBody>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};
