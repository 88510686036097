import React from 'react';

import {
  useCreateRecordResellerMutation,
  useUpdateRecordResellerMutation,
  useDeleteRecordResellerMutation,
  useGetResellersQuery
} from 'api/resellersApi';
import { VendorPartnerForm } from 'app/organisms/VendorPartnerForm/VendorPartnerForm';

type VendorResellerFormProps = {
  vendorId: string;
};

export const VendorResellerForm = ({ vendorId }: VendorResellerFormProps) => (
  <VendorPartnerForm
    vendorId={vendorId}
    recordTitle="Reseller"
    initialValues={{ resellerableId: vendorId, resellerableType: 'Vendor' }}
    useOnCreate={useCreateRecordResellerMutation}
    useOnUpdate={useUpdateRecordResellerMutation}
    useOnDelete={useDeleteRecordResellerMutation}
    useGetList={useGetResellersQuery}
  />
);
