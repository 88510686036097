import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { DrawerNavTab } from 'app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useAuthenticatedNavItems } from './useAuthenticatedNavItems';

export const useAppDrawerNavItems = (): DrawerNavTab[] => {
  const [searchParams] = useSearchParams();
  const drawerType = searchParams.get(DRAWER_PARAMS.type);

  const data = useAuthenticatedNavItems();

  return useMemo(() => {
    if (!drawerType) return [];
    return data.drawerNavs.find(drawerNav => drawerNav.drawerType === drawerType)?.children ?? [];
  }, [data.drawerNavs, drawerType]);
};
