import { User } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['SysAdminUsers'] });

type CreateSysAdmin = {
  params: { email: string };
  result: User;
};

type ListSysAdmins = {
  params: void;
  result: User[];
};

export const sysAdminUsers = api.injectEndpoints({
  endpoints: build => ({
    createSysAdmin: build.mutation<CreateSysAdmin['result'], CreateSysAdmin['params']>({
      query: ({ email }) => ({
        url: `/v2/sys_admin_users`,
        method: 'POST',
        body: { email }
      }),
      invalidatesTags: ['SysAdminUsers']
    }),
    listSysAdmins: build.query<ListSysAdmins['result'], ListSysAdmins['params']>({
      query: () => `/v2/sys_admin_users`,
      providesTags: () => ['SysAdminUsers']
    })
  })
});

export const { useCreateSysAdminMutation, useListSysAdminsQuery } = sysAdminUsers;
