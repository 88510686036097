import React, { useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { LineChart } from 'react-chartkick';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { ReportReport } from 'types/__generated__/GovlyApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useGetReportQuery } from 'api/reportsApi';
import { useReportSlices, ReportSlice } from 'app/hooks/useReportSlices';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { LineChartData } from 'react-chartkick';

const chartOptions = {
  xAxis: {
    type: 'datetime'
  },
  tooltip: {
    shared: true
  }
};

type OrganizationInsightsOppInteractionsTimeline = {
  title: string;
  filters: {
    interaction: 'matched_at' | 'shared_at' | 'viewed_at';
    orgUserId: string;
    dateRange: [Date, Date];
  };
};

export const OrganizationInsightsOppInteractionsTimeline = ({
  filters,
  title
}: OrganizationInsightsOppInteractionsTimeline) => {
  const slices = useReportSlices();
  if (filters.orgUserId === 'all_users') slices.push({ key: 'userSlice', label: 'Users' });

  const [view, setView] = useState<ReportSlice>('noSlice');
  const { data, isFetching, isError } = useGetReportQuery({
    report_type: 'Report::OrganizationOppInteractionsReport',
    ...filters
  });

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!data || isFetching) {
    return <Loading />;
  }
  const report = data as ReportReport;
  const dates = Object.keys(report.series);

  let chartData: LineChartData = dates.map(stage => [stage, report.series[stage].count] as [string, number]);
  if (view !== 'noSlice') {
    chartData = Object.keys(report[view]).map(key => ({
      name: key,
      data: Object.entries(report[view][key])
    }));
  }

  return (
    <ErrorBoundary action="OrganizationInsightsOppInteractionsTimeline" fallback={<CardError title={title} />}>
      <Card className="mb-4" title={title}>
        <CardBody>
          <LineChart data={chartData} library={chartOptions} />
        </CardBody>
        <CardBody className="text-center">
          <ButtonGroup>
            {slices.map(({ key, label }) => (
              <Button key={key} active={view === key} onClick={() => setView(key)} text={label} />
            ))}
          </ButtonGroup>
        </CardBody>
      </Card>
    </ErrorBoundary>
  );
};
