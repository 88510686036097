import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { useOppSearchResults } from 'api/oppsApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchResult } from 'app/organisms/OppSearchResult/OppSearchResult';

export const OppSearchResults = () => {
  const { data: { results = [] } = {}, isLoading, isUninitialized } = useOppSearchResults();

  if (isLoading || isUninitialized) {
    return <Loading type="card-list" />;
  }

  if (!results.length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="search"
            title="No search results"
            description={
              <div>
                {`Your search didn't match any opportunities.`}
                <br />
                {`Try adjusting your search filters.`}
              </div>
            }
          />
        </CardBody>
      </Card>
    );
  }

  return results.map(opp => <OppSearchResult key={opp.id} id={opp.id} />);
};
