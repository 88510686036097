import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import snakeCase from 'lodash-es/snakeCase';
import MapboxMap, { Layer, Source } from 'react-map-gl';
import { useLazyAwardAggregationQuery } from 'api/awardsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { colors } from 'app/lib/colors';
import { AwardSearchAnalyticsTableDrawer } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsTableDrawer';
import {
  MapAgg,
  useAwardSearchAnalyticsMapStore
} from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsMapStore';
import { useAwardSearchAnalyticsMapEvents } from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsMapEvents';
import { useIntersectionObserver } from 'app/hooks/useIntersectionObserver';
import { getBordersKey, getColorRange, getFillsKey, groupColorRange, MAPBOX_STYLE_URL } from 'app/lib/mapbox';
import { ChoroplethMapLegend } from 'app/molecules/ChoroplethMapLegend/ChoroplethMapLegend';
import { AwardSearchAnalyticsFallback } from './AwardSearchAnalyticsFallback';
import { TILE_LAYERS, ZOOM_THRESHHOLD, getAggInfo } from './mapUtils';

type Agg = {
  buckets: {
    key: string;
    docCount: number;
  }[];
};

type AggregationQueryResponse = {
  aggs?: Record<MapAgg, { unitedStates: Agg }>;
};

export const AwardSearchAnalyticsMap = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const { values } = useFormikContext<AwardSearchForm>();
  const { query, filters } = values;
  const cardRef = React.useRef<HTMLDivElement>(null);
  const { hasIntersected: hasCardBeenShown } = useIntersectionObserver(cardRef);

  const {
    aggregation,
    isMapReady,
    setIsMapReady,
    popup,
    drawerIsOpen,
    setDrawerIsOpen,
    clickedFeatureRef,
    mapRef,
    viewState,
    setViewState
  } = useAwardSearchAnalyticsMapStore();

  const [getAwardAggregation, { data: wrongTypeData, isLoading, isError, isUninitialized, isFetching }] =
    useLazyAwardAggregationQuery();
  const data = wrongTypeData as AggregationQueryResponse | undefined;

  useAwardSearchAnalyticsMapEvents();

  const setDocCounts = useCallback((result?: AggregationQueryResponse) => {
    if (!result) return;

    const { aggregation, mapRef } = useAwardSearchAnalyticsMapStore.getState();
    const map = mapRef.current;
    if (!map) return;

    const { source, sourceLayer, getFeatureKey } = TILE_LAYERS[aggregation];
    const agg = result.aggs?.[aggregation]?.unitedStates;
    const locations = new Map(agg?.buckets.map(b => [b.key, b]));
    const features = map.querySourceFeatures(source, { sourceLayer }) ?? [];

    features.forEach((feature, i) => {
      map.setFeatureState(
        { id: feature.id ?? `feature-${i}`, source, sourceLayer },
        { docCount: locations.get(getFeatureKey(feature))?.docCount || 0 }
      );
    });
  }, []);

  useEffect(() => {
    return () => {
      useAwardSearchAnalyticsMapStore.setState({ isMapReady: false });
    };
  }, []);

  useEffect(() => {
    if (searchIsLoading || !isMapReady) {
      return;
    }

    getAwardAggregation(
      {
        query,
        ...filters,
        aggs: [snakeCase(aggregation)]
      },
      true /* preferCache */
    )
      .unwrap()
      .then(result => {
        setDocCounts(result as AggregationQueryResponse);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAwardAggregation, searchIsLoading, aggregation, isMapReady]);

  if (isError) {
    return <AwardSearchAnalyticsFallback />;
  }

  const { drilldownFilters, title, featureLabel } = getAggInfo(aggregation, clickedFeatureRef.current);

  return (
    <ErrorBoundary action="AwardSearchAnalyticsMap" fallback={<CardError title={title} />}>
      <Card
        title={title}
        rightElement={isLoading || isUninitialized || isFetching ? <Loading type="spinner" /> : undefined}
      >
        <CardSection ref={cardRef}>
          {hasCardBeenShown && (
            <MapboxMap
              {...viewState}
              style={{ height: '500px' }}
              ref={mapRef}
              onLoad={() => setIsMapReady(true)}
              mapboxAccessToken={process.env.MAPBOX_PUBLIC_KEY}
              mapStyle={MAPBOX_STYLE_URL}
              onMove={e => {
                setDocCounts(data);
                setViewState(e.viewState);
              }}
              onZoomEnd={e => {
                setDocCounts(data);

                useAwardSearchAnalyticsMapStore.setState({
                  aggregation: e.viewState.zoom >= ZOOM_THRESHHOLD ? 'usPopCdAgg' : 'usPopRegionAgg',
                  clickedFeatureRef: { current: null }
                });
              }}
            >
              {popup}

              {Object.values(TILE_LAYERS).map(tileLayer => {
                const { source, url, sourceProps, sourceLayer, colorScheme, agg, filter } = tileLayer;
                const colorRange = getColorRange({
                  color: colorScheme,
                  data: getDocCounts(data as AggregationQueryResponse, agg)
                });

                return (
                  <Source key={source} id={source} type="vector" url={url} {...sourceProps}>
                    <Layer
                      {...{
                        id: getFillsKey(source),
                        type: 'fill',
                        source,
                        'source-layer': sourceLayer,
                        filter,
                        paint: {
                          'fill-color': ['step', ['feature-state', 'docCount'], ...colorRange],
                          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.75]
                        }
                      }}
                    />

                    <Layer
                      {...{
                        id: getBordersKey(source),
                        type: 'line',
                        source,
                        'source-layer': sourceLayer,
                        paint: {
                          'line-color': colors.gray['300'],
                          'line-width': 2
                        }
                      }}
                    />
                  </Source>
                );
              })}

              <ChoroplethMapLegend
                title="Total Awards"
                isLoading={isFetching || isLoading || !isMapReady}
                colorGroups={groupColorRange(
                  getColorRange({
                    color: TILE_LAYERS[aggregation].colorScheme,
                    data: getDocCounts(data, aggregation)
                  })
                )}
              />
            </MapboxMap>
          )}
        </CardSection>
      </Card>

      <AwardSearchAnalyticsTableDrawer
        id="awards_map_table"
        isOpen={drawerIsOpen}
        drawerTitle={featureLabel}
        onClose={() => {
          setDrawerIsOpen(false);
        }}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};

const getDocCounts = (data: AggregationQueryResponse = {}, agg: MapAgg) =>
  data?.aggs?.[agg]?.unitedStates.buckets.map(b => b.docCount) ?? [];
