import React, { useRef, useEffect } from 'react';
import { useField } from 'formik';
import { FormGroup, TextArea, FormGroupProps, TextAreaProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

type TextAreaInputProps = {
  name: string;
  inputProps?: TextAreaProps;
} & FormGroupProps;

export const TextAreaInput = ({
  name,
  className,
  helperText,
  disabled,
  inline,
  label,
  labelInfo,
  inputProps = {},
  ...rest
}: TextAreaInputProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [field, meta] = useField({ name });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.height = '0';
    ref.current.style.height = Math.max(80, ref.current.scrollHeight) + 'px';
  }, [ref, field.value]);
  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      inline={inline}
      label={label}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      {...rest}
    >
      <TextArea
        {...field}
        id={name}
        name={name}
        disabled={disabled}
        intent={intent}
        inputRef={ref}
        growVertically
        fill
        {...inputProps}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
