import groupBy from 'lodash-es/groupBy';
import maxBy from 'lodash-es/maxBy';
import { OppSourceShow } from 'types/__generated__/GovlyApi';

export function getLatestSequencedNoticeTypes(sequencedNoticeTypes: OppSourceShow['sequencedNoticeTypes']) {
  const groupedNoticeTypes = groupBy(sequencedNoticeTypes, 'noticeType');
  const latestSequencedNoticeTypes = Object.values(groupedNoticeTypes)
    .map(nts => maxBy(nts, 'count'))
    .filter(nt => nt != null)
    .map(({ count, noticeType }) => {
      return count > 0 ? `${noticeType} (Update ${count})` : noticeType;
    });

  return latestSequencedNoticeTypes;
}
