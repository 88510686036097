import React from 'react';
import { MenuItem, MenuItemProps, Button } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';

import { highlightText } from 'app/lib/highlightText';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';

import { TypedUseQuery } from '@reduxjs/toolkit/query/react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type InputMultiSelectHook<Item = any> = (...args: any[]) => {
  data?: Item[] | undefined;
  isLoading: boolean;
};

export type GetHookItem<Hook extends InputMultiSelectHook> =
  Hook extends TypedUseQuery<infer Result, any, any>
    ? Result extends Array<infer Item>
      ? Item
      : 'INVALID_QUERY'
    : Hook extends (...args: any[]) => { data?: infer Items }
      ? Items extends Array<infer Item>
        ? Item
        : 'INVALID_ARRAY'
      : 'INVALID_DATA';
/* eslint-enable @typescript-eslint/no-explicit-any */

export const defaultRenderItem = ({
  options,
  menuItemProps: { key, ...menuItemProps }
}: {
  options: ItemRendererProps;
  menuItemProps: MenuItemProps;
}) => {
  if (!options.modifiers.matchesPredicate) {
    return null;
  }

  return <MenuItem key={key} {...menuItemProps} />;
};

export function defaultMenuItemProps<Item>({
  getItemTextLabel,
  getItemLabel,
  getItemValue,
  selectedItems,
  item,
  options
}: {
  getItemTextLabel: (item: Item) => string;
  getItemLabel?: (item: Item) => React.ReactNode;
  getItemValue: (item: Item) => string | number;
  selectedItems: Item[];
  item: Item;
  options: ItemRendererProps;
}): MenuItemProps {
  const text = getItemTextLabel(item);
  const key = getItemValue(item);
  const selected = selectedItems.some((f: Item) => getItemValue(f) === getItemValue(item));

  return {
    active: options.modifiers.active,
    disabled: options.modifiers.disabled,
    key,
    roleStructure: 'listoption' as const,
    shouldDismissPopover: false,
    onClick: options.handleClick,
    onFocus: options.handleFocus,
    ref: options.ref,
    text: getItemLabel ? getItemLabel(item) : highlightText(text, options.query),
    selected
  };
}

export const DefaultNoResults = ({ loading }: { loading: boolean | undefined }) =>
  loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

export function defaultTagInputProps<Item>({
  error,
  selectedItems,
  onChange
}: {
  error?: string | undefined;
  selectedItems: Item[];
  onChange: (items: Item[]) => void;
}) {
  return {
    fill: true,
    tagProps: { minimal: true, 'data-testid': 'input-multi-select-tag' },
    intent: error ? ('danger' as const) : ('none' as const),
    onRemove: (_tag: React.ReactNode, index: number) => defaultOnItemDeselect(index, selectedItems, onChange),
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') e.preventDefault();
    },
    rightElement:
      selectedItems.length > 0 ? (
        <Button
          icon="cross"
          minimal
          onClick={e => {
            onChange([]);
            e.stopPropagation();
          }}
        />
      ) : undefined
  };
}

export function defaultOnItemSelect<Item>(
  item: Item,
  selected: Item[],
  onChange: (item: Item[]) => void,
  getItemTextLabel: (item: Item) => string
) {
  const existingIndex = selected.findIndex((f: Item) => getItemTextLabel(f) === getItemTextLabel(item));
  if (existingIndex !== -1) {
    defaultOnItemDeselect(existingIndex, selected, onChange);
  } else {
    const newItems = [...selected, item];
    return onChange(newItems);
  }
}

export function defaultOnItemDeselect<Item>(index: number, selected: Item[], onChange: (item: Item[]) => void) {
  const newItems = selected.filter((_: Item, i: number) => i !== index);

  return onChange(newItems);
}
