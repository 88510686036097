import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import keyBy from 'lodash-es/keyBy';
import { createColumnHelper } from '@tanstack/react-table';

import { ProviderFeedIndex } from 'types/__generated__/GovlyApi';
import { useGetPartnershipQuery } from 'api/partnershipsApi';
import { useGetPartnerFeedsQuery, useBulkCreatePartnerFeedsMutation } from 'api/partnerFeedsApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';

import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

import { GovlyTableTitle } from 'app/molecules/GovlyTable/GovlyTableTitle';
import { PartnerFeedTableCheckbox } from './PartnerFeedTableCheckbox';

type ExtendedProviderFeedIndex = ProviderFeedIndex & { authFeedId?: string; shared: boolean };
const columnHelper = createColumnHelper<ExtendedProviderFeedIndex>();

export const PartnerFeeds = () => {
  const { id: partnershipId } = useParams();
  const { data: { partnerId } = {}, isLoading: partnerLoading } = useGetPartnershipQuery(
    { id: partnershipId ?? '' },
    { skip: !partnershipId }
  );
  const { data: partnerFeeds = [], isLoading: authFeedsLoading } = useGetPartnerFeedsQuery({
    partnerId
  });
  const { data: managedFeeds = [], isLoading: managedFeedsLoading } = useGetProviderFeedsQuery();
  const [bulkCreate, { isLoading: isBulkCreating }] = useBulkCreatePartnerFeedsMutation();

  const feeds: ExtendedProviderFeedIndex[] = useMemo(() => {
    const authFeedsIndex = keyBy(partnerFeeds, 'providerFeedId');

    return managedFeeds.map(feed => {
      const authFeed = authFeedsIndex[feed.id];
      return { ...feed, authFeedId: authFeed?.publicId, shared: !!authFeed };
    });
  }, [managedFeeds, partnerFeeds]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'feedName',
        header: 'Feed Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('shared', {
        header: 'Shared?',
        sortingFn: 'basic',
        enableColumnFilter: false,
        cell: e => {
          if (!partnershipId || !partnerId) {
            return null;
          }

          const { authFeedId, publicId } = e.row.original;

          return (
            <div className="flex">
              <PartnerFeedTableCheckbox
                partnershipId={partnershipId}
                organizationId={partnerId}
                id={authFeedId}
                providerFeedPublicId={publicId}
              />
            </div>
          );
        }
      })
    ],
    [partnerId, partnershipId]
  );

  const bulkCreateParams = useMemo(() => {
    if (!partnerId) return [];

    return feeds
      .filter(({ authFeedId }) => !authFeedId)
      .map(({ publicId }) => ({
        providerFeedPublicId: publicId,
        partnershipId,
        organizationId: partnerId
      }));
  }, [feeds, partnerId, partnershipId]);

  return (
    <GovlyTable
      id="partner_feeds_table"
      columns={columns}
      data={feeds}
      title={<GovlyTableTitle title="Shared Feeds" />}
      subtitle="A list of all the feeds you manage in Govly and whether they should be shared with this partner"
      rightElement={
        <GovlyTableToolbar>
          <div key="share-all" className="sm:flex-none">
            <Button
              loading={isBulkCreating}
              disabled={isBulkCreating || bulkCreateParams.length === 0}
              onClick={() => bulkCreate({ partnerId, partnerFeeds: bulkCreateParams })}
              intent="primary"
              text="Share all"
            />
          </div>
        </GovlyTableToolbar>
      }
      emptyStateProps={{
        icon: 'warning-sign',
        title: 'No feeds configured',
        description: 'You are not feeding data into Govly.'
      }}
      isLoading={managedFeedsLoading || authFeedsLoading || partnerLoading}
      initialState={{
        sorting: [{ id: 'feedName', desc: false }],
        pagination: { pageSize: 50 }
      }}
    />
  );
};
