import React, { useState } from 'react';
import { Card as BPCard, Button, Callout, CardList, Icon } from '@blueprintjs/core';
import { useOppWorkspaceStore } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { useGetWorkspaceAttachmentsQuery } from 'api/workspacesAttachmentsApi';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from 'app/organisms/OppWorkspaceAttachmentsCard/utils';
import { useConvertWorkspaceAttachmentToQuote } from 'app/organisms/WorkspaceAttachmentToolsButton/useConvertWorkspaceAttachmentToQuote';
import { WorkspaceAttachmentShow } from 'types/__generated__/GovlyApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useActiveStorageOnDrop } from 'app/hooks/useActiveStorageOnDrop';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { cn } from 'app/lib/cn';
import { useGetQuotesForWorkspaceQuery } from 'api/quotesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { FileInputTarget } from 'app/atoms/FileInputTarget/FileInputTarget';

export const WorkspaceToolboxQuoteButton = () => {
  const setToolboxDialogProps = useOppWorkspaceStore(s => s.setToolboxDialogProps);

  return (
    <Button
      icon={<Icon icon="dollar" className="text-green-500" />}
      minimal
      onClick={() => {
        setToolboxDialogProps({
          isOpen: true,
          title: 'Convert a quote',
          children: <DrawerBody />
        });
      }}
    >
      Convert a quote
    </Button>
  );
};

type DropItem = { files: File[] };

const DrawerBody = () => {
  const setToolboxDialogProps = useOppWorkspaceStore(s => s.setToolboxDialogProps);
  const { data: currentUser } = useGetCurrentUserQuery();
  const workspaceId = useOppWorkspaceStore(s => s.workspaceId);
  const { data: quotes, isLoading: isLoadingQuotes } = useGetQuotesForWorkspaceQuery({ workspaceId });
  const { data: attachments = [], isLoading: isLoadingAttachments } = useGetWorkspaceAttachmentsQuery({
    id: workspaceId
  });

  const isLoadingDeps = isLoadingQuotes || isLoadingAttachments;

  const existingQuoteFileSignedIds = new Set(quotes?.map(q => q.file?.signedId));
  const existingAttachmentsSignedIds = new Set<string | undefined>(attachments.map(a => a.attachment.signedId));

  const [convertToQuote, createQuoteMeta] = useConvertWorkspaceAttachmentToQuote();

  const [formState, setFormState] = useState<'idle' | 'uploading'>('idle');
  const isUploadingDisabled = formState === 'uploading' || createQuoteMeta.isLoading;

  const getIsLoading = (attachment?: WorkspaceAttachmentShow) => {
    const { originalArgs } = createQuoteMeta;
    const signedIdCheck = attachment
      ? originalArgs?.file === attachment.attachment.signedId
      : // no existing signed id implies it's an upload and convert
        !existingAttachmentsSignedIds.has(originalArgs?.file);
    return isUploadingDisabled && signedIdCheck;
  };

  const handleInitialize = () => {
    setFormState('uploading');
  };

  const { onDrop } = useActiveStorageOnDrop({
    uploaderId: workspaceId,
    onInitialize: handleInitialize,
    onAttach: async f => {
      await convertToQuote(f.signedId);
      setFormState('idle');
      setToolboxDialogProps({ isOpen: false });
    }
  });

  const [{ canDrop, isOver }, ref] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: DropItem) {
        onDrop(item.files);
      },
      canDrop(_item: DropItem) {
        return !currentUser?.compliancePreventUploads && !isUploadingDisabled;
      },
      collect: (monitor: DropTargetMonitor<DropItem>) => {
        return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
      }
    }),
    [currentUser?.compliancePreventUploads, isUploadingDisabled]
  );

  const isActive = canDrop && isOver;

  return (
    <Card>
      <CardBody className="flex space-y-0 flex-col gap-y-4 items-center">
        {isLoadingDeps ? (
          <CardList>
            {Array.from({ length: 3 }).map((_, i) => (
              <BPCard key={i} className="flex justify-between items-center w-full">
                <Loading type="text">Loading...</Loading>
                <Loading type="text">Convert</Loading>
              </BPCard>
            ))}
          </CardList>
        ) : (
          <>
            <Callout icon="info-sign">Extract quote information like line items from documents</Callout>

            <div className="flex flex-col gap-y-2 items-center w-full">
              {attachments.length > 0 ? (
                <>
                  <CardList compact className="max-h-56 overflow-y-auto">
                    {attachments.map(a => (
                      <AttachmentEntityTitle
                        key={a.id}
                        {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(a)}
                        actionsButtonGroup={
                          <Button
                            onClick={async () => {
                              await convertToQuote(a.attachment.signedId);
                              setToolboxDialogProps({ isOpen: false });
                            }}
                            loading={getIsLoading(a)}
                            {...(existingQuoteFileSignedIds.has(a.attachment.signedId)
                              ? {
                                  disabled: true,
                                  icon: 'tick',
                                  intent: 'success',
                                  text: 'Converted'
                                }
                              : {
                                  icon: 'lightning',
                                  intent: 'primary',
                                  text: 'Convert'
                                })}
                          />
                        }
                      />
                    ))}
                  </CardList>
                  <span className="text-sm text-gray-500">OR</span>
                </>
              ) : null}

              <div
                className={cn(
                  'border border-dashed border-gray-200 rounded p-4 flex flex-col items-center gap-y-2 w-full relative',
                  {
                    'border-blue-500': isActive
                  }
                )}
                ref={ref}
              >
                {getIsLoading() ? (
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                    <Icon icon="resolve" className="animate-spin" />
                  </div>
                ) : null}
                {currentUser?.compliancePreventUploads ? undefined : (
                  <div className="flex flex-col gap-y-2 items-center">
                    <span className="text-sm font-medium">Upload and convert</span>
                    <span className="flex items-center gap-x-px">
                      <span className="text-sm text-gray-500">Drop files here or</span>
                      <FileInputTarget disabled={isUploadingDisabled} onFileChange={onDrop}>
                        <Button minimal small text="Browse" className="px-1" intent="primary" />
                      </FileInputTarget>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};
