import React, { ReactElement } from 'react';

import { cn } from 'app/lib/cn';
import { Card, CardProps, CardSection } from 'app/atoms/Card/Card';
import { Text } from 'app/atoms/Typography/Typography';

import { CardSectionProps } from 'app/atoms/Card/Card';
import { CollapseProps } from '@blueprintjs/core';

export type CardListItemProps = CardProps & {
  wrapperClassName?: string;
  defaultIsOpen?: boolean;
  cardSectionProps?: CardSectionProps;
  collapseProps?: CollapseProps;
};

type Children = ReactElement<CardListItemProps> | boolean | null | undefined | Children[];

export type CardListProps = Omit<CardProps, 'children'> & {
  wrapperClassName?: string;
  children: Children;
};

export const CardList = ({ children, className, wrapperClassName, ...rest }: CardListProps) => {
  return (
    <Card role="list" className={cn({ 'has-[>div:empty]:hidden': !rest.title }, wrapperClassName)} {...rest}>
      <CardSection padded={false} className={cn('[&>*]:rounded-none [&>*]:border-0', className)}>
        {children}
      </CardSection>
    </Card>
  );
};

export const CardListItem = React.forwardRef<HTMLDivElement, CardListItemProps>(
  (
    {
      children,
      wrapperClassName,
      className,
      defaultIsOpen = false,
      collapseProps,
      collapsible,
      title,
      cardSectionProps = {},
      ...rest
    }: CardListItemProps,
    ref
  ) => (
    <Card
      ref={ref}
      titleRenderer={Text}
      collapseProps={{ defaultIsOpen, ...collapseProps }}
      className={cn('[&>*]:border-0', wrapperClassName)}
      collapsible={collapsible}
      title={title}
      {...rest}
    >
      {children && (
        <CardSection className={cn(className)} {...cardSectionProps}>
          {children}
        </CardSection>
      )}
    </Card>
  )
);

CardListItem.displayName = 'CardListItem';
