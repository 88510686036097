import React from 'react';
import { Button } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useResetRackAttackMutation, useResetFeedProfilesMutation } from 'api/sysAdminSettingsApi';
import { successToast } from 'app/lib/toaster';

export const SysAdminSettings = () => {
  const [resetRackAttack, { isLoading: isResettingRackAttack }] = useResetRackAttackMutation();
  const [resetFeedProfiles, { isLoading: isResettingFeedProfiles }] = useResetFeedProfilesMutation();
  const feedProfiles = ['Unison', 'Chess', 'SewpV', 'Gsa'];

  return (
    <Card>
      {feedProfiles.map(profile => (
        <CardBody key={profile}>
          <Button
            onClick={() => {
              resetFeedProfiles({ type: profile });
              successToast(`Reset All ${profile} Feed Profiles`);
            }}
            disabled={isResettingFeedProfiles}
            intent="warning"
            text={`Reset All ${profile} Feed Profiles`}
          />
        </CardBody>
      ))}
      <CardBody>
        <Button
          onClick={() => resetRackAttack()}
          loading={isResettingRackAttack}
          intent="danger"
          text="Reset throttled ips"
        />
      </CardBody>
    </Card>
  );
};
