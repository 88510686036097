import { useEffect, useMemo } from 'react';
import { asCurrencyCompact } from 'app/lib/numbers';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import {
  SliceType,
  useAwardSearchAnalyticsHistogramStore
} from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsHistogramStore';
import { useFormikContext } from 'formik';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { addMonths } from 'date-fns';
import { USASpendingAwardSearch } from 'types/__generated__/GovlyApi';

type AggBucket = {
  key: string;
  keyAsString: string;
  docCount: number;
  totalAmount: { value: number };
};

const AGG_FIELD_MAP = {
  actionDateAwardingAgencyAgg: 'awardingAgencyName',
  actionDateFundingAgencyAgg: 'fundingAgencyName',
  actionDateRecipientAgg: 'recipientName',
  actionDateSetAsideAgg: 'typeOfSetAside'
} as const satisfies { [key in Exclude<SliceType, 'actionDateTotalAgg'>]: keyof USASpendingAwardSearch };

type AggField = (typeof AGG_FIELD_MAP)[keyof typeof AGG_FIELD_MAP];

type SubAgg = {
  buckets: AggBucket[];
};

type AggWithSubAggsBucket = AggBucket & { [key in AggField]?: SubAgg };

type AggWithSubAggs = {
  buckets: AggWithSubAggsBucket[];
};

type AggregationQueryResponse = {
  aggs: {
    [key in SliceType]: AggWithSubAggs;
  };
};

const extractSeriesData = (
  agg: AggWithSubAggs | undefined,
  sliceType: SliceType,
  view?: 'total' | 'amount'
): Highcharts.SeriesLineOptions[] => {
  if (!agg) {
    return [{ type: 'line', name: 'Total', data: [] }];
  }

  if (sliceType === 'actionDateTotalAgg') {
    const mappedData = agg.buckets.map(bucket => {
      const value = view === 'amount' ? Math.floor(bucket.totalAmount.value) : bucket.docCount;
      const point: [string, number] = [bucket.key, value];
      return point;
    });
    return [{ type: 'line', name: 'Total', data: mappedData }];
  } else {
    const aggMap: Record<string, [string, number][]> = {};

    agg.buckets.forEach(bucket => {
      const dateKey = bucket.key;
      const aggField = AGG_FIELD_MAP[sliceType];
      const subAgg = bucket[aggField];

      subAgg?.buckets.forEach(subBucket => {
        const value = view === 'amount' ? Math.floor(subBucket.totalAmount.value) : subBucket.docCount;
        aggMap[subBucket.key] = [...(aggMap[subBucket.key] || []), [dateKey, value]];
      });
    });

    return Object.entries(aggMap)
      .map(([name, data]) => ({ type: 'line' as const, name, data }))
      .sort((a, b) => {
        const sumA = a.data?.reduce((acc, [_, value]) => acc + value, 0);
        const sumB = b.data?.reduce((acc, [_, value]) => acc + value, 0);
        return sumB - sumA;
      });
  }
};

export const useAwardSearchAnalyticsHistogramOptions = ({ data }: { data: unknown }) => {
  const { sliceType, view } = useAwardSearchAnalyticsHistogramStore();
  const {
    values: {
      filters: {
        dateRange: [startDate]
      }
    }
  } = useFormikContext<AwardSearchForm>();
  const startTime = addMonths(startDate ?? new Date('2020-01-01'), -1).getTime();

  useEffect(() => {
    return () => {
      useAwardSearchAnalyticsHistogramStore.setState(current => ({ ...current, lastClickedPoint: undefined }));
    };
  }, []);

  return useMemo<Highcharts.Options>(() => {
    const agg = (data as AggregationQueryResponse).aggs?.[sliceType];
    const extractedSeriesData = extractSeriesData(agg, sliceType, view);
    const enableLegend = sliceType !== 'actionDateTotalAgg';

    return {
      title: {
        text: undefined
      },
      legend: {
        enabled: enableLegend,
        maxHeight: 100
      },
      time: {
        useUTC: true
      },
      chart: {
        marginLeft: 58,
        height: enableLegend ? 500 : 400,
        zooming: {
          singleTouch: true,
          type: 'xy' as const
        }
      },
      series: extractedSeriesData,
      plotOptions: {
        series: {
          point: {
            events: {
              click: function () {
                useAwardSearchAnalyticsHistogramStore.setState(current => ({
                  ...current,
                  isDrawerOpen: true,
                  lastClickedPoint: this
                }));
              }
            }
          }
        }
      },
      tooltip: {
        shared: false,
        valuePrefix: view === 'amount' ? '$' : '',
        valueSuffix: view === 'amount' ? '' : ' awards',
        pointFormat: sliceType
          ? '<span style="color:{series.color}">\u25CF</span> {series.name} <b>{point.y}</b>'
          : '<b>{point.y}</b>'
      },
      xAxis: {
        type: 'datetime',
        min: startTime,
        title: {
          text: undefined
        }
      },
      yAxis: {
        title: {
          text: undefined
        },
        labels: {
          formatter: function (): string {
            const dis = this as unknown as { value: number };
            return (view === 'amount' ? '$' : '') + asCurrencyCompact(dis.value);
          }
        }
      }
    };
  }, [data, sliceType, startTime, view]);
};
