import React from 'react';
import { colors } from 'app/lib/colors';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';

import { ChartDataPointIndex } from 'types/__generated__/GovlyApi';
import { ComboGraph } from 'app/molecules/ComboGraph/ComboGraph';

type USSpendingComboGraphProps = {
  newAwardsOverTime?: Pick<ChartDataPointIndex, 'y' | 'xStr'>[];
  spendingOverTime?: Pick<ChartDataPointIndex, 'y' | 'xStr'>[];
};

export const USSpendingComboGraph = ({ newAwardsOverTime = [], spendingOverTime = [] }: USSpendingComboGraphProps) => {
  const hasNewAwards = newAwardsOverTime.length > 0;
  const hasSpendingData = spendingOverTime.length > 0;

  const yAxis = [];

  const series: Highcharts.Options['series'] = [];

  if (hasSpendingData) {
    yAxis.push({
      min: 0,
      max: Math.max(...spendingOverTime.map(({ y }) => y).concat(100)) + 100,
      title: {
        text: 'Award Amount'
      }
    });
    series.push({
      name: 'Award Amount',
      type: 'column',
      data: spendingOverTime.map(({ xStr, y }) => [xStr, y]),
      tooltip: {
        valuePrefix: '$'
      },
      yAxis: 0,
      colors: [colors.blue[600]]
    });
  }

  if (hasNewAwards) {
    yAxis.push({
      min: 0,
      max: Math.max(...newAwardsOverTime.map(({ y }) => y).concat(10)) + 10,
      title: {
        text: 'New Awards'
      },
      opposite: true
    });
    const newAwardsSeries: Highcharts.SeriesLineOptions = {
      name: 'New Awards',
      type: 'line',
      data: newAwardsOverTime.map(({ xStr, y }) => [xStr, y]),
      color: colors.pink[600],
      yAxis: 1
    };
    if (!hasSpendingData) newAwardsSeries.yAxis = 0;

    series.push(newAwardsSeries);
  }

  const chartOptions: Highcharts.Options = {
    title: {
      text: ''
    },

    tooltip: {
      shared: true
    },

    xAxis: {
      type: 'category',
      categories: hasSpendingData
        ? spendingOverTime.map(({ xStr }) => xStr)
        : newAwardsOverTime.map(({ xStr }) => xStr),
      title: {
        text: 'Government Fiscal Year'
      }
    },

    legend: {
      enabled: true
    },

    yAxis,

    series
  };

  return <ComboGraph title="US Federal Awards" options={chartOptions} />;
};
