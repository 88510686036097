import React from 'react';
import { Button, ButtonGroup, Popover } from '@blueprintjs/core';
import { useGetReactionsQuery, useCreateReactionMutation } from 'api/reactionsApi';
import { availableReactions } from 'app/lib/reactions';
import { useReactionsStore } from 'app/molecules/Reactions/useReactionsStore';

export const ReactionsButton = () => {
  const { commentId, setIsOpen, isOpen } = useReactionsStore(s => ({
    commentId: s.commentId,
    setIsOpen: s.setIsOpen,
    isOpen: s.isOpen
  }));
  const reactionsQuery = useGetReactionsQuery({ reactableId: commentId });
  const [createCommentReaction] = useCreateReactionMutation();

  const handleClick = (reaction: string) => {
    setIsOpen(false);
    createCommentReaction({
      name: reaction,
      reactableType: 'Comment',
      reactableId: commentId
    });
  };

  const defaultButtons = availableReactions
    .map(reaction => <Button minimal key={reaction} text={reaction} onClick={() => handleClick(reaction)} />)
    .reverse();

  return (
    <div className="flex justify-end items-center">
      <Popover
        position="top-right"
        minimal
        isOpen={isOpen}
        content={
          <ButtonGroup className="p-1" data-test="reaction-emoji-buttons">
            {defaultButtons}
          </ButtonGroup>
        }
        onClose={() => setIsOpen(false)}
        canEscapeKeyClose
      >
        <Button
          className="rounded-full"
          minimal
          icon={<span className="text-lg">😀</span>}
          disabled={reactionsQuery.isLoading}
          onClick={() => setIsOpen(true)}
          data-test="reactions-button"
        />
      </Popover>
    </div>
  );
};
