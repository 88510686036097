import React from 'react';
import { Card, Icon } from '@blueprintjs/core';
import { NonIdealState } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import { useGetWorkspaceAssistantThreadsQuery } from 'api/workspaceAssistantThreadsApi';
import { LlmAssistantThreadIndex } from 'types/__generated__/GovlyApi';
import { Loading } from 'app/atoms/Loading/Loading';

export type AssistantThreadHistoryProps = {
  onThreadSelect?: (thread: LlmAssistantThreadIndex) => void;
  activeThreadId?: string;
  workspaceId: string;
  setShowThreadHistory: (show: boolean) => void;
};

export const AssistantThreadHistory = ({
  onThreadSelect,
  activeThreadId,
  workspaceId,
  setShowThreadHistory
}: AssistantThreadHistoryProps) => {
  const { data: threads = [], isLoading } = useGetWorkspaceAssistantThreadsQuery({ workspaceId: workspaceId });

  const handleThreadClick = (thread: LlmAssistantThreadIndex) => {
    if (onThreadSelect) {
      onThreadSelect(thread);
    }
  };

  return (
    <Card className="absolute z-50 lg:relative flex flex-col gap-2 w-full lg:w-1/3 p-0 mr-4 h-[--main-card-full-height]">
      <div className="flex flex-row items-center gap-2 mb-4 lg:mb-0 border-b px-[10px] py-3">
        <Icon icon="chevron-left" className="text-gray-500 lg:hidden" onClick={() => setShowThreadHistory(false)} />
        <h4 className="text-sm font-medium text-gray-700">Recent Threads</h4>
      </div>

      {isLoading ? (
        <Loading type="thread" threadCount={12} />
      ) : (
        <div className="overflow-y-scroll h-full mx-4">
          {threads.map(thread => (
            <AssistantThreadHistoryItem
              key={thread.id}
              thread={thread}
              activeThreadId={activeThreadId}
              handleThreadClick={handleThreadClick}
            />
          ))}
        </div>
      )}
      {!isLoading && threads.length === 0 && (
        <NonIdealState icon="horizontal-bar-chart-desc" title="No threads found" />
      )}
    </Card>
  );
};

export const AssistantThreadHistoryItem = ({
  thread,
  activeThreadId,
  handleThreadClick
}: {
  thread: LlmAssistantThreadIndex;
  activeThreadId: string | undefined;
  handleThreadClick: (thread: LlmAssistantThreadIndex) => void;
}) => {
  return (
    <div
      key={thread.id}
      className={cn(
        'cursor-pointer transition-colors rounded px-[10px] py-[5px]',
        activeThreadId === thread.id ? 'bg-gray-400' : 'hover:bg-gray-400'
      )}
      onClick={() => handleThreadClick(thread)}
    >
      <div className="flex justify-between items-center p-1">
        <div className="text-sm">
          <h3 className="font-medium">Thread {thread.id.slice(-4)}</h3>
          <p className="text-gray-500 text-xs">
            {thread.lastUserMessage?.content
              ? `${thread.lastUserMessage.content.slice(0, 40)}${thread.lastUserMessage.content.length > 40 ? '...' : ''}`
              : 'Empty thread'}
          </p>
        </div>
      </div>
    </div>
  );
};
