import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import startCase from 'lodash-es/startCase';
import { LineChart } from 'react-chartkick';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useSearchMutation } from 'api/oppsApi';

const chartOptions = {
  xAxis: {
    type: 'datetime'
  },
  tooltip: {
    shared: true
  }
};

type SliceKey = 'feedAgg' | 'noticeTypeAgg' | 'setAsidesAgg';
const slices: { key: SliceKey; label: string }[] = [
  { key: 'feedAgg', label: 'Feeds' },
  { key: 'noticeTypeAgg', label: 'Notice Types' },
  { key: 'setAsidesAgg', label: 'Set Asides' }
];

type OppAggregationPostedAtProps = {
  query: string;
  filters: { contactIds: string[]; contactAggregation: boolean; dateRangeParam?: string };
  title?: string;
  fixedCacheKey?: string;
};

type AggBucket = {
  keyAsString: string;
  docCount: number;
} & Record<SliceKey, { buckets: { key: string; docCount: number }[] }>;

type AggregationQueryResponse = {
  aggs: {
    postedAtAgg?: { buckets: AggBucket[] };
    modifiedAtAgg?: { buckets: AggBucket[] };
    respondByAgg?: { buckets: AggBucket[] };
  };
};

export const OppAggregationPostedAt = ({
  query,
  filters,
  title = 'Posted Over Time',
  fixedCacheKey
}: OppAggregationPostedAtProps) => {
  const [filterType, setFilterType] = useState<SliceKey | null>(null);
  const { dateRangeParam, ...remainingFilters } = filters;

  let aggType = 'posted_at_agg';

  if (dateRangeParam === 'modified_at') {
    aggType = 'modified_at_agg';
  }

  if (dateRangeParam === 'respond_by') {
    aggType = 'respond_by_agg';
  }

  const payload = { query, ...remainingFilters, aggs: [aggType], aggregationQuery: true };

  const [aggregate, { data: aggData = {}, isLoading, isError }] = useSearchMutation({ fixedCacheKey });
  const aggs = (aggData as AggregationQueryResponse)?.aggs ?? {};

  useEffect(() => {
    aggregate(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let data = [];
  let csv: string;

  const agg = aggs?.postedAtAgg || aggs?.modifiedAtAgg || aggs?.respondByAgg;

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!agg || isLoading) {
    return <Loading />;
  }

  if (!filterType) {
    csv = 'Date,Count';
    data = agg.buckets.map(({ keyAsString, docCount }) => {
      const point = [keyAsString, docCount];
      csv += `\n${point.join(',')}`;
      return point as [string, number];
    });
  } else {
    csv = 'Date,Count,Slice';
    const aggMap: Record<string, Record<string, number>> = {};

    agg.buckets.forEach(bucket => {
      const dateKey = bucket.keyAsString;
      const subAgg = bucket[filterType];
      subAgg.buckets.forEach(({ key, docCount }) => {
        aggMap[key] = { ...aggMap[key], [dateKey]: docCount };
        csv += `\n${dateKey},${docCount},${key}`;
      });
    });

    data = Object.entries(aggMap).map(([name, d]) => ({ name: startCase(name), data: d }));
  }

  return (
    <Card
      className="mb-4"
      title={title}
      rightElement={
        csv.length ? (
          <CSVLink data={csv} filename={`govly-posted-at-results.csv`} className="text-gray-400 no-underline">
            <Button outlined icon="download">
              Download Data
            </Button>
          </CSVLink>
        ) : undefined
      }
    >
      <CardBody>
        <LineChart data={data} library={chartOptions} />
      </CardBody>
      <CardBody className="text-center">
        <ButtonGroup>
          <Button active={!filterType} onClick={() => setFilterType(null)} text="No Slice" />
          {slices.map(({ key, label }) => (
            <Button key={key} active={filterType === key} onClick={() => setFilterType(key)} text={label} />
          ))}
        </ButtonGroup>
      </CardBody>
    </Card>
  );
};
