import React from 'react';
import { useParams } from 'react-router-dom';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppAttachmentsTable } from 'app/organisms/OppAttachmentsTable/OppAttachmentsTable';

export const OppDetailsAttachmentsPage = () => {
  const { id } = useParams();

  return (
    <MainColumn columnSpan={12}>
      <OppAttachmentsTable id={id ?? ''} />
    </MainColumn>
  );
};
