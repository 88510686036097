import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetGovernmentEntityQuery } from 'api/governmentEntitiesApi';
import { GovernmentEntity } from 'app/organisms/GovernmentEntity/GovernmentEntity';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const GovernmentEntityPage = () => {
  const { id } = useParams();
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id || (!isLoading && !governmentEntity)) {
      navigate('/budgets/agencies', { replace: true });
    }
  }, [id, governmentEntity, isLoading, navigate]);

  if (!id) return <NotFound />;

  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']}>
      <GovernmentEntity id={id} />
    </SubscriptionRequiredPageWrapper>
  );
};
