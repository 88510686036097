import React from 'react';
import { ColumnHelper, DisplayColumnDef } from '@tanstack/react-table';
import { Checkbox } from '@blueprintjs/core';

export const ROWS_SELECTION_ID = 'row-selection';

export function makeGovlyTableRowSelectionColumn<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Helper extends ColumnHelper<any>,
  Data = Helper extends ColumnHelper<infer Data> ? Data : never
>({
  columnHelper,
  ...def
}: Partial<DisplayColumnDef<Data>> & {
  columnHelper: Helper;
}): DisplayColumnDef<Data> {
  return columnHelper.display({
    id: ROWS_SELECTION_ID,
    header: ({ table }) => (
      <Checkbox
        className="mb-0"
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        className="mb-0"
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler()
        }}
      />
    ),
    size: 16,
    enableResizing: false,
    enableHiding: false,
    enableSorting: false,
    ...def
  });
}
