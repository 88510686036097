import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { Dialog } from '@blueprintjs/core';

import { VendorViewerContext } from 'types/__generated__/GovlyApi';
import { MarketingCta } from 'app/molecules/MarketingCta/MarketingCta';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useGetVendorQuery } from 'api/vendorsApi';
import { PartnershipRequestForm } from 'app/organisms/PartnershipRequestForm/PartnershipRequestForm';
import { Pattern, match } from 'ts-pattern';

export const VendorProfileClaimButtons = ({ slug }: { slug: string }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [partnershipModalOpen, setPartnershipModalOpen] = useState(false);
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();
  const { data, isLoading } = useGetVendorQuery({
    id: slug,
    view: 'viewer_context'
  });

  if (isLoading || currentUserLoading || !data) return null;

  if (!currentUser) {
    return (
      <div>
        <div className="flex justify-center gap-x-2 lg:justify-end">
          <Button large intent="primary" text="Claim Profile" onClick={() => setModalOpen(true)} />
          <Button large outlined text="Request Partnership" onClick={() => setModalOpen(true)} />
        </div>
        <Dialog isOpen={modalOpen} onClose={() => setModalOpen(false)} className="bg-gray-900">
          <MarketingCta variant={'bullets'} />
        </Dialog>
      </div>
    );
  }

  const {
    id: vendorId,
    name,
    organizationId,
    claimed,
    viewerClaimed,
    viewerPartnership,
    viewerPartnershipRequest
  } = data as VendorViewerContext;

  const { subscriptionSeatPaid } = currentUser;

  if (viewerClaimed) {
    return (
      <Link to="/settings/vendor_profile" className="no-underline">
        <Button outlined large text="Edit Profile" />
      </Link>
    );
  }

  const partnershipButton = match({ subscriptionSeatPaid, viewerPartnershipRequest, viewerPartnership })
    .with({ subscriptionSeatPaid: Pattern.not(Pattern.nullish) }, () => null)
    .with({ viewerPartnership: Pattern.not(Pattern.nullish) }, () => (
      <Link to={`/partnerships/${viewerPartnership?.id}`}>
        <Button large outlined text="View Partnership" />
      </Link>
    ))
    .with({ viewerPartnershipRequest: Pattern.not(Pattern.nullish) }, () => (
      <Button large icon="tick" outlined intent="success" disabled text="Partnership Requested" />
    ))
    .otherwise(() => (
      <Button
        large
        outlined={!claimed}
        intent={claimed ? 'primary' : undefined}
        text="Request Partnership"
        onClick={() => setPartnershipModalOpen(true)}
      />
    ));

  return (
    <div>
      <div className="flex justify-center gap-x-2 lg:justify-end">
        {partnershipButton}

        {!claimed && (
          <Link to={`/settings/vendor_profile?vendor_id=${vendorId}&claim_request=true`}>
            <Button intent="primary" large text="Claim Profile" />
          </Link>
        )}
      </div>

      <Dialog isOpen={modalOpen} onClose={() => setModalOpen(false)} className="bg-gray-900">
        <MarketingCta variant={'bullets'} />
      </Dialog>

      <Dialog isOpen={partnershipModalOpen} onClose={() => setPartnershipModalOpen(false)} className="min-w-[40%] p-0">
        <PartnershipRequestForm
          onCancel={() => setPartnershipModalOpen(false)}
          targetOrganizationId={organizationId}
          targetVendorId={vendorId}
          targetVendorName={name ?? ''}
        />
      </Dialog>
    </div>
  );
};
