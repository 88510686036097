import React, { useState } from 'react';
import { Button, Callout } from '@blueprintjs/core';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

export const ErrorBoundaryTest = () => {
  return (
    <ErrorBoundary action="ErrorBoundaryTest" fallback={<Callout intent="danger">Manual error thrown!</Callout>}>
      <Test />
    </ErrorBoundary>
  );
};

const Test = () => {
  const [forceError, setForceError] = useState(false);

  return (
    <Button intent="danger" onClick={() => setForceError(true)}>
      Test error boundary {forceError ? ([] as string[])[0].split('') : ''}
    </Button>
  );
};
