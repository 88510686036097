import React from 'react';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';
import { OrganizationInsights } from 'app/organisms/OrganizationInsights/OrganizationInsights';

export const OrganizationInsightsPage = () => {
  return (
    <SubscriptionRequiredPageWrapper subscriptions={['seatPaid']}>
      <OrganizationInsights />
    </SubscriptionRequiredPageWrapper>
  );
};
