import React from 'react';
import { useField } from 'formik';
import {
  FormGroup,
  NumericInput as BPNumericInput,
  FormGroupProps,
  NumericInputProps as BPNumericInputProps
} from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

type NumericInputProps = {
  name: string;
  inputProps?: BPNumericInputProps;
} & FormGroupProps;

export const NumericInput = ({
  name,
  className,
  helperText,
  disabled,
  inline,
  label,
  labelInfo,
  inputProps = { large: true },
  ...rest
}: NumericInputProps) => {
  const [field, meta, { setValue }] = useField({ name });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      inline={inline}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <BPNumericInput
        {...field}
        onValueChange={v => {
          setValue(v);
        }}
        onButtonClick={v => setValue(v)}
        value={field.value}
        name={name}
        disabled={disabled}
        intent={intent}
        min={0}
        allowNumericCharactersOnly
        clampValueOnBlur
        large
        {...inputProps}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
