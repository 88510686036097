import React from 'react';
import { useParams } from 'react-router-dom';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppWorkspacesTable } from 'app/organisms/OppWorkspacesTable/OppWorkspacesTable';

export const OppDetailsWorkspacesPage = () => {
  const { id } = useParams();

  return (
    <MainColumn columnSpan={12}>
      <OppWorkspacesTable id={id ?? ''} />
    </MainColumn>
  );
};
