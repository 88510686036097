import React from 'react';
import { LoaderFunction, useParams } from 'react-router-dom';

import { OppWorkspacePageContents } from 'app/organisms/OppWorkspacePageContents/OppWorkspacePageContents';
import { oppsApi, useGetOppQuery } from 'api/oppsApi';
import { oppWorkspacesApi, useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { ApplicationError } from 'app/atoms/ErrorFallback/ApplicationError';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';
import { store } from 'app/store';
import { OppWorkspaceStoreProvider } from 'app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

type LoaderData = { workspaceId?: string; oppId?: string };

export const loader: LoaderFunction = async ({ params }): Promise<LoaderData> => {
  return { oppId: params.oppId, workspaceId: params.workspaceId };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ oppId, workspaceId }) => {
  if (!oppId || !workspaceId) return [];

  const opp = await store
    .dispatch(oppsApi.endpoints.getOpp.initiate({ id: oppId }, { subscribe: false }))
    .then(({ data }) => data);
  const workspace = await store
    .dispatch(oppWorkspacesApi.endpoints.getOppWorkspace.initiate({ id: workspaceId }, { subscribe: false }))
    .then(({ data }) => data);

  return workspace && opp
    ? [
        { to: `/opportunities`, text: 'Opportunities' },
        { to: `/opportunities/${opp.id}`, text: opp.displayName },
        { to: `/opportunities/${opp.id}/workspaces`, text: 'Workspaces' },
        { to: `/opportunities/${opp.id}/workspaces/${workspace.id}`, text: workspace.name ?? workspace.id }
      ]
    : [{ to: `/opportunities`, text: 'Opportunities' }];
});

export const OppWorkspacePage = ({ tab }: { tab?: string }) => {
  const { oppId, workspaceId } = useParams();

  const { isLoading: oppLoading, isError: oppIsError } = useGetOppQuery({ id: oppId ?? '' }, { skip: !oppId });

  const {
    isLoading: workspaceLoading,
    isError: workspaceIsError,
    error
  } = useGetOppWorkspaceQuery({ id: workspaceId ?? '' }, { skip: !workspaceId });

  if (oppIsError || workspaceIsError) {
    if (error && 'status' in error && error.status === 401) return <NotAuthorized />;
    return <ApplicationError />;
  }

  if (oppLoading || workspaceLoading) {
    return <AppLoading />;
  }

  if (!workspaceId) return <NotFound />;

  return (
    <OppWorkspaceStoreProvider workspaceId={workspaceId}>
      <OppWorkspacePageContents id={workspaceId} tab={tab} />
    </OppWorkspaceStoreProvider>
  );
};
