import { SouptoolsCatalog, SouptoolsClin } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::Catalog'] });

type GetCatalog = {
  params: void;
  result: SouptoolsCatalog;
};

type SearchCatalog = {
  params: {
    query?: string;
    column?: string;
    page?: number;
  };
  result: {
    clins: SouptoolsClin[];
    meta: {
      currentPage: number;
      total: number;
      perPage: number;
      totalPages: number;
    };
  };
};

export const souptoolsCatalogsApi = api.injectEndpoints({
  endpoints: build => ({
    getCatalog: build.query<GetCatalog['result'], GetCatalog['params']>({
      providesTags: ['Souptools::Catalog'],
      query: () => ({
        url: `/v2/souptools/catalog`
      })
    }),
    searchCatalog: build.query<SearchCatalog['result'], SearchCatalog['params']>({
      query: params => ({
        url: `/v2/souptools/catalog/search`,
        params
      }),
      providesTags: (_result, _err, { query }) =>
        query ? [{ type: 'Souptools::Catalog', id: query }] : ['Souptools::Catalog']
    })
  })
});

export const { useGetCatalogQuery, useSearchCatalogQuery } = souptoolsCatalogsApi;
