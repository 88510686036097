import React from 'react';
import { Tooltip, TooltipProps } from '@blueprintjs/core';
import { CellContext } from '@tanstack/react-table';
import { cn } from 'app/lib/cn';
import { useGetColumnWidth } from 'app/molecules/GovlyTable/useGetColumnWidth';

type Props<Type, Value> = React.HTMLAttributes<HTMLDivElement> & {
  tooltipProps?: Partial<TooltipProps>;
  context: CellContext<Type, Value>;
};

export function GovlyTableOverflowCell<Type, Value extends string | undefined>({
  tooltipProps,
  context,
  className,
  ...rest
}: Props<Type, Value>) {
  const getColumnWidth = useGetColumnWidth();
  const [hasScroll, setHasScroll] = React.useState(false);

  return (
    <Tooltip
      placement="right"
      disabled={!hasScroll}
      content={<span className="max-w-xs inline-block">{context.getValue()}</span>}
      {...tooltipProps}
    >
      <div
        ref={node => {
          if (node) {
            setHasScroll(node.scrollHeight > node.clientHeight);
          }
        }}
        className={cn('text-wrap line-clamp-2', className)}
        {...rest}
        style={{
          ...rest.style,
          maxWidth: getColumnWidth(context.column.getSize())
        }}
      >
        {context.getValue()}
      </div>
    </Tooltip>
  );
}
