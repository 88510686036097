import { FormKeys } from 'app/lib/formikToBp';
import { useFormikContext } from 'formik';
import debounce from 'just-debounce-it';
import { useCallback } from 'react';

export function useGetFormikField<Values>({ debounceTime = 500 }: { debounceTime?: number } = {}) {
  const { submitForm, getFieldProps, getFieldHelpers } = useFormikContext<Values>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => submitForm(), debounceTime),
    [submitForm]
  );
  const getField = useCallback(
    (name: FormKeys<Values>) => ({
      field: getFieldProps(name as string),
      helpers: getFieldHelpers(name as string),
      submitForm: debouncedSubmit
    }),
    [debouncedSubmit, getFieldHelpers, getFieldProps]
  );

  return getField;
}
