import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { hasId } from 'api/utils';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { ColorInput, colorInputValidation } from 'app/atoms/inputs/ColorInput/ColorInput';
import { CardBody, CardFooter, Card } from 'app/atoms/Card/Card';
import { errorToast } from 'app/lib/toaster';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { OrganizationTeam, OrganizationTeamWithOrganizationUsers } from 'types/__generated__/GovlyApi';
import { useCreateOrganizationTeamMutation, useUpdateOrganizationTeamMutation } from 'api/organizationTeamsApi';
import { TeamAvatarWithName } from 'app/molecules/TeamAvatarWithName/TeamAvatarWithName';

type TeamFormProps = {
  team?: OrganizationTeam | OrganizationTeamWithOrganizationUsers;
  onCancel: () => void;
};

export const TeamForm = ({ team, onCancel }: TeamFormProps) => {
  const { trackEvent } = useEventTracking();
  const [update, { isLoading: isUpdating }] = useUpdateOrganizationTeamMutation();
  const [create, { isLoading: isCreating }] = useCreateOrganizationTeamMutation();

  const validationSchema = yup.object({
    name: yup.string().required('Team name is required').max(50),
    avatarColor: colorInputValidation().optional()
  });

  const isLoading = isUpdating || isCreating;

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const teamArgs = { ...values, id: team?.id };
          if (hasId(teamArgs)) {
            await update(teamArgs);
          } else {
            await create(values);
          }

          setSubmitting(false);
          trackEvent({
            object: 'organization_team',
            action: team?.id ? 'updated' : 'created',
            properties: { id: team?.id, name: values.name, avatarColor: values.avatarColor }
          });
          onCancel();
        } catch (e) {
          setSubmitting(false);
          errorToast(e);
        }
      }}
      initialValues={{
        name: team?.name || 'New Team',
        avatarColor: team?.avatarColor || '#444444'
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Card
            title={`${team?.id ? 'Update' : 'Create a'} Team`}
            rightElement={<Button minimal icon="cross" onClick={onCancel} />}
          >
            <CardBody>
              <div className="block">
                <TeamAvatarWithName team={{ ...values, memberCount: team?.memberCount || 0 }} />
              </div>
              <TextInput name="name" label="Team name" />
              <ColorInput name="avatarColor" label="Color" setValue={hex => setFieldValue('avatarColor', hex)} />
            </CardBody>
            <CardFooter>
              <Button intent="primary" type="submit" loading={isLoading}>
                Save changes
              </Button>
              <Button onClick={onCancel} disabled={isLoading}>
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
