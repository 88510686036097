import React from 'react';
import { Button } from '@blueprintjs/core';
import { successToast, errorToast } from 'app/lib/toaster';
import { useBulkUpdateActivityNotificationsMutation } from 'api/activityNotificationsApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';
import { cn } from 'app/lib/cn';

export const MarkAsReadBulk = () => {
  const { table } = useGovlyTableContext();
  const selectedRows = table.getSelectedRowModel().rows.map(r => r.id);
  const [bulkUpdateActivityNotification] = useBulkUpdateActivityNotificationsMutation();
  const { trackEvent } = useEventTracking();

  return (
    <div
      className={cn('flex items-center space-x-2', {
        invisible: selectedRows.length === 0
      })}
    >
      <span className="text-sm text-gray-500">{selectedRows.length} selected</span>
      <Button
        intent="success"
        text="Mark as Read"
        onClick={async () => {
          const ids = selectedRows;
          const readAt = new Date().toISOString();
          try {
            await bulkUpdateActivityNotification({ ids: selectedRows });
            trackEvent({
              object: 'notification',
              action: 'marked_as_read_bulk',
              properties: { ids, readAt }
            });
            successToast();
          } catch (e) {
            errorToast(e);
          }
        }}
      />
    </div>
  );
};
