import React from 'react';
import { useParams } from 'react-router-dom';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppDetailsSource } from 'app/organisms/OppDetailsSource/OppDetailsSource';
import { NotFound } from 'app/molecules/NotFound/NotFound';

export const OppSourcePage = () => {
  const { id, sourceId } = useParams();

  if (!id || !sourceId) {
    return <NotFound />;
  }

  return (
    <Main>
      <MainColumn columnSpan={12}>
        <OppDetailsSource oppId={id} oppSourceId={sourceId} />
      </MainColumn>
    </Main>
  );
};
