import React, { useState } from 'react';
import { AnchorButton, AnchorButtonProps } from '@blueprintjs/core';

import { GoogleIcon } from 'images/components/GoogleIcon';

export const SignInGoogleButton = (props: AnchorButtonProps) => {
  const [loading, setLoading] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    window.location.href = '/sso?provider=GoogleOAuth';
  };

  return (
    <AnchorButton outlined large loading={loading} onClick={onClick} {...props}>
      <div className="flex space-x-2 items-center">
        <GoogleIcon />
        <span>Sign in with Google</span>
      </div>
    </AnchorButton>
  );
};
