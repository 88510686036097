import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, ButtonGroup, Dialog, Tooltip } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetSavedSearchesQuery } from 'api/savedSearchesApi';
import { useCreateSavedSearchFollowMutation, useDeleteSavedSearchFollowMutation } from 'api/savedSearchFollowsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { SavedSearchCreateForm } from 'app/organisms/SavedSearchCreateForm/SavedSearchCreateForm';
import { SavedSearchUpdateForm } from 'app/organisms/SavedSearchUpdateForm/SavedSearchUpdateForm';
import { defaultValues as contactIntitialValues } from 'app/hooks/search/useContactSearchCache';
import { defaultValues as awardInitialValues } from 'app/hooks/search/useAwardSearchCache';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { ContactSearchForm } from 'app/hooks/search/useContactSearchCache';

type SavedSearchControlsProps = {
  searchableType: 'Contact' | 'USASpendingAward';
};

export const SavedSearchControls = ({ searchableType }: SavedSearchControlsProps) => {
  const defaultState = searchableType === 'Contact' ? contactIntitialValues : awardInitialValues;
  const navigate = useNavigate();
  const {
    setValues,
    submitForm,
    values: { query, filters, meta }
  } = useFormikContext<AwardSearchForm | ContactSearchForm>();
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [templateSearchId, setTemplateSearchId] = useState<string>();

  const routes = {
    Contact: { base: '/contacts', breadcrumb: 'Contacts' },
    USASpendingAward: { base: '/awards', breadcrumb: 'Awards' }
  };

  const selected = meta.savedSearchId;

  useEffect(() => {
    setIsDirty(
      JSON.stringify({ query, filters }) !==
        JSON.stringify({ query: defaultState.query, filters: defaultState.filters })
    );
  }, [query, filters, defaultState]);

  const currentOrgUserId = useCurrentUserAttribute('id');
  const [createSavedSearchFollow] = useCreateSavedSearchFollowMutation();
  const [deleteSavedSearchFollow] = useDeleteSavedSearchFollowMutation();

  const { selectedSavedSearch, isLoading } = useGetSavedSearchesQuery(
    { active: true, searchableType },
    {
      selectFromResult: ({ data, isLoading }) => ({
        selectedSavedSearch: data?.find(({ id }) => id === selected),
        isLoading
      })
    }
  );

  const toggleIsCreating = () => setIsCreating(!isCreating);
  const toggleIsEditing = () => setIsEditing(!isEditing);

  return (
    <>
      {selected && selectedSavedSearch && (
        <>
          <ButtonGroup>
            <Tooltip content="Clear this search">
              <Button
                large
                disabled={isLoading}
                onClick={() => {
                  setValues(defaultState);
                  setTemplateSearchId(undefined);
                  navigate(`${routes[searchableType].base}`);
                  submitForm();
                }}
                icon="filter-remove"
              >
                Clear
              </Button>
            </Tooltip>
            <Tooltip content="Edit this search">
              <Button large disabled={isLoading} onClick={toggleIsEditing} icon="edit" />
            </Tooltip>
            <Tooltip content="Save a new copy">
              <Button
                large
                disabled={isLoading}
                onClick={() => {
                  toggleIsCreating();
                  setTemplateSearchId(selected);
                }}
                data-test="save-new-search-button"
                icon="floppy-disk"
              />
            </Tooltip>
            {selectedSavedSearch.currentUserFollowing ? (
              <Tooltip content="Following">
                <Button
                  large
                  className="bp5-intent-success"
                  onClick={async () => {
                    try {
                      await deleteSavedSearchFollow({
                        organizationUserId: currentOrgUserId,
                        savedSearchId: selected,
                        savedSearchType: 'SavedSearch'
                      }).unwrap();
                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                  icon="thumbs-up"
                />
              </Tooltip>
            ) : (
              <Tooltip content="Follow this search">
                <Button
                  large
                  onClick={async () => {
                    try {
                      await createSavedSearchFollow({
                        organizationUserId: currentOrgUserId,
                        savedSearchId: selected,
                        savedSearchType: 'SavedSearch'
                      }).unwrap();

                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                  icon="thumbs-up"
                />
              </Tooltip>
            )}
          </ButtonGroup>
        </>
      )}
      {!selected && (
        <>
          <ButtonGroup>
            {isDirty && (
              <Tooltip content="Clear this search">
                <Button
                  large
                  onClick={() => {
                    setValues(defaultState);
                    setTemplateSearchId(undefined);
                    navigate(`${routes[searchableType].base}`);
                    submitForm();
                  }}
                  icon="filter-remove"
                >
                  Clear
                </Button>
              </Tooltip>
            )}
            <Tooltip content="Save this search">
              <Button large onClick={toggleIsCreating} data-test="save-new-search-button" icon="floppy-disk" />
            </Tooltip>
          </ButtonGroup>
        </>
      )}
      <Dialog
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        shouldReturnFocusOnClose={false}
        className="min-w-[50%] bg-white p-0"
        title="Create a new saved search"
      >
        <SavedSearchCreateForm
          templateSavedSearchId={templateSearchId}
          searchableType={searchableType}
          onCancel={toggleIsCreating}
          handleClose={toggleIsCreating}
        />
      </Dialog>
      {selected && (
        <Dialog
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          shouldReturnFocusOnClose={false}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          <SavedSearchUpdateForm
            savedSearchId={selected}
            searchableType={searchableType}
            onCancel={toggleIsEditing}
            handleClose={toggleIsEditing}
            initialQuery={query}
            initialFilters={filters}
          />
        </Dialog>
      )}
    </>
  );
};
