import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Icon } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { useGetOrganizationUserCalendarQuery } from 'api/organizationUsersApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { H3 } from 'app/atoms/Typography/Typography';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { pluralize } from 'app/lib/strings';
import { WorklistCalendarSubscription } from 'app/organisms/WorklistCalendarSubscription/WorklistCalendarSubscription';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { CalendarEvent } from 'types/__generated__/GovlyApi';

type ValidCalendarEvent = Omit<CalendarEvent, 'startTime' | 'endTime'> & { startTime: string; endTime: string };

const getDates = ([month, year]: [number, number]) => {
  const today = DateTime.local().startOf('day');
  const startOfMonth = DateTime.local(year, month);
  let currDate = startOfMonth.minus({ days: startOfMonth.weekday - 1 });
  const days = [];

  for (let i = 0; i < 35; i++) {
    days.push({
      date: currDate.toISO() ?? '',
      isCurrentMonth: month === currDate.month && year === currDate.year,
      isToday: currDate.toISO() === today.toISO() && month === currDate.month
    });

    currDate = currDate.plus({ days: 1 });
  }

  return {
    name: DateTime.local(year, month).toFormat('MMMM yyyy'),
    days,
    startOfMonth: DateTime.local(year, month).startOf('month'),
    endOfMonth: DateTime.local(year, month).endOf('month')
  };
};

const monthRange = (offset: number, months = 2): [number, number][] => {
  const today = DateTime.local().startOf('day');
  const currMonth = !offset ? today : today.plus({ months: offset });
  const nextMonth = currMonth.plus({ months: 1 });

  if (months === 1) {
    return [
      [currMonth.month, currMonth.year],
      [currMonth.month, currMonth.year]
    ];
  }

  return [
    [currMonth.month, currMonth.year],
    [nextMonth.month, nextMonth.year]
  ];
};

export const WorklistCalendar = ({ organizationUserId }: { organizationUserId?: string }) => {
  const currentOrgUserId: string = useCurrentUserAttribute('id');

  const { isDesktop } = useDeviceWidth();
  const [offset, setOffset] = useState(0);
  const [monthsToDisplay, setMonthsToDisplay] = useState(isDesktop ? 2 : 1);

  useEffect(() => {
    const val = isDesktop ? 2 : 1;
    setMonthsToDisplay(val);
  }, [isDesktop]);

  const { data: { url: calendarUrl, events } = {}, isLoading } = useGetOrganizationUserCalendarQuery({
    id: organizationUserId || currentOrgUserId
  });

  const months = useMemo(() => {
    const currMonths = monthRange(offset, monthsToDisplay);
    return [getDates(currMonths[0]), getDates(currMonths[1])];
  }, [offset, monthsToDisplay]);

  const today = DateTime.local();
  const startOfWindow = months[0].startOfMonth;
  const endOfWindow = months[months.length - 1].endOfMonth;

  const windowEvents = useMemo(
    () =>
      (events?.filter(({ startTime, endTime }) => {
        if (startTime === undefined || endTime === undefined) return false;

        const start = DateTime.fromISO(startTime);
        const end = DateTime.fromISO(endTime);

        return (
          (start.month === startOfWindow.month && start.year === startOfWindow.year) ||
          (end.month === endOfWindow.month && end.year === endOfWindow.year)
        );
      }) as ValidCalendarEvent[]) || [],
    [events, startOfWindow, endOfWindow]
  );

  if (isLoading) {
    return <Loading />;
  }

  const todayKey = today.toISO().split('T')[0];
  const eventsIndex: Record<string, CalendarEvent[]> = {};

  windowEvents.forEach(event => {
    const { startTime, endTime } = event;
    const start = (startTime || '').split('T')[0];
    const end = (endTime || '').split('T')[0];

    if (!eventsIndex[start]) {
      eventsIndex[start] = [event];
    } else {
      eventsIndex[start].push(event);
    }

    if (!eventsIndex[end]) {
      eventsIndex[end] = [event];
    } else {
      eventsIndex[end].push(event);
    }
  });

  let eventsToDisplay = [...windowEvents];
  if (startOfWindow.toFormat('L') === today.toFormat('L')) {
    eventsToDisplay = windowEvents.filter(event => DateTime.fromISO(event.endTime) >= today);
  }

  eventsToDisplay.sort((a, b) => (a.endTime > b.endTime ? 1 : -1));

  return (
    <div>
      <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2">
        <button
          type="button"
          className="absolute -top-1 -left-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setOffset(offset - 1)}
        >
          <span className="sr-only">Previous month</span>
          <Icon icon="chevron-left" className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="absolute -top-1 -right-1.5 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setOffset(offset + 1)}
        >
          <span className="sr-only">Next month</span>
          <Icon icon="chevron-right" className="h-5 w-5" aria-hidden="true" />
        </button>
        {months.map((month, monthIdx) => (
          <section key={monthIdx} className={cn(monthIdx === months.length - 1 && 'hidden md:block', 'text-center')}>
            <h2 className="font-semibold text-gray-900">{month.name}</h2>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {month.days.map((day, dayIdx) => {
                const key = day.date.split('T')[0];
                const hasEvent = !!eventsIndex[key];

                return (
                  <div
                    key={day.date}
                    className={cn(
                      day.isCurrentMonth ? 'bg-white text-gray-900' : 'bg-gray-50 text-gray-400',
                      dayIdx === 0 && 'rounded-tl-lg',
                      dayIdx === 6 && 'rounded-tr-lg',
                      dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                      dayIdx === month.days.length - 1 && 'rounded-br-lg',
                      'relative flex flex-col items-center py-1.5'
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={cn(
                        day.isToday && 'bg-blue-700 font-semibold text-white',
                        'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                      )}
                    >
                      {key.split('-').pop()?.replace(/^0/, '')}
                    </time>
                    {hasEvent ? <Icon icon="dot" className="text-orange-400" /> : <Icon icon="blank" />}
                  </div>
                );
              })}
            </div>
          </section>
        ))}
      </div>
      <WorklistCalendarSubscription
        calendarUrl={calendarUrl ?? ''}
        header={<H3>Subscribe to all events in your calendar via...</H3>}
        className="my-12 justify-center text-center"
        linksClassName="justify-center text-center"
      />
      <section className="mt-12">
        <h2 className="font-semibold text-gray-900">Events</h2>
        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          <li className="flex items-center justify-between py-4 sm:justify-start">
            <time dateTime={todayKey} className="w-28 flex-none">
              Today
            </time>
            {offset ? (
              <LinkTag
                tag="a"
                href="#today"
                onClick={e => {
                  e.preventDefault();
                  setOffset(0);
                }}
              >
                Go to today
              </LinkTag>
            ) : (
              <div>
                {eventsIndex[todayKey] ? (
                  <p className="m-0 flex-auto font-semibold text-gray-900">{`${
                    eventsIndex[todayKey].length
                  } ${pluralize('event', 'events', eventsIndex[todayKey].length)}`}</p>
                ) : (
                  <p className="m-0 flex-auto">Nothing on today’s schedule</p>
                )}
              </div>
            )}
          </li>
          {!eventsToDisplay.length && (
            <li className="py-4 sm:flex">
              <div className="w-28 flex-none"></div>
              <div className="mt-2 flex-auto sm:mt-0">
                <p className="m-0 font-semibold text-gray-900">No events.</p>
              </div>
            </li>
          )}
          {eventsToDisplay.map(event => (
            <li key={event.id} id={event.id} className="py-4 sm:flex">
              <time dateTime={event.startTime} className="w-28 flex-none">
                {DateTime.fromISO(event.startTime || event.endTime).toLocaleString(DateTime.DATE_MED)}
              </time>
              <div className="mt-2 flex-auto sm:mt-0">
                <p className="m-0 font-semibold text-gray-900">{event.title}</p>
                <p>{event.description}</p>
                {event.customProperties?.link && event.customProperties?.linkText && (
                  <LinkTag to={event.customProperties.link}>{event.customProperties.linkText}</LinkTag>
                )}
              </div>
              <p className="flex gap-x-2 sm:ml-6">
                {event.customProperties?.labelPrefix && <span>{event.customProperties?.labelPrefix}</span>}
                {event.startTime && (
                  <time dateTime="2022-01-13T14:30">
                    {DateTime.fromISO(event.startTime).toLocaleString(DateTime.DATETIME_FULL)}
                  </time>
                )}
                {event.startTime && event.endTime && <span>-</span>}
                {event.endTime && (
                  <time dateTime="2022-01-13T16:30">
                    {DateTime.fromISO(event.endTime).toLocaleString(DateTime.DATETIME_FULL)}
                  </time>
                )}
              </p>
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
};
