import React from 'react';
import { useFormikContext } from 'formik';
import { NonIdealState } from '@blueprintjs/core';

import { useSearchMutation } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import { OppSearchAnalyticsPostedAt } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsPostedAt';
import { OppSearchAnalyticsUpcomingExpiration } from 'app/organisms/OppSearchAnalyticsUpcomingExpiration/OppSearchAnalyticsUpcomingExpiration';
import { OppSearchAnalyticsBuyer } from 'app/organisms/OppSearchAnalyticsBuyer/OppSearchAnalyticsBuyer';
import { OppSearchAnalyticsVendor } from 'app/organisms/OppSearchAnalyticsVendor/OppSearchAnalyticsVendor';
import { OppSearchUnitedStatesMap } from 'app/organisms/OppSearchUnitedStatesMap/OppSearchUnitedStatesMap';

export const OppSearchAnalytics = () => {
  const [_search, { data: { results = [] } = {}, isLoading, isUninitialized, isSuccess }] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  const { values } = useFormikContext<OppSearchState>();
  if (values.semantic) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="search"
            title="Semantic Search Unsupported"
            description={<div>Currently, semantic search does not support search analytics.</div>}
          />
        </CardBody>
      </Card>
    );
  }

  if (isUninitialized) {
    return <Loading type="card-list" />;
  }

  if (isSuccess && !results.length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="search"
            title="No search results"
            description={
              <div>
                {`Your search didn't match any opportunities.`}
                <br />
                {`Try adjusting your search filters.`}
              </div>
            }
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <OppSearchAnalyticsPostedAt searchIsLoading={isLoading} />
      <OppSearchAnalyticsUpcomingExpiration searchIsLoading={isLoading} />
      <OppSearchAnalyticsBuyer searchIsLoading={isLoading} />
      <OppSearchAnalyticsVendor searchIsLoading={isLoading} />
      <OppSearchUnitedStatesMap searchIsLoading={isLoading} />
    </>
  );
};
